import { EMAIL_REGEX } from "./validationRegex";

export const validateResponse = (step) => (dispatch, getState) => {
  const { responses, form } = getState().form;

  //VALIDATE EACH FORM STEPS;
  let errors = [];

  form.steps[step - 1].elements.forEach((element) => {
    let widgetError = validateWidgetResponse(
      element,
      responses[element._id],
      form
    );
    if (widgetError.length > 0) errors[element._id] = widgetError;
  });

  return errors;
};

const validateWidgetResponse = (element, response, form) => {
  switch (element.element_type) {
    case "form_email":
      return validateMail(element, response, form.error_messages);
    case "form_multi_choice":
      return validateMultiChoice(element, response, form);
    case "form_single_choice":
      return validateSingleChoice(element, response, form);
    case "form_dropdown":
      return validateDropdown(element, response, form);
    case "form_phone":
      return validatePhone(element, response, form);
    case "form_number":
      return validateNumber(element, response, form);
    case "form_short_text":
    case "form_long_text":
      return validateLongText(element, response, form);
    case "form_date":
      return validateDate(element, response, form);
    case "form_name":
      return validateName(element, response, form);
    case "form_redirect_choice":
        return validateRedirect(element, response, form);
    case "form_product":
      return validateProduct(element, response, form, false);
      case "form_dynamic_product":
        return validateProduct(element, response, form, false);
    case "form_upsell":
      return validateProduct(element, response, form, true);
    default:
      return "";
  }
};

const validateProduct = (element, response, form, isUpsell) => {
  if(form.store_selected == "woocommerce" && response.value) {
    let product = null;
    if(isUpsell) product = element.content?.product_match?.[0]?.upsell_product;
    else product = element.content.products.find(item => item.id == response.value)
    if(product){
      for(let attr of product.attributes) {
        const select_name = attr.name.toLowerCase().replaceAll(" ", "-");
        if(!response[select_name]) return `${attr.name} is a required field.`;
      }
    }
  }
  return "";
}

const validateMail = (element, response, error_messages) => {
  let errorMessage = "";

  if (element.content.required) {
    if (!EMAIL_REGEX.test(response.email)) {
      errorMessage = error_messages.form_email;
    }

    if (response.email && element.content.allowedDomains.length > 0)
      if (
        !element.content.allowedDomains.includes(response.email.split("@")[1])
      ) {
        errorMessage = error_messages.form_email_domain_list;
      }

    if (errorMessage.length === 0) {
      if (!response.termAgree) {
        errorMessage =  error_messages?.form_accept_terms ? error_messages.form_accept_terms: "Please Agree Terms";
      }
    }
  }

  return errorMessage;
};

const validateMultiChoice = (element, response, form) => {
  let error = "";

  if (element.content.required) {
    if (
      response.value !== null &&
      !element.content.options.some((op) =>
        op.value.length > 0 ? response.value.includes(op.value) : true
      )
    ) {
      error = form.error_messages.form_single_choice;
    }
  }

  return error;
};

const validateRedirect = (element, response, form) => {
  let error = "";

  if (element.content.required && !Boolean(response.value)) {
    error = form.error_messages.form_number;
  }

  return error;
}

const validateSingleChoice = (element, response, form) => {
  let errorMessage = "";

  if (element.content.required && !Boolean(response.value)) {
    errorMessage = form.error_messages.form_single_choice;
  } else if (
    element.content.options.value !== null &&
    response.value !== null &&
    !element.content.options.some((op) =>
      op.value.length > 0 ? op.value === response.value : true
    )
  ) {
    errorMessage = form.error_messages.form_single_choice;
  }

  return errorMessage;
};

const validateDropdown = (element, response, form) => {
  return element.content.required && !Boolean(response.value)
    ? form.error_messages.form_dropdown
    : "";
};

const validatePhone = (element, response, form) => {
  return element.content.required && !Boolean(response.value)
    ? form.error_messages.form_phone
    : "";
};

const validateNumber = (element, response, form) => {
  let errorMessage = "";
  if (element.content.required && !Boolean(response.value)) {
    errorMessage = form.error_messages.form_number;
  }
  if (
    parseInt(response.value) < parseInt(element.content.min) ||
    parseInt(response.value) > parseInt(element.content.max)
  ) {
    errorMessage = form.error_messages.form_number_min_max;
  }

  return errorMessage;
};

const validateLongText = (element, response, form) => {
  let errorMessage = "";
  if (element.content.required && !Boolean(response?.value)) {
    errorMessage = form.error_messages.form_long_text;
  }

  if (
    element.content.max_char &&
    response.length > parseInt(element.content.max_char)
  ) {
    errorMessage = form.error_messages.form_long_text_max_chars;
  }

  if (
    element.content.validator &&
    !new RegExp(element.content.validator).test(response)
  ) {
    errorMessage = form.error_messages.form_long_text_validator;
  }

  return errorMessage;
};

const validateDate = (element, response, form) => {
  return element.content.required && !Boolean(response.value)
    ? form.error_messages.form_date
    : "";
};

const validateName = (element, response, form) => {
  return element.content.required &&
    (!Boolean(response.value) || !Boolean(response.last_name))
    ? form.error_messages.form_name
    : "";
};
