import { Button, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import { ChromePicker } from "react-color";
import "react-color-palette/lib/css/styles.css";
const ColorPickerStyle = ({ tag, id, onChange }) => {
  const [openTooltip, setOpen] = useState({
    font_color: false,
  });

  const copyToClipBoard = (value, element) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value).then((res) => {
        setOpen({
          ...openTooltip,
          [element]: true,
        });
      });
    }
  };
  function rgbToHex(rgb) {
    return (
      "#" +
      rgb
        .match(/[0-9|.]+/g)
        .map((x, i) =>
          i === 3
            ? parseInt(255 * parseFloat(x)).toString(16)
            : parseInt(x).toString(16)
        )
        .join("")
    );
  }
  function getHexString(tag) {
    // return (tag ? rgbToHex(tag).length > 7 ? rgbToHex(tag).charAt(rgbToHex(tag).length - 2) == 'f' && rgbToHex(tag).charAt(rgbToHex(tag).length - 1) == 'f' ? rgbToHex(tag).substring(0, rgbToHex(tag).length - 2) : rgbToHex(tag) : rgbToHex(tag) : "unset")
    return tag
      ? rgbToHex(tag).length > 7
        ? rgbToHex(tag).substring(0, rgbToHex(tag).length - 2)
        : rgbToHex(tag)
      : "unset";
  }
  const [uiColor, setColor] = useState("");

  const handleChange = (color, event) => {
    setColor(
      `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    );
  };

  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div className="form-input">
      <Tooltip title="Copied to clipboard" open={openTooltip.font_color}>
        <p
          onClick={() => {
            copyToClipBoard(getHexString(tag), "font_color");
          }}
          onMouseLeave={() => {
            setOpen({ ...openTooltip, font_color: false });
          }}
        >
          {tag ? getHexString(tag) : "unset"}
        </p>
      </Tooltip>

      <Button
        className="input-color-button-container"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <span
          className="input-color-button"
          style={{ backgroundColor: tag }}
        ></span>
      </Button>
      <Overlay
        onHide={() => setShow(false)}
        rootClose
        target={target.current}
        show={show}
        placement="left"
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              backgroundColor: "rgba(255, 100, 100, 0.85)",
              padding: "2px 10px",
              color: "white",
              borderRadius: 3,
              ...props.style,
            }}
          >
            <ChromePicker
              color={uiColor}
              onChange={handleChange}
              onChangeComplete={(e) => { 
                onChange(`rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`);
              }}
            />
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default ColorPickerStyle;
