import { Modal } from "react-bootstrap";
import FormifyButton from "./FromifyButton";
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

const UnsavedChanges = ({ setVisible, leaveForm }) => {
  return (
    <Modal show={ true } size="md" backdrop="static" centered className="discard-changes-modal">
      <Modal.Body>
       <div className="d-flex flex-column align-items-center">
        <span className="discard-icon">
          <PriorityHighRoundedIcon htmlColor="#FFF" fontSize="large" />
        </span>
        <h5 className="discard-title">Leaving the Formify builder ?</h5>
        <p className="discard-subtitle">
          Changes you made may not be saved.
          Please update your form to ensure you don't lose any progress.
        </p>
        <FormifyButton 
          classes="leave"
          title="Leave"
          onClick={ leaveForm }
        />
        <FormifyButton
          classes="closemodal" 
          title="Cancel"
          onClick={ () => setVisible(null) }
        />
       </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnsavedChanges;