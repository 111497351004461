/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUser, tokenConfig, updateUserRedux } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../utils";
import _, { random } from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";
import Lottie from "lottie-react";
import success from "../../../utils/lotties/thank-you.json";
import FormifyLoader from "../../utils/FormifyLoader";

import BillingModal from "./BillingModal";
import auth from "../../../reducers/auth";

const CancelPlanMessage = ({
  currentSubscription,
  getUser,
  updateUserRedux,
}) => {
  const nav = useNavigate();

  const [lowerPlan, setLowerPlan] = useState(null);

  const [currentPlan, setPlan] = useState(null);

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    showFeedbackInput: false,
    isFeedbackSucces: false,
  });
  const [cancelReason, setCancelReason] = useState("Does not meet my needs");

  const updateSubscription = async (price) => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_DEV_URL}/subscription/updateSubscription`,
        { price },
        tokenConfig(store.getState)
      );
      getUser();
      toast.success("Plan is changed");
      nav("/profile/billing");
    } catch (error) {
    }
    setLoading(false);
  };

  const cancelSubscription = async () => {
    setLoading(true);
    axios
      .delete(`${BASE_DEV_URL}/subscription/`, tokenConfig(store.getState))
      .then((res) => {
        if (res.data.success) {
          setFeedback({ showFeedbackInput: true, isFeedbackSucces: false });
          updateUserRedux(res.data.user);
          setLoading(false);
        }
      })
      .catch((err) => {
      });
  };

  const sendFeedback = async () => {
    setLoading(true);
    await axios
      .post(
        `${BASE_DEV_URL}/subscription/feedback`,
        { feedback: cancelReason },
        tokenConfig(store.getState)
      )
      .then((res) => {
        if (res.data.success) {
          setFeedback({ showFeedbackInput: true, isFeedbackSucces: true });
          setLoading(false);
        }
      })
      .catch((err) => {
      });
  };

  const cancelReasons = [
    "Does not meet my needs",
    "App is not performing well",
    "Not needed anymore",
    "I found a better app",
    "App is not worth the cost",
    "Other",
  ];

  return (
    <>
      {!feedback.showFeedbackInput ? (
        loading ? (
          <div className="col-12">
            <FormifyLoader removeLogo={true} text={"Please Wait ..."} />
          </div>
        ) : (
          <>
            <div className="cancel-wraper mt-4">
              <div className="pre-text">
                <h4>Have you finished looking?</h4>
                <p className="grey-p">
                  If you are sure you want to unsubscribe, you can end your
                  subscription below.
                </p>
              </div>
              <div className="formify-card  max-card-width">
                <div className="formify-card-header">
                  <h4 className="font-weight-600">You will be missing on...</h4>
                </div>
                <div className="cancel-benefits mt-3">
                  <ul>
                    <li>
                      <p>
                        A form flow that makes it easy for you to generate leads
                        to later convert to loyal customers
                      </p>
                    </li>
                    <li>
                      <p>
                        The money saved from getting acess to our entire library
                        of graphics, templates, and fonts at no extra cost
                      </p>
                    </li>
                    <li>
                      <p>
                        500 contacts and 5 unique forms hosted in your Formify
                        account
                      </p>
                    </li>
                    <li>
                      <p>
                        Integration capabilities with your preferred CRM, and
                        Ecommerce platform
                      </p>
                    </li>
                    <li>
                      <p>Access to the Formify basic support</p>
                    </li>
                  </ul>
                </div>
                <div className="cancel-period ms-2">
                  <p className="mt-3 grey-p" style={{ fontSize: "14px" }}>
                    Your subscription will end at the end of the current billing
                    period{" "}
                    {auth?.user?.transactions[0]
                      ? new Date(auth?.user?.transactions[0].date_to).toLocaleString()
                      : null}
                  </p>
                </div>

                <div className="btn-group d-flex flex-column">
                  <button
                    className="formify-btn-no-margin text-center"
                    onClick={() => {
                      nav("/profile/billing");
                    }}
                  >
                    <p className="cancel-btn-text">
                      Keep{" "}
                      {auth?.user?.transactions[0]
                        ? auth?.user?.transactions[0].currentSubscription
                        : null}
                    </p>
                  </button>
                  {/* {lowerPlan ? (
              <button
                className="neutral-btn text-center mt-2"
                onClick={() => {
                  setShow(true);
                }}
              >
                <p className="cancel-btn-text" style={{ color: "black" }}>
                  Downgrade to {lowerPlan.product.name}
                </p>
              </button>
            ) : null} */}

                  <button
                    className="danger-btn text-center mt-2"
                    onClick={() => {
                      cancelSubscription();
                    }}
                  >
                    <p className="cancel-btn-text"> Continue Cancellation</p>
                  </button>
                </div>
                {/* <BillingModal
            show={showModal}
            setShow={setShow}
            updateSub={updateSubscription}
            currentPlan={currentPlan}
            newPlan={lowerPlan}
            textContent={`If you downgrade, ${
              lowerPlan ? lowerPlan.product.name : null
            } will start
            immediately . The downgrade will generate a credit to be applied to
            the next invoice`}
            type="down"
          /> */}
              </div>
            </div>
          </>
        )
      ) : !feedback.isFeedbackSucces ? (
        <div className="cancel-wraper mt-4">
          <div className="formify-card  max-card-width">
            <h4>Subscription is canceled! </h4>
            <p>
              We value your opinion and would appreciate it if you could take a
              few moments to let us know why you decided to cancel your
              subscription. Whether it's an issue with our pricing, product
              features, or something else entirely, your feedback will help us
              make the necessary changes to improve your experience.
            </p>
            <p>Please pick a reason</p>
            <div className="mb-4">
              <Select
                style={{ width: "100%", padding: "0px" }}
                onChange={(e) => {
                  setCancelReason(e.target.value);
                }}
                sx={{
                  border: "1px solid #cecece",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                value={cancelReason}
              >
                {cancelReasons.map((i, j) => (
                  <MenuItem key={j} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <button
                className="formify-btn-no-margin text-center p-3"
                onClick={() => {
                  sendFeedback();
                }}
              >
                Send Feedback
              </button>
              <Link to="/profile/billing" className="mx-2">
                Skip
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="cancel-wraper mt-4">
          <div className="formify-card  max-card-width">
            <Lottie
              animationData={success}
              loop={true}
              style={{ height: 300 }}
            />
            <p>Dear valued customer,</p>
            <p>
              Thank you for taking the time to share your feedback with us. We
              appreciate your input and are committed to using it to improve our
              services and products.
            </p>
            <p>
              Your feedback is important to us and we take it seriously. We will
              review your comments carefully and use them to make any necessary
              changes and improvements.
            </p>
            <Link
              to="/profile/billing"
              className="formify-btn w-100 d-flex justify-content-center align-items-center"
            >
              Continue
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapAuthErrorsStateToProps, { getUser, updateUserRedux })(
  CancelPlanMessage
);
