import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { clearErrors, getErrors } from "../../../actions/errors";
import { usePrevious } from "../../../hooks/usePrevious";
import { mapAuthErrorsStateToProps, BASE_DEV_URL } from "../../../utils";
import { createSubblyProduct } from "../../../actions/integrations";
import ImageCropper from "../../form/settings-components/single-components/ImageCropper";
import MediaLibraryModal from "../../medialibrary/MediaLibraryModal";

const SubblyAddProductIntegration = ({
  auth,
  errors,
  createSubblyProduct,
  clearErrors,
}) => {
  const nav = useNavigate();
  const [crop, setCrop] = useState({});

  const [form, setForm] = useState({
    product_name: "",
    product_info: "",
    product_id: "",
    product_price: "",
    product_image_data: null,
    product_image: "",
    product_coupon:"",
  });

  const product_name = useRef(null);
  const product_id = useRef(null);
  const product_price = useRef(null);
  const product_info = useRef(null);
  const productImageBtnRef = useRef(null);
  const product_coupon = useRef(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSubblyProduct(form);
  };

  const prevAuth = usePrevious(auth.user);

  useEffect(() => {
    if (prevAuth) {
      if (auth.user.stores !== prevAuth.stores) {
        nav("/integrations/subbly");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.stores]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = () =>{
    setCrop({
      callback: imageCallback,
      hasIndex: false,
    });
  };

  const imageCallback =(key, url, index) => {
    setForm({
      ...form,
      product_image_data: key,
      product_image: url,
    });
  }
  return (
    <React.Fragment>
      <Overlay
        target={product_price.current}
        show={
          errors.errors && form.product_price.length === 0
            ? errors.errors["stores.subbly.products"].errors.product_price
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.products"].errors.product_price
                ? errors.errors["stores.subbly.products"].errors.product_price
                    .message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={productImageBtnRef.current}
        show={errors.errors && form.product_image_data == null ? true : false}
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.products"].errors.product_image
                ? errors.errors["stores.subbly.products"].errors.product_image
                    .message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={product_name.current}
        show={
          errors.errors && form.product_name.length === 0
            ? errors.errors["stores.subbly.products"].errors.product_name
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.products"].errors.product_name
                ? errors.errors["stores.subbly.products"].errors.product_name
                    .message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={product_info.current}
        show={
          errors.errors && form.product_info.length === 0
            ? errors.errors["stores.subbly.products"].errors.product_info
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.products"].errors.product_info
                ? errors.errors["stores.subbly.products"].errors.product_info
                    .message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={product_id.current}
        show={
          errors.errors && form.product_id.length === 0
            ? errors.errors["stores.subbly.products"].errors.product_id
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.products"].errors.product_id
                ? errors.errors["stores.subbly.products"].errors.product_id
                    .message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/subbly.png"}
              alt="Subbly"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>
      <div className="subbly-header col-lg-8">
        <div className="row mt-2 col-lg-12">
          <h4 className="integration-page-header">
            Connect your form to Subbly
          </h4>
          <p className="grey-paragraph">
            Start by providing Subbly access to Formify, and then create your
            main products.
            <span>
              <Link to="#">Details</Link>
            </span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mt-5">
          <div className="formify-card fixed-width">
            <h4 className="integration-page-header">Create your product</h4>
            <p className="grey-paragraph">
              Complete the following information to help us understand how you
              want   show your product on the website.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row add-subbly-product">
                  <div className="col-lg-5">
                    <div className="product-image-container">
                      <p className="grey-paragraph">Upload</p>
                      <button
                        className="upload-image"
                        type="button"
                        ref={productImageBtnRef}
                        onClick={() => uploadImage()}
                      >
                        <img
                          src={
                            form.product_image
                              ? form.product_image
                              : process.env.PUBLIC_URL +
                                "/assets/images/upload_image.svg"
                          }
                          style={{
                            width: "150px",
                          }}
                          alt="subbly"
                        />
                        <p className="grey-paragraph">Product Image</p>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="input-holder position-relative">
                      <label htmlFor="product_id">Product Name</label>
                      <input
                        type="text"
                        id="product_name"
                        name="product_name"
                        onChange={handleChange}
                        value={form.product_name || ""}
                        placeholder="123456789"
                        ref={product_name}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="product_id">Product ID</label>
                      <input
                        type="text"
                        id="product_id"
                        name="product_id"
                        onChange={handleChange}
                        value={form.product_id || ""}
                        placeholder="123456789"
                        ref={product_id}
                      />
                      {errors.errors
                        ? errors.errors["stores.subbly.product_id"]
                          ? errors.errors["stores.subbly.product_id"].message
                          : ""
                        : ""}
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="product_price">Product Price</label>
                      <input
                        type="number"
                        step=".01"
                        id="product_price"
                        name="product_price"
                        onChange={handleChange}
                        value={form.product_price || ""}
                        placeholder="123456789"
                        ref={product_price}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="product_id">Coupon Code</label>
                      <input
                        type="text"
                        id="product_coupon"
                        name="product_coupon"
                        onChange={handleChange}
                        value={form.product_coupon || ""}
                        placeholder="123456789"
                        ref={product_coupon}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="product_info">Product Description</label>
                      <textarea
                        rows={5}
                        id="product_info"
                        name="product_info"
                        onChange={handleChange}
                        value={form.product_info || ""}
                        placeholder="123456789"
                        ref={product_info}
                      />
                    </div>
                    <button type="submit" className="formify-btn">
                      Create Product
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {Object.keys(crop).length !== 0 && (
        <MediaLibraryModal data={crop} setData={setCrop} />
      )}
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  createSubblyProduct,
  clearErrors,
})(SubblyAddProductIntegration);
