import { useState, useRef, useEffect } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";
import GoogleLogin from "react-google-login";
import { mapAuthErrorsStateToProps } from "../../utils";
import { clearErrors } from "../../actions/errors";
import { googleAuth, registerUser } from "../../actions/auth";
import { connect } from "react-redux";
import store from "../../store";
import { REMOVE_REGISTER_SIGNAL } from "../../actions/types";

const Register = ({ errors, auth, clearErrors, googleAuth, registerUser }) => {
  const nav = useNavigate();
  const location = useLocation();

  const [search] = useSearchParams();
  const [form, setForm] = useState({
    email: search.get("email") ? search.get("email") : "",
    password: "",
    iAgree: false,
    iAccept: false,
    emailErrors: false,
    passwordErros: false,
    showIAgree: false,
    showIAccept: false,
  });

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const iAgree = useRef(null);
  const iAccept = useRef(null);
  const handleSubmit = (e) => {
    let shopifyInformation = localStorage.getItem("shopInfo")
    let concatedString = ''
    if(shopifyInformation){
      shopifyInformation = JSON.parse(shopifyInformation)
      for (const [key, value] of Object.entries(shopifyInformation)) {
        concatedString += key
        concatedString += "="
        concatedString += value
        concatedString += "&"
      }
    }

    e.preventDefault();
    if (form.iAgree && form.iAccept) {
      var shop_url_localstorage = localStorage.getItem("shop_url_localstorage")
      setForm({ ...form, showIAgree: false, showIAccept: false });
      localStorage.getItem("shop_url_localstorage")
       ? 
      registerUser({ email: form.email, password: form.password, register_type: 'shopify', stores: {shopify: { shop_url :shop_url_localstorage}}, checkout_price_id: search.get("checkout_price_id") ? search.get("checkout_price_id") : null, register_url : concatedString }) 
      :
      registerUser({ email: form.email, password: form.password,register_type: 'stripe', checkout_price_id: search.get("checkout_price_id") ? search.get("checkout_price_id") : null }) 
    } else if(!form.iAgree) {
      setForm({ ...form, showIAgree: true });
    } else if (!form.iAccept){
      setForm({ ...form, showIAgree: false, showIAccept: true });
    }
  };

  const setCheckbox = (e) => {
    setForm({
      ...form,
      [e.target.id]: !form[e.target.id],
    });
  };

  useEffect(() => {
    if (auth.isAuthenticated) nav("/");

    if (auth.userRegistered) clearErrors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
      store.dispatch({ type: REMOVE_REGISTER_SIGNAL });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(errors.errors)) {
      setForm({
        ...form,
        passwordErros: !_.isEmpty(errors.errors.password),
        emailErrors: !_.isEmpty(errors.errors.email),
      });
    } else {
      setForm({
        ...form,
        passwordErros: false,
        emailErrors: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };
  const googleSuccess = (response) => {
    googleAuth(response);
  };

  return !auth.isLoading ? (
    <div className="container login-wraper register mt-5 mb-5 ">
      <div className="row justify-content-center g-0">
        <div className="col-lg-8 login-form-wraper  px-4 py-5">
          <div className="login-header">
            <img
              src={process.env.PUBLIC_URL + "assets/images/formify_logo.svg"}
              alt="formify-logo"
              className="img-fluid"
            />
            <p style={{ marginLeft: "0" }}>
              Create an immersive, and personalized checkout experience for your
              users.
            </p>
          </div>
          <Overlay
            target={iAgree.current}
            show={form.showIAgree}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                You need to agree with terms of service to sign up
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={iAccept.current}
            show={form.showIAccept}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                You need to accept Formify usage of data
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={emailRef.current}
            show={form.emailErrors}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {!_.isEmpty(errors) && errors.errors.email
                  ? errors.errors.email.message
                  : ""}
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={passwordRef.current}
            show={form.passwordErros}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {!_.isEmpty(errors) && errors.errors.password
                  ? errors.errors.password.message
                  : ""}
              </Tooltip>
            )}
          </Overlay>
          {auth.userRegistered ? (
            <p className="after-register" style={{ marginLeft: "0" }}>
              We sent an email to <span> {form.email}</span>. Check your inbox
              to activate your account
            </p>
          ) : (
            <form
              className="login-form mt-2"
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <div className="input-holder position-relative">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  value={form.email || ""}
                  onChange={handleChange}
                  name="email"
                  placeholder="email@business.com"
                />
              </div>

              <div className="input-holder">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  ref={passwordRef}
                  value={form.password || ""}
                  onChange={handleChange}
                  name="password"
                  placeholder="Min. 8 characters, one UPPERCASE"
                />
              </div>
              <div className="terms-holder mt-2">
                <input
                  className="form-check-input"
                  checked={form.iAgree}
                  onChange={setCheckbox}
                  type="checkbox"
                  id="iAgree"
                />
                <label htmlFor="iAgree" ref={iAgree}>
                  I agree to Formify’s Terms of Service
                </label>
              </div>
              <div className="terms-holder mt-2 d-flex">
                <input
                  className="form-check-input "
                  type="checkbox"
                  checked={form.iAccept}
                  onChange={setCheckbox}
                  id="iAccept"
                />
                <label htmlFor="iAccept" ref={iAccept}>
                  I accept Formify's use of my data for the service and
                  everything else described in the Privacy Policy and Data
                  Processing Agreement
                </label>
              </div>
              <div className="submit-btn">
                <button>Sign up</button>
              </div>
              <div className="separator">OR</div>
              <div className="google-login">
                <GoogleLogin
                  clientId="381070749077-qrc9uad2k80aduq51co102e1f4gbr137.apps.googleusercontent.com"
                  onSuccess={googleSuccess}
                  onFailure={() => {
                  }}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div className="google-svg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Google__G__Logo.svg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="google-text ">
                        <p>Sign in with Google</p>
                      </div>
                    </button>
                  )}
                />
              </div>
            </form>
          )}
        </div>
        <div className="col-lg-4 signup-banner">
          <div className="text-container">
            <p className="banner-text">
              Formify empowers entrepreneurs to build sleek, beautifully
              designed signup flows for your online business. <br /> Using a
              sign up flow can dramatically increase positive results for your
              business in terms of sales, revenue and email collection.
            </p>
            <p className="banner-subtext">
              Founder at <span>BusterBox</span>
              <span className="avatar-circle">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Gary.png"}
                  alt="BusterBox Founder"
                />
              </span>
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "assets/images/signup-banner.png"}
            alt="diagonal"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8 login-form-wraper signup-wraper">
          <div className="signup-box">
            <p>Already have an account?</p>
            <Link to="/login">
              <button>Login </button>
            </Link>
          </div>
        </div>
        <div className="col-lg-4"></div>
      </div>
    </div>
  ) : null;
};

export default connect(mapAuthErrorsStateToProps, {
  googleAuth,
  clearErrors,
  registerUser,
})(Register);