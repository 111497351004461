import { useState } from "react";
import { ChevronLeft } from "react-feather";
import { Drawer } from "@mui/material";
import ContainerTabs from "../../ContainerTabs";
import ComponentOptions from "../options/ComponentOptions";
import SkipStepContent from "../content/SkipStepContent";
import SkipStepStyle from "../style/SkipStepStyle";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher, setFormStatesDispatcher } from "../../../../actions/form";

const SkipStepContainers = ({
  form: { form, formStates },
  setFormStatesDispatcher
}) => {
  const [active, setActive] = useState("content");

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar) }
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <ContainerTabs
          active={active}
          setActive={setActive}
          name={form.name}
          id={form._id}
        />
        {active === "content" ? (
          <SkipStepContent />
        ) : active === "style" ? (
          <SkipStepStyle />
        ) : active === "options" ? (
          <ComponentOptions />
        ) : null}
      </Drawer>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher, setFormStatesDispatcher })(SkipStepContainers);
