import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import { tokenConfig } from "../../actions/auth";
import { toast } from "react-toastify";
import { BASE_DEV_URL } from "../../utils";
import store from "../../store";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../utils/ConfirmModal";
import DuplicateModal from "../utils/DuplicateModal";

const FormContainer = (props) => {
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateIntent, setDuplicate] = useState(null);
  const [placeholder, setPlaceholder]=useState("")
  const [formName, setFormName] = useState('');
  const [show, setShow] = useState(false);
  const [deleteIntent, setDelete] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const nav = useNavigate();

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
 



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dropdownRef2.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(deleteIntent) setShow(true);
  }, [deleteIntent]) 
  useEffect(()=>{
    if(duplicateIntent) setShowDuplicate(true);

  },[duplicateIntent])

  const deleteForm = (id) => {
    axios
      .delete(`${BASE_DEV_URL}/form/${id}`, tokenConfig(store.getState))
      .then((response) => {
        removeForm(response.data.form._id);
        toast.success("Form was deleted");
      });
  };

  const removeForm = (id) => {
    const newForms = Array.from(props.forms);
    props.setForm(
      newForms.filter((form) => {
        return form._id !== id;
      })
    );
  };
  const duplicateForm = (id, formName) => {
    const widget = id;
    //GET FORM WITH SAME ID
    setDuplicate(id);
    setShowDuplicate(true);
    axios
      .get(`${BASE_DEV_URL}/form/${widget}`, tokenConfig(store.getState))
      .then((response) => {
        const form = response.data.form;
        const newSteps = form.steps.map(step => {
          delete step._id;
          const newElements = step.elements && step.elements?.length > 0 && step.elements.map(element => {
            delete element._id;
            return element;
          }) 
          return { ...step, elements: newElements ? newElements : [] };
        })
        axios
          .post(`${BASE_DEV_URL}/form/create`, 
          {
            name: formName ? formName : form.name + "  Duplicated",
            steps: newSteps,
            error_messages: form.error_messages,
            connected_integrations : form.connected_integrations,
            integrations:form.integrations,
            form_settings: form.form_settings,
            store_selected:form.store_selected,
            form_style : form.form_style,
          }, tokenConfig(store.getState))
          .then((res) => {
            toast.success("Form duplicated successfully.");
            // nav(`/form/${res.data.form._id}/step/1`);
            const duplicatedForm = res.data.form;
            // Update the state with the duplicated form
            props.setForm((prevForms) => [...prevForms, duplicatedForm]);
          })
          .catch((err) => {
            toast.error(err.response.data.error.message);
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div key={props.index} className="single-form-container">
      <ConfirmModal
        show={show}
        contact={deleteIntent}
        setDel={setDelete}
        setShow={setShow}
        del={deleteForm}
        text='Are you sure you want to delete this form?'
      /> 
      <DuplicateModal
        showDuplicate={showDuplicate}
        contactDuplicate={duplicateIntent}
        setDuplicate={setDuplicate}
        setShowDuplicate={setShowDuplicate}
        duplicate={duplicateForm}
        formName={formName}
        setFormName={setFormName}
        placeholder={placeholder}
      />
      
      <div>
        <div className="form-gradient">
          <div className="form-options">
          <Link to={`/form/${props._id}/step/1`}>   
        <div className="gradient">
            <span className="ticket public">{props.status}</span>
            <h1>{truncate(props.name, 10)}</h1>
           
          </div>
          </Link>
          </div>

          <div className="more-options">
              <MoreHorizIcon
                onClick={() => setToggleDropdown(!toggleDropdown)}
                ref={dropdownRef2}
              />
              <div
                className={`custom-dropdown ${
                  toggleDropdown ? "open-form-dropdown" : ""
                }`}
                ref={dropdownRef}
              >
                <Link target="_blank" to={`/form/view/${props._id}`}>
                  View
                </Link>
                <Link to={`/form/${props._id}/step/1`}>Edit</Link>
                <p
                  onClick={() => {
                    setDuplicate(props._id);
                    setPlaceholder(props.name);
                  }}
                >
                  Duplicate
                </p>
                <p
                  onClick={() => {
                    setDelete(props._id);
                  }}
                >
                  Delete
                </p>
              </div>
            </div>
     
       
          <div className="form-desc">
            {props.responses.length > 0 ? (
              <Link to={`/form/responses/${props._id}`}>View Responses</Link>
            ) : (
              <p>No responses yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormContainer;
