import { useEffect, useState } from "react";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import axios from "axios";
import store from "../../store";
import FormSelect from "../utils/FormsSelect";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import Circle from "../loaders/Circle";

const FlowInsights = () => {
  const [data, setData] = useState([]);
  const [form, setForm] = useState([]);
  const [formName, setFormName] = useState([]);
  const [total, setTotal] = useState([]);
  const [sum, setSum] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLatest();
  }, []);

  useEffect(() => {
    if(form.length > 0 )getData(form);
  }, [form]);

  

  const getData = (id) => {
    axios
      .get(`${BASE_DEV_URL}/form/dropout/${id}`, tokenConfig(store.getState))
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        let total = 0;
        var newData = [];
        Object.entries(response.data.data).map(([key, value]) => {
          total = total + value.dropout;
          newData.push({
            name: "Step " + value.step,
            value: Math.round(value.dropout * 100),
          });
        });
        setSum(total);
        setData(newData);
        setFormName(response.data.data);
        setTotal(response.data.total);
      });
  };

  const getLatest = () => {
    axios
      .get(`${BASE_DEV_URL}/form/last`, tokenConfig(store.getState))
      .then((response) => {
        if(response.data.form){
          getData(response.data.form._id);
        }
      });
  };

  const COLORS = [
    "#6563FF",
    "#8180FF",
    "#9A99FF",
    "#CDCCFF",
    "#E6E5FF",
    "#F5F5FF",
  ];

  return (
    <div className="flow-insights">
      <h1>Flow insights</h1>
      <div className="two-stat">
      {!loading ?<><form>
          <ul>
            <FormSelect setForms={setForm} forms={form} />
          </ul>
        </form>
        <div className="responsive-pie">
          <ResponsiveContainer>
            <PieChart margin={{}}>
              {sum === 0 ? (
                <Pie data={[{ name: "No Data", value: 1 }]} fill="#eeeeee" />
              ) : (
                <Pie
                  data={data}
                  innerRadius="70%"
                  outerRadius="80%"
                  startAngle={90}
                  endAngle={450}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="value"
                  strokeWidth={0.5}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      strokeWidth={0.5}
                    />
                  ))}
                </Pie>
              )}
            </PieChart>
          </ResponsiveContainer>
          {data.length > 0 ? null : <p className="no-data-chart">No data</p>}
        </div>
        <div className="legend-container">
          {formName.map((form, index) => {
            return (
              <div className="flow-legend" key={form.step}>
                <div className="flow-no">
                  <p>Step {form.step}</p>
                  <p>{Math.round(form.dropout * total)} / {total}</p>
                </div>
                <div className="formProgressBar">
                  <div
                    className="formProgressBarFilled"
                    style={{ width: form.dropout * 100 + "%" }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div></> 
      : <Circle/>}
      </div>
      <p className="total-dropout">Total Dropout {total}</p>
    </div>
  );
};
export default FlowInsights;
