
import ContentLoader from 'react-content-loader'

const Circle = () => (
<ContentLoader viewBox="0 0 400 200" height={300} width = {"100%"}> 
    <circle cx="200" cy="80" r="70" />
    <rect x="0" y="170" rx="0" ry="0" width="100%" height="8" />
    <rect x="0" y="190" rx="0" ry="0" width="100%" height="8" />
</ContentLoader>
)
export default Circle;
