const BillingAddress = ({ auth, change, address }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="input-holder address">
            <label htmlFor="name">Name on Card</label>
            <input
              type="text"
              id="name"
              value={address.name || ""}
              onChange={change}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-holder address">
            <label htmlFor="line1">Address Line </label>
            <input
              type="text"
              id="line1"
              value={address.line1 || ""}
              onChange={change}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className="input-holder address">
            <label htmlFor="city">City </label>
            <input
              type="text"
              id="city"
              onChange={change}
              value={address.city || ""}
            />
          </div>
        </div>
        <div className="col">
          <div className="input-holder address">
            <label htmlFor="zip">Postal Code </label>
            <input
              type="text"
              id="zip"
              onChange={change}
              value={address.zip || ""}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAddress;
