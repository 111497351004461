import ContentLoader from "react-content-loader";

const List = () => (
  <ContentLoader viewBox="0 0 400 150" height={160} width={"100%"}>
    <rect x="5" y="15" rx="5" ry="5" width="100%" height="15" />
    <rect x="5" y="45" rx="5" ry="5" width="100%" height="15" />
    <rect x="5" y="75" rx="5" ry="5" width="100%" height="15" />
    <rect x="5" y="105" rx="5" ry="5" width="100%" height="15" />
  </ContentLoader>
);
export default List;
