/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "../single-components/InputField";
import SwitchChip from "../single-components/switchChip";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import WidthOptions from "../single-components/WidthOptions";
import AlignmentTabs from "../single-components/AlignmentTabs";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const PhoneContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  mergeWidget,
}) => {

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, url) => {
    handleSetWidgetDispatcher([`style.desktop.label.fontFamily`], [{ name: name, src: url }], widget, setWidgetDispatcher, true);
  };

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateFontSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateLabelColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateHelTextSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.helpText.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };
  const updateInputSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };
  const updateInputLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateHelpTextColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.helpText.color`,
        `style.tablet.helpText.color`,
        `style.mobile.helpText.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container font-family-picker">
          <FontFamilyPicker
            attribute={widget?.style?.desktop?.label}
            onChangeFont={updateLabelFont}
            id={"heading"}
          />
        </div>
        <div className="quick-action-container">
          <AlignmentTabs
            id={"textAlign"} 
            tag={getResponsiveStyle(widget?.style, responsive, "textAlign", null)}
            handleSelect={handleChangeAlign}
          />
        </div>
        <div className="quick-action-container">
          <WidthOptions
            handleSelect={updateInputSelectOptions} 
            tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
            id={"width"}
            name={"width"}
          />
        </div>
      </div>
      <hr />
      <InputField
        id={"title"}
        label={"Title"}
        onChange={handleChange}
        placeholder={"Title"}
        isTitle={true}
        type="text"
        value={widget.content.title || ""}
        mergeWidget={mergeWidget}
      />
      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateFontSize}
            value={checkFontSize(widget?.style, responsive, "label", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateLabelColor}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "label", "color")}

          />
        </div>
      </div>
      <hr />
      <InputField
        id={"placeholder"}
        label={"Placeholder"}
        onChange={handleChange}
        placeholder={"Placeholder"}
        type="text"
        value={widget.content.placeholder || ""}
      />
      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateInputSize}
            value={checkFontSize(widget?.style, responsive, "input", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateInputLabel}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "input", "color")}
          />
        </div>
      </div>
      <hr />
      <InputField
        id={"mask"}
        label={"Mask"}
        onChange={handleChange}
        placeholder={"Mask"}
        type="text"
        value={widget.content.mask || ""}
      />
      <p className="formify-sm-p">
        Restrict users to match the format you specify. Use '9' to express
        digits, use \ to esacpe special characters
      </p>
      <hr />

      <InputField
        id={"help_text"}
        label={"Help Text"}
        onChange={handleChange}
        placeholder={"Help Text"}
        type="text"
        value={widget.content.help_text || ""}
      />
      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateHelTextSize}
            value={checkFontSize(widget?.style, responsive, "helpText", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateHelpTextColor}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "helpText", "color")}
          />
        </div>
      </div>
      <hr />

      <div className="editable-field form-row mt-2">
        <p className="field-label">Required</p>
        <SwitchChip
          id="required"
          checked={widget.content.required || false}
          onChange={(val) => {
            changeCheckBtn(val, "required");
          }}
        />
      </div>
      <div className="editable-field form-row mt-2">
        <p className="field-label">Hide Label</p>
        <SwitchChip
          id="hide_label"
          checked={widget.content.hide_label || false}
          onChange={(val) => {
            changeCheckBtn(val, "hide_label");
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(PhoneContent);
