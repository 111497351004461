import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../../actions/errors";
import { connectShopify } from "../../../actions/integrations";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../utils";
import { shopifyClientId, shopifyRedirect } from "../../../constants";
import { v4 } from "uuid";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { useSearchParams } from "react-router-dom";
import { Check } from "@mui/icons-material";
import Select from "react-select";

const ShopifyIntegration = ({ auth, errors, connectShopify, clearErrors }) => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState({
    shop_url: auth.user
      ? auth.user.stores.shopify
        ? auth.user.stores.shopify.shop_url
        : ""
      : "",
  });
  const shopURL = useRef(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };
  const options = [
    {
      value: "recharge",
      label: "Recharge",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/icons/rechargeicon.svg",
    },
    {
      value: "subscriptions",
      label: "Other Subscription Apps",
      // imageSrc:
      //   process.env.PUBLIC_URL + "/assets/images/icons/no-image-available.svg",
    },
  
  ];
  const [selectedPlatform, setSelectedPlatform] = useState(
    JSON.parse(localStorage.getItem("selectedPlatform")) || null
  );
  
  const handlePlatformChange = (selectedOption) => {
    setSelectedPlatform(selectedOption);
  
    if (selectedOption.value === "subscriptions") {
      localStorage.setItem("selectedPlatform", JSON.stringify(selectedOption));
    } else {
      localStorage.removeItem("selectedPlatform");
    }
  };
  

  const [showEdit, setShowEdit] = useState(false);
  const [apiKey, setApiKey] = useState("");


  useEffect(() => {
    const shopName = searchParams.get("shop");
    if (shopName) {
      setForm({
        shop_url: shopName,
      });
      handleSubmit(null, shopName);
    }
  }, []);
  const handleApiKey = (e) => {
    setApiKey(e.target.value);
  };

//   const handlePlatformSubmit = async (e) => {
//     e?.preventDefault();

//     await axios.post(
//       `${BASE_DEV_URL}/store/shopify/recharge`,
//       {
//         api_key: apiKey,
//       },
//       tokenConfig(store.getState)
//     );
//       toast.success("Recharge connected successfully")

//   };

const [rechargeConn,setRechargeConn]=useState(auth.user?.stores?.shopify?.recharge_api_key ? true : false);
  const handlePlatformSubmit = async (e) => {
    e?.preventDefault();
  
    try {
      const response = await axios.post(
        `${BASE_DEV_URL}/store/shopify/recharge`,
        {
          api_key: apiKey,
        },
        tokenConfig(store.getState)
      );
  
      if (response.status === 201) {
        if (selectedPlatform?.value !== "subscriptions") {
          toast.success("Recharge connected successfully");
        }else{
          toast.success("Subscription products connected successfully");
        }
        setRechargeConn(true)

      } else {
        toast.error("Failed to connect Recharge");
      }
    } catch (error) {
      console.error("Error connecting Recharge:", error);
      toast.error("Failed to connect Recharge");
    }
  };
  

  const handleSubmit = async (e, shopName) => {
    try {
      e?.preventDefault();
      const uuid = v4();

      await axios.post(
        `${BASE_DEV_URL}/store/shopify/nonce`,
        { code: uuid },
        tokenConfig(store.getState)
      );

      window.open(
        `https://${
          shopName ? shopName : form.shop_url
        }/admin/oauth/authorize?client_id=${shopifyClientId}&scope=read_products&redirect_uri=${shopifyRedirect}&state=${uuid}&grant_options[]=value`,
        shopName ? "_self" : "_blank"
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };
  }, []);

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: "black",
      borderRadius: "8px",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
    }),
    optionLabel: (baseStyles) => ({
      ...baseStyles,
      marginLeft: "10px",
    }),
  };

  return (
    <React.Fragment>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/shopify.png"}
              alt="Shopify"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <h4 className="integration-page-header">
          Connect your form to Shopify
        </h4>
        <p className="grey-paragraph">
          Start by selecting the form you want to connect to your shopify store.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-5">
          <div className="formify-card shopify-card-integration">
            <h4 className="integration-page-header">
              {auth.user?.stores?.shopify?.shop_url && auth.user?.stores?.shopify?.access_token ? (
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/formifytick.svg"
                  }
                  alt=""
                  className="shopify-card-num"
                />
              ) : (
                <span className="card-num">1 </span>
              )}
              Install the Formify app
            </h4>
            <p className="grey-paragraph">
              Continue to the Shopify Appstore to download the official Formify
              app.
            </p>
            {auth.user?.stores?.shopify?.shop_url && auth.user?.stores?.shopify?.access_token  ? (
              ""
            ) : (
              <button className="formify-btn mt-3">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://apps.shopify.com/formify"
                >
                  Go to the App store
                </a>
              </button>
            )}
          </div>
          <div className="formify-card shopify-card-integration">
            <h4 className="integration-page-header">
              {auth.user?.stores?.shopify?.access_token ? (
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/formifytick.svg"
                  }
                  alt=""
                  className="shopify-card-num"
                />
              ) : (
                <span className="card-num">2 </span>
              )}
              Shopify Authenticaiton
            </h4>
            {auth.user?.stores?.shopify?.shop_url && auth.user?.stores?.shopify?.access_token  ? (
              <>
                <p className="grey-paragraph">
                  If you haven't done so already, please continue to the app
                  store to download the app.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="input-holder position-relative">
                          {/* <label htmlFor="shop_url">Shop Name</label> */}
                          <input
                            type="hidden"
                            id="shop_url"
                            name="shop_url"
                            onChange={handleChange}
                            value={form.shop_url || ""}
                            placeholder="exampleshop"
                            ref={shopURL}
                          />
                        </div>
                        {!auth.user?.stores?.shopify?.access_token ? (
                          <button type="submit" className="formify-btn mt-3">
                            Authenticate
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="formify-card shopify-card-integration">
            <h4 className="integration-page-header">
              {rechargeConn ? (
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/formifytick.svg"
                  }
                  alt=""
                  className="shopify-card-num"
                />
              ) : (
                <span className="card-num">3 </span>
              )}
              Connect Your Subscription Platform
            </h4>
            <p className="grey-paragraph" style={{ display: "flex" }}>
              Shopify users that utilize a subscription platform have to
              integrate that platform to Formify.
              {(auth.user?.stores?.shopify?.shop_url && rechargeConn) ? (
                <span
                  style={{ color: "#6563FF", cursor: "pointer" }}
                  onClick={() => setShowEdit(true)}
                >
                  Edit
                </span>
              ) : (
                ""
              )}
            </p>
            {auth.user?.stores?.shopify?.access_token && (showEdit || !rechargeConn) && (

              <>
                <form onSubmit={handlePlatformSubmit}>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="input-holder position-relative">
                          <Select
                            styles={customStyles}
                            options={options}
                            placeholder="Select a platform"
                            value={selectedPlatform}
                            onChange={handlePlatformChange}
                            getOptionLabel={(option) => (
                              <div>
                                <span style={{ marginLeft: "10px" }}>
                                  {option.label}
                                </span>
                                {
                                  option.imageSrc &&
                                  <img
                                  style={{ marginLeft: "10px" }}
                                  src={option.imageSrc }
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                                }
                               
                              </div>
                            )}
                            formatOptionLabel={({ label, imageSrc }) => (
                              <div>
                                <span style={{ marginLeft: "10px" }}>
                                  {label}
                                </span>
                                {
                                  imageSrc &&
                                <img
                                  style={{ marginLeft: "10px" }}
                                  src={imageSrc}
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                            }
                              </div>
                            )}
                          />
                        </div>
                        
                        {selectedPlatform?.value !== "subscriptions" && (
  <div className="input-holder position-relative">
    <label htmlFor="platformAPI">API Key</label>
    <input
      type="text"
      name="platformAPI"
      onChange={handleApiKey}
      style={{ margin: "0px" }}
      placeholder="ex. 7bda05ae28047fd0e2abc8c95e585175"
    ></input>
  </div>
)}

                        <button type="submit" className="formify-btn mt-3">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  connectShopify,
  clearErrors,
})(ShopifyIntegration);
