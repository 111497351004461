import axios from "axios";
import { BASE_DEV_URL } from "../../utils";
import store from "../../store";
import {  useRef, useState } from "react";
import {  Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenConfig } from "../../actions/auth";

const AddForm = (props) => {
  const [modalShow, setModalShow] = useState(false);

  //As square container
  if ((props.text).length > 0) {
    return <>
    <div
      className="square_add"
        onClick={() => {
          setModalShow(true);
    }}>
    <img
    src={
      process.env.PUBLIC_URL + "/assets/images/icons/plus_icon_purple.svg"
    }
    alt="no-data"/>
    {props.text}
    </div>
    <AddNewFormPopup
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}/>
    </>;
  }
  //As button
  return (
    <>
      <div
        className=" div-btn"
        onClick={() => {
          setModalShow(true);
        }}
      >
        Create your first signup flow
      </div>
      <AddNewFormPopup
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}/>
    </>
  );
};

function AddNewFormPopup(props) {
  const nav = useNavigate();

  const [form, setForm] = useState({
    name: "",
  });

  const createForm = (data) => {
    axios
      .post(`${BASE_DEV_URL}/form/create`, data, tokenConfig(store.getState))

      .then((res) => {
        nav(`/form/${res.data.form._id}/step/1`);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    createForm({ name: form.name });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const name = useRef(null);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
          <div>
            <h2>Form Name</h2>
            <p>Enter your signup flow name</p>
            <p></p>
          </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit} className="enter-form-name">
            <input
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              value={form.name}
              placeholder="Enter your forms name..."
              ref={name}
            />
            <button type="submit" className="formify-btn mt-3">
              Create Form!
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddForm;
