import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { Avatar } from "@mui/material";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logoutUser } from "../../actions/auth";
import { Link } from "react-router-dom";
import BillingMessage from "./billing/BillingMessage";
import { calculateResetDate } from "../utils/formFunctions";

const Navbar = ({ auth, logoutUser }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const [usage, setUsage] = useState({
    form: 0,
    responses: 0,
  });

  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/subscription/usage`, tokenConfig(store.getState))
      .then((res) => {
        setUsage({
          form: res.data.form_count,
          responses: res.data.response_count,
        });
      })
      .catch((err) => {
      });
  }, []);

  const registrationDate = new Date(auth.user.created_at);
  const resetDate = calculateResetDate(registrationDate);
  

  const renderTooltip = (props) => (
    <Tooltip id="responses-tooltip" {...props}>
      Responses will reset on {resetDate}
    </Tooltip>
  );
  const getWidth = (attr) => {

    switch (attr) {
      case "form":
        const perc =
          (parseInt(usage.form) /
            parseInt(auth.user?.transactions[0]?.account_type?.form)) *
          100;
        return `${perc}%`;

      case "responses":
        const resperc =
          (parseInt(usage.responses) /
            parseInt(auth.user?.transactions[0]?.account_type?.responses)) *
          100;
        return `${resperc}%`;

      default:
        break;
    }
  };
  return (
    <nav className="formify-top-header">
      <div className="logo-header">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
          alt="afsd"
          className="dashboard-logo"
        />
      </div>
      <div className="formify-container-header">
        <h1>Dashboard</h1>
        <div className="d-flex align-center">
          <ul className="top-list">
            <li><Link to="/">Dashboard</Link></li>
            <li><Link to="/form">Signup Flows</Link></li>
            <li><Link to="/contacts">Contacts</Link></li>
          </ul>
          <div className="profile-settings-dropdown">
            <Avatar
              className="avatar-click"
              src={auth.user ? auth.user.profile_picture : null}
              onClick={() => setToggleDropdown(!toggleDropdown)}
            />
            <ul
              className={`profile-header ${
                toggleDropdown ? "open-profile" : ""
              }`}
            >
              <li>
                <Link to="/profile">
                  <span className="settings-icon"></span>Settings
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  <span className="logout-icon"></span>Logout
                </Link>
              </li>
              <li className="mt-3">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <div className="responseCounter">
                    <div className="responseCounterHeader">
                      <p>Responses</p>
                      <span className="responseSubmissionCounter">
                        {usage.responses}/
                        {auth
                          ? auth.user?.transactions[0]?.account_type?.responses
                          : ""}
                      </span>
                    </div>
                    <div className="responseProgressBar">
                      <div
                        className="responseProgressBarFilled"
                        style={{ width: getWidth("responses") }}
                      ></div>
                    </div>
                  </div>
                </OverlayTrigger>
              </li>
              <li>
                <Link to="/profile/billing/plans" className="more-capacity">
                  Need more capacity?
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default connect(mapAuthErrorsStateToProps, { logoutUser })(Navbar);
