import { Dropdown, DropdownButton } from "react-bootstrap";
import { TEXT_VALIDATORS } from "../../../../utils";

const ValidatorDropdown = ({ tag, handleSelect, id }) => {
  const getValidatorTitle = (validatorRegex) => {
    var box = TEXT_VALIDATORS.filter((e) => e.re === validatorRegex);

    return box[0].name;
  }; 

  return (
    <div className="editable-field form-row  ">
      <p> Validator</p>
      <div className="widget-range-slider-container">
        <DropdownButton
          title={tag ? getValidatorTitle(tag) : "Alphanumeric"}
          id={id}
          name={id}
          onSelect={(e) => {
            handleSelect(e, { id: id });
          }}
          className="form-dropdown-select"
        >
          {TEXT_VALIDATORS.map((validator) => {
            return (
              <Dropdown.Item eventKey={validator.re}>
                {validator.name}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </div>
    </div>
  );
};

export default ValidatorDropdown;
