import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { currencies } from "../../../../constants";


const CurrencySelector = ({
    tag,
    handleSelect,
    id,
    name
}) => {
    const [value, setValue] = useState('');
 
        const currenciesArray = currencies.map((currency)=>{ 
            return <Dropdown.Item eventKey={`${currency.country} - ${currency.currency_code} - ${currency.symbol}`}>{currency.country} - {currency.currency_code}</Dropdown.Item>
        })


    return (
        <div className="editable-field"> 
            <div className="widget-range-slider-container">
                <DropdownButton
                    title={tag || "United States Dollar - USD"} 
                    name={name  }
                    onSelect={(e) => handleSelect(e)}
                    className="form-dropdown-select currency-selector"
                > 
                <Form.Control
                    autoFocus 
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                    {currenciesArray.filter(
                  (currency) => 
                    !value || currency.props.children.join(" ").toLowerCase().startsWith(value),
                )} 
                </DropdownButton>
            </div>
        </div>
    );
};

export default CurrencySelector;