import { useState } from "react";
import TypographyPopup from "../single-components/typographyPopup";
import { Accordion } from "react-bootstrap";
import Spacer from "../single-components/Spacer";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const IntroductionStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [headingMarginLockValues, setHeadingMarginLockValues] = useState(false);
  const [headingPaddingLockValues, setHeadingPaddingLockValues] = useState(false);
  const [subheadingMarginLockValues, setSubheadingMarginLockValues] = useState(false);
  const [subheadingPaddingLockValues, setSubheadingPaddingLockValues] = useState(false);

  const updateHeadingFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.heading.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateSubHeadingFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.subheading.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateHeadingSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.heading.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateSubHeadingSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.subheading.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateHeadingStyle = (e, type) => {
    handleSetWidgetDispatcher([`style.${responsive}.heading.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
  };
  
  const updateSubHeadingStyle = (e) => {
    handleSetWidgetDispatcher([`style.${responsive}.subheading.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher);
  };

  const changeSliderHeading = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.heading.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    
    if (e.target.id.substring(0, 6) === "margin") {
      if (headingMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.heading.marginBottom`,
            `style.${responsive}.heading.marginRight`,
            `style.${responsive}.heading.marginLeft`,
            `style.${responsive}.heading.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle()
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (headingPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.heading.paddingBottom`,
            `style.${responsive}.heading.paddingRight`,
            `style.${responsive}.heading.paddingLeft`,
            `style.${responsive}.heading.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle()
    } else updateSingle()
  };

  const updateSubHeadingSlider = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.subheading.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (subheadingMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.subheading.marginBottom`,
            `style.${responsive}.subheading.marginRight`,
            `style.${responsive}.subheading.marginLeft`,
            `style.${responsive}.subheading.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle()
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (subheadingPaddingLockValues){
        lockAllValues(e, 
          [
            `style.${responsive}.subheading.paddingBottom`,
            `style.${responsive}.subheading.paddingRight`,
            `style.${responsive}.subheading.paddingLeft`,
            `style.${responsive}.subheading.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle()
    } else updateSingle()
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <p className="field-heading widget-label">Heading</p>
        <TypographyPopup
          attribute={getResponsiveStyle(widget?.style, responsive, "heading", null)}
          onChangeFont={updateHeadingFont}
          updateSliderstyle={updateHeadingStyle}
          updateSelectstyle={updateHeadingSelectstyle}
          id={"heading"}
        />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer
                label={"Margin"}
                id={"margin"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "heading", "marginLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "heading", "marginRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "heading", "marginTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "heading", "marginBottom")}
                onChange={changeSliderHeading}
                setLockValue={setHeadingMarginLockValues}
                lockValue={headingMarginLockValues}
              />
              <Spacer
                label={"Padding"}
                id={"padding"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "heading", "paddingLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "heading", "paddingRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "heading", "paddingTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "heading", "paddingBottom")}
                onChange={changeSliderHeading}
                setLockValue={setHeadingPaddingLockValues}
                lockValue={headingPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <hr />
      <div className="editable-field">
        <p className="field-heading widget-label">Subheading</p>
        <TypographyPopup
          attribute={getResponsiveStyle(widget?.style, responsive, "subheading", null)}
          onChangeFont={updateSubHeadingFont}
          updateSliderstyle={updateSubHeadingStyle}
          updateSelectstyle={updateSubHeadingSelectstyle}
          id={"subheading"}
        />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer
                label={"Margin"}
                id={"margin"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "subheading", "marginLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "subheading", "marginRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "subheading", "marginTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "subheading", "marginBottom")}
                onChange={updateSubHeadingSlider}
                setLockValue={setSubheadingMarginLockValues}
                lockValue={subheadingMarginLockValues}
              />
              <Spacer
                label={"Padding"}
                id={"padding"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "subheading", "paddingLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "subheading", "paddingRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "subheading", "paddingTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "subheading", "paddingBottom")}
                onChange={updateSubHeadingSlider}
                setLockValue={setSubheadingPaddingLockValues}
                lockValue={subheadingPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <hr />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(IntroductionStyle); 
