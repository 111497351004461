import { CircularProgress } from "@mui/material";
import React from "react";
import { currencyCodeToSymbol } from "../../../utils";

const BillingInvoice = ({ invoices, loading }) => {
  const getParagraphColor = (status) => {
    switch (status) {
      case "paid":
        return "#39b394";
      case "open":
        return "#17a2b8";
      case "uncollectible":
        return "#fc5b3f";
      case "void":
        return "#0d6efd";
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="formify-card   var-width m-4">
        <div className="formify-card-header">
          <div className="payment-info-header">
            <h2>Invoices</h2>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-2">
            <CircularProgress />
          </div>
        ) : (
          <div className="invoices-container">
            <div className="invoice-headers">
              <div className="invoice-date">
                <p>Date</p>
              </div>
              <div className="invoice-status">
                <p>Status</p>
              </div>
              <div className="invoice-amount">
                <p>Amount</p>
              </div>

              <div className="invoice-download"></div>
            </div>
            <div className="invoices">
              {invoices.length > 0 ? (
                invoices.map((invoice) => {
                  return (
                    <div className="invoice" key={Math.random()}>
                      <div className="invoice-date">
                        <p>
                          {new Date(invoice.created * 1000).toLocaleString()}
                        </p>
                      </div>
                      <div className="invoice-status">
                        <p style={{ color: getParagraphColor(invoice.status) }}>
                          {invoice.status.toUpperCase()}
                        </p>
                      </div>
                      <div className="invoice-amount">
                        <p>
                          {currencyCodeToSymbol(invoice.currency)}
                          {parseFloat(invoice.total / 100).toFixed(2)}
                        </p>
                      </div>
                      <div className="invoice-download">
                        {invoice.invoice_pdf ? (
                          <a href={invoice.invoice_pdf}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/download.svg"
                              }
                              alt="export"
                            />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-payment-message">You have no invoices</div>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BillingInvoice;
