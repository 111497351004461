import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { integrateSalesforce } from "../../../actions/integrations";
import { mapAuthStateToProps } from "../../../utils";

const SalesforceIntegration = ({ integrateSalesforce, auth }) => {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");
    integrateSalesforce(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.user.crm.salesforce) {
      nav("/profile/integrations/all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return null;
};

export default connect(mapAuthStateToProps, { integrateSalesforce })(
  SalesforceIntegration
);
