import _ from "lodash";
import { useRef, useState, useEffect } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { changePassword } from "../../../actions/user";
import { clearErrors } from "../../../actions/errors";
const ChangePasswordForm = ({ errors, changePassword, clearErrors }) => {
  const [form, setForm] = useState({
    oldPass: {
      value: "",
      isValid: true,
    },
    newPass: {
      value: "",
      isValid: true,
    },
    cnewPass: {
      value: "",
      isValid: true,
    },
  });

  useEffect(() => {
    return () => {
      clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isEmpty(errors)) {
      setForm({
        oldPass: {
          value: "",
          isValid: true,
        },
        newPass: {
          value: "",
          isValid: true,
        },
        cnewPass: {
          value: "",
          isValid: true,
        },
      });
    }
  }, [errors]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: {
        value: e.target.value,
        isValid: Boolean(e.target.value),
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.newPass.value !== form.cnewPass.value) {
      setForm({
        ...form,
        newPass: {
          value: form.newPass.value,
          isValid: false,
        },
      });
    } else {
      setForm({
        oldPass: {
          value: form.oldPass.value,
          isValid: true,
        },
        newPass: {
          value: form.newPass.value,
          isValid: true,
        },
        cnewPass: {
          value: form.cnewPass.value,
          isValid: true,
        },
      });
      changePassword({
        new_password: form.newPass.value,
        old_password: form.oldPass.value,
      });
    }
  };

  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);

  return (
    <div className="formify-card m-4 pt-4">
      <div className="formify-header mb-4">
        <h4>Change your password</h4>
        <p>
          You'll be logged out after making the change. Use your new password to
          log back in.
        </p>
      </div>
      <form className="change-password" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-8">
            <div className="input-holder custom-margin-input">
              <Overlay
                target={oldPasswordRef.current}
                show={!_.isEmpty(errors) & Boolean(errors.old_pass)}
                placement="bottom"
              >
                {(props) => (
                  <Tooltip
                    id="login-error"
                    style={{ backgroundColor: "#FC5B3F" }}
                    {...props}
                  >
                    {errors
                      ? errors.old_pass
                        ? errors.old_pass.message
                        : ""
                      : ""}
                  </Tooltip>
                )}
              </Overlay>
              <Overlay
                target={oldPasswordRef.current}
                show={!form.oldPass.isValid}
                placement="bottom"
              >
                {(props) => (
                  <Tooltip
                    id="login-error"
                    style={{ backgroundColor: "#FC5B3F" }}
                    {...props}
                  >
                    Old password can't be blank
                  </Tooltip>
                )}
              </Overlay>
              <label htmlFor="password">Old Password</label>
              <input
                type="password"
                id="oldPass"
                value={form.oldPass.value || ""}
                onChange={handleChange}
                ref={oldPasswordRef}
                placeholder="Min. 8 characters, one UPPERCASE"
                name="oldPass"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Overlay
            target={newPasswordRef.current}
            show={!_.isEmpty(errors) & Boolean(errors.new_pass)}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {errors ? (errors.new_pass ? errors.new_pass.message : "") : ""}
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={newPasswordRef.current}
            show={!form.newPass.isValid}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                Password's don't match
              </Tooltip>
            )}
          </Overlay>
          <div className="col-lg-8">
            <div className="input-holder custom-margin-input">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                id="newPass"
                value={form.newPass.value || ""}
                onChange={handleChange}
                name="newPass"
                ref={newPasswordRef}
                placeholder="Min. 8 characters, one UPPERCASE"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="input-holder custom-margin-input">
              <label htmlFor="password">Confirm New Password</label>
              <input
                type="password"
                id="cnewPass"
                value={form.cnewPass.value || ""}
                onChange={handleChange}
                name="cnewPass"
                placeholder="Min. 8 characters, one UPPERCASE"
              />
            </div>
            <div className="submit-btn">
              <button className="formify-btn">Confirm Password</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  changePassword,
  clearErrors,
})(ChangePasswordForm);
