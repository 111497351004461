/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_DEV_URL, tableRenderObject } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { useParams } from "react-router-dom";

const ResponseDetail = () => {
  const params = useParams();

  const [response, setResponse] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${BASE_DEV_URL}/form/${params.id}/responses/${params.response}`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        if (res.data.type === "success") setResponse(res.data.data);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <div className="container-fluid mt-4 contact-list p-relative pl-20 pb-100">
      <h1 className="formify-h1">
        Submission #{response ? response.number : null}
      </h1>
      <div className="submission-card mt-3  white-bck">
        <div>
          <div>
            {response && response.response
              ? response.response.map((r) => {
                  return (
                    <>
                      <div className="col-lg-12 mb-2 d-column">
                        <p className="column">{r.element}: </p>
                        <p className="value">
                          {r.element === "Email" ? (
                            <a
                              href={`mailto:${tableRenderObject(r.value)}`}
                              className="email"
                            >
                              {tableRenderObject(r.value)}
                            </a>
                          ) : (
                            tableRenderObject(r.value)
                          )}
                        </p>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseDetail;
