import { useState } from "react";
import { ChevronLeft } from "react-feather";
import { Drawer } from "@mui/material";
import ContainerTabs from "../../ContainerTabs";
import LongTextContent from "../content/LongTextContent";
import LongTextStyle from "../style/LongTextStyle";
import ComponentOptions from "../options/ComponentOptions";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher, setFormStatesDispatcher } from "../../../../actions/form";

const LongTextContainers = ({
  form: { form, formStates },
  mergeWidget,
  setFormStatesDispatcher
}) => {
  const [active, setActive] = useState("content");

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <ContainerTabs
          active={active}
          setActive={setActive}
          name={form.name}
          id={form._id}
        />
        <div className="tab-content">
          {active === "content" ? (
            <div id="content-button-tabpane-content" className="tab-pane active">
              <LongTextContent mergeWidget={mergeWidget} />
            </div>
          ) : active === "style" ? (
            <div id="content-button-tabpane-style" className="tab-pane active">
              <LongTextStyle />
            </div>
          ) : active === "options" ? (
            <div id="content-button-tabpane-options" className="tab-pane active">
              <ComponentOptions />
            </div>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher, setFormStatesDispatcher })(LongTextContainers);
