import React from "react";
import Lottie from "react-lottie-player";
import loaderLeaf from "./formifyUpdatedLoader.json";
function AiLoader() {
  return (
    <div className="main-loader">
      {/* <img src={loaderLeaf} /> */}
      <Lottie
        loop
        animationData={loaderLeaf}
        play
        style={{ width: 100, height: "auto" , margin:"auto"}}
        />
        <p className="loading-ai-txt" style={{textAlign:"center", marginBottom: "0px !important" }}>Your superpowered copy is loading...</p>
        <p style={{fontSize:"14px", opacity:"0.5", textAlign:"center"}}><i>It may take up to 10 seconds</i></p>
    </div>
  );
}

export default AiLoader;