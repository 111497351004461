import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { ChevronDown, Search } from "react-feather";
import FormifyTable from "../dashboard/contacts/FormifyTable";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import ConfirmModal from "../utils/ConfirmModal";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const ContactList = () => {
  const [contacts, setContacts] = useState([]);

  const [search, setSearch] = useState("");

  const [source, setSource] = useState(null);

  const [forms, setForms] = useState([]);

  const [deleteIntent, setDelete] = useState(null);

  const [show, setShow] = useState(false);

  const data = useMemo(() => {
    if (search && contacts.length > 0) {
      return contacts.filter((contact) => {
        return (
          (contact.email && contact.email.includes(search)) ||
          (contact.first_name && contact.first_name.includes(search)) ||
          (contact.last_name && contact.last_name.includes(search)) ||
          (contact.phone_number && contact.phone_number.includes(search))
        );
      });
    } else {
      return [...contacts];
    }
  }, [contacts, search]);

  useEffect(() => {
    if (deleteIntent) {
      setShow(true);
    }
  }, [deleteIntent]);

  const deleteContact = async (id) => {
    try {
      const res = await axios.delete(
        `${BASE_DEV_URL}/contact/${id}`,
        tokenConfig(store.getState)
      );
      if (res.data.success) {

        setShow(false);
        setDelete(null);
        toast.success("Contact deleted");
        const { contact } = res.data;
        let tempContacts = Array.from(contacts);
        tempContacts = tempContacts.filter((con) => con._id !== contact._id);

        setContacts(tempContacts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
     
      {
        Header: "FirstName",
        accessor: "first_name",
      },
      {
        Header: "Lastname",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Created At",
        accessor: "created_at",
      },
    ],
    []
  );

  useEffect(() => {
    if (source) {
      axios
        .get(
          `${BASE_DEV_URL}/contact/form/${source._id}`,
          tokenConfig(store.getState)
        )
        .then((res) => {
          setContacts(res.data.contacts);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${BASE_DEV_URL}/contact`, tokenConfig(store.getState))
        .then((res) => {
          setContacts(res.data.contacts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [source]);

  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/form`, tokenConfig(store.getState))
      .then((res) => {
        setForms(res.data.forms);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="container-fluid mt-4 contact-list pos-relative pl-20 pb-100">
      <ConfirmModal
        show={show}
        contact={deleteIntent}
        setDel={setDelete}
        setShow={setShow}
        del={deleteContact}
      />
      <div className="row">
        <div className="col">
          <h1 className="formify-h1">Contacts</h1>
          <div className="dropdown">
            <button
              className="formify-round-btn btn-icon  dropdown-toggle formify-drp"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p>{source ? source.name : "All Signup Flows"}</p>
              <ChevronDown
                style={{
                  color: "white",
                  marginLeft: "5px",
                  width: "16px",
                  height: "16px",
                }}
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  className="dropdown-item"
                  href="#/"
                  onClick={() => {
                    setSource(null);
                  }}
                >
                  All forms
                </a>
              </li>
              {forms.length > 0
                ? forms.map((form) => {
                    return (
                      <li key={form._id}>
                        <a
                          className="dropdown-item"
                          href="#/"
                          onClick={() => {
                            setSource(form);
                          }}
                        >
                          {form.name}
                        </a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>

          <div className="table-manipulation   mt-5">
            <div className="search input-icon ">
              <input
                autoComplete="off"
                type="text"
                id="search"
                value={search || ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search..."
              />
              <Search
                style={{
                  width: "24px",
                  height: " 24px",
                  color: "#383838",
                }}
              />
            </div>

            <div className="filter">
              <div className="dropdown me-2">
                <button
                  className="formify-simple-btn btn-icon  dropdown-toggle formify-drp table-dropdown"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <p>Export</p>
                  <ChevronDown
                    style={{
                      color: "#29447A",
                      marginLeft: "5px",
                      width: "16px",
                      height: "16px",
                      border: "2px",
                    }}
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <CSVLink data={data} className="dropdown-item" filename="formify-contacts">
                      CSV
                    </CSVLink>
                  
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <FormifyTable columns={columns} data={data} setDelete={setDelete} />
        </div>
      </div>
    </div>
  );
};

export default ContactList;
