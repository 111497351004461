/* eslint-disable react-hooks/exhaustive-deps */

import { Accordion, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import BorderOptions from "../single-components/BorderOptions";
import RangeSlider from "../single-components/RangeSlider";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const DateStyle = ({ form: { widget, responsive }, setWidgetDispatcher}) => {
  const [key, setKey] = useState("default");
  const [inputMarginLockValues, setInputMarginLockValues] = useState(false);
  const [inputPaddingLockValues, setInputPaddingLockValues] = useState(false);
  const [modalMarginLockValues, setModalMarginLockValues] = useState(false);
  const [modalPaddingLockValues, setModalPaddingLockValues] = useState(false);
  const [inputBorderWidthLockValues, setInputBorderWidthLockValues] = useState(true);
  const [inputBorderRadiusLockValues, setInputBorderRadiusLockValues] = useState(true);
  const [modalBorderWidthLockValues, setModalBorderWidthLockValues] = useState(true);
  const [modalBorderRadiusLockValues, setModalBorderRadiusLockValues] = useState(true);

  const updateModalSelectInputs = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.modal.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateColorBackgroundModal = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.backgroundColor`,
        `style.tablet.modal.backgroundColor`,
        `style.mobile.modal.backgroundColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorBackgroundInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.backgroundColor`,
        `style.tablet.input.backgroundColor`,
        `style.mobile.input.backgroundColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.borderColor`,
        `style.tablet.modal.borderColor`,
        `style.mobile.modal.borderColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateInputBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.borderColor`,
        `style.tablet.input.borderColor`,
        `style.mobile.input.borderColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateInputFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.input.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateModalSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.modal.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (modalMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.modal.marginBottom`,
            `style.${responsive}.modal.marginRight`,
            `style.${responsive}.modal.marginLeft`,
            `style.${responsive}.modal.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (modalPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.modal.paddingBottom`,
            `style.${responsive}.modal.paddingRight`,
            `style.${responsive}.modal.paddingLeft`,
            `style.${responsive}.modal.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (modalBorderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.modal.borderLeftWidth`,
            `style.${responsive}.modal.borderTopWidth`,
            `style.${responsive}.modal.borderRightWidth`,
            `style.${responsive}.modal.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (modalBorderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.modal.borderTopLeftRadius`,
            `style.${responsive}.modal.borderTopRightRadius`,
            `style.${responsive}.modal.borderBottomRightRadius`,
            `style.${responsive}.modal.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateInputSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (inputMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.marginBottom`,
            `style.${responsive}.input.marginRight`,
            `style.${responsive}.input.marginLeft`,
            `style.${responsive}.input.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (inputPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.paddingBottom`,
            `style.${responsive}.input.paddingRight`,
            `style.${responsive}.input.paddingLeft`,
            `style.${responsive}.input.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    }else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (inputBorderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.borderLeftWidth`,
            `style.${responsive}.input.borderTopWidth`,
            `style.${responsive}.input.borderRightWidth`,
            `style.${responsive}.input.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (inputBorderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.borderTopLeftRadius`,
            `style.${responsive}.input.borderTopRightRadius`,
            `style.${responsive}.input.borderBottomRightRadius`,
            `style.${responsive}.input.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateColorInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorModal = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.color`,
        `style.tablet.modal.color`,
        `style.mobile.modal.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateIconColorInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.iconColor`,
        `style.tablet.input.iconColor`,
        `style.mobile.input.iconColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDayDefault = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.defaultNumberBackgroundColor`,
        `style.tablet.modal.defaultNumberBackgroundColor`,
        `style.mobile.modal.defaultNumberBackgroundColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDayHover = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.hoverNumberBackgroundColor`,
        `style.tablet.modal.hoverNumberBackgroundColor`,
        `style.mobile.modal.hoverNumberBackgroundColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDaySelected = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.activeNumberBackgroundColor`,
        `style.tablet.modal.activeNumberBackgroundColor`,
        `style.mobile.modal.activeNumberBackgroundColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDayDefaultText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.defaultColor`,
        `style.tablet.modal.defaultColor`,
        `style.mobile.modal.defaultColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDayHoverText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.hoverColor`,
        `style.tablet.modal.hoverColor`,
        `style.mobile.modal.hoverColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateModalColorInputDaySelectedText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.modal.activeColor`,
        `style.tablet.modal.activeColor`,
        `style.mobile.modal.activeColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <>
      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Input</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "input", null)}
                  onChangeFont={updateInputFont}
                  updateSliderstyle={updateInputSliderstyle}
                  updateSelectstyle={updateInputSelectstyle}
                  id={"input"}
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorInput}
                    id={"color"}
                    attribute={getResponsiveStyle(widget?.style, responsive, "input", "color")}
                  />
                </div>

                <hr />

                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundInput}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "input", "backgroundColor")}
                  />
                </div>
                <hr />
                <div className="editable-field form-row  ">
                  <p>Icon Color</p>
                  <ColorPickerStyle
                    onChange={updateIconColorInput}
                    id={"iconColor"}
                    tag={getResponsiveStyle(widget?.style, responsive, "input", "iconColor")}

                  />
                </div>
                <RangeSlider
                  label={"Icon Size"}
                  id={"iconSize"}
                  tag={getResponsiveStyle(widget?.style, responsive, "input", "iconSize")}
                  min={1}
                  max={200}
                  onChange={updateInputSliderstyle}
                />
                <hr />

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "input", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "input", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "input", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "input", "marginBottom")}
                        onChange={updateInputSliderstyle}
                        setLockValue={setInputMarginLockValues}
                        lockValue={inputMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "input", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "input", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "input", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "input", "paddingBottom")}
                        onChange={updateInputSliderstyle}
                        setLockValue={setInputPaddingLockValues}
                        lockValue={inputPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(widget?.style, responsive, "input", null)}
                  onChange={updateInputSliderstyle}
                  onChangeColor={updateInputBorderColor}
                  setBorderWidthLockValues={setInputBorderWidthLockValues}
                  setBorderRadiusLockValues={setInputBorderRadiusLockValues}
                  borderWidthLockValues={inputBorderWidthLockValues}
                  borderRadiusLockValues={inputBorderRadiusLockValues}
                  updateSelectOptions={updateInputSelectstyle}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Modal</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorModal}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "modal", "color")}
                  />
                </div>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "modal", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "modal", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "modal", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "modal", "marginBottom")}
                        onChange={updateModalSliderstyle}
                        setLockValue={setModalMarginLockValues}
                        lockValue={modalMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "modal", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "modal", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "modal", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "modal", "paddingBottom")}
                        onChange={updateModalSliderstyle}
                        setLockValue={setModalPaddingLockValues}
                        lockValue={modalPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <hr />
                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundModal}
                    id={"color"}
                    attribute={getResponsiveStyle(widget?.style, responsive, "modal", "backgroundColor")}
                  />
                </div>

                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(widget?.style, responsive, "modal", "modal")}
                  onChange={updateModalSliderstyle}
                  onChangeColor={updateModalBorderColor}
                  setBorderWidthLockValues={setModalBorderWidthLockValues}
                  setBorderRadiusLockValues={setModalBorderRadiusLockValues}
                  borderWidthLockValues={modalBorderWidthLockValues}
                  borderRadiusLockValues={modalBorderRadiusLockValues}
                  updateSelectOptions={updateModalSelectInputs}
                />

                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Dates
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="editable-field ">
                        <Tabs
                          defaultActiveKey="default"
                          id="default-button"
                          className="mb-3"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                        >
                          <Tab eventKey="default" title="Default">
                            <div className="editable-field form-row  ">
                              <p>Text</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDayDefaultText}
                                id={"defaultColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "defaultColor")}
                              />
                            </div>
                            <div className="editable-field form-row  ">
                              <p>Background</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDayDefault}
                                id={"defaultNumberBackgroundColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "defaultNumberBackgroundColor")}
                              />
                            </div>
                          </Tab>
                          <Tab eventKey="hover" title="Hover">
                            <div className="editable-field form-row  ">
                              <p>Text</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDayHoverText}
                                id={"hoverColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "hoverColor")}

                              />
                            </div>{" "}
                            <div className="editable-field form-row  ">
                              <p>Background</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDayHover}
                                id={"hoverNumberBackgroundColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "hoverNumberBackgroundColor")}
                              />
                            </div>
                          </Tab>
                          <Tab eventKey="selected" title="Selected">
                            <div className="editable-field form-row  ">
                              <p>Text</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDaySelectedText}
                                id={"activeColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "activeColor")}
                              />
                            </div>{" "}
                            <div className="editable-field form-row  ">
                              <p>Background</p>
                              <ColorPickerStyle
                                onChange={updateModalColorInputDaySelected}
                                id={"activeNumberBackgroundColor"}
                                tag={getResponsiveStyle(widget?.style, responsive, "modal", "activeNumberBackgroundColor")}
                              />
                            </div>
                          </Tab>
                        </Tabs>
                        <hr />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(DateStyle);
