import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import BigcommerceIntegration from "../dashboard/integration/BigcommerceIntegration";
import HubspotIntegration from "../dashboard/integration/HubspotIntegration";
import ShopifyIntegration from "../dashboard/integration/ShopifyIntegration";
import WoocommerceIntegration from "../dashboard/integration/WoocommerceIntegration";
import SubblyIntegration from "../dashboard/integration/SubblyIntegration";
import SubblyAddProductIntegration from "../dashboard/integration/SubblyAddProductIntegration";
import SubblyDashboardIntegration from "../dashboard/integration/SubblyDashboardIntegration";
import SubblyEditProductIntegration from "../dashboard/integration/SubblyEditProductIntegration";
import ActiveCampaignIntegration from "../dashboard/integration/ActiveCampaign";
import MailchimpIntegration from "../dashboard/integration/MailchimpIntegration";
import SimpleNavbar from "../dashboard/SimpleNavbar";
import RechargePayment from "../dashboard/integration/RechargePayment";
import ZapierIntegration from "../dashboard/integration/ZapierIntegration";
import auth from "../../reducers/auth";
import OnboardNavbar from "../dashboard/OnboardNavbar";
import { Icons } from "tabler-icons-react";
import Klaviyo from "../dashboard/integration/Klaviyo";

const OnboardIntegrations = ({allowHomePage, setAllowHomePage }) => {
  return (
    <React.Fragment>
      <OnboardNavbar />
      <div className="container integration-wraper profile ">
        <div className="onboards">
         
      
          <h2> Onboarding Integrations</h2>
          <div className="onboard-integrations container">
            <span>Step 1 of 2</span>
            <h3>Start by connecting your store!</h3>
            <p>Supercharge your workflow with tools you use every day.</p>
            <div className="onboard-cards">
              <div className="onboard-integration-card">
                <div className="integration-header">
                  <div className="integration-details mt-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/shopify.png`
                      }
                      className="onboard-img"
                      alt="sales"
                    />
                    <p>Shopify</p>
                  </div>
                </div>
                <div className="integration-body ">
                  <p>
                    Create a seamless experience between your Formify form and
                    Shopify account. One form, all your products connected in
                    one place.
                  </p>
                </div>
                <div className="integration-footer text-center mt-1 mb-1 ">
                  <Link
                    to="/integrations/shopify"
                    className="formify-link"
                    onClick={() => {
                      "";
                    }}
                  >
                    Connect
                  </Link>
                </div>
              </div>

              <div className="onboard-integration-card">
                <div className="integration-header">
                  <div className="integration-details mt-2">
                    <img
                      src={process.env.PUBLIC_URL + `/assets/images/subbly.png`}
                      alt="sales"
                      className="onboard-img"
                    />
                    <p>Subbly</p>
                  </div>
                </div>
                <div className="integration-body ">
                  <p>
                    Create a seamless experience between your Formify form and
                    Subbly account. One form, all your products connected in one
                    place.
                  </p>
                </div>
                <div className="integration-footer text-center mt-1 mb-1 ">
                  <Link
                    to="/integrations/subbly"
                    className="formify-link"
                    onClick={() => {
                      "";
                    }}
                  >
                    Connect
                  </Link>
                </div>
              </div>

              <div className="onboard-integration-card">
                <div className="integration-header">
                  <div className="integration-details mt-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/wocommerce.png`
                      }
                      className="onboard-img"
                      alt="sales"
                    />
                    <p>Woocommerce</p>
                  </div>
                </div>
                <div className="integration-body ">
                  <p>
                    Create a seamless experience between your Formify form and
                    Woocommerce account. One form, all your products connected
                    in one place.
                  </p>
                </div>
                <div className="integration-footer text-center mt-1 mb-1 ">
                  <Link
                    to="/integrations/woocommerce"
                    className="formify-link"
                    onClick={() => {
                      "";
                    }}
                  >
                    Connect
                  </Link>
                </div>
              </div>
            </div>
            <Link className="skip-onboarding"  onClick={() => setAllowHomePage(true)} to="/profile/billing/plans">
         Skip this step

          </Link>
          </div>
        </div>

        <Routes>
          <Route path="/shopify" element={<ShopifyIntegration />} />
          <Route path="/woocommerce" element={<WoocommerceIntegration />} />
          <Route path="/bigcommerce" element={<BigcommerceIntegration />} />
          <Route path="/subbly" element={<SubblyDashboardIntegration />} />
          <Route path="/subbly/store" element={<SubblyIntegration />} />
          <Route
            path="/subbly/add-product"
            element={<SubblyAddProductIntegration />}
          />
          <Route
            path="/subbly/edit-product/:productId"
            element={<SubblyEditProductIntegration />}
          />
          <Route
            path="/activecampaign"
            element={<ActiveCampaignIntegration />}
          />

          <Route path="/hubspot" element={<HubspotIntegration />} />
          <Route path="/mailchimp" element={<MailchimpIntegration />} />
          <Route path="/klaviyo" element={<Klaviyo />} />
          <Route path="/recharge" element={<RechargePayment />} />
          <Route path="/zapier" element={<ZapierIntegration />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default OnboardIntegrations;
