import { SET_LOADER } from "../actions/types";

const initialState = {
  isLoading: false,
  showBrand: false
};

const loader = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        isLoading: action.payload,
        showBrand: action.showBrand,

      };

    default:
      return state;
  }
};

export default loader;
