import { Check } from "react-feather";

const ThankYouPage = () => {
  return (
    <div className="container-fluid thank-you-page">
      <div
        className="row justify-content-center align-items-center "
        style={{ marginTop: "200px" }}
      >
        <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center">
          <Check
            style={{
              color: "#39B394",
              height: "130px",
              width: "130px",
              marginBottom: "15px",
            }}
          />
          <h1>Thank you!</h1>
          <p>Your form has been submitted.</p>
        </div>

        <div
          className="col-lg-12 d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "300px" }}
        >
          <p className="powered-by">Powered by:</p>
          <a href="https://getformify.com/">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
              alt="Formify Logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
