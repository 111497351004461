import _ from "lodash";
import { toast } from "react-toastify";
const bson = require("bson");

export const getNewClientId = () => {
  return new bson.ObjectId().toHexString();
};

export const deleteElementFromForm = (step, elementId) => {
  let element =
    step.elements[
      _.findIndex(step.elements, function (element) {
        return element._id === elementId;
      })
    ];

  if (element) {
    step.elements = _.filter(step.elements, function (element) {
      return element._id !== elementId;
    });

    step.elements.map((e) => {
      if (e.order > element.order) e.order -= 1;
      return e;
    });

    return step;
  }
};

export const formIntegrationsDeleteElement = (form, element) => {
  if (form.integrations) {
    let integrations = _.cloneDeep(form.integrations)._doc;

    _.forOwn(integrations, (value, key) => {
      integrations[key].fields = integrations[key].fields.filter((field) => {
        if (!_.isEmpty(field) && field.formify_field) {
          return !field.formify_field.equals(element);
        }
      });
    });

    return integrations;
  }
};

export const formHasProductWidget = (form) => {
  let exists = false;
  form.steps.forEach((s) => {
    s.elements.forEach((ele) => {
      if (ele.element_type === "form_product" || ele.element_type === 'form_dynamic_product') {
        exists = true;
        return;
      }
    });
  });

  return exists;
};

export const stepHasSkipStep = (form, step) => {
  let exists = false;
  form.steps[step - 1].elements.forEach((ele) => {
    if (ele.element_type === "form_skip_step") {
      exists = true;
      return;
    }
  });
  return exists;
};

export const isLastStep = (form, step) => {
  return step === form.steps.length;
};

export const formHasWidget = (form, widget) => {
  let exists = false;
  let id = null;
  form.steps.forEach((s) => {
    s.elements.forEach((ele) => {
      if (ele.element_type === widget) {
        exists = true;
        id = ele._id;
        return;
      }
    });
  });

  return [exists, id];
};

export const formHasWidgetLastPage = (form, step, element) => {
  if (formHasWidget(form, "form_redirect_choice")[0]) {
    const canContain = ["form_heading", "form_image", "form_paragraph"];
    if (step === form.steps.length && !canContain.includes(element.element_type)) {
      return true;
    }
    else return false;
  } else return false;
};

export const getFormProductWidget = (form) => {
  let step,
    element = null;

  form.steps.forEach((s) => {
    let found = false;
    s.elements.forEach((ele) => {
      if (ele.element_type === "form_product" || ele.element_type === 'form_dynamic_product') {
        step = s;
        element = ele;
        found = true;
        return;
      }
    });
    if (found) return;
  });

  return [step, element];
};

export const getUpsellWidget = (form) => {
  let step,
    element = null;

  form.steps.forEach((s) => {
    let found = false;
    s.elements.forEach((ele) => {
      if (ele.element_type === "form_upsell") {
        step = s;
        element = ele;
        found = true;
        return;
      }
    });
    if (found) return;
  });

  return [step, element];
};

export const pushElementStep = (step, newElement) => {
  step.elements = step.elements.map((element) => {
    if (element.order >= newElement.order) element.order += 1;

    return element;
  });

  step.elements.push(newElement);
  step.elements.sort((a, b) => a.order - b.order);
  return step;
};

export const getSpecificWidgetId = (name, step = 1, form) => {
  if (!_.isEmpty(form.steps)) {
    //In case Product Widget is in page no.3, upsell is by default in page no. 3+ .
    for (var index = step; index <= form.steps.length; index++) {
      var data = form.steps[index - 1].elements.find(function (element) {
        return element.name === name;
      });
      if (data) {
        return [data, index];
      }
    }
  } else {
    return null;
  }
};

// middleware
export const formBlockValidation = async (form) => {
  if (form?.form_settings?.status === "blocked") {
    toast.warning("Form is blocked please upgrade plan.");
    return false;
  }
  return true;
};

export const formHasStore = async (form) => {
  if (form.store_selected) {
    toast.warning(
      "Form has already a store connected. Disconnect the old form."
    );
    return false;
  }
  return true;
};

export const storeIsConnected = (auth, store, showMessage = true) => {
  if (_.isEmpty(auth.user.stores[store])) {
    if (showMessage) toast.warning("Store is not connected.");
    return false;
  }
  return true;
};

export const deleteProductElement = async (form) => {
  let [step, element] = getFormProductWidget(form);
  if (step && element) {
    step = deleteElementFromForm(step, element._id);
    return step;
  }
  return null;
};

export const deleteUpsellElement = async (form) => {
  let [step, element] = getUpsellWidget(form);
  if (step && element) {
    step = deleteElementFromForm(step, element._id);
    return step;
  }
  return null;
};

export const redirectSingleChoice = async (form) => {
  return false;
};

export const integrationAccountValidation = async (auth, crm) => {
  if (auth.user.transactions[0]?.account_type) {
    if (auth.user.transactions[0]?.account_type.crm.includes(crm)) return true;
    else
      toast.warning(
        "Your plan dosen't support this integration please upgrade"
      );
  } else toast.warning("You have no account type associated with your user");
  return false;
};
