import axios from "axios";
import { BASE_DEV_URL } from "../utils";
import { tokenConfig } from "./auth";
import { getErrors } from "./errors";
import { setLoader } from "./loader";
import { getMessages } from "./messages";
import { UPDATE_USER, CLEAR_ERRORS } from "./types";
import qs from "query-string";
import { shopifyClientId, shopifyRedirect } from "../constants";
import { v4 } from "uuid";
import { toast } from "react-toastify";

export const disconnectRecharge = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/store/recharge`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Recharge disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const rechargeCallback = (data) => (dispatch, getState) => {
  axios
    .post(
      `${BASE_DEV_URL}/store/recharge/callback`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Recharge connected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectRecharge = (data) => (dispatch, getState) => {
  axios
    .post(`${BASE_DEV_URL}/store/recharge/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectKlaviyo = (data) => (dispatch, getState) => {
  axios
    .post(`${BASE_DEV_URL}/crm/klaviyo/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Klaviyo connected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const disconnectKlaviyo = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/crm/klaviyo/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Klaviyo disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const integrateSalesforce = (code) => (dispatch, getState) => {
  axios
    .post(
      `${BASE_DEV_URL}/crm/salesforce/`,
      {
        code: code,
      },
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Salesforce added" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const disconnectSalesforce = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/crm/salesforce`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Salesforce disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectShopify = (data, searchParams) => (dispatch, getState) => {
  dispatch(setLoader(true));

  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post(
      `${BASE_DEV_URL}/store/shopify`,
      {},
      {
        params: searchParams,
        ...tokenConfig(getState),
      }
    )
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Shopify connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const addAppFromShopify = (
  auth,
  state,
  nav,
  setMessages,
  setLoading
) => {
  const qstr = state ? qs.parse(state?.requested_path) : null;
 
  if (auth.isAuthenticated) {
    if (qstr && qstr["host"] && qstr["/?hmac"]) {
      axios
        .get(`${BASE_DEV_URL}/shopify/verify/install${state.requested_path}`)
        .then((res) => {
          if(auth?.user?.stores?.shopify?.access_token){
            nav("/integrations/shopify?shop=" + res.data.shop + ".myshopify.com");
          }else{
            nav("/")
          }
        });
    } else {
      if (qstr && qstr["host"] && qstr["shop"])
        nav(state ? state?.requested_path + "&ignore=1" : "/");
      else nav(state ? state?.requested_path : "/");
    }
  } else {
    if (qstr && qstr["host"] && qstr["/?hmac"]) {
      axios
        .get(`${BASE_DEV_URL}/shopify/verify/install${state.requested_path}`)
        .then((res) => {
          window.open(
            `https://${
              res.data.shop
            }.myshopify.com/admin/oauth/authorize?client_id=${shopifyClientId}&scope=read_products&redirect_uri=${shopifyRedirect}&state=${v4()}&grant_options[]=value`,
            "_self"
          );
        });
    } else if (qstr && qstr["hmac"] && qstr["shop"] && qstr["state"]) {
      localStorage.setItem("shop_url_localstorage", qstr["shop"])
      localStorage.setItem("shopInfo", JSON.stringify(qstr))
      setMessages("To continue shopify integration please login or register!");
    }
  }
  setLoading(false);
};

export const disconnectShopify = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/store/shopify`, tokenConfig(getState))
    .then((res) => {
      
      if (res.data.success) {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
     
        dispatch(getMessages({ message: "Shopify disconnected" }));
      }else{
        toast.error("Please try again later!");
      }
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectWoocommerce = (data) => (dispatch, getState) => {
  // console.log("wooconnect")
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });
  axios
    .post(`${BASE_DEV_URL}/store/woocommerce`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "WooCommerce shop connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const disconnectWoocommerce = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/store/woocommerce`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "WooCommerce disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectHubspot = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });
  axios
    .post(`${BASE_DEV_URL}/crm/hubspot`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Hubspot connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const disconnectHubspot = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/crm/hubspot`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Hubspot disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectBigcommerce = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });
  axios
    .post(`${BASE_DEV_URL}/store/bigcommerce`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Bigcommerce connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const disconnectBigcommerce = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/store/bigcommerce`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Bigcommerce disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectActiveCampaign = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });
  axios
    .post(`${BASE_DEV_URL}/crm/activecampaign`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Active Campaign connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const disconnectActiveCampaign = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/crm/activecampaign`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Active Campaign disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectMailchimp = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });
  axios
    .post(`${BASE_DEV_URL}/crm/mailchimp`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Mailchimp connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const disconnectMailchimp = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/crm/mailchimp`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Mailchimp disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const connectSubbly = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_ERRORS,
  });

  const config = tokenConfig(getState);
  config.headers["Content-Type"] = "multipart/form-data";

  axios
    .post(`${BASE_DEV_URL}/store/subbly/store`, data, tokenConfig(getState))

    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Subbly connected" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const createSubblyProduct = (data) => (dispatch, getState) => {
  axios
    .post(
      `${BASE_DEV_URL}/store/subbly/new-product`,
      data,
      tokenConfig(getState)
    )

    .then((res) => {
      if (res.data.type === "success") {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
        dispatch(getMessages({ message: "Subbly Product Created" }));
        dispatch(setLoader(false));
      } else {
        toast.error(res.data.data);
        dispatch(setLoader(false));
      }
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const editSubblyProduct = (data, id) => (dispatch, getState) => {
  axios
    .put(
      `${BASE_DEV_URL}/store/subbly/edit-product/${id}`,
      data,
      tokenConfig(getState)
    )

    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Subbly Product Edited" }));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const disconnectSubbly = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/store/subbly/store`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Subbly disconnected" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const deleteSubblyProduct = () => (dispatch, getState) => {
  axios
    .delete(
      `${BASE_DEV_URL}/store/subbly/remove-product`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Subbly Product Removed" }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};
