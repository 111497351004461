import { Dropdown } from "react-bootstrap";
import FontFamilyPicker from "./FontFamilyPicker";
import FontDecoration from "./FontDecoration";
import FontWeight from "./FontWeight";
import RangeSlider from "./RangeSlider";

const TypographyPopup = ({
  attribute,
  onChangeFont,
  updateSelectstyle,
  updateSliderstyle,
  id,
  showFontFamily = false,
}) => {
  return (
    <div className="editable-field form-row">
      <p className="field-label">Typography</p>
      <Dropdown autoClose="outside">
        <Dropdown.Toggle className="typography-button">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icons/edit.svg"}
            alt="formify-logo"
            className="img-fluid"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="typography-dropdown">
          {showFontFamily ? (
            <FontFamilyPicker
              attribute={attribute}
              onChangeFont={onChangeFont}
              id={id}
            />
          ) : null}
          {showFontFamily ? (
            <RangeSlider
              label={"Size"}
              id={"fontSize"}
              tag={attribute?.fontSize}
              min={1}
              max={200}
              onChange={updateSliderstyle}
            />
          ) : null}
          <FontDecoration
            handleSelect={updateSelectstyle}
            tag={attribute?.textDecoration}
            id={"textDecoration"}
            name={"textDecoration"}
          />

          <FontWeight
            handleSelect={updateSelectstyle}
            tag={attribute?.fontWeight}
            id={"fontWeight"}
            name={"fontWeight"}
          />

          <RangeSlider
            label={"Line height"}
            id={"lineHeight"}
            tag={attribute?.lineHeight}
            min={0}
            max={100}
            onChange={updateSliderstyle}
          />

          <RangeSlider
            label={"Word Spacing"}
            id={"wordSpacing"}
            tag={attribute?.wordSpacing}
            min={0}
            max={100}
            onChange={updateSliderstyle}
          />

          <RangeSlider
            label={"Letter Spacing"}
            id={"letterSpacing"}
            tag={attribute?.letterSpacing}
            min={-5}
            max={10}
            onChange={updateSliderstyle}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TypographyPopup;
