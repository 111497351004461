import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const DropdownRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  // var questionText = handleDynamicTags(
  //   element.content.question_text,
  //   functions.getResponseVal
  // );
  var questionText = element.content.question_text;

  const css = element?.style?.[screen]?.input  && `
  .widget-${element._id}  option:checked {
    ${ getResponsiveStyle(element?.style, screen, "input", "hoverColor") && ` background-color:  ${getResponsiveStyle(element?.style, screen, "input", "hoverColor")}`};
  };${"}"}`;


  let alignment = getResponsiveStyle(element?.style, screen, "alignment", null);

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable
          ? editElement({
              ...element,
            })
          : null
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <style>{css}</style>
            <div className="container-fluid p-0">
              <div className="row">
                <div
                  className="col-lg-12 input-holder"
                  style={ (element.style, {alignItems: alignment === "left"  ? "start" : alignment === "right" ? "end": alignment, })} >
                  {!element.content.hide_label ? (
                    <h4
                      className="formify-h4"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "label"),
                        fontFamily: element.style?.desktop?.label?.fontFamily?.name
                      }}
                    >
                      {questionText}
                    </h4>
                  ) : null}
                  <select
                    value={
                      isEditable
                        ? element.content.default
                        : responses[element._id]?.value
                    }
                    onChange={(e) =>
                      !isEditable
                        ? setWidgetResponsesDispatcher(element._id, {
                            value: e.target.value,
                          }, "form_dropdown")
                        : null
                    }
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "input"),
                      extAlign: alignment,
                      fontFamily: element.style?.desktop?.label?.fontFamily?.name
                    }}
                  >
                    {element.content.options.map((opt) => {
                      return (
                        <option
                          value={opt}
                          style={{ textAlign: alignment }}
                        >
                          {opt}
                        </option>
                      );
                    })}
                  </select>
                  {!isEditable && responseError ? (
                    Object.keys(responseError).length > 0 ? (
                      <p className="error-message" style={{fontFamily: element.style?.desktop?.helpText?.fontFamily?.name }}>{responseError[element._id]}</p>
                    ) : null
                  ) : null}
                  <p
                    className="formify-sm-p"
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "helpText"),
                      textAlign: alignment,
                      fontFamily: element.style?.desktop?.label?.fontFamily?.name
                    }}>
                    {element.content.help_text}
                  </p>
                </div>
              </div>
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div> 
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(DropdownRender);
