import axios from "axios";
import { useEffect, useState } from "react";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { BASE_DEV_URL } from "../../utils";
import FormContainer from "../form/FormContainer";
import Youtube from "../loaders/Loaders";
import NoForm from "../utils/NoData";
import AddForm from "../utils/AddForm";

const FormList = () => {
  const [loading, setLoading] = useState(true);
  const [publicForm, setPublicForm] = useState([]);
  const [privateForm, setPrivateForm] = useState([]);
  const [draftForms, setDraftForm] = useState([]);

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`${BASE_DEV_URL}/form/`, tokenConfig(store.getState))
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        var publicForms = [];
        var privateForms = [];
        var draftForm = [];
        response.data.forms.map((el) => {
          if (isMounted) {
            switch (el.form_settings.status) {
              case "private":
                privateForms.push(el);
                break;
              case "public":
                publicForms.push(el);
                break;
              case "draft":
                draftForm.push(el);
                break;
              default:
                draftForm.push(el);
            }
          }
        });
        setPublicForm(publicForms);
        setPrivateForm(privateForms);
        setDraftForm(draftForm);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="container-fluid mt-4 contact-list p-relative pl-20 pb-100">
      {loading ? (
        <div className="form-list">
          <Youtube />
        </div>
      ) : publicForm.length > 0 ||
        privateForm.length > 0 ||
        draftForms.length > 0 ? (
        <>
          {publicForm.length > 0 ? (
            <div className="pb-5 ">
              <h1 className="title-form">Published Signup Flows</h1>
              <div className="form-list pe-5">
                {publicForm.map((form, index) => {
                  return (
                    <FormContainer
                      key={form._id}
                      name={form.name}
                      _id={form._id}
                      status={form.form_settings.status}
                      index={index}
                      responses = {form.responses}
                      forms={publicForm}
                      setForm={setPublicForm}
                    />
                  );
                })}
                <AddForm text="Add Signup Flow" />
              </div>
            </div>
          ) : null}
          {privateForm.length > 0 ? (
            <div className="pb-5 private-forms">
              <h1 className="title-form">Private Signup Flows</h1>
              <div className="form-list pe-5">
                {privateForm.map((form, index) => {
                  return (
                    <FormContainer
                      key={form._id}
                      name={form.name}
                      _id={form._id}
                      status={form.form_settings.status}
                      index={index}
                      responses = {form.responses}
                      forms={publicForm}
                      setForm={setPublicForm}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {draftForms.length > 0 ? (
            <div className="pb-5 private-forms">
              <h1 className="title-form">Unpublished forms</h1>
              <div className="form-list pe-5">
                {draftForms.map((form, index) => {
                  return (
                    <FormContainer
                      key={form._id}
                      name={form.name}
                      _id={form._id}
                      status={form.form_settings.status}
                      index={index}
                      responses = {form.responses}
                      forms={publicForm}
                      setForm={setPublicForm}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <NoForm text="" />
      )}
    </div>
  );
};

export default FormList;
