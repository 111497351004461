/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { ArrowRight } from "react-feather";
import CurrencySelector from "../settings-components/single-components/CurrencySelector";
import Subbly from "./Subbly";
import { connectStore, disconnectStore } from '../../utils/formFunctions'
import { mapAuthAndFormStateToProps } from "../../../utils";
import { connect } from "react-redux";
import { handleSetFormDispatcher } from '../../utils/handleDispatcher';
import { setFormDispatcher } from '../../../actions/form';

const stores = {
  woocommerce: {
    icon: process.env.PUBLIC_URL + "/assets/images/wocommerce.png",
    name: "WooCommerce",
  },
  shopify: {
    icon: process.env.PUBLIC_URL + "/assets/images/shopify.png",
    name: "Shopify",
  },
  subbly: {
    icon: process.env.PUBLIC_URL + "/assets/images/subbly.png",
    name: "Subbly",
  },
  bigcommerce: {
    icon: process.env.PUBLIC_URL + "/assets/images/bigcommerce.png",
    name: "Big Commerce",
  },
  recharge: {
    icon: process.env.PUBLIC_URL + "/assets/images/recharge.png",
    name: "Recharge",
  },
}

const Store = ({ auth, form: { form }, store, connectStore, disconnectStore, setFormDispatcher }) => {
  const isConnected = useMemo(() => {
    return form ? form.store_selected === store : false
  }, [form]) 

  const handleCurrencyChange=(e) =>{
    var splittedValue= e.split('-');
    var name =`${splittedValue[0]} - ${splittedValue[1]}`;
    var symbol = splittedValue[2];

    handleSetFormDispatcher([`currency_name`, `currency_symbol`], [name, symbol], form, setFormDispatcher);
  }

  return (
    stores[store].name === "Subbly" && isConnected ? (
      <Subbly /> 
    ) : (
      <div className="container-fluid integraiton mt-5">
        <div className="row justify-content-center">
          <div className="col-12  integraiton-card ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <div className="row">
                    <div className="integration-header col-6">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                      alt="Formify Logo"
                      className="img-fluid me-2"
                    />
                    <ArrowRight
                      className="me-2"
                      style={{ color: "#6563FF", width: "20px", height: "20px" }}
                    />
                    <img
                      src={stores[store].icon}
                      alt="Mailchimp"
                      className="img-fluid"
                      style={{ maxHeight: "30px" }}
                    />
                    </div>
                    <div className="col-6">
                      {" "}
                      <CurrencySelector tag={form.currency_name} handleSelect={handleCurrencyChange} />{" "}
                    </div>
                  </div> 
                  <div className="col-12 integration-body mt-4">
                    {isConnected ? (
                      <>
                        <h4 className="formify-h4">
                          Integration is up and running!
                        </h4>
                        <p className="formify-sm-p">
                          You have successfully created your integration.
                        </p>
                      </>
                    ) : (
                      <>
                        <h4 className="formify-h4">
                          {stores[store].name} Configuration
                        </h4>
                        <p className="formify-sm-p">
                          Give {stores[store].name} access to your form in order
                          to import products to Formify.
                        </p>
                        <button
                          className="formify-btn-no-margin mt-3"
                          onClick={() => connectStore(store, auth, form) }
                        >Connect Shop</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {isConnected ? (
            <div
              className="col-12 d-flex justify-content-between align-items-center mt-3 "
              style={{ maxWidth: "700px" }}
            >
              <div>
                <h4 className="formify-h4">Disconnect {stores[store].name}</h4>
                <p className="formify-sm-p">
                  Once disconnected, all previous changes will be deleted!
                </p>
              </div>
              <button onClick={ disconnectStore } className="delete-btn">
                Disconnect
              </button>
            </div>
          ) : null}
        </div>
      </div> 
    )
  );
};

export default connect(mapAuthAndFormStateToProps, { connectStore, disconnectStore, setFormDispatcher })(Store);