import { Image } from "react-feather";
import AlignmentTabs from "../single-components/AlignmentTabs";
import RangeSlider from "../single-components/RangeSlider";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const ImageContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  setCrop,
}) => {
  const handleFileChange = () => {
    setCrop({
      callback: updateElement,
      hasIndex: false,
    });
  };

  const updateSliderstyle = (e, type) => {
    handleSetWidgetDispatcher([`style.${responsive}.${e.target.id}`], [e.target.value + "%"], widget, setWidgetDispatcher, false, type);
  };

  const updateElement = (key, url) => {
    handleSetWidgetDispatcher([`content.key`, `content.src`], [key, url], widget, setWidgetDispatcher);
  };

  const handleContentChange = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  return (
    <div className="editable-field settings-field-container">
      <div className="row">
        <div className="col-lg-12">
          <p className="field-heading mb-0">Image</p>
          {widget ? (
            widget.content.src ? (
              <div className="image-container ">
                <img
                  src={ widget.content.src }
                  className="img-fluid"
                  alt="Form"
                />
                <div className="d-flex">
                  <div className="w-50">
                    <button
                      className="upload-btn mt-2"
                      type="button"
                      onClick={ () => handleFileChange() }
                    >
                      <p className="m-0 text-center">Change image</p>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="image-container d-flex  flex-column align-items-center">
                <div className="upload w-100">
                  <button
                    type="button"
                    onClick={ () => handleFileChange() }
                  >
                    <Image
                      style={{
                        backgroundColor: "#6563FF",
                        padding: "4px",
                        borderRadius: "7px",
                        color: "white",
                        height: "32px",
                        width: "32px",
                      }}
                    />
                    <p className="m-0 text-center">Select image</p>
                  </button>
                </div>
              </div>
            )
          ) : null}
        </div>
        <hr />
        <div className="quick-action-container">
          <AlignmentTabs
            id={"align"}
            tag={getResponsiveStyle(widget?.style, responsive, "align", null)}
            handleSelect={handleContentChange}
          />
        </div>
        <hr />
        <RangeSlider
          label={"Image Resize"}
          id={"width"}
          tag={getResponsiveStyle(widget?.style, responsive, "width", null)}
          min={0}
          max={100}
          onChange={updateSliderstyle}
          isPercentage={true} 
        />
        <hr />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(ImageContent);
