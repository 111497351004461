import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mapAuthStateToProps } from "../../../utils";
import { connectMailchimp } from "../../../actions/integrations";

const MailChimpOauth = ({ auth, connectMailchimp }) => {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");
    connectMailchimp({ code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.user.crm.mailchimp) {
      nav("/profile/integrations/all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return null;
};

export default connect(mapAuthStateToProps, { connectMailchimp })(
  MailChimpOauth
);
