import { useState, useEffect, useMemo } from "react";
import { ArrowRight } from "react-feather";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  BASE_DEV_URL,
  formGetCrmFields,
  integrationFooter,
} from "../../../utils";
import CurrencySelector from "../settings-components/single-components/CurrencySelector";
import { disconnectStore, connectStore } from "../../utils/formFunctions";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setFormDispatcher } from "../../../actions/form";
import {
  handleSetForm,
  handleSetFormDispatcher,
} from "../../utils/handleDispatcher";
import SubblyList from "../../dashboard/integration/components/SubblyList";
import axios from "axios";
import { tokenConfig } from "../../../actions/auth";
import { useLocation } from "react-router-dom";
import store from "../../../store";
const Subbly = ({
  auth,
  form: { form },
  setFormDispatcher,
  connectStore,
  disconnectStore,
}) => {
  const params = useParams();
  const [formData, setForm] = useState({
    fields: [],
    show: false,
    elements: [],
  });
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState({
    survey: { ...form?.integrations?.subbly?.survey },
    mapped_answers: Array.isArray(form?.integrations?.subbly?.mapped_answers)
      ? [...form?.integrations?.subbly?.mapped_answers]
      : [],
  });

  const [subblyMap, setSubblyMap] = useState([]);
  const formHasSubbly = useMemo(() => {
    return form
      ? form.integrations
        ? form.integrations.subbly
          ? true
          : false
        : false
      : false;
  }, [form]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const formHasElements = useMemo(() => {
    return form.steps.some((step) => {
      return step.elements;
    });
  }, [form]);

  const subblyIsAuth = useMemo(() => {
    return auth.user
      ? auth.user.stores
        ? auth.user.stores.subbly
          ? true
          : false
        : false
      : false;
  }, [auth]);

  const authenticateBody = () => {
    return (
      <div className="col-12 integration-body mt-4">
        {subblyIsAuth ? (
          <>
            <h4 className="formify-h4">Integration is up and running!</h4>
            <p className="formify-mid-p">
              You have successfully created your integration.
            </p>
            {auth.user?.stores?.subbly?.store_url && (
              <p className="formify-mid-p mt-2">
                Connected Store{" "}
                <br></br>
                <span className="font-color formify-mid-p">
                  {auth.user?.stores?.subbly?.store_url}
                </span>
              </p>
            )}
            {!formData.show &&
            <>
            <button
              className="formify-btn-no-margin mt-3"
              onClick={() => {
                setForm({ ...formData, show: true });
              }}
            >
              Edit Configuration
            </button>

            <div>
              <a
                href="https://www.getformify.com/contact"
                target="_blank"
                className="font-color"
              >
                Request Supported Mapping
              </a>
            </div>
            </>
            }
          </>
        ) : (
          <>
            <h4 className="formify-h4">Subbly Authenticaiton</h4>
            <p className="formify-mid-p">
              Go ahead and configure your Subbly store here at Formify!
            </p>
            <button
              onClick={() => {
                connectStore("subbly", auth, form);
                window.open(`${window.location.origin}/integrations/subbly`);
              }}
              className="formify-btn-no-margin mt-3"
            >
              Connect
            </button>
          </>
        )}
      </div>
    );
  };

  //fetch subbly surveys
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchSurveys = async () => {
      try {
        const response = await axios.get(
          `${BASE_DEV_URL}/store/subbly/surveys/`,
          {
            ...tokenConfig(store.getState),
            cancelToken: source.token, // passing the cancel token to the request
          }
        );
        setSurveys(response.data.surveys);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error(error);
        }
      }
    };
    fetchSurveys();

    return () => {
      source.cancel("Operation canceled by the user."); // message is optional
    };
  }, []);

  //fetch single survey
  const fetchSingleSurvey = async (e) => {
    try {
      const response = await axios.get(
        `${BASE_DEV_URL}/store/subbly/surveys/${e.value}`,
        tokenConfig(store.getState)
      );

      const placeholderArrays = [];

      response.data.survey.questions.map((quest) => {
        placeholderArrays.push({});
      });
      setSelectedSurvey({
        survey: response.data.survey,
        mapped_answers: placeholderArrays,
      });
    } catch (error) {
      console.error(error);
    }
  };
  //SubblyMap
  useEffect(() => {
    if (selectedSurvey.survey?.questions) {
      let subblyMap1 = selectedSurvey.survey?.questions.map(
        (item, index, questionIndex) => {
          let singleAnswer = item.answers.map((answer) => {
            return answer;
          });
          let fieldObject = {
            formify_field: formData.elements[index]?._id || "",
            // integration_field_answers: singleAnswer,
            integration_field_type: item.type,
            question_id: item.id,
            question_title: item.title,
          };
          return subblyFormMapping(
            item.type,
            index,
            questionIndex,
            fieldObject
          );
        }
      );
      setSubblyMap(subblyMap1);
    }
  }, [selectedSurvey, formData]);

  //form
  useEffect(() => {
    if (form && formHasElements) {
      let elements = formGetCrmFields(form);

      if (form.integrations && form.integrations.subbly?.fields) {
        setForm({
          ...formData,
          fields: form.integrations.subbly.fields,
          elements,
        });
      } else {
        setForm({
          ...formData,
          elements,
          fields: [],
        });
      }
    }
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedForm = handleSetForm(
      [`integrations.subbly`],
      [selectedSurvey],
      form
    );
    setFormDispatcher(updatedForm, true);
    toast.success("Fields are created");
  };

  const handleCurrencyChange = (e) => {
    var splittedValue = e.split("-");
    var name = `${splittedValue[0]} - ${splittedValue[1]}`;
    var symbol = splittedValue[2];
    handleSetFormDispatcher(
      [`currency_name`, `currency_symbol`],
      [name, symbol],
      form,
      setFormDispatcher
    );
  };

  const handleFormifyChange = (e, questionIndex) => {

    if (!selectedSurvey || !Array.isArray(selectedSurvey.mapped_answers)) {
      console.error(
        "Invalid state: selectedSurvey is not available or mapped_answers is not an array!"
      );
      return;
    }

    const mapped_answers = Array.from(selectedSurvey.mapped_answers);
    const selectedElementId = e.target.value;

    const selectedFormDataElement = formData.elements.find(
      (element) => element._id === selectedElementId
    );

    if (!selectedFormDataElement) {
      console.error(
        `Element with ID ${selectedElementId} not found in formData`
      );
      return;
    }

    const updatedAnswer = {
      ...mapped_answers[questionIndex],
      formify_field_title: selectedFormDataElement.content.question
        ? selectedFormDataElement.content.question
        : selectedFormDataElement.content.question_text
        ? selectedFormDataElement.content.question_text
        : selectedFormDataElement.content.title
        ? selectedFormDataElement.content.title
        : selectedFormDataElement.options.id
        ? selectedFormDataElement.options.id
        : selectedFormDataElement.name,
      formify_field: selectedElementId,
      answers: selectedFormDataElement.content.options,
      formify_field_select_options: selectedFormDataElement.content.options,
    };

    mapped_answers[questionIndex] = updatedAnswer;

    setSelectedSurvey((prevSelectedSurvey) => ({
      ...prevSelectedSurvey,
      mapped_answers: mapped_answers,
    }));

    setForm((prevForm) => ({
      ...prevForm,
      mapped_answers: mapped_answers,
    }));
  };

  const handleFormifyAnswerChange = (e, answerIndex, questionIndex) => {
    let mapped_answers = Array.from(selectedSurvey.mapped_answers);
    let options = Array.from(mapped_answers[questionIndex].answers);
    options[answerIndex] = { ...options[answerIndex], value: e.target.value };
    mapped_answers[questionIndex] = {
      ...mapped_answers[questionIndex],
      answers: options,
    };
    // mapped_answers[questionIndex].answers = options;

    setSelectedSurvey((prevSelectedSurvey) => ({
      ...prevSelectedSurvey,
      mapped_answers,
    }));
  };

  const handleIntegrationChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], [e.target.id]: e.target.value };

    setForm({
      ...formData,
      fields,
    });
  };

  const textFieldsAllowed = [
    "form_number",
    "form_long_text",
    "form_name",
    "form_short_text",
    "form_phone",
    "form_dropdown",
    "form_email",
  ];
  const quantityFieldsAllowed = ["form_number"];
  const [textWidgets, setTextWidgets] = useState([]);
  const [singleChoiceWidgets, setSingleChoiceWidgets] = useState([]);
  const [multipleChoiceWidgets, setMultipleChoiceWidgets] = useState([]);


  //formelements
  useEffect(() => {
    const newSingleChoiceWidgets = [];
    const newMultipleChoiceWidgets = [];
    const newTextWidgets = [];

    formData.elements.forEach((elementi) => {
      const e = elementi.element_type;

      if (e === "form_single_choice") {
        newSingleChoiceWidgets.push(elementi);
      } else if (e === "form_multi_choice") {
        newMultipleChoiceWidgets.push(elementi);
      } else if (
        e === "form_text" ||
        e === "form_long_text" ||
        e === "form_date" ||
        e === "form_phone" ||
        e === "form_short_text" ||
        e === "form_name" ||
        e === "form_dropdown" ||
        e === "form_email"
      ) {
        newTextWidgets.push(elementi);
      }
    });
    setSingleChoiceWidgets(newSingleChoiceWidgets);
    setMultipleChoiceWidgets(newMultipleChoiceWidgets);
    setTextWidgets(newTextWidgets);
  }, [formData.elements]);


  let singleChoiceElements = Array.from(formData.elements).filter(
    (element) => element.element_type === "form_single_choice"
  );

  const subblyFormMapping = (type, questionIndex, index, field) => {
    let multiChoiceElements = Array.from(formData.elements).filter(
      (element) => element.element_type === "form_multi_choice"
    );

    switch (type) {
      case "text":
        return (
          <div className="question-container-subbly">
            <div className="me-2 question-div">
            <div className="formify-light-btn-green subbly-icon-text infield-subbly-question-type"> <img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Text</div>
              <label className="formify-mid-p-black" >
                {selectedSurvey.survey.questions[questionIndex].title}
              </label>
              <select
                value={
                  Object.keys(selectedSurvey.mapped_answers[questionIndex])
                    .length > 0
                    ? selectedSurvey.mapped_answers[questionIndex]
                        .formify_field_title
                    : "Select a widget"
                }
                onChange={(e) => {
                  handleFormifyChange(e, questionIndex);
                }}
                className="integration-select"
              >
                {
                  <option
                    value={
                      Object.keys(selectedSurvey.mapped_answers[questionIndex])
                        .length > 0
                        ? selectedSurvey.mapped_answers[questionIndex]
                            ?.formify_field_title
                        : ""
                    }
                    key={questionIndex}
                  >
                    {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                      .length > 0
                      ? selectedSurvey.mapped_answers[questionIndex]
                          .formify_field_title
                      : "Select a widget"}
                  </option>
                }
                {formData.elements.map((element) => {
                  return textFieldsAllowed.includes(element.element_type) ? (
                    <option value={element._id} key={element._id}>
                      {element.content.title
                        ? element.content.title
                        : element.content.label
                        ? element.content.label
                        : element.content.question
                        ? element.content.question
                        : element.options.id
                        ? element.options.id
                        : element.content.question_text
                        ? element.content.question_text
                        : element.name}
                    </option>
                  ) : null;
                })}
              </select>
            
            </div>

            <div className="me-2">
              <input
                type={"hidden"}
                value={selectedSurvey.survey.questions[questionIndex].id}
                className="integration-select"
                id={"question_id"}
              ></input>
            </div>
          </div>
        );
      case "quantity":
        return (
          <div className="question-container-subbly">
            <div className="me-2">
            <div className="formify-light-btn-black subbly-icon-text infield-subbly-question-type"> <img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Number</div>
              <label className="formify-mid-p-black" >
                {" "}
                {selectedSurvey.survey.questions[questionIndex].title}
              </label>
              <select
                value={
                  Object.keys(selectedSurvey.mapped_answers[questionIndex])
                    .length > 0
                    ? selectedSurvey.mapped_answers[questionIndex]
                        .formify_field_title
                    : "Select a widget"
                }
                onChange={(e) => {
                  handleFormifyChange(e, questionIndex);
                }}
                className="integration-select"
              >
                {
                  <option
                    value={
                      Object.keys(selectedSurvey.mapped_answers[questionIndex])
                        .length > 0
                        ? selectedSurvey.mapped_answers[questionIndex]
                            ?.formify_field_title
                        : ""
                    }
                    key={questionIndex}
                  >
                    {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                      .length > 0
                      ? selectedSurvey.mapped_answers[questionIndex]
                          .formify_field_title
                      : "Select a widget"}
                  </option>
                }
                {formData.elements.map((element) => {
                  return quantityFieldsAllowed.includes(
                    element.element_type
                  ) ? (
                    <option value={element._id} key={element._id}>
                      {element.options.id ? element.options.id : element.name}
                    </option>
                  ) : null;
                })}
              </select>
             
            </div>{" "}
            <div className="me-2">
              <input
                type={"hidden"}
                value={selectedSurvey.survey.questions[questionIndex].id}
                className="integration-select"
                id={"question_id"}
                onChange={(e) => {
                  handleIntegrationChange(e, index);
                }}
              ></input>
            </div>{" "}
          </div>
        );
      case "select":
        return (
          <div className="question-container-subbly">
            <div className="me-2 question-div">
            <div className="formify-light-btn-orange subbly-icon-text infield-subbly-question-type"> <img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Select</div>
              <label className="formify-mid-p-black" >
                {selectedSurvey.survey.questions[questionIndex].title}
              </label>
              <select
                value={
                  Object.keys(selectedSurvey.mapped_answers[questionIndex])
                    .length > 0
                    ? selectedSurvey.mapped_answers[questionIndex]
                        .formify_field_title
                    : "Select a widget"
                }
                onChange={(e) => {
                  handleFormifyChange(e, questionIndex);
                }}
                className="integration-select"
              >
                {
                  <option
                    value={
                      Object.keys(selectedSurvey.mapped_answers[questionIndex])
                        .length > 0
                        ? selectedSurvey.mapped_answers[questionIndex]
                            ?.formify_field_title
                        : ""
                    }
                    key={questionIndex}
                  >
                    {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                      .length > 0
                      ? selectedSurvey.mapped_answers[questionIndex]
                          .formify_field_title
                      : "Select a widget"}
                  </option>
                }

                {singleChoiceElements.length > 0 ? (
                  singleChoiceElements.map((element) => (
                    <option value={element._id} key={element._id}>
                      {/* {element.options.id ? element.options.id : element.name} */}
                      {element.content.title
                        ? element.content.title
                        : element.content.label
                        ? element.content.label
                        : element.content.question
                        ? element.content.question
                        : element.options.id
                        ? element.options.id
                        : element.name}
                    </option>
                  ))
                ) : (
                  <option>Loading options...</option>
                )}
              </select>
            
            </div>
            <div className="me-2">
              <input
                type={"hidden"}
                value={selectedSurvey.survey.questions[questionIndex].id}
                className="integration-select"
                id={"question_id"}
              ></input>
            </div>
            <div className="option-wrapper">
              {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                .length > 0 ? (
                selectedSurvey?.survey.questions[questionIndex]?.answers?.map(
                  (answer, answerIndex) => {
                    const oneanswer = selectedSurvey?.mapped_answers[
                      questionIndex
                    ]?.formify_field_select_options?.map(
                      (option, optionIndex) => {
                        return (
                          <option value={option.value} key={optionIndex}>
                            {option.value}
                          </option>
                        );
                      }
                    );
                    return (
                      <div key={answer.id} className="answer-div">
                        <div className="me-3">
                          <label className="formify-mid-p">
                            {answer?.content}
                          </label>
                        </div>
                        <div className="me-3">
                          <input
                            type="hidden"
                            value={answer.id}
                            className="integration-select"
                            id="integration_field_id"
                          />
                        </div>
                        <select
                          value={
                            Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                            ).length > 0 &&
                            Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                                .answers
                            ).length > 0
                              ? selectedSurvey?.mapped_answers[questionIndex]
                                  ?.answers[answerIndex]?.value
                              : "Select option"
                          }
                          onChange={(e) => {
                            handleFormifyAnswerChange(
                              e,
                              answerIndex,
                              questionIndex
                            );
                          }}
                          className="integration-select"
                        >
                          <option
                            value={
                              Object.keys(
                                selectedSurvey.mapped_answers[questionIndex]
                              ).length > 0
                                ? selectedSurvey?.mapped_answers[questionIndex]
                                    ?.answers[answerIndex]?.value
                                : "Select"
                            }
                            key={questionIndex}
                          >
                            {Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                            ).length > 0
                              ? selectedSurvey?.mapped_answers[questionIndex]
                                  ?.answers[answerIndex]?.value
                              : "Select an option"}
                          </option>

                          {oneanswer}
                        </select>
                      </div>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      case "multiple":
        return (
          <div className="question-container-subbly">
            <div className="me-2 question-div">
            <div className="formify-light-btn-blue subbly-icon-text infield-subbly-question-type"> <img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Multiple</div>
              <label className="formify-mid-p-black">
                {" "}
                {selectedSurvey.survey.questions[questionIndex].title}
              </label>
              <select
                value={
                  Object.keys(selectedSurvey.mapped_answers[questionIndex])
                    .length > 0
                    ? selectedSurvey.mapped_answers[questionIndex]
                        .formify_field_title
                    : "Select a widget"
                }
                onChange={(e) => {
                  handleFormifyChange(e, questionIndex, field);
                }}
                className="integration-select"
              >
                {
                  <option
                    value={
                      Object.keys(selectedSurvey.mapped_answers[questionIndex])
                        .length > 0
                        ? selectedSurvey.mapped_answers[questionIndex]
                            ?.formify_field_title
                        : ""
                    }
                    key={questionIndex}
                  >
                    {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                      .length > 0
                      ? selectedSurvey.mapped_answers[questionIndex]
                          .formify_field_title
                      : "Select a widget"}
                  </option>
                }

                {multiChoiceElements.map((element) => {
                  return (
                    <option value={element._id} key={element._id}>
                      {/* {element.options.id ? element.options.id : element.name} */}
                      {element.content.title
                        ? element.content.title
                        : element.content.label
                        ? element.content.label
                        : element.content.question
                        ? element.content.question
                        : element.options.id
                        ? element.options.id
                        : element.name}
                    </option>
                  );
                })}
              </select>
             
            </div>
            <div className="me-2">
              <input
                type="hidden"
                value={selectedSurvey.survey.questions[questionIndex].id}
                className="integration-select"
                id="question_id"
                onChange={(e) => {
                  handleIntegrationChange(e, index);
                }}
              />
            </div>
            <div className="option-wrapper">
              {Object.keys(selectedSurvey.mapped_answers[questionIndex])
                .length > 0 ? (
                selectedSurvey?.survey.questions[questionIndex]?.answers?.map(
                  (answer, answerIndex) => {
                    const oneanswer = selectedSurvey.mapped_answers[
                      questionIndex
                    ]?.formify_field_select_options?.map(
                      (option, optionIndex) => {
                        return (
                          <option value={option.value} key={optionIndex}>
                            {option.value}
                          </option>
                        );
                      }
                    );
                    return (
                      <div key={answer.id} className="answer-div">
                        <div className="me-3">
                          <label className="formify-mid-p">
                            {answer?.content}
                          </label>
                        </div>
                        <div className="me-3">
                          <input
                            type="hidden"
                            value={answer.id}
                            className="integration-select"
                            id="integration_field_id"
                          />
                        </div>
                        <select
                          value={
                            Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                            ).length > 0 &&
                            Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                                .answers
                            ).length > 0
                              ? selectedSurvey?.mapped_answers[questionIndex]
                                  ?.answers[answerIndex]?.value
                              : "Select option"
                          }
                          onChange={(e) => {
                            handleFormifyAnswerChange(
                              e,
                              answerIndex,
                              questionIndex
                            );
                          }}
                          className="integration-select"
                        >
                          <option
                            value={
                              Object.keys(
                                selectedSurvey.mapped_answers[questionIndex]
                              ).length > 0
                                ? selectedSurvey.mapped_answers[questionIndex]
                                    ?.answers[answerIndex]?.value
                                : "Select widget"
                            }
                            key={questionIndex}
                          >
                            {Object.keys(
                              selectedSurvey.mapped_answers[questionIndex]
                            ).length > 0
                              ? selectedSurvey.mapped_answers[questionIndex]
                                  ?.answers[answerIndex]?.value
                              : "Select an option"}
                          </option>

                          {oneanswer}
                        </select>
                      </div>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const options = surveys.map((survey) => ({
    value: survey.id,
    label: survey.title,
  }));

  const subblyForm = () => {
    return (
      <div className="col-12 integration-form mt-4">
        <div style={{ width: "50%" }}>
          <label>Select a survey</label>
          <p className="formify-mid-p pb-1">
            Make sure the same survey is applied to all the products you use
            within Subbly.
          </p>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "black",
                borderRadius: "8px",
              }),
            }}
            options={options}
            value={
              options.find(
                (option) => option.value === selectedSurvey.survey.id
              ) || "Select a survey"
            }
            onChange={fetchSingleSurvey}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <hr className="grey-hr" />
          <p className="formify-mid-p">
            To successfully map your Subbly survey, you will need to make sure
            that the types of questions you have created in Subbly match those
            available in your Formify form.<span className="formify-mid-p formify-color-text">Please follow the guide below: </span> 
          </p>
          <h6 className="pt-2 pb-1">If Subbly question type is</h6>
          <div className="me-2 question-div my-2">
            <div className="formify-light-btn-green subbly-icon-text"><img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Text</div>
            <ArrowRight
              className="me-2"
              style={{
                color: "#2ac8a2",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
              }}
            />
            <p className="formify-mid-p-black">
              Can only be mapped the following Formify widgets: <br></br>
              <strong className="pt-2" style={{ color: "#2ac8a2" }}>
                Dropdowns, Phone, Number, Short Text, Full Name and Datepicker
              </strong>
              .
            </p>
          </div>
          <div className="me-2 question-div my-2">
            <div className="formify-light-btn-black subbly-icon-text"><img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Quantity</div>
            <ArrowRight
              className="me-2"
              style={{
                color: "#1e1e1e",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
              }}
            />
            <p className="formify-mid-p-black">
              {/* Map Subbly quantity questions with <strong className="pt-2" style={{color:"#1e1e1e"}}>Number Widget</strong>. */}
              Number questions in Subbly are not currently supported in Formify
            </p>
          </div>
          <div className="me-2 question-div my-2">
            <div className="formify-light-btn-orange subbly-icon-text" ><img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Select</div>
            <ArrowRight
              className="me-2"
              style={{
                color: "#fc7136",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
              }}
            />
            <p className="formify-mid-p-black">
              Can only be mapped the following Formify widget: <br></br>
              <strong style={{ color: "#fc7136" }}>Single Choice </strong>.
            </p>
          </div>
          <div className="me-2 question-div my-2">
            <div className="formify-light-btn-blue subbly-icon-text"> <img
                    src={process.env.PUBLIC_URL + "/assets/images/icons/subblyicon.svg"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />Multiple</div>
            <ArrowRight
              className="me-2"
              style={{
                color: "#419df1",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
              }}
            />
            <p className="formify-mid-p-black">
              Can only be mapped the following Formify widget: <br></br>
              <strong style={{ color: "#419df1" }}>Multiple Choice</strong>.
            </p>
          </div>
          <div className="integration-container mt-3">
            <label>Match Fields</label>
            <p className="formify-mid-p">
              Connect the fields on your form with the fields on your Subbly
              object.
            </p>
          </div>
          <>
            <div
              className="add-new-field d-flex mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setForm({
                  ...formData,
                  fields: [
                    ...formData.fields,
                    {
                      integration_field_type: "",
                      formify_field: "",
                      integration_field_answers: [{ integration_field_id: "" }],
                    },
                  ],
                });
              }}
            ></div>
            <div className="subbly-form-mapping">{subblyMap}</div>
            <div className="d-flex flex-row ">
              <button
                className="formify-btn me-2"
                type="submit"
                // onClick={() => {
                //   setForm({
                //     ...formData,
                //     integrations: {
                //       ...formData.integrations,
                //       subbly: selectedSurvey,
                //     },
                //   });
                // }}
                // onClick={() => {
                //   setForm({ ...formData, show: false });
                // }}
              >
                Save
              </button>
              <button
                className="invert-formify-btn"
                type="button"
                onClick={() => {
                  setForm({ ...formData, show: false });
                }}
              >
                Cancel
              </button>
            </div>
          </>
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid integraiton mt-5">
      <div className="row justify-content-center">
        <div className="col-12 integration-card-subbly ">
          <div className="row">
            <div className="col-12">
              <div className="integration-column col-6">
                <div className="mb-2">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />
                  <ArrowRight
                    className="me-2"
                    style={{
                      color: "#6563FF",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/subbly.png"}
                    alt="Mailchimp"
                    className="img-fluid"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                <CurrencySelector
                  tag={form.currency_name}
                  handleSelect={handleCurrencyChange}
                />{" "}
                 {formData.show && 
                 
                 authenticateBody()
                 
                 }
              </div>
              {formData.show ? subblyForm() : authenticateBody()}
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {integrationFooter(formHasSubbly, disconnectStore, "subbly", params)}
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setFormDispatcher,
  connectStore,
  disconnectStore,
})(Subbly);
