import React from "react";
import { Route, Routes } from "react-router-dom";
import BigcommerceIntegration from "../dashboard/integration/BigcommerceIntegration";
import HubspotIntegration from "../dashboard/integration/HubspotIntegration";
import ShopifyIntegration from "../dashboard/integration/ShopifyIntegration";
import WoocommerceIntegration from "../dashboard/integration/WoocommerceIntegration";
import SubblyIntegration from "../dashboard/integration/SubblyIntegration";
import SubblyAddProductIntegration from "../dashboard/integration/SubblyAddProductIntegration";
import SubblyDashboardIntegration from "../dashboard/integration/SubblyDashboardIntegration";
import SubblyEditProductIntegration from "../dashboard/integration/SubblyEditProductIntegration";
import ActiveCampaignIntegration from "../dashboard/integration/ActiveCampaign";
import MailchimpIntegration from "../dashboard/integration/MailchimpIntegration";
import SimpleNavbar from "../dashboard/SimpleNavbar";
import RechargePayment from "../dashboard/integration/RechargePayment";
import ZapierIntegration from "../dashboard/integration/ZapierIntegration";
import Klaviyo from "../dashboard/integration/Klaviyo";

const Integrations = () => {
  return (
    <React.Fragment>
      <SimpleNavbar />
      <div className="container integration-wraper profile">
        <Routes>
          <Route path="/shopify" element={<ShopifyIntegration />} />
          <Route path="/woocommerce" element={<WoocommerceIntegration />} />
          <Route path="/bigcommerce" element={<BigcommerceIntegration />} />
          <Route path="/subbly" element={<SubblyDashboardIntegration />} />
          <Route path="/subbly/store" element={<SubblyIntegration />} />
          <Route
            path="/subbly/add-product"
            element={<SubblyAddProductIntegration />}
          />
          <Route
            path="/subbly/edit-product/:productId"
            element={<SubblyEditProductIntegration />}
          />
          <Route
            path="/activecampaign"
            element={<ActiveCampaignIntegration />}
          />

          <Route path="/hubspot" element={<HubspotIntegration />} />
          <Route path="/mailchimp" element={<MailchimpIntegration />} />
          <Route path="/klaviyo" element={<Klaviyo />} />
          <Route path="/recharge" element={<RechargePayment />} />
          <Route path="/zapier" element={<ZapierIntegration />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default Integrations;
