import ChangeResponsiveness from "./changeResponsiveness";

const VerticalAligns = ({
  handleSelect,
  tag,
  removeXPadding=false,
  text="Vertical Alignment"
}) => {

  const handleAlign = (changeEvent) => {
    handleSelect(changeEvent.target.value, "verticalAlignment");
  }
  return (
    <div className={`editable-field form-column w-100 ${removeXPadding && 'px-0'}`}>
      <div className="d-flex justify-between">
        <p>{text}</p>
        <div><ChangeResponsiveness /></div>
      </div>
      <div className="alignment-tabs">
        <div><input name="verticalAlignment" value="start"   checked={tag === "start"} onChange={handleAlign}  type="radio"/><label className="top-vertical"></label></div>
        <div><input name="verticalAlignment" value="center" checked={tag === "center"} onChange={handleAlign}  type="radio"/><label className="center-vertical"></label></div>
        <div><input name="verticalAlignment" value="end"  checked={tag === "end"} onChange={handleAlign}  type="radio"/><label className="bottom-vertical"></label></div>
      </div>
    </div>
  );
};

export default VerticalAligns;