
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setResponsiveDispatcher } from "../../../../actions/form";

const ChangeResponsiveness = ({ form: { responsive }, setResponsiveDispatcher }) => {
  return (
    <>
      <div className="responsive-tabs">
        <div 
          onClick={() => setResponsiveDispatcher("desktop") }
          className={ responsive === "desktop" ? "active-res" : "" }
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icons/Desktop.svg"}
            alt="Formify"
          />
        </div>
        <div 
          onClick={() => setResponsiveDispatcher("tablet") }
          className={ responsive === "tablet" ? "active-res" : "" }
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icons/Tablet.svg"}
            alt="Formify"
          />
        </div>
        <div 
          onClick={() => setResponsiveDispatcher("mobile") }
          className={ responsive === "mobile" ? "active-res" : "" }
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icons/Mobile.svg"}
            alt="Formify"
          />
        </div>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setResponsiveDispatcher })(ChangeResponsiveness);