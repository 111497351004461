import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { logoutUser } from "../../actions/auth";
import { LogOut } from "react-feather";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../utils";
import { useState } from "react";

/* App.jsx */
// class MobileHeader extends React.Component {

const MobileHeader = ({ menu, type, auth, logoutUser }) => {
  const [state, setState] = useState({
    menuOpen: false,
    role: "",
    menu: menu,
    type: type,
  });

  const handleMenuClick = () => {
    setState({ ...state, menuOpen: !state.menuOpen });
    
  };

  const handleLinkClick = () => {
    setState({ ...state, menuOpen: false  });
  };

  return (
    <div className="mob-header">
      <div className="mob-header-container">
        <MenuButton
          open={state.menuOpen}
          onClick={() => handleMenuClick()}
          color="white"
        />
        <img
          src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
          alt="afsd"
          className="dashboard-logo"
        />
      </div>
      <MenuContainer open={state.menuOpen}>
        <div className="mobile_header ">
          <ul>
            {menu.links.map((link) => {
              return type === "dashboard" ? (
                <li
                  className={
                    "dashboard-link d-flex flex-row justify-content-start align-items-center"
                  }
                  key={link.id}
                >
                  <Link
                    to={link.path}
                    onClick={() => handleLinkClick()}
                    className="link-dark rounded"
                  >
                    <div>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/" +
                          link.icon
                        }
                        className="nav-icons"
                        alt=""
                      />
                      {link.name}
                    </div>
                    <span className="right-arrow-list"></span>
                  </Link>
                </li>
                
              ) : (
                <li key={link.id}>
                  <h4 className="section-header">{link.header}</h4>
                  <div className="section-holder">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small section-list">
                      {link.sublinks.map((sublink) => {
                        return (
                          <li key={sublink.id}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/" +
                                link.icon
                              }
                              className="nav-icons"
                              alt=""
                            />
                            <Link
                              to={sublink.path}
                              className="link-dark rounded"
                              onClick={() => handleLinkClick()}
                            >
                              {sublink.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
                  <li
            className="dashboard-link d-flex flex-row justify-content-start align-items-center"
          >
            {/* {link.icon} */}
            <img src={process.env.PUBLIC_URL + "/assets/images/icons/message-question.svg"}
              className="nav-icons"
              alt=""/>
            <a href="https://docs.google.com/document/d/1Wy2u9nhl4P-uueI2ABoHiHlLx_gH68QeUFNt9Aiom3Y/edit#heading=h.96g1fnf489n5s" target="_blank" className="link-dark rounded">Get Help</a>
          </li>
          </ul>
        </div>
        <div className="user-avatar me-4"></div>
        <div className="connect-shop-mobile">
          <div className="my-data">
            <Avatar src={auth.user ? auth.user.profile_picture : null} />
            <div className="personal-user-data">
              <p>{auth.user.username}</p>
              <p className="user-account-type">
                Formify <span className="ribbon">{auth?.user?.transactions[0]?.account_type?.title}</span>
              </p>
            </div>
          </div>
          <p>
            Discover the endless possibilities of customizable multi-step forms.
            Upgrade Plan
          </p>
          <Link
            onClick={() => {
              logoutUser();
            }}
            className="link-dark rounded logout-link"
            to="/"
          >
            Log Out
          </Link>
        </div>
      </MenuContainer>
    </div>
  );
};

/* Menu.jsx */
class MenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const styles = {
      container: {
        position: "fixed",
        top: 0,
        left: 0,
        height: this.state.open ? "100%" : 0,
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        background: "white",
        color: "#fafafa",
        transition: "height 0.3s ease",
        zIndex: 2,
      },
      menuList: {
        paddingTop: "55px",
      },
    };
    return (
      <div style={styles.container}>
        {this.state.open ? (
          <div style={styles.menuList}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

/* MenuButton.jsx */
class MenuButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : "black",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const styles = {
      container: {
        height: "20px",
        width: "25px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "4px",
        right: "20px",
        position: "absolute",
      },
      line: {
        height: "2px",
        width: "20px",
        background: "black",
        transition: "all 0.2s ease",
      },
      lineTop: {
        transform: this.state.open ? "rotate(45deg)" : "none",
        transformOrigin: "top left",
        marginBottom: "5px",
      },
      lineMiddle: {
        opacity: this.state.open ? 0 : 1,
        transform: this.state.open ? "translateX(-16px)" : "none",
      },
      lineBottom: {
        transform: this.state.open ? "translateX(-1px) rotate(-45deg)" : "none",
        transformOrigin: "top left",
        marginTop: "5px",
      },
    };
    return (
      <div
        style={styles.container}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick();
              }
        }
      >
        <div style={{ ...styles.line, ...styles.lineTop }} />
        <div style={{ ...styles.line, ...styles.lineMiddle }} />
        <div style={{ ...styles.line, ...styles.lineBottom }} />
      </div>
    );
  }
}

export default connect(mapAuthErrorsStateToProps, { logoutUser })(MobileHeader);
