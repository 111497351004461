import { Dropdown, DropdownButton } from "react-bootstrap";

const PositionOptions = ({
    tag,
    handleSelect,
    id,
    name,
}) => {

    return (
        <div className="editable-field form-row  ">
            <p> Position</p>
            <div className="widget-range-slider-container">
                <DropdownButton
                    title={tag ? tag : "Relative"}
                    id={id}
                    name={name}
                    onSelect={(e) => handleSelect(e, { id: id })}
                    className="form-dropdown-select"
                >
                    <Dropdown.Item eventKey="relative">Relative</Dropdown.Item>
                    <Dropdown.Item eventKey="absolute">Absolute</Dropdown.Item>
                    <Dropdown.Item eventKey="sticky">Sticky</Dropdown.Item>
                    <Dropdown.Item eventKey="fixed">Fixed</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
};

export default PositionOptions;