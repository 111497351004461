import { createSlice } from '@reduxjs/toolkit';

const selectedPlatform = JSON.parse(localStorage.getItem("selectedPlatform"));

const subscriptionProdSlice = createSlice({
  name: 'subscriptionProd',
  initialState: selectedPlatform && selectedPlatform.value === 'subscriptions' ? true : false,
  reducers: {
    setSubscriptionProducts: (state, action) => {
      return action.payload; 
    },
  },
});

export const { setSubscriptionProducts } = subscriptionProdSlice.actions;

export default subscriptionProdSlice.reducer;
