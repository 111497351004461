import { Modal } from "react-bootstrap";
import { X } from "react-feather";

const ConfirmModal = ({ 
  show, 
  contact, 
  del, 
  setShow, 
  setDel, 
  text = 'Are you sure you want to delete this contact?',
  subtext = "After you delete it you can't restore it" 
}) => {
  return (
    <Modal show={show} size="lg" centered className="name-modal">
      <Modal.Header>
        <div className="name-modal-header d-flex flex-column align-items-center text-center">
          <div className="danger-circle d-flex justify-content-center align-items-center mb-3">
            <X
              style={{
                color: "white",
                minHeight: "48px",
                minWidth: "48px",
              }}
            />
          </div>
          <h4>{ text }</h4>
          <p>{ subtext }</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <button
          className="danger-btn"
          type="button"
          style={{ width: "100%", fontSize: "14px" }}
          onClick={() => {
            if(contact.index > -1) del(contact.id, contact.index)
            else del(contact?._id ? contact?._id : contact);
          }}
        >
          Delete
        </button>
        <button
          className="invert-formify-btn mt-3"
          onClick={() => {
            setShow(false);
            setDel(null);
          }}
          type="button"
          style={{ width: "100%", fontSize: "14px" }}
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
