/* eslint-disable react-hooks/exhaustive-deps */
import { Check, ChevronLeft } from "react-feather";
import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { Search } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import ChangeResponsiveness from "./settings-components/single-components/changeResponsiveness";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setFormStatesDispatcher } from "../../actions/form";
import { storeIsConnected } from "../utils/formBackendFunctions";

const IntegrationsSidebar = ({
  auth,
  form: { form, formStates },
  setFormStatesDispatcher,
  account_type
}) => {
  const nav = useNavigate();
  const params = useParams();
  const [integrations, setIntegrations] = useState([]);

  const isAnyStoreConnected = () => {
    return (
      storeIsConnected(auth, "shopify", false) ||
      storeIsConnected(auth, "subbly", false) ||
      storeIsConnected(auth, "woocommerce", false) ||
      storeIsConnected(auth, "bigcommerce", false) ||
      storeIsConnected(auth, "recharge", false)
    );
  };
  
  const checkIntegrationPermissions = (element) => {
    var isDisabled =
      account_type?.crm &&
      account_type?.crm.some(function (el) {
        return el === element;
      });
    return isDisabled;
  };

  const setInitalPhase = () => {
    setIntegrations([
      {
        content: [
          {
            name: "Shopify",
            integrated:
              form.store_selected && form.store_selected === "shopify"
                ? true
                : false,
            storeIsConnected: storeIsConnected(auth, "shopify", false),
            icon: process.env.PUBLIC_URL + "/assets/images/shopify.png",
            link: "integration/shopify",
            disabled: form.store_selected && form.store_selected !== "shopify",
          },
          {
            name: "Subbly",
            integrated:
              form.store_selected && form.store_selected === "subbly"
                ? true
                : false,
            storeIsConnected: storeIsConnected(auth, "subbly", false),
            icon: process.env.PUBLIC_URL + "/assets/images/subbly.png",
            link: "integration/subbly",
            disabled: form.store_selected && form.store_selected !== "subbly",
          },
          {
            name: "WooCommerce",
            integrated:
              form.store_selected && form.store_selected === "woocommerce"
                ? true
                : false,
            storeIsConnected: storeIsConnected(auth, "woocommerce", false),
            icon: process.env.PUBLIC_URL + "/assets/images/wocommerce.png",
            link: "integration/woocommerce",
            disabled:
              form.store_selected && form.store_selected !== "woocommerce",
          },
          {
            name: "Big Commerce",
            integrated:
              form.store_selected && form.store_selected === "bigcommerce"
                ? true
                : false,
            storeIsConnected: storeIsConnected(auth, "bigcommerce", false),
            icon: process.env.PUBLIC_URL + "/assets/images/bigcommerce.png",
            link: "integration/bigcommerce",
            disabled:
              form.store_selected && form.store_selected !== "bigcommerce",
          },
          // {
          //   name: "Recharge",
          //   integrated:
          //     form.store_selected && form.store_selected === "recharge"
          //       ? true
          //       : false,
          //   storeIsConnected: storeIsConnected(auth, "recharge", false),
          //   icon: process.env.PUBLIC_URL + "/assets/images/recharge.png",
          //   link: "integration/recharge",
          //   disabled: form.store_selected && form.store_selected !== "recharge",
          // },
        ],
        category: "Shops",
        anyStoreSelected: isAnyStoreConnected,
      },
      {
        content: [
          {
            name: "Mail Chimp",
            integrated:
              form.integrations && form.integrations.mailchimp ? true : false,
            storeIsConnected: true,
            icon: process.env.PUBLIC_URL + "/assets/images/mailchimp.png",
            link: "integration/mailchimp",
            disabled:!checkIntegrationPermissions("Mail Chimp")
          },
          {
            name: "ActiveCampaign",
            integrated:
              form.integrations && form?.integrations?.active_campaign
                ? true
                : false,
            storeIsConnected: true,
            icon: process.env.PUBLIC_URL + "/assets/images/activecampaign.png",
            link: "integration/activecampaign",
            disabled:!checkIntegrationPermissions("ActiveCampaign")
          },
          {
            name: "Salesforce",
            integrated:
              form.integrations && form.integrations.salesforce ? true : false,
            storeIsConnected: true,
            icon: process.env.PUBLIC_URL + "/assets/images/salesforce.png",
            link: "integration/salesforce",
            disabled:!checkIntegrationPermissions("Salesforce")
          },
          {
            name: "Klaviyo",
            integrated:
              form.integrations && form.integrations.klaviyo ? true : false,
            storeIsConnected: true,
            icon: process.env.PUBLIC_URL + "/assets/images/klaviyo.png",
            link: "integration/klaviyo",
            disabled:!checkIntegrationPermissions("Klaviyo")
          },
        ],
        category: "Marketing",
        anyStoreSelected: true,
      },
      {
        content: [
          {
            name: "Hubspot",
            integrated:
              form.integrations && form.integrations.hubspot ? true : false,
            storeIsConnected: true,
            icon: process.env.PUBLIC_URL + "/assets/images/hubspot.png",
            link: "integration/hubspot",
            disabled:!checkIntegrationPermissions("Hubspot")
          },
        ],
        category: "CRM",
        anyStoreSelected: true,
      },
    ]);
  };

  useEffect(() => {
    setInitalPhase();
  }, [form, auth]);

  const [searchQuery, setSearchQuery] = useState("");

  const filterIntegrations = (integrations, searchQuery) => {
    if (!searchQuery) {
      return integrations;
    }

    const lowerCaseSearch = searchQuery.toLowerCase();
    return integrations.map((category) => ({
      ...category,
      content: category.content.filter((integration) =>
        integration.name.toLowerCase().includes(lowerCaseSearch)
      ),
    }));
  };

  const filteredIntegrations = filterIntegrations(integrations, searchQuery);

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <div className="sidebar-wraper">
          <div className="form-information ">
            <h4 className="form-name">{form ? form.name : null}</h4>
            <p className="form-url">beta.getformify.com/{form._id}</p>
          </div>

          <div className="sidebar-settings-wraper">
            <div className="header">
              <h4 className="formify-h4">Integrations</h4>
              <p className="formify-p">
                Connect products to your store or send form responses to
                third-party services.
              </p>
            </div>
            <div className="widget-search container-fluid  mt-3">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="formify-search d-flex flex-row justify-content-start align-items-center">
                    <Search style={{ color: "#3C3C4399" }} />
                    <input
                      type="text"
                      className="search-input"
                      style={{ width: "100%" }}
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="integration-list mt-4">
              <div className="container-fluid p-3">
                {filteredIntegrations.map((integration, index) => {
                  return (
                    <div className="row g-2 form-integration-list">
                      <p
                        className="formify-sm-p mt-4"
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        {integration.category}
                      </p>
                      <div className="form-integration-container">
                        {integration.anyStoreSelected || integration.anyStoreSelected.isAnyStoreConnected ? (
                          integration.content.map((item, itemIndex) => {
                            return (
                              <div
                              className={
                                integration.category === "CRM" || integration.category === "Marketing" ?

                                checkIntegrationPermissions(
                                  item.name
                                )
                                  ? "single-integration"
                                  : "disabled-widget"

                                  :"single-integration"
                                } 
                                key={itemIndex}
                                onClick={() => {
                                  if (!item.disabled)
                                    nav(
                                      `/form/${params.id}/step/${params.step}/${item.link}`
                                    );
                                }}
                                style={
                                  item.disabled
                                    ? { cursor: "not-allowed" }
                                    : null
                                }
                              >
                                <div className="integrated-check ">
                                  {item.integrated ? (
                                    <Check
                                      style={{
                                        backgroundColor: "#6563FF",
                                        color: "white",
                                        height: "18px",
                                        borderRadius: "50%",
                                        border: "1px solid #ffffff",
                                        width: "18px",
                                        padding: "1px",
                                      }}
                                    />
                                  ) : null}
                                </div>
                                <div className="integration d-flex flex-column justify-content-center align-items-center">
                                  <img
                                    src={item.icon}
                                    className="img-fluid"
                                    style={{ maxWidth: "60px" }}
                                    alt=""
                                  />
                                  <p
                                    className="formify-p"
                                    style={{ fontWeight: "600", margin: 0 }}
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <p className="small">No store connected.</p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <ChangeResponsiveness />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormStatesDispatcher })(
  IntegrationsSidebar
);