import ChangeResponsiveness from "./changeResponsiveness";

const AlignmentTabs = ({
    handleSelect,
    tag,
    id,
    text= 'Alignment',
}) => {

  const handleAlign = (changeEvent) => {
    handleSelect(changeEvent.target.value, changeEvent.target.id);
  }
  
  return (
      <div className="editable-field form-column px-0">
          <div className="d-flex justify-between">
            <p>{text}</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <div className="alignment-tabs">
            <div><input name={`alignment-${id}`}  id={id} value="left"   checked={tag === "left"} onChange={handleAlign}  type="radio"/><label className="left-icon"></label></div>
            <div><input name={`alignment-${id}`} id={id} value="center" checked={tag === "center"} onChange={handleAlign}  type="radio"/><label className="center-icon"></label></div>
            <div><input name={`alignment-${id}`} id={id} value="right"  checked={tag === "right"} onChange={handleAlign}  type="radio"/><label className="right-icon"></label></div>
          </div>
      </div>
  );
};

export default AlignmentTabs;