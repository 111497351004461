import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { getCountries, mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { useEffect, useState } from "react";
import { checkFontSize, checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const AddressRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
  }, []);

  const css = element?.style
    ? `.widget-${element._id} input::placeholder {
    opacity:0.6;
    ${ getResponsiveStyle(element?.style, screen, "input", "color") && ` color:  ${getResponsiveStyle(element?.style, screen, "input", "color")}`};
    ${ checkFontSize(element?.style, screen, "input", "fontSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "fontSize")}px`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontDecoration") && `text-decoration:  ${getResponsiveStyle(element?.style, screen, "input", "fontDecoration")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontWeight") && `text-weight:  ${getResponsiveStyle(element?.style, screen, "input", "fontWeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "lineHeight") && `line-height:  ${getResponsiveStyle(element?.style, screen, "input", "lineHeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "wordSpacing") && `word-spacing:  ${getResponsiveStyle(element?.style, screen, "input", "wordSpacing")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "letterSpacing") && `border-right-width:  ${getResponsiveStyle(element?.style, screen, "input", "letterSpacing")}`};
    ${ element?.style?.desktop?.input?.fontFamily  ? `font-family:  ${element?.style?.desktop?.input?.fontFamily?.name}`  : ""};
    ${"}"}`: ``;

    let alignment = getResponsiveStyle(element?.style, screen, "alignment", null);

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable
          ? editElement({
              ...element,
            })
          : null
      }
    >
      <style>{css} </style>
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            {/* in fill mode for each change you construct the new object with values and send it to be updated same goes for full name */}

            <div
              className={"widget-inner-container input-holder    "}
              style={{ alignItems:alignment }}>
              <div className={" row "}>
                {!element.content.hide_label ? (
                  <h4
                    className="formify-h4"
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "label"),
                      fontFamily: element.style?.desktop?.label?.fontFamily?.name,
                      textAlign: alignment
                    }}
                  >
                    {element.content.text}
                  </h4>
                ) : null}
                {element.content.street.visible ? (
                  <div className="col-lg-12 input-holder">
                    {!isEditable ? (
                      <>
                        {" "}
                        <input
                          type="text"
                          style={{
                            ...checkResponsiveStyle(element.style, screen, "input"),
                            fontFamily: element.style?.desktop?.label?.fontFamily?.name,
                            textAlign: alignment
                          }}
                          placeholder={element.content.street.placeholder}
                          value={
                            responses[element._id]
                              ? responses[element._id].street || null
                              : null
                          }
                          onChange={(e) => {
                            setWidgetResponsesDispatcher(element._id, {
                              street: e.target.value,
                            },"form_address");
                          }}
                        />
                      </>
                    ) : (
                      <input
                        type="text"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        placeholder={element.content.street.placeholder}
                      />
                    )}
                    {!isEditable && responseError ? (
                      Object.keys(responseError).length > 0 ? (
                        <p className="error-message" style={{
                            fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          }} >{responseError[element._id]}</p>
                      ) : null
                    ) : null}
                    <p
                      className="formify-sm-p"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "helpText"),
                        fontFamily: element.style.desktop?.label?.fontFamily?.name,
                        textAlign: alignment
                      }}>
                      {element.content.street.label}
                    </p>
                  </div>
                ) : null}
                {element.content.city.visible ? (
                  <div className="col-lg-4 input-holder">
                    {!isEditable ? (
                      <input
                        type="text"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        placeholder={element.content.city.placeholder}
                        value={responses[element._id]?.city || ""}
                        onChange={(e) => {
                          setWidgetResponsesDispatcher(element._id, {
                            city: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        placeholder={element.content.city.placeholder}
                      />
                    )}

                    <p
                      className="formify-sm-p"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "helpText"),
                        fontFamily: element.style.desktop?.label?.fontFamily?.name,
                        textAlign: alignment
                      }}>
                      {element.content.city.label}
                    </p>
                  </div>
                ) : null}
                {element.content.zip.visible ? (
                  <div className="col-lg-4 input-holder">
                    {!isEditable ? (
                      <input
                        type="text"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        placeholder={element.content.zip.placeholder}
                        value={responses[element._id]?.zip || ""}
                        onChange={(e) => {
                          setWidgetResponsesDispatcher(element._id, {
                            zip: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        placeholder={element.content.zip.placeholder}
                      />
                    )}

                    <p
                      className="formify-sm-p"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "zip"),
                        fontFamily: element.style.desktop?.label?.fontFamily?.name,
                        textAlign: alignment
                      }}
                    >
                      {element.content.zip.label}
                    </p>
                  </div>
                ) : null}
                {element.content.country.visible ? (
                  !isEditable ? (
                    <div
                      className="col-lg-4 input-holder"
                      style={{ alignItems: element.style[screen].alignment }}
                    >
                      <select
                        type="text"
                        value={responses[element._id]?.country || ""}
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                        onChange={(e) => {
                          setWidgetResponsesDispatcher(element._id, {
                            country: e.target.value,
                          });
                        }}
                      >
                        {countries.map((country) => {
                          return (
                            <option value={country.name.common}>
                              {country.name.common}
                            </option>
                          );
                        })}
                      </select>
                      <p
                        className="formify-sm-p"
                        style={{
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                      >
                        {element.content.country.label}
                      </p>
                    </div>
                  ) : (
                    <div className="col-lg-4 input-holder">
                      <select
                        type="text"
                        value={element.content.country.default || null}
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "input"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                      >
                        {countries.map((country) => {
                          return (
                            <option
                              value={country.name.common}
                              style={{
                                textAlign: alignment,
                              }}
                            >
                              {country.name.common}
                            </option>
                          );
                        })}
                      </select>
                      <p
                        className="formify-sm-p"
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "helpText"),
                          fontFamily: element.style.desktop?.label?.fontFamily?.name,
                          textAlign: alignment
                        }}
                      >
                        {element.content.country.label}
                      </p>
                    </div>
                  )
                ) : null}
              </div>
            
            </div>
            {isEditable && (
                <WidgetEditTabs element={element} editElement={editElement} />
              )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(AddressRender);
