import { Accordion } from "react-bootstrap";
import ColorPickerStyle from "./ColorPickerStyle";

const BackgroundStyle = ({
  attribute,
  onChangeFont,
  onChange,
  updateSelectOptions,
  updateSliderOptions,
  changeBackground,
}) => {
  return (
    <div className="editable-field ">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="field-label">
            Background
          </Accordion.Header>
          <Accordion.Body>
            <div className="editable-field form-row  ">
              <p>Color</p>
              <ColorPickerStyle
                onChange={changeBackground}
                id={"backgroundColor"}
                tag={attribute.backgroundColor}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default BackgroundStyle;
