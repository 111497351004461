import _ from "lodash";
import { createContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../actions/form";
import EmailRender from "../utils/renderers/EmailRender";
import HeadingRender from "../utils/renderers/HeadingRender";
import ImageRender from "../utils/renderers/ImageRender";
import DropdownRender from "../utils/renderers/DropdownRender";
import PhoneRender from "../utils/renderers/PhoneRender";
import NumberRender from "../utils/renderers/NumberRender";
import ShortTextRender from "../utils/renderers/ShortTextRender";
import LongTextRender from "../utils/renderers/LongTextRender";
import SingleChoiceRender from "../utils/renderers/SingleChoiceRender";
import MultiChoiceRender from "../utils/renderers/MultiChoiceRender";
import SkipStepRender from "../utils/renderers/SkipStepRender";
import ParagraphRender from "../utils/renderers/ParagraphRender";
import FullNameRender from "../utils/renderers/FullNameRender";
import AddressRender from "../utils/renderers/AddressRender";
import DateRender from "../utils/renderers/DateRender";

import { gsap } from "gsap";
import ProductRender from "../utils/renderers/ProductRender";
import UpsellRender from "../utils/renderers/UpsellRender";
import { createGlobalStyle } from "styled-components";
import RedirectChoiceRender from "../utils/renderers/RedirectChoiceRender";
import DynamicProductRender from "../utils/renderers/DynamicProductRender";


export const EditTabContext = createContext();

    
const FormCanvas = ({
  elements,
  openHandler,
  editElement,
  isEditable,
  showUpsell,
  setUpsell,
  errorCheckbox,
  handleResponse,
  buttonRef,
  setErrorCheckbox,
  activeStep,
  uuid,
  form: { form, responsive, activeFormStep },
}) => {

  const [activeWidget, setActiveWidget] = useState(null);
  const widgetRef = useRef(null);

  const handleWidgetClick = (widgetId) => {
    if (activeWidget === widgetId) {
      setActiveWidget(null);
    } else {
      setActiveWidget(widgetId);
    }
  };


  const extractImageUrlsFromStep = (step) => {
    const imageUrls = [];
    if (step && step.elements && Array.isArray(step.elements)) {
      step.elements.forEach((element) => {
        if (element.element_type === 'form_image' && element.content && element.content.src) {
          imageUrls.push(element.content.src);
        }else if ((element.element_type === 'form_multi_choice' || element.element_type === 'form_single_choice') && element.content && element.content.options) {
          element.content.options.forEach((option) => {
            if (option.image) {
              imageUrls.push(option.image);
            }
          });
        
        }
      });
    }
    return imageUrls;
  };
  const preloadImagesForCurrentStep = () => {
    const currentStepData = form.steps[activeFormStep];
    const imageUrlsToPreload = extractImageUrlsFromStep(currentStepData);
    preloadImages(imageUrlsToPreload);
  };
  const getScreenSize = () => {
    if (!isEditable) {
      if (window.innerWidth <= 767) return "mobile";
      else if (window.innerWidth > 767 && window.innerWidth < 1024)
        return "tablet";
      else return "desktop";
    } else {
      return responsive;
    }
  };

  useEffect(() => {
    preloadImagesForCurrentStep();
  }, [activeFormStep]);

  let screen = getScreenSize();

  const getWidget = (index, element, outerSnapshot) => {
    switch (element.element_type) {
      case "form_email": return (<EmailRender element={element} index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/>);
      case "form_heading": return ( <HeadingRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_image": return ( <ImageRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_dropdown": return ( <DropdownRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_phone": return ( <PhoneRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_number": return ( <NumberRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_short_text": return ( <ShortTextRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_long_text": return ( <LongTextRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_single_choice": return ( <SingleChoiceRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_multi_choice": return ( <MultiChoiceRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_paragraph": return ( <ParagraphRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_skip_step": return ( <SkipStepRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_name": return ( <FullNameRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_address": return ( <AddressRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> );
      case "form_date": return ( <DateRender element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> ); 
      case "form_product": return ( <ProductRender activeStep={activeStep} setUpsell = {setUpsell} showUpsell={showUpsell} element={element} buttonRef={buttonRef} handleResponse={handleResponse} index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} errorCheckbox={errorCheckbox} setErrorCheckbox={setErrorCheckbox} screen = {screen}/> ); 
      case "form_dynamic_product": return ( <DynamicProductRender setUpsell = {setUpsell} showUpsell={showUpsell} element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} errorCheckbox={errorCheckbox} setErrorCheckbox={setErrorCheckbox} screen = {screen}/> ); 
      
      case "form_upsell": return ( <UpsellRender activeStep={activeStep} handleResponse={handleResponse} buttonRef={buttonRef} setUpsell = {setUpsell} showUpsell={showUpsell} element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen}/> ); 
      case "form_redirect_choice": return ( <RedirectChoiceRender setUpsell = {setUpsell} showUpsell={showUpsell} element={element}  index={index} outerSnapshot={outerSnapshot} editElement={editElement} isEditable={isEditable} screen = {screen} uuid={uuid}/> ); 
      default: return <h1>WIDGET NOT PROVIDED!</h1>;
    }
  };

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    const imageUrlsToPreload = extractImageUrlsFromElements(elements);
    preloadImages(imageUrlsToPreload);
  }, [elements]);

  const extractImageUrlsFromElements = (elements) => {
    const imageUrls = [];
    elements.forEach((element) => {
      if (element.element_type === 'form_image' && element.content && element.content.src) {
        imageUrls.push(element.content.src);
      }
    });
    return imageUrls;
  };

  useLayoutEffect(() => {
    let element = document.querySelector(".animate")
    let animateStep = document.querySelector(".animate-step")
    if(animateStep) {
      gsap.from(".animate-step", { duration: 0.3, x: 150, opacity: 0 });
      gsap.to(".animate-step", { duration: 0.3, x: 0, opacity: 1 });
    } 
    
    if(element) {
      gsap.from(".animate", { delay: 0.15, duration: 0.4, y: 80, opacity: 0 });
      gsap.to(".animate", { delay: 0.15, duration: 0.4, y: 0, opacity: 1 });
    }
  }, [activeFormStep]);

  return (
    <div
      className="form-canvas container-fluid mb-5 form-building"
      style={{
        ...(!_.isEmpty(form) ? form.form_style.layout : {}),
        width: '100%',
        color: !_.isEmpty(form) ? form.form_style.color.font_color : "#fffff",
        backgroundColor: !_.isEmpty(form)
          ? form.form_style.color.form_color
          : "#FFFFFF",
        fontFamily: !_.isEmpty(form)
          ? form.form_style.layout.font_style?.name
          : "Arial",
      }}
    > 
      <Droppable droppableId="form_elements" isDropDisabled={!isEditable}>
        {(provided, outerSnapshot) => (
          <div
            ref={provided.innerRef}
            className={`row ${isEditable ? "form_elements_builder" : ""}`}
            {...provided.droppableProps}
          >
            {elements.map((element, index) => {
        
       
              const widgetComponent =  getWidget(index, element, outerSnapshot);
              const isWidgetActive = activeWidget === index;
              return !element?.style?.[screen]?.hide && 
              <EditTabContext.Provider
              key={index}
              value={{ isActive: isWidgetActive, handleWidgetClick }}
            >
              <div
                ref={widgetRef}
                className={`animate ${isWidgetActive ? 'active' : ''}`}
                onClick={() => handleWidgetClick(index)}
              >
                {widgetComponent}
              </div>
            </EditTabContext.Provider>
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {isEditable && (
        <div className="row add-widget mt-5">
          <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center">
            {/* <button
              className="formify-btn"
              style={{
                fontSize: "16px",
                fontWeight: "600",
                padding: "12px 40px",
              }}
              onClick={() => {
                openHandler();
              }}
            >
              Add Widget
            </button> */}
            <p>Drag a widget here</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(FormCanvas);
