import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { mapAuthErrorsStateToProps } from "../utils";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import SuccessPopup from "./utils/SuccessPopup";
import _ from "lodash";

const Notification = ({ messages }) => {
  useEffect(() => {
    if (!_.isEmpty(messages)) {
      toast(<SuccessPopup content={messages.message} />);
    }
  }, [messages]);

  return <ToastContainer autoClose={3000} />;
};

export default connect(mapAuthErrorsStateToProps)(Notification);
