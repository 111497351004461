/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "../single-components/InputField";
import SwitchChip from "../single-components/switchChip";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import WidthOptions from "../single-components/WidthOptions";
import AlignmentTabs from "../single-components/AlignmentTabs";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const NumberContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  mergeWidget,
}) => {
  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const changeRequired = (val, ele) => {
    handleSetWidgetDispatcher([`content.${ele}`], [val], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.fontFamily`,
        `style.desktop.input.fontFamily`,
        `style.desktop.helpText.fontFamily`
      ], 
      [
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url }
      ], widget, setWidgetDispatcher, true
    );
  };

  const updateWidth = (e) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.width`], [e], widget, setWidgetDispatcher);
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorHelpText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.helpText.color`,
        `style.tablet.helpText.color`,
        `style.mobile.helpText.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateFontSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateInputSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateInputLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateFontSizeHelpText = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.helpText.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container font-family-picker">
          <FontFamilyPicker
            attribute={widget?.style?.desktop?.label}
            onChangeFont={updateLabelFont}
            id={"heading"}
          />
        </div>
        <div className="quick-action-container">
          <AlignmentTabs
            id={"alignment"} 
            tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
            handleSelect={handleChangeAlign}
          />
        </div>
        <div className="quick-action-container">
          <WidthOptions
            handleSelect={updateWidth} 
            tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
            id={"width"}
            name={"width"}
          />
        </div>
      </div>
      <InputField
        id={"title"}
        label={"Title"}
        onChange={handleChange}
        placeholder={"Title"}
        isTitle={true}
        type="text"
        value={widget.content.title}
        mergeWidget={mergeWidget}
      />
      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateFontSize}
            value={checkFontSize(widget?.style, responsive, "label", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateColorLabel}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "label", "color")}
          />
        </div>
      </div>
      <hr />

      <InputField
        id={"placeholder"}
        label={"Placeholder"}
        onChange={handleChange}
        value={widget.content.placeholder}
        placeholder={"Placeholder"}
        type="text"
      />
      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateInputSize}
            value={checkFontSize(widget?.style, responsive, "input", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateInputLabel}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "input", "color")}
          />
        </div>
      </div>
      <hr />

      <InputField
        id={"help_text"}
        label={"Help Text"}
        onChange={handleChange}
        value={widget.content.help_text}
        placeholder={"Help Text"}
        type="text"
      />

      <div className="editable-field  quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateFontSizeHelpText}
            defaultValue={checkFontSize(widget?.style, responsive, "helpText", "fontSize")}
            value={checkFontSize(widget?.style, responsive, "helpText", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateColorHelpText}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "helpText", "color")}
          />
        </div>
      </div>
      
      <hr />
      <InputField
        id={"min"}
        label={"Minimum Value"}
        onChange={handleChange}
        placeholder={"Minimum Value"}
        type="number"
        value={widget.content.min || 0}
      />
      <hr />

      <InputField
        id={"max"}
        label={"Maximum Value"}
        onChange={handleChange}
        placeholder={"Maximum Value"}
        type="number"
        value={widget.content.max || 0}
      />

      <hr />
      <div className="editable-field form-row mt-2">
        <p className="field-label">Required</p>
        <SwitchChip
          id="required"
          checked={widget.content ? widget.content.required : false}
          onChange={(val) => changeRequired(val, "required")}
        />
      </div>
      <div className="editable-field form-row mt-2">
        <p className="field-label">Hide Label</p>
        <SwitchChip
          id="hide_label"
          checked={widget.content ? widget.content.hide_label : false}
          onChange={(val) => {
            changeCheckBtn(val, "hide_label");
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(NumberContent);
