import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const ImageRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen, 
  form: { responses, responseError },
}) => {

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div
              style={{
                display: "flex",
                justifyContent: getResponsiveStyle(element?.style, screen, "align", null),
              }}
            >
              <img
                src={
                  element.content.src
                    ? element.content.src
                    : process.env.PUBLIC_URL +
                      "/assets/images/default_image.png"
                }
                width={
                  element.content.width ? `${element.content.width}px` : null
                }
                height={
                  element.content.height ? `${element.content.height}px` : null
                }
                alt={element.alt}
                className="img-fluid"
                style={checkResponsiveStyle(element.style, screen, null)}
                loading="lazy"
              />
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
})(ImageRender);
