import { handleSetWidgetDispatcher, handleSetFormDispatcher } from "./handleDispatcher"

export const lockAllValues = (e, keys, widget, setWidgetDispatcher, isForm = false, type) => {
  if(isForm){
    handleSetFormDispatcher(
      keys,
      [
        e.target.value + "px",
        e.target.value + "px",
        e.target.value + "px",
        e.target.value + "px"
      ], widget, setWidgetDispatcher
    );
  }else {
    handleSetWidgetDispatcher(
      keys,
      [
        e.target.value + "px",
        e.target.value + "px",
        e.target.value + "px",
        e.target.value + "px"
      ], widget, setWidgetDispatcher, false, type
    );
  }
};