import AddForm from "../utils/AddForm";

const NoForm = (props) => {

  return (
    <div className="no-data-container">
      <div>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/images/icons/missing-forms.svg"
          }
          alt="no-data"
        />
      </div>
      <AddForm text = {props.text}/>
    </div>
  );
};

export default NoForm;
