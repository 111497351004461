import ChangeResponsiveness from "./changeResponsiveness";

let firstTime = true;
const RangeSlider = ({
  label,
  id,
  tag,
  min,
  max,
  onChange,
  isPercentage = false,
  removeXPadding = false
}) => {
  const checkInputNumber = (e) => {
    var num = e;
    if (e < min) num = min;
    else if (e > max) num = max;
    return num;
  };

  const changeVal = (e) => {
    onChange(e, firstTime ? "focus" : "ignore");
    firstTime = false;
  }

  return (
    <div className={`editable-field form-column w-100 ${ removeXPadding && 'px-0' }`}>
      <div className="d-flex justify-between">
        <p> {label}</p>
        <div><ChangeResponsiveness /></div>
      </div>
      <div className="widget-range-slider-container">
        <input
          type="range"
          id={id}
          name={id}
          value={
            tag
              ? tag.substring(0, isPercentage ? tag.length - 1 : tag.length - 2)
              : ""
          }
          className="widget-range-slider"
          min={min}
          max={max}
          onChange={changeVal}
          onBlur={ e => {
            if(!firstTime) onChange(e, "blur")
            firstTime = true;
          }}
        />
        <input
          autoComplete="off"
          type="number"
          id={id}
          name={id}
          placeholder={""}
          defaultValue={""}
          value={
            tag
              ? checkInputNumber(
                  tag.substring(
                    0,
                    isPercentage ? tag.length - 1 : tag.length - 2
                  )
                )
              : ""
          }
          className="widget-range-slider input"
          min={min}
          max={max}
          onChange={changeVal}
          onBlur={ e => {
            if(!firstTime) onChange(e, "blur")
            firstTime = true;
          }}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
