import { Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL } from "../../../utils";

const PaymentMethods = ({ selectedPm, setPm }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${BASE_DEV_URL}/subscription/paymentmethods`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setPaymentMethods(res.data);
      })
      .catch((err) => {
      });
  }, []);

  const getAdddres = (pm) => {
    if (pm.billing_details.address.line1) {
      return `${pm.billing_details.address.line1} ${pm.billing_details.address.city}, ${pm.billing_details.address.country} ${pm.billing_details.address.postal_code}`;
    } else {
      return;
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="payment-cards">
      {paymentMethods.length > 0 ? (
        paymentMethods.map((pm) => {
          return (
            <div
              onClick={() => {
                setPm(pm.id);
              }}
              className={
                selectedPm
                  ? selectedPm === pm.id
                    ? "payment-card pointer default"
                    : "payment-card pointer"
                  : "payment-card pointer"
              }
              key ={pm.id}
            >
              <div className="card-type">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/VISA_logo.svg"
                  }
                  alt="visa-logo"
                />
              </div>
              <div className="card-ending">
                <p className="ending-number">{pm.billing_details.name}</p>
                <p className="ending-number">
                  {capitalizeFirstLetter(pm.card.brand)} ending in
                  {pm.card.last4}
                </p>
                <p>
                  Expiry {pm.card.exp_month}/{pm.card.exp_year}
                </p>
              </div>
              <div className="card-address">
                <p>Address</p>
                <p className="card-street">{getAdddres(pm)}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-payment-message">
          You have no payment methods added
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
