import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { getDynamicTagComponent } from "./renderersFunctions";
import { checkFontSize, checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const MultiChoiceRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  const checkmarkColor = element.style?.[screen]?.checkbox?.checkBackgroundColor
    ? element?.style?.[screen]?.checkbox?.checkBackgroundColor
        .match(/[0-9|.]+/g)
        .map((x, i) =>
          i === 3
            ? parseInt(255 * parseFloat(x)).toString(16)
            : parseInt(x).toString(16)
        )
        .join("")
        .substring(
          0,
          element?.style?.[screen]?.checkbox?.checkBackgroundColor
            .match(/[0-9|.]+/g)
            .map((x, i) =>
              i === 3
                ? parseInt(255 * parseFloat(x)).toString(16)
                : parseInt(x).toString(16)
            )
            .join("").length - 2
        )
    : "fff";

  const css = element.style ? `
  .widget-${element._id} input:checked {
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "backgroundColor") && ` background-color:  ${getResponsiveStyle(element?.style, screen, "checkbox", "backgroundColor")}`};
    ${ checkFontSize(element?.style, screen, "checkbox", "borderColor") &&  `border-color: ${checkFontSize(element?.style, screen, "checkbox", "borderColor")}px`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderTopLeftRadius") &&  `border-top-left-radius: ${checkFontSize(element?.style, screen, "checkbox", "borderTopLeftRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderTopRightRadius") &&  `border-top-right-radius: ${checkFontSize(element?.style, screen, "checkbox", "borderTopRightRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderBottomLeftRadius") &&  `border-bottom-left-radius: ${checkFontSize(element?.style, screen, "checkbox", "borderBottomLeftRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderBottomRightRadius") &&  `border-bottom-right-radius: ${checkFontSize(element?.style, screen, "checkbox", "borderBottomRightRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderRightWidth") &&  `border-right-width: ${checkFontSize(element?.style, screen, "checkbox", "borderRightWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderTopWidth") &&  `border-top-width: ${checkFontSize(element?.style, screen, "checkbox", "borderTopWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderBottomWidth") &&  `border-bottom-width: ${checkFontSize(element?.style, screen, "checkbox", "borderBottomWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderLeftWidth") &&  `border-left-width: ${checkFontSize(element?.style, screen, "checkbox", "borderLeftWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "borderStyle") &&  `border-style: ${checkFontSize(element?.style, screen, "checkbox", "borderStyle")}`};
    ${ getResponsiveStyle(element?.style, screen, "checkbox", "background-image")   ? `background-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23${checkmarkColor}' stroke-linecap='round' stroke-linejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/></svg>") !important;`
    : `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")`
    }
    ${"}"}`:``;

  const labelCss = element.content.hide_checkbox  ? ` `: null;
  let alignment = getResponsiveStyle(element?.style, screen, "alignment", null);
  let verticalAlignment = getResponsiveStyle(element?.style, screen, "verticalAlignment", null);
  let layout = getResponsiveStyle(element?.style, screen, "layout", null) || 1;
  let rowGap = getResponsiveStyle(element?.style, screen, "rowGap", null);
  let columnGap = getResponsiveStyle(element?.style, screen, "columnGap", null);
  let colNum = getResponsiveStyle(element?.style, screen, "colNum", null) || 3;
  let direction =  getResponsiveStyle(element?.style, screen, "direction", null);
  

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <style> {css} </style>
            <style> {labelCss}</style>
            <div className="container-fluid p-0">
              {!element.content.hide_label ? (
                <h4
                  className="formify-h4"
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "label"),
                    fontFamily: element.style?.desktop?.label?.fontFamily?.name
                  }}
                >
                  {getDynamicTagComponent(element.content.question, responses)}
                </h4>
              ) : null}
              <div
                className={
                  layout === "1"
                    ? "option-wrapper option-layout-1"
                    : "option-wrapper  option-layout-2"
                }
                style={
                  layout === "1"
                    ? {
                        textAlign: alignment,
                      }
                    : layout == "2"
                    ? {
                        display: "grid",
                        gridTemplateColumns: `repeat(${colNum},1fr)`,
                        columnGap: columnGap,
                        rowGap: rowGap,
                        textAlign: alignment,
                        fontFamily:  element.style?.desktop.label?.fontFamily?.name,
                      }
                    : {}
                }
              >
                {element.content.options.map((opt, index) => {
                  return (
                    <div key={index}>
                      {!isEditable ? (
                        <>
                          <input
                            className="me-2 form-check-input"
                            type="checkbox"
                            onChange={(e) => {
                              setWidgetResponsesDispatcher(
                                element._id,
                                {
                                  value: e.target.value,
                                },
                                "form_multi_choice",true
                              );
                            }}
                            value={opt.value ? opt.value : "Option" + index}
                            name={element._id}
                            id={element._id + "" + index}
                            checked={
                              responses[element._id] &&
                              responses[element._id]?.value.includes(
                                opt.value ? opt.value : "Option" + index
                              )
                            }
                            style={{ display: "none" }}
                          />
                        </>
                      ) : (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={element._id}
                          id={element._id + "" + index}
                          style={{ display: "none" }}
                        />
                      )}
                      <label
                        className="form-check-label "
                        htmlFor={element._id + "" + index}
                        style={{
                          ...checkResponsiveStyle(element.style, screen, "option"),
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                      <div
                          className="form-check form-single-choice"
                          key={index}
                          style={{
                            ...checkResponsiveStyle(element.style, screen, "container"),
                            display: "flex",
                            width: "100%",
                            position: "relative",
                            justifyContent:  alignment === "left"  ? "start" : alignment === "right"? "end": alignment,
                            fontFamily:   element.style?.desktop?.label?.fontFamily?.name,
                            alignItems: verticalAlignment === "left"   ? "start" : verticalAlignment === "right"  ? "end": verticalAlignment,
                            flexDirection: direction
                          }}
                        >
                          {element.content.hide_checkbox && (responses[element._id] &&
                            responses[element._id]?.value.includes(
                              opt.value ? opt.value : "Option" + index
                            ) && (
                              <span className="selected-checkbox">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="none"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="3"
                                    d="M6 10l3 3l6-6"
                                  />
                                </svg>
                              </span>
                            ))}
                          {!isEditable ? (
                            <>
                              <input
                                className="me-2 form-check-input"
                                type="checkbox"
                                onChange={(e) => {
                                  setWidgetResponsesDispatcher(
                                    element._id,
                                    {
                                      value: e.target.value,
                                    },
                                    "form_multi_choice",true
                                  );
                                }}
                                style={
                                  element.content.hide_checkbox
                                    ? {
                                      ...checkResponsiveStyle(element.style, screen, "checkbox"),
                                        display: "none",
                                      }
                                    : checkResponsiveStyle(element.style, screen, "checkbox")
                                }

                                value={opt.value ? opt.value : "Option" + index}
                                name={element._id}
                                id={element._id + "" + index}
                                checked={
                                  responses[element._id] &&
                                  responses[element._id]?.value.includes(
                                    opt.value ? opt.value : "Option" + index
                                  )
                                }
                              />
                            </>
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={element._id}
                              id={element._id + "" + index}
                              checked={true}
                              style={
                                element.content.hide_checkbox
                                  ? {
                                    ...checkResponsiveStyle(element.style, screen, "checkbox"),
                                    display: "none",
                                    }
                                    : checkResponsiveStyle(element.style, screen, "checkbox")
                              }
                            />
                          )}
                          {opt.image ? (
                            <img
                              className="img-fluid"
                              style={{
                                ...checkResponsiveStyle(element.style, screen, "image"),
                                fontFamily:  element?.style?.desktop?.helpText?.fontFamily?.name
                              }}
                              src={opt.image}
                              alt="Option"
                            />
                          ) : null}
                          <p
                            style={{
                              display: "flex",
                              display: "flex",
                              marginBottom: "0",
                              flexDirection: element.content.direction,
                              fontFamily: element?.style?.desktop?.label?.fontFamily?.name,
                              alignItems: verticalAlignment === "left"   ? "start" : verticalAlignment === "right"  ? "end": verticalAlignment,
                              justifyContent:  alignment === "left"  ? "start" : alignment === "right"? "end": alignment,

                            }}
                          >
                            <span>{opt.value}</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
              {responseError ? (
                Object.keys(responseError).length > 0 ? (
                  <p
                    className="error-message"
                    style={{
                      fontFamily: element.style?.desktop?.label?.fontFamily?.name,
                    }}
                  >
                    {responseError[element._id]}
                  </p>
                ) : null
              ) : null}
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(MultiChoiceRender);
