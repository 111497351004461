/* eslint-disable react-hooks/exhaustive-deps */
import { X } from "react-feather";
import SwitchChip from "../single-components/switchChip";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import WidthOptions from "../single-components/WidthOptions";
import AlignmentTabs from "../single-components/AlignmentTabs";
import InputField from "../single-components/InputField";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const DropdownContent = ({
  mergeWidget,
  form: { widget, responsive },
  setWidgetDispatcher
}) => {

  const addOption = () => {
    if (widget.content) {
      const newOption = `Option ${widget.content.options.length + 1}`;
      const options = Array.from(widget.content.options);
      options.push(newOption);
      handleSetWidgetDispatcher([`content.options`], [options], widget, setWidgetDispatcher);
    }
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };
  
  const removeOpt = (index) => {
    if (widget.content) {
      const options = Array.from(widget.content.options);
      options.splice(index, 1);
      handleSetWidgetDispatcher([`content.options`], [options], widget, setWidgetDispatcher);
    }
  };

  const handleOptionChange = (e, index, type) => {
    if (widget.content) {
      const options = Array.from(widget.content.options);
      options[index] = e.target.value;
      handleSetWidgetDispatcher([`content.options`], [options], widget, setWidgetDispatcher, false, type);
    }
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, src) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.fontFamily`,
        `style.desktop.input.fontFamily`
      ], 
      [
        { name: name, src: src },
        { name: name, src: src }
      ], widget, setWidgetDispatcher
    );
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher, true
    );
  };

  const updateColorOption = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorHelpText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.helpText.color`,
        `style.tablet.helpText.color`,
        `style.mobile.helpText.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateFontSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateFontSizeOptions = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateFontSizeHelpText = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.helpText.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  return (
    <div className="settings-field-container">
      <div className="row">
        <div className="editable-field  quick-form-actions">
          <div className="quick-action-container font-family-picker">
            <FontFamilyPicker
              attribute={widget?.style?.desktop?.label}
              onChangeFont={updateLabelFont}
              id={"label"}
            />
          </div>
          <div className="quick-action-container">
            <WidthOptions
              handleSelect={updateInputSelectstyle} 
              tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
              id={"width"}
              name={"width"}
            />
          </div>
          <div className="quick-action-container">
            <AlignmentTabs 
              id={"alignment"}
              tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
              handleSelect={handleChangeAlign}
            />
          </div>
        </div>
        <hr />
        <div className="editable-field quick-form-actions pt-1 font-family-picker">
          <p className="field-heading widget-label m-0">Question</p>
          <div className="col-lg-12 mb-3">
            <div className="input-holder mt-0">
              <InputField
                id={"question_text"}
                label={"Title"}
                isTitle={true}
                onChange={handleChange}
                placeholder={"Heading"}
                type="text"
                value={widget.content.question_text || ""}
                mergeWidget={mergeWidget}
              />
            </div>
          </div>
        </div>
        <div className="editable-field quick-form-actions pt-1 font-family-picker">
          <div className="quick-action-container">
            <div className="d-flex justify-between">
              <p>Font Size(px)</p>
              <div className="mb-1"><ChangeResponsiveness /></div>
            </div>
            <FormifyInput 
              type="number"
              id={"fontSize"}
              min={1}
              max={200}
              onChange={updateFontSize}
              value={checkFontSize(widget?.style, responsive, "label", "fontSize")}
            />
          </div>
          <div className="editable-field w-100 form-row mt-3 mb-0">
            <p>Font Color</p>
            <ColorPickerStyle
              onChange={updateColorLabel}
              id={"color"}
              tag={getResponsiveStyle(widget?.style, responsive, "label", "color")}
            />
          </div>
        </div>
        <hr />
        <div className="editable-field quick-form-actions font-family-picker">
          <div className="w-100">
            <p className="field-heading widget-label">Options</p>
            {widget.content
              ? widget.content.options.map((op, index) => {
                  return (
                    <>
                      <div className="row-data">
                        <FormifyInput 
                          type="text"
                          id={"text"}
                          onChange={(e, type) => {
                            handleOptionChange(e, index, type);
                          }}
                          value={op || ""}
                        />
                        <div
                          className="remove-btn"
                          href="#/"
                          onClick={() => {
                            removeOpt(index);
                          }}
                        >
                          <X style={{ color: "#ea4335" }} />
                        </div>
                      </div>
                    </>
                  );
                })
              : null}

            <div className="col-lg-6">
              <button className="add-option" onClick={addOption}>
                Add Option
              </button>
            </div>
          </div>
        </div>
        <div className="editable-field quick-form-actions font-family-picker mt-3">
          <p className="field-heading m-0 mt-1">Default Selected</p>
          <div className="col-lg-12 ">
            <div className="input-holder mt-0">
              <select
                value={widget.content.default || null}
                onChange={(e) => {
                  handleSetWidgetDispatcher([`content.default`], [e.target.value], widget, setWidgetDispatcher);
                }}
              >
                <option value={null}>No default</option>
                {widget.content.options.map((op, index) => {
                  return <option value={op} key={index}>{op}</option>;
                })}
              </select>
            </div>
            <div className="editable-field  quick-form-actions pt-4 font-family-picker">
              <div className="quick-action-container">
                <div className="d-flex justify-between">
                  <p>Font Size(px)</p>
                  <div className="mb-1"><ChangeResponsiveness /></div>
                </div>{" "}
                <FormifyInput 
                  type="number"
                  id={"fontSize"}
                  min={1}
                  max={200}
                  onChange={updateFontSizeOptions}
                  value={checkFontSize(widget?.style, responsive, "input", "fontSize")}
                />
              </div>
              <div className="editable-field w-100 form-row mt-3 mb-0">
                <p>Font Color</p>
                <ColorPickerStyle
                  onChange={updateColorOption}
                  id={"color"}
                  tag={getResponsiveStyle(widget?.style, responsive, "input", "color")}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="editable-field quick-form-actions font-family-picker">
          <p className="field-heading m-0">Help Text</p>
          <div className="col-lg-12 ">
            <div className="input-holder mt-0">
              <FormifyInput 
                type="text"
                id={"help_text"}
                onChange={handleChange}
                value={widget.content.help_text || null}
              />
            </div>
          </div>
          <div className="editable-field  quick-form-actions pt-4 font-family-picker">
            <div className="quick-action-container">
              <div className="d-flex justify-between">
                <p>Font Size(px)</p>
                <div className="mb-1"><ChangeResponsiveness /></div>
              </div>
              <FormifyInput 
                type="number"
                id={"fontSize"}
                min={1}
                max={200}
                onChange={updateFontSizeHelpText}
                value={checkFontSize(widget?.style, responsive, "helpText", "fontSize")}
              />
            </div>
            <div className="editable-field w-100 form-row mt-3 mb-0">
              <p>Font Color</p>
              <ColorPickerStyle
                onChange={updateColorHelpText}
                id={"color"}
                tag={getResponsiveStyle(widget?.style, responsive, "helpText", "color")}
              />
            </div>
          </div>
        </div>
        
        <hr />
        <div className="editable-field form-row mt-2">
          <p className="field-label">Required</p>
          <SwitchChip
            id="required"
            checked={widget.content.required || false}
            onChange={(val) => {
              changeCheckBtn(val, "required");
            }}
          />
        </div>
        <div className="editable-field form-row mt-2">
          <p className="field-label">Hide Label</p>
          <SwitchChip
            id="hide_label"
            checked={widget.content.hide_label || false}
            onChange={(val) => {
              changeCheckBtn(val, "hide_label");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(DropdownContent);
