import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrors } from "../../../actions/errors";
import { connectKlaviyo } from "../../../actions/integrations";
import { usePrevious } from "../../../hooks/usePrevious";
import { mapAuthErrorsStateToProps } from "../../../utils";

const KlaviyoIntegration = ({ auth, errors, clearErrors, connectKlaviyo }) => {
  const nav = useNavigate();
  const [form, setForm] = useState({
    api_key: auth.user
      ? auth.user.crm.klaviyo
        ? auth.user.crm.klaviyo.api_key
        : ""
      : "",
  });

  const apiKey = useRef(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    connectKlaviyo(form);
  };

  const prevAuth = usePrevious(auth.user);

  useEffect(() => {
    if (prevAuth) {
      if (auth.user.crm !== prevAuth.crm) {
        nav("/profile/integrations/all");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.crm]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Overlay
        target={apiKey.current}
        show={
          errors.errors ? (errors.errors["crm.klaviyo"] ? true : false) : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["crm.klaviyo"]
                ? errors.errors["crm.klaviyo"].properties.message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={apiKey.current}
        show={
          errors.errors
            ? errors.errors["crm.klaviyo.api_key"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["crm.klaviyo.api_key"]
                ? errors.errors["crm.klaviyo.api_key"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>

      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/klaviyo.png"}
              alt="Shopify"
              className="img-fluid me-2"
              style={{ maxHeight: "30px", maxWidth: "30px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <h4 className="integration-page-header">
          Connect your form to Klaviyo
        </h4>
        <p className="grey-paragraph">
          Start by selecting the form you want to connect to your klaviyo
          account
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-5">
          <div className="formify-card">
            <h4 className="integration-page-header">
              Authenticate Klaviyo Account
            </h4>
            <p className="grey-paragraph">
              Enter the following information to authenticate your account.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="input-holder position-relative">
                      <label htmlFor="api_key">API Key</label>
                      <input
                        type="text"
                        id="api_key"
                        name="api_key"
                        onChange={handleChange}
                        value={form.api_key || ""}
                        placeholder="7bda05ae28047fd0e2abc8c95e585175"
                        ref={apiKey}
                      />
                    </div>

                    <button type="submit" className="formify-btn mt-3">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  clearErrors,
  connectKlaviyo,
})(KlaviyoIntegration);
