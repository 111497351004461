import React from "react";
import BarLoader from "react-bar-loader";
import { connect } from "react-redux";

const FormifyLoader = ({
  removeLogo = true,
  text = "Redirecting, please wait..",
}) => {
  return (
    <div className="height-body">
      <div className="center-div-loading">
        {!removeLogo ? (
          <div className="power-by">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/formify_logo_icon.svg"
              }
              alt="formify-logo"
            />
            <p>
              {" "}
              Powered by <br /> Formify
            </p>
          </div>
        ) : (
          <p>{text}</p>
        )}
        <BarLoader color="#4e4bfa" height="4" className="w-100 mt-2" />
      </div>
    </div>
  );
};

export default connect()(FormifyLoader);
