const RandomAvatars = () => {
 
 const avatars = ["avatar01","avatar02","avatar03","avatar04","avatar05","avatar06"];
 const gradient = ["gradient01","gradient02","gradient03"];

 const randomAvatar = () => {
   return Math.floor(Math.random() * 6);
 }
 const randomGradient = () => {
  return Math.floor(Math.random() * 3);
}

  return (
    <div className={`random-avatars  ${avatars[randomAvatar()]} ${gradient[randomGradient()]}`}>
      <span className="avatar-icon"></span>
    </div>
  );
};

export default RandomAvatars;
