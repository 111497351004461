import {
  SET_FORM,
  SET_WIDGET,
  SET_WIDGET_STEP_POSITION,
  SET_FORM_LOADING,
  SET_FORM_STATES,
  RESET_FORM_STATE,
  SET_RESPONSES_ERROR_MESSAGE,
  SET_RESPONSES,
  SET_RESPONSIVE,
  SET_ACTIVE_FORM_STEP,
  FORM_REDO,
  FORM_UNDO,
  SET_CLIENT_EMAIL,
} from "./types";
import _, { create } from "lodash";
import { validateResponse } from "../components/utils/formResponseValidations";
import { LAST_INPUT_ELEMENTS, NO_RESPONSE_ELEMENTS } from "../utils";

export const setForm = (form, updated = false, inputType) => {
  return {
    type: SET_FORM,
    payload: { form, updated, inputType },
  };
};

export const setWidget = (widget) => {
  return {
    type: SET_WIDGET,
    payload: widget,
  };
};

export const setClientMail = (mail, id) => {
  return {
    type: SET_CLIENT_EMAIL,
    mail: mail,
    id: id
  };
};

export const setWidgetStepAndPosition = (step, position) => {
  return {
    type: SET_WIDGET_STEP_POSITION,
    payload: { step, position },
  };
};

export const setResponse = (responses) => {
  return {
    type: SET_RESPONSES,
    payload: responses,
  };
};

export const setResponseErrorMessage = (errorMessages) => {
  return {
    type: SET_RESPONSES_ERROR_MESSAGE,
    payload: errorMessages,
  };
};

export const setFormActiveStep = (step) => {
  return {
    type: SET_ACTIVE_FORM_STEP,
    step: step,
  };
};

export const resetFormState = () => {
  return {
    type: RESET_FORM_STATE,
  };
};

export const setFormLoading = (loading) => {
  return {
    type: SET_FORM_LOADING,
    payload: loading,
  };
};

export const setFormStates = (sideBar = true, state = "openWidgets") => {
  return {
    type: SET_FORM_STATES,
    payload: { sideBar, state },
  };
};

export const setResponsive = (responsive) => {
  return {
    type: SET_RESPONSIVE,
    payload: responsive,
  };
};

export const setResponsiveDispatcher = (responsive) => (dispatch) => {
  dispatch(setResponsive(responsive));
};

export const setFormDispatcher = (form, updated, inputType) => (dispatch) => {
  dispatch(setForm(form, updated, inputType));
};

export const setFormFontDispatcher =
  (form, updated, inputType, fonts) => (dispatch) => {
    let newForm = _.cloneDeep(form);
    let id = newForm._id;

    newForm = updateFont(newForm, fonts, id);

    dispatch(setForm(newForm, updated, inputType));
  };

export const setWidgetDispatcher =
  (widget, fonts = null, updated = true, inputType) =>
  (dispatch, getState) => {
    const { form, widgetStep, widgetPosition } = getState().form;
    if (updated) {
      let newForm = _.cloneDeep(form);
      newForm.steps[widgetStep].elements[widgetPosition] = widget;

      if (fonts) {
        newForm = updateFont(newForm, fonts, widget._id);
      }

      dispatch(setForm(newForm, updated, inputType));
    }
    dispatch(setWidget(widget));
  };

const updateFont = (newForm, fonts, id) => {
  newForm.font_family = !newForm.font_family ? {} : newForm.font_family;

  Object.keys(newForm.font_family).map((i, j) => {
    if (newForm?.font_family[i]?.widgets.includes(id)) {
      var index = newForm?.font_family[i].widgets.indexOf(id);
      if (index !== -1) newForm?.font_family[i].widgets.splice(index, 1);
    }

    if (newForm?.font_family[i].widgets.length === 0)
      delete newForm.font_family[i];
  });

  newForm.font_family[fonts.name] = {
    src: fonts.src,
    widgets: [id],
  };
  return newForm;
};
export const setWidgetStepAndPositionDispatcher =
  (step, position) => (dispatch) => {
    dispatch(setWidgetStepAndPosition(step, position));
  };

export const setFormLoadingDispatcher = (loading) => (dispatch) => {
  dispatch(setFormLoading(loading));
};

export const setFormStatesDispatcher =
  (sideBar = true, state = "openWidgets") =>
  (dispatch) => {
    dispatch(setFormStates(sideBar, state));
  };

export const resetFormStateDispatcher = () => (dispatch) => {
  dispatch(resetFormState());
};

// RESPONSE HANDLERS
export const setResponsesDispatcher = (responses) => (dispatch) => {
  dispatch(setResponse(responses));
};

export const setWidgetResponsesDispatcher =
  (id, response, type, allowMultipleOptions = false) =>
  (dispatch, getState) => {
    const { responses } = getState().form; 
    let newResponses = _.cloneDeep(responses);  
    //if array push
    if (newResponses[id].value instanceof Array) {
      let data = newResponses[id].value;

      if (allowMultipleOptions) {
        data.includes(response.value)
          ? (data = newResponses[id].value.filter(
              (item) => item !== response.value
            ))
          : data.push(response.value);
      } else data = [response];
      newResponses[id].value = data;
    } else if (typeof response === "object") {  
      Object.keys(response).map((key,i)=>{
      newResponses[id][key] = Object.values(response)[i]; 
      })
    } else newResponses[id] = response;

    dispatch(setResponse(newResponses));
    if (type === "form_email") dispatch(setClientMail(response.email, id));
    if (LAST_INPUT_ELEMENTS.includes(type)){
      updatePageImmediately(getState, dispatch);

    }
  };

const updatePageImmediately = (getState, dispatch) => {
  const { form, activeFormStep, responses } = getState().form;
  let ids = [];
  let inputElements = [];
  let error = false;

  if (form.steps.length > activeFormStep) {
    if (form) {
      form.steps[activeFormStep - 1].elements.forEach((data) => {
        if (!NO_RESPONSE_ELEMENTS.includes(data.element_type)) {
          inputElements.push(data._id);
          if (LAST_INPUT_ELEMENTS.includes(data.element_type)) {
            if((data.element_type == "form_product" || data.element_type == "form_dynamic_product") && form.store_selected == "wocommerce"){
              if(data.content?.products?.some(item => item.attributes?.length > 0)){}
              else ids.push(data._id);
            }else {
              ids.push(data._id);
            }
          }
        }
      });
    }

    if (ids.length > 0) {
      if (ids.length === 1 && inputElements.length === 1) {
        dispatch(setFormActiveStep(activeFormStep + 1));
      } else {
        let errorMessages = validateResponse(activeFormStep);

        inputElements.forEach((id) => {
          if (responses[id]) error = true;
        });

        setResponseErrorDispatcher(errorMessages);
        if (!error) {
          dispatch(setFormActiveStep(activeFormStep + 1));
        }
      }
    }
  }
};

export const setErrorForOneWidget = (data) => (dispatch, getState) => {
  const { responseError } = getState().form;
  let newErrorMessages = _.cloneDeep(responseError);
  newErrorMessages[data.value] = data.message;

  dispatch(setResponseErrorMessage(newErrorMessages));
};

export const setResponseErrorDispatcher = (errorMessages) => (dispatch) => {
  dispatch(setResponseErrorMessage(errorMessages));
};

export const setFormActiveStepDispatcher = (step) => (dispatch) => {
  dispatch(setFormActiveStep(step));
};

export const formUndoDispatcher = () => (dispatch) => {
  dispatch({
    type: FORM_UNDO,
  });
};

export const formRedoDispatcher = () => (dispatch) => {
  dispatch({
    type: FORM_REDO,
  });
};
