const SearchInput = ({ setSearch, className }) => {
  return (
    <div className={`modal-search-input position-relative ${className}`}>
      <input onChange={ e => setSearch(e.target.value) } type="text" name="search-container" placeholder="Search free high-resolution photos" />
      <div className="icon">
        <svg class="SVGInline-svg" width="13" height="13" viewBox="0 0 13 13" fill="#898989" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.9502 8.77257c.6725-.91404 1.0744-2.03855 1.0744-3.26027C11.0246 2.4675 8.55709 0 5.5123 0 2.4675 0 0 2.4675 0 5.5123c0 3.04479 2.4675 5.5123 5.5123 5.5123 1.21671 0 2.33721-.3989 3.24824-1.06639l1.86816 1.89819c.6545.6545 1.7168.6545 2.3713 0L9.9502 8.77257zM1.60358 5.5123c0-2.15581 1.75391-3.90872 3.90872-3.90872 2.1548 0 3.90872 1.75391 3.90872 3.90872 0 2.1548-1.75392 3.90872-3.90872 3.90872-2.15481 0-3.90872-1.75392-3.90872-3.90872z"></path>
        </svg>
      </div>
    </div>
  );
};

export default SearchInput;