import _ from "lodash";
import set from "lodash/fp/set";

export const handleSetWidgetDispatcher = (keys, values, widget, setWidgetDispatcher, updateFont = false, inputType = "") => {
  let newWidget = { ...widget }
  let fonts = null;

  for(let i=0; i < keys.length; i++){
    newWidget = set(keys[i], values[i], newWidget);
  }

  if(updateFont) fonts = values[0];
  setWidgetDispatcher(newWidget, fonts, true, inputType)
}

export const handleSetFormDispatcher = (keys, values, form, setFormDispatcher, inputType = "") => {
  let newForm = { ...form }
  for(let i=0; i < keys.length; i++){
    newForm = set(keys[i], values[i], newForm);
  }
  setFormDispatcher(newForm, true, inputType);
}

export const handleFontFormDispatcher  = (keys, values, form, setFormFontDispatcher, inputType = "") => {
  let newForm = { ...form };
  let fonts = values[0];

  for(let i=0; i < keys.length; i++){
    newForm = set(keys[i], values[i], newForm);
  }
  setFormFontDispatcher(newForm, true, inputType, fonts);
}

export const handleSetForm = (keys, values, form) => {
  let newForm = { ...form }
  for(let i=0; i < keys.length; i++){
    newForm = set(keys[i], values[i], newForm);
  }
  // setFormDispatcher(newForm, true);
  return newForm;
}