import axios from "axios";
import { BASE_DEV_URL } from "../utils";
import {
  CLEAR_ERRORS,
  DELETE_USER,
  GET_USER,
  GET_USER_ERROR,
  GOOGLE_AUTH,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  UPDATE_USER,
  USER_LOADING,
} from "./types";
import { getErrors } from "./errors";
import { getMessages } from "./messages";
import { setLoader } from "./loader";

export const getUser = (user) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });
  axios
    .get(`${BASE_DEV_URL}/auth/user`, tokenConfig(getState))
    .then((res) => { 
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
     
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_ERROR,
      });
    });
};

export const loginUser = (user) => (dispatch) => {
  dispatch(setLoader(true));
  axios
    .post(`${BASE_DEV_URL}/auth/login`, user)
    .then((res) => {
      dispatch({
        type: LOGIN,
        payload: res.data,

      });
      
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_ERROR,
      });
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const registerUser = (user) => (dispatch) => {
  axios
    .post(`${BASE_DEV_URL}/auth/register`, user)
    .then((res) => {
      dispatch({
        type: REGISTER_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
  });
};

export const googleAuth = (payload) => (dispatch) => {
  axios
    .post(`${BASE_DEV_URL}/auth/google`, payload)
    .then((res) => {
      dispatch({
        type: GOOGLE_AUTH,
        payload: res.data,
      });
    })
    .catch((err) => {
    });
};

export const sendPasswordResetEmail = (email) => (dispatch) => {
  axios
    .post(`${BASE_DEV_URL}/auth/reset/password/send`, email)
    .then((res) => {
      dispatch(getMessages(res.data));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const resetPassword = (data) => (dispatch) => {
  dispatch(setLoader(true));
  axios
    .post(`${BASE_DEV_URL}/auth/reset/password`, data)
    .then((res) => {
      dispatch(getMessages(res.data));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const updateUserName = (data) => (dispatch, getState) => {
  axios
    .put(`${BASE_DEV_URL}/user/update/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CLEAR_ERRORS,
      });
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getMessages({ message: "Profile is updated", succ: true }));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const updateProfilePic = (data) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config.headers["Content-Type"] = "multipart/form-data";

  dispatch(setLoader(true));
  axios
    .put(`${BASE_DEV_URL}/user/update/profile`, data, config)
    .then((res) => {
      dispatch({
        type: CLEAR_ERRORS,
      });
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(
        getMessages({
          message: "Profile is updated",
          succ: true,
        })
      );
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};

export const changeImageGallery = (data) => (dispatch, getState) => {
  axios
    .put(
      `${BASE_DEV_URL}/user/update/profile/gallery`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: CLEAR_ERRORS,
      });
      dispatch(getMessages({ message: "Profile is updated", succ: true }));
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};
export const changeProductLabelGallery = (data) => (dispatch, getState) => {
  axios
    .put(
      `${BASE_DEV_URL}/user/update/profile/product-labels/`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: CLEAR_ERRORS,
      });
      dispatch(getMessages({ message: "Product label added", succ: true }));
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

export const deleteUser = () => (dispatch, getState) => {
  axios
    .delete(`${BASE_DEV_URL}/user/delete`, tokenConfig(getState))
    .then((res) => {
      dispatch(getMessages({ ...res.data }));
      dispatch({
        type: DELETE_USER,
      });
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
    });
};

const config = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const updateUserRedux = (user) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: { user },
  });
};

export const tokenConfig = (getState) => {
  const conf = config();
  const token = getState().auth.token;
  if (token) conf.headers["Authorization"] = `Bearer ${token}`;

  return conf;
};
