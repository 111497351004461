/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { ArrowRight, Plus, X } from "react-feather";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import {
  BASE_DEV_URL,
  formGetCrmFields,
  integrationFooter,
  loginMailChimp,
  mapAuthAndFormStateToProps
} from "../../../utils";
import { disconnectIntegration } from "../../utils/formFunctions";
import { setFormDispatcher } from "../../../actions/form";
import { connect } from "react-redux";
import { handleSetForm } from "../../utils/handleDispatcher";
import { integrationAccountValidation } from "../../utils/formBackendFunctions";

const MailChimp = ({ auth, form: { form }, setFormDispatcher, disconnectIntegration }) => {
  const params = useParams()
  const [mailChimpList, setList] = useState([]);
  const [mailChimpField, setFields] = useState([]);
  const [selectedList, setSelectedList] = useState(localStorage.getItem('selectedList') || 'Select a list here');
  const [formData, setForm] = useState({
    fields: [],
    list: null,
    elements: [],
    show: false,
  });

  const mailChimpIsAuth = useMemo(() => {
    return auth.user
      ? auth.user.crm
        ? auth.user.crm.mailchimp
          ? true
          : false
        : false
      : false;
  }, [auth])

  const formHasMailchimp = useMemo(() => {
    return form
      ? form.integrations
        ? form.integrations.mailchimp
          ? true
          : false
        : false
      : false;
  }, [form])

  useEffect(() => {
    if(mailChimpIsAuth){
      axios
        .get(`${BASE_DEV_URL}/crm/mailchimp/lists`, tokenConfig(store.getState))
        .then((result) => {
          setList([...result.data.lists]);
          if(result.data.lists.length > 0){
            setForm((prevState) => ({
              ...prevState,
              list: result.data.lists[0].id
            }))
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }   
  }, [mailChimpIsAuth]);

  useEffect(() => {
    if (
      form &&
      form.steps &&
      form.steps.some((step) => {
        return step.elements;
      })
    ) {
      let elements = formGetCrmFields(form);

      if (formHasMailchimp) {
        setForm({
          ...formData,
          fields: form.integrations.mailchimp.fields,
          list: form.integrations.mailchimp.list,
          elements,
        });
      } else {
        setForm({
          ...formData,
          // show: true
          elements,
          fields: [
            {
              integration_field: "email",
              formify_field: elements.length > 0 ? elements[0]._id : null,
            },
          ],
        });
      } 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasMailchimp, form]);

  useEffect(() => {
    if (formData.list) {
      axios
        .get(
          `${BASE_DEV_URL}/crm/mailchimp/${formData.list}/fields`,
          tokenConfig(store.getState)
        )
        .then((result) => {
          setFields([...result.data.fields, { name: "email", tag: "email" }]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formData.list]);

  const handleIntegrationChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], integration_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const handleFormifyChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], formify_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!integrationAccountValidation(auth, "Mail Chimp")) return;

    const updatedForm = handleSetForm([`integrations.mailchimp`], [{ list: formData.list, fields: formData.fields }], form);
    setFormDispatcher(updatedForm, true);
    toast.success("Fields are created");
  };

  const authenticateBody = () => {
    return (
      <div className="col-12 integration-body mt-4">
        {mailChimpIsAuth ? (
          formHasMailchimp ? (
            <>
              <h4 className="formify-h4">Integration is up and running!</h4>
              <p className="formify-sm-p">
                You have successfully created your integration.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                }}
              >
                Edit Configuration
              </button>
            </>
          ) : (
            <>
              <h4 className="formify-h4">Mailchimp Configuration</h4>
              <p className="formify-sm-p">
                Give Shopify access to your form in order to import products to
                Formify.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                  // const updatedForm = handleSetForm([`integrations.mailchimp`], [{ list: mailChimpList[0].id, fields: [] }], form);
                  // setFormDispatcher(updatedForm, true);
                }}
              >
                Configure Mailchimp
              </button>
            </>
          )
        ) : (
          <>
            <h4 className="formify-h4">Mailchimp Authenticaiton</h4>
            <p className="formify-sm-p">
              Give Shopify access to your form in order to import products to
              Formify.
            </p>
            <button
              onClick={() => {
                window.open(loginMailChimp);
              }}
              className="formify-btn-no-margin mt-3"
            >
              Authenticate
            </button>
          </>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    setForm({ ...formData, [e.target.id]: e.target.value });
    const value = e.target.value;
    setSelectedList(value);
    localStorage.setItem('selectedList', value);
  };

  const removeRow = (index) => {
    const fields = Array.from(formData.fields);
    fields.splice(index, 1);

    setForm({
      ...formData,
      fields: fields,
    });
  };

  const mailChimpForm = () => {
    return (
      <div className="col-8 integration-form">
        <form onSubmit={handleSubmit}>
          <hr className="grey-hr" />
          <div className="input-holder">
            <label htmlFor="sobject">Choose a List</label>
            <p className="formify-sm-p">
              Select the list you want to send responses to
            </p>
            <select
             value={selectedList}
              id="list"
              onChange={handleChange}
            >
              {mailChimpList.length > 0
                ? mailChimpList.map((list) => {
                    return (
                      <option key={list.id} value={list.id}>
                        {list.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <hr className="grey-hr" />

          {formData.list ? (
            <div className="input-holder">
              <label>Match Fields</label>
              <p className="formify-sm-p">
                Connect the fields on your form with the fields on your
                Mailchimp list.
              </p>
              {formData.fields.map((field, index) => {
                return (
                  <div className="form-input">
                    <div className="me-3">
                      <label className="formify-sm-p">Mailchimp Field</label>
                      <select
                        value={field.integration_field}
                        className="integration-select"
                        disabled={field.integration_field === "email"}
                        style={
                          field.integration_field === "email"
                            ? { cursor: "not-allowed" }
                            : null
                        }
                        onChange={(e) => {
                          handleIntegrationChange(e, index);
                        }}
                      >
                        {mailChimpField.map((prop) => {
                          return (
                            <option value={prop.tag}>
                              {prop.name.charAt(0).toUpperCase() +
                                prop.name.slice(1)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="me-2">
                      <label className="formify-sm-p">Formify Field</label>
                      <select
                        id="email"
                        value={field.formify_field}
                        onChange={(e) => {
                          handleFormifyChange(e, index);
                        }}
                        className="integration-select"
                      >
                        {formData.elements.map((element) => {
                          return (
                            <option value={element._id} key={element._id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {field.integration_field === "email" ? null : (
                      <div
                        className="remove-row"
                        onClick={() => {
                          removeRow(index);
                        }}
                      >
                        <X style={{ color: "#ea4335" }} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}

          {formData.list ? (
            <>
              <div
                className="add-new-field d-flex mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (formData.fields.length < mailChimpField.length) {
                    setForm({
                      ...formData,
                      fields: [
                        ...formData.fields,
                        {
                          integration_field:
                            mailChimpField.length > 0
                              ? mailChimpField[0].tag
                              : null,
                          formify_field:
                            formData.elements.length > 0
                              ? formData.elements[0]._id
                              : null,
                        },
                      ],
                    });
                  } else {
                    toast.warning("You have reached the limit");
                  }
                }}
              >
                <Plus className="formify-color" />
                <p className="formify-p formify-color">Add new field</p>
              </div>
              <div className="d-flex flex-row ">
                <button className="formify-btn me-2">Save</button>
                <button
                  className="invert-formify-btn"
                  type="button"
                  onClick={() => {
                    setForm({ ...formData, show: false });
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid integraiton mt-5">
      <div className="row justify-content-center">
        <div className="col-12  integraiton-card ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="integration-header">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />
                  <ArrowRight
                    className="me-2"
                    style={{ color: "#6563FF", width: "20px", height: "20px" }}
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/mailchimp.png"
                    }
                    alt="Mailchimp"
                    className="img-fluid"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                {formData.show ? mailChimpForm() : authenticateBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {integrationFooter(formHasMailchimp, disconnectIntegration, "mailchimp", params)}
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormDispatcher, disconnectIntegration })(MailChimp);
