import { ChevronLeft, Search } from "react-feather";
import { Drawer } from "@mui/material";
import { Accordion } from "react-bootstrap";

import { Draggable, Droppable } from "react-beautiful-dnd";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setFormStatesDispatcher } from "../../actions/form";
import { advancedWidgets, basicWidgets } from "../utils/widgetsCode";
import { useState } from "react";
import { Close } from "@mui/icons-material";
const WidgetSidebar = ({
  widgets,
  search,
  auth,
  searchHandler,
  form: { form, formStates },
  account_type,
  setFormStatesDispatcher,
}) => {
  const checkWidgetPermissions = (element) => {
    var isDisabled =
      account_type?.widgets &&
      account_type?.widgets.some(function (el) {
        return el === element;
      });
    return isDisabled;
  };

 

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <div className="sidebar-wraper">
          <div className="form-information ">
            <h4 className="form-name">{form ? form.name : null}</h4>
            <p className="form-url">beta.getformify.com/{form._id}</p>
          </div>

{/* {form.store_selected === 'subbly' &&
          (form.integrations?.subbly?.fields !== null ||
            form.integrations?.subbly?.survey > 1) ? (
            <>
              <div className="subbly-integration-warning" style={{ display: shouldDisplayWarning(0) ? 'block' : 'none' }}>
                <p>You do not have a survey connected with your Formify flow. If you do not use surveys, please skip this warning. If you use surveys with your products, please map your questions here. Failing to connect a product with a survey to Formify will lead to users seeing the survey questions after the Formify flow</p>
                {shouldDisplayWarning(0) && (
                 <Close className="close-warning" onClick={() => handleDismiss(0)}/>
                )}
               
              </div>

            </>
          ) : (
            ''
          )} */}

{/* {
            form.store_selected =="subbly" && form.integrations?.subbly?.fields !== null  || form.integrations?.subbly?.survey > 1
          ?
          <div className="subbly-integration-warning">You do not have a survey connected with your Formify flow. If you do not use surveys, please skip this warning. If you use surveys with your products, please map your questions here. Failing to connect a product with a survey to Formify will lead to users seeing the survey questions after the Formify flow

            <button className="formify-btn">Dismiss!</button>
          </div>
          :
          ""
        } */}
          <div className="widget-search container-fluid  mt-3">
            <div className="row">
              <div className="col-12">
                <div className="formify-search d-flex flex-row justify-content-start align-items-center">
                  <Search style={{ color: "#3C3C4399" }} />
                  <input
                    type="text"
                    className="search-input"
                    value={search || ""}
                    onChange={(e) => {
                      searchHandler(e.target.value);
                    }}
                    style={{ width: "100%" }}
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="widget-content">
            <Droppable droppableId="widgets" isDropDisabled={true}>
              {(provided, snapshot) => (
                <div
                  className="container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className={"widget-container  mt-2"}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="field-labelsss">
                          Basic Widgets
                        </Accordion.Header>
                        <Accordion.Body>
                          {basicWidgets.map((item, index) => {
                            const originalIndex = widgets.findIndex(
                              (widget) => widget.id === item.id
                            );
                            if (item.id && item.show) {
                              return (
                                <Draggable
                                  draggableId={item.id.toString()}
                                  index={originalIndex}
                                  key={item.id}
                                  isDragDisabled={
                                    !checkWidgetPermissions(
                                      item.data.element_type
                                    )
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={
                                        checkWidgetPermissions(
                                          item.data.element_type
                                        )
                                          ? "col-12 mt-2"
                                          : "disabled-widget"
                                      }
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className={
                                          "widget d-flex flex-column justify-content-center align-items-center"
                                        }
                                      >
                                        {item.icon}
                                        <p
                                          style={{
                                            margin: 0,
                                            marginTop: "6px",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.data.name}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }

                            return;
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="field-labelssss">
                          Advanced Widgets
                        </Accordion.Header>
                        <Accordion.Body>
                          {advancedWidgets.map((item, index) => {
                            const originalIndex = widgets.findIndex(
                              (widget) => widget.id === item.id
                            );
                            if (item.id && item.show) {
                              return (
                                <Draggable
                                  draggableId={item.id.toString()}
                                  index={originalIndex}
                                  key={item.id}
                                  isDragDisabled={
                                    !checkWidgetPermissions(
                                      item.data.element_type
                                    )
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={
                                        checkWidgetPermissions(
                                          item.data.element_type
                                        )
                                          ? "col-12 mt-2"
                                          : "disabled-widget"
                                      }
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className={
                                          "widget d-flex flex-column justify-content-center align-items-center"
                                        }
                                      >
                                        {item.icon}
                                        <p
                                          style={{
                                            margin: 0,
                                            marginTop: "6px",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.data.name}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }

                            return;
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormStatesDispatcher })(
  WidgetSidebar
);