import axios from "axios";
import { BASE_DEV_URL } from "../utils";
import { tokenConfig } from "./auth";
import { getErrors } from "./errors";
import { setLoader } from "./loader";
import { getMessages } from "./messages";
import { CLEAR_ERRORS } from "./types";

export const changePassword = (data) => (dispatch, getState) => {
  dispatch(setLoader(true));

  axios
    .post(`${BASE_DEV_URL}/user/change/password`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CLEAR_ERRORS,
      });
      dispatch(getMessages(res.data));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(getErrors(err.response.data));
      dispatch(setLoader(false));
    });
};
