import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../utils";
import { toast } from "react-toastify";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Plus, X } from "react-feather";

const UploadFont = ({ auth }) => {
  const [name, setName] = useState("");
  const [font, setFont] = useState([
    {
      type: "",
      file: "",
    },
  ]);

  const uploadFont = (e) => {
    e.preventDefault();

    //VALIDATION
    //name
    if (validateName()) {
      toast.error("Font Name is required!");
      return;
    }

    if (font.length == 0) {
      toast.error("Font File is required!");
      return;
    }

    // file
    var error = false;
    font.map((obj, i) => {
      if (obj?.type?.length == 0) {
        toast.error("Select font type for font no." + (i + 1));
        error = true;
        return;
      }

      if (obj?.file == "") {
        toast.error("Select file for font no." + (i + 1));
        error = true;
        return;
      }
    });

    if (!error) uploadDB();
  };

  const uploadDB = () => {
    const formData = new FormData();

    font.map((obj, i) => {
      formData.append(
        "file",
        obj.file,
        name + obj.type + "." + getExtension(obj.file.name)
      );
    });

    axios
      .post(`${BASE_DEV_URL}/customFont`, formData, tokenConfig(store.getState))
      .then((res) => {
        if (res.data?.form) {
          toast.success("Font was added successfully!");
          setName("");
          setFont([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFontFile = (e, index) => {
    var extenstions = getExtension(e.target.files[0].name);
    if (extenstions == "ttf" || extenstions == "otf") {
      const newState = font.map((obj, i) => {
        // 👇️ if id equals 2, update country property
        if (i === index) {
          return { ...obj, file: e.target.files[0] };
        }
        return obj;
      });

      setFont(newState);
    } else {
      toast.error(
        "Only files with the following extensions are allowed: ttf & otf."
      );
    }
  };

  const handleNameChange = (e, index) => {
    const newState = font.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return { ...obj, type: e };
      }
      return obj;
    });

    setFont(newState);
  };

  const validateName = () => {
    if (name.length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const getExtension = (filename) => {
    return filename.split(".").pop();
  };

  const addFont = () => {
    setFont([
      ...font,
      {
        type: "",
        file: "",
      },
    ]);
  };

  const removeFont = (index) => {
    setFont([...font.slice(0, index), ...font.slice(index + 1)]);
  };

  return (
    <>
      <h1 className="m-4 formify-h1">Upload Fonts</h1>
      <div className="formify-card m-4">
        <div className="header-content">
          <form onSubmit={uploadFont}>
            <div className="input-holder w-60">
              <input
                type="text"
                placeholder="Enter Font Family"
                name="name"
                initialValue= {name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-60 data-desc">
              <h4>Manage Your Font Files</h4>
              <p>The accepted formats are ‘.otf’ and ‘.ttf’</p>
            </div>
            <div>
              {font.map((j, index) => {
                return (
                  <div className="font-upload" key={index}>
                    <div>
                      <div className="d-flex flex-display">
                        <span>{index + 1}.Type </span>
                        <DropdownButton
                          title={
                            font[index].type ? font[index].type : "Choose Type"
                          }
                          id={"layout"}
                          name={"layout"}
                          onSelect={(e) => {
                            handleNameChange(e, index);
                          }}
                          className="form-dropdown-select"
                        >
                          <Dropdown.Item eventKey="Regular">
                            Regular
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Medium">
                            Medium
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Bold">Bold</Dropdown.Item>
                          <Dropdown.Item eventKey="SemiBold">
                            Semi Bold
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="ExtraBold">
                            Extra Bold
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Italic">
                            Italic
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                      <div
                        className="remove-btn"
                        href="#/"
                        onClick={() => {
                          removeFont(index);
                        }}
                      >
                        <X style={{ color: "#ea4335" }} />
                      </div>
                    </div>
                    <div>
                      <p>{j?.file?.name ? j?.file?.name : "TrueType Fonts & Opentype Fonts"}</p>
                      <div>
                        <input
                          type="file"
                          name="fontFile"
                          onChange={(e) => handleFontFile(e, index)}
                        />
                        <div className="button">Upload</div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <hr />
            </div>
            <p className="add" onClick={() => addFont()}>
              <Plus
                style={{ width: "15px", marginBottom: "5px", color: "#4e4bfa" }}
              />{" "}
              Add New Variation
            </p>
            <div className="font-btns mt-3">
              <button className="formify-blue-btn me-2" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(mapAuthErrorsStateToProps)(UploadFont);
