/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import BorderOptions from "../single-components/BorderOptions";
import RangeSlider from "../single-components/RangeSlider";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { T } from "lodash/fp";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const UpsellStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [buttonKey, setButtonKey] = useState("default");
  const [descriptionMarginLockValues, setDescriptionMarginLockValues] = useState(false);
  const [descriptionPaddingLockValues, setDescriptionPaddingLockValues] = useState(false);
  const [imageBorderWidthLockValues, setImageBorderWidthLockValues] = useState(true);
  const [imageBorderRadiusLockValues, setImageBorderRadiusLockValues] = useState(true);
  const [borderButtonYesButtonWidthLockValues, setButtonYesButtonBorderWidthLockValues] = useState(true);
  const [borderButtonYesButtonRadiusLockValues, setButtonYesButtonBorderRadiusLockValues] = useState(true);
  const [borderNoButtonWidthLockValues, setNoButtonBorderWidthLockValues] = useState(true);
  const [borderNoButtonRadiusLockValues, setNoButtonBorderRadiusLockValues] = useState(true);
  const [buttonYesButtonMarginValues, setButtonYesButtonBorderMarginValues] = useState(true);
  const [buttonYesButtonPaddingLockValues, setButtonYesButtonPaddingLockValues] = useState(false);
  const [buttonNoButtonMarginValues, setNoButtonBorderMarginValues] = useState(true);
  const [buttonNoButtonPaddingLockValues, setNoButtonPaddingLockValues] = useState(false);

  const updateButtonsYesButtonSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.buttons.yesButton.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateButtonsNoButtonSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.buttons.noButton.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateButtonsYesButtonBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.yesButton.borderColor`,
        `style.tablet.buttons.yesButton.borderColor`,
        `style.mobile.buttons.yesButton.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateButtonsNoButtonBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.noButton.borderColor`,
        `style.tablet.buttons.noButton.borderColor`,
        `style.mobile.buttons.noButton.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateYesButtonButtonsBackgroundColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.yesButton.backgroundColor`,
        `style.tablet.buttons.yesButton.backgroundColor`,
        `style.mobile.buttons.yesButton.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateYesButtonButtonsColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.yesButton.color`,
        `style.tablet.buttons.yesButton.color`,
        `style.mobile.buttons.yesButton.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateNoButtonColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.noButton.color`,
        `style.tablet.buttons.noButton.color`,
        `style.mobile.buttons.noButton.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateNoButtonSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.buttons.noButton.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderNoButtonWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.noButton.borderLeftWidth`,
            `style.${responsive}.buttons.noButton.borderTopWidth`,
            `style.${responsive}.buttons.noButton.borderRightWidth`,
            `style.${responsive}.buttons.noButton.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderNoButtonRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.noButton.borderTopLeftRadius`,
            `style.${responsive}.buttons.noButton.borderTopRightRadius`,
            `style.${responsive}.buttons.noButton.borderBottomRightRadius`,
            `style.${responsive}.buttons.noButton.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (buttonNoButtonMarginValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.noButton.marginBottom`,
            `style.${responsive}.buttons.noButton.marginRight`,
            `style.${responsive}.buttons.noButton.marginLeft`,
            `style.${responsive}.buttons.noButton.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (buttonNoButtonPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.noButton.paddingBottom`,
            `style.${responsive}.buttons.noButton.paddingRight`,
            `style.${responsive}.buttons.noButton.paddingLeft`,
            `style.${responsive}.buttons.noButton.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateButtonYesButtonSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.buttons.yesButton.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderButtonYesButtonWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.yesButton.borderLeftWidth`,
            `style.${responsive}.buttons.yesButton.borderTopWidth`,
            `style.${responsive}.buttons.yesButton.borderRightWidth`,
            `style.${responsive}.buttons.yesButton.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderButtonYesButtonRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.yesButton.borderTopLeftRadius`,
            `style.${responsive}.buttons.yesButton.borderTopRightRadius`,
            `style.${responsive}.buttons.yesButton.borderBottomRightRadius`,
            `style.${responsive}.buttons.yesButton.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (buttonYesButtonMarginValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.yesButton.marginBottom`,
            `style.${responsive}.buttons.yesButton.marginRight`,
            `style.${responsive}.buttons.yesButton.marginLeft`,
            `style.${responsive}.buttons.yesButton.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (buttonYesButtonPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.buttons.yesButton.paddingBottom`,
            `style.${responsive}.buttons.yesButton.paddingRight`,
            `style.${responsive}.buttons.yesButton.paddingLeft`,
            `style.${responsive}.buttons.yesButton.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateNoButtonBackgroundColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.buttons.noButton.backgroundColor`,
        `style.tablet.buttons.noButton.backgroundColor`,
        `style.mobile.buttons.noButton.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateDescriptionSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.description.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateDescriptionSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.description.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (descriptionMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.description.marginBottom`,
            `style.${responsive}.description.marginRight`,
            `style.${responsive}.description.marginLeft`,
            `style.${responsive}.description.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (descriptionPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.description.paddingBottom`,
            `style.${responsive}.description.paddingRight`,
            `style.${responsive}.description.paddingLeft`,
            `style.${responsive}.description.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateColorHeader = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.description.color`,
        `style.tablet.description.color`,
        `style.mobile.description.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.image.${e.target.id}`], [e.target.value + "%"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width" && e.target.id !== "maxWidth") {
      if (imageBorderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (imageBorderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateImageSlider = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.image.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    }

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
        "Width" &&
      e.target.id !== "maxWidth"
    ) {
      if (imageBorderWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (imageBorderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.image.borderColor`,
        `style.tablet.image.borderColor`,
        `style.mobile.image.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };


  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.image.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };


  return (
    <>
      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Description
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "description", null)}
                  updateSliderstyle={updateDescriptionSliderstyle}
                  updateSelectstyle={updateDescriptionSelectstyle}
                  id={"description"}
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorHeader}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "description", "color")}
                  />
                </div>

                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "description", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "description", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "description", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "description", "marginBottom")}
                        onChange={updateDescriptionSliderstyle}
                        setLockValue={setDescriptionMarginLockValues}
                        lockValue={descriptionMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "description", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "description", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "description", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "description", "paddingBottom")}
                        onChange={updateDescriptionSliderstyle}
                        setLockValue={setDescriptionPaddingLockValues}
                        lockValue={descriptionPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Next Button
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <Tabs
                  defaultActiveKey="yesButton"
                  id="default-button"
                  className="mb-3"
                  activeKey={buttonKey}
                  onSelect={(k) => setButtonKey(k)}
                >
                  <Tab eventKey="yesButton" title="YesButton">
                    <div className="editable-field form-row  ">
                      <p>Color</p>
                      <ColorPickerStyle
                        onChange={updateYesButtonButtonsColor}
                        id={"color"}
                        tag={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.color", true)}
                      />
                    </div>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="field-label">
                          Background
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="editable-field form-row  ">
                            <p>Color</p>
                            <ColorPickerStyle
                              onChange={updateYesButtonButtonsBackgroundColor}
                              id={"backgroundColor"}
                              tag={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.backgroundColor", true)}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <hr />
                    <BorderOptions
                      attribute={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton")}
                      onChange={updateButtonYesButtonSliderOptions}
                      onChangeColor={updateButtonsYesButtonBorderColor}
                      setBorderWidthLockValues={
                        setButtonYesButtonBorderWidthLockValues
                      }
                      setBorderRadiusLockValues={
                        setButtonYesButtonBorderRadiusLockValues
                      }
                      borderWidthLockValues={
                        borderButtonYesButtonWidthLockValues
                      }
                      borderRadiusLockValues={
                        borderButtonYesButtonRadiusLockValues
                      }
                      updateSelectOptions={updateButtonsYesButtonSelectOptions}
                    />
                    <hr />

                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="field-label">
                          Spacing
                        </Accordion.Header>
                        <Accordion.Body>
                          <Spacer
                            label={"Margin"}
                            id={"margin"}
                            tagLeft={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.marginLeft", true)}
                            tagRight={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.marginRight",true)}
                            tagTop={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.marginTop", true)}
                            tagBottom={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.marginBottom",true)}
                            onChange={updateButtonYesButtonSliderOptions}
                            setLockValue={setButtonYesButtonBorderMarginValues}
                            lockValue={buttonYesButtonMarginValues}
                          />
                          <Spacer 
                            label={"Padding"}
                            id={"padding"}
                            tagLeft={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.paddingLeft",true)}
                            tagRight={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.paddingRight",true)}
                            tagTop={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.paddingTop",true)}
                            tagBottom={getResponsiveStyle(widget?.style, responsive, "buttons", "yesButton.paddingBottom",true)}
                            onChange={updateButtonYesButtonSliderOptions}
                            setLockValue={setButtonYesButtonPaddingLockValues}
                            lockValue={buttonYesButtonPaddingLockValues}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Tab>
                  <Tab eventKey="noButton" title="NoButton">
                    <div className="editable-field form-row  ">
                      <p>Color</p>
                      <ColorPickerStyle
                        onChange={updateNoButtonColor}
                        id={"color"}
                        tag={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.color",true)}
                      />
                    </div>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="field-label">
                          Background
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="editable-field form-row  ">
                            <p>Color</p>
                            <ColorPickerStyle
                              onChange={updateNoButtonBackgroundColor}
                              id={"backgroundColor"}
                              tag={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.backgroundColor",true)}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <hr />
                    <BorderOptions
                      attribute={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton", false)}
                      onChange={updateNoButtonSliderOptions}
                      onChangeColor={updateButtonsNoButtonBorderColor}
                      setBorderWidthLockValues={
                        setNoButtonBorderWidthLockValues
                      }
                      setBorderRadiusLockValues={
                        setNoButtonBorderRadiusLockValues
                      }
                      borderWidthLockValues={borderNoButtonWidthLockValues}
                      borderRadiusLockValues={borderNoButtonRadiusLockValues}
                      updateSelectOptions={updateButtonsNoButtonSelectOptions}
                    />
                    <hr />

                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="field-label">
                          Spacing
                        </Accordion.Header>
                        <Accordion.Body>
                          <Spacer 
                            label={"Margin"}
                            id={"margin"}
                            tagLeft={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.marginLeft",true)}
                            tagRight={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.marginRight",true)}
                            tagTop={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.marginTop",true)}
                            tagBottom={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.marginBottom",true)}
                            onChange={updateNoButtonSliderOptions}
                            setLockValue={setNoButtonBorderMarginValues}
                            lockValue={buttonNoButtonMarginValues}
                          />
                          <Spacer 
                            label={"Padding"}
                            id={"padding"}
                            tagLeft={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.paddingLeft",true)}
                            tagRight={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.paddingRight",true)}
                            tagTop={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.paddingTop",true)}
                            tagBottom={getResponsiveStyle(widget?.style, responsive, "buttons", "noButton.paddingBottom",true)}
                            onChange={updateNoButtonSliderOptions}
                            setLockValue={setNoButtonPaddingLockValues}
                            lockValue={buttonNoButtonPaddingLockValues}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Tab>
                </Tabs>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Image</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <RangeSlider
                  label={"Width"}
                  id={"width"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "width")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true} 
                  removeXPadding={ true }
                />
                <RangeSlider
                  label={"Max Width"}
                  id={"maxWidth"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "maxWidth")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={ true }
                />
                <RangeSlider
                  label={"Height"}
                  id={"height"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "height")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={ true }
                />
                <RangeSlider
                  label={"Opacity"}
                  id={"opacity"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "opacity")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={ true }
                />
                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(widget?.style, responsive, "image", null)}
                  onChange={updateImageSlider}
                  onChangeColor={updateBorderColor}
                  setBorderWidthLockValues={setImageBorderWidthLockValues}
                  setBorderRadiusLockValues={setImageBorderRadiusLockValues}
                  borderWidthLockValues={imageBorderWidthLockValues}
                  borderRadiusLockValues={imageBorderRadiusLockValues}
                  updateSelectOptions={updateInputSelectOptions} 
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(UpsellStyle);
