import { Check } from "react-feather";

const SuccessPopup = ({ content }) => {
  return (
    <div className="d-flex flex-row justify-content-start align-items-center success-popup ">
      <div className="success-icon me-3">
        <Check
          style={{
            backgroundColor: "#34a853",
            color: "white",
            borderRadius: "50px",
            padding: "5px",
            height: "36px",
            width: "36px",
          }}
        />
      </div>
      <div className="content">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default SuccessPopup;
