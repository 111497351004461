import React from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../../actions/errors";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { Link } from "react-router-dom";
const ZapierIntegration = () => {
  const openInvitePage = () => {
    window.open("https://zapier.com/developer/public-invite/169342/1c7d48f79ae3a4f8430500cb8ffa2aaf/");
  }

  return (
    <React.Fragment>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/zapier.png"}
              alt="Zapier"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>

      <div className="subbly-header">
        <div className="row mt-2 col-lg-8 ">
          <h4 className="integration-page-header">
            Connect to Zapier
          </h4>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-lg-12 mt-2">
            <div className="formify-card fixed-width subbly">
              <div className="card-identifier-header">
                <h4 className="integration-page-header">Instructions</h4>
              </div>
              <p className="grey-paragraph mt-1">
                Zapier provides workflows to automate the use of web applications together.
                Pass your customer data from Subbly to 1000's of other apps and tools easily.
              </p>
              <p className="grey-paragraph mb-0">
                Visit the special invite page to get access to the app: <Link to="#/" style={{ color: '#6563ff' }} onClick={ openInvitePage }>click here</Link>. Once you've
                accepted the invite, you can then create your zaps.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  clearErrors,
})(ZapierIntegration);
