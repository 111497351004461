import { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useNavigate, useLocation, } from "react-router-dom";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/auth";
// import { LOGOUT_USER } from "./actionTypes";
import { connect } from "react-redux";


const OnboardNavbar = ({auth, logoutUser }) => {
  // var isSubbly = false;
  // if (
  //   window.location.pathname === "/integrations/subbly/add-product" ||
  //   window.location.pathname === "/integrations/subbly/store"
  // ) {
  //   isSubbly = true;
  // }
  const nav = useNavigate();
  const location = useLocation();
  const [isNavbarHidden, hideNavbar] = useState(false);
  const value = location.pathname.split("/");


  useEffect(() => {
    if(location.pathname.includes("/profile/billing/buy/")) hideNavbar(true);
  }, [location])

  return ( !isNavbarHidden &&  
    <nav className="navbar navbar-expand-lg navbar-light bg-white dashboard-nav onboard-special ">
      <div className="container-fluid d-flex justify-content-between p-0 m-0 ms-3 ">
       
          <>
            <div className=" container  d-flex justify-content-start simple-navbar-contents onboard-nav">
                <img src={process.env.PUBLIC_URL + "/assets/images/ff-png.png"} />
                <Link
                onClick={() => {
                  logoutUser();
                }}
                className="link-dark rounded logout-link"
                to="/"
              >
               Log Out
              </Link>
            </div>
          
          </>
       
      
      </div>
    </nav>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth, // Assuming "auth" is the appropriate slice of state
});
export default connect(mapStateToProps, { logoutUser })(OnboardNavbar);
