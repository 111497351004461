import { Dropdown, DropdownButton } from "react-bootstrap";

const FontWeight = ({
    tag,
    handleSelect,
    id,
    name,
}) => {


    const handleValue = (value) =>{
        switch(value){
            // case "100":
            //     return "Light";
                case "400":
                    return "Regular";
                    case "bold":
                        return "Bold";
                        default:
                            return "Regular";

        }
    }

    return (
        <div className="editable-field form-row  ">
            <p> Weight</p>
            <div className="widget-range-slider-container">
                <DropdownButton
                    title={tag ? handleValue(tag) : "Regular"}
                    id={id}
                    name={name}
                    onSelect={(e) => handleSelect(e, { id: id })}
                    className="form-dropdown-select"
                >
                    {/* <Dropdown.Item eventKey="100">Light</Dropdown.Item> */}
                    <Dropdown.Item eventKey="400">Regular</Dropdown.Item>
                    <Dropdown.Item eventKey="bold">Bold</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
};

export default FontWeight;