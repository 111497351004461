/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_DEV_URL, mapAuthAndFormStateToProps } from "../../utils";
import FormSteps from "../form/FormSteps";
import _ from "lodash";
import FormCanvas from "../form/FormCanvas";
import { ChevronLeft, Loader } from "react-feather";
import { DragDropContext } from "react-beautiful-dnd";
import store from "../../store";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setLoader } from "../../actions/loader";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import {
  setErrorForOneWidget,
  setFormDispatcher,
  setFormLoadingDispatcher,
  setResponseErrorDispatcher,
  setResponsesDispatcher,
  setFormActiveStepDispatcher,
  setClientMail,
} from "../../actions/form";
import FormifyButton from "../utils/FromifyButton";
import { validateResponse } from "../utils/formResponseValidations";
import { createResponses } from "../utils/formFunctions";
import FormifyLoader from "../utils/FormifyLoader";
import { createGlobalStyle } from "styled-components";
import { Widgets } from "@mui/icons-material";
import { toast } from "react-toastify";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "${(props) => props.name}";
    src: url(${(props) => props.url});
  } ;`;

const FormView = ({
  setLoader,
  fillMode = false,
  setFormDispatcher,
  setFormLoadingDispatcher,
  setResponseErrorDispatcher,
  setResponsesDispatcher,
  validateResponse,
  setErrorForOneWidget,
  setFormActiveStepDispatcher,
  setClientMail,
  createResponses,
  form: {
    form,
    responsive,
    isFormLoading,
    responses,
    responseError,
    activeFormStep,
    clientEmail,
  },
}) => {
  const nav = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [uuid, setUuid] = useState(null);
  const [showUpsell, setUpsell] = useState(false);
  const [fillIntent, setFillIntent] = useState(null);
  const NO_RESPONSE_ELEMENTS = ["form_heading", "form_image", "form_paragraph"];
  const [blockForm, setBlockForm] = useState(false);
  const [formMessage, setFormMessage] =useState("");
  const [hover, setHover] = useState(false);
  const [activestep, setActiveStep] = useState(false);
  const [errorCheckbox, setErrorCheckbox] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);
  const mainDiv = useRef(null);
  const sendPostMessage = () => {
    window.parent.postMessage(
      {
        frameHeight: mainDiv?.current?.offsetHeight,
        frameId: params.id,
      },
      "*"
    );
  };
  const buttonRef = useRef(null);


  useEffect(() => {
    if (!mainDiv.current) return;
    const resizeObserver = new ResizeObserver(() => {
      sendPostMessage();
    });
    resizeObserver.observe(mainDiv.current);
    return () => resizeObserver.disconnect();
  }, [form._id, activeFormStep, mainDiv]);

  useEffect(() => {
    if (form?.googleAnalyticsTrackingId)
      ReactGA.pageview(location.pathname + location.search);
    if (form?.facebookPixelTrackingId) ReactPixel.pageView();
  }, [form, location]);

  const stepElements = useMemo(() => {
    if (!_.isEmpty(form?.steps)) {
      const widgets =
        form.steps[
          _.findIndex(form.steps, (tempStep) => {
            return tempStep.order_number === parseInt(activeFormStep);
          })
        ];
      return widgets ? widgets.elements : [];
    } else {
      return [];
    }
  }, [form, activeFormStep]);

  const getAllElements = useMemo(() => {
    let elements = [];
    if (!_.isEmpty(form.steps)) {
      form.steps.forEach((step) => {
        elements = elements.concat(step.elements);
      });
    }
    return elements;
  }, [form]);

  const stepLen = useMemo(() => {
    if (form?._id) return form.steps.length;
  }, [form]);


  useEffect(() => {
    if (!form._id) {
      store.dispatch(setLoader(true, false));
      if (fillMode) {
        setUuid(uuidv4());
        axios
          .get(`${BASE_DEV_URL}/public/form/${params.id}/`)
          .then((res) => {
            if (res.data.type === "success") {
              if (res.data.form.form_settings.status === "blocked") {
               
                setBlockForm(true);
                setFormMessage(`This Signup Flow is now blocked because your account has downgraded and it has reached the maximum number of allowed forms. Please upgrade your plan to unblock this form.`)
              } else {
                initGA(res.data.form.googleAnalyticsTrackingId);
                initFacebookPixel(res.data.form.facebookPixelTrackingId);
                setFormDispatcher(res.data.form);
                store.dispatch(setLoader(false));
                if (res.data.usrAcc === "premium" || res.data.usrAcc === "pro")
                  setRemoveLogo(true);
               
                return res.data.type;
              }
            } else {
              if (res.data.type === "limit") {
                
                setBlockForm(true);
              }
              store.dispatch(setLoader(false));
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        axios
          .get(`${BASE_DEV_URL}/public/form/preview/${params.id}/`)
          .then((res) => {
            if (res.data.type === "success") {
              initGA(res.data.form.googleAnalyticsTrackingId);
              initFacebookPixel(res.data.form.facebookPixelTrackingId);
              setFormDispatcher(res.data.form);
              if (res.data.blockResponses) setBlockForm(true);
              else setBlockForm(false);
              

              store.dispatch(setLoader(false));
            } else {
              if (res.data.type === "limit") {
                

                setBlockForm(true);
              }
              store.dispatch(setLoader(false));
            }
          })
          .catch((err) => {});
      }
    }
  }, [form]);
  useEffect(() => {
    setInitialResponse();
    if (form?._id && fillMode) stepDropOut();
  }, [form]);

  const setInitialResponse = () => {
    const res = {};
    let elements = [...getAllElements];
    elements = elements.filter(
      (ele) => !NO_RESPONSE_ELEMENTS.includes(ele.element_type)
    );

    elements.forEach((ele) => {
      res[ele._id] = getElementResponseValue(ele);
    });

    setResponsesDispatcher(res);
  };

  const getElementResponseValue = (element) => {
    switch (element.element_type) {
      case "form_email":
        return {
          type: "Email",
          email: "",
          termAgree: false,
        };
      case "form_product":
        return {
          type: "Product",
          value: "",
        };
      case "form_dynamic_product":
        return {
          type: "Product",
          value: "",
        };
      case "form_upsell":
        return {
          type: "Upsell",
          value: "",
        };
      case "form_dropdown":
        return {
          type: "DropDown",
          value: element.content.default,
        };
      case "form_phone":
        return {
          type: "Phone Number",
          value: "",
        };
      case "form_number":
        return {
          type: "Number",
          value: "",
        };
      case "form_short_text":
        return {
          type: "Short Text",
          value: "",
        };
      case "form_long_text":
        return {
          type: "Short Text",
          value: "",
        };
      case "form_single_choice":
        return {
          type: "Single Choice",
          value: element.content.default ? element.content.default : "",
        };
      case "form_multi_choice":
        return {
          type: "Multi Choice",
          value: [],
        };
      case "form_name":
        return {
          type: "Full Name",
          first_name: "",
          last_name: "",
        };
      case "form_date":
        return {
          type: "Date",
          value: element.content.default ? element.content.default : "",
        };

      case "form_address":
        return {
          type: "Address",
          street: "",
          zip: "",
          city: "",
          country: "",
        };
      case "form_redirect_choice":
        return {
          type: "form_redirect_choice",
          value: "",
        };
      default:
        break;
    }
    return;
  };

  const initGA = (googleAnalyticsTrackingId) => {
    if (!googleAnalyticsTrackingId) return;
    ReactGA.initialize(googleAnalyticsTrackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
    // fireGAPixel("Inital Step: 1");
  };

  const initFacebookPixel = (pixelId) => {
    if (!pixelId) return;
    ReactPixel.init(pixelId);
    // fireGAPixel("Inital Step: 1");
  };

  const fireGAPixel = (label) => {
    if (!fillMode) return;

    if (form?.googleAnalyticsTrackingId) {
      ReactGA.event({
        category: "FormifyFlow",
        action: label,
        label: "Submit",
      });
    }
    if (form?.facebookPixelTrackingId) {
      ReactPixel.track("Lead", {
        category: "FormifyFlow",
        action: label,
      });
    }
  };

  const handleResponse = (isLastStep = false) => {
    //CREATE CONTACT
    
    if (clientEmail?.value?.length > 0 && !isLastStep) {
      axios.post(`${BASE_DEV_URL}/public/contact/${params.id}/`, {
        email: clientEmail.value,
        id: clientEmail.id,
      });
      setClientMail("", null);
    }
    let errorMessages = validateResponse(activeFormStep);
    setResponseErrorDispatcher(errorMessages);
    if (Object.keys(errorMessages).length === 0) {
      updateStep(isLastStep);
    }

  };

  useEffect(() => {
    fireGAPixel("Step: " + activeFormStep);
  }, [form, activeFormStep]);

  const updateStep = (isLastStep) => {
    if (!isLastStep) {
      // fireGAPixel("Step: " + (activeFormStep + 1));
      setFormActiveStepDispatcher(activeFormStep + 1);
    } else {
      submitResponses();
    }
  };

  const submitResponses = async () => {
    const currentStep = form.steps[activeFormStep - 1];
    const formProductChecked = currentStep.elements.some(
      (element) =>
        (element.element_type === "form_product" ||
          element.element_type === "form_dynamic_product") &&
        responses[element._id]?.value
    );

    const filteredElements = currentStep.elements.filter(
      (element) =>
        element.element_type === "form_product" ||
        element.element_type === "form_dynamic_product"
    );

    if (fillMode) {
      let response = await createResponses(params.id, uuid);
    
      if (response.type === "success") {
        fireGAPixel("Form submit.");
        if (response.url) {
          store.dispatch(setLoader(true, false));
          setTimeout(() => {
            window.top.location = response.url;
          }, 3000);
         
        } else if (filteredElements.length > 0 && !formProductChecked) {
          setErrorCheckbox(true);
          setErrorForOneWidget(response.data);
          setFormActiveStepDispatcher(response.data.step);
          
        } else nav("/form/fill/thanks");
      } else {
        if (response.data) {
          setErrorForOneWidget(response.data);
          setFormActiveStepDispatcher(response.data.step);
        }
      }
    }
  };

  const nextStep = () => {
    handleResponse();
  };

  const changeStepHandler = (futureStep) => {
    if (activeFormStep > futureStep) setFormActiveStepDispatcher(futureStep);
    else if (activeFormStep + 1 === futureStep) handleResponse();
  };

  const stepDropOut = () => {
    axios
      .post(`${BASE_DEV_URL}/fillintent`, {
        step: 1,
        uuid: uuid,
        form: form._id,
      })
      .then((res) => {
        setFillIntent(res.data.fillIntent);
      })
      .catch((err) => {});
  };

  const updateStepDropOut = () => {
    axios.put(`${BASE_DEV_URL}/fillintent/${fillIntent._id}`, {
      ...fillIntent,
      step: activeFormStep,
    });
  };

  function nextBtnFunction() {
    const currentStep = form.steps[activeFormStep - 1];
    const formProductChecked = currentStep.elements.some(
      (element) =>
        (element.element_type === "form_product" ||
          element.element_type === "form_dynamic_product") &&
        responses[element._id]?.value
    );

    const filteredElements = currentStep.elements.filter(
      (element) =>
        element.element_type === "form_product" ||
        element.element_type === "form_dynamic_product"
    );

    if (filteredElements.length > 0 && !formProductChecked) {
      setErrorCheckbox(true);
    } else {
      setErrorCheckbox(false);
      nextStep();
      setActiveStep(true);
    }
  }
  useMemo(() => {
    if (fillMode && fillIntent) updateStepDropOut();
  }, [activeFormStep]);

  return _.isEmpty(form._id) ? (
    !blockForm ? (
      <FormifyLoader removeLogo={removeLogo} />
    ) : (
      <div className="height-body">
        <div className="center-div">
          <p>This signup flow isn't accepting new responses!</p>
          <a href="https://beta.getformify.com" className="power-by">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/formify_logo_icon.svg"
              }
              alt="formify-logo"
            />
            <p>
              {" "}
              Powered by
              <br />
              Formify
            </p>
          </a>
        </div>
      </div>
    )
  ) : (
    <DragDropContext>
      {blockForm && (
        <div className="notice-ribbon">
          This Signup Flow is now private because it has reached the total
          number of responses. Wait until responses reset on DATE or upgrade to
          increase your limit.
        </div>
      )}
      <div
        style={{
          backgroundColor: !_.isEmpty(form)
            ? form.form_style.color.page_color
            : null,
        }}
      >
        <div
          ref={mainDiv}
          className="container-fluid form-builder"
          style={{
            minHeight: "100vh",
            width:
              window.innerWidth < parseInt(form.form_style.layout.width)
                ? "100%"
                : parseInt(form.form_style.layout.width),
            backgroundColor: form?.form_style?.color?.page_color,
          }}
        >
          <div className="row">
            {fillMode ? null : (
              <div className="col-12 mt-2 bck-btn">
                <Link to={`/form/${params.id}/step/1`} className="back-arrow">
                  <ChevronLeft style={{ height: "20px", width: "20px" }} />
                </Link>
              </div>
            )}
          </div>
          <div className="row justify-content-center">
            {form?.font_family &&
              Object.keys(form?.font_family).map((i, j) => {
                return (
                  <GlobalStyle
                    name={i}
                    url={form?.font_family[i].url}
                    key={i}
                  />
                );
              })}
            <div className="col-lg-12 d-flex flex-column align-items-center mt-3">
              <FormSteps
                steps={form ? form.steps : null}
                viewMode={true}
                currentstep={activeFormStep}
                errorCheckbox={errorCheckbox}
                setErrorCheckbox={setErrorCheckbox}
                style={form ? form.form_style.layout : {}}
                nextPageHandler={changeStepHandler}
              />
              <FormCanvas
                elements={stepElements}
                publicView={true}
                isEditable={false}
                showUpsell={showUpsell}
                setUpsell={setUpsell}
                handleResponse={handleResponse}
                buttonRef={buttonRef}
                activeStep={activeFormStep}
                nextStep={nextStep}
                uuid={uuid}
                errorCheckbox={errorCheckbox}
                setErrorCheckbox={setErrorCheckbox}
              />
              {
                <div className="form-btn-group d-flex justify-content-center mb-5 ">
                  {activeFormStep < stepLen ? (
                    <div className="col">
                      <FormifyButton
                        type="button"
                        ref={buttonRef}
                        loading={isFormLoading}
                        classes="invert-formify-btn-hover form-submit-btn SUBMIT-1"
                        onClick={() => {
                          const currentStep = form.steps[activeFormStep - 1];
                          const formProductChecked = currentStep.elements.some(
                            (element) =>
                              (element.element_type === "form_product" ||
                                element.element_type ===
                                  "form_dynamic_product") &&
                              responses[element._id]?.value
                          );

                          const filteredElements = currentStep.elements.filter(
                            (element) =>
                              element.element_type === "form_product" ||
                              element.element_type === "form_dynamic_product"
                          );

                          if (
                            filteredElements.length > 0 &&
                            !formProductChecked
                          ) {
                            setErrorCheckbox(true);
                          } else {
                            // setErrorCheckbox(!errorCheckbox)
                            nextStep();
                            setActiveStep(true);
                          }
                        }}
                        loaderWhite={true}
                        title={form.form_style.layout.buttons.nextContent}
                        onMouseLeave={() => setHover(false)}
                        onMouseEnter={() => setHover(true)}
                        style={
                          hover
                            ? {
                                ...form.form_style.layout.buttons.onHover,
                                marginTop:
                                  form.form_style.layout.buttons.default
                                    .marginTop,
                                marginBottom:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginBottom,
                                marginLeft:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginLeft,
                                marginRight:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginRight,
                                paddingTop:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingTop,
                                paddingBottom:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingBottom,
                                paddingLeft:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingLeft,
                                paddingRight:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingRight,
                              }
                            : form.form_style.layout.buttons.default
                        }
                      />
                    </div>
                  ) : null}

                  {activeFormStep === stepLen ? (
                    <div className="col">
                      <FormifyButton
                        type="button"
                        loading={isFormLoading}
                        ref={buttonRef}
                        classes="invert-formify-btn-hover form-submit-btn SUBMIT-2"
                        onClick={() => {
                          if (fillMode) {
                            handleResponse(true);
                            setActiveStep(true);
                          } else {
                            toast.error(
                              "Can't submit forms in preview, please view live version in 'Publish-> Open New Tab'"
                            );
                          }
                        }}
                        // disabled={!fillMode}
                        loaderWhite={true}
                        title={form.form_style.layout.buttons.submitContent}
                        onMouseLeave={() => setHover(false)}
                        onMouseEnter={() => setHover(true)}
                        style={
                          hover
                            ? {
                                ...form.form_style.layout.buttons.onHover,
                                marginTop:
                                  form.form_style.layout.buttons.default
                                    .marginTop,
                                marginBottom:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginBottom,
                                marginLeft:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginLeft,
                                marginRight:
                                  form.form_style.layout?.buttons?.default
                                    ?.marginRight,
                                paddingTop:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingTop,
                                paddingBottom:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingBottom,
                                paddingLeft:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingLeft,
                                paddingRight:
                                  form.form_style.layout?.buttons?.default
                                    ?.paddingRight,
                              }
                            : form.form_style.layout.buttons.default
                        }
                      />
                    </div>
                  ) : null}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setFormDispatcher,
  setFormLoadingDispatcher,
  setLoader,
  setResponsesDispatcher,
  validateResponse,
  setResponseErrorDispatcher,
  createResponses,
  setFormActiveStepDispatcher,
  setClientMail,
  setErrorForOneWidget,
})(FormView);
