/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "../single-components/InputField";
import SwitchChip from "../single-components/switchChip";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import AlignmentTabs from "../single-components/AlignmentTabs";
import WidthOptions from "../single-components/WidthOptions";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const FullNameContent = ({
  form: { widget, responsive },
  setWidgetDispatcher
}) => {
  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.fontFamily`,
        `style.desktop.input.fontFamily`,
        `style.desktop.helpText.fontFamily`
      ], 
      [
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url }
      ], widget, setWidgetDispatcher, true
    );
  };

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateColorBackgroundInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.desktop.input.color`,
        `style.desktop.helpText.color`,
        `style.tablet.label.color`,
        `style.tablet.input.color`,
        `style.tablet.helpText.color`,
        `style.mobile.label.color`,
        `style.mobile.input.color`,
        `style.mobile.helpText.color`,
      ], 
      [value, value, value, value, value, value, value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container">
          <FontFamilyPicker
            attribute={widget?.style?.desktop?.label}
            onChangeFont={updateLabelFont}
            id={"heading"}
          />
        </div>
        <div className="quick-action-container">
          <AlignmentTabs
            tag={getResponsiveStyle(widget?.style, responsive,  "textAlign", null)}
            handleSelect={handleChangeAlign}
            id={"textAlign"}
          />
        </div>
      </div>
      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container">
          <WidthOptions
            handleSelect={updateInputSelectOptions}
            tag={getResponsiveStyle(widget?.style, responsive,  "input", "width")}
            id={"width"}
            name={"width"}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateColorBackgroundInput}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive,  "input", "color")}
          />
        </div>
      </div>
      <hr />
      <InputField
        id={"label"}
        label={"Title"}
        isTitle={true}
        onChange={handleChange}
        placeholder={"Full Name"}
        type="text"
        value={widget.content ? widget.content.label || "" : ""}
      />
      <hr />

      <InputField
        id={"first_name_label"}
        label={"First Name Label"}
        onChange={handleChange}
        placeholder={"First Name "}
        type="text"
        value={widget.content ? widget.content.first_name_label || "" : ""}
      />
      <hr />
      <InputField
        id={"first_name_placeholder"}
        label={"First Name Placeholder"}
        onChange={handleChange}
        placeholder={"Enter your first name"}
        type="text"
        value={
          widget.content ? widget.content.first_name_placeholder || "" : ""
        }
      />
      <hr />
      <InputField
        id={"last_name_label"}
        label={"Last Name Label"}
        onChange={handleChange}
        placeholder={"Last Name "}
        type="text"
        value={widget.content ? widget.content.last_name_label || "" : ""}
      />
      <hr />
      <InputField
        id={"last_name_placeholder"}
        label={"Last Name Placeholder"}
        onChange={handleChange}
        placeholder={"Enter last name placeholder"}
        type="text"
        value={widget.content ? widget.content.last_name_placeholder || "" : ""}
      />
      <hr />
      <div className="editable-field form-row mt-2">
        <p className="field-label">Required</p>
        <SwitchChip
          id="required"
          checked={widget.content.required || false}
          onChange={(val) => {
            changeCheckBtn(val, "required");
          }}
        />
      </div>
      <div className="editable-field form-row mt-2">
        <p className="field-label">Hide Label</p>
        <SwitchChip
          id="hide_label"
          checked={widget.content.hide_label || false}
          onChange={(val) => {
            changeCheckBtn(val, "hide_label");
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(FullNameContent);
