import { updateForm } from "../../../utils/formFunctions";
import { useParams } from "react-router-dom";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import FormifyButton from "../../../utils/FromifyButton";
import { formRedoDispatcher, formUndoDispatcher } from "../../../../actions/form";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { useEffect } from "react";

const UpdateForm = ({
  form: { form, canUndo, canRedo, updatedForm, isFormLoading },
  updateForm,
  formUndoDispatcher,
  formRedoDispatcher
}) => {
  const params = useParams();
  const onUpdateForm = () => {
    updateForm(form, params);
  };

  const onKeyPress = (e) => {
    if (e.keyCode == 90 && e.metaKey) {
      if(canUndo) formUndoDispatcher()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    }
  }, [canUndo])

  function timeSince(date) {
    date = new Date(date);
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + " years";
    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + " months";
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + " days";
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + " hours";
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + " minutes";
    return Math.floor(seconds) + " seconds";
  }

  return (
    <div className="update-form">
      <p className="last-updated-form">Last Updated <br/>{ timeSince(form.updated_at) } ago</p>
      <div className="d-flex align-items-center">
        <FormifyButton 
          title="Undo"
          icon={ <UndoIcon htmlColor="#6563ff" fontSize="18px" /> }
          disabled={ !canUndo }
          classes="undo-redo"
          onClick={ formUndoDispatcher }
        />
        
        <FormifyButton
          title="Redo"
          icon={ <RedoIcon htmlColor="#6563ff" fontSize="18px" /> }
          disabled={ !canRedo }
          classes="undo-redo"
          onClick={ formRedoDispatcher }
        />

        <FormifyButton
          disabled={!updatedForm}
          classes="widget-editing-button"
          onClick={onUpdateForm}
          loading={isFormLoading}
          title="Update"
          loaderWhite={true}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { updateForm, formUndoDispatcher, formRedoDispatcher })(
  UpdateForm
);