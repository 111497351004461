/* eslint-disable react-hooks/exhaustive-deps */
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import AlignmentTabs from "../single-components/AlignmentTabs";
import InputField from "../single-components/InputField";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const ParagraphContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  mergeWidget,
}) => {
  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };
  
  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, url) => {
    handleSetWidgetDispatcher([`style.desktop.paragraph.fontFamily`], [{ name: name, src: url }], widget, setWidgetDispatcher, true);
  };

  const updateFontSize = (e, type) => {
    handleSetWidgetDispatcher([`style.${responsive}.paragraph.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.paragraph.color`,
        `style.tablet.paragraph.color`,
        `style.mobile.paragraph.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <div className="row">
          <div className="col-lg-12">
            <div className="input-holder m-0">
              <div className="editable-field  quick-form-actions">
                <div className="quick-action-container ">
                  <FontFamilyPicker
                    attribute={widget?.style?.desktop?.paragraph}
                    onChangeFont={updateLabelFont}
                    id={"heading"}
                  />
                </div>

                <div className="quick-action-container">
                  <AlignmentTabs
                    tag={getResponsiveStyle(widget?.style, responsive, "textAlign", null)}
                    id={"textAlign"}
                    handleSelect={handleChangeAlign}
                  />
                </div>
              </div>
              <div className="editable-field mb-0 form-row quick-form-actions pt-4 font-family-picker">
                <div className="quick-action-container">
                  <div className="d-flex justify-between">
                    <p>Font Size(px)</p>
                    <div><ChangeResponsiveness /></div>
                  </div>
                  <FormifyInput 
                    type="number"
                    id={"fontSize"}
                    min={1}
                    max={200}
                    onChange={updateFontSize}
                    value={checkFontSize(widget?.style, responsive, "paragraph", "fontSize")}
                  />
                </div>
                <div className="editable-field w-100 form-row mt-3 mb-0">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorLabel}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "paragraph", "color")}
                  />
                </div>
              </div>
              <hr />
              <p className="field-heading widget-label m-0">Paragraph Content</p>
              <div className="input-holder mt-0">
                <InputField
                  id={"text"}
                  label={"Title"}
                  onChange={handleChange}
                  placeholder={"Title"}
                  type="text"
                  isTitle={true}
                  value={widget.content.question}
                  mergeWidget={mergeWidget}
                  numRows={5}
                />
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(ParagraphContent);
