import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/auth";
import { mapAuthErrorsStateToProps } from "../../utils";
import BillingMessage from "./billing/BillingMessage";

const SimpleNavbar = ({ auth, logoutUser }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const nav = useNavigate();
  const location = useLocation();
  const [isNavbarHidden, hideNavbar] = useState(false);
  const value = location.pathname.split("/");

  useEffect(() => {
    if (location.pathname.includes("/profile/billing/buy/")) hideNavbar(true);
  }, [location]);

  return (
    !isNavbarHidden && (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-white dashboard-nav ">
          <div className="container-fluid d-flex justify-content-between p-0 m-0 ms-3 ">
            {value[1] !== "integrations" ? (
              <>
                <div className="d-flex align-items-center justify-content-start simple-navbar-contents">
                  {auth?.user?.transactions?.length !== 0 && (
                    <>
                      <a
                        onClick={() => {
                          nav("/");
                        }}
                        href="#"
                        className="back-arrow"
                      >
                        <ChevronLeft
                          style={{ height: "20px", width: "20px" }}
                        />
                      </a>
                      <a
                        onClick={() => {
                          nav("/");
                        }}
                        href="#"
                        className="default-link"
                      >
                        <p className="simple-navbar-title">Back to Dashboard</p>
                      </a>
                    </>
                  )}
                </div>
                <ul className="top-list">
                  <li>
                    <Link to="/profile">Account Details</Link>
                  </li>
                  <li>
                    <Link to="/profile/change/password">Change Password</Link>
                  </li>
                  <li>
                    <Link to="/profile/billing">Plan & Billing</Link>
                  </li>
                  <li>
                    <Link to="/profile/integrations/all">Connect</Link>
                  </li>
                </ul>
              </>
            ) : value[2] === "subbly" && !value[3] ? (
              <>
                <div className="d-flex align-items-center justify-content-start simple-navbar-contents pointer">
                  <a href="/profile/integrations/all">
                    <div className="back-arrow">
                      <ChevronLeft style={{ height: "20px", width: "20px" }} />
                    </div>
                  </a>
                  <a href="/profile/integrations/all">
                    <div className="default-link">
                      <p className="simple-navbar-title">
                        Back to Integration Page
                      </p>
                    </div>
                  </a>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-start simple-navbar-contents">
                <Link to="/profile/integrations/all" className="back-arrow">
                  <ChevronLeft style={{ height: "20px", width: "20px" }} />
                </Link>
                <Link to="/profile/integrations/all" className="default-link">
                  <p className="simple-navbar-title">
                    Back to Integration Page
                  </p>
                </Link>
              </div>
            )}
          </div>
          <div className="profile-settings-dropdown">
            <Avatar
              className="avatar-click"
              src={
                auth.user
                  ? auth.user.profile_picture
                    ? auth.user.profile_picture
                    : null
                  : null
              }
              onClick={() => setToggleDropdown(!toggleDropdown)}
            />
            <ul
              className={`profile-header ${
                toggleDropdown ? "open-profile" : ""
              }`}
            >
              <li>
                <Link
                  to=""
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  <span className="logout-icon"></span>Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </>
    )
  );
};

export default connect(mapAuthErrorsStateToProps, { logoutUser })(SimpleNavbar);
