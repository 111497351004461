const createSlice = require("@reduxjs/toolkit").createSlice;

const dynamicProdSlice = createSlice({
  name: "dynamicProd",
  initialState: {
    dynamicProducts: false,
    productCount: 0,
    siblingsNumber: [],
    selectedSib: 0,
    siblings: {},
    siblingChecboxIndex: 0,
    siblingVariantArray: {},
  },
  reducers: {
    setDynamicProd: (state, action) => {
      return { ...state, dynamicProducts: action.payload };
    },
    productCount: (state, action) => {
      return { ...state, productCount: action.payload };
    },
    siblingsNumber: (state, action) => {
      if (action.payload) {
        const updatedSiblings = action.payload.map((sibling, index) => {
          if (typeof sibling === "string") {
            return { name: sibling, key: `${index}`, selectedVariants: [] };
          }
          return sibling;
        });
        state.siblingsNumber = updatedSiblings;
      }
    },
    setSiblingVariant: (state, action) => {
      const { siblingIndex, productId, variantId,sellingPlanId } = action.payload;

      if (!state.siblingsNumber[siblingIndex]) {
        state.siblingsNumber[siblingIndex] = {
          ...siblingsNumber,
          selectedVariants: [],
        };
      }
      const productIndex = state.siblingsNumber[
        siblingIndex
      ].selectedVariants.findIndex((p) => p.productId === productId);

      if (productIndex >= 0) {
        state.siblingsNumber[siblingIndex].selectedVariants[
          productIndex
        ].variantId = variantId;

        state.siblingsNumber[siblingIndex].selectedVariants[productIndex].sellingPlanId = sellingPlanId;
      } else {
        state.siblingsNumber[siblingIndex].selectedVariants.push({
          productId,
          variantId,
          sellingPlanId
        });
      }
    },
    updateSelectedVariants: (state, action) => {
      const { siblingIndex, productId, variantId, sellingPlanId } = action.payload;
      let sibling = state.siblingsNumber[siblingIndex];

      if (!sibling) {
        sibling = { name: "", key: `${siblingIndex}`, selectedVariants: [] };
        state.siblingsNumber[siblingIndex] = sibling;
      }

      const variantIndex = sibling.selectedVariants.findIndex(
        (v) => v.productId === productId
      );
      if (variantIndex >= 0) {
        sibling.selectedVariants[variantIndex].variantId = variantId;
        sibling.selectedVariants[variantIndex].sellingPlanId = sellingPlanId;
      } else {
        sibling.selectedVariants.push({ productId, variantId, sellingPlanId });
      }
    },

    selectedSib: (state, action) => {
      return { ...state, selectedSib: action.payload };
    },

    setSiblingProducts: (state, action) => {
      const { siblingIndex, products } = action.payload;
      state.siblings[siblingIndex] = {
        ...state.siblings[siblingIndex],
        products,
      };
    },
    siblingChecboxIndex: (state, action) => {
      return { ...state, siblingChecboxIndex: action.payload };
    },
  },
});

export const {
  initializeSiblings,
  setDynamicProd,
  productCount,
  siblingsNumber,
  selectedSib,
  setSiblingProducts,
  siblingChecboxIndex,
  setSiblingVariant,
  updateSelectedVariants,
} = dynamicProdSlice.actions;

export default dynamicProdSlice.reducer;
