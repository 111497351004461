/* eslint-disable react-hooks/exhaustive-deps */

import { Accordion } from "react-bootstrap";
import { useEffect, useState } from "react";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import WidthOptions from "../single-components/WidthOptions";
import BorderOptions from "../single-components/BorderOptions";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const AddressStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [labelMarginLockValues, setLabelMarginLockValues] = useState(false);
  const [labelPaddingLockValues, setLabelPaddingLockValues] = useState(false);
  const [inputMarginLockValues, setInputMarginLockValues] = useState(false);
  const [inputPaddingLockValues, setInputPaddingLockValues] = useState(false);
  const [helpTextMarginLockValues, setHelpTextMarginLockValues] = useState(false);
  const [helpTextPaddingLockValues, setHelpTextPaddingLockValues] = useState(false);
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.label.fontFamily`], [e.family], widget, setWidgetDispatcher, true);
  };

  const updateInputFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.input.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };
  
  const updateHelpTextFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.helpText.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateLabelSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.label.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabelSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (labelMarginLockValues) { 
        lockAllValues(e, 
          [
            `style.${responsive}.label.marginBottom`,
            `style.${responsive}.label.marginRight`,
            `style.${responsive}.label.marginLeft`,
            `style.${responsive}.label.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (labelPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.label.paddingBottom`,
            `style.${responsive}.label.paddingRight`,
            `style.${responsive}.label.paddingLeft`,
            `style.${responsive}.label.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateInputSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (inputMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.marginBottom`,
            `style.${responsive}.input.marginRight`,
            `style.${responsive}.input.marginLeft`,
            `style.${responsive}.input.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (inputPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.paddingBottom`,
            `style.${responsive}.input.paddingRight`,
            `style.${responsive}.input.paddingLeft`,
            `style.${responsive}.input.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.borderLeftWidth`,
            `style.${responsive}.input.borderTopWidth`,
            `style.${responsive}.input.borderRightWidth`,
            `style.${responsive}.input.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.input.borderTopLeftRadius`,
            `style.${responsive}.input.borderTopRightRadius`,
            `style.${responsive}.input.borderBottomRightRadius`,
            `style.${responsive}.input.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateHelpTextSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.helpText.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (helpTextMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.helpText.marginBottom`,
            `style.${responsive}.helpText.marginRight`,
            `style.${responsive}.helpText.marginLeft`,
            `style.${responsive}.helpText.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (helpTextPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.helpText.paddingBottom`,
            `style.${responsive}.helpText.paddingRight`,
            `style.${responsive}.helpText.paddingLeft`,
            `style.${responsive}.helpText.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateHelpTextSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.helpText.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorBackgroundInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.backgroundColor`,
        `style.tablet.input.backgroundColor`,
        `style.mobile.input.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.borderColor`,
        `style.tablet.input.borderColor`,
        `style.mobile.input.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorHelpText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.helpText.color`,
        `style.tablet.helpText.color`,
        `style.mobile.helpText.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <>
      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Label</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <TypographyPopup
                  tag={getResponsiveStyle(widget?.style, responsive, "label", null)}
                  onChangeFont={updateLabelFont}
                  updateSliderstyle={updateLabelSliderstyle}
                  updateSelectstyle={updateLabelSelectstyle}
                  id={"label"} 
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorLabel}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "label", "color")}
                  />
                </div>
                <hr />

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer 
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "label", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "label", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "label", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "label", "marginBottom")}
                        onChange={updateLabelSliderstyle}
                        setLockValue={setLabelMarginLockValues}
                        lockValue={labelMarginLockValues}
                      />
                      <Spacer 
                        label={"Padding"}
                        id={"padding"}
                        
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "input", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "input", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "input", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "input", "paddingBottom")}
                        onChange={updateLabelSliderstyle}
                        setLockValue={setLabelPaddingLockValues}
                        lockValue={labelPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Input</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "input", null)}
                  onChangeFont={updateInputFont}
                  updateSliderstyle={updateInputSliderstyle}
                  updateSelectstyle={updateInputSelectstyle}
                  id={"input"} 
                />

                <div className="editable-field form-row  ">
                  <p>Font Color</p>

                  <ColorPickerStyle
                    onChange={updateColorInput}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "input", "color")}
                  />
                </div>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer 
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "input", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "input", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "input", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "input", "marginBottom")}
                        onChange={updateInputSliderstyle}
                        setLockValue={setInputMarginLockValues}
                        lockValue={inputMarginLockValues}
                      />
                      <Spacer 
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "input", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "input", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "input", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "input", "paddingBottom")}
                        onChange={updateInputSliderstyle}
                        setLockValue={setInputPaddingLockValues}
                        lockValue={inputPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <hr />
                <WidthOptions
                  handleSelect={updateInputSelectOptions}
                  tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
                  id={"width"}
                  name={"width"}
                />

                <hr />
                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundInput}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "input", "backgroundColor")}
                  />
                </div>

                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(widget?.style, responsive, "input", null)}
                  onChange={updateInputSliderstyle}
                  onChangeColor={updateBorderColor}
                  setBorderWidthLockValues={setBorderWidthLockValues}
                  setBorderRadiusLockValues={setBorderRadiusLockValues}
                  borderWidthLockValues={borderWidthLockValues}
                  borderRadiusLockValues={borderRadiusLockValues}
                  updateSelectOptions={updateInputSelectOptions} 
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Help Text
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "helpText", null)}
                  onChangeFont={updateHelpTextFont}
                  updateSliderstyle={updateHelpTextSliderstyle}
                  updateSelectstyle={updateHelpTextSelectstyle}
                  id={"help_text"} 
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>

                  <ColorPickerStyle
                    onChange={updateColorHelpText}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "helpText", "color")}
                  />
                </div>
                <Spacer 
                  label={"Margin"}
                  id={"margin"}
                  tagLeft={getResponsiveStyle(widget?.style, responsive, "helpText", "marginLeft")}
                  tagRight={getResponsiveStyle(widget?.style, responsive, "helpText", "marginRight")}
                  tagTop={getResponsiveStyle(widget?.style, responsive, "helpText", "marginTop")}
                  tagBottom={getResponsiveStyle(widget?.style, responsive, "helpText", "marginBottom")}
                  onChange={updateHelpTextSliderstyle}
                  setLockValue={setHelpTextMarginLockValues}
                  lockValue={helpTextMarginLockValues}
                />
                <Spacer 
                  label={"Padding"}
                  id={"padding"}
                  tagLeft={getResponsiveStyle(widget?.style, responsive, "helpText", "paddingLeft")}
                  tagRight={getResponsiveStyle(widget?.style, responsive, "helpText", "paddingRight")}
                  tagTop={getResponsiveStyle(widget?.style, responsive, "helpText", "paddingTop")}
                  tagBottom={getResponsiveStyle(widget?.style, responsive, "helpText", "paddingBottom")}
                  onChange={updateHelpTextSliderstyle}
                  setLockValue={setHelpTextPaddingLockValues}
                  lockValue={helpTextPaddingLockValues}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(AddressStyle);
