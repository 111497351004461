/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL } from "../../../utils";
import PaymentMethods from "./PaymentMethods";
import Plan from "./Plan";
import auth from "../../../reducers/auth";

const ChoosePaymentMethod = ({ currentSubscription }) => {
  const loc = useLocation();
  const nav = useNavigate();

  const [searchParams] = useSearchParams();

  const [selectedPlan, setSelectedPlan] = useState(
    loc.state ? loc.state.selectedPlan : null
  );

  const [selectedPm, setPm] = useState(null);

  const [loading, setLoading] = useState(false);

  const [subscriptionData, setSub] = useState(null);

  useEffect(() => {
    if (auth?.user?.transactions[0]) {
      nav("/profile/billing");
    }
  }, [auth?.user?.transactions[0]]);

  useEffect(() => {
    const price = searchParams.get("plan");

    setLoading(true);

    if (!selectedPlan && price) {
      axios
        .get(
          `${BASE_DEV_URL}/subscription/price/${price}`,
          tokenConfig(store.getState)
        )
        .then((res) => {
          setSelectedPlan(res.data.price);
        })
        .catch((err) => {
        });
    }
    setLoading(false);
  }, []);

  const createSubscription = async (price, payment_method = null) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_DEV_URL}/subscription/`,
        { price, payment_method },
        tokenConfig(store.getState)
      );

      if (res.data.payment_method) {
        nav(
          `/profile/billing/status?payment_intent=${res.data.paymentIntent.id}&payment_intent_client_secret=${res.data.paymentIntent.client_secret}&redirect_status=${res.data.paymentIntent.status}`
        );
      } else {
        const { subscriptionId, clientSecret } = res.data;

        setSub({ subscriptionId, clientSecret });
      }
    } catch (error) {
    }
    setLoading(false);
  };

  if (subscriptionData) {
    nav("/profile/billing/subscribe", {
      state: { ...subscriptionData, plan: selectedPlan },
    });
  }

  return (
    <div className="formify-card   var-width m-4">
      {!loading ? (
        <>
          <div className="formify-header integration-header mb-4">
            <h4>Selecte payment method</h4>
          </div>
          <div className="container-fluid integrations" style={{ padding: 0 }}>
            <div className="row">
              <div className="col-lg-12">
                <h4 className="formify-h4">Choose existing payment method</h4>
                <br />
                <PaymentMethods setPm={setPm} selectedPm={selectedPm} />
                <button
                  className="formify-btn formify-btn-disabled w-100 mt-2"
                  disabled={ !selectedPm }
                  onClick={() => {
                    selectedPm && createSubscription(selectedPlan.id, selectedPm);
                  }}
                >
                  Subscribe
                </button>
              </div>
              <div className="col-lg-12 d-flex justify-content-center ">
                <hr
                  className="grey-hr w-100"
                  style={{ borderColor: "black" }}
                />
                <h4 className="formify-h4 mt-2 ms-2 me-2">OR</h4>
                <hr
                  className="grey-hr w-100"
                  style={{ borderColor: "black" }}
                />
              </div>
              <div className="col-lg-12">
                <button
                  className="plan-btn"
                  onClick={() => {
                    createSubscription(selectedPlan.id);
                  }}
                >
                  Add Payment Method
                </button>
              </div>

              {selectedPlan ? (
                <Plan plan={selectedPlan} onCheckout={true} />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default ChoosePaymentMethod;
