/* eslint-disable react-hooks/exhaustive-deps */

import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getUser } from "../../../actions/auth";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../../utils";

const PaymentMethodStatus = ({ getUser }) => {
  const [search] = useSearchParams();

  const [clientSecret] = useState(search.get("setup_intent_client_secret"));

  const nav = useNavigate();

  const [msg, setMsg] = useState({
    error: false,
    text: null,
  });

  useEffect(() => {
    switch (search.get("redirect_status")) {
      case "succeeded":
        setMsg({
          error: false,
          text: "Success! Payment method created. ",
        });
        break;
      case "processing":
        setMsg({
          error: false,
          text: "Payment method processing. We'll update you when payment is received. ",
        });
        break;
      case "requires_payment_method":
        setMsg({
          error: true,
          text: "Payment method failed. Please try another payment method ",
        });

        break;

      default:
        setMsg({
          error: true,
          text: "Something went wrong.",
        });

        break;
    }

    return function cleanup() {
      getUser();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="formify-card   var-width m-4">
      <div className="formify-header integration-header mb-4">
        <h4>Payment Method Status</h4>
      </div>
      <div className="container-fluid integrations" style={{ padding: 0 }}>
        <div className="row gy-3 ">
          <div className="col-lg-12">
            <div className="container-fluid" style={{ padding: 0 }}>
              <div className="row">
                <div className="col-lg-12">
                  <Alert severity={msg.error ? "error" : "success"}>
                    {msg.text}
                  </Alert>

                  <p className="m-0 mt-2">
                    <Link to="/profile/billing" className="formify-link">
                      {" "}
                      Back to Billing
                    </Link>
                  </p>
                </div>
                {msg.error ? (
                  <a
                    href="#/"
                    className="formify-link "
                    onClick={() => {
                      nav("/profile/billing/add/paymentmethod", {
                        state: {
                          clientSecret,
                        },
                      });
                    }}
                  >
                    Try again with another payment method
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps, { getUser })(
  PaymentMethodStatus
);
