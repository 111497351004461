import { useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../../utils";

const BillingMessage = ({ styleProps, auth, type, isPending }) => {
  const calculateDays = useMemo(() => {
    const today = new Date();
    const date_to = new Date(auth?.user?.transactions[0].date_to);
    let difference = date_to.getTime() - today.getTime();
    let days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
  }, [auth]);
 

  return (
    <>
      {type !== "free" ? (
         isPending == "pending" ?
        (
          <div className={`general-notification ${"notification-" + styleProps}`}>
            <p>
            Your account status is pending, please return shortly when the order is processed.
            </p>
            {/* <Link to="/profile/billing/plans" className="formify-btn ">
              Upgrade today!
            </Link> */}
          </div>
        ) :
        <div className={`general-notification ${"notification-" + styleProps}`}>
          <p>
            You have got <b>{calculateDays} days left</b> to try premium
            features like unlimited forms & responses, advanced templates and
            priority support
          </p>
          <Link to="/profile/billing/plans" className="formify-btn ">
            Upgrade
          </Link>
        </div>
      )
      
      : (
        isPending == "pending" ?
        (
          <div className={`general-notification ${"notification-" + styleProps}`}>
            <p>
            Your account status is pending, please return shortly when the order is processed.
            </p>
            {/* <Link to="/profile/billing/plans" className="formify-btn ">
              Upgrade today!
            </Link> */}
          </div>
        ) :
        <div className={`general-notification ${"notification-" + styleProps}`}>
          <p>
            As a free user, you're currently missing out on some  of the amazing benefits our higher-tier plans have to offer.
          </p>
          <Link to="/profile/billing/plans" className="formify-btn ">
            Upgrade today!
          </Link>
        </div>
      )   
      }

      {}
      <div style={{ height: 60, width: "100%" }}></div>
    </>
  );
};

export default connect(mapAuthErrorsStateToProps)(BillingMessage);
