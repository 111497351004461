import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../utils";
import { Route, Routes } from "react-router-dom";
import CurrentPlan from "../dashboard/billing/CurrentPlan";
import PaymentInformation from "../dashboard/billing/PaymentInformation";
import BillingInvoice from "../dashboard/billing/BillingInvoice";
import PlanOptions from "../dashboard/billing/PlanOptions";
import Subscribe from "../dashboard/billing/Subscribe";
import PaymentStatus from "../dashboard/billing/PaymentStatus";
import axios from "axios";
import { tokenConfig, getUser } from "../../actions/auth";
import store from "../../store";
import { toast } from "react-toastify";
import CreatePaymentMethod from "../dashboard/billing/CreatePaymentMethod";
import PaymentMethodStatus from "../dashboard/billing/PaymentMethodStatus";
import ChoosePaymentMethod from "../dashboard/billing/ChoosePaymentMethod";
import CancelPlan from "../dashboard/billing/CancelPlan";
import PaymentCheckoutReturn from "../dashboard/billing/messages/PaymentCheckoutReturn";

const PlanBilling = ({ auth, getUser }) => {
  const [subscription, setSubscription] = useState(null);

  const [invoices, setInvoices] = useState([]);

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  useEffect(() => {
    if (auth.user) {
      axios
        .get(`${BASE_DEV_URL}/subscription`, tokenConfig(store.getState))
        .then((res) => {
          setSubscription(res.data);
            
        })
        .catch((err) => {
          setSubscription(null);
        });
    }
  }, [auth]);

  useEffect(() => {
    setInvoiceLoading(true);
    axios
      .get(`${BASE_DEV_URL}/subscription/invoices`, tokenConfig(store.getState))
      .then((res) => {
        setInvoices(res.data.invoices);
        setInvoiceLoading(false);
      })
      .catch((err) => {
        setInvoiceLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <CurrentPlan currentSubscription={subscription} />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8 mt-3">
                <PaymentInformation
                  currentSubscription={subscription}
                  subscriptionHandle={setSubscription}
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8 mt-3">
                <BillingInvoice invoices={invoices} loading={invoiceLoading} />
              </div>
            </>
          }
        />
        <Route
          path="/plans"
          element={<PlanOptions currentSubscription={subscription} />}
        />
        <Route path="/:type" element={<PaymentCheckoutReturn />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/status" element={<PaymentStatus />} />
        <Route
          path="/choose/paymentmethod"
          element={<ChoosePaymentMethod currentSubscription={subscription} />}
        />
        <Route path="/add/paymentmethod" element={<CreatePaymentMethod />} />
        <Route path="/paymentmethod/status" element={<PaymentMethodStatus />} />
        <Route
          path="/cancel"
          element={<CancelPlan currentSubscription={subscription} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, { getUser })(PlanBilling);
