/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import RandomAvatars from "../utils/RandomAvatars";
import { toast } from "react-toastify";
import ConfirmModal from "../utils/ConfirmModal";

const ContactDetail = () => {
  const nav = useNavigate();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [contact, setContact] = useState(null);
  const [deleteIntent, setDelete] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/contact/${params.id}`, tokenConfig(store.getState))
      .then((res) => {
        setContact(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  const deleteContact = async (id) => {
    try {
      const res = await axios.delete(
        `${BASE_DEV_URL}/contact/${id}`,
        tokenConfig(store.getState)
      );

      if (res.data.success) {
        nav("/contacts");
        toast.success("Contact deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (deleteIntent) {
      setShow(true);
    }
  }, [deleteIntent]);

  return contact ? (
    <div className="container-fluid mt-4 contact-list pos-relative pl-20 pb-100 contact-detail ">
      <ConfirmModal
        show={show}
        contact={deleteIntent}
        setDel={setDelete}
        setShow={setShow}
        del={deleteContact}
      />
      <div className="row">
        <div className="col-lg-12">
          <h2 className="formify-h2">Contact Details</h2>
        </div>
        <div className="">
          <div className="contact-summary">
            <div className="disp-flex">
              <div className="avatar-details">
                <RandomAvatars />
              </div>
              <div className="info">
                <h1 className="formify-h1 me-4 formify-title">
                  {contact.email}
                </h1>
                <div className="added-date">
                  <p className="formify-p time-created-ribbon">
                    Created Time:
                    <span>{new Date(contact.created_at).toLocaleString()}</span>
                  </p>
                </div>
                <div className="added-date subscribe-in-margin">
                  <p className="formify-p time-created-ribbon">
                    Subscribed In:
                    <span>
                      <p>{contact.form ? contact.form.name : "Deleted Form"}</p>
                    </span>
                  </p>
                </div>
                <div className="info-header">
                  <div
                    className="delete-contact-btn delete-btn"
                    onClick={() => {
                      setDelete(contact);
                    }}
                  >
                    Delete Contact
                  </div>
                </div>
              </div>
            </div>
            <div className="data-containers-details">
              <p>Email Address: {!contact.email ? "-" : contact.email}</p>
              <p>
                First Name: {!contact.first_name ? "-" : contact.first_name}
              </p>
              <p>Last Name: {!contact.last_name ? "-" : contact.last_name}</p>
              <p>Phone: {!contact.phone_number ? "-" : contact.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ContactDetail;
