import { Drawer, Icon, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { ChevronLeft, Info, Video } from "react-feather";
import ChangeResponsiveness from "./settings-components/single-components/changeResponsiveness";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setFormStatesDispatcher } from "../../actions/form";
import shopifyimg from "./../loaders/shopify.png";
import playicon from "./../loaders/playvideo.svg";

const PublishSidebar = ({
  form: { form, formStates },
  setFormStatesDispatcher,
}) => {
  const [publishLink, setLink] = useState(null);
  const [iframeLink, setIframe] = useState(null);
  const [formId, setFormId] = useState(null);
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const hasShopifyIntegration = Boolean(form?.integrations?.shopify);

  const [tooltip, setTooltip] = useState({
    link: false,
    iframe: false,
    form_id: false,
  });

  useEffect(() => {
    if (form) {
      if (process.env.NODE_ENV === "development") {
        setLink(`http://localhost:3000/form/fill/${form._id}`);
        setIframe(`
          <iframe id="${form._id}" src="http://localhost:3000/form/fill/${form.id}" frameborder="0" style="min-width: 100%; border:none;"></iframe>
          <script src="http://localhost:3000/embed.js"></script>
        `);
        setFormId(form._id);
      } else if (
        process.env.REACT_APP_ENV === "testing" &&
        process.env.NODE_ENV === "production"
      ) {
        setLink(`https://testing.getformify.com/form/fill/${form._id}`);
        setIframe(
          `<iframe id="formify-/${form._id}" title="Form" onload="window.parent.scrollTo(0,0)"   src="https://testing.getformify.com/form/fill/${form.id}" frameborder="0" style=" min-width: 100%; height:100vh; border:none;"></iframe>`
        );
        setFormId(form._id);
      } else {
        setLink(`https://beta.getformify.com/form/fill/${form._id}`);
        setIframe(
          `<iframe id="formify-/${form._id}" title="Form" onload="window.parent.scrollTo(0,0)"   src="https://beta.getformify.com/form/fill/${form.id}" frameborder="0" style=" min-width: 100%; height:100vh; border:none;"></iframe>`
        );
        setFormId(form._id);
      }
    }
  }, [form]);

  const copyToClipBoard = (value, element) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value).then((res) => {
        setTooltip({
          ...tooltip,
          [element]: true,
        });
      });
    }
  };

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <div className="sidebar-wraper">
          <div className="form-information">
            <h4 className="form-name">{form ? form.name : ""}</h4>
            <p className="form-url">beta.getformify.com/{formId}</p>
          </div>
          <div className="sidebar-settings-wraper">
            <div className="header">
              <h4 className="formify-h4">Publish</h4>
              <p className="formify-p">
                Publish your form from one of the options below
              </p>
            </div>
            <div className="settings-body">
              <div className="publish">
                <h4 className="formify-h4">Quick Share Link</h4>
                <Tooltip title="Copied to clipboard" open={tooltip.link}>
                  <div className="share-link">
                    <p
                      className="formify-p m-0"
                      onClick={() => {
                        copyToClipBoard(publishLink, "link");
                      }}
                      onMouseLeave={() => {
                        setTooltip({ ...tooltip, link: false });
                      }}
                    >
                      {publishLink}
                    </p>
                  </div>
                </Tooltip>
                <button
                  className="formify-btn-no-margin mt-3"
                  onClick={() => {
                    window.open(publishLink);
                  }}
                >
                  Open New Tab
                </button>
              </div>
              <div className="publish mt-4">
                <h4 className="formify-h4">Signup Flow Id</h4>
                <Tooltip title="Copied to clipboard" open={tooltip.form_id}>
                  <div className="share-link">
                    <p
                      className="formify-p m-0"
                      onClick={() => {
                        copyToClipBoard(formId, "form_id");
                      }}
                      onMouseLeave={() => {
                        setTooltip({ ...tooltip, form_id: false });
                      }}
                      onMouseOver={connect}
                    >
                      {formId}
                    </p>
                  </div>
                </Tooltip>
              {hasShopifyIntegration && 
              <div
              className="flow-id-text"
              onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              <p>
                Shopify User Guide <Info />
              </p>
            </div>
              }
                
                {isHovered && (
                  <div
                    className="shopify-user-guide"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={shopifyimg}
                      className="img-fluid shopify-image-modal"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                    <p>
                      <strong>Connect your form with your store easily!</strong>
                    </p>
                    <div>
                      <p>
                        Connecting your signup flow with your store has never
                        been easier!
                      </p>
                      <p>
                        Simply copy your unique ‘Signup Flow ID’ and paste it
                        into your Formify app extension.
                      </p>
                      <p>
                        Watch the full video for a preview on how to add the app
                        extension as a section or block within your store!
                      </p>
                      <img
                        src={playicon}
                        width={25}
                        height={25}
                        alt="icon-play"
                      />
                      <span style={{ marginLeft: "10px" }}>
                        <button
                          className="shopify-modal-btn "
                          onClick={() => {
                            window.open(
                              "https://www.loom.com/share/24e96759f27b4382ae4f15bc2b697d08?sid=41823e2f-7293-4f87-adf3-674ccf1c2871"
                            );
                          }}
                        >
                          Play Full Video
                        </button>
                      </span>
                      <ol className="mt-3 p-0">
                        <strong>Resources</strong>
                        <li className="resource-video-li">
                          How to remove Formify <br></br> from your storefront?{" "}
                          <span style={{ marginLeft: "10px" }}>
                            <button
                              className="shopify-modal-btn resource-btn-li"
                              onClick={() => {
                                window.open(
                                  " https://www.loom.com/share/b88869cf6fa14a4285b16c68530f2d1f"
                                );
                              }}
                            >
                              Play Full Video
                            </button>
                          </span>{" "}
                        </li>
                        <li className="resource-video-li">
                          How to add Formify <br></br> for Vintage Shopify
                          Themes?
                          <span style={{ marginLeft: "10px" }}>
                            <button
                              className="shopify-modal-btn resource-btn-li"
                              onClick={() => {
                                window.open(
                                  "https://www.loom.com/share/23dcac191c8b4b0a8b99574784ca3914?sid=44de9af8-2676-44b8-b819-d9c8c0968c7a"
                                );
                              }}
                            >
                              Play Full Video
                            </button>
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                )}
              </div>
              <div className="publish mt-4">
                <h4 className="formify-h4">Embed</h4>
                <Tooltip title="Copied to clipboard" open={tooltip.iframe}>
                  <div className="share-link">
                    <p
                      className="formify-p m-0"
                      onClick={() => {
                        copyToClipBoard(iframeLink, "iframe");
                      }}
                      onMouseLeave={() => {
                        setTooltip({ ...tooltip, iframe: false });
                      }}
                    >
                      {iframeLink}
                    </p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <ChangeResponsiveness />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormStatesDispatcher })(
  PublishSidebar
);
