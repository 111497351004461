import { useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect, useSelector } from "react-redux";
import {
  setFormStatesDispatcher,
  setWidgetResponsesDispatcher,
} from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert, Radio } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import Product from "../../form/widget-components/Product";
import { AlertCircle } from "react-feather";
import {
  checkResponsiveStyle,
  getResponsiveStyle,
} from "../responsiveFunctions";
import { Dropdown, DropdownButton } from "react-bootstrap";

export const ProductRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  showUpsell,
  setUpsell,
  setWidgetResponsesDispatcher,
  errorCheckbox,
  isNextClicked,
  setErrorCheckbox,
  setFormStatesDispatcher,
  activeStep,
  handleResponse,
  buttonRef,
  form: { responses, responseError, form, widget },
}) => {
  let layout = getResponsiveStyle(element?.style, screen, "layout", null) || 1;
  let columnGap = getResponsiveStyle(element?.style, screen, "columnGap", null);
  let rowGap = getResponsiveStyle(element?.style, screen, "rowGap", null);
  let colNum = getResponsiveStyle(element?.content, screen, "colNum", null);


  // console.log("rerer", buttonRef)
  const selectedProduct = useMemo(() => {
    if (responses[element._id]?.value) {
      return element.content.products.find(
        (item) => item.id == responses[element._id]?.value
      );
    } else if (isEditable) return element.content.products[0];
    return null;
  }, [responses, element, isEditable]);

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            {(form.store_selected != "woocommerce" ||
              (form.store_selected == "woocommerce" &&
                !responses[element._id]?.value)) && (
              <div className={"widget-inner-container"}>
                <div
                  className="pt-3  pb-3 rounded product-list"
                  style={{
                    alignItems: "start",
                    display: "grid",
                    gridTemplateColumns:
                      layout === "2" ? `repeat(${colNum}, 1fr)` : "",
                    columnGap: layout === "2" ? columnGap : "",
                    rowGap: layout === "2" ? rowGap : "",
                    textAlign: element.content.alignment,
                  }}
                >
                  {form.store_selected ? (
                    element.content.products.length > 0 ? (
                      responses && element.content.upsell && showUpsell ? (
                        <>
                          <h4 className="formify-h4">
                            {element.content.upsell.text}
                          </h4>
                          {/* <label className="d-flex flex-row align-items-center"> */}
                          <label className="">
                            <Product
                              storeSelected={form.store_selected}
                              view={layout}
                              rdyProduct={element.content.upsell.product}
                              publicView={!isEditable}
                              style={checkResponsiveStyle(
                                element.style,
                                screen,
                                null
                              )}
                              element={element}
                              form={form}
                              screen={screen}
                            />
                          </label>
                          {form.store_selected === "shopify" ? (
                            element?.content?.upsell?.product?.variants
                              ?.length > 0 ? (
                              element.content.upsell.product.variants[0].id ===
                              responses ? null : (
                                <div className="mt-2 d-flex flex-row">
                                  <button
                                    className="invert-formify-btn-hover"
                                    onClick={(e) => {
                                      setWidgetResponsesDispatcher(
                                        element._id,
                                        {
                                          value:
                                            element.content.upsell.product
                                              .variants.length > 0
                                              ? element.content.upsell.product
                                                  .variants[0].id || ""
                                              : element.content.upsell.product
                                                  .id,
                                          product_id:
                                            element.content.upsell.product.id,
                                        },
                                        "form_product"
                                      );
                                    }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="invert-formify-btn-hover"
                                    onClick={() => {
                                      setUpsell(false);
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              )
                            ) : element.content.upsell.product.id ===
                              responses ? null : (
                              <div className="mt-2 d-flex flex-row">
                                <button
                                  className="invert-formify-btn-hover"
                                  onClick={(e) => {
                                    setWidgetResponsesDispatcher(
                                      element._id,
                                      {
                                        value:
                                          element.content.upsell.product
                                            .variants.length > 0
                                            ? element.content.upsell.product
                                                .variants[0].id || ""
                                            : element.content.upsell.product.id,
                                        product_id:
                                          element.content.upsell.product.id,
                                      },
                                      "form_product"
                                    );
                                  }}
                                >
                                  Yes
                                </button>

                                <button
                                  className="invert-formify-btn-hover"
                                  onClick={() => {
                                    setUpsell(false);
                                  }}
                                >
                                  No
                                </button>
                              </div>
                            )
                          ) : form.store_selected === "subbly" ? (
                            element.content.upsell.product.product_id ===
                            responses ? null : (
                              <div className="mt-2 d-flex flex-row">
                                <button
                                  className="invert-formify-btn-hover"
                                  onClick={(e) => {
                                    setWidgetResponsesDispatcher(
                                      element._id,
                                      {
                                        value:
                                          form.store_selected === "shopify"
                                            ? element?.content?.upsell?.product
                                                ?.variants?.length > 0
                                              ? element.content.upsell.product
                                                  .variants[0].id || ""
                                              : element.content.upsell.product
                                                  .id
                                            : form.store_selected === "subbly"
                                            ? element.content.upsell.product
                                                .product_id
                                            : element.content.upsell.product.id,
                                      },
                                      "form_product"
                                    );
                                  }}
                                >
                                  Yes
                                </button>

                                <button
                                  className="invert-formify-btn-hover"
                                  onClick={() => {
                                    setUpsell(false);
                                  }}
                                >
                                  No
                                </button>
                              </div>
                            )
                          ) : element.content.upsell.product.id ===
                            responses ? null : (
                            <div className="mt-2 d-flex flex-row">
                              <button
                                className="invert-formify-btn-hover"
                                onClick={(e) => {
                                  setWidgetResponsesDispatcher(
                                    element._id,
                                    {
                                      value:
                                        form.store_selected === "shopify"
                                          ? element?.content?.upsell?.product
                                              ?.variants?.length > 0
                                            ? element.content.upsell.product
                                                .variants[0].id || ""
                                            : element.content.upsell.product.id
                                          : form.store_selected === "subbly"
                                          ? element.content.upsell.product
                                              .product_id
                                          : element.content.upsell.product.id,
                                    },
                                    "form_product"
                                  );
                                }}
                              >
                                Yes
                              </button>

                              <button
                                className="invert-formify-btn-hover"
                                onClick={() => {
                                  setUpsell(false);
                                }}
                              >
                                No
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        element.content.products.map((product, index) => {
                          return !isEditable ? (
                            !isEditable ? (
                              <label
                                className=""
                                style={{ position: "relative" }}
                              >
                                {
                                  // form.store_selected === "shopify"
                                  // ? parseInt(product.product_id) ===
                                  //     parseInt(responses[element._id]?.value)
                                  //   : form.store_selected === "subbly"
                                  //   ? parseInt(product.product_id) ===
                                  //     parseInt(responses[element._id]?.value)
                                  // :
                                  product.id ===
                                    parseInt(responses[element._id]?.value) ||
                                  parseInt(product.product_id) ===
                                    parseInt(responses[element._id]?.value) ? (
                                    <span className="selected-checkbox">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path
                                          fill="none"
                                          stroke="white"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          strokeWidth="3"
                                          d="M6 10l3 3l6-6"
                                        />
                                      </svg>
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                                <Radio
                                  value={
                                    form.store_selected === "shopify"
                                      ? product?.variants?.length > 0
                                        ? product?.variants[0]?.id || ""
                                        : product.id
                                      : // : form.store_selected === "subbly"
                                        // ? product.product_id
                                        product.id
                                  }
                                  name={element._id}
                                  checked={
                                    form.store_selected === "shopify"
                                      ? parseInt(product.product_id) ===
                                        parseInt(responses[element._id]?.value)
                                      : // : form.store_selected === "subbly"
                                        // ? parseInt(product.product_id) ===
                                        //   parseInt(responses[element._id]?.value)
                                        product.id ===
                                        parseInt(responses[element._id]?.value)
                                  }
                                  onClick={(e) => {
                                    if (form.steps.length === activeStep) {
                                      setTimeout(() => {
                                        buttonRef.current.click();
                                      }, 1000)
                                    } else {
                                    }
                                    // handleResponse(true);
                                    setErrorCheckbox(false);
                                    if (
                                      parseInt(e.target.value) ===
                                      parseInt(responses[element._id]?.value)
                                    )
                                      {

                                        setWidgetResponsesDispatcher(
                                        element._id,
                                        {value:""},
                                        "form_product"
                                      );}
                                    else {
                                      setErrorCheckbox(false);
                                      if (form.store_selected === "shopify") {
                                        setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            product_id: product.id,
                                            value: e.target.value,
                                            sellingPlanId: element.content.products_objects_array[index]?.sellingPlanId ? element.content.products_objects_array[index]?.sellingPlanId : null
                                          },
                                          "form_product"
                                        );
                                      } else {
                                        setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            value: e.target.value,
                                          },
                                          "form_product"
                                        );
                                      }
                                      setUpsell(true);
                                    }
                                  }}
                                  onChange={(e) => {
                                    setErrorCheckbox(false);
                                    if (
                                      parseInt(e.target.value) ===
                                      parseInt(responses[element._id]?.value)
                                    )
                                      setWidgetResponsesDispatcher(
                                        element._id,
                                        {value:""},
                                        "form_product"
                                      );
                                    else {
                                      setErrorCheckbox(false);
                                      if (form.store_selected === "shopify") {
                                        setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            product_id: product.id,
                                            value: e.target.value,
                                            sellingPlanId: element.content.products_objects_array[index]?.sellingPlanId ? element.content.products_objects_array[index]?.sellingPlanId : null
                                          },
                                          "form_product"
                                        );
                                      } else {
                                    setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            value: e.target.value,
                                          },
                                          "form_product"
                                        );
                                      }
                                      setUpsell(true);
                                    }
                                  }}
                                  disabled={!form.store_selected}
                                />

                                <Product
                                  storeSelected={form.store_selected}
                                  details={
                                    element.content.products_objects_array
                                      ? element.content.products_objects_array[
                                          index
                                        ]
                                      : {}
                                  }
                                  view={layout}
                                  rdyProduct={product}
                                  publicView={!isEditable}
                                  style={checkResponsiveStyle(
                                    element.style,
                                    screen,
                                    null
                                  )}
                                  element={element}
                                  form={form}
                                  sellingPlanId={
                                    element.content.products_objects_array[
                                      index
                                    ]?.sellingPlanId
                                      ? element.content.products_objects_array[
                                          index
                                        ].sellingPlanId
                                      : null
                                  }
                                  screen={screen}
                                />
                              </label>
                            ) : (
                              <Product
                                product_id={product}
                                sellingPlanId={
                                  element.content.products_objects_array[index]
                                    ?.sellingPlanId
                                    ? element.content.products_objects_array[
                                        index
                                      ].sellingPlanId
                                    : null
                                }
                                details={
                                  element.content.products_objects_array
                                    ? element.content.products_objects_array[
                                        index
                                      ]
                                    : {}
                                }
                                storeSelected={form.store_selected}
                                view={layout}
                                style={checkResponsiveStyle(
                                  element.style,
                                  screen,
                                  null
                                )}
                                element={element}
                                form={form}
                                screen={screen}
                              />
                            )
                          ) : (
                            <Product
                              product_id={product}
                              sellingPlanId={
                                element.content.products_objects_array[index]
                                  ?.sellingPlanId
                                  ? element.content.products_objects_array[
                                      index
                                    ]?.sellingPlanId
                                  : null
                              }
                              details={
                                element.content.products_objects_array
                                  ? element.content.products_objects_array[
                                      index
                                    ]
                                  : {}
                              }
                              view={layout}
                              storeSelected={form.store_selected}
                              publicView={!isEditable}
                              style={checkResponsiveStyle(
                                element.style,
                                screen,
                                null
                              )}
                              element={element}
                              form={form}
                              screen={screen}
                            />
                          );
                        })
                      )
                    ) : (
                      <div className="col-12">
                        <h4 className="formify-h4">No products selected </h4>
                        <div
                          className="d-flex flex-row alert-danger align-items-center p-2"
                          style={{ borderRadius: "7px" }}
                        >
                          <AlertCircle className="me-3" />
                          <p className="formify-p">
                            You have no products selected, edit this widget and
                            select your favorite products.
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="col-12">
                      <h4 className="formify-h4">Store is not connected</h4>
                      <div
                        className="d-flex flex-row alert-danger align-items-center p-2"
                        style={{ borderRadius: "7px" }}
                      >
                        <AlertCircle className="me-3" />
                        <p className="formify-p">
                          To use this widget you need to connect a store. Go to
                          <span
                            style={{ color: "#6563ff", cursor: "pointer" }}
                            onClick={() =>
                              setFormStatesDispatcher(true, "openIntegrations")
                            }
                          >
                            {" "}
                            Integrations
                          </span>{" "}
                          and select your favorite store.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {errorCheckbox ? (
                  <p className="error-message">
                    Please select a product to continue
                  </p>
                ) : (
                  ""
                )}
              </div>
            )}

            {form.store_selected == "woocommerce" &&
              (responses[element._id]?.value || isEditable) &&
              selectedProduct && (
                <>
                  {!isEditable && (
                    <p
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setWidgetResponsesDispatcher(
                          element._id,
                          {
                            value: "",
                          },
                          "form_product"
                        );
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="-4.5 0 20 20"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-345.000000, -6679.000000)"
                            fill="#000000"
                          >
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              <path d="M299.633777,6519.29231 L299.633777,6519.29231 C299.228878,6518.90256 298.573377,6518.90256 298.169513,6519.29231 L289.606572,6527.55587 C288.797809,6528.33636 288.797809,6529.60253 289.606572,6530.38301 L298.231646,6538.70754 C298.632403,6539.09329 299.27962,6539.09828 299.685554,6538.71753 L299.685554,6538.71753 C300.100809,6538.32879 300.104951,6537.68821 299.696945,6537.29347 L291.802968,6529.67648 C291.398069,6529.28574 291.398069,6528.65315 291.802968,6528.26241 L299.633777,6520.70538 C300.038676,6520.31563 300.038676,6519.68305 299.633777,6519.29231"></path>
                            </g>
                          </g>
                        </g>
                      </svg>{" "}
                      <span className="ms-2">Back</span>
                    </p>
                  )}
                  {selectedProduct?.attributes ? (
                    selectedProduct?.attributes?.map((attr) => {
                      const select_name = attr.name
                        .toLowerCase()
                        .replaceAll(" ", "-");
                      return (
                        <div className="col-lg-12 input-holder">
                          <h4
                            className="formify-h4"
                            style={{
                              ...checkResponsiveStyle(
                                element.style,
                                screen,
                                "attr_label"
                              ),
                              fontFamily:
                                element?.style?.desktop?.attr_label?.fontFamily
                                  ?.name,
                            }}
                          >
                            {attr.name}
                          </h4>
                          <select
                            value={responses[element._id][select_name]}
                            onChange={(e) => {
                              setWidgetResponsesDispatcher(
                                element._id,
                                {
                                  [select_name]: e.target.value,
                                },
                                "form_product"
                              );
                            }}
                            style={{
                              ...checkResponsiveStyle(
                                element.style,
                                screen,
                                "attr_input"
                              ),
                              fontFamily:
                                element?.style?.desktop?.attr_label?.fontFamily
                                  ?.name,
                            }}
                          >
                            <option value="">Choose</option>
                            {attr.options.map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-lg-12 input-holder">
                      <h4
                        className="formify-h4"
                        style={{
                          ...checkResponsiveStyle(
                            element.style,
                            screen,
                            "attr_label"
                          ),
                          fontFamily:
                            element?.style?.desktop?.attr_label?.fontFamily
                              ?.name,
                        }}
                      >
                        Attribute name
                      </h4>
                      <select
                        style={{
                          ...checkResponsiveStyle(
                            element.style,
                            screen,
                            "attr_input"
                          ),
                          fontFamily:
                            element?.style?.desktop?.attr_label?.fontFamily
                              ?.name,
                        }}
                      >
                        <option>Attribute option</option>
                      </select>
                    </div>
                  )}
                </>
              )}
            {!isEditable && responseError ? (
              Object.keys(responseError).length > 0 ? (
                <p
                  className="error-message"
                  style={{
                    fontFamily: element.style?.desktop?.label?.fontFamily?.name,
                  }}
                >
                  {responseError[element._id]}
                </p>
              ) : null
            ) : null}
            {isEditable && (
              <WidgetEditTabs
                element={element}
                editElement={editElement}
                showDuplicateButton={false}
              />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
  setFormStatesDispatcher,
})(ProductRender);
