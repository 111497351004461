/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { ArrowRight, Plus, X } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import {
  BASE_DEV_URL,
  formGetCrmFields,
  integrationFooter,
  mapAuthAndFormStateToProps,
} from "../../../utils";
import { connect } from "react-redux";
import { disconnectIntegration } from "../../utils/formFunctions";
import { setFormDispatcher } from "../../../actions/form";
import { handleSetForm } from "../../utils/handleDispatcher";
import { integrationAccountValidation } from "../../utils/formBackendFunctions";

const ActiveCampaign = ({
  auth,
  form: { form },
  setFormDispatcher,
  disconnectIntegration,
}) => {
  const nav = useNavigate();
  const params = useParams();
  const [formData, setForm] = useState({
    fields: [],
    list: form?.integrations?.active_campaign?.list
      ? form.integrations.active_campaign.list
      : null,
    listname: form?.integrations?.active_campaign?.listname
      ? form.integrations.active_campaign.listname
      : null,
    elements: [],
    show: false,
  });

  const emailField = formData.elements.filter(function (n) {
    return n.element_type === "form_email";
  });

  const [acList, setList] = useState([]);
  const [acFields, setFields] = useState([]);

  const DEFAULT_FIELDS = [
    { title: "email", id: "email" },
    { title: "phone", id: "phone" },
    { title: "firstname", id: "firstname" },
    { title: "lastname", id: "lastname" },
  ];

  const acticeCampaignIsAuth = useMemo(() => {
    return auth.user
      ? auth.user.crm
        ? auth.user.crm.active_campaign
          ? true
          : false
        : false
      : false;
  }, [auth]);

  const formHasAC = useMemo(() => {
    return form
      ? form.integrations
        ? form.integrations.active_campaign
          ? true
          : false
        : false
      : false;
  }, [form]);

  useEffect(() => {
    if (acticeCampaignIsAuth) {
      axios
        .get(
          `${BASE_DEV_URL}/crm/activecampaign/lists `,
          tokenConfig(store.getState)
        )
        .then((result) => {
          setList([...result.data.lists]);
          if (result.data.lists.length > 0) {
            if (form.integrations.active_campaign) {
              // Assuming this object contains your saved settings
              const activeCampaignSettings = form.integrations.active_campaign;

              // Set state based on fetched settings
              setForm((prevState) => ({
                ...prevState,
                list: form.integrations.active_campaign.list,
                listname: form.integrations.active_campaign.listname,
                fields: form.integrations.active_campaign.fields,
              }));
            } else {
              setForm((prevState) => ({
                ...prevState,
                list: result.data.lists[0].id,
              }));
            }
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
      axios
        .get(
          `${BASE_DEV_URL}/crm/activecampaign/fields`,
          tokenConfig(store.getState)
        )
        .then((result) => {
          setFields([...result.data.fields, ...DEFAULT_FIELDS]);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }, [acticeCampaignIsAuth]);

  useEffect(() => {
    if (
      form &&
      form.steps &&
      form.steps.some((step) => {
        return step.elements;
      })
    ) {
      let elements = formGetCrmFields(form);
      if (formHasAC) {
        setForm({
          ...formData,
          fields: form.integrations.active_campaign.fields,
          list: form.integrations.active_campaign.list,
          elements,
        });
      } else {
        setForm({
          ...formData,
          // show: true
          elements,
          fields: [
            {
              integration_field: "email",
              formify_field: elements.length > 0 ? elements[0]._id : null,
            },
          ],
        });
      }
    }
  }, [formHasAC, form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!integrationAccountValidation(auth, "ActiveCampaign")) return;
    const updatedForm = handleSetForm(
      [`integrations.active_campaign`],
      [
        {
          list: formData.list,
          fields: formData.fields,
          listname: formData.listname,
        },
      ],
      form
    );
    setFormDispatcher(updatedForm, true);
    toast.success("Fields are created");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the name of the select element is 'list', we want to update the list state.
    if (name === "list") {
      const selectedList = acList.find((list) => list.id === value);
      setForm((prevState) => ({
        ...prevState,
        list: value, // Set the selected list's ID
        listname: selectedList ? selectedList.name : "", // Update the listname as well
      }));
    }
  };

  const removeRow = (index) => {
    const fields = Array.from(formData.fields);
    fields.splice(index, 1);

    setForm({
      ...formData,
      fields: fields,
    });
  };

  const handleIntegrationChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], integration_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const handleFormifyChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], formify_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const authenticateBody = () => {
    return (
      <div className="col-12 integration-body mt-4">
        {acticeCampaignIsAuth ? (
          formHasAC ? (
            <>
              <h4 className="formify-h4">Integration is up and running!</h4>
              <p className="formify-sm-p">
                You have successfully created your integration.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                }}
              >
                Edit Configuration
              </button>
            </>
          ) : (
            <>
              <h4 className="formify-h4">Active Campaign Configuration</h4>
              <p className="formify-sm-p">
                Give Active Campaign access to your form in order to import
                products to Formify.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                  // const updatedForm = handleSetForm([`integrations.active_campaign`], [{ list: acList[0].id, fields: [] }], form);
                  // setFormDispatcher(updatedForm, true);
                }}
              >
                Configure Active Campaign
              </button>
            </>
          )
        ) : (
          <>
            <h4 className="formify-h4">Active Campaign Authenticaiton</h4>
            <p className="formify-sm-p">
              Give Active Campaign access to your form in order to import
              products to Formify.
            </p>
            <button
              onClick={() => {
                nav("/integrations/activecampaign");
              }}
              className="formify-btn-no-margin mt-3"
            >
              Authenticate
            </button>
          </>
        )}
      </div>
    );
  };
  const acForm = () => {
    return (
      <div className=" integration-form">
        <form onSubmit={handleSubmit}>
          <hr className="grey-hr" />
          <div className="input-holder">
            <label htmlFor="sobject">Choose a list</label>
            <p className="formify-sm-p">
              Select the list you want to send responses to
            </p>
            <select
              id="list"
              name="list"
              value={formData.list}
              onChange={handleChange}
            >
              {acList.length > 0
                ? acList.map((list) => {
                    return (
                      <option value={list.id} key={list.id}>
                        {list.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="input-holder">
            <label>Match Fields</label>
            <p className="formify-sm-p">
              Connect the fields on your form with the fields of Active Campaign
              Contact
            </p>
            {formData.fields.map((field, index) => {
              return (
                <div className="form-input col-8">
                  <div className="me-3">
                    <label className="formify-sm-p">
                      Active Campaign Field
                    </label>
                    <select
                      value={field.integration_field}
                      disabled={field.integration_field === "email"}
                      className="integration-select"
                      style={
                        field.integration_field === "email"
                          ? { cursor: "not-allowed" }
                          : null
                      }
                      onChange={(e) => {
                        handleIntegrationChange(e, index);
                      }}
                    >
                      {acFields.map((prop) => {
                        return (
                          <option value={prop.id}>
                            {prop.title.charAt(0).toUpperCase() +
                              prop.title.slice(1)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="me-2">
                    <label className="formify-sm-p">Formify Field</label>
                    <select
                      id="email"
                      value={field.formify_field}
                      onChange={(e) => {
                        handleFormifyChange(e, index);
                      }}
                      className="integration-select"
                    >
                      {field.integration_field === "emailsss" ? (
                        emailField.length > 0 ? (
                          <option
                            value={emailField[0]?._id}
                            key={emailField[0]?._id}
                          >
                            {emailField[0]?.name}
                          </option>
                        ) : (
                          <option value={0} key={0}>
                            No email widget
                          </option>
                        )
                      ) : (
                        formData.elements.map((element) => {
                          return (
                            <option value={element._id} key={element._id}>
                              {element.content.title
                                ? element.content.title
                                : element.content.label
                                ? element.content.label
                                : element.content.question
                                ? element.content.question
                                : element.content.question_text
                                ? element.content.question_text
                                : element.name}
                            </option>
                          );
                        })
                      )}
                    </select>
                  </div>
                  {field.integration_field === "email" &&
                  emailField.length < 1 ? (
                    <div className="ac-warning">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/warningorange.svg"
                        }
                        width={"25px"}
                        height={"25px"}
                      ></img>
                      <div className="ac-message">
                        You are missing an email widget on your form.
                        Submissions will not be saved in ActiveCampaign!
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {field.integration_field === "email" ? null : (
                    <div
                      className="remove-row"
                      onClick={() => {
                        removeRow(index);
                      }}
                    >
                      <X style={{ color: "#ea4335" }} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div
            className="add-new-field d-flex mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (formData.fields.length < acFields.length) {
                setForm({
                  ...formData,
                  fields: [
                    ...formData.fields,
                    {
                      integration_field:
                        acFields.length > 0 ? acFields[0].id : null,
                      formify_field:
                        formData.elements.length > 0
                          ? formData.elements[0]._id
                          : null,
                    },
                  ],
                });
              } else {
                toast.warning("You have reached the limit");
              }
            }}
          >
            <Plus className="formify-color" />
            <p className="formify-p formify-color">Add new field</p>
          </div>
          <div className="d-flex flex-row ">
            <button
              disabled={emailField.length < 1}
              className="formify-btn me-2"
            >
              Save
            </button>
            <button
              className="invert-formify-btn"
              onClick={() => {
                setForm({ ...formData, show: false });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid integraiton mt-5">
      <div className="row justify-content-center">
        <div className="col-12  integraiton-card ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="integration-header">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />
                  <ArrowRight
                    className="me-2"
                    style={{ color: "#6563FF", width: "20px", height: "20px" }}
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/activecampaign.png"
                    }
                    alt="Mailchimp"
                    className="img-fluid"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                {formData.show ? acForm() : authenticateBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {integrationFooter(
          formHasAC,
          disconnectIntegration,
          "active_campaign",
          params
        )}
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setFormDispatcher,
  disconnectIntegration,
})(ActiveCampaign);
