/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getCountries } from "../../../../utils";
import InputField from "../single-components/InputField";
import SwitchChip from "../single-components/switchChip";
import { Accordion } from "react-bootstrap";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import AlignmentTabs from "../single-components/AlignmentTabs";
import FormifyInput from "../single-components/FormifyInput";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";
 
const AddressContent = ({ form: { widget, responsive }, setWidgetDispatcher }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
  }, []);

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabel = (e, type) => {
    handleSetWidgetDispatcher([`content.text`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const updateStreetLabel = (e, type) => {
    handleSetWidgetDispatcher([`content.street.placeholder`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const updateCityLabel = (e, type) => {
    handleSetWidgetDispatcher([`content.city.placeholder`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const updateZipLabel = (e, type) => {
    handleSetWidgetDispatcher([`content.zip.placeholder`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const handleLabelChange = (val, key, type) => {
    handleSetWidgetDispatcher([`content.${key}.label`], [val], widget, setWidgetDispatcher, false, type);
  };

  const changeVisibility = (val, key) => {
    handleSetWidgetDispatcher([`content.${key}.visible`], [val], widget, setWidgetDispatcher);
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const changeDefaultCountry = (e) => {
    handleSetWidgetDispatcher([`content.country.default`], [e.target.value], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (name, src) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.fontFamily`,
        `style.desktop.input.fontFamily`,
        `style.desktop.helpText.fontFamily`
      ], 
      [
        { name: name, src: src },
        { name: name, src: src },
        { name: name, src: src }
      ], widget, setWidgetDispatcher,true
    );
  };

  return (
    <div className="settings-field-container">
      <div className="row">
        <div className="editable-field quick-form-actions">
          <div className="quick-action-container font-family-picker">
            <FontFamilyPicker
              attribute={widget?.style?.label}
              onChangeFont={updateLabelFont}
              id={"label"}
            />
          </div>
          <div className="quick-action-container mt-1">
            <AlignmentTabs 
              id={"alignment"}
              tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
              handleSelect={handleChangeAlign}
            />
          </div>
          <InputField
            id={"text"}
            label={"Label"}
            onChange={updateLabel}
            placeholder={"Address"}
            type="text"
            value={widget.content.text || ""}
          />
        </div>
        <hr />
        <div className="editable-field quick-form-actions pt-1 font-family-picker">
          <div className="col-lg-12">
            <p className="field-heading widget-label">Fields</p>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="field-label">
                  Country
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-lg-12 mt-1">
                    <div className="input-holder mt-0">
                      <select
                        value={
                          widget.content.country
                            ? widget.content.country.default
                            : null
                        }
                        onChange={changeDefaultCountry}
                      >
                        {countries.map((country) => {
                          return (
                            <option value={country.name.common}>
                              {country.name.common}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <InputField
                    id={"placeholder"}
                    label={"Placeholder"}
                    value={widget.content.country.label || ""}
                    onChange={(e, type) => {
                      handleLabelChange(e.target.value, "country", type);
                    }}
                    placeholder={"Country Help Text"}
                    type="text"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="field-label mt-4">
                  Street
                </Accordion.Header>
                <Accordion.Body>
                  <InputField
                    id={"help_text"}
                    label={"Help Text"}
                    value={
                      widget.content.street ? widget.content.street.label : ""
                    }
                    onChange={(e, type) => {
                      handleLabelChange(e.target.value, "street", type);
                    }}
                    placeholder={"Street Help Text"}
                    type="text"
                    parentClass="mt-1"
                  />
                  <div className="inner-widget-spacer" />
                  <InputField
                    id={"placeholder"}
                    label={"Placeholder"}
                    onChange={updateStreetLabel}
                    placeholder={"Street"}
                    type="text"
                    parentClass="mt-1"
                    value={ widget.content.street ? widget.content.street.placeholder : ""}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="field-label mt-4">
                  City
                </Accordion.Header>
                <Accordion.Body>
                  <InputField
                    id={"help_text"}
                    label={"Help Text"}
                    onChange={(e, type) => {
                      handleLabelChange(e.target.value, "city", type);
                    }}
                    placeholder={"City Help Text"}
                    type="text"
                    value={widget.content.city ? widget.content.city.label : ""}
                    parentClass="mt-1"
                  />
                  <div className="inner-widget-spacer" />
                  <InputField
                    id={"placeholder"}
                    label={"Placeholder"}
                    onChange={updateCityLabel}
                    placeholder={"City"}
                    type="text"
                    value={ widget.content.city ? widget.content.city.placeholder : "" }
                    parentClass="mt-1"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="field-label mt-4">
                  ZIP
                </Accordion.Header>
                <Accordion.Body>
                  <InputField
                    id={"help_text"}
                    label={"Help Text"}
                    value={widget.content.zip.label || ""}
                    onChange={(e, type) => {
                      handleLabelChange(e.target.value, "zip", type);
                    }}
                    placeholder={"Zip Help Text"}
                    type="text"
                    parentClass="mt-1"
                  />
                  <div className="inner-widget-spacer" />
                  <InputField
                    id={"placeholder"}
                    label={"Placeholder"}
                    onChange={updateZipLabel}
                    placeholder={"Zip"}
                    type="text"
                    value={widget.content.zip.placeholder ? widget.content.zip.placeholder : ""}
                    parentClass="mt-1"
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <hr />
            <div className="col-lg-12 mt-3">
              <p className="field-heading widget-label">Visibility</p>
              <div className="editable-field form-row mt-2">
                <p className="field-label">Street</p>
                <SwitchChip
                  id="street"
                  checked={widget.content.street.visible || false}
                  onChange={(val) => {
                    changeVisibility(val, "street");
                  }}
                />
              </div>
              <div className="editable-field form-row mt-2">
                <p className="field-label">City</p>
                <SwitchChip
                  id="city"
                  checked={
                    widget.content.city ? widget.content.city.visible : false
                  }
                  onChange={(val) => {
                    changeVisibility(val, "city");
                  }}
                />
              </div>
              <div className="editable-field form-row mt-2">
                <p className="field-label">Zip</p>
                <SwitchChip
                  id="zip"
                  checked={widget.content.zip.visible || false}
                  onChange={(val) => {
                    changeVisibility(val, "zip");
                  }}
                />
              </div>
              <div className="editable-field form-row mt-2">
                <p className="field-label">Country</p>
                <SwitchChip
                  id="country"
                  checked={widget.content.country.visible || false}
                  onChange={(val) => {
                    changeVisibility(val, "country");
                  }}
                />
              </div>

              <div className="editable-field form-row mt-2">
                <p className="field-label">Required</p>
                <SwitchChip
                  id="required"
                  checked={widget.content.required || false}
                  onChange={(val) => {
                    changeCheckBtn(val, "required");
                  }}
                />
              </div>
              <div className="editable-field form-row mt-2">
                <p className="field-label">Hide Label</p>
                <SwitchChip
                  id="hide_label"
                  checked={widget.content.hide_label || false}
                  onChange={(val) => {
                    changeCheckBtn(val, "hide_label");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(AddressContent);
