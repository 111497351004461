import { useEffect, useState } from "react";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { AlertTriangle } from "react-feather";
const StyledDiv = styled.div`
  background-color: aquamarine;
  padding: 10px;
  border: 1px solid black;
  margin-top: 5px;
`;

const SecondStyledDiv = styled.div`
  background-color: #ffb446;
  padding: 10px;
  border: 1px solid black;
  margin-top: 5px;
`;



const Test = () => {
  const [items, setItems] = useState({
    first: [1, 2, 3, 4, 5, 6, 7],
    second: [8, 9, 10, 11, 12, 13, 14],
  });

  const reorder = (key, startIndex, endIndex) => {
    const result = Array.from(items[key]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setItems({
      ...items,
      [key]: result,
    });
  };

  const move = (startIndex, endIndex, startDroppable, endDroppable) => {
    const startList = Array.from(items[startDroppable]);
    const endList = Array.from(items[endDroppable]);

    const [removed] = startList.splice(startIndex, 1);
    endList.splice(endIndex, 0, removed);

    setItems({
      ...items,
      [startDroppable]: startList,
      [endDroppable]: endList,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (result.source.droppableId !== result.destination.droppableId) {
      move(
        result.source.index,
        result.destination.index,
        result.source.droppableId,
        result.destination.droppableId
      );
    } else {
      reorder(
        result.source.droppableId,
        result.source.index,
        result.destination.index
      );
    }
  };

  // useEffect(() => {
  //   // GET request using fetch inside useEffect React hook
  //   fetch('https://www.subbly.co/admin/surveys/allNew')
  //       .then(response => console.log(response.json()))
  //       // .then(data => setTotalReactPackages(data.total));
  
  // // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, []);

  return (
    <div className="container">
      <div className="row">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="col-lg-4 ">
            <Droppable droppableId="first">
              {(provided, snapshot) => (
                <div
                  className="container droppable"
                  ref={provided.innerRef}
                  style={{
                    backgroundColor: snapshot.isDraggingOver
                      ? "#6563FF"
                      : "#FFD2CA",
                    transition: ".3s ease",
                  }}
                  {...provided.droppableProps}
                >
                  {items.first.map((item, index) => {
                    return (
                      <Draggable
                        draggableId={item.toString()}
                        key={item}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <StyledDiv
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            Item #{item}
                          </StyledDiv>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-lg-4 ">
            <Droppable droppableId="second">
              {(provided, snapshot) => (
                <div
                  className="container droppable"
                  ref={provided.innerRef}
                  style={{
                    backgroundColor: snapshot.isDraggingOver
                      ? "#6563FF"
                      : "#FFD2CA",
                    transition: ".3s ease",
                  }}
                  {...provided.droppableProps}
                >
                  {items.second.map((item, index) => {
                    return (
                      <Draggable
                        draggableId={item.toString()}
                        key={item}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <SecondStyledDiv
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <AlertTriangle
                              style={{ height: "18px", width: "18px" }}
                            />
                            Item #{item}
                          </SecondStyledDiv>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-lg-3"></div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Test;
