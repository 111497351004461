/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const ParagraphStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [paragraphMarginLockValues, setParagraphMarginLockValues] = useState(false);
  const [paragraphPaddingLockValues, setParagraphPaddingLockValues] = useState(false);

  const updateParagraphFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.paragraph.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateParagraphSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.paragraph.${id}`,
        `style.tablet.paragraph.${id}`,
        `style.mobile.paragraph.${id}`,
      ], 
      [e, e, e], widget, setWidgetDispatcher
    );
  };

  const updateParagraphSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.paragraph.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
   
    if (e.target.id.substring(0, 6) === "margin") {
      if (paragraphMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.paragraph.marginBottom`,
            `style.${responsive}.paragraph.marginRight`,
            `style.${responsive}.paragraph.marginLeft`,
            `style.${responsive}.paragraph.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (paragraphPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.paragraph.paddingBottom`,
            `style.${responsive}.paragraph.paddingRight`,
            `style.${responsive}.paragraph.paddingLeft`,
            `style.${responsive}.paragraph.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <p className="field-paragraph widget-label">Paragraph</p>

        <TypographyPopup
          attribute={getResponsiveStyle(widget?.style, responsive, "paragraph", null)}
          onChangeFont={updateParagraphFont}
          updateSliderstyle={updateParagraphSliderstyle}
          updateSelectstyle={updateParagraphSelectstyle}
          id={"paragraph"}
        />
        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer
                label={"Margin"}
                id={"margin"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "paragraph", "marginLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "paragraph", "marginRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "paragraph", "marginTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "paragraph", "marginBottom")}
                onChange={updateParagraphSliderstyle}
                setLockValue={setParagraphMarginLockValues}
                lockValue={paragraphMarginLockValues}
              />
              <Spacer
                label={"Padding"}
                id={"padding"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "paragraph", "paddingLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "paragraph", "paddingRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "paragraph", "paddingTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "paragraph", "paddingBottom")}
                onChange={updateParagraphSliderstyle}
                setLockValue={setParagraphPaddingLockValues}
                lockValue={paragraphPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(ParagraphStyle);
