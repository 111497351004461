import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrors } from "../../../actions/errors";
import { usePrevious } from "../../../hooks/usePrevious";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { connectWoocommerce } from "../../../actions/integrations";

const WoocommerceIntegration = ({
  auth,
  errors,
  connectWoocommerce,
  clearErrors,
}) => {
  const nav = useNavigate();
  const [form, setForm] = useState({
    store_url: auth.user
      ? auth.user.stores.woocommerce
        ? auth.user.stores.woocommerce.store_url
        : ""
      : "",
    consumer_key: auth.user
      ? auth.user.stores.woocommerce
        ? auth.user.stores.woocommerce.consumer_key
        : ""
      : "",
    consumer_secret: auth.user
      ? auth.user.stores.woocommerce
        ? auth.user.stores.woocommerce.consumer_secret
        : ""
      : "",
  });

  const storeUrl = useRef(null);
  const consumerKey = useRef(null);
  const consumerSecret = useRef(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    connectWoocommerce(form);
    // auth.user.transactions[0].account_type ? window.location.href('localhost:3000/profile/billing/plans') : <></>
    
  };

  const prevAuth = usePrevious(auth.user);
  useEffect(() => {
    if (prevAuth) {
      if (auth.user.stores !== prevAuth.stores ) {
        nav("/profile/integrations/all");
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.stores]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Overlay
        target={storeUrl.current}
        show={
          errors.errors
            ? errors.errors["stores.woocommerce.store_url"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.woocommerce.store_url"]
                ? errors.errors["stores.woocommerce.store_url"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={consumerKey.current}
        show={
          errors.errors
            ? errors.errors["stores.woocommerce.consumer_key"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.woocommerce.consumer_key"]
                ? errors.errors["stores.woocommerce.consumer_key"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={consumerSecret.current}
        show={
          errors.errors
            ? errors.errors["stores.woocommerce.consumer_secret"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.woocommerce.consumer_secret"]
                ? errors.errors["stores.woocommerce.consumer_secret"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/wocommerce.png"}
              alt="Shopify"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <h4 className="integration-page-header">
          Connect your form to WooCommerce
        </h4>
        <p className="grey-paragraph">
          Start by selecting the form you want to connect to your woocommerce
          store.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-5">
          <div className="formify-card">
            <h4 className="integration-page-header">
              Authenticate WooCommerce Shop
            </h4>
            <p className="grey-paragraph">
              Enter the following information to authenticate your account.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="input-holder position-relative">
                      <label htmlFor="store_url">Store URL</label>
                      <input
                        type="text"
                        id="store_url"
                        name="store_url"
                        onChange={handleChange}
                        value={form.store_url || ""}
                        placeholder="http://example.com"
                        ref={storeUrl}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="consumer_key">Store API Key</label>
                      <input
                        type="text"
                        id="consumer_key"
                        name="consumer_key"
                        onChange={handleChange}
                        value={form.consumer_key || ""}
                        placeholder="7bda05ae28047fd0e2abc8c95e585175"
                        ref={consumerKey}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="consumer_secret">API Secret</label>
                      <input
                        type="password"
                        id="consumer_secret"
                        name="consumer_secret"
                        onChange={handleChange}
                        value={form.consumer_secret || ""}
                        placeholder="shpss_859acd6d742c5b0b276da003872b"
                        ref={consumerSecret}
                      />
                    </div>
                    <button type="submit" className="formify-btn mt-3">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  connectWoocommerce,
  clearErrors,
})(WoocommerceIntegration);
