import { CLEAR_ERRORS, GET_ERRORS } from "./types";

export const getErrors = (errors) => {
  return {
    type: GET_ERRORS,
    payload: errors,
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
