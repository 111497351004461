export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const GET_USER = "GET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GOOGLE_AUTH = "GOOGLE_AUTH";
export const GOOGLE_AUTH_ERROR = "GOOGLE_AUTH_ERROR";
export const USER_LOADING = "USER_LOADING";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_MESSAGES = "GET_MESSAGES";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_LOADER = "SET_LOADER";
export const REMOVE_REGISTER_SIGNAL = "REMOVE_REGISTER_SIGNAL";

export const SET_FORM = "SET_FORM";
export const FORM_UNDO = "FORM_UNDO";
export const FORM_REDO = "FORM_REDO";
export const SET_WIDGET = "SET_WIDGET";
export const SET_WIDGET_STEP_POSITION = "SET_WIDGET_STEP_POSITION";
export const SET_FORM_LOADING = "SET_FORM_LOADING";
export const SET_FORM_STATES = "SET_FORM_STATES";
export const RESET_FORM_STATE = "RESET_FORM_STATE";


export const SET_RESPONSES = "SET_RESPONSES";
export const SET_WIDGET_RESPONSES = "SET_WIDGET_RESPONSES";
export const SET_RESPONSES_ERROR_MESSAGE = "SET_RESPONSES_ERROR_MESSAGE";

export const SET_RESPONSIVE = "SET_RESPONSIVE";
export const SET_ACTIVE_FORM_STEP = "SET_ACTIVE_FORM_STEP";

export const SET_CLIENT_EMAIL = "SET_CLIENT_EMAIL";