import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51KlDLYLvFUx8hFgEwwRTWCj2pzZY9keIOsG69jwRb3XbFwCVVCA1KKvVt1nsRIHfxklA66ztjOqWjYqHETSWLiQK00E4o9BIu4"
);

const CreatePaymentMethod = () => {
  const nav = useNavigate();

  const location = useLocation();

  const [clientSecret] = useState(
    location.state ? location.state.clientSecret : null
  );

  if (!clientSecret) {
    nav("/profile/billing");
  }

  const options = {
    clientSecret,
  };

  return (
    <div className="formify-card  var-width  m-4">
      <div className="formify-header integration-header mb-4">
        <h4>Complete the payment</h4>
      </div>
      <div className="container-fluid integrations" style={{ padding: 0 }}>
        <div className="row gy-3 ">
          <div className="col-lg-12">
            <div className="container-fluid" style={{ padding: 0 }}>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm paymentMethod={true} />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePaymentMethod;
