/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { ChevronDown } from "react-feather";
import FormifyTable from "../dashboard/contacts/FormifyTable";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import ConfirmModal from "../utils/ConfirmModal";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

const ResponseList = () => {
  const params = useParams();

  const [responses, setResponses] = useState([]);

  const [form, setForm] = useState(null);

  const [cols, setColumns] = useState([]);

  const [deleteIntent, setDelete] = useState(null);

  const [show, setShow] = useState(false);

  const data = useMemo(() => {
    return responses;
  }, [responses]);

  const headers = useMemo(() => {
    let c = cols.map((col) => {
      return { label: col.Header, key: col.accessor };
    });
    return c;
  }, [cols]);

  const columns = useMemo(() => {
    return cols;
  }, [cols]);

  useEffect(() => {
    if (deleteIntent) {
      setShow(true);
    }
  }, [deleteIntent]);

  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/form/${params.id}`, tokenConfig(store.getState))
      .then((res) => {
        setForm(res.data.form);
      })
      .catch((err) => {
      });

    axios
      .get(
        `${BASE_DEV_URL}/form/${params.id}/responses`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setResponses(res.data.data);
        setColumns(res.data.columns);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <div className="container-fluid mt-4 contact-list pos-relative pl-20 pb-100">
      <ConfirmModal
        show={show}
        contact={deleteIntent}
        d
        setDel={setDelete}
        setShow={setShow}
        del={null}
      />
      <div className="row">
        <div className="col">
          <h1 className="formify-h1">
            Responses for form '{form ? form.name : null}'
          </h1>
          <div className="table-manipulation   mt-5">
            <div className="filter">
              <div className="dropdown">
                <button
                  className="formify-simple-btn btn-icon  dropdown-toggle formify-drp"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <p>Export to</p>
                  <ChevronDown
                    style={{
                      color: "#29447A",
                      marginLeft: "5px",
                      width: "16px",
                      height: "16px",
                      border: "2px",
                    }}
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <CSVLink
                      data={data}
                      headers={headers}
                      className="dropdown-item"
                      filename="formify-contacts"
                    >
                      CSV{" "}
                    </CSVLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <FormifyTable
            columns={columns}
            data={data}
            setDelete={setDelete}
            form={form}
            response={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponseList;
