export const getDynamicTagComponent = (text, responses) => {

  var matches = text.match(/\[(.*?)\]/g);

  if (matches) {
    matches.forEach((val) => {
      var v = responses[val.replace(/\[|\]/g, "")];
      if (v) {
        v = typeof v == "string" ? v : getValueJson(v);
        text = text.replace(val, v ? v : "[user answer here]");
      } else text = text.replace(val, "[user answer here]");
    });
    return text;
  } else return text;
};

const getValueJson = (v) => {
  const keyNames = Object.keys(v);
  if (keyNames.includes("email")) {
    return v.email;
  }
  else if (keyNames.includes("first_name") && keyNames.includes("last_name"))
    return v.first_name + " " + v.last_name;
  else return String(v.value);
};

export const insertLinksToAcceptanceText = (content, style) => {
  return content.acceptance_text
    .replace(
      "{{terms}}",
      `<a target="_blank" href="${content.acceptance_text_privacy_policy_link}" style="color: ${style?.linkColor}">${content.acceptance_text_privacy_policy}</a>`
    )
    .replace(
      "{{privacy-policy}}",
      `<a target="_blank" href="${content.acceptance_text_tos_link}" style="color: ${style?.linkColor}">${content.acceptance_text_tos}</a>`
    );
};
