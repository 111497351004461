import { useState } from "react";
import FormifyLoader from "../utils/FormifyLoader";
import MoneyFormat from "./MoneyFormat";
import "./PriceCard.css";
import BillingModal from "../dashboard/billing/BillingModal";
import { BASE_DEV_URL } from "../../utils";
import axios from "axios";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { useNavigate } from "react-router-dom";

const PriceCard = ({
  auth,
  plan,
  isCurrentSubscription,
  type,
  redirectPaymentMethod,
  onCheckout,
  selectNewPlan,
  updateSubscription,
}) => {

  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); 
  const [updateInfo, setUpdateInfo] = useState(null);

  const showUpdateInformation = async () => {
    setLoading(true);

    try {
      if(auth.user.register_type === "stripe"){
        const response = await axios.post(
          `${BASE_DEV_URL}/subscription/updateSubscriptionInformation`,
          { price: plan.id },
          tokenConfig(store.getState)
        );
        setLoading(false);
        setModalIsOpen(true);
        setUpdateInfo(response.data); 
      }else{
        const response = await axios.post(
          `${BASE_DEV_URL}/subscription/update`,
          { price: plan.id },
          tokenConfig(store.getState)
        );
        setLoading(false);
        window.location.href = response.data.url;
        // setModalIsOpen(true);
        // setUpdateInfo(response.data); 
      }
    
  
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

function format(num) {
  return (num / 100).toFixed(2);

}
function formatAuthPrice(num) {
  return (num / 10000).toFixed(2);

}

const authPrice =  formatAuthPrice(auth?.user?.transactions[0]?.price);

const newPlanUnitAmount = plan?.unit_amount / 100; 

let upgradeOrDowngrade = "";

if (newPlanUnitAmount > parseFloat(authPrice)) {
  upgradeOrDowngrade = "upgrade";
} else if (newPlanUnitAmount < parseFloat(authPrice)) {
  upgradeOrDowngrade = "downgrade";
} else {
  upgradeOrDowngrade = "no-change";
}

  return (
    <>
      <span className="ribbon">{plan.product.name}</span>
      <div className="card-wrapper">
        <div className="format-wrapper" onClick={showUpdateInformation}>
          <MoneyFormat
            value={
              plan?.recurring?.interval === "month"
                ? plan.unit_amount / 100
                : Number.isInteger(plan.unit_amount / 1200)
                ? plan.unit_amount / 1200
                : (plan.unit_amount / 1200).toFixed(2)
            }
            period="mo"
            cent=""
            desc={plan?.recurring?.interval === "month" ? "Per month, paid monthly (USD) (no transaction fees)" : "Per month, paid yearly (USD) (no transaction fees)"}
          />
          <div className="col-lg-12 plan-footer p-0 mt-2">
            <button
              className={
                isCurrentSubscription && auth?.user?.transactions?.length !== 0
                  ? `current-plan-btn`
                  : `plan-btn`
              }
              onClick={() => {
                if(auth?.user?.transactions?.length !== 0){
                  setLoading(true);
                  showUpdateInformation()

                }else{
                  redirectPaymentMethod(plan)

                }
              
              }}
              disabled={loading}
            >
              {loading ? (
              <>
              <i className="fa fa-circle-o-notch fa-spin"></i>
           </>
              ) : (
                <>
                  {isCurrentSubscription && auth?.user?.transactions?.length !== 0
                    ? "Current"
                    : auth.user.used_trial
                    ? `Get ${plan.product.name} ${plan?.recurring?.interval}ly`
                    // : "Start 14-Day Trial"}

                    : `Get ${plan.product.name} ${plan?.recurring?.interval}ly`}
                </>
              )}
            </button>
          </div>
          {plan.product.metadata.type !== "lite" && (
            <span className="most-popular-impactful">
              {plan.product.metadata.type === "pro"
                ? "Most Popular"
                : "Most Impactful"}
            </span>
          )}
        </div>
        <div>
          {plan?.info ? (
            <ol className="tick-list">
              {plan.info.features.map((feature, idx) => {
                return <li key={idx}>{feature}</li>;
              })}
            </ol>
          ) : null}
        </div>
      </div>
    

      <BillingModal
        show={modalIsOpen}
        setShow={setModalIsOpen}
        currentPlan={auth.user.subscription} 
        newPlan={plan}
        updateSub={updateSubscription}
        proRate={format(updateInfo?.invoice.total)}
        textContent={`If you change plans, ${
          plan.product.name
        } will start immediately. The change will generate a credit to your next invoice for the pro-rated amount paid on your previous plan. `}
        type={upgradeOrDowngrade} 
      />
    </>
  );
};

export default PriceCard;
