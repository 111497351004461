import { useEffect, useState } from "react";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import axios from "axios";
import store from "../../store";
import FormSelect from "../utils/FormsSelect";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const ContactGrowth = () => {
  const [data, setData] = useState([]);
  const [forms, setForms] = useState([]);
  const [other, setOther] = useState({ growth: "", added: "" });
  const [days, setDays] = useState(7);
  const [toggleDropdown, setToggleDropdown] = useState (false);
  const [latestForm, setLatestForm] = useState('');

  useEffect(() => {
    getLatest();
  }, []);

  useEffect(() => {
    if(latestForm.length > 0 ) getData(latestForm);
  }, [days]);

  useEffect(() => {
    if(forms.length > 0 )getData(forms);
  }, [forms]);

  const getData = (id) => {
    axios
      .get(
        `${BASE_DEV_URL}/form/${id}/growth/${days}`,
        tokenConfig(store.getState)
      )
      .then((response) => {
        var newData = [];
        Object.entries(response.data.dayData).map(([key, value]) => {
          var nameData = Object.keys(value);
          var valueData = Object.values(value)[0];
          newData.push({ name: nameData, uv: valueData });
        });
        setData(newData);
        setOther({ growth: response.data.growth, added: response.data.number });
      });
  };

    const getLatest = () => {
    axios
      .get(`${BASE_DEV_URL}/form/last`, tokenConfig(store.getState))
      .then((response) => {
        if(response.data.form){
          setLatestForm(response.data.form._id);
          getData(response.data.form._id);
        }
      });
  };

  const checkGrowth = (value) => {
    return value < 0 ? "negative-value" : "positive-value";
  };

  return (
    <div>
      <h1>Contact form growth</h1>
      <div className="one-stat">
        <form>
          <ul>
            <FormSelect setForms={setForms} forms = {forms}/>
            <li className= {`select-list ${toggleDropdown ? "open-select" : ""}`} onClick = {() => setToggleDropdown(!toggleDropdown)}>
              <p className="purp-array">
                Last {days} days
                <span className="bottom-arrow"></span>
              </p>
              <ul className="absolute-list">
                <li
                  onClick={() => {
                    setDays(7);
                  }}
                >Last 7 days
                {days === 7 ? <span className="checked_select"></span> : null}
                </li>
                <li
                  onClick={() => {
                    setDays(30);
                  }}
                >Last 30 days
                {days === 30 ? <span className="checked_select"></span> : null}
                </li>
              </ul>
            </li>
          </ul>
        </form>
        <div className="responsive-chart">
          <ResponsiveContainer>
            <AreaChart
              width={500}
              height={350}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: -35,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="40%" stopColor="#6563FF" stopOpacity={0.4} />
                  <stop offset="80%" stopColor="#FFFFFF" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#6563FF"
                strokeWidth={3}
                fill="url(#colorUv)"
                background="#000"
              />
              <CartesianGrid strokeWidth={0.5} vertical={false} />
              <XAxis dataKey="name" stroke="#B5B5B5" strokeWidth={0} />
              <YAxis strokeWidth={0} stroke="#B5B5B5" />
            </AreaChart>
          </ResponsiveContainer>
          {data.length > 0 ? null : <p className="no-data-chart">-</p>}
        </div>
        <h3>
          Contact form growth
          <span className={checkGrowth(other.growth)}>[{Math.round(other.growth * 100,2)}%]</span>
        </h3>
        <p>
          <span>+{other.added}</span> Contacts added
        </p>
      </div>
    </div>
  );
};
export default ContactGrowth;
