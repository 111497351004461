import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { mapAuthStateToProps } from "../../../utils";
import {
  disconnectHubspot,
  disconnectSalesforce,
  disconnectShopify,
  disconnectSubbly,
  disconnectWoocommerce,
  disconnectBigcommerce,
  disconnectMailchimp,
  disconnectActiveCampaign,
  disconnectKlaviyo,
  disconnectRecharge,
} from "../../../actions/integrations";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";
import { toast } from "react-toastify";
const IntegrationList = ({
  auth,
  disconnectSalesforce,
  disconnectSubbly,
  disconnectShopify,
  disconnectWoocommerce,
  disconnectHubspot,
  disconnectMailchimp,
  disconnectActiveCampaign,
  disconnectKlaviyo,
  disconnectRecharge,
  account_type,
}) => {
  const [filters, setFilters] = useState("all");
  const { id } = useParams();

  const getInitialConnectedStore = () => {
    if (auth.user?.stores?.subbly) return 'Subbly';
    if (auth.user?.stores?.shopify?.access_token) return 'Shopify';
    if (auth.user?.stores?.woocommerce) return 'WooCommerce';
    // Add other stores as needed
    return null;
  };

  const [connectedStore, setConnectedStore] = useState(getInitialConnectedStore());

  // const [connectedStore, setConnectedStore] = useState(null);

  
  
  useEffect(() => {}, []);

  const checkIntegrationPermissions = (element) => {
    
    var isDisabled =
      account_type?.crm &&
      account_type?.crm.some(function (el) {
        return el === element;
      });
    return isDisabled;
  };

  const [modal, setModal] = useState({
    show: false,
    function: null,
    type: "",
  });

  const [menu] = useState({
    links: [
      {
        id: 1,
        name: "View All",
        path: "/profile/integrations/all",
        click: "all",
      },
      {
        id: 2,
        name: "Shops",
        path: "/#",
        click: "shops",
      },
      {
        id: 3,
        name: "Marketing",
        path: "/#",
        click: "productivity",
      },
    ],
  });

  const loginSalesforce = () => {
    window.open(
      `https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9p1Q1BCe9GmCXuz6Q8m7YnWjDkEgD.P0.ZVPnJAAWycmBZZKW2UYAX5NlrGmiqvqG7AGi0tZf20ZJh9.2&redirect_uri=https://beta.getformify.com/profile/integrations/crm/salesforce&response_type=code`
    );
  };

  const loginHubspot = () => {
    window.open(
      `https://app-eu1.hubspot.com/oauth/authorize?client_id=46ce7218-a288-45ec-b787-2b6f25210288&redirect_uri=https://beta.getformify.com/profile/integrations/crm/hubspot&scope=crm.objects.contacts.read%20crm.objects.contacts.write`
    );
  };

  const mailChimpOauthLogin = () => {
    window.open(
      `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=717465923303&redirect_uri=https://beta.getformify.com/profile/integrations/crm/mailchimp`
    );
  };

  const DisconnectModal = (props) => {
    var text;
    if (props.name === "shopify") {
      if (auth.user?.transactions[0].payment_type === "shopify") {
        text =
          "If you disconnect this integration your subscription will be canceled starting from the next billing cycle.";
      } else
        text =
          "Once disconnected, you won't be able to use the features associated with this integration.";
    } else
      text =
        "Once disconnected, you won't be able to use the features associated with this integration.";

    return (
      <Modal show={props.show} size="lg" centered className="name-modal">
        <Modal.Header style={{ justifyContent: "center" }}>
          <div className="name-modal-header d-flex flex-column align-items-center text-center">
            <div className="danger-circle d-flex justify-content-center align-items-center mb-3">
              <X
                style={{
                  color: "white",
                  minHeight: "48px",
                  minWidth: "48px",
                }}
              />
            </div>
            <h4> Are you sure you want to disconnect this integration?</h4>
            <p>{text} </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            className="danger-btn"
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
            onClick={() => {
              props.disconnect();
              props.handler({
                show: false,
                function: null,
              });
              disconnectStore()
            }}
          >
            Disconnect
          </button>
          <button
            className="invert-formify-btn mt-3"
            onClick={() => {
              props.handler({
                show: false,
                function: null,
              });
            }}
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    setFilters(id);
  }, [id]);

  const connectStore = (storeName, connectFunction) => {
    if (connectedStore) {
      // toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
    } else {
      setConnectedStore(storeName);
      connectFunction();
    }
  };

  const disconnectStore = (storeName, disconnectFunction) => {
    setConnectedStore(null);
    disconnectFunction();
  };

  

  return (
    <div className="formify-card mt-4">
      <DisconnectModal
        show={modal.show}
        handler={setModal}
        disconnect={modal.function}
        name={modal.type}
      />
      <div className="formify-header integration-header mb-4">
        <h4>Integrations and connected apps</h4>
        <p>Supercharge your workflow with tools you use every day.</p>
      </div>
      <div className="container-fluid integrations" style={{ padding: 0 }}>
        <div className="row gy-3 ">
          <div className="col-lg-2 integration-menu">
            <div className="integration-item">
              {menu.links.map((link) => {
                return (
                  <p
                    key={link.id}
                    className={filters === link.click ? "selected" : ""}
                    onClick={() => setFilters(link.click)}
                  >
                    {link.name}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container-fluid" style={{ padding: 0 }}>
              <div className="row integrations-holder  g-3">
                {filters !== "shops" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("Hubspot")
                          ? "integration-card "
                          : " integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/hubspot.png`
                            }
                            alt="sales"
                          />
                          <p>Hubspot</p>
                        </div>
                        {auth.user ? (
                          auth.user.crm.hubspot ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.crm.hubspot
                                      ? "btn   connected-btn mt-2"
                                      : "btn   mt-2"
                                    : "btn   mt-2"
                                }
                              >
                                {auth.user
                                  ? auth.user.crm.hubspot
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                            </div>
                          ) : (
                            <div className="status mt-2">

{checkIntegrationPermissions("Hubspot") ? (
                  <Link
                  to="#/"
                  onClick={() => {
                    loginHubspot();
                  }}
                >
                  Connect
                </Link>
              ) : (
                <Link
                  to="/integrations/klaviyo"
                  className="formify-link"
                  onClick={(event) => event.preventDefault()}
                >
                  Connect
                </Link>
              )}
                           
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Hubspot account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.crm.hubspot ? (
                            <Link
                              to="#/"
                              className="formify-link-danger"
                              onClick={() => {
                                setModal({
                                  show: true,
                                  function: disconnectHubspot,
                                  type: "Hubsport",
                                });
                              }}
                            >
                              Disconnect
                            </Link>
                          ) : (

                            checkIntegrationPermissions("Hubspot") ? (
                              <Link
                              to="#/"
                              className="formify-link"
                              onClick={loginHubspot}
                            >
                              Integrate
                            </Link>
                            ) : (
                              <Link
                              to="#/"
                              className="formify-link"
                                onClick={(event) => event.preventDefault()}
                              >
                                Connect
                              </Link>
                            )

                          
                          )
                        ) : (

                          
                          checkIntegrationPermissions("Hubspot") ? (
                              <Link
                              to="#/"
                              className="formify-link"
                              onClick={loginHubspot}
                            >
                              Integrate
                            </Link>
                            ) : (
                              <Link
                              to="#/"
                              className="formify-link"
                                onClick={(event) => event.preventDefault()}
                              >
                                Connect
                              </Link>
                            )
                       
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "shops" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("Salesforce")
                          ? "integration-card "
                          : " integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/salesforce.png`
                            }
                            alt="sales"
                          />
                          <p>Salesforce</p>
                        </div>
                        {auth.user ? (
                          auth.user.crm.salesforce ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.crm.salesforce
                                      ? "btn   connected-btn mt-2"
                                      : "btn   mt-2"
                                    : "btn   mt-2"
                                }
                                type="button"
                              >
                                {auth.user
                                  ? auth.user.crm.salesforce
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              {checkIntegrationPermissions("Salesforce") ? (
                                <Link
                                  to="#/"
                                  className="formify-link"
                                  onClick={loginSalesforce}
                                >
                                  Connect
                                </Link>
                              ) : (
                                <Link
                                  to="#/"
                                  className="formify-link"
                                  onClick={(event) => event.preventDefault()}
                                >
                                  Connect
                                </Link>
                              )}
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Salesforce account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.crm.salesforce ? (
                            <Link
                              to="#/"
                              className="formify-link-danger"
                              onClick={() => {
                                setModal({
                                  show: true,
                                  function: disconnectSalesforce,
                                  type: "Salesforce",
                                });
                              }}
                            >
                              Disconnect
                            </Link>
                          ) : checkIntegrationPermissions("Salesforce") ? (
                            <Link
                              to="#/"
                              className="formify-link"
                              onClick={loginSalesforce}
                            >
                              Integrate
                            </Link>
                          ) : (
                            <Link
                              to="#/"
                              className="formify-link"
                              onClick={(event) => event.preventDefault()}
                            >
                              Connect
                            </Link>
                          )
                        ) : checkIntegrationPermissions("Salesforce") ? (
                          <Link
                            to="#/"
                            className="formify-link"
                            onClick={loginSalesforce}
                          >
                            Integrate
                          </Link>
                        ) : (
                          <Link
                            to="#/"
                            className="formify-link"
                            onClick={(event) => event.preventDefault()}
                          >
                            Connect
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "shops" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("ActiveCampaign")
                          ? "integration-card "
                          : " integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/activecampaign.png`
                            }
                            alt="sales"
                          />
                          <p>Active Campaign</p>
                        </div>
                        {auth.user ? (
                          auth.user.crm.active_campaign ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.crm.active_campaign
                                      ? "btn  dropdown-toggle connected-btn mt-2"
                                      : "btn  dropdown-toggle mt-2"
                                    : "btn  dropdown-toggle mt-2"
                                }
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {auth.user
                                  ? auth.user.crm.active_campaign
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                              <ul
                                className="integration-dropdown-menu dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <Link
                                    to="/integrations/activecampaign"
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#FC5B3F" }}
                                    href="#/"
                                    onClick={() => {
                                      setModal({
                                        show: true,
                                        function: disconnectActiveCampaign,
                                        type: "ActiveCampaign",
                                      });
                                    }}
                                  >
                                    Disconnect
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              {checkIntegrationPermissions("ActiveCampaign") ? (
                                <Link to="/integrations/activecampaign">
                                  Connect
                                </Link>
                              ) : (
                                <Link
                                  to="/integrations/activecampaign"
                                  className="formify-link"
                                  onClick={(event) => event.preventDefault()}
                                >
                                  Connect
                                </Link>
                              )}
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seemless experience between your Formify form
                          and Active Campaign account. One form, all your
                          products connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.crm.active_campaign ? (
                            checkIntegrationPermissions("ActiveCampaign") ? (
                              <Link
                                to="/integrations/activecampaign"
                                className="formify-link"
                              >
                                Integrate
                              </Link>
                            ) : (
                              <Link
                                to="/integrations/activecampaign"
                                className="formify-link"
                                onClick={(event) => event.preventDefault()}
                              >
                                Connect
                              </Link>
                            )
                          ) : checkIntegrationPermissions("ActiveCampaign") ? (
                            <Link
                              to="/integrations/activecampaign"
                              className="formify-link"
                            >
                              Integrate
                            </Link>
                          ) : (
                            <Link
                              to="/integrations/activecampaign"
                              className="formify-link"
                              onClick={(event) => event.preventDefault()}
                            >
                              Connect
                            </Link>
                          )
                        ) : checkIntegrationPermissions("ActiveCampaign") ? (
                          <Link
                            to="/integrations/activecampaign"
                            className="formify-link"
                          >
                            Integrate
                          </Link>
                        ) : (
                          <Link
                            to="/integrations/activecampaign"
                            className="formify-link"
                            onClick={(event) => event.preventDefault()}
                          >
                            Connect
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/wocommerce.png`
                            }
                            alt="sales"
                          />
                          <p>WooCommerce</p>
                        </div>
                        {auth.user ? (
                          auth.user.stores.woocommerce ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.stores.woocommerce
                                      ? "btn  dropdown-toggle connected-btn mt-2"
                                      : "btn  dropdown-toggle mt-2"
                                    : "btn  dropdown-toggle mt-2"
                                }
                                type="button"
                                id="woocommercedrpdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {auth.user
                                  ? auth.user.stores.woocommerce
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                              <ul
                                className="integration-dropdown-menu dropdown-menu"
                                aria-labelledby="woocommercedrpdown"
                              >
                                <li>
                                  <Link
                                    to="/integrations/woocommerce"
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#FC5B3F" }}
                                    href="#/"
                                    onClick={() => {
                                      setModal({
                                        show: true,
                                        function: disconnectWoocommerce,
                                        type: "woocommerce",
                                      });
                                    }}
                                  >
                                    Disconnect
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              <Link to="/integrations/woocommerce"
                              
                              onClick={(e) => {
                                if (connectedStore && connectedStore !== 'WooCommerce') {
                                  e.preventDefault();
                                  toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                                } else {
                                  setConnectedStore('WooCommerce');
                                }
                              }}>
                                Connect
                              </Link>
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and WooCommerce account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.stores.woocommerce ? (
                            <Link
                            to="/integrations/woocommerce"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'WooCommerce') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('WooCommerce');
                              }
                            }}
                          >
                            View Integration
                          </Link>
                          ) : (
                            <Link
                            to="/integrations/woocommerce"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'WooCommerce') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('WooCommerce');
                              }
                            }}
                          >
                            Integrate
                          </Link>
                          )
                        ) : (
                          <Link
                          to="/integrations/woocommerce"
                          onClick={(e) => {
                            if (connectedStore && connectedStore !== 'WooCommerce') {
                              e.preventDefault();
                              toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                            } else {
                              setConnectedStore('WooCommerce');
                            }
                          }}
                        >
                          Integrate
                        </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/shopify.png`
                            }
                            alt="sales"
                          />
                          <p>Shopify</p>
                        </div>
                        {auth.user ? (
                          auth.user.stores?.shopify?.access_token ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.stores?.shopify?.access_token
                                      ? "btn  dropdown-toggle connected-btn mt-2"
                                      : "btn  dropdown-toggle mt-2"
                                    : "btn  dropdown-toggle mt-2"
                                }
                                type="button"
                                id="shopifdrpdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {auth.user
                                  ? auth.user.stores?.shopify?.access_token
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                              <ul
                                className="integration-dropdown-menu dropdown-menu"
                                aria-labelledby="shopifdrpdown"
                              >
                                <li>
                                  <Link
                                    to="/integrations/shopify"
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#FC5B3F" }}
                                    href="#/"
                                    onClick={() => {
                                      setModal({
                                        show: true,
                                        function: disconnectShopify,
                                        type: "shopify",
                                      });
                                    }}
                                  >
                                    Disconnect
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="status mt-2">
                                     <Link
                to="/integrations/shopify"
                onClick={(e) => {
                  if (connectedStore && connectedStore !== 'Shopify') {
                    e.preventDefault();
                    toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                  } else {
                    setConnectedStore('Shopify');
                  }
                }}
              >
                Connect
              </Link>
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Shopify account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.stores?.shopify?.access_token ? (
                            <Link
                            to="/integrations/shopify"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'Shopify') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('Shopify');
                              }
                            }}
                          >
                            View Integration
                          </Link>
                          ) : (
                            <Link
                            to="/integrations/shopify"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'Shopify') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('Shopify');
                              }
                            }}
                          >
                           Integrate
                          </Link>
                          )
                        ) : (
                          <Link
                          to="/integrations/shopify"
                          onClick={(e) => {
                            if (connectedStore && connectedStore !== 'Shopify') {
                              e.preventDefault();
                              toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                            } else {
                              setConnectedStore('Shopify');
                            }
                          }}
                        >
                          Integrate
                        </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/subbly.png`
                            }
                            alt="sales"
                          />
                          <p>Subbly</p>
                        </div>
                        {auth.user ? (
                          auth.user.stores.subbly ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.stores.subbly
                                      ? "btn  dropdown-toggle connected-btn mt-2"
                                      : "btn  dropdown-toggle mt-2"
                                    : "btn  dropdown-toggle mt-2"
                                }
                                type="button"
                                id="shopifdrpdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {auth.user
                                  ? auth.user.stores.subbly
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                              <ul
                                className="integration-dropdown-menu dropdown-menu"
                                aria-labelledby="shopifdrpdown"
                              >
                                <li>
                                  <Link
                                    to="/integrations/subbly"
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#FC5B3F" }}
                                    href="#/"
                                    onClick={() => {
                                      setModal({
                                        show: true,
                                        function: disconnectSubbly,
                                        type: "Subbly",
                                      });
                                      connectStore('Subbly', loginHubspot)
                                    }}
                                  >
                                    Disconnect
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              <Link
                to="/integrations/subbly"
                onClick={(e) => {
                  if (connectedStore && connectedStore !== 'Subbly') {
                    e.preventDefault();
                    toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                  } else {
                    setConnectedStore('Subbly');
                  }
                }}
              >
                Connect
              </Link>
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Subbly account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.stores.subbly ? (
                            <Link
                            to="/integrations/subbly"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'Subbly') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('Subbly');
                              }
                            }}
                          >
                            View Integration
                          </Link>
                          ) : (
                            <Link
                            to="/integrations/subbly"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'Subbly') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('Subbly');
                              }
                            }}
                          >
                            Integrate
                          </Link>
                          )
                        ) : (
                          <Link
                            to="/integrations/subbly"
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'Subbly') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('Subbly');
                              }
                            }}
                          >
                           Integrate
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/bigcommerce.png`
                            }
                            alt="sales"
                          />
                          <p>Big Commerce</p>
                        </div>
                        {auth.user ? (
                          auth.user.stores.bigcommerce ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.stores.bigcommerce
                                      ? "btn  dropdown-toggle connected-btn mt-2"
                                      : "btn  dropdown-toggle mt-2"
                                    : "btn  dropdown-toggle mt-2"
                                }
                                type="button"
                                id="bigcommercedrpdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {auth.user
                                  ? auth.user.stores.bigcommerce
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                              <ul
                                className="integration-dropdown-menu dropdown-menu"
                                aria-labelledby="bigcommercedrpdown"
                              >
                                <li>
                                  <Link
                                    to="/integrations/bigcommerce"
                                    className="dropdown-item"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#FC5B3F" }}
                                    href="#/"
                                    onClick={() => {
                                      setModal({
                                        show: true,
                                        function: disconnectBigcommerce,
                                        type: "BigCommerce",
                                      });
                                    }}
                                  >
                                    Disconnect
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              <Link to="/integrations/bigcommerce"
                              
                              onClick={(e) => {
                                if (connectedStore && connectedStore !== 'BigCommerce') {
                                  e.preventDefault();
                                  toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                                } else {
                                  setConnectedStore('BigCommerce');
                                }
                              }}
                              >
                                Connect
                              </Link>
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Big Commerce account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.stores.bigcommerce ? (
                            <Link to="/integrations/bigcommerce"
                              
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'BigCommerce') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('BigCommerce');
                              }
                            }}
                            >
                              View Integration
                            </Link>
                          ) : (
                            <Link to="/integrations/bigcommerce"
                              
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'BigCommerce') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('BigCommerce');
                              }
                            }}
                            >
                              Integrate
                            </Link>
                          )
                        ) : (
                          <Link to="/integrations/bigcommerce"
                              
                            onClick={(e) => {
                              if (connectedStore && connectedStore !== 'BigCommerce') {
                                e.preventDefault();
                                toast.error(`You are already connected to ${connectedStore}. Please disconnect it first.`);
                              } else {
                                setConnectedStore('BigCommerce');
                              }
                            }}
                            >
                              Integrate
                            </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {filters !== "shops" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("Mail Chimp")
                          ? "integration-card "
                          : " integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/mailchimp.png`
                            }
                            alt="sales"
                          />
                          <p>Mailchimp</p>
                        </div>
                        {auth.user ? (
                          auth.user.crm.mailchimp ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.crm.mailchimp
                                      ? "btn   connected-btn mt-2"
                                      : "btn   mt-2"
                                    : "btn   mt-2"
                                }
                              >
                                {auth.user
                                  ? auth.user.crm.mailchimp
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              {checkIntegrationPermissions("Mail Chimp") ? (
                                <Link
                                  to="#/"
                                  onClick={() => {
                                    mailChimpOauthLogin();
                                  }}
                                >
                                  Connect
                                </Link>
                              ) : (
                                <Link
                                  to="#/"
                                  className="formify-link"
                                  onClick={(event) => event.preventDefault()}
                                >
                                  Connect
                                </Link>
                              )}
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Mailchimp account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.crm.mailchimp ? (
                            <Link
                              to="#/"
                              className="formify-link-danger"
                              onClick={() => {
                                setModal({
                                  show: true,
                                  function: disconnectMailchimp,
                                  type: "Mailchimp",
                                });
                              }}
                            >
                              Disconnect
                            </Link>
                          ) : checkIntegrationPermissions("Mail Chimp") ? (
                            <Link
                              to="#/"
                              className="formify-link"
                              onClick={mailChimpOauthLogin}
                            >
                              Integrate
                            </Link>
                          ) : (
                            <Link
                              to="#/"
                              className="formify-link"
                              onClick={(event) => event.preventDefault()}
                            >
                              Connect
                            </Link>
                          )
                        ) : checkIntegrationPermissions("Mail Chimp") ? (
                          <Link
                            to="#/"
                            className="formify-link"
                            onClick={mailChimpOauthLogin}
                          >
                            Integrate
                          </Link>
                        ) : (
                          <Link
                            to="#/"
                            className="formify-link"
                            onClick={(event) => event.preventDefault()}
                          >
                            Connect
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {filters !== "shops" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("Klaviyo")
                          ? "integration-card"
                          : "integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/klaviyo.png`
                            }
                            alt="sales"
                          />
                          <p>Klaviyo</p>
                        </div>
                        {auth.user ? (
                          auth.user.crm.klaviyo ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user.crm.klaviyo
                                    ? "btn connected-btn mt-2"
                                    : "btn mt-2"
                                }
                              >
                                {auth.user.crm.klaviyo
                                  ? "Connected"
                                  : "Connect"}
                              </button>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              {checkIntegrationPermissions("Klaviyo") ? (
                                <Link
                                  to="/integrations/klaviyo"
                                  className="formify-link"
                                >
                                  Connect
                                </Link>
                              ) : (
                                <Link
                                  to="/integrations/klaviyo"
                                  className="formify-link"
                                  onClick={(event) => event.preventDefault()}
                                >
                                  Connect
                                </Link>
                              )}
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Klaviyo account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.crm.klaviyo ? (
                            <Link
                              to="#/"
                              onClick={() => {
                                setModal({
                                  show: true,
                                  function: disconnectKlaviyo,
                                  type: "Klaviyo",
                                });
                              }}
                              className="formify-link-danger"
                            >
                              Disconnect
                            </Link>
                          ) : checkIntegrationPermissions("Klaviyo") ? (
                            <Link
                              to="/integrations/klaviyo"
                              className="formify-link"
                            >
                              Integrate
                            </Link>
                          ) : (
                            <Link
                              to="/integrations/klaviyo"
                              className="formify-link"
                              onClick={(event) => event.preventDefault()}
                            >
                              Connect
                            </Link>
                          )
                        ) : checkIntegrationPermissions("Klaviyo") ? (
                          <Link
                            to="/integrations/klaviyo"
                            className="formify-link"
                          >
                            Integrate
                          </Link>
                        ) : (
                          <Link
                            to="/integrations/klaviyo"
                            className="formify-link"
                            onClick={(event) => event.preventDefault()}
                          >
                            Connect
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/recharge.png`
                            }
                            alt="sales"
                          />
                          <p>Recharge</p>
                        </div>
                        {auth.user ? (
                          auth.user.stores.recharge &&
                          auth.user.stores.recharge.access_token ? (
                            <div className="dropdown integrations-dropdown">
                              <button
                                className={
                                  auth.user
                                    ? auth.user.stores.recharge &&
                                      auth.user.stores.recharge.access_token
                                      ? "btn   connected-btn mt-2"
                                      : "btn   mt-2"
                                    : "btn   mt-2"
                                }
                              >
                                {auth.user
                                  ? auth.user.stores.recharge &&
                                    auth.user.stores.recharge.access_token
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                              </button>
                            </div>
                          ) : (
                            <div className="status mt-2">
                              <Link to="/integrations/recharge">Connect</Link>
                            </div>
                          )
                        ) : (
                          <div className="status mt-2">
                            <p>Connect</p>
                          </div>
                        )}
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Reacharge account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {auth.user ? (
                          auth.user.stores.recharge &&
                          auth.user.stores.recharge.access_token ? (
                            <Link
                              to="#/"
                              onClick={() => {
                                setModal({
                                  show: true,
                                  function: disconnectRecharge,
                                  type: "Recharge"
                                });
                              }}
                              className="formify-link-danger"
                            >
                              Disconnect
                            </Link>
                          ) : (
                            <Link
                              to="/integrations/recharge"
                              className="formify-link"
                            >
                              Integrate
                            </Link>
                          )
                        ) : (
                          <Link
                            to="/integrations/recharge"
                            className="formify-link"
                          >
                            Integrate
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}

                {filters !== "productivity" && (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div
                      className={`integration-card ${
                        checkIntegrationPermissions("Zapier")
                          ? "integration-card "
                          : " integration-card disabled-widget"
                      }`}
                    >
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/zapier.png`
                            }
                            alt="sales"
                          />
                          <p>Zapier</p>
                        </div>
                        <div className="status mt-2">
                          {checkIntegrationPermissions("Zapier") ? (
                            <Link
                              to="/integrations/zapier"
                              className="formify-link"
                            >
                              Open
                            </Link>
                          ) : (
                            <Link
                              to="/integrations/zapier"
                              className="formify-link"
                              onClick={(event) => event.preventDefault()}
                            >
                              Open
                            </Link>
                          )}
                          {/* <Link to="/integrations/zapier">Open</Link> */}
                        </div>
                      </div>
                      <div className="integration-body ">
                        <p>
                          Create a seamless experience between your Formify form
                          and Zapier account. One form, all your products
                          connected in one place.
                        </p>
                      </div>
                      <div className="integration-footer text-center mt-1 mb-1 ">
                        {checkIntegrationPermissions("Zapier") ? (
                          <Link
                            to="/integrations/zapier"
                            className="formify-link"
                          >
                            Open
                          </Link>
                        ) : (
                          <Link
                            to="/integrations/zapier"
                            className="formify-link"
                            onClick={(event) => event.preventDefault()}
                          >
                            Open
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthStateToProps, {
  disconnectSalesforce,
  disconnectShopify,
  disconnectSubbly,
  disconnectMailchimp,
  disconnectWoocommerce,
  disconnectHubspot,
  disconnectActiveCampaign,
  disconnectKlaviyo,
  disconnectRecharge,
})(IntegrationList);
