/* Expl. of checkResponsiveStyle and getResponsiveStyle params:
Example4Type: input, heading, helpText etc
Example4Property: leftMargin, rightMargin, border etc 

One Property or Default  = style.desktop.marginLeft
Two Property = style.desktop.buttons.yesButtons.marginLeft

*/

export const checkResponsiveStyle = (style, currentScreen, type) => {


  if(currentScreen !== "desktop"){
    let desktopStyle = type ? style?.desktop?.[type] : style?.desktop;
    let currentScreenStyle = type ? style?.[currentScreen]?.[type] : style?.[currentScreen];

    if(currentScreenStyle){
      let style = structuredClone(currentScreenStyle);
      if(desktopStyle){
        const missingKeys = Object.keys(desktopStyle).filter(key => !Object.keys(style).includes(key));
        if(missingKeys.length > 0){

          missingKeys.forEach(key => {
            style[key] = desktopStyle[key];
          });
        }
      }
      return style;
    }else return type ?  style?.desktop?.[type] : style?.desktop;
  }else return type ?  style?.desktop?.[type] : style?.desktop;
}

export const checkResponsiveProperties = (style, currentScreen, type) => {
  let properties = type.split(/[.,]/);


  if(currentScreen !== "desktop"){
    let desktopStyle = type ? style?.desktop?.[properties[0]]?.[properties[1]] : style?.desktop;
    let currentScreenStyle = type ? style?.[currentScreen]?.[properties[0]]?.[properties[1]] : style?.[currentScreen];

    if(currentScreenStyle){
      let style = structuredClone(currentScreenStyle);
      if(desktopStyle){
        const missingKeys = Object.keys(desktopStyle).filter(key => !Object.keys(style).includes(key));
        if(missingKeys.length > 0){
          missingKeys.forEach(key => {
            style[key] = desktopStyle[key];
          });
        }
      }
      return style;
    }else return type ?  style?.desktop?.[properties[0]]?.[properties[1]] : style?.desktop;
  }else return type ?  style?.desktop?.[properties[0]]?.[properties[1]] : style?.desktop;

}

export const getResponsiveStyle = (style, currentScreen, type, property, hasTwoProperty = false) => {

  if(!hasTwoProperty){
    if(currentScreen !== "desktop"){
      let haveType = type ? style?.[currentScreen]?.[type] : style?.[currentScreen];
      let currentScreenStyle = property ? style?.[currentScreen]?.[type]?.[property] : haveType;
  
      if(currentScreenStyle) return  currentScreenStyle;
    }
    let haveType = type ? style?.desktop?.[type] : style?.desktop;
    return property ? style?.desktop?.[type]?.[property] : haveType;
  }else return getTwoProperty(style, currentScreen, type, property);
}
 
const getTwoProperty =  (style, currentScreen, type, property) => {
  let properties = property.split(/[.,]/);

  if(currentScreen !== "desktop"){
    let currentScreenStyle = style?.[currentScreen]?.[type]?.[properties[0]]?.[properties[1]];
    if(currentScreenStyle) return  currentScreenStyle;
  }
  return style?.desktop?.[type]?.[properties[0]]?.[properties[1]];
}

export const checkFontSize = (style, currentScreen, type, property) => {
  var fontSize = getResponsiveStyle(style, currentScreen, type,  property);
  if(fontSize){
    return fontSize.substring(0,fontSize.length - 2);
  }else return 12;
}
