import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker,  LocalizationProvider } from '@mui/x-date-pickers'
import { Alert, TextField } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";

import { useEffect, useState } from "react";
import { checkFontSize, getResponsiveStyle } from "../responsiveFunctions";

export const DateRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  const css = element?.style  ? `   
  .widget-${element._id} input::placeholder {
    opacity:0.6;
  }
  .widget-${element._id} input::placeholder,.widget-${ element._id } label, .widget-${element._id} .MuiInputBase-input  {
    ${ getResponsiveStyle(element?.style, screen, "input", "color") && ` color:  ${getResponsiveStyle(element?.style, screen, "input", "color")}`};
    ${ checkFontSize(element?.style, screen, "input", "fontSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "fontSize")}px`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontDecoration") && `text-decoration:  ${getResponsiveStyle(element?.style, screen, "input", "fontDecoration")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontWeight") && `text-weight:  ${getResponsiveStyle(element?.style, screen, "input", "fontWeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "lineHeight") && `line-height:  ${getResponsiveStyle(element?.style, screen, "input", "lineHeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "wordSpacing") && `word-spacing:  ${getResponsiveStyle(element?.style, screen, "input", "wordSpacing")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "letterSpacing") && `border-right-width:  ${getResponsiveStyle(element?.style, screen, "input", "letterSpacing")}`};
    ${ element?.style?.desktop?.input?.fontFamily  ? `font-family:  ${element?.style?.desktop?.input?.fontFamily?.name}`  : ""};
    ${"}"}
  .datepicker-${element._id} .MuiPaper-root {
    ${ getResponsiveStyle(element?.style, screen, "modal", "marginLeft") &&  `margin-left: ${checkFontSize(element?.style, screen, "modal", "marginLeft")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "marginTop") &&  `margin-top: ${checkFontSize(element?.style, screen, "modal", "marginTop")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "marginBottom") &&  `margin-bottom: ${checkFontSize(element?.style, screen, "modal", "marginBottom")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "marginRight") &&  `margin-right: ${checkFontSize(element?.style, screen, "modal", "marginRight")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "paddingTop") &&  `padding-top: ${checkFontSize(element?.style, screen, "modal", "paddingTop")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "paddingLeft") &&  `padding-left: ${checkFontSize(element?.style, screen, "modal", "paddingLeft")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "paddingBottom") &&  `padding-bottom: ${checkFontSize(element?.style, screen, "modal", "paddingBottom")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "paddingRight") &&  `padding-right: ${checkFontSize(element?.style, screen, "modal", "paddingRight")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderColor") &&  `border-color: ${checkFontSize(element?.style, screen, "modal", "borderColor")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderTopLeftRadius") &&  `border-top-left-radius: ${checkFontSize(element?.style, screen, "modal", "borderTopLeftRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderTopRightRadius") &&  `border-top-right-radius: ${checkFontSize(element?.style, screen, "modal", "borderTopRightRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderBottomLeftRadius") &&  `border-bottom-left-radius: ${checkFontSize(element?.style, screen, "modal", "borderBottomLeftRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderBottomRightRadius") &&  `border-bottom-right-radius: ${checkFontSize(element?.style, screen, "modal", "borderBottomRightRadius")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderRightWidth") &&  `border-right-width: ${checkFontSize(element?.style, screen, "input", "borderRightWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderTopWidth") &&  `border-top-width: ${checkFontSize(element?.style, screen, "modal", "borderTopWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderBottomWidth") &&  `border-bottom-width: ${checkFontSize(element?.style, screen, "modal", "borderBottomWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderLeftWidth") &&  `border-left-width: ${checkFontSize(element?.style, screen, "modal", "borderLeftWidth")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "borderStyle") &&  `border-style: ${checkFontSize(element?.style, screen, "modal", "borderStyle")}`};
    ${ getResponsiveStyle(element?.style, screen, "modal", "backgroundColor") &&  `background-color: ${checkFontSize(element?.style, screen, "modal", "backgroundColor")}`};

  } 
  .widget-${element._id} .css-owpyss-MuiFormControl-root-MuiTextField-root{

    ${ getResponsiveStyle(element?.style, screen, "input", "marginLeft") && `margin-left:  ${getResponsiveStyle(element?.style, screen, "input", "marginLeft")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "marginTop") && `margin-top:  ${getResponsiveStyle(element?.style, screen, "input", "marginTop")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "marginBottom") && `margin-bottom:  ${getResponsiveStyle(element?.style, screen, "input", "marginBottom")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "marginRight") && `margin-right:  ${getResponsiveStyle(element?.style, screen, "input", "marginRight")}`};
  }

  .widget-${element._id} .MuiInputLabel-root {

    ${ getResponsiveStyle(element?.style, screen, "input", "paddingLeft") && `padding-left:  ${getResponsiveStyle(element?.style, screen, "input", "paddingLeft")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingTop") && `padding-top:  ${getResponsiveStyle(element?.style, screen, "input", "paddingTop")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingBottom") && `padding-bottom:  ${getResponsiveStyle(element?.style, screen, "input", "paddingBottom")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingRight") && `padding-right:  ${getResponsiveStyle(element?.style, screen, "input", "paddingRight")}`};
  
  }

  .widget-${element._id} .MuiOutlinedInput-root {
    ${ getResponsiveStyle(element?.style, screen, "input", "backgroundColor") && `background-color:  ${getResponsiveStyle(element?.style, screen, "input", "backgroundColor")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingLeft") && `padding-left:  ${getResponsiveStyle(element?.style, screen, "input", "paddingLeft")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingTop") && `padding-top:  ${getResponsiveStyle(element?.style, screen, "input", "paddingTop")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingBottom") && `padding-bottom:  ${getResponsiveStyle(element?.style, screen, "input", "paddingBottom")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "paddingRight") && `padding-right:  ${getResponsiveStyle(element?.style, screen, "input", "paddingRight")}`};
  }

  .datepicker-${element._id} .MuiPickersDay-root, .datepicker-${  element._id } .MuiYearPicker-root button {
    ${ getResponsiveStyle(element?.style, screen, "modal", "defaultNumberBackgroundColor") && `background-color:  ${getResponsiveStyle(element?.style, screen, "modal", "defaultNumberBackgroundColor")}`}; 
    ${ getResponsiveStyle(element?.style, screen, "modal", "defaultColor") && `color:  ${getResponsiveStyle(element?.style, screen, "modal", "defaultColor")}`}; 
  }
    .widget-${element._id} .MuiFormControl-root svg {
    ${ checkFontSize(element?.style, screen, "input", "iconSize") && `font-size:  ${getResponsiveStyle(element?.style, screen, "input", "iconSize")}px`}; 
    ${ getResponsiveStyle(element?.style, screen, "modal", "iconColor") && `color:  ${getResponsiveStyle(element?.style, screen, "modal", "iconColor")}`}; 
  } 
  
  .datepicker-${element._id} .MuiCalendarPicker-root, .datepicker-${ element._id } .MuiTypography-root{
    ${ getResponsiveStyle(element?.style, screen, "modal", "color") && `color:  ${getResponsiveStyle(element?.style, screen, "modal", "color")}`}; 
  }
  .datepicker-${element._id} .MuiPickersDay-root.Mui-selected, .datepicker-${ element._id } .MuiYearPicker-root  .Mui-selected{
    ${ getResponsiveStyle(element?.style, screen, "modal", "activeNumberBackgroundColor") && `background-color:  ${getResponsiveStyle(element?.style, screen, "modal", "activeNumberBackgroundColor")}`}; 
    ${ getResponsiveStyle(element?.style, screen, "modal", "activeColor") && `color:  ${getResponsiveStyle(element?.style, screen, "modal", "activeColor")}`}; 
    }
  .datepicker-${element._id} .MuiPickersDay-root:hover, .datepicker-${ element._id } .MuiYearPicker-root button:hover {
    
    ${ getResponsiveStyle(element?.style, screen, "modal", "hoverColor") && `color:  ${getResponsiveStyle(element?.style, screen, "modal", "hoverColor")}`}; 

    }
  .widget-${element._id} .MuiFormControl-root svg *, .datepicker-${element._id } .MuiPaper-root svg * { 
    ${ getResponsiveStyle(element?.style, screen, "input", "iconColor") && `fill:  ${getResponsiveStyle(element?.style, screen, "input", "iconColor")}`}; 
  }
  .widget-${element._id} .MuiFormControl-root fieldset{ 
    ${ getResponsiveStyle(element?.style, screen, "input", "borderColor") && `border-color:  ${getResponsiveStyle(element?.style, screen, "input", "borderColor")}`}; 
    ${ getResponsiveStyle(element?.style, screen, "input", "borderColor") && `border-color:  ${getResponsiveStyle(element?.style, screen, "input", "borderColor")}`}; 
    ${ checkFontSize(element?.style, screen, "input", "borderTopLeftRadius") &&  `border-top-left-radius: ${checkFontSize(element?.style, screen, "input", "borderTopLeftRadius")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderTopRightRadius") &&  `border-top-right-radius: ${checkFontSize(element?.style, screen, "input", "borderTopRightRadius")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderBottomLeftRadius") &&  `border-bottom-left-radius: ${checkFontSize(element?.style, screen, "input", "borderBottomLeftRadius")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderBottomRightRadius") &&  `border-bottom-right-radius: ${checkFontSize(element?.style, screen, "input", "borderBottomRightRadius")}`};
 
    ${ checkFontSize(element?.style, screen, "input", "borderTopWidth") &&  `border-top-width: ${checkFontSize(element?.style, screen, "input", "borderTopWidth")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderRightWidth") &&  `border-right-width: ${checkFontSize(element?.style, screen, "input", "borderRightWidth")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderBottomWidth") &&  `border-bottom-width: ${checkFontSize(element?.style, screen, "input", "borderBottomWidth")}`};
    ${ checkFontSize(element?.style, screen, "input", "borderLeftWidth") &&  `border-left-width: ${checkFontSize(element?.style, screen, "input", "borderLeftWidth")}`};
    ${ checkFontSize(element?.style, screen, "modal", "borderStyle") &&  `border-style: ${checkFontSize(element?.style, screen, "modal", "borderStyle")}`};
  }
  .widget-${element._id} .MuiFormControl-root svg {
    ${ checkFontSize(element?.style, screen, "input", "iconSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "iconSize")}px`};
   } `: ``;

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div
              className={"widget-inner-container flex"}
              style={{
                display: "flex",
                justifyContent: getResponsiveStyle(element?.style, screen, "alignment",null),
              }}
            >
              <div
                className="container-fluid p-0"
                style={{
                  margin: "0px",
                  width: getResponsiveStyle(element?.style, screen, "input","width"),
                }}
              >
                <style>{css}</style>
                {!isEditable ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                      label={element.content.date_label}
                      inputFormat={element.content.format}
                      mask={element.content.mask}
                      showTodayButton={true}
                      PopperProps={{
                        className: "datepicker-" + element._id,
                      }}
                      DialogProps={{
                        className: "datepicker-" + element._id,
                      }}
                      value={responses[element._id]?.value}
                      onChange={(val) => {
                        setWidgetResponsesDispatcher(element._id, {
                          value:val,
                        },"form_date")
                      }}
                      renderInput={(params) => <TextField sx={{input: {textAlign: getResponsiveStyle(element?.style, screen, "alignment",null),}}} {...params} />}
                    />
                  </LocalizationProvider>
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={element.content.date_label}
                      inputFormat={element.content.format}
                      mask={element.content.mask}
                      showTodayButton={true}
                      value={element.content.default}
                      PopperProps={{
                        className: "datepicker-" + element._id,
                      }}
                      DialogProps={{
                        className: "datepicker-" + element._id,
                      }}
                      onChange={() => {
                        return;
                      }}
                      renderInput={(params) => <TextField sx={{input: {textAlign: getResponsiveStyle(element?.style, screen, "alignment",null),}}} {...params} />}
                    /> 
                  </LocalizationProvider>
                )}

                {!isEditable && responseError ? (
                  Object.keys(responseError).length > 0 ? (
                    <p className="error-message" style={{fontFamily: element?.style?.desktop?.input?.fontFamily?.name }}>{responseError[element._id]}</p>
                  ) : null
                ) : null}
              </div>
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(DateRender);
