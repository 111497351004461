import React, { useState } from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../../actions/errors";
import {
  connectShopify,
  disconnectSubbly,
} from "../../../actions/integrations";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { Link } from "react-router-dom";
import { X } from "react-feather";
import { Modal } from "react-bootstrap";
import SubblyList from "./components/SubblyList";
const SubblyDashboardIntegration = ({ auth, disconnectSubbly }) => {
  const [modal, setModal] = useState({
    show: false,
    function: null,
  });

  const DisconnectModal = (props) => {
    return (
      <Modal show={props.show} size="lg" centered className="name-modal">
        <Modal.Header style={{ justifyContent: "center" }}>
          <div className="name-modal-header d-flex flex-column align-items-center text-center">
            <div className="danger-circle d-flex justify-content-center align-items-center mb-3">
              <X
                style={{
                  color: "white",
                  minHeight: "48px",
                  minWidth: "48px",
                }}
              />
            </div>
            <h4> Are you sure you want to disconnect this integration?</h4>
            <p>
              Once disconnected, you won't be able to use the features
              associated with this integration.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            className="danger-btn"
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
            onClick={() => {
              props.disconnect();
              props.handler({
                show: false,
              });
            }}
          >
            Disconnect
          </button>
          <button
            className="invert-formify-btn mt-3"
            onClick={() => {
              props.handler({
                show: false,
              });
            }}
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  const connectedSubblyHTML = () => {
    return (
      <div className="row">
        <DisconnectModal
          show={modal.show}
          handler={setModal}
          disconnect={modal.function}
        />
        <div className="col-lg-10 mt-5">
         <div className="subbly-infos-flex">


          <div className="formify-card  subbly main-auth-card">
            <div className="subbly-header">
              <div className="row mt-2 col-lg-10">
                <h4 className="integration-page-header">
                  Authenticate Subbly Account
                </h4>
                <p className="grey-paragraph">
                  Enter the following information to authenticate your account.
                </p>
              </div>

              <div className="row mt-2 col-lg-2">
                <Link to="/integrations/subbly/store">Edit</Link>
              </div>
            </div>

            <div className="subblyInfo">
              <h4>Shop URL</h4>
              <p className="grey-paragraph">
                {auth.user.stores.subbly.store_url}
              </p>
            </div>

            <div className="subblyInfo">
              <h4>Checkout URL</h4>
              <p className="grey-paragraph">
                {auth.user.stores.subbly.checkout_url}
              </p>
            </div>

            <div className="subblyInfo">
              <h4>API Key</h4>
              <p className="grey-paragraph">
                {auth.user.stores.subbly.api_key}
              </p>
            </div>

            <div className="subblyInfo">
              <h4>Storefront API Key</h4>
              <p className="grey-paragraph">
                {auth.user.stores.subbly.storefront_api_key}
              </p>
            </div>

            <div></div>

            <div className="divider"></div>

            {/* <SubblyList/> */}
          </div>

          <div className="formify-card  subbly tips-card">
            <div className="subbly-header">
              <div className="row mt-2 col-lg-10">
                <p className="tips-subheading">Integration tips</p>
                <h4 className="integration-page-header mb-4">
                  How to successfully integrate your Subbly account
                </h4>
              </div>
            </div>
            <div>
              <div className="">
                <h5>Shop URL</h5>
                <p className="tips-paragraph">E.G. - https://busterbox.com</p>
                <p className="tips-paragraph">{`Found in settings > general`}</p>

                <p className="tips-paragraph">
                  or at <a href="https://www.subbly.co/admin/settings/general" target="_blank" rel="noreferrer"> https://www.subbly.co/admin/settings/general</a>
                </p>
              </div>

              <div className="">
                <h5> Checkout URL</h5>
                <p className="tips-paragraph">
                  {`Found in Settings > checkout domain`}
                </p>
                <p className="tips-paragraph">
                  E.G. - https://checkout.busterbox.com
                </p>

                <p className="tips-paragraph">
                  If you don't see this in your Subbly settings, you may not
                  have it activated. In that case use
                  <a href="https://www.subbly.co" target="_blank" rel="noreferrer"> https://www.subbly.co</a>
                </p>
              </div>

              <div className="">
                <h5>API Key</h5>
                <p className="tips-paragraph">{`Found in settings > general`}</p>
                <p className="tips-paragraph">
                  or at
                  <a href="https://www.subbly.co/admin/settings/general" target="_blank" rel="noreferrer"> https://www.subbly.co/admin/settings/general</a>
                  
                </p>
              </div>
              <div className="">
                <h5>Storefront API Key</h5>
                <p className="tips-paragraph">{`Found in: Settings > Storefront API Keys `}</p>
                <p className="tips-paragraph">
                or at 
                  <a href="https://www.subbly.co/admin/settings/api-keys" target="_blank" rel="noreferrer">  https://www.subbly.co/admin/settings/api-keys</a>
                  
                </p>
              </div>
            </div>

            <div className="divider"></div>

            {/* <SubblyList/> */}
          </div>
          </div>

          <div>
            <div className="subbly-header subbly-integration-footer">
              <div className="row mt-2 col-lg-10">
                <h4 className="integration-page-header">Remove Integration</h4>
                <p className="grey-paragraph">
                  Once removed, all previous changes will be deleted!
                </p>
              </div>

              <div className="row mt-2 col-lg-2">
                <a
                  href="#/"
                  onClick={() => {
                    setModal({
                      show: true,
                      function: disconnectSubbly,
                    });
                  }}
                >
                  Disconnect Subbly
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notConnectedSubblyHTML = () => {
    return (
      <div className="row">
        <div className="col-lg-12 mt-5">
          <div className="formify-card fixed-width subbly">
            <div className="card-identifier-header">
              <h4 className="integration-page-header">Subbly Authentication</h4>
            </div>
            <p className="grey-paragraph">
              Give Subbly access to your form in order to import products to
              Formify.
            </p>
            <div>
              <Link to="/integrations/subbly/store" className="upgradePlan">
                Authenticate
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/subbly.png"}
              alt="Shopify"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>

      <div className="subbly-header">
        <div className="row mt-2 col-lg-8 ">
          <h4 className="integration-page-header">
            Connect your form to Subbly
          </h4>
          <div className="row mt-2">
            <p className="grey-paragraph">
              Start by selecting the form you want to connect to your Subbly
              store.
            </p>
          </div>
        </div>

        <div className="row mt-2 col-lg-2">
          <p className="grey-paragraph">Status</p>

          <div className="authenticated">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icons/tick.svg"}
              alt=""
              className="dashboard-logo"
            />
            <p>Authenticated</p>
          </div>
        </div>
      </div>

      <div>
        {auth.user
          ? auth.user.stores.subbly
            ? connectedSubblyHTML()
            : notConnectedSubblyHTML()
          : notConnectedSubblyHTML()}
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  connectShopify,
  clearErrors,
  disconnectSubbly,
})(SubblyDashboardIntegration);
