import { ChevronLeft } from "react-feather";
import { Drawer } from "@mui/material";
import { Tab, Tabs } from "react-bootstrap";
import React  from "react";
import ChangeResponsiveness from "./settings-components/single-components/changeResponsiveness";
import { setFormStatesDispatcher } from "../../actions/form";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";

const HistorySidebar = ({
  form: { formStates },
  setFormStatesDispatcher
}) => {
  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <div className="sidebar-wraper">
          <div className="form-information ">
            <h4 className="form-name">Arti's form</h4>
            <p className="form-url">artshop.getformify.com/Y812356128</p>
          </div>

          <Tabs defaultActiveKey="content" id="content-button" className="mb-3">
            <Tab eventKey="content" title="Content">
              Content
            </Tab>
            <Tab eventKey="style" title="Style">
              Style
            </Tab>
            <Tab eventKey="options" title="Options">
              Options
            </Tab>
          </Tabs>
          <div>HISTORY</div>
        </div>
      </Drawer>

      <ChangeResponsiveness />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormStatesDispatcher })(HistorySidebar);
