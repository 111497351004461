import axios from "axios";
import { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BASE_DEV_URL } from "../../utils";

const ResendEmailConfirmation = () => {
  const [form, setForm] = useState({
    email: "",
    error: "",
    isValid: true,
  });
  const emailRef = useRef(null);
  const nav = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.email) {
      axios
        .post(`${BASE_DEV_URL}/auth/resend/verification/`, {
          email: form.email,
        })
        .then((res) => {
          nav("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setForm({ ...form, isValid: false, error: "Email can't be empty" });
    }
  };

  return (
    <div className="container login-wraper mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper px-4 py-5 pt-4">
          <div className="login-header forgot-password-header  ms-3 d-flex flex-column align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
              alt="formify-logo"
              className="img-fluid"
            />
            <h4>Resend Email Verification</h4>
            <p className="text-center">
              We get it, it happens! Enter the email address associated with
              your account.
            </p>
          </div>
          <Overlay
            target={emailRef.current}
            show={!form.isValid}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {form.error}
              </Tooltip>
            )}
          </Overlay>

          <form className="login-form mt-2" onSubmit={handleSubmit}>
            <div className="input-holder position-relative">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={form.email}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
                ref={emailRef}
                name="email"
                placeholder="email@business.com"
              />
            </div>

            <div className="submit-btn ">
              <button>Email Recovery Link</button>
            </div>
          </form>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper signup-wraper">
          <div className="signup-box">
            <p>Don't have an account?</p>
            <Link to="/register">
              <button>Login </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResendEmailConfirmation;
