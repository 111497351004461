/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react";
import BorderOptions from "../single-components/BorderOptions";
import RangeSlider from "../single-components/RangeSlider";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const ImageStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);

  const updateSliderstyle = (e, type) => {
    handleSetWidgetDispatcher([`style.${responsive}.${e.target.id}`], [e.target.value + "%"], widget, setWidgetDispatcher, false, type);
  }
  
  const updateImageStyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.borderLeftWidth`,
            `style.${responsive}.borderTopWidth`,
            `style.${responsive}.borderRightWidth`,
            `style.${responsive}.borderBottomWidth`
          ], widget, setWidgetDispatcher
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.borderTopLeftRadius`,
            `style.${responsive}.borderTopRightRadius`,
            `style.${responsive}.borderBottomRightRadius`,
            `style.${responsive}.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.borderColor`,
        `style.tablet.borderColor`,
        `style.mobile.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <div className="settings-field-container">
      <RangeSlider
        label={"Opacity"}
        id={"opacity"}
        tag={getResponsiveStyle(widget?.style, responsive, "opacity", null)}
        min={0}
        max={100}
        onChange={updateSliderstyle}
        isPercentage={true}
        removeXPadding={true}
      />
      <hr />
      <BorderOptions
        attribute={getResponsiveStyle(widget?.style, responsive, null, null)}
        onChange={updateImageStyle}
        onChangeColor={updateBorderColor}
        setBorderWidthLockValues={setBorderWidthLockValues}
        setBorderRadiusLockValues={setBorderRadiusLockValues}
        borderWidthLockValues={borderWidthLockValues}
        borderRadiusLockValues={borderRadiusLockValues}
        updateSelectOptions={updateInputSelectOptions}
      />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(ImageStyle); 
