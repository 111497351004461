/* eslint-disable react-hooks/exhaustive-deps */
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import AlignmentTabs from "../single-components/AlignmentTabs";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import InputField from "../single-components/InputField";

const SkipStepContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.fontFamily`], [e.family], widget, setWidgetDispatcher, true);
  };

  const updateFontSize = (e, type) => {
    handleSetWidgetDispatcher([`style.${responsive}.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.color`,
        `style.tablet.color`,
        `style.mobile.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };
  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <div className="row">
          <div className="col-lg-12">
            <div className="input-holder m-0">
              <div className="editable-field  quick-form-actions">
                <div className="quick-action-container ">
                  <FontFamilyPicker
                    attribute={widget.style.desktop}
                    onChangeFont={updateLabelFont}
                    id={"heading"}
                  />
                </div>
                <div className="quick-action-container">
                  <AlignmentTabs
                    tag={widget.style[responsive].textAlign}
                    id={"textAlign"}
                    handleSelect={handleChangeAlign}
                  />
                </div>
              </div>
              <div className="editable-field  form-row quick-form-actions pt-4 font-family-picker">
                <div className="quick-action-container">
                  <div className="d-flex justify-between">
                    <p>Font Size(px)</p>
                    <p><ChangeResponsiveness /></p>
                  </div>
                  <FormifyInput 
                    type="number"
                    id={"fontSize"}
                    min={1}
                    max={200}
                    onChange={updateFontSize}
                    value={
                      widget.style
                        ? widget?.style[responsive]?.fontSize.substring(
                            0,
                            widget?.style[responsive]?.fontSize.length - 2
                          )
                        : "16"
                    }
                  />
                </div>
                <div className="quick-action-container">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorLabel}
                    id={"color"}
                    tag={widget?.style[responsive]?.color}
                  />
                </div>
              </div>
              <hr />
              <InputField
                numRows={4}
                id={"text"}
                label={"SkipStep Content"}
                onChange={handleChange}
                placeholder={"SkipStep Content"}
                type="text"
                value={widget.content.text || ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(SkipStepContent);
