/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL } from "../../../utils";
import {
  checkFontSize,
  checkResponsiveProperties,
  checkResponsiveStyle,
  getResponsiveStyle,
} from "../../utils/responsiveFunctions";
import { useSelector } from "react-redux";
const Product = ({
  product_id,
  storeSelected,
  view,
  publicView,
  sellingPlanId,
  rdyProduct = null,
  style,
  upsell = false,
  product_index,
  element,
  responseHandle,   
  responsesVal,
  details,
  form,
  screen,
  widget,
}) => {

  const [prod, setProduct] = useState(rdyProduct);

  const getSubblyProduct = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/subbly/product/${product_id}?expand[]=pricings`,
        tokenConfig(store.getState)
      );
      const { product } = result.data;
     

      setProduct(product);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const subscriptionProducts = useSelector(
    (state) => state.subscriptionProducts
  );

  const getShopifyProduct = async () => {
    try {
      if (subscriptionProducts && sellingPlanId && sellingPlanId !== null ){
        const finalProduct = await axios.get(`${BASE_DEV_URL}/store/shopify/products/subscriptions/${product_id}/sellingPlan/${sellingPlanId}`,
        tokenConfig(store.getState))
      
        const { product } = finalProduct.data;
        setProduct(product);
      
      }else{
        const result = await axios.get(
          `${BASE_DEV_URL}/store/shopify/products/${product_id}`,
          tokenConfig(store.getState)
        );
        const { product } = result.data;
        setProduct(product);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const getWoocommerceProduct = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/woocommerce/products/${product_id}`,
        tokenConfig(store.getState)
      );

      const { product } = result.data;
      setProduct(product);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const getBigcommerceProduct = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/bigcommerce/products/${product_id}`,
        tokenConfig(store.getState)
      );

      const { product } = result.data;
      setProduct(product);
    } catch (error) {}
  };

  const getRechargeProduct = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/recharge/products/${product_id}`,
        tokenConfig(store.getState)
      );
      const { product } = result.data;
      setProduct(product);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const checkedSiblingIndex = useSelector(
    (state) => state.dynamicProd.siblingChecboxIndex
  );
  const shopifyProductRender = (
    product = rdyProduct ? rdyProduct : prod,
    d = details ? details : ""
  ) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
 
    const dynamicProductNames = form.steps
    .flatMap(step => step.elements)
    .filter(element => element.element_type === 'form_dynamic_product') 
    .flatMap(element => element.content?.siblingswithprods || [])
    // .map(sibling => sibling.name);
  

    return product ? (
      view === "1" ? (
        <div
          className="product col-12"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
          }}
        >
          <div className=" d-flex flex-row justify-content-between  gx-4">
            <div className="col-4">
              <div
                className="product-image"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <img
                  className="img-fluid"
                  src={
                    product?.images?.length > 0
                      ? product.images[0].src
                      : 
                      process.env.PUBLIC_URL +
                        "/assets/images/icons/no-image-available.svg"
                  }
                  alt={product.title}
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "image"),
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-8">
              <div
                className="product-body "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: checkResponsiveStyle(
                    element.style,
                    screen,
                    "verticalAlignment"
                  ),
                  alignItems:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <div className="d-flex flex-column">
                  <h4
                    className="formify-h4"
                    style={
                      element.content.hide_label ?
                      {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                     display:"none",
                    }
                    :
                    {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                      fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                      textAlign: alignment,
                    }}
                  >
            
              {product.title}
                  </h4>
                  {/* <p className="product-description">{product.body_html}</p> */}
                 
                  <p
                    className="product-price"
                    style={
                      element.content.hide_price ?
                      {
                      
                      ...checkResponsiveStyle(element.style, screen, "price"),
                      fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                      textAlign: alignment,
                      display: "none"
                    }
                    :
                    {
                      
                      ...checkResponsiveStyle(element.style, screen, "price"),
                      fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                      textAlign: alignment,
                    }
                  }
                  >
                    {form.currency_symbol}
                    {product.variants.length > 0
                      ? product.variants[0].price
                      : null}
                  </p>

                  <p
                    className="product-promotional-text"
                    style={{
                      ...checkResponsiveStyle(
                        element.style,
                        screen,
                        "promotional"
                      ),
                      fontFamily:
                        element?.style?.desktop?.promotional?.fontFamily?.name,
                      textAlign: alignment,
                    }}
                  >
                    {d.promotionalText}
                  </p>
                  <p
                    className="product-informational-text"
                    style={{
                      ...checkResponsiveStyle(
                        element.style,
                        screen,
                        "informational"
                      ),
                      textAlign: alignment,
                      fontFamily:
                        element?.style?.desktop?.informational?.fontFamily
                          ?.name,
                      textAlign: alignment,
                    }}
                  >
                    {d.informationalText}
                  </p>
                </div>
                {/* <p className="product-price" style={{...checkResponsiveStyle(element.style, screen, "price"), fontFamily: checkResponsiveStyle(element.style, screen, "price")?.fontFamily?.name}}>
                    $
                    {product.variants.length > 0
                      ? product.variants[0].price
                      : null}
                  </p> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={` product`}
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems:
              alignment === "left"
                ? "start"
                : alignment === "right"
                ? "end"
                : alignment,
          }}
        >
          <div
            className="product-image"
            style={{
              height: "",
              display: "flex",
              justifyContent:
                alignment === "left"
                  ? "start"
                  : alignment === "right"
                  ? "end"
                  : alignment,
            }}
          >
            <img
              className="img-fluid"
              src={
                product?.images?.length > 0
                  ? product.images[0].src
                  : process.env.PUBLIC_URL +
                    "/assets/images/icons/no-image-available.svg"
              }
              alt={product.title}
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div className="product-body  ">
            <div className="d-flex flex-column mt-2">
              <h4
                className="formify-h4"
                style={
                  element.content.hide_label ?
                  {
                  ...checkResponsiveStyle(element.style, screen, "title"),
                 display:"none",
                }
                :
                {
                  ...checkResponsiveStyle(element.style, screen, "title"),
                  fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
            {  product.title}
             
              </h4>{" "}
             
              <p
                className="product-price"
                style={
                  element.content?.hide_price ?
                  {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                  display: "none"
                }
                :
                {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                }
              }
              >
                {form.currency_symbol}
                {product?.variants?.length > 0 ? product.variants[checkedSiblingIndex].price : product?.price ? product.price : null}
              </p>
              <p
                className="product-promotional-text"
                style={{
                  ...checkResponsiveStyle(element.style, screen, "promotional"),
                  fontFamily:
                    element?.style?.desktop?.promotional?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
                {d.promotionalText}
              </p>
              <p
                className="product-informational-text"
                style={{
                  ...checkResponsiveStyle(
                    element.style,
                    screen,
                    "informational"
                  ),
                  textAlign: alignment,
                  fontFamily:
                    element?.style?.desktop?.informational?.fontFamily?.name,
                }}
              >
                {d.informationalText}
              </p>
            </div>
          </div>
        </div>
      )
    ) : null;
  };

  const getRechargeProductImage = (product) => {
    if (product && _.isObject(product)) {
      if (_.isArray(product?.images) && product?.images?.length > 0) {
        return product.images[0].original;
      } else {
        return product.images.original;
      }
    } else {
      return (
        process.env.PUBLIC_URL + "/assets/images/icons/no-image-available.svg"
      );
    }
  };

  const getRechargeProductPrice = (product) => {
    if (product) {
      if (product.variants && product.variants.length > 0) {
        return product.variants[0].price;
      }
    }
  };

  const getSubblyProductPrice = (product) => {
    if (product) {
      if (product && product.pricings !== null) {
        return product.pricings[0].price;
      }
    }
  };

  const rechargeProductRender = (
    product = rdyProduct ? rdyProduct : prod,
    d = details ? details : ""
  ) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      view === "1" ? (
        <div
          className="col-12"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
          }}
        >
          <div className="d-flex flex-row justify-content-between gx-4">
            <div className="col-4">
              <div
                className="product-image"
                style={{
                  height: "",
                  display: "flex",
                  justifyContent:
                    alignment === "left"
                      ? "start"
                      : alignment === "right"
                      ? "end"
                      : alignment,
                }}
              >
                <img
                  className="img-fluid"
                  src={getRechargeProductImage(product)}
                  alt={product.title}
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "image"),
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-8">
              <div
                className="product-body "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: checkResponsiveStyle(
                    element.style,
                    screen,
                    "verticalAlignment"
                  ),
                  alignItems:
                    alignment === "left"
                      ? "start"
                      : alignment === "right"
                      ? "end"
                      : alignment,
                }}
              >
                <div className="d-flex flex-column">
                  <h4
                    className="formify-h4"
                    style={
                      element.content.hide_label ?
                      {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                     display:"none",
                    }
                    :
                    {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                      fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                      textAlign: alignment,
                    }}
                  >
                  
              {product.title}
                  </h4>
                  <div>
                    <p
                      style={{
                        ...checkResponsiveStyle(
                          element.style,
                          screen,
                          "promotional"
                        ),
                        fontFamily:
                          element?.style?.desktop?.promotional?.fontFamily
                            ?.name,
                        textAlign: alignment,
                      }}
                    >
                      {d.promotionalText ? d.promotionalText : ""}
                    </p>
                    <p
                      style={{
                        ...checkResponsiveStyle(
                          element.style,
                          screen,
                          "informational"
                        ),
                        fontFamily:
                          element?.style?.desktop?.informational?.fontFamily
                            ?.name,
                      }}
                    >
                      {d.informationalText ? d.informationalText : ""}
                    </p>
                  </div>
                  
                  <p
                    className="product-price"
                    style={
                      element.content.hide_price ?
                      {
                      
                      ...checkResponsiveStyle(element.style, screen, "price"),
                      fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                      textAlign: alignment,
                      display: "none"
                    }
                    :
                    {
                      
                      ...checkResponsiveStyle(element.style, screen, "price"),
                      fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                      textAlign: alignment,
                    }
                  }

                    
                  >
                    
                    ${getRechargeProductPrice(product)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`product`}
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            className="product-image"
            style={{
              display: "flex",
              justifyContent:
                alignment === "left"
                  ? "start"
                  : alignment === "right"
                  ? "end"
                  : alignment,
            }}
          >
            <img
              className="img-fluid"
              src={getRechargeProductImage(product)}
              alt={product.title}
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div className="product-body  ">
            <div className="d-flex flex-column mt-2">
              <h4
                className="formify-h4"
                style={
                  element.content.hide_label ?
                  {
                  ...checkResponsiveStyle(element.style, screen, "title"),
                 display:"none",
                }
                :
                {
                  ...checkResponsiveStyle(element.style, screen, "title"),
                  fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
               
             { product.title}
              </h4>
             
              <p
                className="product-price"
                style={
                  element.content.hide_price ?
                  {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                  display: "none"
                }
                :
                {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                }
              }
              >
                {form.currency_symbol}
                {getRechargeProductPrice(product)}
              </p>
              <p
                className="product-promotional-text"
                style={{
                  ...checkResponsiveStyle(element.style, screen, "promotional"),
                  fontFamily:
                    element?.style?.desktop?.promotional?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
                {d.promotionalText}
              </p>
              <p
                className="product-informational-text"
                style={{
                  ...checkResponsiveStyle(
                    element.style,
                    screen,
                    "informational"
                  ),
                  textAlign: alignment,
                  fontFamily:
                    element?.style?.desktop?.informational?.fontFamily?.name,
                }}
              >
                {d.informationalText}
              </p>
            </div>
          </div>
        </div>
      )
    ) : null;
  };

  const subblyProductRender = (
    product = prod,
    d = details ? details : ""
  ) => {
    
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    if (prod?.pricings && prod?.pricings?.length > 0) {
      var number = prod.pricings[0].price;
      var formattedPrice = number / 100;
     
    } else {
    }

   



    return product ? (
      view === "1" ? (
        <div
          className="product col-12"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
          }}
        >
          <div className="col-12 d-flex flex-row justify-content-between  g-4">
            <div className="col-4">
              <div
                className="product-image"
                style={{
                  height: "",
                  display: "flex",
                  justifyContent:
                    alignment === "left"
                      ? "start"
                      : alignment === "right"
                      ? "end"
                      : alignment,
                }}
              >
                <img
                  className="img-fluid "
                  src={
                    product?.images?.length > 0
                      ? product?.images[0]?.url
                      : process.env.PUBLIC_URL +
                        "/assets/images/icons/no-image-available.svg"
                  }
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "image"),
                    objectFit: "cover",
                  }}
                  alt={product.product_name}
                />
              </div>
            </div>
            <div className="col-8">
              <div
                className="product-body a "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: checkResponsiveStyle(
                    element.style,
                    screen,
                    "verticalAlignment"
                  ),
                  alignItems:
                    alignment === "left"
                      ? "start"
                      : alignment === "right"
                      ? "end"
                      : alignment,
                }}
              >
                <div className="d-flex flex-column">
                  <h4
                    className="formify-h4"
                    style={
                      element.content.hide_label ?
                      {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                     display:"none",
                    }
                    :
                    {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                      fontFamily: element?.style?.[screen]?.title?.fontFamily?.name,
                      textAlign: alignment,
                    }
                  }
                  >
                    {product.name}
                  </h4>
                  {/* <p>{element.content.hide_label ? "HIDDEN" : "SHOWN"}</p> */}
                  <p className="product-description">{product.body_html}</p>
                </div>
           
                <p
                  className="product-price"
                  style={
                    element.content.hide_price ?
                    {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                    display: "none"
                  }
                  :
                  {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                  }
                }
                >
                  {form.currency_symbol}
                  {formattedPrice ? formattedPrice : "" }
                </p>
                <div className="d-flex flex-column">
                  <div>
                    <p
                      style={{
                        ...checkResponsiveStyle(
                          element.style,
                          screen,
                          "promotional"
                        ),
                        fontFamily:
                          element?.style?.desktop?.promotional?.fontFamily
                            ?.name,
                        textAlign: alignment,
                      }}
                    >
                      {d.promotionalText ? d.promotionalText : ""}
                    </p>
                    <p
                      className="product-informational-text"
                      style={{
                        ...checkResponsiveStyle(
                          element.style,
                          screen,
                          "informational"
                        ),
                        textAlign: alignment,
                        fontFamily:
                          element?.style?.desktop?.informational?.fontFamily
                            ?.name,
                      }}
                    >
                      {d.informationalText ? d.informationalText : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="product"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <div className="col-12">
            <div
              className="product-image"
              style={{
                display: "flex",
                justifyContent:
                  checkResponsiveStyle(element.style, screen, "alignment") ===
                  "left"
                    ? "start"
                    : checkResponsiveStyle(
                        element.style,
                        screen,
                        "alignment"
                      ) === "right"
                    ? "end"
                    : checkResponsiveStyle(element.style, screen, "alignment"),
              }}
            >
              <img
                className="img-fluid"
                src={
                  product.images?.length > 0
                    ? product.images[0].url
                    : process.env.PUBLIC_URL +
                      "/assets/images/icons/no-image-available.svg"
                }
                style={checkResponsiveStyle(element.style, screen, "image")}
                alt={product.product_name}
              />
            </div>
            <div className="product-body  ">
              <div className="d-flex flex-column mt-2">
                <h4
                  className="formify-h4"
                  style={
                    element.content.hide_label ?
                    {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                   display:"none",
                  }
                  :
                  {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                    fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {product.name || product.label}
                </h4>
          
                <p
                  className="product-price"
                  style={
                    element.content.hide_price ?
                    {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                    display: "none"
                  }
                  :
                  {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                  }
                }
                >
                  {form.currency_symbol}

                  {formattedPrice ? formattedPrice : "" }


                </p>
                <p
                  className="product-promotional-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "promotional"
                    ),
                    fontFamily:
                      element?.style?.desktop?.promotional?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {d.promotionalText}
                </p>
                <p
                  className="product-informational-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "informational"
                    ),
                    textAlign: alignment,
                    fontFamily:
                      element?.style?.desktop?.informational?.fontFamily?.name,
                  }}
                >
                  {d.informationalText}
                </p>
              </div>
              {/* <p className="product-price"  style={{...checkResponsiveStyle(element.style, screen, "price"), fontFamily: checkResponsiveStyle(element.style, screen, "price")?.fontFamily?.name}}>${product.product_price}</p> */}
            </div>
          </div>
        </div>
      )
    ) : null;
  };

  const woocoommerceProductRender = (
    product = rdyProduct ? rdyProduct : prod,
    d = details ? details : ""
  ) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      view === "1" ? (
        <div
          className="col-12 product"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            width: "100%",
          }}
        >
          <div className="gx-4  d-flex flex-row justify-content-between">
            <div className="col-4">
              <div
                className="product-image"
                style={{
                  height: "",
                  display: "flex",
                  justifyContent:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <img
                  className="img-fluid"
                  src={
                    product?.images?.length > 0
                      ? product.images[0]?.src
                      : process.env.PUBLIC_URL +
                        "/assets/images/icons/no-image-available.svg"
                  }
                  alt={product.name}
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "image"),
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-8">
              <div
                className="product-body "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: checkResponsiveStyle(
                    element.style,
                    screen,
                    "verticalAlignment"
                  ),
                  alignItems:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <div className="d-flex flex-column">
                  <h4
                    className="formify-h4"
                    style={
                      element.content.hide_label ?
                      {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                     display:"none",
                    }
                    :
                    {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                      fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                      textAlign: alignment,
                    }}
                  >
                    {product.name}
                  </h4>
                </div>
             
                <p
                  className="product-price"
                  style={
                    element.content.hide_price ?
                    {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                    display: "none"
                  }
                  :
                  {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                  }
                }
                >
                  {form.currency_symbol} {product.price}
                </p>
                <p
                  className="product-promotional-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "promotional"
                    ),
                    fontFamily:
                      element?.style?.desktop?.promotional?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {d.promotionalText}
                </p>
                <p
                  className="product-informational-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "informational"
                    ),
                    textAlign: alignment,
                    fontFamily:
                      element?.style?.desktop?.informational?.fontFamily?.name,
                  }}
                >
                  {d.informationalText}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="product"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <div className="col-12">
            <div
              className="product-image"
              style={{
                display: "flex",
                justifyContent:
                  checkResponsiveStyle(element.style, screen, "alignment") ===
                  "left"
                    ? "start"
                    : checkResponsiveStyle(
                        element.style,
                        screen,
                        "alignment"
                      ) === "right"
                    ? "end"
                    : checkResponsiveStyle(element.style, screen, "alignment"),
              }}
            >
              <img
                className="img-fluid"
                src={
                  product?.images?.length > 0
                    ? product.images[0]?.src
                    : process.env.PUBLIC_URL +
                      "/assets/images/icons/no-image-available.svg"
                }
                style={checkResponsiveStyle(element.style, screen, "image")}
                alt={product.name}
              />
            </div>
            <div className="product-body  ">
              <div className="d-flex flex-column mt-2">
                <h4
                  className="formify-h4"
                  style={
                    element.content.hide_label ?
                    {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                   display:"none",
                  }
                  :
                  {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                    fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {product.name}
                </h4>
                {/* <p className="product-description">{product.body_html}</p> */}
              </div>
              
           
            
              <p
              className="product-price"
              style={
                element.content.hide_price ?
                {
                
                ...checkResponsiveStyle(element.style, screen, "price"),
                fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                textAlign: alignment,
                display: "none"
              }
              :
              {
                
                ...checkResponsiveStyle(element.style, screen, "price"),
                fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                textAlign: alignment,
              }
            }
            >
              {form.currency_symbol}
              {product.price}
            </p>
              <p
                className="product-promotional-text"
                style={{
                  ...checkResponsiveStyle(element.style, screen, "promotional"),
                  fontFamily:
                    element?.style?.desktop?.promotional?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
                {d.promotionalText}
              </p>
              <p
                className="product-informational-text"
                style={{
                  ...checkResponsiveStyle(
                    element.style,
                    screen,
                    "informational"
                  ),
                  textAlign: alignment,
                  fontFamily:
                    element?.style?.desktop?.informational?.fontFamily?.name,
                }}
              >
                {d.informationalText}
              </p>
            </div>
          </div>
        </div>
      )
    ) : null;
  };

  const bigcommerceProductRender = (
    product = rdyProduct ? rdyProduct : prod,
    d = details ? details : ""
  ) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      view === "1" ? (
        <div
          className="col-12 product"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
          }}
        >
          <div className=" gx-4  d-flex flex-row justify-content-between">
            <div className="col-4">
              <div
                className="product-image"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <img
                  className="img-fluid"
                  // src={product.images[0].src}
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "image"),
                    objectFit: "cover",
                  }}
                  alt={product.name}
                />
              </div>
            </div>
            <div className="col-8">
              <div
                className="product-body "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: checkResponsiveStyle(
                    element.style,
                    screen,
                    "verticalAlignment"
                  ),
                  alignItems:
                    checkResponsiveStyle(element.style, screen, "alignment") ===
                    "left"
                      ? "start"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ) === "right"
                      ? "end"
                      : checkResponsiveStyle(
                          element.style,
                          screen,
                          "alignment"
                        ),
                }}
              >
                <div className="d-flex flex-column">
                  <h4
                    className="formify-h4"
                    style={
                      element.content.hide_label ?
                      {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                     display:"none",
                    }
                    :
                    {
                      ...checkResponsiveStyle(element.style, screen, "title"),
                      fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                      textAlign: alignment,
                    }}
                  >
                    {product.name}
                  </h4>
                </div>
          
                <p
                  className="product-price"
                  style={
                    element.content.hide_price ?
                    {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                    display: "none"
                  }
                  :
                  {
                    
                    ...checkResponsiveStyle(element.style, screen, "price"),
                    fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                    textAlign: alignment,
                  }
                }
                >
                  {form.currency_symbol}
                  {product.price}
                </p>
                <p
                  className="product-promotional-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "promotional"
                    ),
                    fontFamily:
                      element?.style?.desktop?.promotional?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {d.promotionalText}
                </p>
                <p
                  className="product-informational-text"
                  style={{
                    ...checkResponsiveStyle(
                      element.style,
                      screen,
                      "informational"
                    ),
                    textAlign: alignment,
                    fontFamily:
                      element?.style?.desktop?.informational?.fontFamily?.name,
                  }}
                >
                  {d.informationalText}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="product"
          style={{
            padding: 0,
            ...checkResponsiveStyle(element.style, screen, "container"),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <div className="col-12">
            <div
              className="product-image"
              style={{
                display: "flex",
                justifyContent:
                  checkResponsiveStyle(element.style, screen, "alignment") ===
                  "left"
                    ? "start"
                    : checkResponsiveStyle(
                        element.style,
                        screen,
                        "alignment"
                      ) === "right"
                    ? "end"
                    : checkResponsiveStyle(element.style, screen, "alignment"),
              }}
            >
              <img
                className="img-fluid"
                // src={
                //   product.images.length > 0
                //     ? product.images[0].src
                //     : process.env.PUBLIC_URL + "/assets/images/icons/no-image-available.svg"
                // }
                style={checkResponsiveStyle(element.style, screen, "image")}
                alt={product.name}
              />
            </div>
            <div className="product-body  ">
              <div className="d-flex flex-column mt-2">
                <h4
                  className="formify-h4"
                  style={
                    element.content.hide_label ?
                    {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                   display:"none",
                  }
                  :
                  {
                    ...checkResponsiveStyle(element.style, screen, "title"),
                    fontFamily: element?.style?.desktop?.title?.fontFamily?.name,
                    textAlign: alignment,
                  }}
                >
                  {product.name}
                </h4>
                {/* <p className="product-description">{product.body_html}</p> */}
              </div>
         
              <p
                className="product-price"
                style={
                  element.content.hide_price ?
                  {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                  display: "none"
                }
                :
                {
                  
                  ...checkResponsiveStyle(element.style, screen, "price"),
                  fontFamily: element?.style?.desktop?.price?.fontFamily?.name,
                  textAlign: alignment,
                }
              }
              >
                {form.currency_symbol}
                {product.price}
              </p>
              <p
                className="product-promotional-text"
                style={{
                  ...checkResponsiveStyle(element.style, screen, "promotional"),
                  fontFamily:
                    element?.style?.desktop?.promotional?.fontFamily?.name,
                  textAlign: alignment,
                }}
              >
                {d.promotionalText}
              </p>
              <p
                className="product-informational-text"
                style={{
                  ...checkResponsiveStyle(
                    element.style,
                    screen,
                    "informational"
                  ),
                  textAlign: alignment,
                  fontFamily:
                    element?.style?.desktop?.informational?.fontFamily?.name,
                }}
              >
                {d.informationalText}
              </p>
            </div>
          </div>
        </div>
      )
    ) : null;
  };

  

  const upSellShopify = (product = rdyProduct ? rdyProduct : prod) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      <>
        <div className="col-12 upsell-text row">
          <div className="col-6 upsell-image">
            <img
              className="img-fluid"
              src={
                prod?.images
                  ? prod?.images.length > 0
                    ? prod?.images[0].src
                    : process.env.PUBLIC_URL +
                      "/assets/images/icons/no-image-available.svg"
                  : process.env.PUBLIC_URL +
                    "/assets/images/icons/no-image-available.svg"
              }
              alt={product.title}
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div
            className="col-6 upsell-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: checkResponsiveStyle(
                element.style,
                screen,
                "verticalAlignment"
              ),
              alignItems:
                checkResponsiveStyle(element.style, screen, "alignment") ===
                "left"
                  ? "start"
                  : checkResponsiveStyle(element.style, screen, "alignment") ===
                    "right"
                  ? "end"
                  : checkResponsiveStyle(element.style, screen, "alignment"),
            }}
          >
            <p
              className="upsell-description e"
              style={{ 
                
                ...checkResponsiveStyle(
                  element.style,
                  screen,
                  "description"
                ),
                textAlign: alignment,
                fontFamily: element?.style?.desktop?.fontFamily?.name,
                fontSize: checkFontSize(
                  element?.style,
                  screen,
                  "fontSize",
                  null
                ),
              }}
            >
              {element.content?.description || ""}
            </p>

            <div className="btn-group mt-3 d-flex flex-row justify-content-center">
              <>
                {responsesVal ? (
                  <h4 className="formify-h4">Thank you for choosing us!!</h4>
                ) : (
                  <>
                    <button
                      className="formify-btn yes-upsell mx-1"
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.yesButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      onClick={() => {
                        if (responseHandle)
                          responseHandle(
                            element._id,
                            product.variants.length > 0
                              ? product.variants[0].id || ""
                              : product.id
                          );
                      }}
                    >
                       {element.content.yesUpsell}
                    </button>
                    <button
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.noButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      className="invert-formify-btn m-0"
                    >
                   {element.content.noUpsell}
                    </button>
                  </>
                )}
                {/* <button
                  className="formify-btn yes-upsell me-2"
                  style={checkResponsiveProperties(element.style, screen, "buttons.yesButton")}
                  onClick={responseHandle}
                >
                  Yes
                </button>
                <button className="invert-formify-btn m-0" 
                  style={element.style[screen].buttons.noButton}>No</button> */}
              </>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const upSellRecharge = (product = rdyProduct ? rdyProduct : prod) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      <>
        <div className="col-12 upsell-text row">
          <div className="col-6 upsell-image">
            <img
              className="img-fluid"
              src={getRechargeProductImage(prod)}
              alt={product.title}
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div
            className="col-6 upsell-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: checkResponsiveStyle(
                element.style,
                screen,
                "verticalAlignment"
              ),
              alignItems:
                checkResponsiveStyle(element.style, screen, "alignment") ===
                "left"
                  ? "start"
                  : checkResponsiveStyle(element.style, screen, "alignment") ===
                    "right"
                  ? "end"
                  : checkResponsiveStyle(element.style, screen, "alignment"),
            }}
          >
            <p
              className="upsell-description b"
              style={{
                ...element.style[screen].description,
                textAlign: alignment,
                fontFamily: element?.style?.desktop?.fontFamily?.name,
                fontSize: checkFontSize(
                  element?.style,
                  screen,
                  "fontSize",
                  null
                ),
              }}
            >
              {element.content.description}
            </p>

            <div className="btn-group mt-3 d-flex flex-row justify-content-center">
              <>
                {responsesVal ? (
                  <h4 className="formify-h4">Thank you for choosing us!!</h4>
                ) : (
                  <>
                    <button
                      className="formify-btn yes-upsell mx-1"
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.yesButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      onClick={() => {
                        if (responseHandle)
                          responseHandle(
                            element._id,
                            product.variants && product.variants.length > 0
                              ? product.variants[0].external_variant_id || ""
                              : product.external_product_id
                          );
                      }}
                    >
                   {element.content.yesUpsell}
                    </button>
                    <button
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.noButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      className="invert-formify-btn m-0"
                    >
                  {element.content.noUpsell}
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };
  let fontSize =
    getResponsiveStyle(element?.style, screen, "fontSize", null) || "";
  const upSellSubbly = (product = rdyProduct ? rdyProduct : prod) => {

    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      <>
        <div className="col-12 upsell-text row">
          <div className="col-6 upsell-image">
            <img
              className="img-fluid"
              src={
                prod?.images
                ? prod.images[0]?.url ? prod.images[0].url : process.env.PUBLIC_URL +
                "/assets/images/icons/no-image-available.svg"
                  : process.env.PUBLIC_URL +
                    "/assets/images/icons/no-image-available.svg"
                    
              }
              style={checkResponsiveStyle(element.style, screen, "image")}
              alt="product-img"
            />
          </div>
          <div
            className="col-6 upsell-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: checkResponsiveStyle(
                element.style,
                screen,
                "verticalAlignment"
              ),
              alignItems:
                checkResponsiveStyle(element.style, screen, "alignment") ===
                "left"
                  ? "start"
                  : checkResponsiveStyle(element.style, screen, "alignment") ===
                    "right"
                  ? "end"
                  : checkResponsiveStyle(element.style, screen, "alignment"),
            }}
          >
            <p
              className="upsell-description r"
              style={{
                ...checkResponsiveStyle(
                  element.style,
                  screen,
                  "description"
                ),
                fontSize: fontSize,
                textAlign: alignment,
              
                fontFamily: element.style?.desktop?.fontFamily?.name,
               
              }}

             
            >
              {element.content.description}
            </p>

            <div className="btn-group mt-3 d-flex flex-row justify-content-center">
              <>
                {responsesVal ? (
                  <h4 className="formify-h4">Thank you for choosing us!!</h4>
                ) : (
                  <>
                    <button
                      className="formify-btn yes-upsell mx-1"
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.yesButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      onClick={() => {
                        if (responseHandle)
                          responseHandle(element._id, prod.id);
                      }}
                    >
                    {element.content.yesUpsell}
                    </button>
                    <button
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.noButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      className="invert-formify-btn m-0"
                    >
                     {element.content.noUpsell}
                    </button>
                  </>
                )}

                {/* <button
                  className="formify-btn yes-upsell me-2"
                  style={checkResponsiveProperties(element.style, screen, "buttons.yesButton")}
                >
                  Yes
                </button>
                <button className="invert-formify-btn m-0" 
                  style={element.style[screen].buttons.noButton}>No</button> */}
              </>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const upSellWoo = (product = rdyProduct ? rdyProduct : prod) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      <>
        <div className="col-12 upsell-text row">
          <div className="col-6 upsell-image">
            <img
              className="img-fluid"
              src={
                product?.images?.length > 0
                  ? product.images[0].src
                  : process.env.PUBLIC_URL +
                    "/assets/images/icons/no-image-available.svg"
              }
              alt="product-img"
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div
            className="col-6 upsell-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: checkResponsiveStyle(
                element.style,
                screen,
                "verticalAlignment"
              ),
              alignItems:
                checkResponsiveStyle(element.style, screen, "alignment") ===
                "left"
                  ? "start"
                  : checkResponsiveStyle(element.style, screen, "alignment") ===
                    "right"
                  ? "end"
                  : checkResponsiveStyle(element.style, screen, "alignment"),
            }}
          >
            <p
              className="upsell-description a"
              style={{
                ...element.style?.[screen]?.description,
                textAlign: alignment,
                fontFamily: element.style?.desktop?.fontFamily?.name,
                fontSize: checkFontSize(
                  element?.style,
                  screen,
                  "fontSize",
                  null
                ),
              }}
            >
              {element.content.description}
            </p>

            <div className="btn-group mt-3 d-flex flex-row justify-content-center">
              <>
                {responsesVal ? (
                  <h4 className="formify-h4">Continue Finishing Your Order!</h4>
                ) : (
                  <>
                    <button
                      className="formify-btn yes-upsell mx-1"
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.yesButton"
                        ),

                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      onClick={() => {
                        if (responseHandle)
                          responseHandle(element._id, product.id);
                      }}
                    >
                      {element.content.yesUpsell}
                    </button>
                    <button
                      style={{
                        ...checkResponsiveProperties(
                          element.style,
                          screen,
                          "buttons.noButton"
                        ),
                        fontFamily: element.style?.desktop?.fontFamily?.name,
                      }}
                      className="invert-formify-btn m-0"
                    >
                      {element.content.noUpsell}
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const upsellBigcommerce = (product = rdyProduct ? rdyProduct : prod) => {
    let alignment = checkResponsiveStyle(element.style, screen, "alignment");
    return product ? (
      <>
        <div className="col-12 upsell-text row">
          <div className="col-6 upsell-image">
            <img
              className="img-fluid"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/icons/no-image-available.svg"
              }
              alt="product-img"
              style={checkResponsiveStyle(element.style, screen, "image")}
            />
          </div>
          <div
            className="col-6 upsell-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: checkResponsiveStyle(
                element.style,
                screen,
                "verticalAlignment"
              ),
              alignItems:
                checkResponsiveStyle(element.style, screen, "alignment") ===
                "left"
                  ? "start"
                  : checkResponsiveStyle(element.style, screen, "alignment") ===
                    "right"
                  ? "end"
                  : checkResponsiveStyle(element.style, screen, "alignment"),
            }}
          >
            U
            <p
              className="upsell-description t"
              style={{
                ...element.style[screen].description,
                textAlign: alignment,
                fontFamily: element.style?.desktop?.fontFamily?.name,
                fontSize: checkFontSize(
                  element?.style,
                  screen,
                  "fontSize",
                  null
                ),
              }}
            >
              {element.content.description}
            </p>
            <div className="btn-group mt-3 d-flex flex-row justify-content-center">
              <>
                {responsesVal ? (
                  <h4 className="formify-h4">Thank you for choosing us!!</h4>
                ) : (
                  <>
                    <button
                      className="formify-btn yes-upsell"
                      style={checkResponsiveProperties(
                        element.style,
                        screen,
                        "buttons.yesButton"
                      )}
                      onClick={() => {
                        if (responseHandle)
                          responseHandle(element._id, product.id);
                      }}
                    >
                      {element.content.yesUpsell}
                    </button>
                    <button
                      style={checkResponsiveProperties(
                        element.style,
                        screen,
                        "buttons.noButton"
                      )}
                      className="invert-formify-btn m-0"
                    >
                      {element.content.noUpsell}
                    </button>
                  </>
                )}

                {/* <button
                  className="formify-btn yes-upsell me-2"
                  style={checkResponsiveProperties(element.style, screen, "buttons.yesButton")}
                >
                  Yes
                </button>
                <button className="invert-formify-btn m-0" 
                  style={element.style[screen].buttons.noButton}>No</button> */}
              </>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const upsellRenderToStore = {
    shopify: upSellShopify,
    subbly: upSellSubbly,
    woocommerce: upSellWoo,
    bigcommerce: upsellBigcommerce,
    recharge: upSellRecharge,
  };

  const productRenderToStore = {
    shopify: shopifyProductRender,
    woocommerce: woocoommerceProductRender,
    bigcommerce: bigcommerceProductRender,
    subbly: subblyProductRender,
    recharge: rechargeProductRender,
  };

  const productFetchToStore = {
    shopify: getShopifyProduct,
    woocommerce: getWoocommerceProduct,
    bigcommerce: getBigcommerceProduct,
    subbly: getSubblyProduct,
    recharge: getRechargeProduct,
  };

  useEffect(() => {
    if (!publicView) productFetchToStore[storeSelected]();
  }, [product_id]);



  return (
    <>
      {!rdyProduct && !prod ? (
        <div className="d-flex justify-content-center w-100">
          <div className="d-flex flex-column align-items-center">
            <CircularProgress />
            <p className="pt-2">Loading products widget...</p>
          </div>
        </div>
      ) : rdyProduct ? (
        upsell ? (
          upsellRenderToStore[storeSelected]()
        ) : (
          productRenderToStore[storeSelected]()
        )
      ) : (
        productRenderToStore[storeSelected]()
      )}
    </>
  );
};

export default Product;