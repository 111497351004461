import { Avatar } from "@mui/material";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../utils";
import SidebarLinks from "../utils/SidbarLinks";
import FormResponse from "../utils/FormResponses";
const SettingsSidebar = ({ auth, menu, type }) => {
  const loc = useLocation();

  const NO_SIDEBAR_LINKS = ["/profile/billing/cancel"];
  const displaySidebar = () => {
    if (NO_SIDEBAR_LINKS.includes(loc.pathname) || loc.pathname.includes("/profile/billing/buy/")) {
      return "none";
    } else {
      return "display-block";
    }
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1)+"..." : str;
  };
  
  return (
    <div
      className="flex-shrink-0  bg-white sidebar p-0 "
      style={{ maxWidth: 200, display: displaySidebar() }}
    >
      <ul className="list-unstyled ps-0">
        <SidebarLinks menu={menu} type={type} />
        {type === "dashboard" && (<li
            className="dashboard-link ps-4  d-flex flex-row justify-content-start align-items-center"
          >
            {/* {link.icon} */}
            <img src={process.env.PUBLIC_URL + "/assets/images/icons/message-question.svg"}
              className="nav-icons"
              alt=""/>
            <a href="https://docs.google.com/document/d/1Wy2u9nhl4P-uueI2ABoHiHlLx_gH68QeUFNt9Aiom3Y/edit#heading=h.96g1fnf489n5" target="_blank" className="link-dark rounded">Get Help</a>
          </li>)}
      </ul>
      <div className="top-bottom">
        <ul className="list-unstyled ps-0 mt-5">
          <li className="border-top my-3" style={{ padding: 0 }} />
          <li className="">
            <div className="connect-shop">
              <h4>Connect your shop</h4>
              <p>
                Send data where you need it, set up notifications, and more. Work
                smarter, not harder.
              </p>
              <div className="icon-bar">
                <Link to="/integrations/shopify"><img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/shopify-icon.png"}
                  className="header-link-data img-fluid"
                  alt=""
                /></Link>
                 <Link to="/integrations/subbly"><img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/subly-icon.png"}
                  className="header-link-data img-fluid"
                  alt=""
                /></Link>
                <Link to="/integrations/woocommerce"><img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/woo-icon.png"}
                  className="header-link-data img-fluid"
                  alt=""
                /></Link>
              </div>
            </div>
          </li>
          <li className="border-top my-3" style={{ padding: 0 }} />
          <li>
            <div className="profile-data">
              <Link to ="/profile" ><Avatar
                src={auth.user ? auth.user.profile_picture : null}
                sx={{ width: 42, height: 42 }}
              /></Link>
              <div className="user-info d-flex flex-column align-items-start">
                <h4>
                  <Link to="/profile">
                    {auth.user
                      ? auth.user.first_name ? truncate(auth.user.first_name,10) 
                        
                        :auth.user.username ?  truncate(auth.user.username,12) : ""
                      : ""}
                  </Link>
                </h4>
                <p>{
                   auth
                   ? auth.user.transactions[0]?.account_type?.title
                   : null}</p>
              </div>
            </div>
          </li>
        </ul>
        <div className="nav-form-response">
          <FormResponse/>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps)(SettingsSidebar);
