import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../utils";
import Profile from "./Profile";
import Resources from "./Resources";
import Home from "./Home";
import Integrations from "./Integrations";
import FormBuilder from "./FormBuilder";
import OnboardIntegrations from "./OnboardIntegrations";
import { useEffect } from "react";
const Dashboard = ({ auth }) => {
  // useEffect(() => {
  //   if (
  //     auth.user.account.info.customer_id &&
  //     auth.user.register_type === "stripe"
  //   ) {
  //     const head = document.querySelector("head");
  //     const script = document.createElement("script");
  //     script.innerHTML = `(function(d, t) {
  //       var e = d.createElement(t),
  //       s = d.getElementsByTagName(t)[0];
  //       e.src = 'https://d1gqkepxkcxgvm.cloudfront.net/stunning-bar.js';
  //       e.id  = 'stunning-bar';
  //       e.setAttribute('defer','');
  //       e.setAttribute('data-app-ckey', '4441mgysukdqpqclmzqewiiuq');
  //       e.setAttribute('data-stripe-id', '${auth.user.account.info.customer_id}');
  //       s.parentNode.insertBefore(e, s);
  //       }(document, 'script'));`;
  //     head.appendChild(script);
  //     return () => {
  //       head.removeChild(script);
  //     };
  //   }
  // }, [auth]);

  return (
    <>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/integrations/*" element={<Integrations />} />
        <Route path="/onboard-integrations/" element={<OnboardIntegrations />} />

        <Route path="/form/:id/step/:step/*" element={<FormBuilder />} />
        <Route path="/resources/*" element={<Resources />} />
      </Routes>
    </>
  );
};

export default connect(mapAuthErrorsStateToProps)(Dashboard);
