import {
  DELETE_USER,
  GET_USER,
  GET_USER_ERROR,
  GOOGLE_AUTH,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  UPDATE_USER,
  USER_LOADING,
  REMOVE_REGISTER_SIGNAL,
} from "../actions/types";

let token = "";
try {
  token = localStorage.getItem("token")
}catch(e){
 
}

const initialState = {
  user: null,
  token: token,
  isAuthenticated: false,
  isLoading: false,
  userRegistered: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GOOGLE_AUTH:
    case LOGIN:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
      };

    case REMOVE_REGISTER_SIGNAL:
      return {
        ...state,
        userRegistered: false,
      };

    case REGISTER_USER:
      return {
        ...state,
        userRegistered: true,
      };
    case GET_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_USER:
    case LOGIN_ERROR:
    case GET_USER_ERROR:
    case LOGOUT_USER:
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default auth;
