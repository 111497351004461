import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { getDynamicTagComponent } from "./renderersFunctions";
import { checkFontSize, checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const FullNameRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  const css = element?.style
    ? `
.widget-${element._id} input::placeholder {
  opacity:0.6;
  ${ getResponsiveStyle(element?.style, screen, "input", "color") && ` color:  ${getResponsiveStyle(element?.style, screen, "input", "color")}`};
  ${ element.style?.desktop?.input?.fontFamily && `font-family:  ${element?.style?.desktop?.helpText?.fontFamily?.name}` };
  ${ checkFontSize(element?.style, screen, "input", "fontSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "fontSize")}px`};
  ${ getResponsiveStyle(element?.style, screen, "input", "fontDecoration") && `text-decoration:  ${getResponsiveStyle(element?.style, screen, "input", "fontDecoration")}`};
  ${ getResponsiveStyle(element?.style, screen, "input", "fontWeight") && `text-weight:  ${getResponsiveStyle(element?.style, screen, "input", "fontWeight")}`};
  ${ getResponsiveStyle(element?.style, screen, "input", "lineHeight") && `line-height:  ${getResponsiveStyle(element?.style, screen, "input", "lineHeight")}`};
  ${ getResponsiveStyle(element?.style, screen, "input", "wordSpacing") && `word-spacing:  ${getResponsiveStyle(element?.style, screen, "input", "wordSpacing")}`};
  ${ getResponsiveStyle(element?.style, screen, "input", "letterSpacing") && `border-right-width:  ${getResponsiveStyle(element?.style, screen, "input", "letterSpacing")}`};
  ${"}"}`: ``;

  let inputAlignment = getResponsiveStyle(element?.style, screen,  "textAlign", null);

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <style>{css} </style>
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div
              className={"widget-inner-container input-holder "}
              style={{
                alignItems: inputAlignment === "left"  ? "start" : inputAlignment  === "right"  ? "end": inputAlignment 
              }}
            >
              {!element.content.hide_label ? (
                <h4
                  className="formify-h4"
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "label"),
                    fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                  }}
                >
                  {element.content.label}
                </h4>
              ) : null}
              <div className="row name-widget-wrapper">
                <div className="col-lg-6 col-sm-12">
                  {!isEditable ? (
                    <input
                      type="text"
                      value={
                        responses[element._id]
                          ? responses[element._id]?.first_name
                          : ""
                      }
                      onChange={(e) => {
                        setWidgetResponsesDispatcher(element._id, {
                          first_name: e.target.value,
                        },"form_name");
                      }}
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "input"),
                        fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      }}
                      placeholder={element.content.first_name_placeholder}
                    />
                  ) : (
                    <input
                      type="text"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "input"),
                        fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      }}
                      placeholder={element.content.first_name_placeholder}
                    />
                  )}

                  <p
                    className="formify-sm-p"
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "helpText"),
                      fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      textAlign: inputAlignment,
                    }}
                  >
                    {element.content.first_name_label}
                  </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                  {!isEditable ? (
                    <input
                      type="text"
                      value={
                        responses[element._id]
                          ? responses[element._id]?.last_name
                          : ""
                      }
                      onChange={(e) => {
                        setWidgetResponsesDispatcher(element._id, {
                          last_name: e.target.value,
                        },"form_name");
                      }}
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "input"),
                        fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      }}
                      placeholder={element.content.last_name_placeholder}
                    />
                  ) : (
                    <input
                      type="text"
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "input"),
                        fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      }}
                      placeholder={element.content.last_name_placeholder}
                    />
                  )}

                  <p
                    className="formify-sm-p"
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "helpText"),
                      fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                      textAlign: inputAlignment,
                    }}
                  >
                    {element.content.last_name_label}
                  </p>
                </div>
              </div>

              {responseError ? (
                Object.keys(responseError).length > 0 ? (
                  <p className="error-message" style={{fontFamily: element.style?.[screen]?.helpText.fontFamily?.name }}>{responseError[element._id]}</p>
                ) : null
              ) : null}
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(FullNameRender);
