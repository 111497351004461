import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import InputMask from "react-input-mask";
import { getDynamicTagComponent } from "./renderersFunctions";
import { checkFontSize, checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const NumberRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {

  const css = element?.style
    ? `
  .widget-${element._id} input::placeholder { opacity:0.6;
    ${ getResponsiveStyle(element?.style, screen, "input", "color") && ` color:  ${getResponsiveStyle(element?.style, screen, "input", "color")}`};
    ${ element.style?.desktop?.input?.fontFamily && `font-family:  ${element?.style?.desktop?.helpText?.fontFamily?.name}` };
    ${ checkFontSize(element?.style, screen, "input", "fontSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "fontSize")}px`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontDecoration") && `text-decoration:  ${getResponsiveStyle(element?.style, screen, "input", "fontDecoration")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontWeight") && `text-weight:  ${getResponsiveStyle(element?.style, screen, "input", "fontWeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "lineHeight") && `line-height:  ${getResponsiveStyle(element?.style, screen, "input", "lineHeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "wordSpacing") && `word-spacing:  ${getResponsiveStyle(element?.style, screen, "input", "wordSpacing")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "letterSpacing") && `border-right-width:  ${getResponsiveStyle(element?.style, screen, "input", "letterSpacing")}`};
    ${"}"}`: ``;

  let alignment = getResponsiveStyle(element?.style, screen, "alignment", null);

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <style>{css}</style>
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
          {(provided, snapshot) => (
            <div
              className={
                snapshot.isDragging && outerSnapshot.isDraggingOver
                  ? index === 0
                    ? " form-widget isDragging"
                    : " mt-3 form-widget isDragging"
                  : "  mt-3 form-widget"
              }
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            ><div
                className={"widget-inner-container input-holder"}
                style={{alignItems: alignment === "left"  ? "start" : alignment === "right" ? "end": alignment }}
              >
                {!element.content.hide_label ? (
                  <h4
                    className="formify-h4"
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "label"),
                      textAlign: alignment,
                      fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                    }}
                  >
                    {element.content.title}
                  </h4>
                ) : null}
                {!isEditable ? (
                    <input
                      type="number"
                      min={element.content.min}
                      max={element.content.max}
                      placeholder={element.content.placeholder}
                      style={{
                        ...checkResponsiveStyle(element.style, screen, "input"),
                        textAlign: alignment,
                        fontFamily: element.style?.[screen].label?.fontFamily?.name,
                      }}
                      value={responses[element._id]?.value}
                      onChange={(e) => {
                        setWidgetResponsesDispatcher(element._id, {
                          value: e.target.value,
                        },"form_phone")
                      }}
                    />
                ) : (
                  <input
                    type="number"
                    min={element.content.min}
                    max={element.content.max}
                    placeholder={element.content.placeholder}
                    style={{
                      ...checkResponsiveStyle(element.style, screen, "input"),
                      textAlign: alignment,
                      fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                    }}
                  />
                )}
              {!isEditable && responseError ? (
                Object.keys(responseError).length > 0 ? (
                  <p className="error-message" style={{fontFamily: element.style?.[screen]?.label?.fontFamily?.name }}>{responseError[element._id]}</p>
                ) : null
              ) : null}
                <p
                  className="formify-sm-p"
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "helpText"),
                    textAlign: alignment,
                    fontFamily: element.style?.[screen]?.label?.fontFamily?.name,
                  }}
                >  {element.content.help_text}</p>
              </div>

              {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
            </div>
          )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(NumberRender);
