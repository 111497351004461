import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51KlDLYLvFUx8hFgEwwRTWCj2pzZY9keIOsG69jwRb3XbFwCVVCA1KKvVt1nsRIHfxklA66ztjOqWjYqHETSWLiQK00E4o9BIu4"
);

const Subscribe = () => {
  const { state } = useLocation();

  const nav = useNavigate();

  const [clientSecret] = useState(state ? state.clientSecret : null);

  const [plan] = useState(state ? state.plan : null);

  const options = {
    clientSecret,
  };

  if (!clientSecret) {
    nav("/profile/billing");
  }

  return (
    <div className="formify-card  var-width  m-4">
      <div className="formify-header integration-header mb-4">
        <h4>Complete the payment</h4>
      </div>
      <div className="container-fluid integrations" style={{ padding: 0 }}>
        <div className="row gy-3 ">
          <div className="col-lg-12">
            <div className="container-fluid" style={{ padding: 0 }}>
              <div className="row integrations-holder  g-3">
                {plan ? (
                  <div className="col-sm-5 col-md-4 col-12">
                    <div className="integration-card">
                      <div className="integration-header">
                        <div className="integration-details mt-2">
                          <p>{plan.product.name}</p> <br />
                        </div>
                        <h4 className="m-0">
                          {plan.currency.toUpperCase()} {plan.unit_amount / 100}{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="container-fluid" style={{ padding: 0 }}>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
