import { Dropdown, DropdownButton } from "react-bootstrap";

const FontDecoration = ({
    tag,
    handleSelect,
    id
}) => {

    return (
        <div className="editable-field form-row  ">
            <p> Decoration</p>
            <div className="widget-range-slider-container">
                <DropdownButton
                    title={tag ? tag.replace('-', ' ') : "None"}
                    id={id}
                    name={id}
                    onSelect={(e) => handleSelect(e, { id: id })}
                    className="form-dropdown-select"
                >
                    <Dropdown.Item eventKey="underline">Underline</Dropdown.Item>
                    <Dropdown.Item eventKey="overline">Overline</Dropdown.Item>
                    <Dropdown.Item eventKey="line-through">Line Through</Dropdown.Item>
                    <Dropdown.Item eventKey="none">None</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
};

export default FontDecoration;