import FontFamilyPicker from "../single-components/FontFamilyPicker";
import InputField from "../single-components/InputField";
import AlignmentTabs from "../single-components/AlignmentTabs";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import WidthOptions from "../single-components/WidthOptions";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import SwitchChip from "../single-components/switchChip";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const IntroductionContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  mergeWidget
}) => {

  const handleSettings = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.heading.${id}`, `style.${responsive}.subheading.${id}`], [e, e], widget, setWidgetDispatcher);
  };

  const handleDataChanges = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const changeRequired = (val, ele) => {
    handleSetWidgetDispatcher([`content.${ele}`], [val], widget, setWidgetDispatcher);
  };

  const updateInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateFontFamily = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.heading.fontFamily`,
        `style.desktop.subheading.fontFamily`
      ], 
      [
        { name: name, src: url },
        { name: name, src: url }
      ], widget, setWidgetDispatcher, true
    );
  };

  const updateFontSize = (e, type) => {
    let value = e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher([`style.${responsive}.heading.${e.target.id}`], [value], widget, setWidgetDispatcher, false, type);
  };

  const updateSubeheadingFontSize = (e, type) => {
    let value = e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher([`style.${responsive}.subheading.${e.target.id}`], [value], widget, setWidgetDispatcher, false, type);
  };

  const updateColorHeader = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.heading.color`,
        `style.tablet.heading.color`,
        `style.mobile.heading.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorSubHeader = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.subheading.color`,
        `style.tablet.subheading.color`,
        `style.mobile.subheading.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container font-family-picker">
          <FontFamilyPicker
            attribute={widget?.style?.desktop?.heading}
            onChangeFont={updateFontFamily}
            id={"label"}
          />
        </div>
        <div className="quick-action-container font-family-picker">
          <WidthOptions
            handleSelect={updateInputSelectstyle}
            tag={getResponsiveStyle(widget?.style, responsive, "width", null)}
            id={"width"}
            name={"width"}
          />
        </div>
      </div>
      <div className="quick-action-container font-family-picker">
        <AlignmentTabs
          tag={getResponsiveStyle(widget?.style, responsive, "heading", "textAlign")}
          id={"textAlign"}
          handleSelect={handleSettings}
        />
      </div>
      <hr />
      <InputField
        id={"heading"}
        label={"Heading"}
        onChange={handleDataChanges}
        placeholder={"Heading"}
        type="text"
        value={widget.content.heading}
        mergeWidget={mergeWidget}
      />
      <div className="editable-field  form-row quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateFontSize}
            value={checkFontSize(widget?.style, responsive, "heading", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateColorHeader}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "heading", "color")}
          />
        </div>
      </div>
      <InputField
        id={"subHeading"}
        label={"Subheading"}
        onChange={handleDataChanges}
        placeholder={"Subheading"}
        type="text"
        value={widget.content.subHeading}
      />
      <div className="editable-field  form-row quick-form-actions pt-4 font-family-picker font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1"><ChangeResponsiveness /></div>
          </div>
          <FormifyInput 
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateSubeheadingFontSize}
            value={checkFontSize(widget?.style, responsive, "subheading", "fontSize")}
          />
        </div>
        <div className="editable-field w-100 form-row mt-3 mb-0">
          <p>Font Color</p>
          <ColorPickerStyle
            onChange={updateColorSubHeader}
            id={"color"}
            tag={getResponsiveStyle(widget?.style, responsive, "subheading", "color")}
          />
        </div>
      </div>
      <hr />
      <div className="settings-field-container mb-0 px-0">
        <div className="editable-field form-row">
          <p className="field-label">Hide Heading</p>
          <SwitchChip
            id="heading_hidden"
            checked={widget.content ? widget.content.heading_hidden : false}
            onChange={(val) => changeRequired(val, "heading_hidden")}
          />
        </div>
        <div className="editable-field form-row">
          <p className="field-label">Hide Subheading</p>
          <SwitchChip
            id="subheading_hidden"
            checked={widget.content ? widget.content.subheading_hidden : false}
            onChange={(val) => changeRequired(val, "subheading_hidden")}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(IntroductionContent);