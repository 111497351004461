import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../dashboard/Navbar";
import SettingsSidebar from "../dashboard/SettingsSidebar";
import ContactDetail from "./ContactDetail";
import ContactList from "./ContactList";
import FormList from "./FormList";
import Copy from "./Copy";
import CopyDetail from "./CopyDetail";
import CopySaved from "./CopySaved";
import Stats from "./Stats";
import MobileHeader from "../utils/MobileHeader";
import ResponseDetail from "./ResponseDetailView";
import ResponseList from "./ResponseListView";
import { getUser } from "../../actions/auth";
import { mapAuthErrorsStateToProps } from "../../utils";
import { connect } from "react-redux";
import BillingMessage from "../dashboard/billing/BillingMessage";

const Home = ({ auth, getUser }) => {
  const [search] = useSearchParams();
  const nav = useNavigate();

  useEffect(() => {
   
      getUser();
  
  }, [])

  const menu = {
    links: [
      {
        id: 1,
        name: "Dashboard",
        icon: "home-wifi.svg",
        path: "/",
      },
      {
        id: 2,
        name: "Signup Flows",
        icon: "forms.svg",
        path: "/form",
      },
      {
        id: 6,
        name: "CopyGuru",
        icon: "copyguruicon.svg",
        path: "/copy",
        icontwo:"superscript-ai.svg"
      },
      {
        id: 3,
        name: "Contacts",
        icon: "user-square.svg",
        path: "/contacts",
      },
      {
        id: 4,
        name: "Integrations",
        icon: "cloud-connection.svg",
        path: "/profile/integrations/all",
      },
      {
        id: 5,
        name: "Media Assets",
        icon: "box-2.svg",
        path: "/resources",
      }
    ],
  };
  const mobile = {
    links: [
      {
        id: 1,
        name: "Dashboard",
        icon: "home-wifi.svg",
        path: "/",
      },
      {
        id: 2,
        name: "Signup Flows",
        icon: "forms.svg",
        path: "/form",
      },
      {
        id: 5,
        name: "Contacts",
        icon: "user-square.svg",
        path: "/contacts",
      },
      {
        id: 5,
        name: "Profile",
        icon: "user-square.svg",
        path: "/profile",
      },
    ],
  };
  return (
    <React.Fragment>
      <Navbar />
      <MobileHeader menu={menu} type="dashboard" />
      <div className="container-fluid" stlye={{ padding: 0 }}>
        <div className="row">
          <SettingsSidebar menu={menu} type="dashboard" />
          <div className="col container-panel" style={{ padding: 0 }}>
          {(auth?.user?.transactions?.[0]?.is_trial  ||  auth?.user?.transactions?.[0]?.account_type.type === 'free' || auth?.user?.transactions?.[0]?.status === 'pending' ) && 
             ( <BillingMessage styleProps="customize" type={ auth?.user?.transactions[0]?.account_type.type  } isPending={auth?.user?.transactions?.[0]?.status}/>)}
            <Routes>
              <Route path="/" element={<Stats />} />
              <Route path="/form" element={<FormList />} />
              <Route path="/copy" element={<Copy />} />
              <Route path="/copy/saved" element={<CopySaved />} />
              <Route path="/copy/:slug" element={<CopyDetail />} />
              <Route path="/contacts" element={<ContactList />} />
              <Route path="/contacts/:id" element={<ContactDetail />} />

              <Route path="/form/responses/:id" element={<ResponseList />} />
              <Route
                path="/form/:id/responses/:response"
                element={<ResponseDetail />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, { getUser })(Home);