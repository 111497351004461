import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_DEV_URL } from "../../utils";
import { Check, X } from "react-feather";
import { CircularProgress } from "@mui/material";

const EmailConfirmation = () => {
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .post(`${BASE_DEV_URL}/auth/email/verify/` + params.token, {
        token: params.token,
      })
      .then((res) => {
        setIsValid(res.status === 200);
        setLoading(false);
      })
      .catch((err) => {
        setIsValid(false);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container login-wraper mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper px-4 py-5 pt-4">
          <div className="login-header forgot-password-header  ms-3 d-flex flex-column align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
              alt="formify-logo"
              className="img-fluid mb-2"
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {isValid ? (
                  <div className="green-circle d-flex justify-content-center align-items-center mt-4">
                    <Check
                      style={{
                        color: "white",
                        minHeight: "48px",
                        minWidth: "48px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="danger-circle d-flex justify-content-center align-items-center mt-4">
                    <X
                      style={{
                        color: "white",
                        minHeight: "48px",
                        minWidth: "48px",
                      }}
                    />
                  </div>
                )}

                <h4 className="text-center">
                  {isValid ? "Email Verified" : "Verify Email Address"}
                </h4>
                <p className="text-center">
                  {isValid
                    ? "We have managed to successfully confirm your email. Now you can access the platform!"
                    : "The token is expired resend the link down here"}
                </p>
              </>
            )}
          </div>

          <form>
            <div className="submit-btn ">
              {isValid ? (
                <Link to="/login">
                  <button>Login</button>
                </Link>
              ) : (
                <Link to="/resend/verification">
                  <button>Resend Email Confirmation</button>
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
