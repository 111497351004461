import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  setFormStatesDispatcher,
  setWidgetResponsesDispatcher,
} from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { AlertCircle } from "react-feather";
import Product from "../../form/widget-components/Product";
import _ from "lodash";
import { useState } from "react";
import {
  checkResponsiveProperties,
  checkResponsiveStyle,
  checkResponsiveTwoPropertyStyle,
  getResponsiveStyle,
} from "../responsiveFunctions";
import { isLastStep } from "../formBackendFunctions";

export const UpsellRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError, form },
  handleResponse,
  setFormStatesDispatcher,
  activeStep,
}) => {
  const [showButton, setShowButton] = useState(true);
  const getProduct = () => {
    if (!isEditable && form.store_selected !== "shopify") {
      if (responses[element.content.product_widget]?.value?.length > 0) {
        let upsell_product = null;

        element.content.product_match.forEach((pm) => {
          if (
            pm.product.toString() ===
            responses[element.content.product_widget].value
          ) {
            upsell_product = pm.upsell_product;
          }
        });
        return upsell_product;
      }

      return;
    } else if (!isEditable && form.store_selected === "shopify") {
      if (responses[element.content.product_widget].value.length > 0) {
        let upsell_product = null;
        if (responses[element.content.product_widget].product_id) {
          element.content.product_match.forEach((pm) => {
            if (
              pm.product.toString() ===
              responses[element.content.product_widget].product_id.toString()
            ) {
              upsell_product = pm.upsell_product;
            }
          });
        }

        return upsell_product;
      }

      return;
    }

    return;
  };
  const product = !isEditable
    ? getProduct()
    : element.content?.product_match?.length > 0
    ? element.content?.product_match[0]?.upsell_product
    : null;

  if (!product && responses && !isEditable) {
    // setWidgetResponsesDispatcher(element._id, { value: {} });
  }

  const getRechargeProductImage = (product) => {
    if (product && _.isObject(product)) {
      if (_.isArray(product.images) && product?.images?.length > 0) {
        return product.images[0].original;
      } else {
        return product.images.original;
      }
    } else {
      return (
        process.env.PUBLIC_URL + "/assets/images/icons/no-image-available.svg"
      );
    }
  };

  let alignment = getResponsiveStyle(element?.style, screen, "textAlign", null);
  let verticalAlignment = getResponsiveStyle(
    element?.style,
    screen,
    "verticalAlignment",
    null
  );
  let layout = getResponsiveStyle(element?.style, screen, "layout", null) || 1;
  let fontSize =
    getResponsiveStyle(element?.style, screen, "fontSize", null) || "";

  let noButtonStyle = checkResponsiveProperties(
    element.style,
    screen,
    "buttons.noButton"
  );
  let yesButtonStyle = checkResponsiveProperties(
    element.style,
    screen,
    "buttons.yesButton"
  );

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            {(form.store_selected != "woocommerce" ||
              (form.store_selected == "woocommerce" &&
                !responses[element._id]?.value)) && (
              <div className={"widget-inner-container"}>
                <div
                  className="row   pt-3  pb-3 rounded product-list"
                  style={{
                    alignItems:
                      alignment === "left"
                        ? "start"
                        : alignment === "right"
                        ? "end"
                        : alignment,
                  }}
                >
                  {form.store_selected ? (
                    product ? (
                      !isEditable ? (
                        <>
                          <div className="col-lg-12 upsell-text row">
                            <div className="col-lg-6 upsell-image">
                              <img
                                className="img-fluid"
                                src={
                                  form.store_selected === "shopify"
                                    ? product.images &&
                                      product?.images?.length > 0
                                      ? product.images[0].src
                                      : process.env.PUBLIC_URL +
                                        "/assets/images/icons/no-image-available.svg"
                                    : form.store_selected === "subbly"
                                    ? product.images &&
                                      product?.images?.length > 0 
                                      ? product.images[0].url 
                                      : process.env.PUBLIC_URL +
                                        "/assets/images/icons/no-image-available.svg" 
                                    : form.store_selected === "recharge"
                                    ? getRechargeProductImage(product)
                                    : product.images &&
                                      product?.images?.length > 0
                                    ? product.images[0].src
                                    : process.env.PUBLIC_URL +
                                      "/assets/images/icons/no-image-available.svg"
                                }
                                alt={product.title}
                                style={getResponsiveStyle(
                                  element?.style,
                                  screen,
                                  "image",
                                  null
                                )}
                              />
                            </div>
                            <div
                              className="col-lg-6 upsell-content"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: verticalAlignment,
                                alignItems:
                                  alignment === "left"
                                    ? "start"
                                    : alignment === "right"
                                    ? "end"
                                    : alignment,
                              }}
                            >
                              <p
                                className="upsell-description f"
                                style={{
                                  ...checkResponsiveStyle(
                                    element.style,
                                    screen,
                                    "description"
                                  ),
                                  fontSize: fontSize,
                                  textAlign: alignment,
                                }}
                              >
                                {element.content.description}
                              </p>

                              <div className="btn-group mt-3 d-flex flex-row justify-content-center">
                                {!showButton ? (
                                  <h4 className="formify-h4">
                                    Continue Finishing Your Order!
                                  </h4>
                                ) : (
                                  <>
                                    <button
                                      className="formify-btn m-0 yes-upsell"
                                      style={{
                                        ...checkResponsiveProperties(
                                          element.style,
                                          screen,
                                          "buttons.yesButton"
                                        ),
                                        fontSize: fontSize,
                                        textAlign: alignment,
                                      }}
                                      onClick={() => {
                                        setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            value:
                                              form.store_selected === "shopify"
                                                ? product.variants.length > 0
                                                  ? product.variants[0].id || ""
                                                  : product.id
                                                : product.id,
                                            product_id: product.id,
                                          },
                                          "form_upsell"
                                        );
                                        setShowButton(false);
                                        if (form.steps.length === activeStep) {
                                          handleResponse(true);
                                        }
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      style={{
                                        ...checkResponsiveProperties(
                                          element.style,
                                          screen,
                                          "buttons.noButton"
                                        ),
                                        fontSize: fontSize,
                                        textAlign: alignment,
                                      }}
                                      className="invert-formify-btn m-0"
                                      onClick={() => {
                                        setWidgetResponsesDispatcher(
                                          element._id,
                                          {
                                            value: "",
                                          },
                                          "form_upsell"
                                        );
                                        setShowButton(false);
                                        if (form.steps.length === activeStep) {
                                          handleResponse(true);
                                        }
                                      }}
                                    >
                                      No
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Product
                          product_id={product}
                          view={layout}
                          storeSelected={form.store_selected}
                          publicView={!isEditable}
                          rdyProduct={product}
                          style={checkResponsiveStyle(
                            element.style,
                            screen,
                            null
                          )}
                          element={element}
                          form={form}
                          upsell={true}
                          screen={screen}
                        />
                      )
                    ) : !isEditable ? (
                      <div className="col-12 text-center no-upsell">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/info-circle.svg"
                          }
                          alt="no_upsell"
                          width={50}
                        />
                        <p>Oooopps….</p>
                        <p>
                          {" "}
                          Seems like this is currently unavailable, please
                          choose a different product and try again!
                        </p>
                      </div>
                    ) : (
                      <div className="col-12">
                        <h4 className="formify-h4">No products selected </h4>
                        <div
                          className="d-flex flex-row alert-danger align-items-center p-2"
                          style={{ borderRadius: "7px" }}
                        >
                          <AlertCircle className="me-3" />
                          <p className="formify-p">
                            You have no products selected, edit this widget and
                            select your favorite products.
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="col-12">
                      <h4 className="formify-h4">Store is not connected</h4>
                      <div
                        className="d-flex flex-row alert-danger align-items-center p-2"
                        style={{ borderRadius: "7px" }}
                      >
                        <AlertCircle className="me-3" />
                        <p className="formify-p">
                          To use this widget you need to connect a store. Go to
                          <span
                            style={{ color: "#6563ff", cursor: "pointer" }}
                            onClick={() =>
                              setFormStatesDispatcher(true, "openIntegrations")
                            }
                          >
                            {" "}
                            Integrations
                          </span>{" "}
                          and select your favorite store.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {form.store_selected == "woocommerce" &&
              (responses[element._id]?.value || isEditable) &&
              product && (
                <>
                  {!isEditable && (
                    <p
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setWidgetResponsesDispatcher(
                          element._id,
                          {
                            value: "",
                          },
                          "form_product"
                        );
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="-4.5 0 20 20"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-345.000000, -6679.000000)"
                            fill="#000000"
                          >
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              <path d="M299.633777,6519.29231 L299.633777,6519.29231 C299.228878,6518.90256 298.573377,6518.90256 298.169513,6519.29231 L289.606572,6527.55587 C288.797809,6528.33636 288.797809,6529.60253 289.606572,6530.38301 L298.231646,6538.70754 C298.632403,6539.09329 299.27962,6539.09828 299.685554,6538.71753 L299.685554,6538.71753 C300.100809,6538.32879 300.104951,6537.68821 299.696945,6537.29347 L291.802968,6529.67648 C291.398069,6529.28574 291.398069,6528.65315 291.802968,6528.26241 L299.633777,6520.70538 C300.038676,6520.31563 300.038676,6519.68305 299.633777,6519.29231"></path>
                            </g>
                          </g>
                        </g>
                      </svg>{" "}
                      <span className="ms-2">Back</span>
                    </p>
                  )}
                  {product?.attributes ? (
                    product?.attributes?.map((attr) => {
                      const select_name = attr.name
                        .toLowerCase()
                        .replaceAll(" ", "-");
                      return (
                        <div className="col-lg-12 input-holder">
                          <h4
                            className="formify-h4"
                            style={{
                              ...checkResponsiveStyle(
                                element.style,
                                screen,
                                "attr_label"
                              ),
                              fontFamily:
                                element?.style?.desktop?.attr_label?.fontFamily
                                  ?.name,
                            }}
                          >
                            {attr.name}
                          </h4>
                          <select
                            value={responses[element._id][select_name]}
                            onChange={(e) => {
                              setWidgetResponsesDispatcher(
                                element._id,
                                {
                                  [select_name]: e.target.value,
                                },
                                "form_product"
                              );
                            }}
                            style={{
                              ...checkResponsiveStyle(
                                element.style,
                                screen,
                                "attr_input"
                              ),
                              fontFamily:
                                element?.style?.desktop?.attr_label?.fontFamily
                                  ?.name,
                            }}
                          >
                            <option value="">Choose</option>
                            {attr.options.map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-lg-12 input-holder">
                      <h4
                        className="formify-h4"
                        style={{
                          ...checkResponsiveStyle(
                            element.style,
                            screen,
                            "attr_label"
                          ),
                          fontFamily:
                            element?.style?.desktop?.attr_label?.fontFamily
                              ?.name,
                        }}
                      >
                        Attribute name
                      </h4>
                      <select
                        style={{
                          ...checkResponsiveStyle(
                            element.style,
                            screen,
                            "attr_input"
                          ),
                          fontFamily:
                            element?.style?.desktop?.attr_label?.fontFamily
                              ?.name,
                        }}
                      >
                        <option>Attribute option</option>
                      </select>
                    </div>
                  )}
                </>
              )}
            {!isEditable && responseError ? (
              Object.keys(responseError).length > 0 ? (
                <p
                  className="error-message"
                  style={{
                    fontFamily: element.style?.desktop?.label?.fontFamily?.name,
                  }}
                >
                  {responseError[element._id]}
                </p>
              ) : null
            ) : null}
            {isEditable && (
              <WidgetEditTabs
                element={element}
                editElement={editElement}
                showDuplicateButton={false}
              />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
  setFormStatesDispatcher,
})(UpsellRender);
