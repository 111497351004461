import React from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../utils";
import SettingsSidebar from "../dashboard/SettingsSidebar";
import SimpleNavbar from "../dashboard/SimpleNavbar";
import MobileHeader from "../utils/MobileHeader";
import { Link } from "react-router-dom";
import FontList from "../dashboard/fonts/FontList";
import UploadFont from "../dashboard/fonts/UploadFont";
import MediaLibrary from "./MediaLibrary";
import BillingMessage from "../dashboard/billing/BillingMessage";

const Resources = ({ auth }) => {
  const menu = {
    links: [
      {
        id: 1,
        header: "Media Library",
        sublinks: [
          {
            id: 1,
            name: "Media gallery",
            path: "/resources",
          }
        ]
      },
      {
        id: 2,
        header: "My Fonts",
        sublinks: [
          {
            id: 1,
            name: "Upload Fonts",
            path: "/resources/fonts/upload",
          },
          {
            id: 2,
            name: "My Fonts",
            path: "/resources/fonts",
          },
       
        ],
      },
    ],
  };

  const mobile = {
    links: [
      {
        id: 1,
        name: "Dashboard",
        icon: "home-wifi.svg",
        path: "/",
      },
      {
        id: 2,
        name: "Signup Flows",
        icon: "forms.svg",
        path: "/form",
      },
      {
        id: 5,
        name: "Contacts",
        icon: "user-square.svg",
        path: "/contacts",
      },
      { 
        id: 5,
        name: "Profile",
        icon: "user-square.svg",
        path: "/profile",
      },
    ],
  };
  return (
    <React.Fragment>
      <SimpleNavbar />
      <MobileHeader menu={mobile} type="dashboard" />
      <div className="container-fluid" stlye={{ padding: 0 }}>
        <div className="row">
          <SettingsSidebar menu={menu} type="fonts" />
          <div className="container-panel col profile" style={{ padding: 0 }}>
            {/* <PlanNotification /> */}
            {(auth?.user?.transactions?.[0]?.is_trial  ||  auth?.user?.transactions?.[0]?.account_type.type === 'free') 
            && ( <BillingMessage styleProps="customize"  type={ auth?.user?.transactions[0]?.account_type.type}/>)}
            <div className="profile-header-mob">
              {menu.links.map((menu) => {
                return (
                  <div key={menu.sublinks[0].id}>
                    <Link className="box-links" to={menu.sublinks[0].path}>
                      {menu.sublinks[0].name}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="container-fluid">
              <div className="row">
                <Routes>
                  <Route
                    path=""
                    element={
                      <div className="col-sm-12 col-md-8 col-lg-12">
                        <MediaLibrary />                        
                      </div>
                    }
                  />
                  <Route
                    path="/fonts"
                    element={
                      <div className="col-sm-12 col-md-8 col-lg-12">
                        <FontList />                        
                      </div>
                    }
                  />
                  <Route
                    path="/fonts/upload"
                    element={
                      <div className="col-sm-12 col-md-8 col-lg-6">
                        <UploadFont/>
                      </div>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps)(Resources);
