import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { clearErrors } from "../../../actions/errors";
import { connectRecharge } from "../../../actions/integrations";
import {
  getRechargeBigcommerceURL,
  getRechargeDefault,
  getRechargeShopifyURL,
} from "../../../constants";
import { mapAuthErrorsStateToProps } from "../../../utils";

const RechargePayment = ({ auth, connectRecharge, errors, clearErrors }) => {
  const [form, setForm] = useState({
    shop: auth.user
      ? auth.user.stores.recharge
        ? auth.user.stores.recharge.shop
        : ""
      : "",
    shop_name: auth.user
      ? auth.user.stores.recharge
        ? auth.user.stores.recharge.shop_name
        : ""
      : "",
  });

  const shops = ["shopify", "bigcommerce", "custom"];

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    connectRecharge(form);

    if (form.shop && form.shop_name) {
      switch (form.shop) {
        case "shopify":
          window.open(getRechargeShopifyURL(form.shop_name));
          break;
        case "bigcommerce":
          window.open(getRechargeBigcommerceURL(form.shop_name));

          break;
        default:
          window.open(getRechargeDefault(form.shop_name));

          break;
      }
    }
  };
  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shop = useRef(null);
  const shopName = useRef(null);

  return (
    <React.Fragment>
      <Overlay
        target={shop.current}
        show={
          errors.errors
            ? errors.errors["stores.recharge.shop"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.recharge.shop"]
                ? errors.errors["stores.recharge.shop"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={shopName.current}
        show={
          errors.errors
            ? errors.errors["stores.recharge.shop_name"] && form.shop
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.recharge.shop_name"]
                ? errors.errors["stores.recharge.shop_name"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <div className="row  pt-10">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/recharge.png"}
              alt="Shopify"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <h4 className="integration-page-header">
          Connect your form to Shopify
        </h4>
        <p className="grey-paragraph">
          Start by selecting the form you want to connect to your shopify store.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-5">
          <div className="formify-card">
            <h4 className="integration-page-header">
              Authenticate Recharge Account
            </h4>
            <p className="grey-paragraph">
              Enter the following information to authenticate your account.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="input-holder position-relative">
                      <label htmlFor="store">Shop provider</label>
                      <select
                        id="shop"
                        value={form.shop}
                        onChange={handleChange}
                        ref={shop}
                      >
                        <option value="">Select store</option>
                        {shops.map((shop, index) => {
                          return (
                            <option key={index} value={shop}>
                              {shop}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {form.shop ? (
                      <div className="input-holder position-relative">
                        <label htmlFor="store">Shop name</label>
                        <input
                          value={form.shop_name || ""}
                          onChange={handleChange}
                          id="shop_name"
                          ref={shopName}
                        />
                      </div>
                    ) : null}

                    <button type="submit" className="formify-btn mt-3">
                      Connect
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  connectRecharge,
  clearErrors,
})(RechargePayment);
