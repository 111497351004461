import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { siblingChecboxIndex } from "../../reducers/dynamicProdSlice";
import { mapAuthAndFormStateToProps } from "../../utils";
import {
  setFormStatesDispatcher,
  setWidgetResponsesDispatcher,
} from "../../actions/form";
import { connect } from "react-redux";

const DynamicProductCheckboxes = ({ form: { form, widget }, element }) => {
  const dispatch = useDispatch();
  const siblings = useSelector((state) => state.dynamicProd.siblingsNumber);
  const checkedSiblingIndex = useSelector(
    (state) => state.dynamicProd.siblingChecboxIndex
  );

  const handleCheckboxChange = (index) => {
    dispatch(siblingChecboxIndex(index));
  };

  const dynamicProductNames = form.steps
  .flatMap(step => step.elements)
  .filter(element => element.element_type === 'form_dynamic_product') 
  .flatMap(element => element.content?.siblingswithprods || [])
  .map(sibling => sibling.name);


let dynamicProductTitle = '';

form.steps.forEach(step => {
  step.elements.forEach(element => {
    if (element.element_type === "form_dynamic_product") {
      dynamicProductTitle = element.content.title;
    }
  });
});

  return (
    <div>
     

{!element.content.hide_title ? (
  <h4>{ dynamicProductTitle || "Select options"}</h4> 
) : null}
      
     
      <select
      className="dynamic-product-checkboxes-select"
        value={checkedSiblingIndex}
        onChange={(e) => handleCheckboxChange(parseInt(e.target.value, 10))}
      >
        {dynamicProductNames?.map((sibling, index) => (
          <option key={index} value={index}>
            {sibling || `Sibling ${index}`}
          </option>
        ))}
      </select>
      {/* {checkedSiblingIndex !== null && siblings[checkedSiblingIndex] && (
        <p>Checked Sibling: {siblings[checkedSiblingIndex]?.name}</p>
      )} */}
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
  setFormStatesDispatcher,
})(DynamicProductCheckboxes);
