/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  BASE_DEV_URL,
  getRemanningDays,
  mapAuthErrorsStateToProps,
} from "../../../utils";
import axios from "axios";
import { getUser, tokenConfig, updateUserRedux } from "../../../actions/auth";
import store from "../../../store";
import { Switch } from "@mui/material";
import PriceCard from "../../informativeWebsiteComponents/PriceCard";
import FormifyLoader from "../../utils/FormifyLoader";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BillingModal from "./BillingModal";

const PlanOptions = ({ auth, updateUserRedux, currentSubscription }) => {
  const nav = useNavigate();
  const [checked, setChecked] = useState("month");
  const [showModal, setShow] = useState(false);
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newPlan, setNewPlan] = useState(null);
  useEffect(() => {
    axios
      .get(
        `${BASE_DEV_URL}/subscription/planprices`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setPlans(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSwitchChange = (e) => {
    e.target.checked ? setChecked("year") : setChecked("month");
  };

  const redirectPaymentMethod = (plan) => {
    setLoading(true);

    //Check
    // if (!auth?.user?.transactions[0] || auth?.user?.transactions[0]?.is_trial || auth?.user?.transactions[0]?.status !== 'active') {

    axios
      .post(
        `${BASE_DEV_URL}/subscription/checkout/${plan.id}`,
        {},
        tokenConfig(store.getState)
      )
      .then((res) => {
        if (res.data.success) {
          //Check if is Trial
          if (res.data.type === "trial") {
            updateUserRedux(res.data.user);
            setLoading(false);

            if (auth.user.register_type.toUpperCase() !== "SHOPIFY") {
              nav("/");
            } else {
              window.location.href = res.data.url;
            }
          } else {
            window.location.href = res.data.url;
          }
        }
      })
      .catch((err) => {
        if (auth.user.register_type.toUpperCase() === "SHOPIFY") {
          //Inform User that his shopify integration has error
          toast.error(
            "Please fix your Shopify Integration to continue the process."
          );
          setLoading(false);
        } else toast.error("Please try again later or contact support!.");
        //If user is shopify
      });
    // } else {
    //   setShow(true);
    //   setNewPlan(plan);
    // }

    setLoading(false);
  };

  const updateSubscription = async (price) => {
    setLoading(true);
    setShow(false);
    try {
      await axios
        .post(
          `${BASE_DEV_URL}/subscription/updateSubscription`,
          { price },
          tokenConfig(store.getState)
        )
        .then((res) => {
          getUser();

          if (auth.user.register_type.toUpperCase() !== "SHOPIFY") {
            nav("/profile/billing");

            toast.success("Plan is changed");
          } else {
            if (price == "price_1LdWtoLvFUx8hFgEmtWzq3ho") {
              nav("/profile/billing");
            } else {
              window.location.href = res.data.url;
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const isCurrentSubscription = (planId) => {
    if (auth?.user?.transactions[0]) {
      if (planId === auth?.user?.transactions[0].account_type._id) {
        if (
          checked === "month" &&
          auth?.user?.transactions[0].billing_cycle === 1
        )
          return true;
        else if (
          checked === "year" &&
          auth?.user?.transactions[0].billing_cycle === 12
        )
          return true;
        return false;
      } else return false;
    } else return false;
  };

  const getFreePlan = useMemo(() => {
    const freePlan = plans?.["free"][0];
    return freePlan;
  }, [plans]);

  return (
    <React.Fragment>
      <div className="price-container">
        <div className="price-text">
          <h1>Start building today and save hours from the process.</h1>
          <p>
            {" "}
            Become a Pro or Premium Member and get access to exclusive features
            that will help your form convert better and faster.
          </p>
          <div className="switch-comp">
            <div>
              <p>Pay Monthly</p>
              <Switch
                onChange={handleSwitchChange}
                checked={checked === "month" ? false : true}
                handleDiameter={20}
              />
              <p>Pay Yearly</p>
            </div>
            <div className="save-data">
              <p className="orange-ribbon">Save 30%</p>
            </div>
          </div>
        </div>
        <div className="bottom-image">
          <div className="price-wrapper">
            {plans !== null && !loading ? (
              plans?.[checked]
                .filter((plan) => plan.product?.name !== "Formify Enterprise")
                .map((plan, index) => (
                  <div className="price-card pricecard" key={index}>
                    <PriceCard
                      auth={auth}
                      plan={plan}
                      type={checked}
                      updateSubscription={updateSubscription}
                      isCurrentSubscription={isCurrentSubscription(
                        plan?.info?._id
                      )}
                      redirectPaymentMethod={redirectPaymentMethod}
                    />
                  </div>
                ))
            ) : (
              <div className="col-12">
                {auth.user.used_trial ? (
                  <FormifyLoader
                    removeLogo={true}
                    text={`Your checkout process will be made through  ${auth.user.register_type.toUpperCase()}.`}
                  />
                ) : (
                  <FormifyLoader removeLogo={true} text={"Please wait.."} />
                )}
              </div>
            )}
          </div>
        </div>
        {auth?.user?.transactions[0]?.account_type.type != "free" && (
          <p className="freePlan">
            Try Formify for free.{" "}
            <span
              onClick={() => {
                // redirectPaymentMethod(getFreePlan);
                redirectPaymentMethod(getFreePlan);
              }}
            >
              Start on a free plan!
            </span>
          </p>
        )}
        {auth.user.used_trial ? (
          <p className="purple">
            Payment will be made through{" "}
            <span>{auth.user.register_type.toUpperCase()}</span> checkout.
          </p>
        ) : (
          <p>
            Start your free trial today! Simply choose one of our plans and
            begin exploring our features and services.
          </p>
        )}
        <BillingModal
          show={showModal}
          setShow={setShow}
          updateSub={updateSubscription}
          currentPlan={auth?.user?.transactions[0]}
          newPlan={newPlan}
          textContent={
            newPlan &&
            newPlan?.unit_amount / 100 >
              auth?.user?.transactions[0]?.price / 10000
              ? `If you upgrade, ${
                  newPlan ? newPlan?.product.name : null
                } will start
            immediately. At current period end you will be charged for 
            ${getRemanningDays()} days remaning in  ${
                  newPlan ? newPlan?.product?.name : null
                } and the new month invoice`
              : `If you downgrade, ${
                  auth?.user?.transactions[0]
                    ? auth?.user?.transactions[0]?.plan_name
                    : null
                } will start
            immediately . The downgrade will generate a credit to be applied to
            the next invoice`
          }
          type={
            newPlan &&
            newPlan?.unit_amount / 100 >
              auth?.user?.transactions[0]?.price / 10000
              ? "upgrade"
              : "downgrade"
          }
        />
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, { updateUserRedux })(
  PlanOptions
);
