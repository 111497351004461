import FormifyButton from "./FromifyButton";
import { Edit, Trash, Copy } from "react-feather";
import { duplicateElement, deleteElement } from './formFunctions'

import { useParams } from 'react-router-dom';
import { mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setFormStatesDispatcher } from "../../actions/form";
import { useContext, useState } from "react";
import { EditTabContext } from '../form/FormCanvas';

const WidgetEditTabs = ({
  element, 
  editElement,
  showDuplicateButton=true,
  form: { form },
  duplicateElement,
  deleteElement,
  setFormStatesDispatcher,
  
}) => {
  const { showEditTab } = useContext(EditTabContext);
  const { isActive, handleWidgetClick } = useContext(EditTabContext);
  const params = useParams()
  return (
    <ul className={`edit-tab ${isActive ? 'edit-tab-shown' : 'edit-tab-hidden'}`}>
      <li>
        <FormifyButton 
          type="button"
          onClick={() => {
            editElement({ ...element });
            setFormStatesDispatcher(true, "openEditWidget")
          }}
          icon={ <Edit className="edit-icon" /> }
        />
      </li>
      { showDuplicateButton && (
        <li className="position-relative">
          <FormifyButton 
            type="button"
            onClick={() => form.steps.length == 1 && duplicateElement({ ...element }, element.order, params, form)}
            size="small"
            icon={ <Copy className="edit-icon" /> }
          />
          { form.steps.length > 1 && (
            <ul className="edit-tab">
              { [...Array(form.steps.length)].map((_, i) => (
                <li key={i}>
                  <span onClick={() => duplicateElement({ ...element }, element.order, { step: i+1 }, form)}>Step {(i + 1)}</span>
                </li>
              ))}
            </ul>
          )}
        </li>
      )}
      <li>
        <FormifyButton 
          onClick={(e) => {
            e.stopPropagation();
            deleteElement(element, element.element_type === "product_widget" ? "product_widget" : "", params.step, true, params, form);
            deleteElement(element, element.element_type === "dynamic_product_widget" ? "dynamic_product_widget" : "", params.step, true, params, form);
            setFormStatesDispatcher(true, "openWidgets")
          }}
          size="small"
          icon={ <Trash className="edit-icon delete" /> }
        />
      </li>
    </ul>
  )
};

export default connect(mapAuthAndFormStateToProps, { duplicateElement, deleteElement, setFormStatesDispatcher })(WidgetEditTabs);
 