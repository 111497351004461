import React, { useState } from 'react'
import { Info } from 'react-feather';

function InfoIcon(props) {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <>   
        <div
    className="flow-id-text"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
     <Info />

  </div>
  {isHovered && (
    <div
      className="info-icon-content"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
         <div class="info-icon-content-arrow"></div>
      <p>
      Adding a title makes identifying the widget easier when integrating your CRM or Shop.
      </p>
    </div>
  )}
  </>
  )
}

export default InfoIcon