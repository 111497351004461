import React from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../utils";
import ChangePasswordForm from "../dashboard/profile/ChangePasswordForm";
import IntegrationList from "../dashboard/profile/IntegrationList";
import ProfileCard from "../dashboard/profile/ProfileCard";
import SettingsSidebar from "../dashboard/SettingsSidebar";
import PlanBilling from "./PlanBilling";
import SalesForceIntegration from "../dashboard/integration/salesforce";
import HubspotOauth from "../dashboard/integration/hubspot";
import MailChimpOauth from "../dashboard/integration/mailchimp";
import SimpleNavbar from "../dashboard/SimpleNavbar";
import MobileHeader from "../utils/MobileHeader";
import { Link } from "react-router-dom";
import ShopifyOAuth from "../dashboard/integration/ShopifyOAuth";
import RechargeOauthCallback from "../dashboard/integration/RechargePaymentCallback";
import BillingMessage from "../dashboard/billing/BillingMessage";

const Profile = ({ auth }) => {
  const location = useLocation();
  
  //Get Nav Active Route
  const menu = {
    links: [
      {
        id: 1,
        header: "Account",
        sublinks: [
          {
            id: 1,
            name: "Account Details",
            path: "/profile",
          },
        ],
      },
      {
        id: 2,
        header: "Security",
        sublinks: [
          {
            id: 1,
            name: "Change Password",
            path: "/profile/change/password",
          },
        ],
      },
      {
        id: 3,
        header: "Billing",
        sublinks: [
          {
            id: 1,
            name: "Plan & Billing",
            path: "/profile/billing",
          },
        ],
      },
      {
        id: 4,
        header: "Integrations",
        sublinks: [
          {
            id: 1,
            name: "Connect",
            path: "/profile/integrations/all",
          },
        ],
      },
    ],
  };

  const mobile = {
    links: [
      {
        id: 1,
        name: "Dashboard",
        icon: "home-wifi.svg",
        path: "/",
      },
      {
        id: 2,
        name: "Forms",
        icon: "forms.svg",
        path: "/form",
      },
      {
        id: 5,
        name: "Contacts",
        icon: "user-square.svg",
        path: "/contacts",
      },
      {
        id: 5,
        name: "Profile",
        icon: "user-square.svg",
        path: "/profile",
      },
    ],
  };
  return (
    <React.Fragment>
      <SimpleNavbar />
      <MobileHeader menu={mobile} type="dashboard" />
      <div className="container-fluid" stlye={{ padding: 0 }}>
        <div className="row">
          {(location.pathname !== "/profile/billing/plans" && location.pathname.slice(0, location.pathname.lastIndexOf('/')) !== "/profile/billing") && <SettingsSidebar menu={menu} type="profile" />}
          <div className={`${(location.pathname !== "/profile/billing/plans" && location.pathname.slice(0, location.pathname.lastIndexOf('/')) !== "/profile/billing") ? "container-panel col profile" : "pt50"}`}>
            {(auth?.user?.transactions?.[0]?.is_trial  ||  auth?.user?.transactions?.[0]?.account_type.type === 'free') && 
            ( <BillingMessage styleProps = {location.pathname !== "/profile/billing/plans" ? "customize" : ""}  type={ auth?.user?.transactions[0]?.account_type.type}/>)}

            <div className="profile-header-mob ">
              {menu.links.map((menu) => {
                return (
                  <div key={menu.id}>
                    <Link className="box-links" to={menu.sublinks[0].path}>
                      {menu.sublinks[0].name}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="container-fluid">
              <div className="row">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <div className="col-sm-12 col-md-8 col-lg-6">
                        <ProfileCard />
                      </div>
                    }
                  />
                  <Route
                    path="/change/password"
                    element={
                      <div className="col-sm-12 col-md-8 col-lg-6">
                        <ChangePasswordForm />
                      </div>
                    }
                  />

                  <Route
                    path="/integrations/:id"
                    element={
                      <div className="col-lg-12">
                        <IntegrationList account_type={auth.user.transactions[0]?.account_type} />
                      </div>
                    }
                  />
                  <Route
                    path="/integrations/crm/salesforce"
                    element={
                      <div className="col-lg-12">
                        <SalesForceIntegration />
                      </div>
                    }
                  />

                  <Route
                    path="/integrations/shop/shopify"
                    element={
                      <div className="col-lg-12">
                        <ShopifyOAuth />
                      </div>
                    }
                  />
                  <Route
                    path="/integrations/crm/hubspot"
                    element={
                      <div className="col-lg-12">
                        <HubspotOauth />
                      </div>
                    }
                  />
                  <Route
                    path="/integrations/crm/mailchimp"
                    element={
                      <div className="col-lg-12">
                        <MailChimpOauth />
                      </div>
                    }
                  />
                  <Route
                    path="/integrations/shop/recharge"
                    element={
                      <div className="col-lg-12">
                        <RechargeOauthCallback />
                      </div>
                    }
                  />
                  <Route
                    path="/billing/*"
                    element={
                      <div className="col-lg-12">
                        <PlanBilling />
                      </div>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps)(Profile);
