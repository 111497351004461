/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { AlertCircle, Plus } from "react-feather";
import AlignmentTabs from "../single-components/AlignmentTabs";
import { BASE_DEV_URL, getProductWidget } from "../../../../utils";
import _ from "lodash";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setFormStatesDispatcher,
  setWidgetDispatcher,
} from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import VerticalAligns from "../single-components/VerticalAligns";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import InputField from "../single-components/InputField";
import FormifyInput from "../single-components/FormifyInput";
import {
  checkFontSize,
  getResponsiveStyle,
} from "../../../utils/responsiveFunctions";
import { setRechargeProd } from "../../../../reducers/rechargeProdSlice";
import { useCallback } from "react";
import axios from "axios";
import { tokenConfig } from "../../../../actions/auth";
import store from "../../../../store";
import { toast } from "react-toastify";
import SelectReact from "react-select";
import { useRef } from "react";
// import rechargeimg from "../../../../../public/assets/images/recharge.png"
const UpsellContent = ({
  form: { form, widget, responsive },
  setWidgetDispatcher,
  storeToMethodMap,
  setFormStatesDispatcher,
}) => {
  const [products, setProducts] = useState([]);
  const [productWidgetProducts, setProductWidgetProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectOptions, setSelectOptions] = useState({});

  const [searchVal, setSearchVal] = useState("");
  const [subblyLoading, setSubblyLoading] = useState(false);
  const selectRef = useRef(null);
  const [scrollEventListener, setScrollEventListener] = useState(null);

  const [selectValues, setSelectValues] = useState(
    Array(widget.content.product_match.length).fill(null)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 250,
      },
    },
  };

  const dispatch = useDispatch();
  const rechargeProd = useSelector((state) => state.rechargeProd);
  const handleCheckboxChange = () => {
    setProducts([]);
    dispatch(setRechargeProd(!rechargeProd));
    getShopifyProducts();
  };
  const getSubblyProducts = useCallback(async (page) => {
    setSubblyLoading(true);
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/subbly/products?page=${page}`,
        tokenConfig(store.getState)
      );

      return result.data;
    } catch (error) {
      toast.error("There is something wrong with your Subbly connection.");
    } finally {
      setSubblyLoading(false);
    }
  }, []);
  const getShopifyProducts = useCallback(
    async (rechargeProd, page = 1, lastproduct,  after = "") => {
      setSubblyLoading(true);
      try {
        let result;
        if (rechargeProd) {
          result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/products?recharge=true&page=${page}`,
            tokenConfig(store.getState)
          );
        } else {
          result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/products?${
              after ? "after=" + after : ""
            }`,
            tokenConfig(store.getState)
          );
        }

        return result;
      } catch (error) {
        toast.error("There is something wrong with your Shopify connection.");
      } finally {
        setSubblyLoading(false);
      }
    },
    []
  );
  // useEffect(() => {
  //   if (form.store_selected) {
  //     if (form.store_selected !== "subbly") {
  //       storeToMethodMap[form.store_selected]().then((res) => {
  //         setProducts(res.data.products);
  //       });
  //     } else {
  //       storeToMethodMap[form.store_selected]().then((res) => {
  //         setProducts(res);
  //       });
  //     }
  //   } else {
  //     setProducts([]);
  //   }
  // }, []);

  useEffect(() => {
    if (form.store_selected) {
      if (form.store_selected !== "subbly") {
        storeToMethodMap[form.store_selected]().then((res) => {
          let productList = [];
          res?.data?.products.map((product) => {
            productList.push({
              key: product.id.toString(),
              label: productName(product),
            });
          });
          setProducts((prevProducts) => [...prevProducts, ...productList]);
          setLoading(false);
        });
      } else {
        storeToMethodMap[form.store_selected]().then((res) => {
          let productList = [];
          res?.data?.products.forEach((product) => {
            productList.push({
              key: product.id,
              label: product.name,
            });
          });
          setProducts((prevProducts) => [...prevProducts, ...productList]);
          setLoading(false);
        });
      }
    } else {
      setProducts([]);
      setLoading(false);
    }
  }, [
    form.store_selected,
    rechargeProd,
    getSubblyProducts,
    getShopifyProducts,
  ]);

  const selectedProd = (selectedOptions) => {
    const value = selectedOptions.key;

    handleSetWidgetDispatcher(
      [`content.products`, `content.products_objects_array`],
      [value, selectedOptions],
      widget,
      setWidgetDispatcher
    );
  };

  const loadMoreProducts = useCallback(() => {
    setLoading(true);
    if (form.store_selected === "shopify") {
      let lastProductId = products[products.length - 1]
        ? products[products.length - 1]
        : null;

      getShopifyProducts(rechargeProd, currentPage, lastProductId)
        .then((data) => {
          let newProducts = rechargeProd
            ? data.data.data.products
            : data.data.products;

          let productList = [];
          newProducts.forEach((product) => {
            productList.push({
              key: product.id.toString(),
              label: rechargeProd ? product.title : product.title,
            });
          });

          if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...productList]);

            setCurrentPage(currentPage + 1);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (form.store_selected === "subbly") {
      getSubblyProducts(currentPage + 1)
        .then((data) => {
          const newProducts = data.products;

          let productList = [];
          newProducts.forEach((product) => {
            productList.push({
              key: product.id.toString(),
              label: product.name,
            });
          });

          if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...productList]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentPage, getShopifyProducts, getSubblyProducts, rechargeProd]);

  const handleSelectChange = (selectedOptions, index) => {
    const updatedSelectValues = [...selectValues];
    updatedSelectValues[index] = selectedOptions;
    setSelectValues(updatedSelectValues);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = selectRef.current;
    const isScrollCloseToEnd = scrollTop + clientHeight + 20 >= scrollHeight;

    if (isScrollCloseToEnd && !subblyLoading) {
      loadMoreProducts();
    }
  };
  useEffect(() => {
    if (selectRef.current) {
      const scrollEventListener = () => handleScroll();
      selectRef.current.addEventListener("scroll", scrollEventListener);
      setLoading(true);
      setScrollEventListener(scrollEventListener);
    }

    return () => {
      if (selectRef.current && scrollEventListener) {
        selectRef.current.removeEventListener("scroll", scrollEventListener);
        setLoading(true);
      }
    };
  }, [currentPage, handleScroll]);

  useEffect(() => {
    if (form) {
      const productWidget = getProductWidget(form);

      const widgetProducts = [];
      if (productWidget && products?.length > 0) {
        productWidget.content.products.forEach((prod) => {
          // const foreignIndex = _.findIndex(products, (p) => {
          //   return p.id.toString() === prod.toString();
          // });
          // if (foreignIndex !== -1) {
          //   widgetProducts.push(products[foreignIndex]);
          // }
        });

        setProductWidgetProducts(productWidget.content.products_objects_array);
      }
    }
  }, [products, form]);

  const productName = (product) => {
    if (product) {
      switch (form.store_selected) {
        case "shopify":
          return product?.label ? product.label : product.title;
        case "recharge":
          return product.title;
        case "woocommerce":
          return product.name;
        case "bigcommerce":
          return product.name;
        case "subbly":
          return product.name;
        default:
          return "No name provided";
      }
    } else {
      return "";
    }
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };
  const updateProductMatching = (value, index, prod) => {
    if (widget) {
      let product_match = [];
      if (prod) {
        product_match = _.cloneDeep(widget.content.product_match);
        product_match = product_match.map((pm, pmIndex) => {
          if (pmIndex === index) {
            pm.product = value;
            return pm;
          } else return pm;
        });
      } else {
        product_match = _.cloneDeep(widget.content.product_match);
        product_match = product_match.map((pm, pmIndex) => {
          if (pmIndex === index) {
            pm.upsell_product = value.value;
            return pm;
          }

          return pm;
        });
      }
      handleSetWidgetDispatcher(
        [`content.product_match`],
        [product_match],
        widget,
        setWidgetDispatcher
      );
    }
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher(
      [`content.description`],
      [e.target.value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const handleChangeUpsell = (e, type) => {
    handleSetWidgetDispatcher(
      [`content.yesUpsell`],
      [e.target.value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };
  const handleChangeNoUpsell = (e, type) => {
    handleSetWidgetDispatcher(
      [`content.noUpsell`],
      [e.target.value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const updateFontFamily = (name, url) => {
    handleSetWidgetDispatcher(
      [`style.desktop.fontFamily`],
      [
        { name: name, src: url },
        { name: name, src: url },
      ],
      widget,
      setWidgetDispatcher,
      true
    );
  };
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      if (form.store_selected === "shopify") {
        try {
          const result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/product/${searchVal}`,
            tokenConfig(store.getState)
          );
          const product = result.data.product;

          const updatedSelectOptions = [...products];

          updatedSelectOptions.push({
            key: product.key,
            value: product.label,
            label: product.label,
          });

          setSelectOptions(updatedSelectOptions);
          setSearchVal("");
        } catch (error) {
          console.log(error);
          toast.error("An error happened while searching for the product!");
        }
      }
    }
  };

  const updateFontSize = (e, type) => {
    var value =
      e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher(
      [`style.${responsive}.${e.target.id}`],
      [value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  useEffect(() => {
    setSelectOptions(
      products.map((product, index) => ({
        key: product.key,
        value: product.key,
        label: product.label,
      }))
    );
  }, [products]);

  return widget ? (
    <div className="settings-field-container">
      <div className="editable-field ">
        <div className="shopify-recharge">
          <p className="field-heading widget-label">Products</p>
          {form.store_selected === "shopify" ? (
            <div className="recharge-select">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/rechargeicon.svg"
                }
                className={`nav-icons ${rechargeProd ? "checked" : ""}`}
                alt=""
                onClick={handleCheckboxChange}
                style={{ cursor: "pointer" }}
              />
              <p
                className="recharge-field"
                onClick={handleCheckboxChange}
                style={{
                  cursor: "pointer",
                  color: rechargeProd ? "#E51B49" : "#3901F1",
                }}
              >
                {rechargeProd
                  ? "Disable Recharge Filter"
                  : "Filter Recharge Products"}
              </p>

              <input
                id="recharge-checkbox"
                type="checkbox"
                name="recharge"
                onChange={handleCheckboxChange}
                checked={rechargeProd}
                style={{ display: "none" }} // Hide the checkbox
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {form.store_selected ? (
          <>
            {widget ? (
              widget.content.product_match.length > 0 ? (
                widget.content.product_match.map((pm, index) => {
                  if (index < productWidgetProducts.length)
                    return (
                      <>
                        <div className="row gy-3">
                          <div className="col-lg-12 mt-3">
                            <InputLabel id="demo-controlled-open-select-label">
                              Product
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              style={{ width: "100%" }}
                              value={pm?.product ? pm?.product : ""}
                              MenuProps={MenuProps}
                              onChange={(e) => {
                                updateProductMatching(
                                  e.target.value,
                                  index,
                                  true
                                );
                              }}
                              sx={{
                                borderRadius: 2,
                              }}
                            >
                              {productWidgetProducts.map((product) => {
                                return (
                                  <MenuItem
                                    key={product.key}
                                    value={
                                      // form.store_selected === "subbly"
                                      //   ? product.product_id
                                      //   :
                                      product.value
                                    }
                                  >
                                    {form.store_selected === "subbly"
                                      ? product.label
                                      : productName(product)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <InputLabel id="demo-controlled-open-select-label">
                              Upsell Product
                            </InputLabel>
                            {form.store_selected === "shopify" ? (
                              <>
                                <SelectReact
                                  // isMulti={true}
                                  options={selectOptions}
                                  value={
                                    pm?.upsell_product
                                      ? selectOptions.find(
                                          (option) =>
                                            option.value === pm.upsell_product
                                        )
                                      : null
                                  }
                                  onChange={(selectedOptions) => {
                                    handleSelectChange(selectedOptions, index);
                                    updateProductMatching(
                                      selectedOptions,
                                      index,
                                      false
                                    );
                                  }}
                                  onMenuScrollToBottom={loadMoreProducts}
                                  isLoading={isLoading}
                                  components={{
                                    LoadingIndicator: () => (
                                      <CircularProgress
                                        size={18}
                                        style={{ alignSelf: "center" }}
                                      />
                                    ),
                                  }}
                                  onKeyDown={handleKeyDown}
                                  onInputChange={(value) => setSearchVal(value)}
                                />
                              </>
                            ) : form.store_selected === "subbly" ? (
                              <SelectReact
                                // isMulti={false}
                                options={selectOptions}
                                value={
                                  pm?.upsell_product
                                    ? selectOptions.find(
                                        (option) =>
                                          option.value === pm.upsell_product
                                      )
                                    : null
                                }
                                onChange={(selectedOptions) => {
                                  handleSelectChange(selectedOptions, index);
                                  updateProductMatching(
                                    selectedOptions,
                                    index,
                                    false
                                  );
                                }}
                                onMenuScrollToBottom={loadMoreProducts}
                                isLoading={isLoading}
                                components={{
                                  LoadingIndicator: () => (
                                    <CircularProgress
                                      size={18}
                                      style={{ alignSelf: "center" }}
                                    />
                                  ),
                                }}
                                onKeyDown={handleKeyDown}
                                onInputChange={(value) => setSearchVal(value)}
                              />
                            ) : (
                              <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                style={{ width: "100%" }}
                                value={
                                  pm.upsell_product ? pm.upsell_product : ""
                                }
                                MenuProps={MenuProps}
                                onChange={(e) => {
                                  updateProductMatching(
                                    e.target.value,
                                    index,
                                    false
                                  );
                                }}
                              >
                                {products.map((product) => {
                                  return (
                                    <MenuItem
                                      key={product.id}
                                      value={product.id}
                                    >
                                      {productName(product)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                })
              ) : (
                <div className="col-12">
                  <p className="formify-p">No products matched!</p>
                </div>
              )
            ) : (
              <div className="col-12">
                <p className="formify-p">No products matched!</p>
              </div>
            )}
          </>
        ) : (
          <div className="col-12">
            <h4 className="formify-h4">Store is not connected</h4>
            <div
              className="d-flex flex-row alert-danger align-items-center p-2"
              style={{ borderRadius: "7px" }}
            >
              <AlertCircle className="me-3" />
              <p className="formify-p">
                To use this widget you need to connect a store. Go to
                <span
                  style={{ color: "#6563ff", cursor: "pointer" }}
                  onClick={() =>
                    setFormStatesDispatcher(true, "openIntegrations")
                  }
                >
                  {" "}
                  Integrations
                </span>{" "}
                and select your favorite store.
              </p>
            </div>
          </div>
        )}
      </div>

      {widget.content.product_match.length < productWidgetProducts.length ? (
        <div
          className="add-new-field d-flex mt-3"
          style={{ cursor: "pointer" }}
          onClick={() => {
            const productMatch = [
              ...widget.content.product_match,
              { product: null, upsell_product: null },
            ];
            handleSetWidgetDispatcher(
              [`content.product_match`],
              [productMatch],
              widget,
              setWidgetDispatcher
            );
          }}
        >
          <Plus size={20} className="formify-color" />
          <p className="formify-p formify-color ms-1 mb-0">
            Add new product matching
          </p>
        </div>
      ) : null}
      <hr />

      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container">
          <div className="input-holder m-0">
            <InputField
              id={"text"}
              label={"Upsell Description"}
              onChange={handleChange}
              placeholder={"Upsell Description"}
              type="text"
              value={widget.content.description}
              parentClass="mt-0"
            />
          </div>
        </div>
      </div>
      <hr />

      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container">
          <div className="input-holder m-0">
            <InputField
              id={"Yes"}
              label={"Upsell button YES"}
              onChange={handleChangeUpsell}
              placeholder={"Yes"}
              type="text"
              style={{ marginRight: "10px" }}
              value={widget.content.yesUpsell}
              parentClass="mt-0"
            />
          </div>
        </div>
      </div>
      <hr />

      <div className="editable-field  quick-form-actions">
        <div className="quick-action-container">
          <div className="input-holder m-0">
            <InputField
              id={"No"}
              label={"Upsell button NO"}
              onChange={handleChangeNoUpsell}
              placeholder={"No"}
              type="text"
              value={widget.content.noUpsell}
              parentClass="mt-0"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="editable-field  form-row quick-form-actions pt-4 font-family-picker">
        <div className="quick-action-container">
          <div className="d-flex justify-between">
            <p>Font Size(px)</p>
            <div className="mb-1">
              <ChangeResponsiveness />
            </div>
          </div>
          <FormifyInput
            type="number"
            id={"fontSize"}
            min={1}
            max={200}
            onChange={updateFontSize}
            value={checkFontSize(widget?.style, responsive, "fontSize", null)}
          />
        </div>
      </div>
      <div className="quick-action-container font-family-picker">
        <FontFamilyPicker
          attribute={widget?.style?.desktop}
          onChangeFont={updateFontFamily}
          id={"label"}
        />
      </div>
      <div className="mt-2">
        <VerticalAligns
          tag={getResponsiveStyle(
            widget?.style,
            responsive,
            "verticalAlignment",
            null
          )}
          handleSelect={handleChangeAlign}
          id={"verticalAlignment"}
          removeXPadding={true}
        />
      </div>

      <div className="mt-2">
        <AlignmentTabs
          id={"alignment"}
          tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
          handleSelect={handleChangeAlign}
        />
      </div>
    </div>
  ) : null;
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(
  UpsellContent,
  setFormStatesDispatcher
);
