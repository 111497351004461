import React, { useState, useEffect } from "react";
import { BASE_DEV_URL } from "../../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import store from "../../store";
import { connect } from "react-redux";
import axios from "axios";
import { tokenConfig } from "../../actions/auth";
import { mapAuthErrorsStateToProps } from "../../utils";
import { calculateResetDate } from "./formFunctions";
const FormReponses = ({ auth, currentSubscription }) => {
  const [usage, setUsage] = useState({
    form: 0,
    responses: 0,
  });

  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/subscription/usage`, tokenConfig(store.getState))
      .then((res) => {
        setUsage({
          form: res.data.form_count,
          responses: res.data.response_count,
        });
      })
      .catch((err) => {});
  }, []);

  const registrationDate = new Date(auth.user.created_at);
  const resetDate = calculateResetDate(registrationDate);

  const renderTooltip = (props) => (
    <Tooltip id="responses-tooltip" {...props}>
      Responses will reset on {resetDate}
    </Tooltip>
  );

  const getWidth = (attr) => {
    switch (attr) {
      case "form":
        const perc =
          (parseInt(usage.form) /
            parseInt(auth.user.transactions[0]?.account_type?.form)) *
          100;
        return `${perc}%`;

      case "responses":
        const resperc =
          (parseInt(usage.responses) /
            parseInt(auth.user.transactions[0]?.account_type?.responses)) *
          100;
        return `${resperc}%`;

      default:
        break;
    }
  };

  return (
    <div className="plan-details w-100">
      <div className="formCounter">
        <div className="formCounterHeader">
          <p>My Signup Flows</p>
          <span className="formSubmissionCounter">
            {usage.form}/{" "}
            {auth ? auth.user.transactions[0]?.account_type?.form : ""}
          </span>
        </div>
        <div className="formProgressBar">
          <div
            className="formProgressBarFilled"
            style={{ width: getWidth("form") }}
          ></div>
        </div>
      </div>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className="responseCounter">
          <div className="responseCounterHeader">
            <p>Responses</p>
            <span className="responseSubmissionCounter">
              {usage.responses}/
              {auth ? auth.user?.transactions[0]?.account_type?.responses : ""}
            </span>
          </div>
          <div className="responseProgressBar">
            <div
              className="responseProgressBarFilled"
              style={{ width: getWidth("responses") }}
            ></div>
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps)(FormReponses);
