import { Drawer } from "@mui/material";
import { useState } from "react";
import { ChevronLeft } from "react-feather";
import ChangeResponsiveness from "./settings-components/single-components/changeResponsiveness";
import BorderOptions from "./settings-components/single-components/BorderOptions";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import ColorPickerStyle from "./settings-components/single-components/ColorPickerStyle";
import Spacer from "./settings-components/single-components/Spacer";
import InputField from "./settings-components/single-components/InputField";
import FontFamilyPicker from "./settings-components/single-components/FontFamilyPicker";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import {
  setFormDispatcher,
  setFormFontDispatcher,
  setFormStatesDispatcher,
} from "../../actions/form";
import { handleFontFormDispatcher, handleSetFormDispatcher } from "../utils/handleDispatcher";
import { lockAllValues } from "../utils/lockValues";
import FormifyInput from "./settings-components/single-components/FormifyInput";

const StyleSidebar = ({
  form: { form, formStates },
  setFormDispatcher,
  setFormFontDispatcher,
  setFormStatesDispatcher,
}) => {
  const [buttonKey, setButtonKey] = useState("default");
  const [stepKey, setStepKey] = useState("default");
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);
  const [
    borderButtonDefaultWidthLockValues,
    setButtonDefaultBorderWidthLockValues,
  ] = useState(true);
  const [
    borderButtonDefaultRadiusLockValues,
    setButtonDefaultBorderRadiusLockValues,
  ] = useState(true);
  const [
    borderButtonHoverWidthLockValues,
    setButtonHoverBorderWidthLockValues,
  ] = useState(true);
  const [
    borderButtonHoverRadiusLockValues,
    setButtonHoverBorderRadiusLockValues,
  ] = useState(true);

  const [
    borderButtonActiveWidthLockValues,
    setButtonActiveBorderWidthLockValues,
  ] = useState(true);
  const [
    borderButtonActiveRadiusLockValues,
    setButtonActiveBorderRadiusLockValues,
  ] = useState(true);

  const [buttonDefaultMarginValues, setButtonDefaultBorderMarginValues] =
    useState(true);
  const [buttonDefaultPaddingLockValues, setButtonDefaultPaddingLockValues] =
    useState(false);
  const [buttonHoverMarginValues, setButtonHoverBorderMarginValues] =
    useState(true);
  const [buttonHoverPaddingLockValues, setButtonHoverPaddingLockValues] =
    useState(false);

    const [buttonActiveMarginValues, setButtonActiveBorderMarginValues] =
    useState(true);
  const [buttonActivePaddingLockValues, setButtonActivePaddingLockValues] =
    useState(false);

  const [
    borderStepDefaultWidthLockValues,
    setStepDefaultBorderWidthLockValues,
  ] = useState(true);
  const [
    borderStepDefaultRadiusLockValues,
    setStepDefaultBorderRadiusLockValues,
  ] = useState(true);
  const [borderStepHoverWidthLockValues, setStepHoverBorderWidthLockValues] =
    useState(true);
  const [borderStepHoverRadiusLockValues, setStepHoverBorderRadiusLockValues] =
    useState(true);

    const [borderStepActiveWidthLockValues, setStepActiveBorderWidthLockValues] =
    useState(true);
  const [borderStepActiveRadiusLockValues, setStepActiveBorderRadiusLockValues] =
    useState(true);


  const [stepDefaultMarginValues, setStepDefaultBorderMarginValues] =
    useState(true);
  const [stepDefaultPaddingLockValues, setStepDefaultPaddingLockValues] =
    useState(false);
  const [stepHoverMarginValues, setStepHoverBorderMarginValues] =
    useState(true);
  const [stepHoverPaddingLockValues, setStepHoverPaddingLockValues] =
    useState(false);

    const [stepActiveMarginValues, setStepActiveBorderMarginValues] =
    useState(true);
  const [stepActivePaddingLockValues, setStepActivePaddingLockValues] =
    useState(false);

  const formColorChange = (e) => {
    handleSetFormDispatcher(
      [`form_style.color.form_color`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const pageColorChange = (e) => {
    handleSetFormDispatcher(
      [`form_style.color.page_color`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const fontColorChange = (e) => {
    handleSetFormDispatcher(
      [`form_style.color.font_color`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const fontChange = (name, url) => {
    handleSetFormDispatcher(
      [`form_style.layout.font_style`],
      [{ name: name, src: url }],
      form,
      setFormDispatcher
    );
  };

  const stepFontChange = (name, url) => {
    handleFontFormDispatcher(
      [`form_style.layout.steps.default.fontFamily`],
      [{ name: name, src: url }],
      form,
      setFormFontDispatcher
    );

  };

  const layoutChange = (e, type) => {
    handleSetFormDispatcher(
      [`form_style.layout.${e.target.id}`],
      [e.target.value],
      form,
      setFormDispatcher,
      false,
      type
    );
  };

  const updateSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsDefaultSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.default.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsHoverSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onHover.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsActiveSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onActive.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateStepsDefaultSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.default.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateStepsHoverSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onHover.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };
  const updateStepsActiveSelectOptions = (e, { id }) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onActive.${id}`],
      [e],
      form,
      setFormDispatcher
    );
  };

  const updateBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsDefaultBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.default.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsHoverBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onHover.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateButtonsActiveBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onActive.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateStepsDefaultBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.default.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateStepsHoverBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onHover.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateStepsActiveBorderColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onActive.borderColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateDefaultButtonsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.default.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateDefaultButtonsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.default.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateHoverButtonsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onHover.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateHoverButtonsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onHover.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateActiveButtonsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onActive.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateActiveButtonsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.onActive.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateDefaultStepsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.default.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateDefaultStepsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.default.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateDefaultStepsLineColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.default.lineColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateHoverStepsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onHover.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateHoverStepsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onHover.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateActiveStepsColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onActive.color`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateActiveStepsBackgroundColor = (value) => {
    handleSetFormDispatcher(
      [`form_style.layout.steps.onActive.backgroundColor`],
      [value],
      form,
      setFormDispatcher
    );
  };

  const updateSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.borderLeftWidth`,
            `form_style.layout.borderTopWidth`,
            `form_style.layout.borderRightWidth`,
            `form_style.layout.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.borderTopLeftRadius`,
            `form_style.layout.borderTopRightRadius`,
            `form_style.layout.borderBottomRightRadius`,
            `form_style.layout.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateButtonHoverSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.buttons.onHover.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderButtonHoverWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onHover.borderLeftWidth`,
            `form_style.layout.buttons.onHover.borderTopWidth`,
            `form_style.layout.buttons.onHover.borderRightWidth`,
            `form_style.layout.buttons.onHover.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderButtonHoverRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onHover.borderTopLeftRadius`,
            `form_style.layout.buttons.onHover.borderTopRightRadius`,
            `form_style.layout.buttons.onHover.borderBottomRightRadius`,
            `form_style.layout.buttons.onHover.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (buttonHoverMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onHover.marginBottom`,
            `form_style.layout.buttons.onHover.marginRight`,
            `form_style.layout.buttons.onHover.marginLeft`,
            `form_style.layout.buttons.onHover.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (buttonHoverPaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onHover.paddingBottom`,
            `form_style.layout.buttons.onHover.paddingRight`,
            `form_style.layout.buttons.onHover.paddingLeft`,
            `form_style.layout.buttons.onHover.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateButtonActiveSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.buttons.onActive.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderButtonActiveWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onActive.borderLeftWidth`,
            `form_style.layout.buttons.onActive.borderTopWidth`,
            `form_style.layout.buttons.onActive.borderRightWidth`,
            `form_style.layout.buttons.onActive.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderButtonActiveRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onActive.borderTopLeftRadius`,
            `form_style.layout.buttons.onActive.borderTopRightRadius`,
            `form_style.layout.buttons.onActive.borderBottomRightRadius`,
            `form_style.layout.buttons.onActive.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (buttonActiveMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onActive.marginBottom`,
            `form_style.layout.buttons.onActive.marginRight`,
            `form_style.layout.buttons.onActive.marginLeft`,
            `form_style.layout.buttons.onActive.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (buttonActivePaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.onActive.paddingBottom`,
            `form_style.layout.buttons.onActive.paddingRight`,
            `form_style.layout.buttons.onActive.paddingLeft`,
            `form_style.layout.buttons.onActive.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateButtonDefaultSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.buttons.default.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderButtonDefaultWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.default.borderLeftWidth`,
            `form_style.layout.buttons.default.borderTopWidth`,
            `form_style.layout.buttons.default.borderRightWidth`,
            `form_style.layout.buttons.default.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderButtonDefaultRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.default.borderTopLeftRadius`,
            `form_style.layout.buttons.default.borderTopRightRadius`,
            `form_style.layout.buttons.default.borderBottomRightRadius`,
            `form_style.layout.buttons.default.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (buttonDefaultMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.default.marginBottom`,
            `form_style.layout.buttons.default.marginRight`,
            `form_style.layout.buttons.default.marginLeft`,
            `form_style.layout.buttons.default.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (buttonDefaultPaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.buttons.default.paddingBottom`,
            `form_style.layout.buttons.default.paddingRight`,
            `form_style.layout.buttons.default.paddingLeft`,
            `form_style.layout.buttons.default.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateStepHoverSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.steps.onHover.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderStepHoverWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onHover.borderLeftWidth`,
            `form_style.layout.steps.onHover.borderTopWidth`,
            `form_style.layout.steps.onHover.borderRightWidth`,
            `form_style.layout.steps.onHover.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderStepHoverRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onHover.borderTopLeftRadius`,
            `form_style.layout.steps.onHover.borderTopRightRadius`,
            `form_style.layout.steps.onHover.borderBottomRightRadius`,
            `form_style.layout.steps.onHover.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (stepHoverMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onHover.marginBottom`,
            `form_style.layout.steps.onHover.marginRight`,
            `form_style.layout.steps.onHover.marginLeft`,
            `form_style.layout.steps.onHover.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (stepHoverPaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onHover.paddingBottom`,
            `form_style.layout.steps.onHover.paddingRight`,
            `form_style.layout.steps.onHover.paddingLeft`,
            `form_style.layout.steps.onHover.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateStepActiveSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.steps.onActive.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderStepActiveWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onActive.borderLeftWidth`,
            `form_style.layout.steps.onActive.borderTopWidth`,
            `form_style.layout.steps.onActive.borderRightWidth`,
            `form_style.layout.steps.onActive.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderStepActiveRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onActive.borderTopLeftRadius`,
            `form_style.layout.steps.onActive.borderTopRightRadius`,
            `form_style.layout.steps.onActive.borderBottomRightRadius`,
            `form_style.layout.steps.onActive.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (stepActiveMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onActive.marginBottom`,
            `form_style.layout.steps.onActive.marginRight`,
            `form_style.layout.steps.onActive.marginLeft`,
            `form_style.layout.steps.onActive.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (stepActivePaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.onActive.paddingBottom`,
            `form_style.layout.steps.onActive.paddingRight`,
            `form_style.layout.steps.onActive.paddingLeft`,
            `form_style.layout.steps.onActive.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateStepDefaultSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetFormDispatcher(
        [`form_style.layout.steps.default.${e.target.id}`],
        [e.target.value + "px"],
        form,
        setFormDispatcher,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderStepDefaultWidthLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.default.borderLeftWidth`,
            `form_style.layout.steps.default.borderTopWidth`,
            `form_style.layout.steps.default.borderRightWidth`,
            `form_style.layout.steps.default.borderBottomWidth`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderStepDefaultRadiusLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.default.borderTopLeftRadius`,
            `form_style.layout.steps.default.borderTopRightRadius`,
            `form_style.layout.steps.default.borderBottomRightRadius`,
            `form_style.layout.steps.default.borderBottomLeftRadius`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 6) === "margin") {
      if (stepDefaultMarginValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.default.marginBottom`,
            `form_style.layout.steps.default.marginRight`,
            `form_style.layout.steps.default.marginLeft`,
            `form_style.layout.steps.default.marginTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (stepDefaultPaddingLockValues) {
        lockAllValues(
          e,
          [
            `form_style.layout.steps.default.paddingBottom`,
            `form_style.layout.steps.default.paddingRight`,
            `form_style.layout.steps.default.paddingLeft`,
            `form_style.layout.steps.default.paddingTop`,
          ],
          form,
          setFormDispatcher,
          true,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const handleDataChanges = (e, type) => {
    handleSetFormDispatcher(
      [`form_style.layout.buttons.${e.target.id}`],
      [e.target.value],
      form,
      setFormDispatcher,
      type
    );
  };

  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        {form.form_style.layout.buttons && form.form_style.layout.steps ? (
          <div className="sidebar-wraper">
            <div className="form-information ">
              <h4 className="form-name">{form ? form.name : ""}</h4>
              <p className="form-url">beta.getformify.com/{form._id}</p>
            </div>
            <div className="sidebar-settings-wraper">
              <div
                className="settings-field-container"
                style={{ margin: "10px 0 20px 10px" }}
              >
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Form Style
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-widget-spacer "></div>
                      <form className="style-form">
                        <div className="editable-field  form-row">
                          <p htmlFor="page_color">Page Color</p>
                          <ColorPickerStyle
                            onChange={pageColorChange}
                            id={"page_color"}
                            tag={form.form_style.color.page_color}
                          />
                        </div>
                        <div className="editable-field form-row">
                          <p htmlFor="form_color">Form Color</p>
                          <ColorPickerStyle
                            onChange={formColorChange}
                            id={"form_color"}
                            tag={form.form_style.color.form_color}
                          />
                        </div>
                        <hr />
                        <div className="editable-field form-row">
                          <FontFamilyPicker
                            attribute={form.form_style.layout.font_style}
                            onChangeFont={fontChange}
                            id={"label"}
                          />
                        </div>
                        <div className="editable-field form-row">
                          <p htmlFor="font_color">Font Color</p>
                          <ColorPickerStyle
                            onChange={fontColorChange}
                            id={"font_color"}
                            tag={form.form_style.color.font_color}
                          />
                        </div>

                        <div className="editable-field form-row">
                          <p htmlFor="font_size">Font Size</p>
                          <FormifyInput
                            type="number"
                            id={"font_size"}
                            name={"font_size"}
                            onChange={layoutChange}
                            value={form.form_style.layout.font_size || ""}
                          />
                        </div>
                        <hr />
                        <div className="editable-field  form-row">
                          <p htmlFor="width">Form Width</p>
                          <FormifyInput
                            type="number"
                            id={"width"}
                            name={"width"}
                            onChange={layoutChange}
                            value={form.form_style.layout.width || ""}
                          />
                        </div>
                        <hr />
                        <BorderOptions
                          attribute={form.form_style.layout}
                          onChange={updateSliderOptions}
                          onChangeColor={updateBorderColor}
                          setBorderWidthLockValues={setBorderWidthLockValues}
                          setBorderRadiusLockValues={setBorderRadiusLockValues}
                          borderWidthLockValues={borderWidthLockValues}
                          borderRadiusLockValues={borderRadiusLockValues}
                          updateSelectOptions={updateSelectOptions}
                        />
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div
                className="settings-field-container"
                style={{ margin: "10px 0 20px 10px" }}
              >
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Next Button
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-widget-spacer "></div>
                      <div className="editable-field ">
                        <InputField
                          id={"nextContent"}
                          label={"Next Content"}
                          onChange={handleDataChanges}
                          placeholder={"Next"}
                          type="text"
                          value={form.form_style.layout.buttons.nextContent}
                        />
                        <InputField
                          id={"submitContent"}
                          label={"Submit Content"}
                          onChange={handleDataChanges}
                          placeholder={"Submit"}
                          type="text"
                          value={form.form_style.layout.buttons.submitContent}
                        />
                        <Tabs
                          defaultActiveKey="default"
                          id="default-button"
                          className="mb-3"
                          activeKey={buttonKey}
                          onSelect={(k) => setButtonKey(k)}
                        >
                          <Tab eventKey="default" title="Default">
                            <div className="editable-field form-row  ">
                              <p>Color</p>
                              <ColorPickerStyle
                                onChange={updateDefaultButtonsColor}
                                id={"color"}
                                tag={
                                  form.form_style.layout.buttons
                                    ? form.form_style.layout.buttons.default
                                        .color
                                    : "rgb(0,0,0)"
                                }
                              />
                            </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Background
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="editable-field form-row  ">
                                    <p>Color yy</p>
                                    <ColorPickerStyle
                                      onChange={
                                        updateDefaultButtonsBackgroundColor
                                      }
                                      id={"backgroundColor"}
                                      tag={
                                        form.form_style.layout.buttons
                                          ? form.form_style.layout.buttons
                                              .default.backgroundColor
                                          : "rgb(0,0,0)"
                                      }
                                    />
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <hr />
                            <BorderOptions
                              attribute={form.form_style.layout.buttons.default}
                              onChange={updateButtonDefaultSliderOptions}
                              onChangeColor={updateButtonsDefaultBorderColor}
                              setBorderWidthLockValues={
                                setButtonDefaultBorderWidthLockValues
                              }
                              setBorderRadiusLockValues={
                                setButtonDefaultBorderRadiusLockValues
                              }
                              borderWidthLockValues={
                                borderButtonDefaultWidthLockValues
                              }
                              borderRadiusLockValues={
                                borderButtonDefaultRadiusLockValues
                              }
                              updateSelectOptions={
                                updateButtonsDefaultSelectOptions
                              }
                            />
                            <hr />

                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Spacing
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Spacer
                                    label={"Margin"}
                                    id={"margin"}
                                    tagLeft={
                                      form.form_style.layout.buttons.default
                                        .marginLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.buttons.default
                                        .marginRight
                                    }
                                    tagTop={
                                      form.form_style.layout.buttons.default
                                        .marginTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.buttons.default
                                        .marginBottom
                                    }
                                    onChange={updateButtonDefaultSliderOptions}
                                    setLockValue={
                                      setButtonDefaultBorderMarginValues
                                    }
                                    lockValue={buttonDefaultMarginValues}
                                  />
                                  <Spacer
                                    label={"Padding"}
                                    id={"padding"}
                                    tagLeft={
                                      form.form_style.layout.buttons.default
                                        .paddingLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.buttons.default
                                        .paddingRight
                                    }
                                    tagTop={
                                      form.form_style.layout.buttons.default
                                        .paddingTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.buttons.default
                                        .paddingBottom
                                    }
                                    onChange={updateButtonDefaultSliderOptions}
                                    setLockValue={
                                      setButtonDefaultPaddingLockValues
                                    }
                                    lockValue={buttonDefaultPaddingLockValues}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Tab>

                          <Tab eventKey="hover" title="Hover">
                            <div className="editable-field form-row  ">
                              <p>Color</p>
                              <ColorPickerStyle
                                onChange={updateHoverButtonsColor}
                                id={"color"}
                                tag={
                                  form.form_style.layout.buttons.onHover.color
                                }
                              />
                            </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Background
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="editable-field form-row  ">
                                    <p>Color</p>
                                    <ColorPickerStyle
                                      onChange={
                                        updateHoverButtonsBackgroundColor
                                      }
                                      id={"backgroundColor"}
                                      tag={
                                        form.form_style.layout.buttons.onHover
                                          .backgroundColor
                                      }
                                    />
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <hr />
                            <BorderOptions
                              attribute={form.form_style.layout.buttons.onHover}
                              onChange={updateButtonHoverSliderOptions}
                              onChangeColor={updateButtonsHoverBorderColor}
                              setBorderWidthLockValues={
                                setButtonHoverBorderWidthLockValues
                              }
                              setBorderRadiusLockValues={
                                setButtonHoverBorderRadiusLockValues
                              }
                              borderWidthLockValues={
                                borderButtonHoverWidthLockValues
                              }
                              borderRadiusLockValues={
                                borderButtonHoverRadiusLockValues
                              }
                              updateSelectOptions={
                                updateButtonsHoverSelectOptions
                              }
                            />
                          </Tab>

                          <Tab eventKey="active" title="Active">
                            <div className="editable-field form-row  ">
                              <p>Color</p>
                              <ColorPickerStyle
                                onChange={updateActiveButtonsColor}
                                id={"color"}
                                tag={
                                  form.form_style.layout.buttons
                                    ? form.form_style.layout.buttons.default
                                        .color
                                    : "rgb(0,0,0)"
                                }
                              />
                            </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Background
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="editable-field form-row  ">
                                    <p>Color</p>
                                    <ColorPickerStyle
                                      onChange={
                                        updateActiveButtonsBackgroundColor
                                      }
                                      id={"backgroundColor"}
                                      tag={
                                        form.form_style.layout.buttons
                                          ? form.form_style.layout.buttons
                                              .default.backgroundColor
                                          : "rgb(0,0,0)"
                                      }
                                    />
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <hr />
                            <BorderOptions
                              attribute={form.form_style.layout.buttons.default}
                              onChange={updateButtonActiveSliderOptions}
                              onChangeColor={updateButtonsActiveBorderColor}
                              setBorderWidthLockValues={
                                setButtonActiveBorderWidthLockValues
                              }
                              setBorderRadiusLockValues={
                                setButtonActiveBorderRadiusLockValues
                              }
                              borderWidthLockValues={
                                borderButtonDefaultWidthLockValues
                              }
                              borderRadiusLockValues={
                                borderButtonDefaultRadiusLockValues
                              }
                              updateSelectOptions={
                                updateButtonsActiveSelectOptions
                              }
                            />
                            <hr />

                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Spacing
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Spacer
                                    label={"Margin"}
                                    id={"margin"}
                                    tagLeft={
                                      form.form_style.layout.buttons.default
                                        .marginLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.buttons.default
                                        .marginRight
                                    }
                                    tagTop={
                                      form.form_style.layout.buttons.default
                                        .marginTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.buttons.default
                                        .marginBottom
                                    }
                                    onChange={updateButtonDefaultSliderOptions}
                                    setLockValue={
                                      setButtonDefaultBorderMarginValues
                                    }
                                    lockValue={buttonDefaultMarginValues}
                                  />
                                  <Spacer
                                    label={"Padding"}
                                    id={"padding"}
                                    tagLeft={
                                      form.form_style.layout.buttons.default
                                        .paddingLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.buttons.default
                                        .paddingRight
                                    }
                                    tagTop={
                                      form.form_style.layout.buttons.default
                                        .paddingTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.buttons.default
                                        .paddingBottom
                                    }
                                    onChange={updateButtonActiveSliderOptions}
                                    setLockValue={
                                      setButtonActivePaddingLockValues
                                    }
                                    lockValue={buttonActivePaddingLockValues}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Tab>

                        </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div
                className="settings-field-container"
                style={{ margin: "10px 0 20px 10px" }}
              >
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Steps
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-widget-spacer "></div>
                      <div className="editable-field ">
                        <Tabs
                          defaultActiveKey="default"
                          id="default-button"
                          className="mb-3"
                          activeKey={stepKey}
                          onSelect={(k) => setStepKey(k)}
                        >
                          <Tab eventKey="default" title="Default">
                            <div className="editable-field">
                              <FontFamilyPicker
                                attribute={form.form_style.layout.steps.default}
                                onChangeFont={stepFontChange}
                                id={"label"}
                              />
                            </div>
                            <div className="editable-field form-row  ">
                              <p>Color</p>
                              <ColorPickerStyle
                                onChange={updateDefaultStepsColor}
                                id={"color"}
                                tag={form.form_style.layout.steps.default.color}
                              />
                            </div>
                            <div className="editable-field form-row  ">
                              <p>Line color</p>
                              <ColorPickerStyle
                                onChange={updateDefaultStepsLineColor}
                                id={"color2"}
                                tag={
                                  form.form_style.layout.steps.default.lineColor
                                }
                              />
                            </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Background
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="editable-field form-row  ">
                                    <p>Color</p>
                                    <ColorPickerStyle
                                      onChange={
                                        updateDefaultStepsBackgroundColor
                                      }
                                      id={"backgroundColor"}
                                      tag={
                                        form.form_style.layout.steps.default
                                          .backgroundColor
                                      }
                                    />
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <hr />
                            <BorderOptions
                              attribute={form.form_style.layout.steps.default}
                              onChange={updateStepDefaultSliderOptions}
                              onChangeColor={updateStepsDefaultBorderColor}
                              setBorderWidthLockValues={
                                setStepDefaultBorderWidthLockValues
                              }
                              setBorderRadiusLockValues={
                                setStepDefaultBorderRadiusLockValues
                              }
                              borderWidthLockValues={
                                borderStepDefaultWidthLockValues
                              }
                              borderRadiusLockValues={
                                borderStepDefaultRadiusLockValues
                              }
                              updateSelectOptions={
                                updateStepsDefaultSelectOptions
                              }
                            />
                            <hr />

                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Spacing
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Spacer
                                    label={"Margin"}
                                    id={"margin"}
                                    tagLeft={
                                      form.form_style.layout.steps.default
                                        .marginLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.steps.default
                                        .marginRight
                                    }
                                    tagTop={
                                      form.form_style.layout.steps.default
                                        .marginTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.steps.default
                                        .marginBottom
                                    }
                                    onChange={updateStepDefaultSliderOptions}
                                    setLockValue={
                                      setStepDefaultBorderMarginValues
                                    }
                                    lockValue={stepDefaultMarginValues}
                                  />
                                  <Spacer
                                    label={"Padding"}
                                    id={"padding"}
                                    tagLeft={
                                      form.form_style.layout.steps.default
                                        .paddingLeft
                                    }
                                    tagRight={
                                      form.form_style.layout.steps.default
                                        .paddingRight
                                    }
                                    tagTop={
                                      form.form_style.layout.steps.default
                                        .paddingTop
                                    }
                                    tagBottom={
                                      form.form_style.layout.steps.default
                                        .paddingBottom
                                    }
                                    onChange={updateStepDefaultSliderOptions}
                                    setLockValue={
                                      setStepDefaultPaddingLockValues
                                    }
                                    lockValue={stepDefaultPaddingLockValues}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Tab>

                          <Tab eventKey="hover" title="Hover">
                              <div className="editable-field form-row  ">
                                <p>Color</p>
                                <ColorPickerStyle
                                  onChange={updateHoverStepsColor}
                                  id={"color"}
                                  tag={form.form_style?.layout?.steps?.onHover?.color}
                                />
                              </div>
                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="field-label">
                                    Background
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="editable-field form-row  ">
                                      <p>Color</p>
                                      <ColorPickerStyle
                                        onChange={updateHoverStepsBackgroundColor}
                                        id={"backgroundColor"}
                                        tag={
                                          form.form_style.layout.steps.onHover
                                            .backgroundColor
                                        }
                                      />
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              <hr />
                              <BorderOptions
                                attribute={form.form_style.layout.steps.onHover}
                                onChange={updateStepHoverSliderOptions}
                                onChangeColor={updateStepsHoverBorderColor}
                                setBorderWidthLockValues={
                                  setStepHoverBorderWidthLockValues
                                }
                                setBorderRadiusLockValues={
                                  setStepHoverBorderRadiusLockValues
                                }
                                borderWidthLockValues={
                                  borderStepHoverWidthLockValues
                                }
                                borderRadiusLockValues={
                                  borderStepHoverRadiusLockValues
                                }
                                updateSelectOptions={
                                  updateStepsHoverSelectOptions
                                }
                              />
                              <hr />

                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="field-label">
                                    Spacing
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Spacer
                                      label={"Margin"}
                                      id={"margin"}
                                      tagLeft={
                                        form.form_style.layout.steps.onHover
                                          .marginLeft
                                      }
                                      tagRight={
                                        form.form_style.layout.steps.onHover
                                          .marginRight
                                      }
                                      tagTop={
                                        form.form_style.layout.steps.onHover
                                          .marginTop
                                      }
                                      tagBottom={
                                        form.form_style.layout.steps.onHover
                                          .marginBottom
                                      }
                                      onChange={updateStepHoverSliderOptions}
                                      setLockValue={
                                        setStepHoverBorderMarginValues
                                      }
                                      lockValue={stepHoverMarginValues}
                                    />
                                    <Spacer
                                      label={"Padding"}
                                      id={"padding"}
                                      tagLeft={
                                        form.form_style.layout.steps.onHover
                                          .paddingLeft
                                      }
                                      tagRight={
                                        form.form_style.layout.steps.onHover
                                          .paddingRight
                                      }
                                      tagTop={
                                        form.form_style.layout.steps.onHover
                                          .paddingTop
                                      }
                                      tagBottom={
                                        form.form_style.layout.steps.onHover
                                          .paddingBottom
                                      }
                                      onChange={updateStepHoverSliderOptions}
                                      setLockValue={setStepHoverPaddingLockValues}
                                      lockValue={stepHoverPaddingLockValues}
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Tab>
                            
                            <Tab eventKey="active" title="Active">
                              <div className="editable-field form-row  ">
                                <p>Color</p>
                                <ColorPickerStyle
                                  onChange={updateActiveStepsColor}
                                  id={"color"}
                                  tag={form.form_style?.layout?.steps?.onActive?.color}
                                />
                              </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Background
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="editable-field form-row  ">
                                    <p>Color</p>
                                    <ColorPickerStyle
                                      onChange={updateActiveStepsBackgroundColor}
                                      id={"backgroundColor"}
                                      tag={
                                        form.form_style?.layout?.steps?.onActive?.backgroundColor
                                      }
                                    />
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <hr />
                            <BorderOptions
                              attribute={form?.form_style?.layout?.steps?.onActive}
                              onChange={updateStepActiveSliderOptions}
                              onChangeColor={updateStepsActiveBorderColor}
                              setBorderWidthLockValues={
                                setStepActiveBorderWidthLockValues
                              }
                              setBorderRadiusLockValues={
                                setStepActiveBorderRadiusLockValues
                              }
                              borderWidthLockValues={
                                borderStepActiveWidthLockValues
                              }
                              borderRadiusLockValues={
                                borderStepActiveRadiusLockValues
                              }
                              updateSelectOptions={
                                updateStepsActiveSelectOptions
                              }
                            />
                            <hr />

                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className="field-label">
                                  Spacing
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Spacer
                                    label={"Margin"}
                                    id={"margin"}
                                    tagLeft={
                                      form?.form_style?.layout?.steps?.onActive?.marginLeft
                                    }
                                    tagRight={
                                      form?.form_style?.layout?.steps?.onActive?.marginRight
                                    }
                                    tagTop={
                                      form?.form_style?.layout?.steps?.onActive?.marginTop
                                    }
                                    tagBottom={
                                      form?.form_style?.layout?.steps?.onActive?.marginBottom
                                    }
                                    onChange={updateStepActiveSliderOptions}
                                    setLockValue={
                                      setStepActiveBorderMarginValues
                                    }
                                    lockValue={stepActiveMarginValues}
                                  />
                                  <Spacer
                                    label={"Padding"}
                                    id={"padding"}
                                    tagLeft={
                                      form?.form_style?.layout?.steps?.onActive?.paddingLeft
                                    }
                                    tagRight={
                                      form?.form_style?.layout?.steps?.onActive?.paddingRight
                                    }
                                    tagTop={
                                      form?.form_style?.layout?.steps?.onActive?.paddingTop
                                    }
                                    tagBottom={
                                      form?.form_style?.layout?.steps?.onActive?.paddingBottom
                                    }
                                    onChange={updateStepActiveSliderOptions}
                                    setLockValue={setStepActivePaddingLockValues}
                                    lockValue={stepActivePaddingLockValues}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Tab>
                        </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Drawer>
      <ChangeResponsiveness />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setFormDispatcher,
  setFormStatesDispatcher,
  setFormFontDispatcher
})(StyleSidebar);
