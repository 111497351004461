import axios from "axios";
import { useState, useEffect } from "react";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { BASE_DEV_URL } from "../../../utils";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";

const GlobalStyle = styled.tr`
  @font-face {
    font-family: "${(props) => props.name}";
    src: url(${(props) => props.url});
  } ;
`;

const FontList = () => {
  const [customFonts, setCustomFonts] = useState([]);
  const [modal, setModal] = useState({
    show: false,
    function: null,
  });
  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/customFont`, tokenConfig(store.getState))
      .then((res) => {
       

        setCustomFonts(res.data.fonts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteFont = (id) => {
    axios
      .delete(`${BASE_DEV_URL}/font/${id}`, tokenConfig(store.getState))
      .then((res) => {
        if (res.data?.success) {
          setCustomFonts(res.data.fonts);
          toast.success("Font was deleted successfully!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DisconnectModal = (props) => {
    return (
      <Modal show={props.show} size="lg" centered className="name-modal">
        <Modal.Header style={{ justifyContent: "center" }}>
          <div className="name-modal-header d-flex flex-column align-items-center text-center">
            <h4> Are you sure you want to delete this font?</h4>
            <p>All forms that use this font will be affected.</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            className="danger-btn"
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
            onClick={() => {
              props.delete(props.id);
              props.handler({
                show: false,
              });
            }}
          >
            Delete
          </button>
          <button
            className="invert-formify-btn mt-3"
            onClick={() => {
              props.handler({
                show: false,
              });
            }}
            type="button"
            style={{ width: "100%", fontSize: "14px" }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div className="font-list container-fluid mt-4 contact-list pos-relative pl-20 pb-100">
      {customFonts.length > 0 ? (
        <>
          <DisconnectModal
            show={modal.show}
            handler={setModal}
            delete={modal.function}
            id= {modal.id}
          />
          <h1 className="formify-h1">My Fonts</h1>
          <table className="formitable">
            <thead>
              <tr>
                <td>Font Name</td>
                <td>Preview Text</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {customFonts.map((font) => {
                return (
                  <GlobalStyle key={font._id} name={font?.src[0]?.name} url={font?.src[0]?.src}>
                    <td>{font.name}</td>
                    <td style={{ fontFamily: font?.src[0]?.name }}>
                      Formify helps you generate leads and improve conversion
                      rate
                    </td>
                    <td>
                      <button
                        className="delete-table formify-sm-iconbtn"
                        onClick={() =>
                          setModal({
                            show: true,
                            function: deleteFont,
                            id: font._id
                          })
                        }
                      >
                        <span className="trash-icon"></span>
                      </button>
                    </td>
                  </GlobalStyle>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className="no-font">
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/icons/missing font-02.png"
              }
              alt="no-data"
            />
          </div>
          <p>Seems like you don't have any fonts uploaded</p>
          <Link to="/resources/fonts/upload">Start by importing your first custom font!</Link>
        </div>
      )}
    </div>
  );
};

export default FontList;
