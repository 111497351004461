import React from "react";

export default function MoneyFormat(props) {
  return (
    <>
      <div className="money-format">
        <div>
          <p><span className="dollar-sign">$</span>{props.value}<sup className="sup">{props.cent}</sup><span>/{props.period}</span></p>
        </div>
        <p className="tiny-text">{props.desc}</p>
      </div>
    </>
  );
}
