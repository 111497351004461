import React from "react";
import { mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";

const ContainerTabs = ({ setActive, active, name, id, form: { widget } }) => {
  return (
    <div className="sidebar-wraper">
      <div className="form-information ">
        <h4 className="form-name">{name}</h4>
        <p className="form-url">getformify.com/{id}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-nowrap widget-editing-name-container">
        <h2 className="widget-editing-name">Edit {widget.name} Widget</h2>
      </div>
      <ul>
        <li
          className={active === "content" ? "activeTab" : ""}
          onClick={() => setActive("content")}
        >Content</li>
        <li
          className={active === "style" ? "activeTab" : ""}
          onClick={() => setActive("style")}
        >Style</li>
        <li
          className={active === "options" ? "activeTab" : ""}
          onClick={() => setActive("options")}
        >Options</li>
      </ul>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps)(ContainerTabs);
