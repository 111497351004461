const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LETTERS_AND_SPACES = /^[A-Za-z\s]+$/;
const LETTERS_NO_SPACES = /^[A-Za-z]+$/;
const PASSWORD_RE = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
const PRODUCT_NAME = /^[\w+\s+]+$/;
const PRODUCT_PRICE = /^(\d{1,3})*(\.\d{2})?$/;
const PRODUCT_ID = /^(\d{1,30})?$/;
const DESCRIPTION = /[\w\[\]`!@#$.%\^&*():;+'-]*/;

const URL_RE = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

exports.URL_RE = URL_RE;
exports.PRODUCT_NAME = PRODUCT_NAME;
exports.PRODUCT_ID = PRODUCT_ID;
exports.DESCRIPTION = DESCRIPTION;
exports.PRODUCT_PRICE = PRODUCT_PRICE;
exports.PASSWORD_RE = PASSWORD_RE;
exports.EMAIL_REGEX = EMAIL_REGEX;
exports.LETTERS_NO_SPACES = LETTERS_NO_SPACES;
exports.LETTERS_AND_SPACES = LETTERS_AND_SPACES;