// import { BASE_DEV_URL } from "./utils";
// import axios from "axios";
// import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/PrivateRoute";
import { useEffect, useState } from "react";
import { getUser } from "./actions/auth";
import Register from "./components/auth/Register";
import PasswordResetEmail from "./components/auth/PasswordResetEmail";
import PasswordReset from "./components/auth/PasswordReset";
import EmailConfirmation from "./components/auth/EmailConfirmation";
import ResendEmailConfirmation from "./components/auth/ResendEmailConfirmation";
import Dashboard from "./components/pages/Dashboard";
import Notification from "./components/Notification";
import Loader from "./components/utils/Loader";
import Test from "./test";
import FormView from "./components/pages/FormView";
import ThankYouPage from "./components/pages/ThankYouPage";
import OnboardIntegrations from "./components/pages/OnboardIntegrations";

function App({ getUser }) {


  useEffect(() => {
    getUser();
  }, []);

  return (
    <Router>
      <div
        className="container-fluid formify-wraper "
        style={{ margin: 0, padding: 0 }}
      >
        <Loader />
        <Notification />
        <Routes>
          <Route
            path="/*"
            element={
              <PrivateRoute >
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/form/view/:id"
            element={<FormView fillMode={false} />}
          />
          <Route path="/form/fill/:id" element={<FormView fillMode={true} />} />
          <Route
            path="/form/fill/thanks"
            element={<ThankYouPage fillMode={true} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/onboarding-integrations" element={ <PrivateRoute ><OnboardIntegrations /></PrivateRoute>} />

          <Route path="/password/reset/send" element={<PasswordResetEmail />} />
          <Route path="/password/reset/:token" element={<PasswordReset />} />
          <Route path="/verify/email/:token" element={<EmailConfirmation />} />
          <Route
            path="/resend/verification"
            element={<ResendEmailConfirmation />}
          />
          <Route path="/test" element={<Test />} />
        </Routes>
      </div>
    </Router>
  );
}

export default connect(null, { getUser })(App);
