import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrors } from "../../../actions/errors";
import { usePrevious } from "../../../hooks/usePrevious";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { connectSubbly } from "../../../actions/integrations";
import { Overlay, Tooltip } from "react-bootstrap";

const SubblyIntegration = ({ auth, errors, connectSubbly, clearErrors }) => {
  const nav = useNavigate();
  const [form, setForm] = useState({
    checkout_url: auth.user
      ? auth.user.stores.subbly
        ? auth.user.stores.subbly.checkout_url
        : ""
      : "",
    store_url: auth.user
      ? auth.user.stores.subbly
        ? auth.user.stores.subbly.store_url
        : ""
      : "",
    api_key: auth.user
      ? auth.user.stores.subbly
        ? auth.user.stores.subbly.api_key
        : ""
      : "",
    storefront_api_key: auth.user
      ? auth.user.stores.subbly
        ? auth.user.stores.subbly.storefront_api_key
        : ""
      : "",
  });
  const checkout_url = useRef(null);
  const store_url = useRef(null);
  const api_key = useRef(null);
  const storefront_api_key = useRef(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    connectSubbly(form);
  };

  const prevAuth = usePrevious(auth.user);

  useEffect(() => {
    if (prevAuth) {
      if (auth.user.stores !== prevAuth.stores) {
        nav("/integrations/subbly");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.stores]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Overlay
        target={store_url.current}
        show={
          errors.errors
            ? errors.errors["stores.subbly.store_url"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.store_url"]
                ? errors.errors["stores.subbly.store_url"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={api_key.current}
        show={
          errors.errors
            ? errors.errors["stores.subbly.api_key"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.api_key"]
                ? errors.errors["stores.subbly.api_key"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={checkout_url.current}
        show={
          errors.errors
            ? errors.errors["stores.subbly.checkout_url"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.checkout_url"]
                ? errors.errors["stores.subbly.checkout_url"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>
      <Overlay
        target={storefront_api_key.current}
        show={
          errors.errors
            ? errors.errors["stores.subbly.storefront_api_key"]
              ? true
              : false
            : false
        }
        placement="bottom"
      >
        {(props) => (
          <Tooltip
            id="login-error"
            style={{ backgroundColor: "#FC5B3F" }}
            {...props}
          >
            {errors.errors
              ? errors.errors["stores.subbly.storefront_api_key"]
                ? errors.errors["stores.subbly.storefront_api_key"].message
                : ""
              : ""}
          </Tooltip>
        )}
      </Overlay>

      <div className="row  mt-4">
        <div className="col-lg-12 ">
          <div className="integration-header d-flex justify-content-start align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
              alt="Formify"
              className="img-fluid me-2"
            />

            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/right-arrow.svg"
              }
              alt=""
              className="right-arrow"
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/images/subbly.png"}
              alt="Subbly"
              className="img-fluid me-2"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <h4 className="integration-page-header">Connect your form to Subbly</h4>
        <p className="grey-paragraph">
          Start by selecting the form you want to connect to your Subbly store.
        </p>
      </div>
      <div className="row">
        <div className=" mt-5">

        <div className="subbly-infos-flex">

          <div className="formify-card main-auth-card">
            <h4 className="integration-page-header">
              Authenticate Subbly Shop
            </h4>
            <p className="grey-paragraph">
              Enter the following information to authenticate your account.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="input-holder position-relative">
                      <label htmlFor="store_url">Store URL</label>
                      <input
                        type="text"
                        id="store_url"
                        name="store_url"
                        onChange={handleChange}
                        value={form.store_url || ""}
                        placeholder="https://example.com"
                        ref={store_url}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="checkout_url">Checkout URL</label>
                      <input
                        type="text"
                        id="checkout_url"
                        name="checkout_url"
                        onChange={handleChange}
                        value={form.checkout_url || ""}
                        placeholder="https://example.com"
                        ref={checkout_url}
                      />
                    </div>
                    <div className="input-holder position-relative">
                      <label htmlFor="api_key">API Key</label>
                      <input
                        type="text"
                        id="api_key"
                        name="api_key"
                        onChange={handleChange}
                        value={form.api_key || ""}
                        placeholder="123456789ASDFGJHKDAS"
                        ref={api_key}
                      />
                    </div>

                    <div className="input-holder position-relative">
                      <label htmlFor="storefront_api_key">Storefront Key</label>
                      <input
                        type="text"
                        id="storefront_api_key"
                        name="storefront_api_key"
                        onChange={handleChange}
                        value={form.storefront_api_key || ""}
                        placeholder="1234-5678-9ASD-FGJH-KDAS"
                        ref={storefront_api_key}
                      />
                    </div>

                    <button type="submit" className="formify-btn mt-3">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="formify-card  subbly tips-card">
            <div className="subbly-header">
              <div className="row mt-2 col-lg-10">
                <p className="tips-subheading">Integration tips</p>
                <h4 className="integration-page-header mb-4">
                  How to successfully integrate your Subbly account
                </h4>
              </div>
            </div>
            <div>
              <div className="">
                <h5>Shop URL</h5>
                <p className="tips-paragraph">E.G. - https://busterbox.com</p>
                <p className="tips-paragraph">{`Found in settings > general`}</p>

                <p className="tips-paragraph">
                  or at <a href="https://www.subbly.co/admin/settings/general" target="_blank" rel="noreferrer"> https://www.subbly.co/admin/settings/general</a>
                </p>
              </div>

              <div className="">
                <h5> Checkout URL</h5>
                <p className="tips-paragraph">
                  {`Found in Settings > checkout domain`}
                </p>
                <p className="tips-paragraph">
                  E.G. - https://checkout.busterbox.com
                </p>

                <p className="tips-paragraph">
                  If you don't see this in your Subbly settings, you may not
                  have it activated. In that case use
                  <a href="https://www.subbly.co" target="_blank" rel="noreferrer"> https://www.subbly.co</a>
                </p>
              </div>

              <div className="">
                <h5>API Key</h5>
                <p className="tips-paragraph">{`Found in settings > general`}</p>
                <p className="tips-paragraph">
                  or at
                  <a href="https://www.subbly.co/admin/settings/general" target="_blank" rel="noreferrer">  https://www.subbly.co/admin/settings/general</a>
                  
                </p>
              </div>

               <div className="">
                <h5>Storefront API Key</h5>
                <p className="tips-paragraph">{`Found in: Settings > Storefront API Keys `}</p>
                <p className="tips-paragraph">
                or at 
                  <a href="https://www.subbly.co/admin/settings/api-keys" target="_blank" rel="noreferrer">  https://www.subbly.co/admin/settings/api-keys</a>
                  
                </p>
              </div>
            </div>

            <div className="divider"></div>

            {/* <SubblyList/> */}
          </div>

          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  connectSubbly,
  clearErrors,
})(SubblyIntegration);