import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../utils";
import { loginUser, googleAuth } from "../../actions/auth";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Overlay, Tooltip } from "react-bootstrap";
import { useRef } from "react";
import { clearErrors } from "../../actions/errors";
import { addAppFromShopify } from "../../actions/integrations";
import qs from "query-string";
import FormifyLoader from "../utils/FormifyLoader";

const Login = ({ auth, errors, loginUser, googleAuth, clearErrors }) => {
  const nav = useNavigate();
  const loc = useLocation();
  const emailRef = useRef(null);
  const [userCheckoutType, setUserCheckoutType] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessages] = useState(null);

  const [form, setForm] = useState({
    email: "",
    password: "",
    hasErrors: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser({ email: form.email, password: form.password });
  };

  useEffect(() => {
    setLoading(true);
    // also used for navigation if re-loggedin
    addAppFromShopify(auth, loc.state, nav, setMessages, setLoading);
    if (!auth.isAuthenticated) {
      const qstr = loc.state ? qs.parse(loc.state?.requested_path) : null;
      if (qstr && qstr["hmac"] && qstr["shop"] && qstr["state"]) {
        setUserCheckoutType(loc.state ? loc.state : "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    return function cleanup() {
      clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      setForm({ ...form, hasErrors: true });
    } else setForm({ ...form, hasErrors: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const googleSuccess = (response) => {
    googleAuth(response);
  };
  const urlPatter = /^\/+$/;

  return !loading ? (
    <div className="container login-wraper mt-5 mb-5 ">
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper px-4 py-5">
          <div className="login-header">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify_logo.svg"}
              alt="formify-logo"
              className="img-fluid"
            />
            <p style={{ marginLeft: "0" }}>Hello, let’s get you logged in!</p>
          </div>
          <Overlay
            target={emailRef.current}
            show={form.hasErrors}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {errors.message}
              </Tooltip>
            )}
          </Overlay>

          <form className="login-form mt-2" onSubmit={handleSubmit}>
            {message && <div className="alert-ribbon">{message}</div>}
            <div className="input-holder position-relative">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={form.email || ""}
                onChange={handleChange}
                name="email"
                placeholder="email@business.com"
                ref={emailRef}
              />
            </div>

            <div className="input-holder">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={form.password || ""}
                onChange={handleChange}
                name="password"
                placeholder="Min. 8 characters, one UPPERCASE"
              />
            </div>
            <div className="forgot-pass text-end mt-3">
              <Link
                to="/password/reset/send"
                style={{ textDecoration: "none", color: "black" }}
              >
                <p>Forgot password?</p>
              </Link>
            </div>
            <div className="submit-btn ">
              <button>Log in</button>
            </div>
            <div className="separator">OR</div>
            <div className="google-login">
              <GoogleLogin
                clientId="381070749077-qrc9uad2k80aduq51co102e1f4gbr137.apps.googleusercontent.com"
                onSuccess={googleSuccess}
                onFailure={() => {
                }}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <div className="google-svg">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Google__G__Logo.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="google-text ">
                      <p>Sign in with Google</p>
                    </div>
                  </button>
                )}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper signup-wraper">
          <div className="signup-box">
            <p>Don’t have an account?</p>
            <Link
              to="/register"
              state={{
                shopify: !urlPatter.test(userCheckoutType?.requested_path)
                  ? userCheckoutType
                  : "",
              }}
            >
              <button>Sign up</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12">
      <FormifyLoader removeLogo={true} text={"Please Wait ..."} />
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  loginUser,
  googleAuth,
  clearErrors,
})(Login);