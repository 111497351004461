/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AlertCircle } from "react-feather";
import RangeSlider from "../single-components/RangeSlider";
import Multiselect from "multiselect-react-dropdown";
import VerticalAligns from "../single-components/VerticalAligns";
import AlignmentTabs from "../single-components/AlignmentTabs";
import { Accordion } from "react-bootstrap";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import { BASE_DEV_URL, mapAuthAndFormStateToProps } from "../../../../utils";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setFormStatesDispatcher,
  setWidgetDispatcher,
} from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { CircularProgress } from "@mui/material";
import Select from "react-select";

import FormifyInput from "../single-components/FormifyInput";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";
import axios from "axios";
import { tokenConfig } from "../../../../actions/auth";
import store from "../../../../store";
import { toast } from "react-toastify";
import { async } from "q";
import SwitchChip from "../single-components/switchChip";
import { setRechargeProd } from "../../../../reducers/rechargeProdSlice";
import { setSubscriptionProducts } from "../../../../reducers/subscriptionProdSlice";
import { id } from "date-fns/locale";

const ProductContent = ({
  form: { form, widget, responsive },
  setWidgetDispatcher,
  storeToMethodMap,
  setFormStatesDispatcher,
  auth,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const selectRef = useRef(null);
  const [scrollEventListener, setScrollEventListener] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [subblyLoading, setSubblyLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const dispatch = useDispatch();
  const rechargeProd = useSelector((state) => state.rechargeProd);
  const subscriptionProducts = useSelector(
    (state) => state.subscriptionProducts
  );
  const dropdownRef = useRef(null);
  const [endCursor, setEndCursor] = useState(null);
  let layout =
    getResponsiveStyle(widget?.style, responsive, "layout", null) || 1;
  let rowGap =
    getResponsiveStyle(widget?.style, responsive, "rowGap", null) || "20px";
  let columnGap =
    getResponsiveStyle(widget?.style, responsive, "columnGap", null) || "20px";
  let colNum =
    getResponsiveStyle(widget?.content, responsive, "colNum", null) || 1;

  const productName = (product) => {
    if (product) {
      switch (form.store_selected) {
        case "shopify":
        case "recharge":
          return product.title;
        case "woocommerce":
        case "bigcommerce":
          return product.name;
        case "subbly":
          return product;
        default:
          return "No name provided";
      }
    } else {
      return "";
    }
  };

  const getSubblyProducts = useCallback(async (page) => {
    setSubblyLoading(true);
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/subbly/products?page=${page}`,
        tokenConfig(store.getState)
      );

      return result.data;
    } catch (error) {
      toast.error("There is something wrong with your Subbly connection.");
    } finally {
      setSubblyLoading(false);
    }
  }, []);

  const getWoocommerceProducts = useCallback(async (currentPage = 1) => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/woocommerce/products?page=${currentPage}`,
        tokenConfig(store.getState)
      );

      return result;
    } catch (error) {
      toast.error(
        "There is something wrong with your Woo Commerce connection."
      );
    }
  },
  []
  );

  const getShopifyProducts = useCallback(
    async (
      rechargeProd,
      page = 1,
      lastproduct,
      subscriptionProducts,
      after = ""
    ) => {
      setSubblyLoading(true);
      try {
        let result;
        const pageInfo = result?.data?.pageInfo ? result?.data?.pageInfo : "";
        setEndCursor(pageInfo.endCursor);
        if (rechargeProd) {
          result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/products?recharge=true&page=${page}`,
            tokenConfig(store.getState)
          );
        } else if (subscriptionProducts) {
          result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/products/subscriptions?first=12&${
              after ? "after=" + after : ""
            }`,
            tokenConfig(store.getState)
          );
          setEndCursor(result.data.pageInfo.endCursor);
        } else {
          result = await axios.get(
            `${BASE_DEV_URL}/store/shopify/products?${
              after ? "after=" + after : ""
            }`,
            tokenConfig(store.getState)
          );
          setEndCursor(result.data.pageInfo.endCursor);
        }
        return result;
      } catch (error) {
        console.error(error);
        console.log("eror", error);
        toast.error("There is something wrong with your shop connection.");
      } finally {
        setSubblyLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (form.store_selected === "shopify") {
      if (rechargeProd) {
        getShopifyProducts(true, 1, null, false);
      } else if (subscriptionProducts) {
        getShopifyProducts(false, 1, null, true);
      } else {
        getShopifyProducts(false, 1, null, false);
      }
    }
  }, [rechargeProd, subscriptionProducts, getShopifyProducts]);

  useEffect(() => {}, [rechargeProd]);

  useEffect(() => {}, [subscriptionProducts]);

  useEffect(() => {
    if (form.store_selected) {
      if (form.store_selected !== "subbly" && form.store_selected !== "woocommerce" ) {
        storeToMethodMap[form.store_selected]().then((res) => {
          let productList = [];
          res?.data?.products.map((product) => {
            productList.push({
              key: product.id.toString(),
              label: productName(product),
              sellingPlanId: product.sellingPlanId
                ? product.sellingPlanId
                : null,
            });
          });
          setProducts((prevProducts) => [...prevProducts, ...productList]);
          setLoading(false);
        });
      }
      else if(form.store_selected == "woocommerce"){
       
        storeToMethodMap[form.store_selected]().then((res) => {
          let productList = [];
          res?.data?.products.map((product) => {
            productList.push({
              key: product.id.toString(),
              label: productName(product),
            
            });
          });
          setProducts((prevProducts) => [...prevProducts, ...productList]);
          setLoading(false);
        });
      }
      else {
        storeToMethodMap[form.store_selected]().then((res) => {
          let productList = [];
          res?.data?.products.forEach((product) => {
            productList.push({
              key: product.id.toString(),
              label: productName(product.name),
            });
          });
          setProducts((prevProducts) => [...prevProducts, ...productList]);
          setLoading(false);
        });
      }
    } else {
      setProducts([]);
      setLoading(false);
    }
  }, [
    form.store_selected,
    page,
    getSubblyProducts,
    rechargeProd,
    getShopifyProducts,
    getWoocommerceProducts,
    subscriptionProducts,
  ]);

  const changeCheckBtn = (val, key) => {
    if (key === "required") {
      if (val) {
        handleSetWidgetDispatcher(
          [
            `style.desktop.hide`,
            `style.mobile.hide`,
            `style.tablet.hide`,
            `content.required`,
          ],
          [false, false, false, val],
          widget,
          setWidgetDispatcher
        );
      } else
        handleSetWidgetDispatcher(
          [`content.${key}`],
          [val],
          widget,
          setWidgetDispatcher
        );
    } else
      handleSetWidgetDispatcher(
        [`content.${key}`],
        [val],
        widget,
        setWidgetDispatcher
      );
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateSliderstyle = (e, type) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${e.target.id}`],
      [e.target.value + "px"],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const updateLayoutSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.layout`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateDesktopColumnNumberSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`content.desktop.colNum`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateTabletColumnNumberSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`content.tablet.colNum`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateMobileColumnNumberSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`content.mobile.colNum`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const selectedProd = (selectedOptions) => {
   
    const value = selectedOptions.map((option) => option.key.toString());

    handleSetWidgetDispatcher(
      [`content.products`, `content.products_objects_array`],
      [value, selectedOptions],
      widget,
      setWidgetDispatcher
    );
  };

  const updateFontFamily = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.title.fontFamily`,
        `style.desktop.price.fontFamily`,
        `style.desktop.promotional.fontFamily`,
        `style.desktop.informational.fontFamily`,
      ],
      [
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url },
      ],
      widget,
      setWidgetDispatcher,
      true
    );
  };

  const loadMoreProducts = useCallback(() => {
   
    setLoading(true);

    if (form.store_selected === "subbly") {
      getSubblyProducts(currentPage + 1)
        .then((data) => {
          const newProducts = data.products;

          let productList = [];
          newProducts.forEach((product) => {
            productList.push({
              key: product.id.toString(),
              label: product.name,
            });
          });

          if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...productList]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else  if (form.store_selected === "woocommerce") {
      getWoocommerceProducts(currentPage + 1)
        .then((res) => {
          const newProducts = res.data.products;
  
          let productList = [];
          newProducts.forEach((product) => {
            productList.push({
              key: product.id.toString(),
           
              label: productName(product),
            });
          });
  
          if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...productList]); // Concatenate new products
            setCurrentPage((prevPage) => prevPage + 1);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let lastProductId = products[products.length - 1]
        ? products[products.length - 1]
        : null;

      if (subscriptionProducts) {
        getShopifyProducts(false, currentPage + 1, null, true, endCursor)
          .then((data) => {
            let newProducts = data.data.products; // Assuming the response structure for subscription products

            let productList = [];
            newProducts.forEach((product) => {
              productList.push({
                key: product.id.toString(),
                label: product.title,
                sellingPlanId: product.sellingPlanId
                  ? product.sellingPlanId
                  : null,
              });
            });

            if (newProducts.length > 0) {
              setProducts((prevProducts) => [...prevProducts, ...productList]);
              setCurrentPage((prevPage) => prevPage + 1);
              setSelectOptions((prevOptions) => [
                ...prevOptions,
                ...productList.map((product) => ({
                  key: product.key,
                  value: product.key,
                  label: product.label,
                  sellingPlanId: product.sellingPlanId
                    ? product.sellingPlanId
                    : null,
                })),
              ]);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        getShopifyProducts(
          rechargeProd,
          currentPage,
          lastProductId,
          subscriptionProducts,
          endCursor
        )
          .then((data) => {
            let newProducts = rechargeProd
              ? data.data.data.products
              : subscriptionProducts
              ? data.products
              : data.data.products;

            let productList = [];
            newProducts.forEach((product) => {
              productList.push({
                key: product.id.toString(),
                label: rechargeProd ? product.title : product.title,
              });
            });

            if (newProducts.length > 0) {
              setProducts((prevProducts) => [...prevProducts, ...productList]);
              setCurrentPage(currentPage + 1);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    currentPage,
    endCursor,
    getSubblyProducts,
    getShopifyProducts,
    rechargeProd,
    getWoocommerceProducts,
    subscriptionProducts,
  ]);


  const handleScroll = (e) => {
    e.preventDefault();
    const { scrollTop, clientHeight, scrollHeight } = selectRef.current;
    const isScrollCloseToEnd = scrollTop + clientHeight + 20 >= scrollHeight;

    if (isScrollCloseToEnd && !subblyLoading) {
      loadMoreProducts();
    }
  };

  const handleCheckboxChange = (type) => {
    setProducts([]);
    if (type === "recharge") {
      dispatch(setRechargeProd(!rechargeProd));
      getShopifyProducts(!rechargeProd, 1, null, null, subscriptionProducts);
    } else if (type === "subscription") {
      const newSubscriptionProductsState = !subscriptionProducts;
      dispatch(setSubscriptionProducts(newSubscriptionProductsState));
      getShopifyProducts(
        rechargeProd,
        1,
        null,
        null,
        newSubscriptionProductsState
      );
    }
  };

  useEffect(() => {
    if (selectRef.current) {
      const scrollEventListener = (e) => handleScroll(e);
      selectRef.current.addEventListener("scroll", scrollEventListener);
      setLoading(true);
      setScrollEventListener(scrollEventListener);
    }

    return () => {
      if (selectRef.current && scrollEventListener) {
        selectRef.current.removeEventListener("scroll", scrollEventListener);
        setLoading(true);
      }
    };
  }, [currentPage, handleScroll]);

  useEffect(() => {
    setSelectOptions(
      products.map((product) => ({
        key: product.key,
        value: product.key,
        label: product.label,
        sellingPlanId: product.sellingPlanId ? product.sellingPlanId : null,
      }))
    );
  }, [products]);

  const searchWoocommerceProduct = useCallback(async () => {
    if (!searchVal.trim()) return; // Check if the search value is not empty
  
    setLoading(true);
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/woocommerce/products/${searchVal}`,
        tokenConfig(store.getState)
      );
  
      const product = result.data.product;
      setProducts([{
        key: product.id.toString(),
        label: productName(product),
      }]); // Set the search result as the only product in the products array
    } catch (error) {
      toast.error("An error occurred while searching for the product!");
    } finally {
      setLoading(false);
    }
  }, [searchVal]);

  
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      try {
        if (form.store_selected === "woocommerce") {
          searchWoocommerceProduct();
        }
        else{

        if (subscriptionProducts && form.store_selected !== "woocommerce") {
          const result = await axios.get(
            `${BASE_DEV_URL}/store/${form.store_selected}/products/subscriptions/${searchVal}`,
            tokenConfig(store.getState)
          );
          const products = result.data.products;
          products.map((product) => {
            setSelectOptions((prevOptions) => [
              ...prevOptions,
              {
                key: product.id.toString(),
                value: product.id.toString(),
                label: product.title,
                sellingPlanId: product.sellingPlanId
                  ? product.sellingPlanId
                  : null,
              },
            ]);
          });

          setSearchVal("");
        } else {
          const result = await axios.get(
            `${BASE_DEV_URL}/store/${form.store_selected}/product/${searchVal}`,
            tokenConfig(store.getState)
          );
          const product = result.data.product;
          setSelectOptions((prevOptions) => [
            ...prevOptions,
            {
              key: product.id.toString(),
              value: product.id.toString(),
              label: product.name,
            },
          ]);
          setSearchVal("");
        }
      }

      } catch (error) {
        toast.error("An error happened while searching for the product!");
      }
    }
  };

  const RechargeSelect = ({ rechargeProd, handleCheckboxChange }) => (
    <div className="recharge-select">
      <img
        src={process.env.PUBLIC_URL + "/assets/images/icons/rechargeicon.svg"}
        className={`nav-icons ${rechargeProd ? "checked" : ""}`}
        alt=""
        onClick={handleCheckboxChange}
        style={{ cursor: "pointer" }}
      />
      <p
        className="recharge-field"
        onClick={handleCheckboxChange}
        style={{
          cursor: "pointer",
          color: rechargeProd ? "#E51B49" : "#3901F1",
        }}
      >
        {rechargeProd ? "Disable Recharge Filter" : "Filter Recharge Products"}
      </p>
      <input
        id="recharge-checkbox"
        type="checkbox"
        name="recharge"
        onChange={handleCheckboxChange}
        checked={rechargeProd}
        style={{ display: "none" }}
      />
    </div>
  );

  const SubscriptionSelect = ({
    subscriptionProducts,
    handleCheckboxChange,
  }) => (
    <div className="my-3 subscription-select-filter">
      {/* <img
      src={process.env.PUBLIC_URL + "/assets/images/icons/rechargeicon.svg"}
      className={`nav-icons ${subscriptionProducts ? "checked" : ""}`}
      alt=""
      onClick={handleCheckboxChange}
      style={{ cursor: "pointer" }}
    /> */}
      <p
        className=""
        onClick={handleCheckboxChange}
        style={{
          cursor: "pointer",
          color: subscriptionProducts ? "#E51B49" : "#3901F1",
        }}
      >
        {subscriptionProducts
          ? "Disable Filter"
          : "Filter Subscription Products"}
      </p>
      <input
        id="recharge-checkbox"
        type="checkbox"
        name="subscription"
        onChange={handleCheckboxChange}
        checked={subscriptionProducts}
        style={{ display: "none" }}
      />
    </div>
  );

  return widget ? (
    <div className="settings-field-container">
      <div className="editable-field  quick-form-actions">
        <div className="products w-100">
          <p className="field-heading mb-0">Search product by id</p>
          {/* <input style={{margin: "10px auto 10px 0px"}} type="text" onKeyDown={handleKeyDown} onChange={(e)=>setSearchVal(e.target.value)} placeholder="Enter product ID" /> */}
          <div className="shopify-recharge">
            <p className="field-heading widget-label">Products</p>
            {form.store_selected === "shopify" &&
              auth?.user?.stores?.shopify?.recharge_api_key && (
                <RechargeSelect
                  rechargeProd={rechargeProd}
                  handleCheckboxChange={() => handleCheckboxChange("recharge")}
                />
              )}
            {form.store_selected === "shopify" &&
              !auth?.user?.stores?.shopify?.recharge_api_key && (
                <SubscriptionSelect
                  subscriptionProducts={subscriptionProducts}
                  handleCheckboxChange={() =>
                    handleCheckboxChange("subscription")
                  }
                />
              )}
          </div>
          {form.store_selected ? (
            products.length > 0 ? (
              form.store_selected === "subbly" ? (
                <Select
                  isMulti
                  options={selectOptions}
                  value={widget.content.products_objects_array}
                  onChange={(selectedOptions) => {
                    selectedProd(selectedOptions);
                  }}
                  onMenuScrollToBottom={loadMoreProducts}
                  isLoading={isLoading}
                  components={{
                    LoadingIndicator: () => (
                      <CircularProgress
                        size={18}
                        style={{ alignSelf: "center" }}
                      />
                    ),
                  }}
                  onKeyDown={handleKeyDown}
                  onInputChange={(value) => setSearchVal(value)}
                />
              ) : form.store_selected === "shopify" ? (
                <>
                  {/* <div className="recharge-select">
                <label htmlFor="recharge">Recharge products</label>
                <input
                id="recharge-checkbox"
                  type="checkbox"
                  name="recharge"
                  onChange={handleCheckboxChange}
                  checked={rechargeProd}
                />
              </div> */}
                  <Select
                    isMulti
                    options={selectOptions}
                    value={widget.content.products_objects_array}
                    onChange={(selectedOptions, sellingPlanId) => {
                      selectedProd(selectedOptions, sellingPlanId);
                    }}
                    onMenuScrollToBottom={loadMoreProducts}
                    isLoading={isLoading}
                    components={{
                      LoadingIndicator: () => (
                        <CircularProgress
                          size={18}
                          style={{ alignSelf: "center" }}
                        />
                      ),
                    }}
                    onKeyDown={handleKeyDown}
                    onInputChange={(value) => setSearchVal(value)}
                  />
                </>
              ) : (
                <>
                  <Select
                  isMulti
                  options={selectOptions}
                  value={widget.content.products_objects_array}
                  onChange={(selectedOptions) => {
                    selectedProd(selectedOptions);
                  }}
                  onMenuScrollToBottom={loadMoreProducts}
                  isLoading={isLoading}
                  components={{
                    LoadingIndicator: () => (
                      <CircularProgress
                        size={18}
                        style={{ alignSelf: "center" }}
                      />
                    ),
                  }}
                  onKeyDown={handleKeyDown}
                  onInputChange={(value) => setSearchVal(value)}
                />
                </>
                // <>
                //   <Multiselect
                //     ref={dropdownRef}
                //     options={products}
                //     displayValue="label"
                //     selectedValues={widget.content.products_objects_array}
                //     multiselectContainer={{
                //       onScroll: loadMoreProducts, 
                //     }}
                //     onRemove={(e) => {
                //       selectedProd(e);
                //     }}
                //     onSelect={(e) => {
                //       selectedProd(e);
                //     }}
                //     style={{
                //       height: "400px",
                //       overflow: "auto",
                //       zIndex: 99999,
                //       multiselectContainer: {
                //         background: "#fff",
                //         cursor: "pointer",
                //       },
                //       searchBox: {
                //         border: "1px solid #7e7e7e",
                //         borderRadius: "14px",
                //       },
                //       chips: {
                //         background: "#6563ff",
                //       },
                //       optionContainer: {
                //         border: "1px solid #7e7e7e",
                //         borderRadius: "14px",
                //       },
                //       option: {
                //         fontSize: "14px",
                //       },
                //     }}
                //   />
                // </>
              )
            ) : (
              <div className="col-12">
                <p className="formify-p">No Products Exist!</p>

                {form.store_selected !== "subbly" ? (
                  <p className="formify-p">
                    No products exist on the connected store.
                  </p>
                ) : (
                  <p className="formify-p">
                    Click{" "}
                    <a href={`${window.location.origin}/integrations/subbly`}>
                      here{" "}
                    </a>{" "}
                    to create a product link!
                  </p>
                )}
              </div>
            )
          ) : (
            <div className="col-12">
              <h4 className="formify-h4">Store is not connected</h4>
              <div
                className="d-flex flex-row alert-danger align-items-center p-2"
                style={{ borderRadius: "7px" }}
              >
                <AlertCircle className="me-3" />
                <p className="formify-p">
                  To use this widget you need to connect a store. Go to
                  <span
                    style={{ color: "#6563ff", cursor: "pointer" }}
                    onClick={() =>
                      setFormStatesDispatcher(true, "openIntegrations")
                    }
                  >
                    {" "}
                    Integrations
                  </span>{" "}
                  and select your favorite store.
                </p>
              </div>
            </div>
          )}
        </div>
        {widget.content.products.map((op, index) => {
          return (
            <Accordion defaultActiveKey="0" className="w-100 mt-1">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="field-label border-label">
                  Extra Options for Product no. {index + 1}
                </Accordion.Header>
                <Accordion.Body className="accordion_body">
                  <div className="w-100">
                    <div className="input-holder">
                      <p className="my-0">Promotional text</p>
                      <FormifyInput
                        type="text"
                        id={"promotionalText"}
                        onChange={(e, type) => {
                          handleSetWidgetDispatcher(
                            [
                              `content.products_objects_array.${index}.promotionalText`,
                            ],
                            [e.target.value],
                            widget,
                            setWidgetDispatcher,
                            false,
                            type
                          );
                        }}
                        value={
                          widget.content.products_objects_array
                            ? widget.content.products_objects_array[index]
                                .promotionalText
                            : ""
                        }
                      />
                    </div>
                    <div className="input-holder mt-3">
                      <p className="my-0">Informational text</p>
                      <FormifyInput
                        type="text"
                        id={"informationalText"}
                        onChange={(e, type) => {
                          handleSetWidgetDispatcher(
                            [
                              `content.products_objects_array.${index}.informationalText`,
                            ],
                            [e.target.value],
                            widget,
                            setWidgetDispatcher,
                            false,
                            type
                          );
                        }}
                        value={
                          widget.content.products_objects_array
                            ? widget.content.products_objects_array[index]
                                .informationalText
                            : ""
                        }
                      />
                    </div>
                    <hr className="mb-0" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
        <div className="editable-field form-row mt-2">
          <p className="field-label">Hide Product Name</p>
          <SwitchChip
            id="hide_label"
            checked={widget.content.hide_label || false}
            onChange={(val) => {
              changeCheckBtn(val, "hide_label");
            }}
          />
        </div>
        <div className="editable-field form-row mt-2">
          <p className="field-label">Hide Product Price</p>
          <SwitchChip
            id="hide_price"
            checked={widget.content.hide_price || false}
            onChange={(val) => {
              changeCheckBtn(val, "hide_price");
            }}
          />
        </div>
        <div className="mt-3 w-100">
          <div className="quick-action-container">
            <AlignmentTabs
              tag={getResponsiveStyle(
                widget?.style,
                responsive,
                "alignment",
                null
              )}
              handleSelect={handleChangeAlign}
              id={"alignment"}
            />
          </div>
          <div className="quick-action-container font-family-picker mt-3">
            <FontFamilyPicker
              attribute={getResponsiveStyle(
                widget?.style,
                responsive,
                "desktop",
                "title"
              )}
              onChangeFont={updateFontFamily}
              id={"label"}
            />
            <hr />
          </div>
        </div>
        <div className="editable-field form-row w-100">
          <p> Layout</p>
          <div className="widget-range-slider-container">
            <DropdownButton
              title={widget ? "Layout " + layout : "Layout 1"}
              id={"layout"}
              name={"layout"}
              onSelect={updateLayoutSelectOptions}
              className="form-dropdown-select"
            >
              <Dropdown.Item eventKey="1">Layout 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Layout 2</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        {layout === "2" ? (
          <>
            <RangeSlider
              label={"Column Gap"}
              id={"columnGap"}
              tag={columnGap}
              min={0}
              max={200}
              onChange={updateSliderstyle}
              removeXPadding={true}
            />
            <RangeSlider
              label={"Row Gap"}
              id={"rowGap"}
              tag={rowGap}
              min={0}
              max={200}
              onChange={updateSliderstyle}
              removeXPadding={true}
            />
          </>
        ) : (
          <VerticalAligns
            tag={getResponsiveStyle(
              widget?.style,
              responsive,
              "verticalAlignment",
              null
            )}
            handleSelect={handleChangeAlign}
            id={"verticalAlignment"}
            removeXPadding={true}
          />
        )}

        {layout === "2" ? (
          <div className="editable-field form-row mt-3 w-100">
            <p> Column Number</p>
            <div className="widget-range-slider-container">
              <DropdownButton
                title={colNum}
                id={"colNum"}
                name={"colNum"}
                onSelect={
                  responsive === "mobile"
                    ? updateMobileColumnNumberSelectOptions
                    : responsive === "tablet"
                    ? updateTabletColumnNumberSelectOptions
                    : updateDesktopColumnNumberSelectOptions
                }
                className="form-dropdown-select"
              >
                <Dropdown.Item eventKey="1"> 1</Dropdown.Item>
                <Dropdown.Item eventKey="2"> 2</Dropdown.Item>
                <Dropdown.Item eventKey="3"> 3</Dropdown.Item>
                <Dropdown.Item eventKey="4"> 4</Dropdown.Item>
                <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                <Dropdown.Item eventKey="6"> 6</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetDispatcher,
  setFormStatesDispatcher,
})(ProductContent);
