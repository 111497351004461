import { Draggable } from "react-beautiful-dnd";
import { connect, useDispatch } from "react-redux";
import {
  setFormActiveStepDispatcher,
  setResponsesDispatcher,
} from "../../../actions/form";
import { setWidgetResponsesDispatcher } from "../../../actions/form";

import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";

export const SkipStepRender = ({
  element,
  outerSnapshot,
  index,
  steps,
  editElement,
  isEditable,
  screen,
  setFormActiveStepDispatcher,
  setWidgetResponsesDispatcher,

  form: { responses, responseError, activeFormStep, form },
}) => {
  const currentStep = form.steps[activeFormStep - 1];

  const dispatch = useDispatch();
  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({
          ...element,
        })
      }
    >
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className={"widget-inner-container"}>
              <p
                className="wrappable-text"
                style={{
                  ...element.style[screen],
                }}
                onClick={() => {
                  let newResponses = JSON.parse(JSON.stringify(responses));
                  for (let key in newResponses) {
                    currentStep.elements.map((element) => {
                      if (element._id == key) {
                        newResponses[key].value = "";
                      }
                    });
                  }
                  dispatch(setResponsesDispatcher(newResponses));

                  setFormActiveStepDispatcher(activeFormStep + 1);
                }}
              >
                {element.content.text}
              </p>
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
  setFormActiveStepDispatcher,
})(SkipStepRender);
