import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import WidgetEditTabs from "../WidgetEditTabs";
import { getDynamicTagComponent } from "./renderersFunctions";
import { checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

export const HeadingRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  screen,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
}) => {
  const HeaderTag = `${element.content.headingLevel}`;
  const SubheaderTag = `${element.content.subheadingLevel}`;

  let alignment = getResponsiveStyle(element?.style, screen, "heading", "textAlign");

  return (
    <div
      className={
        "widget " + element.options.className + " widget-" + element._id
      }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>
        isEditable &&
        editElement({ 
          ...element,
        })
      }
    >
    <Draggable
      key={element._id}
      draggableId={element._id.toString()}
      index={index}
      isDragDisabled={!isEditable}
    >
      {(provided, snapshot) => (
        <div
          className={
            snapshot.isDragging && outerSnapshot.isDraggingOver
              ? index === 0
                ? " form-widget isDragging"
                : " mt-3 form-widget isDragging"
              : "  mt-3 form-widget"
          }
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          { element.content.heading_hidden && element.content.subheading_hidden && (
            <p>This heading widget has both heading and subheading empty.</p>
          )}
          { !element.content.heading_hidden && (
            <div
              className={"widget-inner-container"}
              style={
                (checkResponsiveStyle(element.style, screen, "width"),
                { margin: alignment === "center"  ? "auto" : alignment  === "left"  ? "auto 0px 0px 0px": "0px 0px 0px auto" })
              }
            >
            <HeaderTag
              style={{
                ...checkResponsiveStyle(element.style, screen, "heading"),
                fontFamily: element?.style?.desktop?.heading?.fontFamily?.name
              }}
              >
                {getDynamicTagComponent(element.content.heading, responses)}
              </HeaderTag>
            </div>
          )}
          { !element.content.subheading_hidden && (
            <div 
              className={"widget-inner-container"}
              style={
                { 
                  width: checkResponsiveStyle(element.style, screen, "width"),
                  fontFamily: element.style?.desktop?.heading?.fontFamily?.name,
                  margin: alignment === "center"  ? "auto" : alignment  === "left"  ? "auto 0px 0px 0px": "0px 0px 0px auto"
                
                }
              }
            >
              <SubheaderTag
                style={{
                  ...checkResponsiveStyle(element.style, screen, "subheading"),
                  fontFamily: element.style?.desktop?.heading?.fontFamily?.name
                }}
              >
                {element.content.subHeading}
              </SubheaderTag>
            </div>
          )}
          
          {isEditable && (
            <WidgetEditTabs element={element} editElement={editElement} />
          )}
        </div>
      )}
    </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(HeadingRender);
