import { Dropdown, DropdownButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import InfoIcon from "../../../utils/InfoIcon";

let firstTime = true;
const InputField = ({
  label,
  type,
  id,
  value,
  onChange,
  placeholder,
  isRow = false,
  numRows = 2,
  mergeWidget = [],
  isTitle,
  parentClass = ""
}) => {
  // GET OPTIONS OF MERGE TAGS
  const [mergeOptions, setMergeOptions] = useState([]);

  useEffect(() => {
    setMergeOptions(mergeWidget);
  }, []);

  const handleSelect = (e, id) => {
    onChange({
      target: {
        id: id.id,
        value: value+ " ["+e+"]"
      }
    });
  }

  const changeVal = (e) => {
    onChange(e, firstTime ? "focus" : "ignore");
    firstTime = false;
  }

  return (
    <div
      className={
        isRow
          ? `editable-field form-row input-holder ${parentClass}`
          : `editable-field input-holder ${parentClass}`
      }
    >
      <div className="row-display merge-options">
      <div className={`field-heading mb-0 ${isTitle ? "isTitle" : ""}`}>{label} <p>{isTitle && <InfoIcon />}</p> </div>

        
        <p>
          {" "}
          {mergeOptions.length > 0 && (
            <DropdownButton
              title={"Dynamic Tags"}
              id={id}
              onSelect={(e) => handleSelect(e, { id: id })}
              x
              className="form-dropdown-select dropdown-merge"
            >
              {mergeOptions.map((m) => (
                <Dropdown.Item eventKey={m.id} className="row-display">
                    <p>{m.name}</p>
                    <p>(From Step {m.step})</p>
                  </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </p>
      </div>
      <textarea
        rows={numRows}
        autoComplete="off"
        type={type}
        id={id}
        value={value}
        onChange={changeVal}
        onBlur={ e => {
          if(!firstTime) onChange(e, "blur")
          firstTime = true;
        }}
        name={id}
        placeholder={placeholder}
        className="mt-0"
      />
    </div>
  );
};

export default InputField;