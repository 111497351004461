import { Link } from "react-router-dom";
import { Search, X } from "react-feather";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_DEV_URL } from "../../../../utils";
import styled, { createGlobalStyle } from "styled-components";
import store from "../../../../store";
import { tokenConfig } from "../../../../actions/auth";
import { Plus } from "react-feather";
import FontLoader from "../../../loaders/Font";
import InfiniteScroll from "react-infinite-scroll-component";

const GlobalStyle = styled.section`
  @font-face {
    font-family: "${(props) => props.name}";
    src: url(${(props) => props.url});
  } ;
`;

const FontFamilyPicker = ({ attribute, onChangeFont, id }) => {
  const [openFonts, setOpenFonts] = useState(false);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");
  const [fonts, setFonts] = useState([]);
  const [customFonts, setCustomFonts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fontLimit, setFontLimit] = useState(0);
  const [loadMore, setLoadMore] = useState(true);


  const [debouncedSearch, setDebouncedSearch] = useState("");

useEffect(() => {
  const timerId = setTimeout(() => {
    setDebouncedSearch(search);
  }, 1000); 

  return () => {
    clearTimeout(timerId);
  };
}, [search]);

  useEffect(() => {
    getFonts();
    getCustomFonts();
  }, []);


  
  useEffect(() => {
    getFonts();
  }, [debouncedSearch]);

  const getFonts = () => {
    const batchSize = 20; 
    axios
      .get(`${BASE_DEV_URL}/font`, {
        params: {
          skipNo: 0,
          keyword: search,
          limit: batchSize,
        },
      })
      .then((res) => {
        setFonts(res.data.fonts);
        setFontLimit(res.data.countData);
        setSkip((prevSkip) => prevSkip + batchSize);
        if (res.data.fonts.length === res.data.countData) setLoadMore(false);
        else setLoadMore(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomFonts = () => {
    axios
      .get(`${BASE_DEV_URL}/customFont`, tokenConfig(store.getState))
      .then((res) => {
        setCustomFonts(res.data.fonts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (loading) {
      var batchSize= 15;
      setTimeout(() => {
        axios
          .get(`${BASE_DEV_URL}/font`, {
            params: {
              skipNo: skip + batchSize,
              keyword: search,
            },
          })
          .then((res) => {
            var merge = fonts.concat(res.data.fonts);
            setFonts(merge);
            setSkip(skip + 70);
            setLoading(false);
            if (merge.length === fontLimit) setLoadMore(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1500);
    }
  }, [loading]);

  const fetchMore = () => {
    setLoading(true);
  };

  //create a new array by filtering the original array
  const filtertCustomFont = customFonts.filter((el) => {
    if (search.length === 0) {
      return el;
    } else {
      return el.name.toLowerCase().includes(search);
    }
  });
  
  return (
    <>
      <p>Font Family</p>
      <div className="font-container" onClick={() => setOpenFonts(true)}>
        {attribute?.fontFamily?.name
          ? attribute?.fontFamily?.name
          : "Not Selected"}
      </div>
      {openFonts && (
        <>
          <div className="font-options">
            <div
              className="remove"
              href="#/"
              onClick={() => {
                setOpenFonts(false);
              }}
            >
              <X style={{ color: "#000" }} />
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder='Try "Poppins" or "Open Sans" '
                onChange={(e) => {
                  // setTimeout(()=>{
                    setSearch(e.target.value);
                  // },1000)
                }}
              />
              <Search
                style={{
                  width: "24px",
                  height: " 24px",
                  color: "#383838",
                }}
              />
            </div>
            {(fonts.length > 0 || filtertCustomFont.length > 0) ? (
              <ul
                className="general"
                style={{ height: "80vh", "overflow": "hidden" }}
              >
                <InfiniteScroll
                  dataLength={fonts.length + filtertCustomFont.length} //This is important field to render the next data
                  next={fetchMore}
                  hasMore={loadMore}
                  loader={<FontLoader />}
                  height={"80vh"}
                  style={{ "overflowX": "hidden" }}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  <li>My Fonts</li>
                  {filtertCustomFont.length > 0 &&
                    filtertCustomFont.map((font) => {
                      return (
                        <div key={font._id}>
                          <li className="title-font">{font.name}</li>
                          <li className="subfont-container">
                            <ul className="subfont">
                              {font.src.map((f) => {
                                return (
                                  <GlobalStyle
                                    key={f._id}
                                    name={f.name}
                                    url={f.src}
                                  >
                                    <li
                                      className={
                                        attribute?.fontFamily?.name == f.name
                                          ? "active-font"
                                          : ""
                                      }
                                      style={{ fontFamily: f.name }}
                                      onClick={() =>{
                                        onChangeFont(f.name, f.source);
                                      }
                                      }
                                    >
                                      {f.name}
                                      {attribute?.fontFamily?.name ==
                                        f.name && <span></span>}
                                    </li>
                                  </GlobalStyle>
                                );
                              })}
                            </ul>
                          </li>
                        </div>
                      );
                    })}
                  <li className="link-container">
                    <Link to="/resources/fonts/upload">
                      <Plus style={{ marginBottom: "5px", color: "#4e4bfa" }} />{" "}
                      Add New Font
                    </Link>
                  </li>
                  {fonts.map((font) => {
                    return (
                      <div key={font._id}>
                        <li className="title-font">{font.name}</li>
                        <li className="subfont-container">
                          <ul className="subfont">
                            {font.src.map((f) => {
                              return (
                                <GlobalStyle
                                  key={f._id}
                                  name={f.name}
                                  url={f.src}
                                >
                                  <li
                                    className={
                                      attribute?.fontFamily?.name == f.name
                                        ? "active-font"
                                        : ""
                                    }
                                    style={{ fontFamily: f.name }}
                                    
                                    onClick={() => {
                                      onChangeFont(f.name, f.source)}}
                                  >
                                    {f.name}
                                    {attribute?.fontFamily?.name == f.name && (
                                      <span></span>
                                    )}
                                  </li>
                                </GlobalStyle>
                              );
                            })}
                          </ul>
                        </li>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </ul>
            ) : (
              <p>No Font Found! </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FontFamilyPicker;
