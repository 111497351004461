/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import BorderOptions from "../single-components/BorderOptions";
import RangeSlider from "../single-components/RangeSlider";
import { Accordion } from "react-bootstrap";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import FormifyInput from "../single-components/FormifyInput";
import {
  checkFontSize,
  getResponsiveStyle,
} from "../../../utils/responsiveFunctions";

const DynamicProductStyle = ({
  setWidgetDispatcher,
  form: { widget, responsive },
}) => {
  const [titleMarginLockValues, setTitleMarginLockValues] = useState(false);
  const [titlePaddingLockValues, setTitlePaddingLockValues] = useState(false);
  const [promotionalMarginLockValues, setPromotionalMarginLockValues] =
    useState(true);
  const [promotionalPaddingLockValues, setPromotionalPaddingLockValues] =
    useState(true);
  const [priceMarginLockValues, setPriceMarginLockValues] = useState(false);
  const [pricePaddingLockValues, setPricePaddingLockValues] = useState(false);
  const [imageMarginLockValues, setImageMarginLockValues] = useState(false);
  const [imagePaddingLockValues, setImagePaddingLockValues] = useState(false);
  const [borderContainerWidthLockValues, setBorderContainerWidthLockValues] =
    useState(true);
  const [borderContainerRadiusLockValues, setBorderContainerRadiusLockValues] =
    useState(true);
  const [containerMarginLockValues, setContainerMarginLockValues] =
    useState(true);
  const [containerPaddingLockValues, setContainerPaddingLockValues] =
    useState(true);
  const [imageBorderWidthLockValues, setImageBorderWidthLockValues] =
    useState(true);
  const [imageBorderRadiusLockValues, setImageBorderRadiusLockValues] =
    useState(true);
  const [informationalMarginLockValues, setInformationalMarginLockValues] =
    useState(false);
  const [informationalPaddingLockValues, setInformationalPaddingLockValues] =
    useState(false);

  // Woocomerce attributes
  const [labelMarginLockValues, setLabelMarginLockValues] = useState(false);
  const [labelPaddingLockValues, setLabelPaddingLockValues] = useState(false);
  const [inputBorderWidthLockValues, setInputBorderWidthLockValues] = useState(true);
  const [inputBorderRadiusLockValues, setInputBorderRadiusLockValues] = useState(true);
  const [inputMarginLockValues, setInputMarginLockValues] = useState(false);
  const [inputPaddingLockValues, setInputPaddingLockValues] = useState(false);

  const updateTitleFont = (e) => {
    handleSetWidgetDispatcher(
      [`style.desktop.title.fontFamily`],
      [e.family],
      widget,
      setWidgetDispatcher
    );
  };

  const updatePromotionalFont = (e) => {
    handleSetWidgetDispatcher(
      [`style.desktop.promotional.fontFamily`],
      [e.family],
      widget,
      setWidgetDispatcher
    );
  };

  const updatePriceFont = (e) => {
    handleSetWidgetDispatcher(
      [`style.desktop.price.fontFamily`],
      [e.family],
      widget,
      setWidgetDispatcher
    );
  };

  const updateTitleSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.title.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updatePromotionalStyle = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.promotional.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateInformationalSliderstyle = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.informational.${e.target.id}`],
      [e.target.value + "px"],
      widget,
      setWidgetDispatcher
    );

    if (e.target.id.substring(0, 6) === "margin") {
      if (informationalMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.informational.marginBottom`,
            `style.${responsive}.informational.marginRight`,
            `style.${responsive}.informational.marginLeft`,
            `style.${responsive}.informational.marginTop`,
          ],
          widget,
          setWidgetDispatcher
        );
      }
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (informationalPaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.informational.paddingBottom`,
            `style.${responsive}.informational.paddingRight`,
            `style.${responsive}.informational.paddingLeft`,
            `style.${responsive}.informational.paddingTop`,
          ],
          widget,
          setWidgetDispatcher
        );
      }
    }
  };

  const updatePromotionalSliderstyle = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.promotional.${e.target.id}`],
      [e.target.value + "px"],
      widget,
      setWidgetDispatcher
    );

    if (e.target.id.substring(0, 6) === "margin") {
      if (promotionalMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.promotional.marginBottom`,
            `style.${responsive}.promotional.marginRight`,
            `style.${responsive}.promotional.marginLeft`,
            `style.${responsive}.promotional.marginTop`,
          ],
          widget,
          setWidgetDispatcher
        );
      }
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (promotionalPaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.promotional.paddingBottom`,
            `style.${responsive}.promotional.paddingRight`,
            `style.${responsive}.promotional.paddingLeft`,
            `style.${responsive}.promotional.paddingTop`,
          ],
          widget,
          setWidgetDispatcher
        );
      }
    }
  };

  const updateTitleSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.title.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (e.target.id.substring(0, 6) === "margin") {
      if (titleMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.title.marginBottom`,
            `style.${responsive}.title.marginRight`,
            `style.${responsive}.title.marginLeft`,
            `style.${responsive}.title.marginTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (titlePaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.title.paddingBottom`,
            `style.${responsive}.title.paddingRight`,
            `style.${responsive}.title.paddingLeft`,
            `style.${responsive}.title.paddingTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updatePromotional = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.promotional.${e.target.id}`],
      [e.target.value + "px"],
      widget,
      setWidgetDispatcher
    );
  };

  const updatePriceSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.price.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (e.target.id.substring(0, 6) === "margin") {
      if (priceMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.price.marginBottom`,
            `style.${responsive}.price.marginRight`,
            `style.${responsive}.price.marginLeft`,
            `style.${responsive}.price.marginTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (pricePaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.price.paddingBottom`,
            `style.${responsive}.price.paddingRight`,
            `style.${responsive}.price.paddingLeft`,
            `style.${responsive}.price.paddingTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updatePriceSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.price.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateColorTitle = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.title.color`,
        `style.tablet.title.color`,
        `style.mobile.title.color`,
      ],
      [value, value, value],
      widget,
      setWidgetDispatcher
    );
  };

  const updateFontSize = (name, value, type) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${name}.fontSize`],
      [value + "px"],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const updateColorPrice = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.price.color`,
        `style.tablet.price.color`,
        `style.mobile.price.color`,
      ],
      [value, value, value],
      widget,
      setWidgetDispatcher
    );
  };

  const updatePromotionalColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.promotional.color`,
        `style.tablet.promotional.color`,
        `style.mobile.promotional.color`,
      ],
      [value, value, value],
      widget,
      setWidgetDispatcher
    );
  };

  const updateInformationalColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.informational.color`,
        `style.tablet.informational.color`,
        `style.mobile.informational.color`,
      ],
      [value, value, value],
      widget,
      setWidgetDispatcher
    );
  };

  const updateSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.image.${e.target.id}`],
        [e.target.value + "%"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
        "Width" &&
      e.target.id !== "maxWidth"
    ) {
      if (imageBorderWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (imageBorderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateImageSlider = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.image.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
        "Width" &&
      e.target.id !== "maxWidth"
    ) {
      if (imageBorderWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (imageBorderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateImageSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.image.${e.target.id}`],
        [e.target.value + "%"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (e.target.id.substring(0, 6) === "margin") {
      if (imageMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.marginBottom`,
            `style.${responsive}.image.marginRight`,
            `style.${responsive}.image.marginLeft`,
            `style.${responsive}.image.marginTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (imagePaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.paddingBottom`,
            `style.${responsive}.image.paddingRight`,
            `style.${responsive}.image.paddingLeft`,
            `style.${responsive}.image.paddingTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (imageBorderWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (imageBorderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateContainerBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.container.borderColor`],
      [value],
      widget,
      setWidgetDispatcher
    );
  };

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.image.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.checkbox.borderColor`,
        `style.tablet.checkbox.borderColor`,
        `style.mobile.checkbox.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorBackgroundContainer = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.container.backgroundColor`,
        `style.tablet.container.backgroundColor`,
        `style.mobile.container.backgroundColor`,
      ],
      [value, value, value],
      widget,
      setWidgetDispatcher
    );
  };

  const updateContainerSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.container.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (e.target.id.substring(0, 6) === "margin") {
      if (containerMarginLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.container.marginBottom`,
            `style.${responsive}.container.marginRight`,
            `style.${responsive}.container.marginLeft`,
            `style.${responsive}.container.marginTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (containerPaddingLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.container.paddingBottom`,
            `style.${responsive}.container.paddingRight`,
            `style.${responsive}.container.paddingLeft`,
            `style.${responsive}.container.paddingTop`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
      "Width"
    ) {
      if (borderContainerWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.container.borderLeftWidth`,
            `style.${responsive}.container.borderTopWidth`,
            `style.${responsive}.container.borderRightWidth`,
            `style.${responsive}.container.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (borderContainerRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.container.borderTopLeftRadius`,
            `style.${responsive}.container.borderTopRightRadius`,
            `style.${responsive}.container.borderBottomRightRadius`,
            `style.${responsive}.container.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateContainerSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.container.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateAttrLabelSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.attr_label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (labelMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_label.marginBottom`,
            `style.${responsive}.attr_label.marginRight`,
            `style.${responsive}.attr_label.marginLeft`,
            `style.${responsive}.attr_label.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (labelPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_label.paddingBottom`,
            `style.${responsive}.attr_label.paddingRight`,
            `style.${responsive}.attr_label.paddingLeft`,
            `style.${responsive}.attr_label.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateAttrInputFont = (e) => {
    handleSetWidgetDispatcher(
      [`style.desktop.attr_input.fontFamily`], [e.family], widget, setWidgetDispatcher
    );
  };

  const updateAttrInputSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.attr_input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (inputMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_input.marginBottom`,
            `style.${responsive}.attr_input.marginRight`,
            `style.${responsive}.attr_input.marginLeft`,
            `style.${responsive}.attr_input.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (inputBorderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_input.borderLeftWidth`,
            `style.${responsive}.attr_input.borderTopWidth`,
            `style.${responsive}.attr_input.borderRightWidth`,
            `style.${responsive}.attr_input.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (inputPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_input.paddingBottom`,
            `style.${responsive}.attr_input.paddingRight`,
            `style.${responsive}.attr_input.paddingLeft`,
            `style.${responsive}.attr_input.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (inputBorderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.attr_input.borderTopLeftRadius`,
            `style.${responsive}.attr_input.borderTopRightRadius`,
            `style.${responsive}.attr_input.borderBottomLeftRadius`,
            `style.${responsive}.attr_input.borderBottomRightRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateAttrLabelFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.attr_label.fontFamily`], [e.family], widget, setWidgetDispatcher, true
    );
  };
  const updateAttrLabelSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.attr_label.${id}`], [e], widget, setWidgetDispatcher);
  };
  const updateAttrInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.attr_input.${id}`], [e], widget, setWidgetDispatcher);
  };
  const updateAttrColorBackgroundInput = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.attr_input.backgroundColor`,
        `style.tablet.attr_input.backgroundColor`,
        `style.mobile.attr_input.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };
  const updateAttrInputBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.attr_input.borderColor`,
        `style.tablet.attr_input.borderColor`,
        `style.mobile.attr_input.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  return (
    <>
      <div className="settings-field-container">
        {/* Wocommerce attributes  */}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Attributes</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field mt-2">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Label
                    </Accordion.Header>
                    <Accordion.Body>
                      <TypographyPopup
                        attribute={getResponsiveStyle(widget?.style, responsive, "attr_label", null)}
                        onChangeFont={updateAttrLabelFont}
                        updateSliderstyle={updateAttrLabelSliderstyle}
                        updateSelectstyle={updateAttrLabelSelectstyle}
                        id={"attr_label"} 
                      />
                      <hr/>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="field-label">
                            Spacing
                          </Accordion.Header>
                          <Accordion.Body>
                            <Spacer 
                              label={"Margin"}
                              id={"margin"}
                              tagLeft={getResponsiveStyle(widget?.style, responsive, "attr_label", "marginLeft")}
                              tagRight={getResponsiveStyle(widget?.style, responsive, "attr_label", "marginRight")}
                              tagTop={getResponsiveStyle(widget?.style, responsive, "attr_label", "marginTop")}
                              tagBottom={getResponsiveStyle(widget?.style, responsive, "attr_label", "marginBottom")}
                              onChange={updateAttrLabelSliderstyle}
                              setLockValue={setLabelMarginLockValues}
                              lockValue={labelMarginLockValues}
                            />
                            <Spacer 
                              label={"Padding"}
                              id={"padding"}
                              tagLeft={getResponsiveStyle(widget?.style, responsive, "attr_label", "paddingLeft")}
                              tagRight={getResponsiveStyle(widget?.style, responsive, "attr_label", "paddingRight")}
                              tagTop={getResponsiveStyle(widget?.style, responsive, "attr_label", "paddingTop")}
                              tagBottom={getResponsiveStyle(widget?.style, responsive, "attr_label", "paddingBottom")}
                              onChange={updateAttrLabelSliderstyle}
                              setLockValue={setLabelPaddingLockValues}
                              lockValue={labelPaddingLockValues}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <hr/>
              <div className="editable-field">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">Input</Accordion.Header>
                    <Accordion.Body>
                      <div className="editable-field ">
                        <TypographyPopup
                          attribute={getResponsiveStyle(widget?.style, responsive, "attr_input", null)}
                          onChangeFont={updateAttrInputFont}
                          updateSliderstyle={updateAttrInputSliderstyle}
                          updateSelectstyle={updateAttrInputSelectstyle}
                          id={"attr_input"} 
                        />{" "}
                        <hr />
                        <div className="editable-field form-row  ">
                          <p>Background Color</p>

                          <ColorPickerStyle
                            onChange={updateAttrColorBackgroundInput}
                            id={"attr_color"}
                            tag={widget.style?.attr_input?.backgroundColor}
                          />
                        </div>
                        <hr />
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="field-label">
                              Spacing
                            </Accordion.Header>
                            <Accordion.Body>
                              <Spacer 
                                label={"Margin"}
                                id={"margin"}
                                tagLeft={getResponsiveStyle(widget?.style, responsive, "attr_input", "marginLeft")}
                                tagRight={getResponsiveStyle(widget?.style, responsive, "attr_input", "marginRight")}
                                tagTop={getResponsiveStyle(widget?.style, responsive, "attr_input", "marginTop")}
                                tagBottom={getResponsiveStyle(widget?.style, responsive, "attr_input", "marginBottom")}
                                onChange={updateAttrInputSliderstyle}
                                setLockValue={setInputMarginLockValues}
                                lockValue={inputMarginLockValues}
                              />
                              <Spacer 
                                label={"Padding"}
                                id={"padding"}
                                tagLeft={getResponsiveStyle(widget?.style, responsive, "attr_input", "paddingLeft")}
                                tagRight={getResponsiveStyle(widget?.style, responsive, "attr_input", "paddingRight")}
                                tagTop={getResponsiveStyle(widget?.style, responsive, "attr_input", "paddingTop")}
                                tagBottom={getResponsiveStyle(widget?.style, responsive, "attr_input", "paddingBottom")}
                                onChange={updateAttrInputSliderstyle}
                                setLockValue={setInputPaddingLockValues}
                                lockValue={inputPaddingLockValues}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <hr />
                        <BorderOptions
                          attribute={getResponsiveStyle(widget?.style, responsive, "attr_input", null)}
                          onChange={updateAttrInputSliderstyle}
                          onChangeColor={updateAttrInputBorderColor}
                          setBorderWidthLockValues={setInputBorderWidthLockValues}
                          setBorderRadiusLockValues={setInputBorderRadiusLockValues}
                          borderWidthLockValues={inputBorderWidthLockValues}
                          borderRadiusLockValues={inputBorderRadiusLockValues}
                          updateSelectOptions={updateAttrInputSelectstyle} 
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Title</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  attribute={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "title",
                    null
                  )}
                  onChangeFont={updateTitleFont}
                  updateSliderstyle={updateTitleSliderstyle}
                  updateSelectstyle={updateTitleSelectstyle}
                  id={"title"}
                />
                <div className="editable-field  form-row quick-form-actions pt-2 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1">
                        <ChangeResponsiveness />
                      </div>
                    </div>

                    <FormifyInput
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={(e, type) =>
                        updateFontSize("title", e.target.value, type)
                      }
                      value={checkFontSize(
                        widget?.style,
                        responsive,
                        "title",
                        "fontSize"
                      )}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateColorTitle}
                      id={"color"}
                      tag={getResponsiveStyle(widget?.style, responsive, "title", "color")}
                    />
                  </div>
                </div>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "marginLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "marginRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "marginTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "marginBottom"
                        )}
                        onChange={updateTitleSliderstyle}
                        setLockValue={setTitleMarginLockValues}
                        lockValue={titleMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "paddingLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "paddingRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "paddingTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "title",
                          "paddingBottom"
                        )}
                        onChange={updateTitleSliderstyle}
                        setLockValue={setTitlePaddingLockValues}
                        lockValue={titlePaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Promotional
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  attribute={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "promotional",
                    null
                  )}
                  onChangeFont={updatePromotionalFont}
                  updateSliderstyle={updatePromotional}
                  updateSelectstyle={updatePromotionalStyle}
                  id={"promotional"}
                />
                <div className="editable-field  form-row quick-form-actions pt-2 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1">
                        <ChangeResponsiveness />
                      </div>
                    </div>

                    <FormifyInput
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={(e, type) =>
                        updateFontSize("promotional", e.target.value, type)
                      }
                      value={checkFontSize(
                        widget?.style,
                        responsive,
                        "promotional",
                        "fontSize"
                      )}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updatePromotionalColor}
                      id={"color"}
                      tag={getResponsiveStyle(widget?.style, responsive, "title", "color")}
                    />
                  </div>
                </div>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "marginLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "marginRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "marginTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "marginBottom"
                        )}
                        onChange={updatePromotionalSliderstyle}
                        setLockValue={setPromotionalMarginLockValues}
                        lockValue={promotionalMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "paddingLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "paddingRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "paddingTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "promotional",
                          "paddingBottom"
                        )}
                        onChange={updatePromotionalSliderstyle}
                        setLockValue={setPromotionalPaddingLockValues}
                        lockValue={promotionalPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Informational Text
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <div className="editable-field  form-row quick-form-actions pt-2 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1">
                        <ChangeResponsiveness />
                      </div>
                    </div>
                    <FormifyInput
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={(e, type) =>
                        updateFontSize("informational", e.target.value, type)
                      }
                      value={checkFontSize(
                        widget?.style,
                        responsive,
                        "informational",
                        "fontSize"
                      )}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateInformationalColor}
                      id={"color"}
                      tag={getResponsiveStyle(widget?.style, responsive, "informational", "color")}
                    />
                  </div>
                </div>
                <hr />

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "marginLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "marginRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "marginTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "marginBottom"
                        )}
                        onChange={updateInformationalSliderstyle}
                        setLockValue={setInformationalMarginLockValues}
                        lockValue={informationalMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "paddingLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "paddingRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "paddingTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "informational",
                          "paddingBottom"
                        )}
                        onChange={updateInformationalSliderstyle}
                        setLockValue={setInformationalPaddingLockValues}
                        lockValue={informationalPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Price</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <TypographyPopup
                  tag={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "price",
                    null
                  )}
                  onChangeFont={updatePriceFont}
                  updateSliderstyle={updatePriceSliderstyle}
                  updateSelectstyle={updatePriceSelectstyle}
                  id={"price"}
                />
                <div className="editable-field  form-row quick-form-actions pt-2 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1">
                        <ChangeResponsiveness />
                      </div>
                    </div>
                    <FormifyInput
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={(e, type) =>
                        updateFontSize("price", e.target.value, type)
                      }
                      value={checkFontSize(
                        widget?.style,
                        responsive,
                        "price",
                        "fontSize"
                      )}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateColorPrice}
                      id={"color"}
                      tag={getResponsiveStyle(widget?.style, responsive, "price", "color")}
                    />
                  </div>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "marginLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "marginRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "marginTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "marginBottom"
                        )}
                        onChange={updatePriceSliderstyle}
                        setLockValue={setPriceMarginLockValues}
                        lockValue={priceMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "paddingLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "paddingRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "paddingTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "price",
                          "paddingBottom"
                        )}
                        onChange={updatePriceSliderstyle}
                        setLockValue={setPricePaddingLockValues}
                        lockValue={pricePaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Image</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <RangeSlider
                  label={"Width"}
                  id={"width"}
                  tag={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    "width"
                  )}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={true}
                />
                <RangeSlider
                  label={"Max Width"}
                  id={"maxWidth"}
                  tag={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    "maxWidth"
                  )}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={true}
                />
                <RangeSlider
                  label={"Height"}
                  id={"height"}
                  tag={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    "height"
                  )}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={true}
                />
                <RangeSlider
                  label={"Opacity"}
                  id={"opacity"}
                  tag={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    "opacity"
                  )}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true}
                  removeXPadding={true}
                />
                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    null
                  )}
                  onChange={updateImageSlider}
                  onChangeColor={updateBorderColor}
                  setBorderWidthLockValues={setImageBorderWidthLockValues}
                  setBorderRadiusLockValues={setImageBorderRadiusLockValues}
                  borderWidthLockValues={imageBorderWidthLockValues}
                  borderRadiusLockValues={imageBorderRadiusLockValues}
                  updateSelectOptions={updateInputSelectOptions}
                />
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "marginLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "marginRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "marginTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "marginBottom"
                        )}
                        onChange={updateImageSlider}
                        setLockValue={setImageMarginLockValues}
                        lockValue={imageMarginLockValues}
                      />
                      <Spacer
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "paddingLeft"
                        )}
                        tagRight={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "paddingRight"
                        )}
                        tagTop={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "paddingTop"
                        )}
                        tagBottom={getResponsiveStyle(
                          widget?.style,
                          responsive,
                          "image",
                          "paddingBottom"
                        )}
                        onChange={updateImageSlider}
                        setLockValue={setImagePaddingLockValues}
                        lockValue={imagePaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {/* Just added */}
      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Container
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundContainer}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "container", "backgroundColor")}
                  />
                </div>
                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "container",
                    null
                  )}
                  onChange={updateContainerSliderstyle}
                  onChangeColor={updateContainerBorderColor}
                  setBorderWidthLockValues={setBorderContainerWidthLockValues}
                  setBorderRadiusLockValues={setBorderContainerRadiusLockValues}
                  borderWidthLockValues={borderContainerWidthLockValues}
                  borderRadiusLockValues={borderContainerRadiusLockValues}
                  updateSelectOptions={updateContainerSelectOptions}
                />
              </div>
              <hr />

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="field-label">
                    Spacing
                  </Accordion.Header>
                  <Accordion.Body>
                    <Spacer
                      label={"Margin"}
                      id={"margin"}
                      tagLeft={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "marginLeft"
                      )}
                      tagRight={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "marginRight"
                      )}
                      tagTop={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "marginTop"
                      )}
                      tagBottom={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "marginBottom"
                      )}
                      onChange={updateContainerSliderstyle}
                      setLockValue={setContainerMarginLockValues}
                      lockValue={containerMarginLockValues}
                    />
                    <Spacer
                      label={"Padding"}
                      id={"padding"}
                      tagLeft={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "paddingLeft"
                      )}
                      tagRight={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "paddingRight"
                      )}
                      tagTop={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "paddingTop"
                      )}
                      tagBottom={getResponsiveStyle(
                        widget?.style,
                        responsive,
                        "container",
                        "paddingBottom"
                      )}
                      onChange={updateContainerSliderstyle}
                      setLockValue={setContainerPaddingLockValues}
                      lockValue={containerPaddingLockValues}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(
  DynamicProductStyle
);
