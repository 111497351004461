import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import axios from "axios";
import store from "../../../store";
import { tokenConfig } from "../../../actions/auth";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const PaymentInformation = ({
  auth,
  currentSubscription,
  subscriptionHandle,
}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${BASE_DEV_URL}/subscription/paymentmethods`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setPaymentMethods(res.data);
        setShowExport(constructCheckedArray(res.data));
      })
      .catch((err) => {
      });
  }, []);

  const constructCheckedArray = (pms) => {
    let arr = [];
    pms.forEach((pm) => {
      arr.push({ id: pm.id, show: false });
    });

    return { options: arr };
  };

  const [showExport, setShowExport] = useState({
    options: [
      {
        id: 1,
        show: false,
      },
      {
        id: 2,
        show: false,
      },
    ],
  });

  const checked = (id) => {
    const showOption = showExport.options.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          show: !item.show,
        };
      } else {
        return {
          ...item,
          show: false,
        };
      }
    });
    setShowExport({
      options: showOption,
    });
  };

  const getAdddres = (pm) => {
    if (pm.billing_details.address.line1) {
      return `${pm.billing_details.address.line1} ${pm.billing_details.address.city}, ${pm.billing_details.address.country} ${pm.billing_details.address.postal_code}`;
    } else {
      return;
    }
  };

  const showPopup = (id) => {
    const op = showExport.options.filter((opt) => opt.id === id)[0];

    if (op) {
      return op.show;
    } else {
      return false;
    }
  };

  const createPaymentMethod = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_DEV_URL}/subscription/paymentmethod`,
        {},
        tokenConfig(store.getState)
      );

      if (res.data.clientSecret) {
        nav("/profile/billing/add/paymentmethod", {
          state: { clientSecret: res.data.clientSecret },
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deattachPaymentMethod = async (paymentmethod) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${BASE_DEV_URL}/subscription/paymentmethod/${paymentmethod}`,
        tokenConfig(store.getState)
      );

      setPaymentMethods(res.data);
      setShowExport(constructCheckedArray(res.data));
      toast.success("Payment method removed");
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
    setLoading(false);
  };

  const setDefaultPaymentMethod = async (pm) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${BASE_DEV_URL}/subscription/paymentmethod/default/${pm}`,
        {},
        tokenConfig(store.getState)
      );
      toast.success("Payment method set as default for subscription");

      subscriptionHandle(res.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
    setLoading(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <React.Fragment>
      <div className="formify-card  var-width m-4">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="formify-card-header">
              <div className="payment-info-header">
                <h2>Payment Information</h2>
              </div>
            </div>
            <div className="payment-cards">
              {paymentMethods.length > 0 ? (
                paymentMethods.map((pm) => {
                  return (
                    <div
                      className={
                        auth?.user?.transactions[0]
                          ? auth?.user?.transactions[0].default_payment_method === pm.id
                            ? "payment-card default"
                            : "payment-card"
                          : "payment-card"
                      }
                    >
                      <div className="card-type">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/VISA_logo.svg"
                          }
                          alt="visa-logo"
                        />
                        {auth?.user?.transactions[0] ? (
                          auth?.user?.transactions[0].default_payment_method ===
                          pm.id ? (
                            <span className="default-card">Default</span>
                          ) : null
                        ) : null}
                      </div>
                      <div className="card-ending">
                        <p className="ending-number">
                          {pm.billing_details.name}
                        </p>
                        <p className="ending-number">
                          {capitalizeFirstLetter(pm.card.brand)} ending in
                          {pm.card.last4}
                        </p>
                        <p>
                          Expiry {pm.card.exp_month}/{pm.card.exp_year}
                        </p>
                      </div>
                      <div className="card-address">
                        <p>Address</p>
                        <p className="card-street">{getAdddres(pm)}</p>
                      </div>
                      <div
                        onClick={() => checked(pm.id)}
                        className="card-options"
                      >
                        <MoreHorizIcon />
                        <div
                          className={
                            showPopup(pm.id)
                              ? "exportPopup open"
                              : "exportPopup"
                          }
                        >
                          {auth?.user?.transactions[0] ? (
                            auth?.user?.transactions[0].default_payment_method !==
                            pm.id ? (
                              <p
                                onClick={() => {
                                  setDefaultPaymentMethod(pm.id);
                                }}
                              >
                                Set as default
                              </p>
                            ) : null
                          ) : null}
                          <p
                            onClick={() => {
                              deattachPaymentMethod(pm.id);
                            }}
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-payment-message">
                  You have no payment methods added
                </div>
              )}
            </div>

            <div className="upgradePlan">
              <Link to="#" onClick={createPaymentMethod}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/plus_icon.svg"
                  }
                  alt="add more"
                />
              </Link>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps)(PaymentInformation);
