let firstTime = true;
const Spacer = ({
  label,
  id,
  tagLeft,
  tagRight,
  tagTop,
  tagBottom,
  onChange,
  lockValue,
  setLockValue,
}) => {
  const changeVal = (e) => {
    onChange(e, firstTime ? "focus" : "ignore");
    firstTime = false;
  }
  return (
    <div>
      <p>{ label }</p>
      <div className="editable-field form-row  ">
        <div className="widget-space-input-container">
          <div>
            <input
              autoComplete="off"
              type="number"
              id={
                id === "borderRadius"
                  ? "borderTopLeftRadius"
                  : id === "borderWidth"
                  ? "borderLeftWidth"
                  : id + "Left"
              }
              name={
                id === "borderRadius"
                  ? "borderTopLeftRadius"
                  : id === "borderWidth"
                  ? "borderLeftWidth"
                  : id + "Left"
              }
              value={
                tagLeft ? tagLeft.substring(0, tagLeft.length - 2) || "0" : "0"
              }
              className="widget-spacer-input"
              onChange={changeVal}
              onBlur={ e => {
                if(!firstTime) onChange(e, "blur")
                firstTime = true;
              }}
            />

            <p className="field-heading widget-label">
              {id === "borderRadius" ? "" : "Left"}
            </p>
          </div>
          <div>
            <input
              autoComplete="off"
              type="number"
              id={
                id === "borderRadius"
                  ? "borderTopRightRadius"
                  : id === "borderWidth"
                  ? "borderTopWidth"
                  : id + "Top"
              }
              name={
                id === "borderRadius"
                  ? "borderTopRightRadius"
                  : id === "borderWidth"
                  ? "borderTopWidth"
                  : id + "Top"
              }
              value={
                tagTop ? tagTop.substring(0, tagTop.length - 2) || "0" : "0"
              }
              className="widget-spacer-input"
              onChange={changeVal}
              onBlur={ e => {
                if(!firstTime) onChange(e, "blur")
                firstTime = true;
              }}
            />
            <p className="field-heading widget-label">
              {id === "borderRadius" ? "" : "Top"}
            </p>
          </div>
          <div>
            <input
              autoComplete="off"
              type="number"
              id={
                id === "borderRadius"
                  ? "borderBottomRightRadius"
                  : id === "borderWidth"
                  ? "borderRightWidth"
                  : id + "Right"
              }
              name={
                id === "borderRadius"
                  ? "borderBottomRightRadius"
                  : id === "borderWidth"
                  ? "borderRightWidth"
                  : id + "Right"
              }
              value={
                tagRight
                  ? tagRight.substring(0, tagRight.length - 2) || "0"
                  : "0"
              }
              className="widget-spacer-input"
              onChange={changeVal}
              onBlur={ e => {
                if(!firstTime) onChange(e, "blur")
                firstTime = true;
              }}
            />
            <p className="field-heading widget-label">
              {id === "borderRadius" ? "" : "Right"}
            </p>
          </div>
          <div>
            <input
              autoComplete="off"
              type="number"
              id={
                id === "borderRadius"
                  ? "borderBottomLeftRadius"
                  : id === "borderWidth"
                  ? "borderBottomWidth"
                  : id + "Bottom"
              }
              name={
                id === "borderRadius"
                  ? "borderBottomLeftRadius"
                  : id === "borderWidth"
                  ? "borderBottomWidth"
                  : id + "Bottom"
              }
              value={
                tagBottom
                  ? tagBottom.substring(0, tagBottom.length - 2) || "0"
                  : "0"
              }
              className="widget-spacer-input"
              onChange={changeVal}
              onBlur={ e => {
                if(!firstTime) onChange(e, "blur")
                firstTime = true;
              }}
            />
            <p className="field-heading widget-label">
              {id === "borderRadius" ? "" : "Bottom"}
            </p>
          </div>
          <div
            onClick={() => {
              setLockValue(!lockValue);
            }}
            className="lock-values"
            style={{ background: lockValue ? "#7e7e7e" : "#e0e0e0" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icons/link.svg"}
              style={{
                width: "15px",
              }}
              alt="Formify"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spacer;
