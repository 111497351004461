// Local storage with expiry date (default 1 year)
class LocalStorage {
  static get(key) {
    if (typeof localStorage === "undefined") {
      return null;
    }

    const data = localStorage.getItem(key);
    if (data) {
      const parsed = JSON.parse(data);
      if (parsed.expires > Date.now()) {
        return parsed.data;
      } else {
        LocalStorage.remove(key);
      }
    }
    return null;
  }

  static set(key, data, expiry = 86400 * 365) {
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        expires: Date.now() + expiry * 1000,
      })
    );
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}
export default LocalStorage;
