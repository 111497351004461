/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../../utils";
import { tokenConfig, updateUserRedux } from "../../../../actions/auth";
import { connect } from "react-redux";
import Lottie from "lottie-react";
import successPayment from "../../../../utils/lotties/payment-successful.json";
import errorPayment from "../../../../utils/lotties/payment-failed.json";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import FormifyLoader from "../../../utils/FormifyLoader";
import axios from "axios";
import store from "../../../../store";

const PaymentCheckoutReturn = ({
  auth,
  updateUserRedux,
  currentSubscription,
  dispatch,
}) => {
  const params = useParams();
  const { search } = useLocation();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (params.type === "succeeded" || params.type === "canceled") {
      if (params.type === "succeeded") {
        //Update user

        axios
          .get(`${BASE_DEV_URL}/user`, tokenConfig(store.getState))
          .then((res) => {
            updateUserRedux(res.data.user);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      }
      setLoading(false);
      setIsSuccess(params.type);
    } else {
      //Means you need to verify shopify account
      axios
        .post(
          `${BASE_DEV_URL}/shopify/payment/verify`,
          {
            token: params.type,
            chargeId: search.split("=")[1],
          },
          tokenConfig(store.getState)
        )
        .then((res) => {
          setIsSuccess(res.data.type);
          setLoading(false);
          if (res.data.type === "succeeded") {
            updateUserRedux(res.data.user);
          } else if (res.data.type === "succeeded-free") {
            updateUserRedux(res.data.user);
            navigator("/");
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }, []);

  return !loading ? (
    <div className="price-container">
      <div className="price-text">
        <div className="lottie-container">
          <Lottie
            animationData={
              isSuccess === "succeeded" ? successPayment : errorPayment
            }
            loop={false}
          />
          {isSuccess === "succeeded" ? (
            <NavLink to="/" className="formify-btn ">
              Go to Dashboard!
            </NavLink>
          ) : (
            <NavLink to="/profile/billing/plans" className="formify-btn ">
              Try Again
            </NavLink>
          )}
        </div>
        {/* <Lottie animationData={successPayment} loop={true} /> */}
      </div>
    </div>
  ) : (
    <div className="col-12">
      <FormifyLoader removeLogo={true} text={"Please Wait ..."} />
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps, { updateUserRedux })(
  PaymentCheckoutReturn
);
