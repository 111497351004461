import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connectShopify } from "../../../actions/integrations";
import { mapAuthStateToProps } from "../../../utils";

const ShopifyOAuth = ({ auth, connectShopify }) => {
  const nav = useNavigate();

  const [searchParams] = useSearchParams();


  useEffect(() => {
    connectShopify(null, searchParams);
  }, []);

  useEffect(() => {
    if (auth.user.stores.shopify) {
      nav("/profile/integrations/all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return <></>;
};

export default connect(mapAuthStateToProps, { connectShopify })(ShopifyOAuth);
