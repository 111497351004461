import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { setWidgetResponsesDispatcher } from "../../../actions/form";
import { mapAuthAndFormStateToProps } from "../../../utils";
import { Alert } from "@mui/material";

import WidgetEditTabs from "../WidgetEditTabs";
import {
  insertLinksToAcceptanceText,
  getDynamicTagComponent,
} from "./renderersFunctions";
import { checkFontSize, checkResponsiveStyle, getResponsiveStyle } from "../responsiveFunctions";

const EmailRender = ({
  element,
  outerSnapshot,
  index,
  editElement,
  isEditable,
  setWidgetResponsesDispatcher,
  form: { responses, responseError },
  screen,
}) => {
  
  const css = element?.style
    ? `
  .widget-${element._id} input::placeholder {
    opacity:0.6;
    ${ getResponsiveStyle(element?.style, screen, "input", "color") && ` color:  ${getResponsiveStyle(element?.style, screen, "input", "color")}`};
    ${ element.style?.desktop?.input?.fontFamily && `font-family:  ${element?.style?.desktop?.helpText?.fontFamily?.name}` };
    ${ checkFontSize(element?.style, screen, "input", "fontSize") &&  `font-size: ${checkFontSize(element?.style, screen, "input", "fontSize")}px`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontDecoration") && `text-decoration:  ${getResponsiveStyle(element?.style, screen, "input", "fontDecoration")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "fontWeight") && `text-weight:  ${getResponsiveStyle(element?.style, screen, "input", "fontWeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "lineHeight") && `line-height:  ${getResponsiveStyle(element?.style, screen, "input", "lineHeight")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "wordSpacing") && `word-spacing:  ${getResponsiveStyle(element?.style, screen, "input", "wordSpacing")}`};
    ${ getResponsiveStyle(element?.style, screen, "input", "letterSpacing") && `border-right-width:  ${getResponsiveStyle(element?.style, screen, "input", "letterSpacing")}`};
    ${"}"}`: ``;

  let inputAlignment = getResponsiveStyle(element?.style, screen, "textAlign", null);
  return (
    <div
      className={  "widget " + element.options.className + " widget-" + element._id }
      data-widget-type={element.name}
      id={element.options.id}
      style={element.options}
      onClick={() =>  isEditable  ? editElement({    ...element,  }) : null }
    >
      <style>{css} </style>
      <Draggable
        key={element._id}
        draggableId={element._id.toString()}
        index={index}
        isDragDisabled={!isEditable}
      >
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging && outerSnapshot.isDraggingOver
                ? index === 0
                  ? " form-widget isDragging"
                  : " mt-3 form-widget isDragging"
                : "  mt-3 form-widget"
            }
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div
              className="input-holder widget  position-relative "
              key={element._id}
              style={{
                ...checkResponsiveStyle(element.style, screen, null),
                display: "flex",
                justifyContent: inputAlignment,
                alignItems: inputAlignment === "left"  ? "start" : inputAlignment  === "right"  ? "end": inputAlignment,
              }}

            >
              {!element.content.hide_label ? (
                <label
                  htmlFor="email"
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "label"),
                    display: "flex",
                    fontFamily:  element?.style?.desktop?.helpText?.fontFamily?.name,
                    justifyContent: inputAlignment,
                  }}
                >
                  {getDynamicTagComponent(element.content.title, responses)}
                </label>
              ) : null}
              {!isEditable ? (
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={responses[element._id]?.email || ""}
                  onChange={(e) =>
                    setWidgetResponsesDispatcher(element._id, {
                      email: e.target.value,
                    },"form_email")
                  }
                  placeholder={element.content.placeholder}
                  style={{
                    ...checkResponsiveStyle(element.style, screen, "input"),
                    textAlign: inputAlignment,
                    justifyContent: inputAlignment,
                    fontFamily:
                      element?.style?.desktop?.helpText?.fontFamily?.name,
                  }}
                />
              ) : (
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={element.content.placeholder}
                  style={checkResponsiveStyle(element.style, screen, "input")}

                />
              )}
              <p
                className="formify-sm-p"
                style={checkResponsiveStyle(element.style, screen, "helpText")}
              >{element.content.help_text}</p>
              <label
                className={"acceptance_text"}
                style={{
                  ...element?.style?.[screen]?.acceptance_text,
                  textAlign: inputAlignment,
                  justifyContent: inputAlignment,
                  fontFamily: element?.style?.desktop?.helpText?.fontFamily?.name,
                }}
              >
                {" "}
                <input
                  type={"checkbox"}
                  name="email-checkbox"
                  onChange={(e) => {
                    !isEditable &&
                      setWidgetResponsesDispatcher(element._id, {
                        termAgree: e.target.checked,
                      });
                  }}
                  checked={responses[element._id]?.termAgree}
                  id={element._id + "-checkbox"}
                  className="mt-0"
                />{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: insertLinksToAcceptanceText(
                      element.content,
                      element?.style?.[screen]?.acceptance_text
                    ),
                  }}
                />
              </label>

              {!isEditable && responseError ? (
                Object.keys(responseError).length > 0 ? (
                  <p
                    className="error-message"
                    style={{
                      fontFamily:
                      element?.style?.desktop?.helpText?.fontFamily?.name,
                      textAlign: inputAlignment,
                      justifyContent: inputAlignment,

                    }}
                  >
                    {responseError[element._id]}
                  </p>
                ) : null
              ) : null}
            </div>
            {isEditable && (
              <WidgetEditTabs element={element} editElement={editElement} />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetResponsesDispatcher,
})(EmailRender);
