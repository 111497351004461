/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import PositionOptions from "../single-components/PositionOptions";
import InputField from "../single-components/InputField";
import Spacer from "../single-components/Spacer";
import WidthOptions from "../single-components/WidthOptions";
import BackgroundStyle from "../single-components/BackgroundStyle";
import BorderOptions from "../single-components/BorderOptions";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import _ from "lodash";
import { lockAllValues } from "../../../utils/lockValues";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import SwitchChip from "../single-components/switchChip";
import { toast } from "react-toastify";

const ComponentOptions = ({
  form: { widget, responsive },
  setWidgetDispatcher
}) => {
  const [marginLockValues, setMarginLockValues] = useState(false);
  const [paddingLockValues, setPaddingLockValues] = useState(false);
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);

  const updateSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`options.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`options.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (marginLockValues) {
        lockAllValues(e, 
          [
            `options.marginBottom`, 
            `options.marginRight`, 
            `options.marginLeft`, 
            `options.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (paddingLockValues) {
        lockAllValues(e, 
          [
            `options.paddingBottom`, 
            `options.paddingRight`, 
            `options.paddingLeft`, 
            `options.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, 
          [
            `options.borderLeftWidth`, 
            `options.borderTopWidth`, 
            `options.borderRightWidth`, 
            `options.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, 
          [
            `options.borderTopLeftRadius`, 
            `options.borderTopRightRadius`, 
            `options.borderBottomRightRadius`, 
            `options.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher([`options.borderColor`], [value], widget, setWidgetDispatcher);
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`options.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const updateBackgroundColor = (value) => {
    handleSetWidgetDispatcher([`options.backgroundColor`], [value], widget, setWidgetDispatcher);
  };


  const hideWidget = (val) => {
    if(val){
      handleSetWidgetDispatcher([
        `style.${responsive}.hide`,
        `content.required`
        ], 
        [val,false], widget, setWidgetDispatcher);
    }else{
      handleSetWidgetDispatcher([`style.${responsive}.hide`],[val], widget, setWidgetDispatcher);
    }
  }

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <p className="field-heading widget-label">Options</p>

        <Spacer
          label={"Margin"}
          id={"margin"}
          tagLeft={widget.options.marginLeft}
          tagRight={widget.options.marginRight}
          tagTop={widget.options.marginTop}
          tagBottom={widget.options.marginBottom}
          onChange={updateSliderOptions}
          setLockValue={setMarginLockValues}
          lockValue={marginLockValues}
        />
        
        <Spacer
          label={"Padding"}
          id={"padding"}
          tagLeft={widget.options.paddingLeft}
          tagRight={widget.options.paddingRight}
          tagTop={widget.options.paddingTop}
          tagBottom={widget.options.paddingBottom}
          onChange={updateSliderOptions}
          setLockValue={setPaddingLockValues}
          lockValue={paddingLockValues}
        />

        <InputField
          id={"className"}
          label={"CSS Class"}
          onChange={handleChange}
          placeholder={"Class"}
          type="text"
          value={widget.options.className}
          isRow={true}
        />
        <InputField
          id={"id"}
          label={"CSS ID"}
          onChange={handleChange}
          placeholder={"ID"}
          type="text"
          value={widget.options.id}
          isRow={true}
        />
        <InputField
          id={"zIndex"}
          label={"Z Index"}
          onChange={handleChange}
          placeholder={"Z Index"}
          type="number"
          value={widget.options.zIndex || ""}
          isRow={true}
        />
        <hr />
        <PositionOptions
          handleSelect={updateSelectOptions}
          tag={widget.options.position}
          id={"position"}
          name={"position"}
        />
        <hr />
        <div className="editable-field form-row">
          <p className="field-label">Hide on {responsive.charAt(0).toUpperCase() + responsive.slice(1)}</p>
          <SwitchChip
            id="hide"
            checked={widget.style ? widget?.style[responsive]?.hide : false}
            onChange={(val) => hideWidget(val)}
          />
        </div>
        <hr />
        <WidthOptions
          handleSelect={updateSelectOptions}
          tag={widget.options.width}
          id={"width"}
          name={"width"}
          />
        <hr />
        <BackgroundStyle
          tag={widget.options.position}
          attribute={widget.options}
          onChange={handleChange}
          changeBackground={updateBackgroundColor}
        />
        <hr />
        <BorderOptions
          attribute={widget.options}
          onChange={updateSliderOptions}
          onChangeColor={updateBorderColor}
          setBorderWidthLockValues={setBorderWidthLockValues}
          setBorderRadiusLockValues={setBorderRadiusLockValues}
          borderWidthLockValues={borderWidthLockValues}
          borderRadiusLockValues={borderRadiusLockValues}
          updateSelectOptions={updateSelectOptions}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(ComponentOptions);
