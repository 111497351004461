import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../utils";
import { sendPasswordResetEmail } from "../../actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { Overlay, Tooltip } from "react-bootstrap";
import _ from "lodash";
const PasswordResetEmail = ({ auth, sendPasswordResetEmail, errors }) => {
  const [form, setForm] = useState("");
  const emailRef = useRef(null);
  const nav = useNavigate();
  useEffect(() => {
    if (auth.isAuthenticated) nav("/user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form) {
      sendPasswordResetEmail({ email: form });
      nav("/login");
    }
  };

  return (
    <div className="container login-wraper mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper px-4 py-5 pt-4">
          <div className="login-header forgot-password-header  ms-3 d-flex flex-column align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
              alt="formify-logo"
              className="img-fluid"
            />
            <h4>Reset Your Password</h4>
            <p className="text-center">
              We get it, it happens! Enter the email address associated with
              your account.
            </p>
          </div>
          <Overlay
            target={emailRef.current}
            show={!_.isEmpty(errors)}
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="login-error"
                style={{ backgroundColor: "#FC5B3F" }}
                {...props}
              >
                {errors.message}
              </Tooltip>
            )}
          </Overlay>

          <form className="login-form mt-2" onSubmit={handleSubmit}>
            <div className="input-holder position-relative">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={form}
                onChange={(e) => {
                  setForm(e.target.value);
                }}
                name="email"
                placeholder="email@business.com"
                ref={emailRef}
              />
            </div>

            <div className="submit-btn ">
              <button>Email Recovery Link</button>
            </div>
          </form>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-12 login-form-wraper signup-wraper">
          <div className="signup-box">
            <p>Don’t have an account?</p>
            <Link to="/register" params={{ userCheckoutType:  null }}>
              <button>Sign up</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps, { sendPasswordResetEmail })(
  PasswordResetEmail
);
