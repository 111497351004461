import { connect } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { mapAuthStateToProps } from "../utils";
import React, { useEffect, useState } from "react";
import Login from "./auth/Login";


const checkHasStores = (user) => {
  if (user && user.stores) {
    const storeEntries = Object.entries(user.stores);
    if(user?.stores?.shopify){
      if(user?.stores?.shopify?.access_token != null){
        return storeEntries.some(([key, value]) => typeof value === "object");
      }else{
        return false;

      }
    }
    return storeEntries.some(([key, value]) => typeof value === "object");
  }
  return false;
};

const PrivateRoute = ({ children, auth }) => {

  const [allowHomePage, setAllowHomePage] = useState(false);
  const loc = useLocation();

  const hasStores = checkHasStores(auth.user);

  const integrationPaths = [ 
    "/onboarding-integrations",
    "/integrations/subbly",
    "/integrations/shop",
    "/login",
    // "/",
    // "",
    "/integrations/shopify",
    "/integrations/woocommerce",
    "/integrations/subbly/store"
  ];
  
  const billingPaths = [
    "/profile/billing/plans",
    "/profile/billing/choose/paymentmethod",
  ];
   
  const isIntegrationPath = integrationPaths.filter((path)=>loc.pathname.includes(path))[0]; 
  const isBillingPath = billingPaths.includes(loc.pathname);
  
  const hasAccountType = auth.user && auth.user.account && auth.user.transactions[0]?.account_type;

  if (!auth.isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{
          requested_path: loc.search ? loc.pathname + loc.search : loc.pathname,
        }}
      />
    );
  }else{

    if(hasAccountType){
      return children
    }
    else if (!hasStores) {
      if(!isIntegrationPath && !allowHomePage){
        return <Navigate to="/onboarding-integrations" state={{ downgrade: true }} />
      } 
      
    }
    else{
      if(!isBillingPath){
        return <Navigate to={`/profile/billing/plans${auth.user.checkout_price_id ? `?priceId=${auth.user.checkout_price_id}` : ''}`} state={{ downgrade: true }} />;
      }
    }
  
}
return React.cloneElement(children, { allowHomePage, setAllowHomePage });
};

export default connect(mapAuthStateToProps)(PrivateRoute);