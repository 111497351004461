import axios from "axios";
import { useEffect, useState } from "react";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { BASE_DEV_URL } from "../../utils";

const FormSelect = (props) => {
  const [forms, setForm] = useState([]);
  const [name, setName] = useState("All Signup Flows");
  const [toggleDropdown, setToggleDropdown] = useState(false);
  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/form/`, tokenConfig(store.getState))
      .then((response) => {
        setForm([...response.data.forms]);
        if (response.data.forms[response.data.forms.length - 1]) {
          setName(response.data.forms[response.data.forms.length - 1].name);
        }
      });
  }, []);

  const update = (id, name) => {
    props.setForms(id);
    setName(name);
  };
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <li
      className={`select-list ${toggleDropdown ? "open-select" : ""}`}
      onClick={() => setToggleDropdown(!toggleDropdown)}
    >
      <p>
        {truncate(name, 10)}
        <span className="bottom-arrow"></span>
      </p>
      <ul className="absolute-list">
        {forms.map((form, index) => {
          return (
            <li
              key={form._id}
              onClick={() => {
                update(form._id, form.name);
              }}
            >
              {truncate(form.name, 18)}
              {form._id === props.forms ? (
                <span className="checked_select"></span>
              ) : null}
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default FormSelect;
