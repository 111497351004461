let firstTime = true;
const FormifyInput = ({
  type="text",
  id,
  value,
  min,
  max,
  onChange,
  placeholder,
  defaultValue,
  className
}) => {
  const changeVal = (e) => {
    onChange(e, firstTime ? "focus" : "ignore");
    firstTime = false;
  }

  return (
    <input
      type={type}
      id={id}
      name={id}
      min={min}
      max={max}
      placeholder={placeholder}
      onChange={changeVal}
      onBlur={ e => {
        if(!firstTime) onChange(e, "blur")
        firstTime = true;
      }}
      value={value}
      defaultValue={defaultValue}
      className={className}
    />
  );
};

export default FormifyInput;