import { useEffect, useState } from "react";
import {
  Text,
  Card,
  Container,
  Group,
  ThemeIcon,
  Divider,
  Center,
  Button,
} from "@mantine/core";
import LocalStorage from "../utils/LocalStorage";
import { Copy, Heart } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

export default function CopySaved() {
  const [savedCopy, setSavedCopy] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedCopy = LocalStorage.get("copy") || [];
      setSavedCopy(savedCopy);
    }
  }, []);

  return (
    <main>
      <Container size="md" my={50}>
        <h1 className="formify-h1">Saved Copy</h1>
        {savedCopy && savedCopy.length < 1 ? (
          <>
            <Center>
              <Text size="xl" p="sm">
                You haven&apos;t saved any copy yet.
              </Text>
            </Center>
            <Center>
              <Button
                onClick={() => {
                  navigate("/copy");
                }}
              >
                Generate new copy
              </Button>
            </Center>
          </>
        ) : (
          savedCopy.map((copy, index) => (
            <Card shadow="sm" p="md" radius="md" mt="md" withBorder key={index}>
              <Group position="apart" mt="md" mb="xs">
                <Text size="xl" weight="bold">
                  Generated copy #{index + 1}
                </Text>
                <Group>
                  <ThemeIcon
                    color="red"
                    style={{
                      cursor: "pointer",
                    }}
                    variant="outline"
                    onClick={() => {
                      const copy = LocalStorage.get("copy");
                      copy.splice(index, 1);
                      LocalStorage.set("copy", copy);
                      setSavedCopy(copy);
                    }}
                  >
                    <Heart size={16} fill="red" />
                  </ThemeIcon>
                  <ThemeIcon
                    color="blue"
                    style={{
                      cursor: "pointer",
                    }}
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(copy);

                      showNotification({
                        title: "Copied to clipboard",
                        message:
                          "The generated copy has been copied to your clipboard.",
                        color: "green",
                      });
                    }}
                  >
                    <Copy size={16} />
                  </ThemeIcon>
                </Group>
              </Group>

              <Divider mt="md" mb="md" />

              <Text
                size="xl"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {copy}
              </Text>
            </Card>
          ))
        )}
      </Container>
    </main>
  );
}
