import React from 'react'
import ContentLoader from 'react-content-loader'

const FontLoader = () => {
  return (
    <ContentLoader viewBox="0 0 250 116" width={250} height={116}>
      <rect x="20" y="34" rx="0" ry="0" width="0" height="0" />
      <rect x="20" y="29" rx="0" ry="0" width="350" height="32" />
      <rect x="20" y="71" rx="0" ry="0" width="350" height="32" />
      <rect x="20" y="94" rx="0" ry="0" width="0" height="0" />
      <rect x="20" y="116" rx="0" ry="0" width="350" height="32" />
      <rect x="20" y="94" rx="0" ry="0" width="0" height="0" />
      <rect x="20" y="116" rx="0" ry="0" width="150" height="32" />
    </ContentLoader>
  )
}


export default FontLoader