import { Checkbox } from "@mui/material";
import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Settings,
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable, usePagination } from "react-table";
import { tableRenderObject } from "../../../utils";
const FormifyTable = ({
  columns,
  data,
  setDelete,
  form = null,
  response = false,
}) => {
  const nav = useNavigate();
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 15 } },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    allColumns,
    getToggleHideAllColumnsProps,
  } = tableInstance;

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <div className="filter hide-columns-drop">
        <div className="dropdown me-2">
          <button
            className="formify-simple-btn btn-icon  dropdown-toggle formify-drp table-dropdown"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <p>
              <Settings />
            </p>
            <ChevronDown
              style={{
                color: "#29447A",
                marginLeft: "5px",
                width: "16px",
                height: "16px",
                border: "2px",
              }}
            />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div>
                <Checkbox {...getToggleHideAllColumnsProps()} /> All
              </div>
            </li>
            {allColumns.map((column) => {
              return (
                <li key={column.id}>
                  <div key={column.id}>
                    <label>
                      <Checkbox {...column.getToggleHiddenProps()} />
                      {column.Header}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="table-scrollable">
        <table className="formify-contact-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={Math.random()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDown
                            style={{
                              backgroundColor: "#6563ff",
                              color: "white",
                              borderRadius: "10px",
                              border: "none",
                              width: "14px",
                              height: "14px",
                              marginLeft: "5px",
                              marginBottom: "3px",
                            }}
                          />
                        ) : (
                          <ArrowUp
                            style={{
                              backgroundColor: "#6563ff",
                              color: "white",
                              borderRadius: "10px",
                              border: "none",
                              width: "14px",
                              height: "14px",
                              marginLeft: "5px",
                              marginBottom: "3px",
                            }}
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length > 0
              ? page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props

                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props

                          return (
                            <td
                              {...cell.getCellProps()}
                              data-label={
                                cell.value
                                  ? cell.column.Header
                                  : cell.column.Header
                              }
                            >
                              {typeof cell.value === "object"
                                ? tableRenderObject(cell.value)
                                : cell.value
                                ? truncate(cell.value, 20)
                                : "-"}
                            </td>
                          );
                        })
                      }
                      <div
                        className="row-btn-group mt-1"
                        style={{ maxWidth: "20px" }}
                      >
                        <button
                          className="edit-table formify-sm-iconbtn"
                          onClick={() => {
                            if (response) {
                              nav(
                                `/form/${form._id}/responses/${row.original._id}`
                              );
                            } else {
                              nav(`/contacts/${row.original._id}`);
                            }
                            return;
                          }}
                        >
                          <span className="edit-icon"></span>
                        </button>
                        <button
                          className="delete-table formify-sm-iconbtn"
                          onClick={() => {
                            setDelete(row.original);
                          }}
                        >
                          <span className="trash-icon"></span>
                        </button>
                      </div>
                    </tr>
                  );
                })
              : "You have no contacts"}
          </tbody>
        </table>
      </div>
      <div className="pagination d-flex flex-column justify-content-center align-items-end mt-4">
        <div className="simple-nav">
          <a
            onClick={() => previousPage()}
            className={canPreviousPage ? "formify-link" : "disabled-link"}
            href="#/"
            disabled={!canPreviousPage}
          >
            <ChevronLeft />
          </a>
          <span className="formify-table-pagination">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <a
            onClick={() => nextPage()}
            href="#/"
            className={canNextPage ? "formify-link" : "disabled-link"}
            disabled={!canNextPage}
          >
            <ChevronRight />
          </a>
        </div>
        {/* <div className="go-to mt-2">
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
        </div> */}
        {/* <div className="page-size mt-2 mb-2">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
      </div>
    </>
  );
};

export default FormifyTable;
