import { Alert, CircularProgress } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
import BillingAddress from "./BillingAddress";
import { BASE_DEV_URL, PLATFORM_URL, mapAuthErrorsStateToProps } from "../../../utils";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import { connect } from "react-redux";
const CheckoutForm = ({ auth, paymentMethod = null }) => {
  const elements = useElements();
  const stripe = useStripe();

  const [loading, setLoading] = useState(null);

  const [address, setAddress] = useState({
    name: "",
    line1: "",
    city: "",
    zip: "",
  });

  useEffect(() => {
    if (auth.user) {
      setAddress({
        line1: auth.user.business_address
          ? auth.user.business_address.street
          : "",
        city: auth.user.business_address ? auth.user.business_address.city : "",
        zip: auth.user.business_address
          ? auth.user.business_address.zip_code
          : "",
      });
    }
  }, [auth]);

  const handleAddressChange = (e) => {
    setAddress({
      ...address,
      [e.target.id]: e.target.value,
    });
  };

  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (address.name && address.line1 && address.city && address.zip) {
      if (!stripe || !elements) {
        return;
      }

      if (!paymentMethod) {
        await axios.post(
          `${BASE_DEV_URL}/user/address`,
          {
            address: {
              street: address.line1,
              city: address.city,
              zip_code: address.zip,
            },
          },
          tokenConfig(store.getState)
        );

        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${PLATFORM_URL}/profile/billing/status`,
            payment_method_data: {
              billing_details: {
                address: {
                  line1: address.line1,
                  city: address.city,
                  postal_code: address.zip,
                },
                name: address.name,
              },
            },
          },
        });

        if (result.error) {
          setError(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      } else {
        const result = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${PLATFORM_URL}/profile/billing/paymentmethod/status`,
            payment_method_data: {
              billing_details: {
                address: {
                  line1: address.line1,
                  city: address.city,
                  postal_code: address.zip,
                },
                name: address.name,
              },
            },
          },
        });

        if (result.error) {
          setError(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      }
    } else {
      setError("All billing address information is required");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error ? (
        <Alert severity="error" className="mb-3">
          {error}
        </Alert>
      ) : null}
      <div style={{ display: loading ? "none" : "block" }}>
        <PaymentElement />
        <BillingAddress change={handleAddressChange} address={address} />
        <button
          className="upgradePlan"
          style={{ border: "none" }}
          disabled={!stripe}
        >
          Submit
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <CircularProgress sx={{ display: loading ? "inline-block" : "none" }} />
      </div>
    </form>
  );
};

export default connect(mapAuthErrorsStateToProps)(CheckoutForm);
