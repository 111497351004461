import { Link, useLocation } from "react-router-dom";

const SidebarLinks = ({ menu, type }) => {
  const loc = useLocation();

  return menu.links.map((link) => {
    return type === "dashboard" ? (
      <li
        className={
          link.path === loc.pathname
            ? "dashboard-link ps-3  d-flex flex-row justify-content-start align-items-center selected "
            : "dashboard-link ps-4  d-flex flex-row justify-content-start align-items-center"
        }
        key={link.id}
      >
        {/* {link.icon} */}
        <img src={process.env.PUBLIC_URL + "/assets/images/icons/"+ link.icon}
          className="nav-icons"
          alt=""/>
        <Link to={link.path} className="link-dark rounded">
          {link.name} 
        {link.name === "CopyGuru" ?  <img src={process.env.PUBLIC_URL + "/assets/images/icons/"+ link.icontwo}
          className="nav-icons"
          style={{width:"20px", marginTop:"-10px", marginLeft:"5px"}}
          alt=""/>: ""}
        </Link>
      </li>
    ) : (
      <li key={link.id}>
        <h4 className="section-header">{link.header}</h4>
        <div className="section-holder">
          <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small section-list">
            {link.sublinks.map((sublink) => {
              return (
                <li
                  className={sublink.path === loc.pathname ? "selected" : ""}
                  key={sublink.id}
                >
                  <Link to={sublink.path} className="link-dark rounded">
                    {sublink.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    );
  });
};

export default SidebarLinks;
