import { Dropdown, DropdownButton } from "react-bootstrap";
import ChangeResponsiveness from "./changeResponsiveness";

const WidthOptions = ({
    tag,
    handleSelect,
    id,
    name,
}) => {


    return (
        <div className="editable-field form-column px-0">
            <div className="d-flex justify-between">
                <p> Width</p>
                <div className="mb-1"><ChangeResponsiveness /></div>
            </div>
            <div className="widget-range-slider-container my-0">
                <DropdownButton
                    title={tag ? tag : "100%"}
                    id={id}
                    name={name}
                    onSelect={(e) => handleSelect(e, { id: id })}
                    className="form-dropdown-select"
                >
                    <Dropdown.Item eventKey="100%">100%</Dropdown.Item>
                    <Dropdown.Item eventKey="80%">80%</Dropdown.Item>
                    <Dropdown.Item eventKey="75%">75%</Dropdown.Item>
                    <Dropdown.Item eventKey="70%">70%</Dropdown.Item>
                    <Dropdown.Item eventKey="66%">66%</Dropdown.Item>
                    <Dropdown.Item eventKey="60%">60%</Dropdown.Item>
                    <Dropdown.Item eventKey="50%">50%</Dropdown.Item>
                    <Dropdown.Item eventKey="40%">40%</Dropdown.Item>
                    <Dropdown.Item eventKey="33%">33%</Dropdown.Item>
                    <Dropdown.Item eventKey="30%">30%</Dropdown.Item>
                    <Dropdown.Item eventKey="25%">25%</Dropdown.Item>
                    <Dropdown.Item eventKey="20%">20%</Dropdown.Item>
                    <Dropdown.Item eventKey="0%">0%</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
};

export default WidthOptions;