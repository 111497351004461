import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { BASE_DEV_URL } from "../../utils";
import Youtube from "../loaders/Loaders";
import { toast } from "react-toastify";
import FormifyButton from "../utils/FromifyButton";
import ConfirmModal from "../utils/ConfirmModal";
import ImageBox from "./ImageBox";
import SearchInput from "./SearchInput";
import { Link, useSearchParams } from 'react-router-dom'
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import Multiselect from "multiselect-react-dropdown";
import MediaLibraryModal from "./MediaLibraryModal";

const MediaLibraryContent = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteIntent, setDelete] = useState(null);
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("type") ? searchParams.get("type") : "custom"
  const sort = searchParams.get("sort") ? searchParams.get("sort") : "newest"

  useEffect(() => {
    if(deleteIntent) setShow(true);
  }, [deleteIntent])

  useEffect(() => {
    getData(search, sort);
  }, [activeTab, sort, search]);

  const getData = (search = "", sort = null) => {
    setLoading(true);
    axios
      .get(
        `${BASE_DEV_URL}/mediaLibrary/${activeTab}/${sort}/${search}`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setImages(res.data.images);
        setTimeout(()=>{
          setLoading(false)
        },400)
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }

  const uploadAppendImage = () => {
    getData(search, sort);
    setVisible(false);
  }

  const deleteImage = (imageId, index) => {
    axios
      .delete(
        `${BASE_DEV_URL}/mediaLibrary/${imageId}`,
        tokenConfig(store.getState)
      )
      .then(() => {
        const items = [...images];
        items.splice(index, 1)
        setImages(items);
        toast.success("Image deleted successfully.");
        setDelete(null);
        setShow(null)
      })
      .catch((err) => {
        toast.error(err)
      });
  };

  const handleSortChange = (e) => {
    searchParams.set("sort", e[0].id);
    setSearchParams(searchParams)
  }

  const sortInitialValues = [
    { name: 'Sort: Newest First', id: 'newest'},
    { name: 'Sort: Oldest First', id: 'oldest'},
    { name: 'Sort: Alphabetical (A-Z)', id: 'alpha'}
  ];

  const selectedSort = useMemo(() => {
    return sortInitialValues.find(item => item.id === sort)
  }, [sort])

  return (
    <div className={`container-fluid contact-list p-relative pl-20 mt-4 pb-100`}>
      <div className="pb-5">
        <div className="d-flex">
          <div className="media-library-nav-first me-5">
            <SearchInput setSearch={ setSearch } className="media-search-input" />
            <div className="media-library-nav">
              <Link to="?type=custom" className={activeTab === "custom" ? 'active' : ''}>
                <AddPhotoAlternateOutlinedIcon fontSize="small" />
                <span>My gallery</span>
              </Link>
              <Link to="?type=general" className={activeTab === "general" ? 'active' : ''}>
                <CollectionsOutlinedIcon fontSize="small" />
                <span>Images</span>
              </Link>
              <Link to="?type=icons" className={activeTab === "icons" ? 'active' : ''}>
                <SentimentSatisfiedOutlinedIcon fontSize="small" />
                <span>Icons</span>
              </Link>
            </div>
          </div>
          <div className="media-library-nav-second">
            <div className={`pb-2 d-flex align-items-center justify-content-between`}>
              <Multiselect
                options={sortInitialValues}
                displayValue="name"
                selectedValues={[selectedSort]}
                onSelect={handleSortChange}
                singleSelect={true}
                style={{
                  multiselectContainer: {
                    background: '#fff',
                    width: '200px',
                    cursor: 'pointer'
                  },
                  searchBox: {
                    border: '1px solid #BBBBBB',
                    borderRadius: '4px'
                  },
                  chips: {
                    margin: 0
                  },
                  option: {
                    fontSize: '12px'
                  }
                }}
              />
  
              { activeTab === "custom" && (
                <FormifyButton 
                  title="Add new asset"
                  onClick={ () => setVisible(true) }
                  classes="add-new-assets"
                  icon={ 
                    <svg width="16" height="16" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.20801 17.0287H31.4912" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M16.8496 31.6702V2.38709" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  }
                />
              )}
            </div>
          
          {isLoading ? (
            <div className="form-list">
              <Youtube />
            </div>
          ) : images.length > 0 ? (
            <div className="form-list pe-5 media-library">
              {images.map((img, index) => (
                <ImageBox 
                  img={ img } 
                  key={ img._id } 
                  index={ index } 
                  setDelete={ setDelete } 
                /> 
              ))}
            </div>
          ) : (
            <div className="no-font">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/missing font-02.png"}
                  alt="no-data"
                  width={80}
                />
              </div>
              <p>Seems like you don't have any image uploaded.</p>
              { activeTab === "custom" && (
                <FormifyButton 
                  title="Add new asset"
                  onClick={ () => setVisible(true) } 
                  classes="add-new-assets"
                  icon={ 
                    <svg width="16" height="16" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.20801 17.0287H31.4912" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M16.8496 31.6702V2.38709" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  }
                />
              )}
            </div>
          )}
          </div>
        </div>
      </div>
      { visible && (
        <MediaLibraryModal 
          uploadOnly={true} 
          setVisible={setVisible} 
          uploadAppendImage={uploadAppendImage} 
        /> 
      )}
      <ConfirmModal
        show={show}
        contact={deleteIntent}
        setDel={setDelete}
        setShow={setShow}
        del={deleteImage}
        text='Are you sure you want to delete this image?'
        subtext='If you are using this image it will affect all your forms.'
      />
    </div>
  );
};

export default MediaLibraryContent;