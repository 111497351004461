/* eslint-disable react-hooks/exhaustive-deps */
import { X } from "react-feather";
import SwitchChip from "../single-components/switchChip";
import { Dropdown, DropdownButton } from "react-bootstrap";
import RangeSlider from "../single-components/RangeSlider";
import AlignmentTabs from "../single-components/AlignmentTabs";
import { Accordion } from "react-bootstrap";
import { Image } from "react-feather";
import { Edit, Trash } from "react-feather";
import InputField from "../single-components/InputField";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import VerticalAligns from "../single-components/VerticalAligns";
import _ from 'lodash'
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const SingleChoiceContent = ({
  form: { widget, responsive },
  setWidgetDispatcher, 
  setCrop,
  mergeWidget,
}) => {
  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const addOption = () => {
    if (widget.content) {
      const newOption = `Option ${widget.content.options.length + 1}`;
      const options = Array.from(widget.content.options);
      options.push({
        value: newOption,
        image: null,
        key: Date.now(),
      });
      handleSetWidgetDispatcher(
        [`content.options`],
        [options],
        widget,
        setWidgetDispatcher
      );
    }
  };

  const removeOpt = (index) => {
    if (widget.content) {
      const options = Array.from(widget.content.options);
      options.splice(index, 1);
      handleSetWidgetDispatcher(
        [`content.options`],
        [options],
        widget,
        setWidgetDispatcher
      );
    }
  };

  const updateSliderstyle = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${e.target.id}`],
      [e.target.value + "px"],
      widget,
      setWidgetDispatcher
    );
  };

  const handleOptionChange = (e, index, type) => {
    if (widget.content) {
      const newWidget = _.cloneDeep(widget);
      const options = Array.from(newWidget.content.options);
      options[index].value = e.target.value;
      handleSetWidgetDispatcher(
        [`content.options`],
        [options],
        newWidget,
        setWidgetDispatcher,
        false,
        type
      );
    }
  };

  const changeCheckBtn = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const updateLayoutSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.layout`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateDirectionSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.direction`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const updateColumnNumberSelectOptions = (e) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.colNum`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const uploadChoiceImage = (index) => {
    setCrop({
      callback: imageCropper,
      hasIndex: true,
      index: index,
    });
  };

  const imageCropper = (key, url, index) => {
    handleSetWidgetDispatcher(
      [`content.options[${index}].imageKey`, `content.options[${index}].image`],
      [key, url],
      widget,
      setWidgetDispatcher
    );
  };

  const deleteImage = (i) => {
    handleSetWidgetDispatcher(
      [`content.options[${i}].image`, `content.options[${i}].imageKey`],
      [null, null],
      widget,
      setWidgetDispatcher
    );
  };

  const handleChangeAlignQuestion = (e, id) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.label.textAlign`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher(
      [`content.question`],
      [e.target.value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const updateFontFamily = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.option.fontFamily`,
        `style.desktop.description.fontFamily`,
        `style.desktop.label.fontFamily`,
      ],
      [
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url },
      ],
      widget,
      setWidgetDispatcher,
      true
    );
  };

  const updateSubeheadingFontSize = (e, type) => {
    let value = e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher(
      [`style.${responsive}.label.${e.target.id}`],
      [value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };
  const updateOptionsFontSize = (e, type) => {
    let value = e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher(
      [`style.${responsive}.option.${e.target.id}`],
      [value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };
  let direction =  getResponsiveStyle(widget?.style, responsive, "direction", null) || 'row';
  let layout =  getResponsiveStyle(widget?.style, responsive, "layout", null) || 1; 


  return (
    <div className="settings-field-container">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="editable-field py-3">
            <p className="ps-0">Question</p>
          </Accordion.Header>
          <Accordion.Body className="accordion_body">
            <div className="row">
              <div className="col-lg-12 mt-0">
                <div className="input-holder">
                  <InputField
                    id={"title"}
                    label={"Title"}
                    onChange={handleChange}
                    placeholder={"Title"}
                    isTitle={true}
                    type="text"
                    value={widget.content.question || ""}
                    mergeWidget={mergeWidget}
                  />
                </div>
                <div className="mt-3">
                  <AlignmentTabs
                    tag={getResponsiveStyle(widget?.style, responsive,  "label", "textAlign")}
                    handleSelect={handleChangeAlignQuestion}
                    id={"textAlign"}
                    text={"Question Alignmnet"}
                  />
                </div>
                <div className="editable-field quick-action-container mt-3 mb-2">
                  <div className="d-flex justify-between">
                    <p>Font Size(px)</p>
                    <div className="mb-1"><ChangeResponsiveness /></div>
                  </div>
                  <FormifyInput 
                    type="number"
                    id={"fontSize"}
                    min={1}
                    max={200}
                    onChange={updateSubeheadingFontSize}
                    value={checkFontSize(widget?.style, responsive, "label", "fontSize")}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="editable-field py-3">
            <p className="ps-0">Options</p>
          </Accordion.Header>
          <Accordion.Body className="accordion_body">
            <div className="col-lg-12 mt-1">
              {widget.content.options.map((op, index) => {
                return (
                  <div className="col-lg-12">
                    <div className="row-data">
                      <FormifyInput 
                        type="text"
                        id={"text"}
                        onChange={(e, type) => {
                          handleOptionChange(e, index, type);
                        }}
                        value={op.value || ""}
                      />
                      <div
                        className="remove-btn"
                        href="#/"
                        onClick={() => {
                          removeOpt(index);
                        }}
                      >
                        <X style={{ color: "#ea4335" }} />
                      </div>
                    </div>
                    {op.image ? (
                      <div className="preview-wrapp">
                        <div className="img-size">
                          <img src={op.image} className="img-fluid" alt="" />
                        </div>
                        <div className="d-flex mt-2">
                          <button
                            className="delete-btn-icon"
                            onClick={() => {
                              deleteImage(index);
                            }}
                          >
                            <Trash className="delete" />
                          </button>
                          <button
                            className="edit-btn"
                            onClick={() => uploadChoiceImage(index)}
                          >
                            <Edit className="delete" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="image-container d-flex  flex-column align-items-center">
                        <div className="upload w-100">
                          <button
                            type="button"
                            onClick={() => uploadChoiceImage(index)}
                          >
                            <Image
                              style={{
                                backgroundColor: "#6563FF",
                                padding: "4px",
                                borderRadius: "7px",
                                color: "white",
                                height: "32px",
                                width: "32px",
                              }}
                            />
                            <p className="m-0 text-center">Select image</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <button className="add-option" onClick={addOption}>
              Add Option
            </button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="editable-field py-3">
            <p className="ps-0">Default Options</p>
          </Accordion.Header>
          <Accordion.Body className="accordion_body">
            <div className="col-lg-12 mt-3">
              <p className="m-0">Default Selected</p>
              <div className="input-holder">
                <select
                  value={widget.content.default || null}
                  onChange={(e) => {
                    handleSetWidgetDispatcher(
                      [`content.default`],
                      [e.target.value],
                      widget,
                      setWidgetDispatcher
                    );
                  }}
                  className="mt-0"
                >
                  <option value={null}>No default</option>
                  {widget.content
                    ? widget.content.options.map((op, index) => {
                        return (
                          <option value={op.value} key={index}>
                            {op.value}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="editable-field py-3">
            <p className="ps-0">Customize Widget</p>
          </Accordion.Header>
          <Accordion.Body className="accordion_body">
            <div className="col-lg-12 mt-3">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="editable-field quick-form-actions">
                    <div className="quick-action-container">
                      <FontFamilyPicker
                        attribute={widget?.style?.desktop?.label}
                        onChangeFont={updateFontFamily}
                        id={"label"}
                      />
                    </div>
                  </div>

                  <div className="editable-field quick-action-container mt-4">
                    <div className="d-flex justify-between">
                      <p>Options Font Size(px)</p>
                      <div className="mb-1"><ChangeResponsiveness /></div>
                    </div>
                    <FormifyInput 
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={updateOptionsFontSize}
                      value={checkFontSize(widget?.style, responsive, "option", "fontSize")}
                    />
                  </div>
                  <div className="mt-3">
                    {direction === 'row' && <AlignmentTabs
                      tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
                      handleSelect={handleChangeAlign}
                      id={"alignment"}
                      text={"Alignment"}
                    />}
                  </div>
                  <div className="mt-3">
                    <VerticalAligns
                      tag={getResponsiveStyle(widget?.style, responsive,  "verticalAlignment", null)}
                      handleSelect={handleChangeAlign}
                      id={"verticalAlignment"}
                      removeXPadding={true}
                      text={direction === 'row'? "Vertical Alignment" : "Alignment"}
                    />
                  </div>
                  <hr />
                  <div className="editable-field form-row mt-4">
                    <p> Layout</p>
                    <div className="widget-range-slider-container">
                      <DropdownButton
                        title={
                          widget.content
                            ? "Layout " + layout
                            : "Layout 1"
                        }
                        id={"layout"}
                        name={"layout"}
                        onSelect={updateLayoutSelectOptions}
                        className="form-dropdown-select"
                      >
                        <Dropdown.Item eventKey="1">Layout 1</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Layout 2</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>

                  <div className="editable-field form-row  ">
                    <p> Direction</p>
                    <div className="widget-range-slider-container">
                      <DropdownButton
                        title={
                          widget.style
                            ? direction
                            : "Row"
                        }
                        id={"layout"}
                        name={"layout"}
                        onSelect={updateDirectionSelectOptions}
                        className="form-dropdown-select"
                      >
                        <Dropdown.Item eventKey="row">Row</Dropdown.Item>
                        <Dropdown.Item eventKey="column">Column</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                  {layout == "2" ? (
                    <>
                      <RangeSlider
                        label={"Column Gap"}
                        id={"columnGap"}
                        tag={getResponsiveStyle(widget?.style, responsive,  "columnGap", null)}
                        min={0}
                        max={200}
                        onChange={updateSliderstyle}
                      />
                      <RangeSlider
                        label={"Row Gap"}
                        id={"rowGap"}
                        tag={getResponsiveStyle(widget?.style, responsive,  "rowGap", null)}
                        min={0}
                        max={200}
                        onChange={updateSliderstyle}
                      />
                    </>
                  ) : null}

                  {layout == "2" ? (
                    <div className="editable-field form-row  ">
                      <p> Column Number</p>
                      <div className="widget-range-slider-container">
                        <DropdownButton
                          title={
                            widget.content
                              ? getResponsiveStyle(widget?.style, responsive,  "colNum", null)
                              : "3"
                          }
                          id={"colNum"}
                          name={"colNum"}
                          onSelect={updateColumnNumberSelectOptions}
                          className="form-dropdown-select"
                        >
                          <Dropdown.Item eventKey="1"> 1</Dropdown.Item>
                          <Dropdown.Item eventKey="2"> 2</Dropdown.Item>
                          <Dropdown.Item eventKey="3"> 3</Dropdown.Item>
                          <Dropdown.Item eventKey="4"> 4</Dropdown.Item>
                          <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                          <Dropdown.Item eventKey="6"> 6</Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  ) : null}
                  <hr />
                  <div className="editable-field form-row mt-2">
                    <p className="field-label">Required</p>
                    <SwitchChip
                      id="required"
                      checked={widget.content.required || false}
                      onChange={(val) => {
                        changeCheckBtn(val, "required");
                      }}
                    />
                  </div>
                  <div className="editable-field form-row mt-2">
                    <p className="field-label">Hide Label</p>
                    <SwitchChip
                      id="hide_label"
                      checked={widget.content.hide_label || false}
                      onChange={(val) => {
                        changeCheckBtn(val, "hide_label");
                      }}
                    />
                  </div>
                  <div className="editable-field form-row mt-2">
                    <p className="field-label">Hide Checkbox</p>
                    <SwitchChip
                      id="hide_checkbox"
                      checked={widget.content.hide_checkbox || false}
                      onChange={(val) => {
                        changeCheckBtn(val, "hide_checkbox");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(
  SingleChoiceContent
);
