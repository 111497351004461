import { useState } from "react";
import FormifyButton from "../utils/FromifyButton";
import { CircularProgress } from "@mui/material";

const ImageBox = ({ img, index, setDelete }) => {
  const [dimensions, setDimensions] = useState("");
  const setImageDimensions = (img) => {
    setDimensions(img.target.naturalWidth + 'x' + img.target.naturalHeight)
  }

  return (
    <div className="image-container">
      <div className="image">
        <div className="image-inner">
          <img onLoad={ setImageDimensions } src={img.url} alt={ img.name } style={{ maxWidth: '100%', maxHeight: '100%' }} />
          { !dimensions && <CircularProgress size={30} style={{ alignSelf: 'center' }} /> }
        </div>
      </div>
      <div className="image-info">
        <h2>{ img.name }</h2>
        <p><span>{ img.name.split(".").pop() }</span> - { dimensions }</p>
      </div>
      <div className="remove-button">
        <FormifyButton 
          onClick={ () => setDelete({ id: img._id, index}) }
          icon={
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.125 10.875L7.125 8.625" stroke="#212134" strokeLinecap="round"/>
              <path d="M10.875 10.875L10.875 8.625" stroke="#212134" strokeLinecap="round"/>
              <path d="M2.25 4.875H15.75V4.875C15.1689 4.875 14.8783 4.875 14.639 4.93494C13.9232 5.11425 13.3642 5.67319 13.1849 6.38904C13.125 6.62832 13.125 6.91888 13.125 7.5V10.625C13.125 12.5106 13.125 13.4534 12.5392 14.0392C11.9534 14.625 11.0106 14.625 9.125 14.625H8.875C6.98938 14.625 6.04657 14.625 5.46079 14.0392C4.875 13.4534 4.875 12.5106 4.875 10.625V7.5C4.875 6.91888 4.875 6.62832 4.81506 6.38904C4.63575 5.67319 4.07681 5.11425 3.36096 4.93494C3.12168 4.875 2.83112 4.875 2.25 4.875V4.875Z" stroke="#212134" strokeLinecap="round"/>
              <path d="M7.125 2.62518C7.125 2.62518 7.5 1.875 9 1.875C10.5 1.875 10.875 2.625 10.875 2.625" stroke="#212134" strokeLinecap="round"/>
            </svg>
          }
        />
      </div>
    </div>
  )
};

export default ImageBox;