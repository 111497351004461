import axios from "axios";
import { tokenConfig } from "../../actions/auth";
import { BASE_DEV_URL } from "../../utils";
import store from "../../store";
import { toast } from "react-toastify";
import { setFormLoading, setForm } from "../../actions/form";
import _ from "lodash";
import { handleSetForm } from "./handleDispatcher";
import {
  getNewClientId,
  deleteElementFromForm,
  pushElementStep,
  formIntegrationsDeleteElement,
  formHasProductWidget,
  stepHasSkipStep,
  isLastStep,
  formHasWidget,
  getFormProductWidget,
  getSpecificWidgetId,
  formBlockValidation,
  formHasStore,
  storeIsConnected,
  deleteProductElement,
  deleteUpsellElement,
  redirectSingleChoice,
  formHasWidgetLastPage,
} from "./formBackendFunctions";

export const updateForm = (form, params) => (dispatch) => {
  if (!formBlockValidation(form)) return;
  dispatch(setFormLoading(true));

  axios
    .put(`${BASE_DEV_URL}/form/${params.id}`, form, tokenConfig(store.getState))
    .then((response) => {
      dispatch(setForm(response.data.form, "reset"));
      dispatch(setFormLoading(false));
      toast.success("Form is updated");
    })
    .catch((err) => {
      dispatch(setFormLoading(false));
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    });
};

export const deleteElement =
  (element, type = "", step = "", showMessage = true, params, form) =>
  (dispatch) => {
    if (!formBlockValidation(form)) return;
    const newForm = _.cloneDeep(form);
    const newStep = deleteElementFromForm(newForm.steps[step - 1], element._id);
    const integrations = formIntegrationsDeleteElement(newForm, element._id);
    const updatedForm = handleSetForm(
      [`steps[${step - 1}]`, `integrations`, `form_settings.type`],
      [
        newStep,
        integrations,
        element.element_type === "form_product" ||
        element.element_type === "form_dynamic_product"
          ? "submit"
          : newForm.form_settings.type,
      ],
      newForm
    );
    dispatch(setForm(updatedForm, true));

    if (type == "product_widget") {
      var data = getSpecificWidgetId("Upsell", params.step, newForm);
      if (data) {
        deleteElement(data[0], "", data[1], false, params, newForm);
        toast.success("Upsell widget has also been deleted.");
      } else toast.success("Element deleted successfully");
    } else {
      if (showMessage) toast.success("Element deleted successfully");
    }
  };

const createElementAction = (element, order, params, form, type, dispatch) => {
  try {
    const newForm = _.cloneDeep(form);
    element = { ...element, order: order + 1, _id: getNewClientId() };
    const stepIndex = parseInt(params.step);
    if (
      element.element_type === "form_product" &&
      formHasProductWidget(newForm)
    ) {
      throw "Form already has a Products Widget";
    }
    if (
      element.element_type === "form_upsell" &&
      formHasWidget(newForm, "form_upsell")[0]
    ) {
      throw "Form already has an Upsell Widget";
    }
    if (
      element.element_type === "form_email" &&
      formHasWidget(newForm, "form_email")[0]
    ) {
      throw "Form already has an Email Widget";
    }
    if (element.element_type === "form_skip_step") {
      if (isLastStep(newForm, stepIndex)) {
        throw "Cant add Skip Widget on the last step!";
      }
      if (stepHasSkipStep(newForm, stepIndex)) {
        throw "Step already has a Skip Step Widget";
      }
    }
    if (
      element.element_type === "form_upsell" &&
      !formHasProductWidget(newForm)
    ) {
      throw "To add  upsell you need to have  a product Widget";
    } else if (
      element.element_type === "form_upsell" &&
      formHasProductWidget(newForm)
    ) {
      const [formStep, productWidgetElement] = getFormProductWidget(newForm);
      element = {
        ...element,
        content: {
          ...element.content,
          product_widget: productWidgetElement._id,
        },
      };
    }

    if (
      element.element_type === "form_redirect_choice" &&
      !isLastStep(newForm, stepIndex)
    ) {
      throw "Please remove the redirect widget to continue adding steps!";
    }

    //Do not allow to add widgets after REDIRECT CHOICE
    if (formHasWidgetLastPage(newForm, stepIndex, element)) {
      throw "Please remove the redirect widget to continue adding other widgets!";
    }

    const newStep = pushElementStep(newForm.steps[stepIndex - 1], element);
    const updatedForm = handleSetForm(
      [`steps[${params.step - 1}]`, `form_settings.type`],
      [
        newStep,
        element.element_type === "form_product"
          ? "checkout"
          : newForm.form_settings.type,
      ],
      newForm
    );
    toast.success(
      `Element ${
        type === "duplicated" ? "duplicated" : "created"
      } successfully.`
    );
    dispatch(setForm(updatedForm, true));
  } catch (msg) {
    toast.warning(msg);
  }
};

export const duplicateElement =
  (element, order, params, form) => (dispatch) => {
    if (!formBlockValidation(form)) return;
    element = _.omit(element, "_id");
    createElementAction(element, order, params, form, "duplicated", dispatch);
  };

export const createElement = (element, order, params, form) => (dispatch) => {
  if (!formBlockValidation(form)) return;
  createElementAction(element.data, order, params, form, "created", dispatch);
};

// not used for the moment
export const updateElement = (
  element,
  params,
  setFormDispatcher,
  setWidgetDispatcher
) => {
  axios
    .put(
      `${BASE_DEV_URL}/form/${params.id}/step/${params.step}/element/${element._id}`,
      { element },
      tokenConfig(store.getState)
    )
    .then((response) => {
      setFormDispatcher(response.data.form);
      setWidgetDispatcher(element, false);
      toast.success("Form updated successfully");
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};
// not used for the moment

export const createStep = (orderNum, params, nav, form) => (dispatch) => {
  if (!formBlockValidation(form)) return;
  const newForm = _.cloneDeep(form);
  const step_number = newForm.steps.some((step) => {
    return orderNum ? step.order_number === orderNum : false;
  });
  if (step_number) return toast.error("Step with that number already exists.");

  //Check if Redirect URL Widget exists
  const hasRedirectWidget = newForm.steps.some((step) => {
    return step.elements.some((s) => {
      return s.element_type === "form_redirect_choice" ? true : false;
    });
  });

  if (hasRedirectWidget)
    return toast.error(
      "Can't add new step page beacuse redirect widget must be on the last one!"
    );

  const updatedForm = handleSetForm(
    [`steps[${orderNum - 1}]`, `steps[${orderNum - 1}].elements`],
    [{ order_number: orderNum, _id: getNewClientId() }, []],
    newForm
  );
  dispatch(setForm(updatedForm, true));
  nav(`/form/${params.id}/step/${orderNum}`);
  toast.success(`Step '${orderNum}' created successfully.`);
};
export const hasRedirectWidget = (form) => {
  return form.steps.some((step) => {
    return step.elements.some((s) => {
      return s.element_type === "form_redirect_choice" ? true : false;
    });
  });
};
export const duplicateStep =
  (orderNum, step, params, nav, form) => (dispatch) => {
    if (step.elements[0].element_type === "form_redirect_choice") {
      return toast.error("redirect widget can't be duplicated");
    } else {
      if (!formBlockValidation(form)) return;
      const newForm = _.cloneDeep(form);

      // Update order_number of all steps with a greater order_number
      newForm.steps.forEach((s) => {
        if (s.order_number > step.order_number) {
          s.order_number++;
        }
      });

      let newStep = {
        ...step,
        order_number: step.order_number + 1,
        _id: getNewClientId(),
      };
      const newElements =
        newStep.elements &&
        newStep.elements.length > 0 &&
        newStep.elements.map((item) => {
          return { ...item, _id: getNewClientId() };
        });
      newStep.elements = newElements ? newElements : [];

      // Insert new step at index of duplicated step + 1
      newForm.steps.splice(orderNum, 0, newStep);
      newForm.steps.sort((a, b) => a.order_number - b.order_number);
      const updatedForm = handleSetForm([], [], newForm);

      dispatch(setForm(updatedForm, true));
      nav(`/form/${params.id}/step/${newStep.order_number}`);
      toast.success(`Step '${newStep.order_number}' duplicated successfully.`);
    }
  };

export const moveStep =
  (orderNum, direction, params, nav, form) => (dispatch) => {
    if (!formBlockValidation(form)) return;
    const newForm = _.cloneDeep(form);
    let steps = newForm.steps;
    const index = steps.findIndex((step) => {
      return step.order_number === parseInt(orderNum);
    });
    const indexLeft = steps.findIndex((step) => {
      return step.order_number === parseInt(orderNum) - 1;
    });
    const indexRight = steps.findIndex((step) => {
      return step.order_number === parseInt(orderNum) + 1;
    });

    //Check if Redirect URL Widget exists
    const hasRedirectWidget = newForm.steps.some((step) => {
      return step.elements.some((s) => {
        return s.element_type === "form_redirect_choice" ? true : false;
      });
    });

    if (hasRedirectWidget) {
      if (orderNum === steps.length - 1 && direction === "right")
        return toast.error(
          "Can't move step page beacuse redirect widget must be on the last one!"
        );
      else if (orderNum > steps.length - 1)
        return toast.error(
          "Can't move step page beacuse redirect widget must be on the last one!"
        );
    }

    const temp = steps[index].order_number;
    if (direction === "left") {
      steps[index].order_number = parseInt(orderNum) - 1;
      steps[indexLeft].order_number = temp;
    } else {
      steps[index].order_number = parseInt(orderNum) + 1;
      steps[indexRight].order_number = temp;
    }
    steps = _.sortBy(steps, ["order_number"]);
    const updatedForm = handleSetForm([`steps`], [steps], newForm);
    dispatch(setForm(updatedForm, true));
    if (direction === "left") nav(`/form/${params.id}/step/${orderNum - 1}`);
    else nav(`/form/${params.id}/step/${orderNum + 1}`);
    toast.success(`Step '${orderNum}' moved ${direction} successfully.`);
  };

export const deleteStep = (step, params, nav, form) => (dispatch) => {
  if (!formBlockValidation(form)) return;
  let newForm = _.cloneDeep(form);
  const steps = newForm.steps.filter(
    (item) => item.order_number !== parseInt(step)
  );
  if (steps.length < 1)
    return toast.error("Can't delete, form must have at least one step");
  steps.map((item) => {
    if (item.order_number > step) item.order_number -= 1;
  });
  const updatedForm = handleSetForm([`steps`], [steps], newForm);
  dispatch(setForm(updatedForm, true));
  nav(`/form/${params.id}/step/${step == 1 ? step : step - 1}`);
};

export const connectStore = (shop, auth, form) => (dispatch) => {
  const newForm = _.cloneDeep(form);
  if (
    !formHasStore(newForm) ||
    !storeIsConnected(auth, shop) ||
    !formBlockValidation(form)
  )
    window.location.href = `/integrations/${shop}`;

  let updatedForm = null;
  if (shop === "subbly") {
    updatedForm = handleSetForm(
      [`store_selected`, `integrations.subbly`],
      [shop, { fields: [] }],
      newForm
    );
  } else {
    updatedForm = handleSetForm([`store_selected`], [shop], newForm);
  }
  dispatch(setForm(updatedForm, true));
};

export const disconnectStore = () => (dispatch, getState) => {
  const { form } = getState().form;
  let newForm = _.cloneDeep(form);
  if (!formBlockValidation(newForm)) return;
  const step = deleteProductElement(newForm);
  if (step) {
    const stepIndex = newForm.steps.findIndex((item) => item._id === step._id);
    newForm = handleSetForm([`steps.${stepIndex}`], [step], newForm);
  }
  const step2 = deleteUpsellElement(newForm);
  if (step2) {
    const step2Index = newForm.steps.findIndex(
      (item) => item._id === step2._id
    );
    newForm = handleSetForm([`steps.${step2Index}`], [step2], newForm);
  }

  newForm = handleSetForm(
    [`form_settings.type`, `store_selected`],
    ["submit", null],
    newForm
  );
  dispatch(setForm(newForm, true));
};

export const disconnectIntegration =
  (integration, params) => (dispatch, getState) => {
    const { form } = getState().form;
    let newForm = _.cloneDeep(form);
    if (!formBlockValidation(newForm)) return;

    // const updatedForm = handleSetForm(
    //   [`integrations.${integration}`],
    //   [null],
    //   newForm
    // );
    // delete newForm.integrations[integration];
    newForm = _.omit(newForm, [`integrations.${integration}`]);
    dispatch(setForm(newForm, true));
  };

export const createResponses = (formId, uuid) => (dispatch, getState) => {
  const responses = getState().form.responses;
  const response = axios
    .post(`${BASE_DEV_URL}/public/form/${formId}/response`, {
      response: responses,
      uuid: uuid,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {});
  return response;
};

export const calculateResetDate = (registrationDate) => {
  const currentDate = new Date();
  const registrationDay = registrationDate.getDate();
  const currentDay = currentDate.getDate();

  let resetDate;

  if (currentDay >= registrationDay) {
    // If the current day has passed the registration day, set reset date to next month
    resetDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      registrationDay
    );
  } else {
    // If the current day hasn't passed the registration day, set reset date to this month
    resetDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      registrationDay
    );
  }

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedResetDate = resetDate.toLocaleDateString("en-US", options);

  return formattedResetDate;
};
