import { Modal } from "react-bootstrap";

import { X } from "react-feather";
import { connect } from "react-redux";
import { deleteUser } from "../../../actions/auth";

const DeleteUserModal = (props) => {
  return (
    <Modal show={props.show} size="lg" centered className="name-modal">
      <Modal.Header style={{ justifyContent: "center" }}>
        <div className="name-modal-header text-center">
          <X
            style={{
              marginBottom: "15px",
              backgroundColor: "#FFB446",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              height: "55px",
              width: "55px",
            }}
          />
          <h4>Are you sure you want to delete your account?</h4>
          <p>
            When you delete your account, you loose are related data and
            permissions. This cannot be undone.
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <button
              className="danger-btn"
              type="button"
              style={{ width: "100%", fontSize: "14px" }}
              onClick={() => {
                props.deleteUser();
              }}
            >
              Delete Account
            </button>
            <button
              className="invert-formify-btn mt-3"
              onClick={() => {
                props.handler(false);
              }}
              type="button"
              style={{ width: "100%", fontSize: "14px" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, { deleteUser })(DeleteUserModal);
