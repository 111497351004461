/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { ArrowRight, Plus, X } from "react-feather";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenConfig } from "../../../actions/auth";
import store from "../../../store";
import {
  BASE_DEV_URL,
  formGetCrmFields,
  integrationFooter,
  loginSalesforce,
  mapAuthAndFormStateToProps
} from "../../../utils";
import { useEffect, useMemo, useState } from "react";
import { disconnectIntegration } from "../../utils/formFunctions";
import { setFormDispatcher } from "../../../actions/form";
import { connect } from "react-redux";
import { handleSetForm } from "../../utils/handleDispatcher";
import { integrationAccountValidation } from "../../utils/formBackendFunctions";

const SalesForce = ({ auth, form: { form }, setFormDispatcher, disconnectIntegration }) => {
  const params = useParams();
  const salesforceIsAuth = useMemo(() => {
    return auth.user
      ? auth.user.crm
        ? auth.user.crm.salesforce
          ? true
          : false
        : false
      : false;
  }, [auth])

  const formHasSalesforce = useMemo(() => {
    return form
      ? form.integrations
        ? form.integrations.salesforce
          ? true
          : false
        : false
      : false;
  }, [form]);
  
  const [salesForceProps, setProps] = useState([]);
  const [salesForceSobjects, setSobjets] = useState([]);
  const [formData, setForm] = useState({
    sobject: null,
    fields: [],
    show: false,
    elements: [],
    required_integration_fields: [],
  });

  useEffect(() => {
    if (
      form &&
      form.steps &&
      form.steps.some((step) => {
        return step.elements;
      })
    ) {
      let elements = formGetCrmFields(form);

      if (
        form.integrations &&
        form.integrations.salesforce &&
        form.integrations.salesforce.fields
      ) {
        setForm({
          ...formData,
          fields: form.integrations.salesforce.fields,
          sobject: form.integrations.salesforce.sobject,
          elements,
        });
      } else {
        setForm({
          ...formData,
          elements,
          fields: [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (salesforceIsAuth) {
      axios
        .get(
          `${BASE_DEV_URL}/crm/salesforce/sobjects`,
          tokenConfig(store.getState)
        )
        .then((result) => {
          setSobjets([...result.data.sobjects]);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
  }, [salesforceIsAuth]);

  useEffect(() => {
    if (formData.sobject) {
      axios
        .get(
          `${BASE_DEV_URL}/crm/salesforce/sobjects/${formData.sobject}/`,
          tokenConfig(store.getState)
        )
        .then((result) => {
          setProps([...result.data.fields]);

          if (
            !formHasSalesforce ||
            !(formData.sobject === form.integrations.salesforce.sobject)
          ) {
            const fields = [];
            const required_integration_fields = result.data.fields.filter(
              (item) =>
                !item.nillable && item.createable && !item.defaultedOnCreate
            );

            required_integration_fields.forEach((item) => {
              fields.push({
                integration_field: item.name,
                formify_field:
                  formData.elements.length > 0
                    ? formData.elements[0]._id
                    : null,
              });
            });
            setForm({
              ...formData,
              fields,
              required_integration_fields,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasSalesforce, formData.sobject]);

  useEffect(() => {
    if (salesForceSobjects.length > 0 && !formHasSalesforce) {
      setForm({ ...formData, sobject: salesForceSobjects[0].name });
    }
  }, [salesForceSobjects, formHasSalesforce]);

  const handleChange = (e) => {
    setForm({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const authenticateBody = () => {
    return (
      <div className="col-12 integration-body mt-4">
        {salesforceIsAuth ? (
          formHasSalesforce ? (
            <>
              <h4 className="formify-h4">Integration is up and running!</h4>
              <p className="formify-sm-p">
                You have successfully created your integration.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                }}
              >
                Edit Configuration
              </button>
            </>
          ) : (
            <>
              <h4 className="formify-h4">Salesforce Configuration</h4>
              <p className="formify-sm-p">
                Give Salesforce access to your form in order to import products to
                Formify.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm({ ...formData, show: true });
                }}
              >
                Configure Salesforce
              </button>
            </>
          )
        ) : (
          <>
            <h4 className="formify-h4">Salesforce Authenticaiton</h4>
            <p className="formify-sm-p">
              Give Salesforce access to your form in order to import products to
              Formify.
            </p>
            <button
              onClick={() => {
                window.open(loginSalesforce);
              }}
              className="formify-btn-no-margin mt-3"
            >
              Authenticate
            </button>
          </>
        )}
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!integrationAccountValidation(auth, "Salesforce")) return;

    const updatedForm = handleSetForm([`integrations.salesforce`], [{ sobject: formData.sobject, fields: formData.fields }], form);
    setFormDispatcher(updatedForm, true);
    toast.success("Fields are created");
  };

  const removeRow = (index) => {
    const fields = Array.from(formData.fields);
    fields.splice(index, 1);

    setForm({
      ...formData,
      fields: fields,
    });
  };

  const handleIntegrationChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], integration_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const handleFormifyChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], formify_field: e.target.value };
    setForm({
      ...formData,
      fields,
    });
  };

  const salesforceForm = () => {
    return (
      <div className="col-8 integration-form">
        <form onSubmit={handleSubmit}>
          <hr className="grey-hr" />
          <div className="input-holder">
            <label htmlFor="sobject">Choose an Object</label>
            <p className="formify-sm-p">
              Select the object you want to send responses to
            </p>
            <select
              value={formData.sobject || ""}
              id="sobject"
              onChange={handleChange}
            >
              {salesForceSobjects.length > 0
                ? salesForceSobjects.map((sobject) => {
                    return <option value={sobject.name}>{sobject.name}</option>;
                  })
                : null}
            </select>
          </div>
          <hr className="grey-hr" />

          {formData.fields.length > 0 ? (
            <div className="input-holder">
              <label>Match Fields</label>
              <p className="formify-sm-p">
                Connect the fields on your form with the fields on your
                Salesforce object.
              </p>
              {formData.fields.map((field, index) => {
                return (
                  <div className="form-input">
                    <div className="me-3">
                      <label className="formify-sm-p">Salesforce Field</label>
                      <select
                        value={field.integration_field}
                        className="integration-select"
                        disabled={formData.required_integration_fields.some(
                          (i) => i.name === field.integration_field
                        )}
                        style={
                          formData.required_integration_fields.some(
                            (i) => i.name === field.integration_field
                          )
                            ? { cursor: "not-allowed" }
                            : null
                        }
                        onChange={(e) => {
                          handleIntegrationChange(e, index);
                        }}
                      >
                        {salesForceProps.map((prop) => {
                          return (
                            <option value={prop.name}>
                              {prop.name.charAt(0).toUpperCase() +
                                prop.name.slice(1)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="me-2">
                      <label className="formify-sm-p">Formify Field</label>
                      <select
                        id="email"
                        value={field.formify_field}
                        onChange={(e) => {
                          handleFormifyChange(e, index);
                        }}
                        className="integration-select"
                      >
                        {formData.elements.map((element) => {
                          return (
                            <option value={element._id} key={element._id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formData.required_integration_fields.some(
                      (i) => i.name === field.integration_field
                    ) ? null : (
                      <div
                        className="remove-row"
                        onClick={() => {
                          removeRow(index);
                        }}
                      >
                        <X style={{ color: "#ea4335" }} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}

          {formData.sobject ? (
            <>
              <div
                className="add-new-field d-flex mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (formData.fields.length < salesForceProps.length) {
                    setForm({
                      ...formData,
                      fields: [
                        ...formData.fields,
                        {
                          integration_field:
                            salesForceProps.length > 0
                              ? salesForceProps[0].name
                              : null,
                          formify_field:
                            formData.elements.length > 0
                              ? formData.elements[0]._id
                              : null,
                        },
                      ],
                    });
                  } else {
                    toast.warning("You have reached the limit");
                  }
                }}
              >
                <Plus className="formify-color" />
                <p className="formify-p formify-color">Add new field</p>
              </div>
              <div className="d-flex flex-row ">
                <button className="formify-btn me-2">Save</button>
                <button
                  className="invert-formify-btn"
                  type="button"
                  onClick={() => {
                    setForm({ ...formData, show: false });
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid integraiton mt-5">
      <div className="row justify-content-center">
        <div className="col-12  integraiton-card ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="integration-header">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />
                  <ArrowRight
                    className="me-2"
                    style={{ color: "#6563FF", width: "20px", height: "20px" }}
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/salesforce.png"
                    }
                    alt="Mailchimp"
                    className="img-fluid"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                {formData.show ? salesforceForm() : authenticateBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {integrationFooter(formHasSalesforce, disconnectIntegration, "salesforce", params)}
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormDispatcher, disconnectIntegration })(SalesForce);