/* eslint-disable react-hooks/exhaustive-deps */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker,  LocalizationProvider } from '@mui/x-date-pickers'
import { TextField } from "@mui/material";
import { useState } from "react";
import AlignmentTabs from "../single-components/AlignmentTabs";
import SwitchChip from "../single-components/switchChip";
import WidthOptions from "../single-components/WidthOptions";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from '../../../utils/responsiveFunctions';

const DateContent = ({ form: { widget, responsive }, setWidgetDispatcher }) => {
  const [datePicker, setDate] = useState({
    value: null,
    show: false,
  });

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher(
      [`content.${e.target.id}`],
      [e.target.value],
      widget,
      setWidgetDispatcher,
      false,
      type
    );
  };

  const changeCheckBtn = (val, key) => {

    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const handleSeperatorChange = (e) => {
    if (widget.content) {
      const ele = { ...widget.content };
      ele.format = ele.format.replaceAll(ele.seperator, e.target.value);
      ele.mask = ele.mask.replaceAll(ele.seperator, e.target.value);
      ele.seperator = e.target.value;

      handleSetWidgetDispatcher(
        [`content`],
        [ele],
        widget,
        setWidgetDispatcher
      );
    }
  };

  const handleFormatChange = (e) => {
    const { value } = e.target;
    const newMask = value.replaceAll(/[a-zA-Z]/g, "_");
    handleSetWidgetDispatcher(
      [`content.format`, `content.mask`],
      [e.target.value, newMask],
      widget,
      setWidgetDispatcher
    );
  };

  const updateInputSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.input.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher(
      [`style.${responsive}.${id}`],
      [e],
      widget,
      setWidgetDispatcher
    );
  };

  const formatOptions = [
    `dd${widget.content.seperator}MM${widget.content.seperator}yyyy`,
    `MM${widget.content.seperator}dd${widget.content.seperator}yyyy`,
    `yyyy${widget.content.seperator}MM${widget.content.seperator}dd`,
    `yyyy${widget.content.seperator}dd${widget.content.seperator}MM`,
    `yyyy${widget.content.seperator}MM`,
    `MM${widget.content.seperator}yyyy`,
    `dd${widget.content.seperator}MM`,
    `MM${widget.content.seperator}dd`,
  ];

  const updateFontFamily = (name, url) => {
    handleSetWidgetDispatcher(
      [`style.desktop.input.fontFamily`],
      [
        { name: name, src: url },
        { name: name, src: url },
      ],
      widget,
      setWidgetDispatcher,
      true
    );
  };

  const updateFontSize = (e, type) => {
    let value = e.target.id === "fontSize" ? e.target.value + "px" : e.target.value;
    handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [value], widget, setWidgetDispatcher, false, type);
  };

  return (
    <div className="settings-field-container">
      <div className="row">
        <div className="editable-field quick-form-actions">
          <div className="quick-action-container font-family-picker mb-2">
            <FontFamilyPicker
              attribute={widget?.style?.desktop?.input}
              onChangeFont={updateFontFamily}
              id={"label"}
            />
          </div>
          <div className="quick-action-container ">
            <div className="d-flex justify-between">
              <p>Font Size(px)</p>
              <div className="mb-1">
                <ChangeResponsiveness />
              </div>
            </div>
            <FormifyInput 
              type="number"
              id={"fontSize"}
              min={1}
              max={200}
              onChange={updateFontSize}
              value={checkFontSize(widget?.style, responsive, "input", "fontSize")}
            />
          </div>
          <div className="quick-action-container">
            <AlignmentTabs
              id={"alignment"}
              tag={getResponsiveStyle(widget?.style, responsive, "alignment", null)}
              handleSelect={handleChangeAlign}
            />
          </div>
          <div className="quick-action-container">
            <WidthOptions
              handleSelect={updateInputSelectstyle}
              tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
              id={"width"}
              name={"width"}
            />
          </div>
        </div>

        <div className="editable-field  quick-form-actions">
          <div className="col-lg-12">
            <div className="input-holder m-0 mt-3">
              <p className="field-heading m-0">Label</p>
              <FormifyInput 
                type="text"
                id={"date_label"}
                onChange={handleChange}
                className="mt-0"
                value={widget.content ? widget.content.date_label || "" : ""}
              />
            </div>

            <div className="col-lg-12 mt-3">
              <p className="field-heading m-0">Seperator</p>
              <div className="input-holder mt-0">
                <select
                  value={widget.content ? widget.content.seperator : null}
                  id="seperator"
                  onChange={handleSeperatorChange}
                  className="mt-0"
                >
                  <option value="/">/</option>
                  <option value="-">-</option>
                  <option value=".">.</option>
                </select>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <p className="field-heading m-0">Format</p>
              <div className="input-holder mt-0">
                {widget.content ? (
                  <select
                    value={widget.content ? widget.content.format : null}
                    id="format"
                    onChange={handleFormatChange}
                    className="mt-0"
                  >
                    {formatOptions.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="editable-field form-row mt-2">
              <p className="field-label">Required</p>
              <SwitchChip
                id="required"
                checked={widget.content ? widget.content.required : false}
                onChange={(val) => {
                  changeCheckBtn(val, "required");
                }}
              />
            </div>
            <div className="col-lg-12 mt-3">
              <p className="field-heading m-0">Default</p>
              <div className="d-flex flex-row align-items-center">
                <button
                  onClick={() => {
                    handleSetWidgetDispatcher(
                      [`content.default`],
                      [null],
                      widget,
                      setWidgetDispatcher
                    );

                    setDate({
                      ...datePicker,
                      show: false,
                    });
                  }}
                  className={
                    widget.content
                      ? !widget.content.default && !datePicker.show
                        ? "formify-btn-no-margin me-3"
                        : "invert-formify-btn-hover me-3"
                      : null
                  }
                >
                  None
                </button>
                <button
                  onClick={() => {
                    setDate({
                      ...datePicker,
                      show: true,
                    });
                  }}
                  className={
                    widget.content
                      ? widget.content.default || datePicker.show
                        ? "formify-btn-no-margin me-3"
                        : "invert-formify-btn-hover me-3"
                      : null
                  }
                >
                  Custom
                </button>
              </div>
            </div>

            <div className="col-lg-12 mt-3 date-picker-label">
              {widget.content.default || datePicker.show ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Default"
                    inputFormat={widget.content.format}
                    mask={widget.content.mask}
                    showTodayButton={true}
                    PopperProps={{
                      placement: "bottom-end",
                    }}
                    value={widget.content.default || datePicker.value}
                    onChange={(val) => {
                      handleSetWidgetDispatcher(
                        [`content.default`],
                        [val],
                        widget,
                        setWidgetDispatcher
                      );
                      setDate({
                        ...datePicker,
                        value: val,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(
  DateContent
);
