import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setDynamicProd,
  siblingsNumber,
  selectedSib,
  setSiblingVariant,
  setSiblingProducts,
} from "../../reducers/dynamicProdSlice";
import { Close } from "@mui/icons-material";
import { Accordion } from "react-bootstrap";
import DynamicProductContent from "../form/settings-components/content/DynamicProductContent";
import {
  handleSetFormDispatcher,
  handleSetWidgetDispatcher,
} from "./handleDispatcher";
import { setWidgetDispatcher } from "../../actions/form";
import { mapAuthAndFormStateToProps } from "../../utils";

const DynamicProducts = ({
  form: { form, widget, responsive },
  setWidgetDispatcher,
  baseProductsShopify,
  renderVariantCheckboxes,
}) => {
  const [existingSiblings, setExistingSiblings] = useState([]);
  const [selectedSibling, setSelectedSibling] = useState(null);
  const [selectedSiblingIndex, setSelectedSiblingIndex] = useState(null);

  const dispatch = useDispatch();
  const siblings = useSelector((state) => state.dynamicProd.siblingsNumber);
  const siblingVariants = useSelector(
    (state) => state.dynamicProd.siblings || {}
  );

  const handleSiblingSelect = (index) => {
    setSelectedSiblingIndex(index);
  };

  const handleSiblingChange = (index, e) => {
    const newSiblings = [...siblings];
    newSiblings[index] = {
      name: e.target.value,
      key: `${index}`,
      selectedVariants: newSiblings[index]?.selectedVariants || [],
    };

    dispatch(siblingsNumber(newSiblings));
    dispatch(
      setSiblingProducts({
        siblingIndex: index,
        products: newSiblings[index].products,
      })
    );

    handleSetWidgetDispatcher(
      [`content.siblings`, `content.siblings`],
      [newSiblings, newSiblings.map((sibling) => sibling.name || [])],
      widget,
      setWidgetDispatcher
    );
  };

  useEffect(() => {
    if (Array.isArray(widget.content.siblings)) {
      const updatedSiblings = widget.content.siblings.map((name, index) => {
        return { name, key: `${index}`, selectedVariants: [] };
      });

      setExistingSiblings(updatedSiblings);
    }
  }, [widget.content.siblings]);

  useEffect(() => {
    const initialSiblings = widget?.content?.siblingswithprods?.map((sib) => ({
      ...sib,
      selectedVariants: sib.selectedVariants || [],
    }));

    dispatch(siblingsNumber(initialSiblings));
  }, [widget.content.siblingswithprods, dispatch]);

  const addSibling = () => {
    const newSiblings = [
      ...siblings,
      {
        name: "",
        key: `${siblings.length}`,
        selectedVariants: [],
      },
    ];
    dispatch(siblingsNumber(newSiblings));
    handleSetWidgetDispatcher(
      [`content.siblings`, `content.siblings`],
      [newSiblings, newSiblings.map((sibling) => sibling.name || [])],
      widget,
      setWidgetDispatcher
    );
  };

  const deleteSibling = (index) => {
    const newSiblings = siblings.filter((_, i) => i !== index);
    dispatch(siblingsNumber(newSiblings));
    handleSetWidgetDispatcher(
      [`content.siblings`, `content.siblings`],
      [newSiblings, newSiblings.map((sibling) => sibling.name || [])],
      widget,
      setWidgetDispatcher
    );
};


  return (
    <>
      <h5 className="pt-4">
      Link the variants to your base products
      </h5>
      <p> (up to 5 combinations are allowed)</p>
      <Accordion defaultActiveKey="0">
        {existingSiblings.map((sibling, index) => (
          <Accordion.Item
            eventKey={index.toString()}
            key={index}
            style={{
              padding: "10px 5px",
              border: "1px solid black #6563ff !important",
              margin: "20px 0px",
            }}
          >
            <Accordion.Header className="field-labelsss">
              <label>Variant - {sibling.name ? sibling.name : ""}</label>
              {/* <button onClick={() => handleSiblingSelect(index)}>Select</button> */}
            </Accordion.Header>
            <Accordion.Body>
              <>
                <div key={index} className="sibling-entry">
                  <input
                    type="text"
                    value={sibling.name ? sibling.name : ""}
                    onChange={(e) => handleSiblingChange(index, e)}
                  />
                  <Close onClick={() => deleteSibling(index)}>Close</Close>
                </div>
                <p
                  style={{
                    color: "#6563ff",
                    margin: "20px 0px 0px 0px !important",
                    fontSize: "18px",
                  }}
                  className="pt-3"
                >
                  Select Variants for {sibling.name}
                </p>
                {baseProductsShopify.map((product, prodIndex) => (
                  <div key={product.id} style={{ margin: "15px 0px" }}>
                    <p>
                      <span style={{ color: "#6563ff" }}>
                        Product {prodIndex + 1}
                      </span>{" "}
                      : {product.title}
                    </p>
                    {renderVariantCheckboxes(
                      product,
                      prodIndex,
                      siblingVariants[index]?.selectedVariants || [],
                      index
                    )}
                  </div>
                ))}
              </>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {siblings.length < 6 && (
        <button className="formify-btn m-0" onClick={addSibling}>
          Add Sibling
        </button>
      )}
      {selectedSibling && (
        <DynamicProductContent
          sibling={selectedSibling}
          selectedSiblingIndex={selectedSiblingIndex}
        />
      )}
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(
  DynamicProducts
);
