import React from "react";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import BarLoader from "react-bar-loader";

const Loader = ({ loader }) => {
  return (
     <React.Fragment>
      {loader.isLoading && <div className="loader-fixed height-body">
        <div className="center-div-loading">
          {loader.showBrand ? <div  className="power-by">
            <img src={ process.env.PUBLIC_URL + "/assets/images/formify_logo_icon.svg"} alt="formify-logo"/>
            <p> Formify
            </p>
          </div> :  <p>Please wait..</p>}
          <BarLoader color="#4e4bfa" height="4" className="w-100 mt-2" />
        </div>
      </div>}
    </React.Fragment>
  );
};

const mapLoader = (state) => ({
  loader: state.loader,
});

export default connect(mapLoader)(Loader);
