/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";

const SkipStepStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [skipStepMarginLockValues, setSkipStepMarginLockValues] = useState(false);
  const [skipStepPaddingLockValues, setSkipStepPaddingLockValues] = useState(false);
  
  const updateSkipStepFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateSkipStepSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateSkipStepSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.${e.target.id}`], [e.target.value + "%"], widget, setWidgetDispatcher, false, type);
    }
   
    if (e.target.id.substring(0, 6) === "margin") {
      if (skipStepMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.marginBottom`,
            `style.${responsive}.marginRight`,
            `style.${responsive}.marginLeft`,
            `style.${responsive}.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (skipStepPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.paddingBottom`,
            `style.${responsive}.paddingRight`,
            `style.${responsive}.paddingLeft`,
            `style.${responsive}.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <p className="field-skipStep widget-label">SkipStep</p>

        <TypographyPopup
          attribute={widget.style[responsive]}
          onChangeFont={updateSkipStepFont}
          updateSliderstyle={updateSkipStepSliderstyle}
          updateSelectstyle={updateSkipStepSelectstyle}
          id={"skipStep"}
        />
        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer
                label={"Margin"}
                id={"margin"}
                tagLeft={widget.style[responsive].marginLeft}
                tagRight={widget.style[responsive].marginRight}
                tagTop={widget.style[responsive].marginTop}
                tagBottom={widget.style[responsive].marginBottom}
                onChange={updateSkipStepSliderstyle}
                setLockValue={setSkipStepMarginLockValues}
                lockValue={skipStepMarginLockValues}
              />
              <Spacer
                label={"Padding"}
                id={"padding"}
                tagLeft={widget.style[responsive].paddingLeft}
                tagRight={widget.style[responsive].paddingRight}
                tagTop={widget.style[responsive].paddingTop}
                tagBottom={widget.style[responsive].paddingBottom}
                onChange={updateSkipStepSliderstyle}
                setLockValue={setSkipStepPaddingLockValues}
                lockValue={skipStepPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(SkipStepStyle);
