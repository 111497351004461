import {
  AppShell,
  Navbar,
  Container,
  NumberInput,
  TextInput,
  Button,
  Divider,
  Textarea,
  Select,
  Switch,
  Loader,
  Text,
  Center,
  Card,
  Group,
  ThemeIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { Copy, Heart } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { Link, useParams } from "react-router-dom";
import LocalStorage from "../utils/LocalStorage";
import axios from "axios";
import { OPENAI_API_BASE_URL } from "../../utils";
import { v4 } from "uuid";

// Prompts
import prompts from "../../data/prompts.json";
import AiLoader from "../loaders/AiLoader";
import { ChevronLeft } from "react-feather";

const CopyDetail = () => {
  const params = useParams();
  const [prompt] = useState(
    prompts.find((prompt) => prompt.slug === params.slug)
  );
  const initialValues = {
    ...prompt.params.reduce((acc, param) => {
      acc[param.slug] = param.default || "";
      return acc;
    }, {}),
    toneOfVoice: "friendly",
    outputsAmount: 1,
    useEmoji: false,
  };
  const form = useForm({ initialValues });
  const [generating, setGenerating] = useState(false);
  const [copy, setCopy] = useState([]);
  const [saved, setSaved] = useState([]);

  const generateCopy = async () => {
    const params = form.values;

    let copy = prompt.params.reduce((acc, param) => {
      const value = params[param.slug];
      if (param.prompt && value !== "") {
        return acc.replace(
          `{{${param.slug}}}`,
          param.prompt.replace(`{{self}}`, value)
        );
      } else if (param.promptWhenNegative && value === "") {
        return acc.replace(
          `{{${param.slug}}}`,
          param.promptWhenNegative.replace(`{{self}}`, value)
        );
      }
      return acc.replace(`{{${param.slug}}}`, value);
    }, prompt.prompt);

    if (params.useEmoji) {
      copy += " Use emojis.";
    }
    if (params.toneOfVoice) {
      copy += " Use a " + params.toneOfVoice + " tone of voice.";
    }

    setSaved([]);
    setGenerating(true);

    try {
      const amount = Math.min(5, params.outputsAmount);
      const res = await axios.post(
        `${OPENAI_API_BASE_URL}/copy?amount=${amount}`,
        {
          userId: v4(),
          prompt: copy,
        }
      );

      setTimeout(() => {
        setCopy(res.data.copy);
        setGenerating(false);
      }, 1000);
    } catch (e) {
      showNotification({
        title: "Error",
        message: "There was an error generating your copy.",
        color: "red",
      });
      setGenerating(false);
    }
  };

  return (
    <main>
      <AppShell
        padding="md"
        navbar={
          <Navbar
            width={{ base: 400 }}
            p="xl"
            id="navbar-ai"
            style={{
              overflow: "auto",
              marginLeft:"200px",
              width:"25%"
            }}
          >
            <Link to="/copy">
            <div className="d-flex align-items-center justify-content-start simple-navbar-contents pointer mt-5">
            <div className="back-arrow">
              <ChevronLeft style={{ height: "20px", width: "20px" }} />
            </div>
            <div className="default-link">
              <p className="simple-navbar-title">Back to CopyGuru</p>
            </div>
          </div>
          </Link>
            <h1
              className="formify-h1"
              style={{
                paddingTop: "20px",
              }}
            >
              {prompt.name}
            </h1>
            <p>{prompt.description}</p>

            <Divider mt="md" mb="md" />

            <form onSubmit={form.onSubmit(generateCopy)}>
              {prompt.params.map((param) => {
                if (param.type === "number") {
                  return (
                    <NumberInput
                      key={param.slug}
                      label={
                        param.label +
                        (param.required !== false ? "" : " (optional)")
                      }
                      placeholder={param.placeholder}
                      name={param.slug}
                      required={param.required !== false}
                      mt="xs"
                      {...form.getInputProps(param.slug)}
                    />
                  );
                } else if (param.type === "textarea") {
                  return (
                    <Textarea
                      key={param.slug}
                      label={
                        param.label +
                        (param.required !== false ? "" : " (optional)")
                      }
                      placeholder={param.placeholder}
                      name={param.slug}
                      required={param.required !== false}
                      mt="xs"
                      minRows={5}
                      {...form.getInputProps(param.slug)}
                    />
                  );
                } else if (param.type === "select") {
                  return (
                    <Select
                      key={param.slug}
                      label={
                        param.label +
                        (param.required !== false ? "" : " (optional)")
                      }
                      placeholder={param.placeholder}
                      name={param.slug}
                      required={param.required !== false}
                      mt="xs"
                      {...form.getInputProps(param.slug)}
                      data={param.options.map((option) => ({
                        label: option.label,
                        value: option.value,
                      }))}
                    />
                  );
                } else {
                  return (
                    <TextInput
                      key={param.slug}
                      label={
                        param.label +
                        (param.required !== false ? "" : " (optional)")
                      }
                      placeholder={param.placeholder}
                      name={param.slug}
                      required={param.required !== false}
                      mt="xs"
                      {...form.getInputProps(param.slug)}
                    />
                  );
                }
              })}

              <Divider mt="md" mb="md" />

              <Select
                label="Tone of voice"
                value={form.values.toneOfVoice}
                onChange={(value) => {
                  form.setFieldValue("toneOfVoice", value);
                }}
                data={[
                  { label: "Friendly", value: "friendly" },
                  { label: "Professional", value: "professional" },
                  { label: "Informal", value: "informal" },
                  { label: "Casual", value: "casual" },
                  { label: "Fun", value: "fun" },
                  { label: "Sarcastic", value: "sarcastic" },
                  { label: "Humorous", value: "humorous" },
                  { label: "Formal", value: "formal" },
                ]}
                mb="md"
              />

              {prompt.canGenerateMultiple !== false && (
                <NumberInput
                  label="How many outputs?"
                  name="outputsAmount"
                  required
                  mb="md"
                  {...form.getInputProps("outputsAmount")}
                />
              )}

              <Switch
                label="Use emoji"
                checked={form.values.useEmoji}
                onChange={(event) => {
                  form.setFieldValue("useEmoji", event.currentTarget.checked);
                }}
                mb="md"
              />

              <Button
                type="submit"
                color="blue"
                variant="outline"
                fullWidth
                mt="xs"
              >
                Generate
              </Button>
            </form>
          </Navbar>
        }
        header={<></>}
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        {generating ? (
          <Container size="md" p="xl">
            <Center>
              <AiLoader />
            </Center>
          </Container>
        ) : copy ? (
          copy.map((copy, i) => (
            <Container size="md" p="xl" key={i}>
              <Card shadow="sm" p="md" radius="md">
                <Group position="apart" mt="md" mb="xs">
                  <Text size="xl" weight="bold">
                    Generated copy #{i + 1}
                  </Text>
                  <Group>
                    <ThemeIcon
                      color="red"
                      style={{
                        cursor: "pointer",
                      }}
                      variant="outline"
                      onClick={() => {
                        if (saved.includes(i)) {
                          LocalStorage.set(
                            "copy",
                            (LocalStorage.get("copy") || []).filter(
                              (c) => c !== copy
                            )
                          );
                        } else {
                          LocalStorage.set(
                            "copy",
                            (LocalStorage.get("copy") || []).concat(copy)
                          );

                          showNotification({
                            title: "Saved",
                            message: "The generated copy has been saved.",
                            color: "green",
                          });
                        }

                        if (saved.includes(i)) {
                          setSaved(saved.filter((s) => s !== i));
                        } else {
                          setSaved(saved.concat(i));
                        }
                      }}
                    >
                      <Heart
                        size={16}
                        fill={saved.includes(i) ? "red" : "none"}
                      />
                    </ThemeIcon>
                    <ThemeIcon
                      color="blue"
                      style={{
                        cursor: "pointer",
                      }}
                      variant="outline"
                      onClick={() => {
                        navigator.clipboard.writeText(copy);

                        showNotification({
                          title: "Copied to clipboard",
                          message:
                            "The generated copy has been copied to your clipboard.",
                          color: "green",
                        });
                      }}
                    >
                      <Copy size={16} />
                    </ThemeIcon>
                  </Group>
                </Group>

                <Divider mt="md" mb="md" />

                <Text
                  size="xl"
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {copy}
                </Text>
              </Card>
            </Container>
          ))
        ) : (
          <Center>
            <Container size="md" p="xl">
              <Text>
                The copy will appear here once you&apos;ve generated it.
              </Text>
            </Container>
          </Center>
        )}
      </AppShell>
    </main>
  );
};

export default CopyDetail;
