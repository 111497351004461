import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { Alert } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FormReponses from "../../utils/FormResponses";
const CurrentPlan = ({ auth, currentSubscription }) => {
  const [popup, showPopup] = useState(false);
  const nav = useNavigate();
  return (
    <React.Fragment>
      <div className="formify-card  var-width m-4">
        <div className="formify-card-header">
          <div className="billing-header  mb-2 ">
            <div className="text">
              <h2 className="mb-0">Formify</h2>
              <span
                className={`current-billing-header ${
                  auth
                    ? (auth.user.transactions[0].account_type?.title)
                    // .toLowerCase()).replace(/ /g,"-")
                    : null
                }`}
              >
                {auth ? auth.user.transactions[0].account_type?.title : ""}
              </span>
            </div>
            <div
              onClick={() => {
                showPopup(!popup);
              }}
              className="card-options p-0 mb-2"
            >
              <MoreHorizIcon />
              <div
                className={popup ? "exportPopup  open mb-2" : "exportPopup"}
                style={{ top: "25px" }}
              >
                <p
                  className="formify-text"
                  onClick={() => {
                    nav("/profile/billing/plans");
                  }}
                >
                  Upgrade Plan
                </p>
                <p
                  onClick={() => {
                    nav("/profile/billing/plans", { state: { downgrade: true } });
                  }}
                >
                  Downgrade
                </p>
                {auth?.user?.transactions[0] ? (
                 ( auth?.user?.transactions[0].canceledAt && !auth.user.transactions[0]?.status === "active")
                  || auth?.user?.transactions[0].is_trial
                     ? null : (
                    <p
                      className="danger-text"
                      onClick={() => {
                        nav("/profile/billing/cancel");
                      }}
                    >
                      Cancel Subscription
                    </p>
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="plan-features">
            <p>Discover the possibilities of customizable multi-step forms.</p>
            <Link to="/profile/billing/plans/">
              <span>See all features</span>
            </Link>
          </div>
        </div>
        <FormReponses />
        <div className="option-btns d-flex flex-row align-items-center justify-content-between">
          {/* <div className="upgradePlan">
            <Link to="/profile/billing/plans">Upgrade Plan</Link>
          </div> */}
          {auth?.user?.transactions[0] ? (
           ( auth?.user?.transactions[0].canceledAt && !auth.user.transactions[0]?.status === "active") || auth?.user?.transactions[0].is_trial ? null : (
              <div
                className="upgradePlan"
                style={{ backgroundColor: "#fc5b3f" }}
              >
                <Link to="/profile/billing/cancel">Cancel Plan</Link>
              </div>
            )
          ) : null}
        </div>
        <div className="mt-2 mb-2">
          {auth?.user?.transactions[0] ? (
            auth?.user?.transactions[0].canceledAt && auth.user.transactions[0]?.status === "active" ? (
              <Alert severity="warning">
                Subscription is canceled. Your plan is set to expire at{" "}
                {new Date(
                  auth?.user?.transactions[0].date_to 
                ).toLocaleString()}
              </Alert>
            ) : null
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapAuthErrorsStateToProps)(CurrentPlan);