import { 
  SET_FORM, 
  SET_FORM_LOADING, 
  SET_WIDGET,
  SET_WIDGET_STEP_POSITION,
  SET_FORM_STATES,
  RESET_FORM_STATE,
  SET_RESPONSES,
  SET_RESPONSES_ERROR_MESSAGE,
  SET_RESPONSIVE,
  SET_ACTIVE_FORM_STEP,
  FORM_UNDO,
  FORM_REDO,
  SET_CLIENT_EMAIL
} from "../actions/types";
import produce, { applyPatches, enablePatches } from 'immer';
enablePatches();
let initialState = {
  form: {},
  widget: {},
  widgetStep: null,
  widgetPosition: null,
  updatedForm: false,
  isFormLoading: false,
  formStates: {
    sideBar: true,
    state: "openWidgets"
  },
  responses: {},
  responseError: {},
  activeFormStep: 1,
  responsive: 'desktop',
  clientEmail: {
    value: '',
    id: ''
  }
};

let changes = {};
let firstTime = true;
let currentVersion = -1;
const noOfVersionsSupported = 30;
const undoableActions = [SET_FORM];

const formReducer = (state = initialState, action) => 
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FORM:
        draft.canRedo = false;
        draft.canUndo = state.form._id ? true : false; 
        draft.form = action.payload.form;
        if(action.payload.updated === "reset"){
          draft.updatedForm = false;
          draft.canUndo = false;
          currentVersion = -1;
          firstTime = true;
          changes = {};
        } else {
          draft.updatedForm = state.updatedForm ? state.updatedForm : action.payload.updated
        }
        break;
      case SET_WIDGET:
        draft.widget = action.payload;
        break;
      case SET_WIDGET_STEP_POSITION:
        draft.widgetStep = action.payload.step;
        draft.widgetPosition = action.payload.position;
        break;
      case SET_FORM_LOADING:
        draft.isFormLoading = action.payload;
        break;
      case SET_FORM_STATES:
        draft.formStates = {
          sideBar: action.payload.sideBar,
          state: action.payload.state,
        }
        break;
      case SET_CLIENT_EMAIL:
        draft.clientEmail.value = action.mail;
        draft.clientEmail.id = action.id;
        break;
      case RESET_FORM_STATE:
        draft.form = {};
        draft.widget = {}
        draft.widgetStep = 0;
        draft.widgetPosition = 0;
        draft.updatedForm = false;
        draft.isFormLoading = false;
        draft.formStates = {
          sideBar: true,
          state: "openWidgets"
        };
        break;
      case SET_RESPONSES:
          draft.responses = action.payload;
          break;
      case SET_RESPONSES_ERROR_MESSAGE: 
          draft.responseError = action.payload;
          break;
      case SET_RESPONSIVE: 
          draft.responsive = action.payload;
          break;
      case SET_ACTIVE_FORM_STEP:
        draft.activeFormStep = action.step;
        break;
      case FORM_UNDO:
        const changeUndo = changes[currentVersion--];
        const newState = applyPatches(state, changeUndo.undo);
        return produce(
          newState,
          newDraft => {
            newDraft.canRedo = true;
            newDraft.canUndo = changes.hasOwnProperty(currentVersion);
            if(changeUndo.widgetStep !== null && changeUndo.widgetPosition !== null){
              newDraft.widgetStep = changeUndo.widgetStep
              newDraft.widgetPosition = changeUndo.widgetPosition
              newDraft.widget = newState.form.steps[changeUndo.widgetStep].elements[changeUndo.widgetPosition]
            }
          }
        );
      case FORM_REDO:
        const changeRedo = changes[++currentVersion];
        const newStateRedo = applyPatches(state, changeRedo.redo);
        return produce(
          newStateRedo,
          newDraft => {
            newDraft.canUndo = true;
            newDraft.canRedo = changes.hasOwnProperty(currentVersion + 1);
            if(changeRedo.widgetStep !== null && changeRedo.widgetPosition !== null){
              newDraft.widgetStep = changeRedo.widgetStep
              newDraft.widgetPosition = changeRedo.widgetPosition
              newDraft.widget = newStateRedo.form.steps[changeRedo.widgetStep].elements[changeRedo.widgetPosition]
            }
          }
        );
        default:
          return state;
    }
  },
    (patches, inversePatches) => {
      if (undoableActions.indexOf(action.type) !== -1) {
        if(action.payload?.inputType !== "ignore") {
          if(firstTime) {
            firstTime = false;
            return;
          }
          if(action.payload?.inputType === "blur"){
            const formIndex = changes[currentVersion].redo.findIndex(item => item.path.includes("form"));
            changes[currentVersion]["redo"][formIndex] = patches[0]
          } else {
            currentVersion++;
            changes[currentVersion] = {
              redo: patches,
              undo: inversePatches,
              widgetStep: state.widgetStep,
              widgetPosition: state.widgetPosition
            }
          }
          delete changes[currentVersion + 1];
          delete changes[currentVersion - noOfVersionsSupported];
        }
      }
    }
  );

export default formReducer;