import { Check } from "react-feather";
import auth from "../../../reducers/auth";

const Plan = ({
  plan,
  acc,
  currentSubscription,
  redirectPaymentMethod,
  onCheckout,
  selectNewPlan,
}) => {
  return (
    <div
      className={
        onCheckout
          ? "col-lg-12 price-container-list  plan mt-4 p-0"
          : "price-container-list col-lg-4 plan"
      }
    >
      <div className="container-fluid top-margin">
        <div className="row">
          <p className="ribbon">{plan.product.name}</p>
          <div className="col-lg-12 plan-body-wraper">
            <div className="plan-body">
              <div>
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="price-info ">
                      <h4 className="text-center">
                        <span className="currency">$</span>
                        {plan.recurring.interval === "month"
                          ? plan.unit_amount / 100
                          : Number.isInteger(plan.unit_amount / 1200)
                          ? plan.unit_amount / 1200
                          : (plan.unit_amount / 1200).toFixed(2)}
                        <span>/mo</span>
                      </h4>
                    </div>
                    <div className="price-details text-center">
                      <p>
                        {plan.recurring.interval === "month"
                          ? "Per month"
                          : "Per month, paid yearly (USD)"}
                      </p>
                      <span>(no transaction fees)</span>
                    </div>
                  </div>
                  <hr className="price-hr mt-2" />
                  {acc ? (
                    <div className="col-lg-12">
                      <div className="plan-features mt-2">
                        {acc.features.map((feature) => {
                          return (
                            <div className="feature" key={Math.random()}>
                              <Check
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  color: "#6563FF",
                                }}
                              />
                              <p>{feature}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 plan-footer p-0 mt-2">
            {onCheckout ? null : (
              <button
                className={
                  auth?.user?.transactions[0]
                    ? auth?.user?.transactions[0].account_type === plan.id
                      ? `current-plan-btn`
                      : `plan-btn`
                    : `plan-btn`
                }
                onClick={() => {
                  if (auth?.user?.transactions[0]) {
                    if (
                      auth?.user?.transactions[0].account_type !== plan.id
                    ) {
                      selectNewPlan(plan);
                    }
                  } else {
                    redirectPaymentMethod(plan);
                  }
                }}
              >
                {auth?.user?.transactions[0]
                  ? auth?.user?.transactions[0].account_type === plan.id
                    ? "Current"
                    : `Get ${plan.product.name} ${plan.recurring.interval}ly`
                  : `Get ${plan.product.name} ${plan.recurring.interval}ly`}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
