/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { tokenConfig } from "../../../actions/auth";
import {
  BASE_DEV_URL,
  formGetCrmFields,
  integrationFooter,
  mapAuthAndFormStateToProps,
} from "../../../utils";
import store from "../../../store";
import { ArrowRight, Loader, Plus, X } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { disconnectIntegration } from "../../utils/formFunctions";
import { setFormDispatcher } from "../../../actions/form";
import { handleSetForm } from "../../utils/handleDispatcher";
import { integrationAccountValidation } from "../../utils/formBackendFunctions";
import Select from "react-select";
import { CircularProgress } from "@mui/material";

const Klaviyo = ({
  auth,
  form: { form },
  setFormDispatcher,
  disconnectIntegration,
}) => {
  const nav = useNavigate();
  const params = useParams();
  const [lists, setLists] = useState([]);
  const [nextCursor, setNextCursor] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setForm] = useState({
    fields: [],
    list: form?.integrations?.klaviyo?.list
      ? form.integrations.klaviyo.list
      : null,
    listname: form?.integrations?.klaviyo?.listname
      ? form.integrations.klaviyo.listname
      : "",
    elements: [],
    show: false,
  });


  const klaviyoIsAuth = useMemo(() => {
    return auth.user
      ? auth.user.crm
        ? auth.user.crm.klaviyo
          ? true
          : false
        : false
      : false;
  }, [auth]);

  const formHasK = useMemo(() => {
    return form
      ? form.integrations
        ? form.integrations.klaviyo
          ? true
          : false
        : false
      : false;
  }, [form]);
  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/crm/klaviyo/lists`, tokenConfig(store.getState))
      .then((res) => {
        setLists(res.data.data);
        // console.log("redata",res.data.pagination.next)
        const nextCursorValue = res.data.pagination.next
          ? res.data.pagination.next
          : "";

        // Split the URL by the '=' character
        const parts = nextCursorValue.split('=');

        // Get the value after the '=' sign
        const cursorValue = parts[1];

        // console.log(cursorValue);
        setNextCursor(cursorValue);

        if (res.data.data.length > 0) {
          const defaultList = res.data.data[0];
          setForm((prevState) => ({
            ...prevState,
            list: form?.integrations?.klaviyo?.list
              ? form.integrations.klaviyo.list
              : defaultList.list_id,
            listname: form?.integrations?.klaviyo?.listname
              ? form.integrations.klaviyo.listname
              : defaultList.list_name,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchLists = (cursor = "") => {
    // console.log("cursor",cursor)
    setIsLoading(true);
    const url = `${BASE_DEV_URL}/crm/klaviyo/lists${cursor ? `?next=${nextCursor}` : ""
      }`;
    axios
      .get(url, tokenConfig(store.getState))
      .then((res) => {
        setLists((prevLists) => [...prevLists, ...res.data.data]);
        // console.log("redata",res.data.pagination.next)
        const nextCursorValue = res.data.pagination.next
          ? res.data.pagination.next
          : "";

        // Split the URL by the '=' character
        const parts = nextCursorValue.split('=');

        // Get the value after the '=' sign
        const cursorValue = parts[1];

        // console.log(cursorValue);
        setNextCursor(cursorValue);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMoreLists = () => {
    // console.log("Loading more lists...");
    // console.log("next cursor:", nextCursor);
    fetchLists(nextCursor);
  };



  useEffect(() => {
    if (
      form &&
      form.steps &&
      form.steps.some((step) => {
        return step.elements;
      })
    ) {
      let elements = formGetCrmFields(form);
      if (formHasK) {

        setForm({
          ...formData,
          fields: form.integrations.klaviyo.fields,
          list: form.integrations.klaviyo.list,
          listname: form.integrations.klaviyo.listname,
          elements,
        });
      } else {
        setForm({
          ...formData,
          elements,
          fields: [
            {
              integration_field: "email",
              formify_field: elements.length > 0 ? elements[0]._id : null,
            },
          ],
        });
      }
    }
  }, [formHasK, form]);

  const handleChange = (e) => {
    const selectedList = lists.find(list => list.list_id === e.target.value);
    setForm({
      ...formData,
      list: selectedList.list_id,
      listname: selectedList.list_name,
    });
  };

  const removeRow = (index) => {
    const fields = Array.from(formData.fields);
    fields.splice(index, 1);

    setForm((prevState) => ({
      ...prevState,
      fields: fields,
    }));
  };

  const handleIntegrationChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], integration_field: e.target.value };
    setForm((prevState) => ({
      ...prevState,
      fields,
    }));
  };

  const handleFormifyChange = (e, index) => {
    const fields = Array.from(formData.fields);
    fields[index] = { ...fields[index], formify_field: e.target.value };

    setForm((prevState) => ({
      ...prevState,
      fields,
    }));
  };

  const authenticateBody = () => {
    return (
      <div className="col-12 integration-body mt-4">
        {klaviyoIsAuth ? (
          formHasK ? (
            <>
              <h4 className="formify-h4">Integration is up and running!</h4>
              <p className="formify-sm-p">
                You have successfully created your integration.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {

                  setForm((prevState) => ({
                    ...prevState,
                    show: true
                  }));
                }}
              >
                Edit Configuration
              </button>
            </>
          ) : (
            <>
              <h4 className="formify-h4">Klaviyo Campaign Configuration</h4>
              <p className="formify-sm-p">
                Give Klaviyo access to your form in order to import products to
                Formify.
              </p>
              <button
                className="formify-btn-no-margin mt-3"
                onClick={() => {
                  setForm((prevState) => ({
                    ...prevState,
                    show: true
                  }));
                  // const updatedForm = handleSetForm([`integrations.klaviyo`], [{ list: lists[0].list_id, fields: [] }], form);
                  // setFormDispatcher(updatedForm, true);
                }}
              >
                Configure Klaviyo
              </button>
            </>
          )
        ) : (
          <>
            <h4 className="formify-h4">Klaviyo Authenticaiton</h4>
            <p className="formify-sm-p">
              Give Klaviyo access to your form in order to import products to
              Formify.
            </p>
            <button
              onClick={() => {
                nav("/integrations/klaviyo");
              }}
              className="formify-btn-no-margin mt-3"
            >
              Authenticate
            </button>
          </>
        )}
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!integrationAccountValidation(auth, "Klaviyo")) return;

    const updatedForm = handleSetForm(
      [`integrations.klaviyo`],
      [{ list: formData.list, listname: formData.listname, fields: formData.fields, }],
      form
    );

    setFormDispatcher(updatedForm, true);
    toast.success("Fields are created");
  };
  // console.log("formdata",formData)
  const klaviyoForm = () => {
    return (
      <div className="col-8 integration-form">
        <form onSubmit={handleSubmit}>
          <hr className="grey-hr" />
          <div className="input-holder">
            <label htmlFor="sobject">Choose a list</label>
            <p className="formify-sm-p">
              Select the list you want to send responses to
            </p>
            {/* <select
              value={formData.list }
              id="list"
              onChange={handleChange}
            >
              {lists.length > 0
                ? lists.map((list) => {
                    return (
                      <option value={list.list_id} key={list.list_id}>
                        {list.list_name}
                      </option>
                    );
                  })
                : null}
            </select> */}
            <Select
              isMulti={false}
              options={lists.map((list) => ({ value: list.list_id, label: list.list_name }))}
              value={formData.list ? { value: formData.list, label: formData.listname } : null}
              onChange={(selectedOption) => {
                setForm({
                  ...formData,
                  list: selectedOption.value,
                  listname: selectedOption.label,
                });
              }}
              onMenuScrollToBottom={loadMoreLists}
              isLoading={isLoading}
              components={{
                LoadingIndicator: () => (
                  <CircularProgress
                    size={18}
                    style={{ alignSelf: "center" }}
                  />
                ),
              }}
            />


          </div>

          <div className="input-holder">
            <label>Match Fields</label>
            <p className="formify-sm-p">
              Connect the fields on your form with the fields of Klaviyo Contact
            </p>
            {formData.fields.map((field, index) => {
              return (
                <div className="form-input">
                  <div className="me-3">
                    <label className="formify-sm-p">Klaviyo Field</label>
                    <input
                      type="text"
                      key={index}
                      value={field.integration_field}
                      onChange={(e) => {
                        handleIntegrationChange(e, index);
                      }}
                    />
                  </div>
                  <div className="me-2">
                    <label className="formify-sm-p">Formify Field</label>
                    <select
                      id="email"
                      value={field.formify_field}
                      onChange={(e) => {
                        handleFormifyChange(e, index);
                      }}
                      className="integration-select"
                    >
                      {formData.elements.map((element) => {
                        return (
                          <option value={element._id} key={element._id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div
                    className="remove-row"
                    onClick={() => {
                      removeRow(index);
                    }}
                  >
                    <X style={{ color: "#ea4335" }} />
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="add-new-field d-flex mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => {

              setForm((prevState) => ({
                ...prevState,
                fields: [
                  ...formData.fields,
                  {
                    integration_field: "email",
                    formify_field:
                      formData.elements.length > 0
                        ? formData.elements[0]._id
                        : null,
                  },
                ],
              }));

            }}
          >
            <Plus className="formify-color" />
            <p className="formify-p formify-color">Add new field</p>
          </div>
          <div className="d-flex flex-row ">
            <button className="formify-btn me-2">Save</button>
            <button
              className="invert-formify-btn"
              onClick={() => {
                setForm((prevState) => ({
                  ...prevState,
                  show: false
                }));
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid integraiton mt-5">
      <div className="row justify-content-center">
        <div className="col-12  integraiton-card ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="integration-header">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/formify.png"}
                    alt="Formify Logo"
                    className="img-fluid me-2"
                  />
                  <ArrowRight
                    className="me-2"
                    style={{ color: "#6563FF", width: "20px", height: "20px" }}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/klaviyo.png"}
                    alt="Mailchimp"
                    className="img-fluid"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                {formData.show ? klaviyoForm() : authenticateBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {integrationFooter(formHasK, disconnectIntegration, "klaviyo", params)}
      </div>


    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setFormDispatcher,
  disconnectIntegration,
})(Klaviyo);
