import { Dropdown, DropdownButton } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Copy, Plus, Trash } from "react-feather";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { mapAuthAndFormStateToProps } from "../../utils";
import {
  createStep,
  moveStep,
  duplicateStep,
  deleteStep,
} from "../utils/formFunctions";
import FormifyButton from "../utils/FromifyButton";

const FormSteps = ({
  steps,
  formId,
  style,
  viewMode = null,
  nextPageHandler = null,
  openWidgetSidebar,
  createStep,
  moveStep,
  duplicateStep,
  setErrorCheckbox,
  deleteStep,
  currentstep,
  form: { form, activeFormStep,responses },
}) => {

 


  const params = useParams();
  const nav = useNavigate();
  const css = style.steps
    ? style.steps.onHover
      ? `
  .form-steps .step >button{
    ${
      style.steps?.default?.fontFamily?.name
        ? ` font-family:  ${style.steps?.default?.fontFamily?.name} !important`
        : ""
    }; 
  }
  .form-steps .step >button:hover{
    ${
      style.steps.onHover.color
        ? ` color:  ${style.steps.onHover.color} !important`
        : style.steps.default.color
    };
 
   font-size:  ${style.font_size}; 
    ${
      style.steps.onHover.backgroundColor
        ? ` background-color:  ${style.steps.onHover.backgroundColor} !important`
        : ""
    }; 

    ${
      style.steps.onHover.borderStyle
        ? ` border-style:  ${style.steps.onHover.borderStyle} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderColor
        ? ` border-color:  ${style.steps.onHover.borderColor} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderLeftWidth
        ? ` border-left-width:  ${style.steps.onHover.borderLeftWidth} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderRightWidth
        ? ` border-right-width:  ${style.steps.onHover.borderRightWidth} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderTopWidth
        ? ` border-top-width:  ${style.steps.onHover.borderTopWidth} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderBottomWidth
        ? ` border-bottom-width:  ${style.steps.onHover.borderBottomWidth} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderTopLeftRadius
        ? ` border-top-left-radius:  ${style.steps.onHover.borderTopLeftRadius} !important`
        : ""
    };  
    ${
      style.steps.onHover.borderBottomRightRadius
        ? ` border-bottom-right-radius:  ${style.steps.onHover.borderBottomRightRadius} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderTopRightRadius
        ? ` border-top-right-radius:  ${style.steps.onHover.borderTopRightRadius} !important`
        : ""
    }; 
    ${
      style.steps.onHover.borderBottomLeftRadius
        ? ` border-bottom-left-radius:  ${style.steps.onHover.borderBottomLeftRadius} !important`
        : ""
    }; 
    ${
      style.steps.onHover.marginLeft
        ? ` margin-left:  ${style.steps.onHover.marginLeft} !important`
        : ""
    }; 
    ${
      style.steps.onHover.marginRight
        ? ` margin-right:  ${style.steps.onHover.marginRight} !important`
        : ""
    };
 
    ${
      style.steps.onHover.marginTop
        ? ` margin-top:  ${style.steps.onHover.marginTop} !important`
        : ""
    }; 
    ${
      style.steps.onHover.marginBottom
        ? ` margin-bottom:  ${style.steps.onHover.marginBottom} !important`
        : ""
    }; 
    ${
      style.steps.onHover.paddingLeft
        ? ` padding-left:  ${style.steps.onHover.paddingLeft} !important`
        : ""
    }; 
    ${
      style.steps.onHover.paddingTop
        ? ` padding-top:  ${style.steps.onHover.paddingTop} !important`
        : ""
    }; 
    ${
      style.steps.onHover.paddingBottom
        ? ` padding-bottom:  ${style.steps.onHover.paddingBottom} !important`
        : ""
    }; 
    ${
      style.steps.onHover.paddingRight
        ? ` padding-right:  ${style.steps.onHover.paddingRight} !important`
        : ""
    }; 
    
  }
  `:
  style.steps.onActive
  ? `
.form-steps .step >button{
${
  style.steps?.default?.fontFamily?.name
    ? ` font-family:  ${style.steps?.default?.fontFamily?.name} !important`
    : ""
}; 
}
.form-steps .step >button:hover{
${
  style.steps.onActive.color
    ? ` color:  ${style.steps.onActive.color} !important`
    : style.steps.default.color
};

font-size:  ${style.font_size}; 
${
  style.steps.onActive.backgroundColor
    ? ` background-color:  ${style.steps.onActive.backgroundColor} !important`
    : ""
}; 

${
  style.steps.onActive.borderStyle
    ? ` border-style:  ${style.steps.onActive.borderStyle} !important`
    : ""
}; 
${
  style.steps.onActive.borderColor
    ? ` border-color:  ${style.steps.onActive.borderColor} !important`
    : ""
}; 
${
  style.steps.onActive.borderLeftWidth
    ? ` border-left-width:  ${style.steps.onActive.borderLeftWidth} !important`
    : ""
}; 
${
  style.steps.onActive.borderRightWidth
    ? ` border-right-width:  ${style.steps.onActive.borderRightWidth} !important`
    : ""
}; 
${
  style.steps.onActive.borderTopWidth
    ? ` border-top-width:  ${style.steps.onActive.borderTopWidth} !important`
    : ""
}; 
${
  style.steps.onActive.borderBottomWidth
    ? ` border-bottom-width:  ${style.steps.onActive.borderBottomWidth} !important`
    : ""
}; 
${
  style.steps.onActive.borderTopLeftRadius
    ? ` border-top-left-radius:  ${style.steps.onActive.borderTopLeftRadius} !important`
    : ""
};  
${
  style.steps.onActive.borderBottomRightRadius
    ? ` border-bottom-right-radius:  ${style.steps.onActive.borderBottomRightRadius} !important`
    : ""
}; 
${
  style.steps.onActive.borderTopRightRadius
    ? ` border-top-right-radius:  ${style.steps.onActive.borderTopRightRadius} !important`
    : ""
}; 
${
  style.steps.onActive.borderBottomLeftRadius
    ? ` border-bottom-left-radius:  ${style.steps.onActive.borderBottomLeftRadius} !important`
    : ""
}; 
${
  style.steps.onActive.marginLeft
    ? ` margin-left:  ${style.steps.onActive.marginLeft} !important`
    : ""
}; 
${
  style.steps.onActive.marginRight
    ? ` margin-right:  ${style.steps.onActive.marginRight} !important`
    : ""
};

${
  style.steps.onActive.marginTop
    ? ` margin-top:  ${style.steps.onActive.marginTop} !important`
    : ""
}; 
${
  style.steps.onActive.marginBottom
    ? ` margin-bottom:  ${style.steps.onActive.marginBottom} !important`
    : ""
}; 
${
  style.steps.onActive.paddingLeft
    ? ` padding-left:  ${style.steps.onActive.paddingLeft} !important`
    : ""
}; 
${
  style.steps.onActive.paddingTop
    ? ` padding-top:  ${style.steps.onActive.paddingTop} !important`
    : ""
}; 
${
  style.steps.onActive.paddingBottom
    ? ` padding-bottom:  ${style.steps.onActive.paddingBottom} !important`
    : ""
}; 
${
  style.steps.onActive.paddingRight
    ? ` padding-right:  ${style.steps.onActive.paddingRight} !important`
    : ""
}; 

}
`

      : ""
    : "";

  
    
  return viewMode ? (
    <div className="form-steps">
      <style> {css}</style>
      {steps
        ? steps.map((step, index) => {

            return (
              <>
                <div className="step">
                  <button
                    className={
                      index === activeFormStep - 1
                        ? "formify-btn-no-margin hoverable current-step"
                        : "formify-btn-no-margin hoverable"
                    }
                    style={style.steps ? (index === currentstep - 1  ? style.steps.onActive : style.steps.default ) : "" }
                    onClick={() => {
                      const currentStep = form.steps[activeFormStep - 1];
                      const formProductChecked = currentStep.elements.some(
                        (element) => element.element_type === 'form_product' && responses[element._id]?.value
                      );
                    
                      const filteredElements = currentStep.elements.filter(
                        (element) => element.element_type === 'form_product'
                      );
                    
                      if (filteredElements.length > 0 && !formProductChecked) {
                        setErrorCheckbox(true);
                      }else{
                        nextPageHandler(step.order_number);
                      }
                    }}
                  >
                    {step.order_number}
                  </button>
                </div>
                {index === steps.length - 1 ? null : (
                  <hr
                    className="normal-line"
                    style={
                      style.steps.default.lineColor
                        ? { backgroundColor: style.steps.default.lineColor }
                        : null
                    }
                  />
                )}
              </>
            );
          })
        : null}
    </div>
  ) : (
    <div className="form-steps">
      <style> {css}</style>
      {steps
        ? steps.map((step, index) => {
            return (
              <>
              
                <Link
                  to={`/form/${formId}/step/${step.order_number}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="step" onClick={() => openWidgetSidebar()}>
                    <FormifyButton
                      classes={
                        index === currentstep - 1
                          ? "formify-btn-no-margin hoverable current-step"
                          : "formify-btn-no-margin hoverable"
                      }

                      style={style.steps ? (index === currentstep - 1 ? style.steps.onActive : style.steps.default ) : "" }
                      title={step.order_number}
                      loaderWhite={true}
                    />
                    <DropdownButton
                      id={`dropdown-button-drop-down`}
                      drop={"down"}
                      title={
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/vertical-ellipses.svg"
                          }
                        />
                      }
                    >
                      {index != 0 ? (
                        <Dropdown.Item
                          eventKey="3"
                          onClick={() => {
                            moveStep(
                              step.order_number,
                              "left",
                              params,
                              nav,
                              form
                            );
                            openWidgetSidebar();
                          }}
                        >
                          {" "}
                          Move Step Left{" "}
                          <ChevronLeft
                            style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "3px",
                              color: "#6563ff",
                              backgroundColor: "white",
                              borderRadius: "7px",
                              padding: "2px",
                            }}
                          />{" "}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}

                      {index != steps.length - 1 ? (
                        <Dropdown.Item
                          eventKey="4"
                          onClick={() => {
                            moveStep(
                              step.order_number,
                              "right",
                              params,
                              nav,
                              form
                            );
                            openWidgetSidebar();
                          }}
                        >
                          {" "}
                          Move Step Right{" "}
                          <ChevronRight
                            style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "3px",
                              color: "#6563ff",
                              borderRadius: "7px",
                              padding: "2px",
                            }}
                          />
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {steps.length != 1 ? <Dropdown.Divider /> : ""}
                      
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => {
                          duplicateStep(
                            steps.length + 1,
                            step,
                            params,
                            nav,
                            form
                          );
                          openWidgetSidebar();
                        }}
                      >
                        Duplicate Step{" "}
                        <Copy
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "3px",
                            color: "#6563ff",
                            borderRadius: "7px",
                            padding: "2px",
                          }}
                        />
                      </Dropdown.Item>

                      <Dropdown.Item
                        style={{ color: "#ea4335" }}
                        eventKey="1"
                        onClick={() => {
                          deleteStep(step.order_number, params, nav, form);
                          openWidgetSidebar();
                        }}
                      >
                        Delete Step{" "}
                        <Trash
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "3px",
                            color: "#ea4335",
                            borderRadius: "7px",
                            padding: "2px",
                          }}
                        />
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Link>
                {index === steps.length ? (
                  <hr
                    className="dashed-line"
                    style={
                      style.steps.default.lineColor
                        ? { backgroundColor: style.steps.default.lineColor }
                        : null
                    }
                  />
                ) : (
                  <hr
                    className="normal-line"
                    style={
                      style.steps.default.lineColor
                        ? { backgroundColor: style.steps.default.lineColor }
                        : null
                    }
                  />
                )}
              </>
            );
          })
        : null}

      <div
        className="step"
        onClick={() => {
          createStep(steps.length + 1, params, nav, form);
          openWidgetSidebar();
        }}
      >
        <FormifyButton
          classes="formify-btn-no-margin hoverable next-step"
          icon={<Plus style={{ marginBottom: "5px" }} />}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  createStep,
  duplicateStep,
  deleteStep,
  moveStep,
})(FormSteps);