import { updateForm } from "../../../utils/formFunctions";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setResponsiveDispatcher } from '../../../../actions/form';

const ResponsiveTabs = ({ 
  deviceFrame, 
  setDeviceFrame, 
  setResponsiveDispatcher,
  form: { responsive }, 
}) => {

  return (
    <div className="change-responsivess">
      <div className="responsiveness-icon ">
        <img
          onClick={() => setResponsiveDispatcher("desktop") }
          src={process.env.PUBLIC_URL + "/assets/images/icons/Desktop.svg"}
          alt="Formify"
          className={`img-fluid responsiveness-icon ${(responsive === 'desktop') && 'active-responsive'}`}
        />
        <img
          onClick={() => setResponsiveDispatcher("tablet")}
          src={process.env.PUBLIC_URL + "/assets/images/icons/Tablet.svg"}
          alt="Formify"
          className={`img-fluid responsiveness-icon ${responsive === 'tablet' && 'active-responsive'}`}
        />
        <img
          onClick={() => setResponsiveDispatcher("mobile")}
          src={process.env.PUBLIC_URL + "/assets/images/icons/Mobile.svg"}
          alt="Formify"
          className={`img-fluid responsiveness-icon ${responsive === 'mobile' && 'active-responsive'}`}
        />
      </div>
      <p className="widget-editing-button" onClick={(e)=>setDeviceFrame(!deviceFrame)}>{deviceFrame ? "Remove Device Frame" : "Show Device Frame"}</p>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { updateForm, setResponsiveDispatcher })(ResponsiveTabs);
