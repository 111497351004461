import { createSlice } from '@reduxjs/toolkit';

const rechargeProdSlice = createSlice({
  name: 'rechargeProd',
  initialState: false,
  reducers: {
    setRechargeProd: (state, action) => {
      return action.payload; 
    },
  },
});

export const { setRechargeProd } = rechargeProdSlice.actions;

export default rechargeProdSlice.reducer;
