import React from "react";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import ColorPickerStyle from "./ColorPickerStyle";
import Spacer from "./Spacer";

const BorderOptions = ({
  attribute,
  onChange,
  onChangeColor,
  updateSelectOptions,
  setBorderWidthLockValues,
  setBorderRadiusLockValues,
  borderWidthLockValues,
  borderRadiusLockValues,
}) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="field-label">Border</Accordion.Header>
        <Accordion.Body>
          <div className="editable-field form-row  ">
            <p> Border Style</p>
            <div className="widget-range-slider-container">
              <DropdownButton
                title={attribute?.borderStyle ? attribute?.borderStyle : "None"}
                id={"borderStyle"}
                name={"borderStyle"}
                onSelect={(e) => updateSelectOptions(e, { id: "borderStyle" })}
                className="form-dropdown-select"
              >
                <Dropdown.Item eventKey="solid">Solid</Dropdown.Item>
                <Dropdown.Item eventKey="dashed">Dashed</Dropdown.Item>
                <Dropdown.Item eventKey="double">Double</Dropdown.Item>
                <Dropdown.Item eventKey="dotted">Dotted</Dropdown.Item>
                <Dropdown.Item eventKey="ridge">Ridge</Dropdown.Item>
                <Dropdown.Item eventKey="groove">Groove</Dropdown.Item>
                <Dropdown.Item eventKey="inset">Inset</Dropdown.Item>
                <Dropdown.Item eventKey="outset">Outset</Dropdown.Item>
                <Dropdown.Item eventKey="none">None</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          <div className="editable-field form-row">
            <p>Border Color</p>
            <ColorPickerStyle
              onChange={onChangeColor}
              id={"borderColor"}
              tag={attribute?.borderColor}
            />
          </div>
          <div className="editable-field ">
            <Spacer
              label={"Border Width"}
              id={"borderWidth"}
              tagLeft={attribute?.borderLeftWidth}
              tagRight={attribute?.borderRightWidth}
              tagTop={attribute?.borderTopWidth}
              tagBottom={attribute?.borderBottomWidth}
              onChange={onChange}
              setLockValue={setBorderWidthLockValues}
              lockValue={borderWidthLockValues}
            />
            <Spacer                 
              label={"Border Radius"}
              id={"borderRadius"}
              tagLeft={attribute?.borderTopLeftRadius}
              tagRight={attribute?.borderBottomRightRadius}
              tagTop={attribute?.borderTopRightRadius}
              tagBottom={attribute?.borderBottomLeftRadius}
              onChange={onChange}
              setLockValue={setBorderRadiusLockValues}
              lockValue={borderRadiusLockValues}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default BorderOptions;
