/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PositionOptions from "../single-components/PositionOptions";
import InputField from "../single-components/InputField";
import Spacer from "../single-components/Spacer";
import BackgroundStyle from "../single-components/BackgroundStyle";
import BorderOptions from "../single-components/BorderOptions";
import { lockAllValues } from "../../../utils/lockValues";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";

const IndependentComponentOption = ({ form, setWidgetDispatcher }) => {
  const { widget } = form;
  const [marginLockValues, setMarginLockValues] = useState(false);
  const [paddingLockValues, setPaddingLockValues] = useState(false);
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);

  useEffect(() => {
    if(widget._id) {
      handleSetWidgetDispatcher([`options`], [{
        marginLeft: widget.options.marginLeft ? widget.options.marginLeft : "",
        marginRight: widget.options.marginRight ? widget.options.marginRight : "",
        marginTop: widget.options.marginTop ? widget.options.marginTop : "",
        marginBottom: widget.options.marginBottom ? widget.options.marginBottom : "",
        paddingLeft: widget.options.paddingLeft ? widget.options.paddingLeft : "",
        paddingTop: widget.options.paddingTop ? widget.options.paddingTop : "",
        paddingBottom: widget.options.paddingBottom ? widget.options.paddingBottom : "",
        paddingRight: widget.options.paddingRight ? widget.options.paddingRight : "",
        id: widget.options.id ? widget.options.id : "",
        width: widget.options.width ? widget.options.width : "",
        className: widget.options.className ? widget.options.className : "",
        position:widget.options.position ? widget.options.position : "",
        display: widget.options.display ? widget.options.display : "",
        alignSelf: widget.options.alignSelf ? widget.options.alignSelf : "",
        backgroundColor: widget.options.backgroundColor ? widget.options.backgroundColor : "",
        backgroundImage: widget.options.backgroundImage ? widget.options.backgroundImage : "",
        borderColor: widget.options.borderColor ? widget.options.borderColor : "rgb(126, 126, 126)",
        borderStyle: widget.options.borderStyle ? widget.options.borderStyle : "",
        borderLeftWidth: widget.options.borderLeftWidth ? widget.options.borderLeftWidth : "1px",
        borderTopWidth: widget.options.borderTopWidth ? widget.options.borderTopWidth : "1px",
        borderRightWidth: widget.options.borderRightWidth ? widget.options.borderRightWidth : "1px",
        borderBottomWidth: widget.options.borderBottomWidth ? widget.options.borderBottomWidth : "1px",
        borderTopLeftRadius: widget.options.borderTopLeftRadius ? widget.options.borderTopLeftRadius : "0px",
        borderTopRightRadius: widget.options.borderTopRightRadius ? widget.options.borderTopRightRadius : "0px",
        borderBottomRightRadius: widget.options.borderBottomRightRadius ? widget.options.borderBottomRightRadius : "0px",
        borderBottomLeftRadius: widget.options.borderBottomLeftRadius ? widget.options.borderBottomLeftRadius : "0px",
        zIndex: widget.options.zIndex ? widget.options.zIndex : "",
      }], widget, setWidgetDispatcher);
    }
  }, [widget?._id])

  const updateSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`options.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateSliderOptions = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`options.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    
    if (e.target.id.substring(0, 6) === "margin") {
      if (marginLockValues) {
        lockAllValues(e, [`options.marginBottom`, `options.marginRight`, `options.marginLeft`, `options.marginTop`], widget, setWidgetDispatcher, false, type);
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (paddingLockValues) {
        lockAllValues(e, [`options.paddingBottom`, `options.paddingRight`, `options.paddingLeft`, `options.paddingTop`], widget, setWidgetDispatcher, false, type);
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, [`options.borderLeftWidth`, `options.borderTopWidth`, `options.borderRightWidth`, `options.borderBottomWidth`], widget, setWidgetDispatcher, false, type);
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, [`options.borderTopLeftRadius`, `options.borderTopRightRadius`, `options.borderBottomRightRadius`, `options.borderBottomLeftRadius`], widget, setWidgetDispatcher, false, type);
      } else updateSingle();
    } else updateSingle();
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher([`options.borderColor`], [value], widget, setWidgetDispatcher);
  };

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`options.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const updateBackgroundColor = (value) => {
    handleSetWidgetDispatcher([`options.backgroundColor`], [value], widget, setWidgetDispatcher);
  };

  return (
    <div className="settings-field-container">
      <div className="editable-field">
        <p className="field-heading widget-label">Options</p>

        <Spacer
          label={"Margin"}
          id={"margin"}
          tagLeft={widget.options.marginLeft}
          tagRight={widget.options.marginRight}
          tagTop={widget.options.marginTop}
          tagBottom={widget.options.marginBottom}
          onChange={updateSliderOptions}
          setLockValue={setMarginLockValues}
          lockValue={marginLockValues}
        />
        <Spacer
          label={"Padding"}
          id={"padding"}
          tagLeft={widget.options.paddingLeft}
          tagRight={widget.options.paddingRight}
          tagTop={widget.options.paddingTop}
          tagBottom={widget.options.paddingBottom}
          onChange={updateSliderOptions}
          setLockValue={setPaddingLockValues}
          lockValue={paddingLockValues}
        />

        <InputField
          id={"className"}
          label={"CSS Class"}
          onChange={handleChange}
          placeholder={"Class"}
          type="text"
          value={widget.options.className}
          isRow={true}
        />
        <InputField
          id={"id"}
          label={"CSS ID"}
          onChange={handleChange}
          placeholder={"ID"}
          type="text"
          value={widget.options.id}
          isRow={true}
        />
        <InputField
          id={"zIndex"}
          label={"Z Index"}
          onChange={handleChange}
          placeholder={"Z Index"}
          type="number"
          value={widget.options.zIndex}
          isRow={true}
        />
        <hr />
        <PositionOptions
          handleSelect={updateSelectOptions}
          tag={widget.options.position}
          id={"position"}
          name={"position"}
        />
        <hr />
        <BackgroundStyle
          tag={widget.options.position}
          attribute={widget.options}
          onChange={handleChange}
          changeBackground={updateBackgroundColor}
        />
        <hr />
        <BorderOptions
          attribute={widget.options}
          onChange={updateSliderOptions}
          onChangeColor={updateBorderColor}
          setBorderWidthLockValues={setBorderWidthLockValues}
          setBorderRadiusLockValues={setBorderRadiusLockValues}
          borderWidthLockValues={borderWidthLockValues}
          borderRadiusLockValues={borderRadiusLockValues}
          updateSelectOptions={updateSelectOptions}
        />
      </div>
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(IndependentComponentOption);
