import { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { updateUserName } from "../../../actions/auth";

const UpdateNameModal = (props) => {
  const [form, setForm] = useState({
    first_name: props.auth.user
      ? props.auth.user.first_name
        ? props.auth.user.first_name
        : ""
      : "",
    last_name: props.auth.user
      ? props.auth.user.last_name
        ? props.auth.user.last_name
        : ""
      : "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateUserName({
      first_name: form.first_name,
      last_name: form.last_name,
    });
    props.handler(false);
  };

  return (
    <Modal show={props.show} size="lg" centered className="name-modal">
      <Modal.Header style={{ justifyContent: "center" }}>
        <div className="name-modal-header text-center">
          <h4> Change Name</h4>
          <p>Enter your new first and last name then save changes</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="input-holder">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  value={form.first_name || ""}
                  onChange={handleChange}
                  placeholder="John"
                  name="first_name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="input-holder">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  value={form.last_name || ""}
                  onChange={handleChange}
                  name="last_name"
                  placeholder="Doe"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="submit-btn mt-4">
                <button
                  className="formify-btn"
                  type="submit"
                  style={{ width: "100%", fontSize: "14px" }}
                >
                  Save Changes
                </button>
                <button
                  className="invert-formify-btn mt-3"
                  onClick={() => {
                    props.handler(false);
                  }}
                  type="button"
                  style={{ width: "100%", fontSize: "14px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapAuthErrorsStateToProps, { updateUserName })(
  UpdateNameModal
);
