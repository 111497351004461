/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion } from "react-bootstrap";
import { useState } from "react";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import Spacer from "../single-components/Spacer";
import TypographyPopup from "../single-components/typographyPopup";
import BorderOptions from "../single-components/BorderOptions";
import RangeSlider from "../single-components/RangeSlider";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import { lockAllValues } from "../../../utils/lockValues";
import { getResponsiveStyle } from "../../../utils/responsiveFunctions";

const SingleChoiceStyle = ({
  form: { widget, responsive },
  setWidgetDispatcher,
}) => {
  const [labelMarginLockValues, setLabelMarginLockValues] = useState(false);
  const [labelPaddingLockValues, setLabelPaddingLockValues] = useState(false);
  const [descriptionMarginLockValues, setDescriptionMarginLockValues] = useState(false);
  const [descriptionPaddingLockValues, setDescriptionPaddingLockValues] = useState(false);
  const [optionMarginLockValues, setOptionMarginLockValues] = useState(false);
  const [optionPaddingLockValues, setOptionPaddingLockValues] = useState(false);
  const [checkboxMarginLockValues, setCheckboxMarginLockValues] = useState(false);
  const [checkboxPaddingLockValues, setCheckboxPaddingLockValues] = useState(false);
  const [containerMarginLockValues, setContainerMarginLockValues] = useState(false);
  const [containerPaddingLockValues, setContainerPaddingLockValues] = useState(false);
  const [borderWidthLockValues, setBorderWidthLockValues] = useState(true);
  const [borderRadiusLockValues, setBorderRadiusLockValues] = useState(true);
  const [borderContainerWidthLockValues, setBorderContainerWidthLockValues] = useState(true);
  const [borderContainerRadiusLockValues, setBorderContainerRadiusLockValues] = useState(true);
  const [imageBorderWidthLockValues, setImageBorderWidthLockValues] = useState(true);
  const [imageBorderRadiusLockValues, setImageBorderRadiusLockValues] = useState(true);
  const [imageMarginLockValues, setImageMarginLockValues] = useState(false);
  const [imagePaddingLockValues, setImagePaddingLockValues] = useState(false);

  const updateCheckboxSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.checkbox.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateContainerSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.container.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateCheckboxSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.checkbox.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (checkboxMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.checkbox.marginBottom`,
            `style.${responsive}.checkbox.marginRight`,
            `style.${responsive}.checkbox.marginLeft`,
            `style.${responsive}.checkbox.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (checkboxPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.checkbox.paddingBottom`,
            `style.${responsive}.checkbox.paddingRight`,
            `style.${responsive}.checkbox.paddingLeft`,
            `style.${responsive}.checkbox.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.checkbox.borderLeftWidth`,
            `style.${responsive}.checkbox.borderTopWidth`,
            `style.${responsive}.checkbox.borderRightWidth`,
            `style.${responsive}.checkbox.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.checkbox.borderTopLeftRadius`,
            `style.${responsive}.checkbox.borderTopRightRadius`,
            `style.${responsive}.checkbox.borderBottomRightRadius`,
            `style.${responsive}.checkbox.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateContainerSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.container.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (containerMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.container.marginBottom`,
            `style.${responsive}.container.marginRight`,
            `style.${responsive}.container.marginLeft`,
            `style.${responsive}.container.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (containerPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.container.paddingBottom`,
            `style.${responsive}.container.paddingRight`,
            `style.${responsive}.container.paddingLeft`,
            `style.${responsive}.container.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderContainerWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.container.borderLeftWidth`,
            `style.${responsive}.container.borderTopWidth`,
            `style.${responsive}.container.borderRightWidth`,
            `style.${responsive}.container.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===  "Radius") {
      if (borderContainerRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.container.borderTopLeftRadius`,
            `style.${responsive}.container.borderTopRightRadius`,
            `style.${responsive}.container.borderBottomRightRadius`,
            `style.${responsive}.container.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateImageSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.image.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    
    if (e.target.id.substring(0, 6) === "margin") {
      if (imageMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.marginBottom`,
            `style.${responsive}.image.marginRight`,
            `style.${responsive}.image.marginLeft`,
            `style.${responsive}.image.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (imagePaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.paddingBottom`,
            `style.${responsive}.image.paddingRight`,
            `style.${responsive}.image.paddingLeft`,
            `style.${responsive}.image.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width") {
      if (borderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (borderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.image.${e.target.id}`], [e.target.value + "%"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(e.target.id.length - 5, e.target.id.length) === "Width" && e.target.id !== "maxWidth") {
      if (imageBorderWidthLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(e.target.id.length - 6, e.target.id.length) === "Radius") {
      if (imageBorderRadiusLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };


  const updateImageSlider = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher(
        [`style.${responsive}.image.${e.target.id}`],
        [e.target.value + "px"],
        widget,
        setWidgetDispatcher,
        false,
        type
      );
    };

    if (
      e.target.id.substring(e.target.id.length - 5, e.target.id.length) ===
        "Width" &&
      e.target.id !== "maxWidth"
    ) {

      if (imageBorderWidthLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderLeftWidth`,
            `style.${responsive}.image.borderTopWidth`,
            `style.${responsive}.image.borderRightWidth`,
            `style.${responsive}.image.borderBottomWidth`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else if (
      e.target.id.substring(e.target.id.length - 6, e.target.id.length) ===
      "Radius"
    ) {
      if (imageBorderRadiusLockValues) {
        lockAllValues(
          e,
          [
            `style.${responsive}.image.borderTopLeftRadius`,
            `style.${responsive}.image.borderTopRightRadius`,
            `style.${responsive}.image.borderBottomRightRadius`,
            `style.${responsive}.image.borderBottomLeftRadius`,
          ],
          widget,
          setWidgetDispatcher,
          false,
          type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateInputSelectOptions = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.image.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateImageBorderColor = (value) => {
    handleSetWidgetDispatcher([`style.${responsive}.image.borderColor`], [value], widget, setWidgetDispatcher);
  };
  
  const updateColorBackgroundCheckbox = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.checkbox.backgroundColor`,
        `style.tablet.checkbox.backgroundColor`,
        `style.mobile.checkbox.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorCheckBackgroundCheckbox = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.checkbox.checkBackgroundColor`,
        `style.tablet.checkbox.checkBackgroundColor`,
        `style.mobile.checkbox.checkBackgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateBorderColor = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.image.borderColor`,
        `style.tablet.image.borderColor`,
        `style.mobile.image.borderColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorBackgroundContainer = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.container.backgroundColor`,
        `style.tablet.container.backgroundColor`,
        `style.mobile.container.backgroundColor`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateContainerBorderColor = (value) => {
    handleSetWidgetDispatcher([`style.${responsive}.container.borderColor`], [value], widget, setWidgetDispatcher);
  };

  const updateLabelFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.label.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateDescriptionFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.description.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateOptionFont = (e) => {
    handleSetWidgetDispatcher([`style.desktop.option.fontFamily`], [e.family], widget, setWidgetDispatcher);
  };

  const updateLabelSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.label.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateLabelSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    if (e.target.id.substring(0, 6) === "margin") {
      if (labelMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.label.marginBottom`,
            `style.${responsive}.label.marginRight`,
            `style.${responsive}.label.marginLeft`,
            `style.${responsive}.label.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (labelPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.label.paddingBottom`,
            `style.${responsive}.label.paddingRight`,
            `style.${responsive}.label.paddingLeft`,
            `style.${responsive}.label.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`,
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateDescriptionSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.description.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateDescriptionSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.description.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }

    if (e.target.id.substring(0, 6) === "margin") {
      if (descriptionMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.description.marginBottom`,
            `style.${responsive}.description.marginRight`,
            `style.${responsive}.description.marginLeft`,
            `style.${responsive}.description.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (descriptionPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.description.paddingBottom`,
            `style.${responsive}.description.paddingRight`,
            `style.${responsive}.description.paddingLeft`,
            `style.${responsive}.description.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateColorDescription = (value) => {
    handleSetWidgetDispatcher([`style.${responsive}.description.color`], [value], widget, setWidgetDispatcher);
  };

  const updateOptionSelectstyle = (e, { id }) => {
    handleSetWidgetDispatcher([`style.${responsive}.option.${id}`], [e], widget, setWidgetDispatcher);
  };

  const updateOptionSliderstyle = (e, type) => {
    const updateSingle = () => {
      handleSetWidgetDispatcher([`style.${responsive}.option.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
    
    if (e.target.id.substring(0, 6) === "margin") {
      if (optionMarginLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.option.marginBottom`,
            `style.${responsive}.option.marginRight`,
            `style.${responsive}.option.marginLeft`,
            `style.${responsive}.option.marginTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else if (e.target.id.substring(0, 7) === "padding") {
      if (optionPaddingLockValues) {
        lockAllValues(e, 
          [
            `style.${responsive}.option.paddingBottom`,
            `style.${responsive}.option.paddingRight`,
            `style.${responsive}.option.paddingLeft`,
            `style.${responsive}.option.paddingTop`
          ], widget, setWidgetDispatcher, false, type
        );
      } else updateSingle();
    } else updateSingle();
  };

  const updateColorOption = (value) => {
    handleSetWidgetDispatcher([`style.${responsive}.option.color`], [value], widget, setWidgetDispatcher);
  };

  return (
    <>
      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Label</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "label", null)}
                  onChangeFont={updateLabelFont}
                  updateSliderstyle={updateLabelSliderstyle}
                  updateSelectstyle={updateLabelSelectstyle}
                  id={"label"}
                  showFontFamily={false}
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorLabel}
                    id={"color"}
                    attribute={getResponsiveStyle(widget?.style, responsive, "label", "color")}
                  />
                </div>
                <hr />

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "label", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "label", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "label", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "label", "marginBottom")}
                        onChange={updateLabelSliderstyle}
                        setLockValue={setLabelMarginLockValues}
                        lockValue={labelMarginLockValues}
                      />
                      <Spacer 
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "label", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "label", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "label", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "label", "paddingBottom")}
                        onChange={updateLabelSliderstyle}
                        setLockValue={setLabelPaddingLockValues}
                        lockValue={labelPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Options</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <TypographyPopup
                  attribute={getResponsiveStyle(widget?.style, responsive, "option", null)}
                  onChangeFont={updateOptionFont}
                  updateSliderstyle={updateOptionSliderstyle}
                  updateSelectstyle={updateOptionSelectstyle}
                  id={"options"}
                  showFontFamily={false} 
                />
                <div className="editable-field form-row  ">
                  <p>Font Color</p>
                  <ColorPickerStyle
                    onChange={updateColorOption}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "option", "color")}
                  />
                </div>
                <hr />

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer 
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "option", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "option", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "option", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "option", "marginBottom")}
                        onChange={updateOptionSliderstyle}
                        setLockValue={setOptionMarginLockValues}
                        lockValue={optionMarginLockValues}
                      />
                      <Spacer 
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "option", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "option", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "option", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "option", "paddingBottom")}
                        onChange={updateOptionSliderstyle}
                        setLockValue={setOptionPaddingLockValues}
                        lockValue={optionPaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Image</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <RangeSlider
                  label={"Width"}
                  id={"width"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "width")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true} 
                />
                <RangeSlider
                  label={"Max Width"}
                  id={"maxWidth"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "maxWidth")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true} 
                />
                <RangeSlider
                  label={"Height"}
                  id={"height"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "height")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true} 
                />
                <hr />
                <RangeSlider
                  label={"Opacity"}
                  id={"opacity"}
                  tag={getResponsiveStyle(widget?.style, responsive, "image", "opacity")}
                  min={0}
                  max={100}
                  onChange={updateSliderstyle}
                  isPercentage={true} 
                />
                <hr />
                <BorderOptions
                  attribute={getResponsiveStyle(
                    widget?.style,
                    responsive,
                    "image",
                    null
                  )}
                  onChange={updateImageSlider}
                  onChangeColor={updateBorderColor}
                  setBorderWidthLockValues={setImageBorderWidthLockValues}
                  setBorderRadiusLockValues={setImageBorderRadiusLockValues}
                  borderWidthLockValues={imageBorderWidthLockValues}
                  borderRadiusLockValues={imageBorderRadiusLockValues}
                  updateSelectOptions={updateInputSelectOptions}
                />

                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="field-label">
                      Spacing
                    </Accordion.Header>
                    <Accordion.Body>
                      <Spacer 
                        label={"Margin"}
                        id={"margin"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "image", "marginLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "image", "marginRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "image", "marginTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "image", "marginBottom")}
                        onChange={updateImageSliderstyle}
                        setLockValue={setImageMarginLockValues}
                        lockValue={imageMarginLockValues}
                      />
                      <Spacer 
                        label={"Padding"}
                        id={"padding"}
                        tagLeft={getResponsiveStyle(widget?.style, responsive, "image", "paddingLeft")}
                        tagRight={getResponsiveStyle(widget?.style, responsive, "image", "paddingRight")}
                        tagTop={getResponsiveStyle(widget?.style, responsive, "image", "paddingTop")}
                        tagBottom={getResponsiveStyle(widget?.style, responsive, "image", "paddingBottom")}
                        onChange={updateImageSliderstyle}
                        setLockValue={setImagePaddingLockValues}
                        lockValue={imagePaddingLockValues}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Checkbox
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundCheckbox}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "checkbox", "backgroundColor")}

                  />
                </div>

                <hr />

                <div className="editable-field form-row  ">
                  <p>Checkmark Color</p>

                  <ColorPickerStyle
                    onChange={updateColorCheckBackgroundCheckbox}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "checkbox", "checkBackgroundColor")}
                  />
                </div>

                <hr />
                <BorderOptions
                  tag={getResponsiveStyle(widget?.style, responsive, "checkbox", null)}
                  onChange={updateCheckboxSliderstyle}
                  onChangeColor={updateBorderColor}
                  setBorderWidthLockValues={setBorderWidthLockValues}
                  setBorderRadiusLockValues={setBorderRadiusLockValues}
                  borderWidthLockValues={borderWidthLockValues}
                  borderRadiusLockValues={borderRadiusLockValues}
                  updateSelectOptions={updateCheckboxSelectOptions} 
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr />

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer 
                label={"Margin"}
                id={"margin"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "checkbox", "marginLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "checkbox", "marginRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "checkbox", "marginTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "checkbox", "marginBottom")}
                onChange={updateCheckboxSliderstyle}
                setLockValue={setCheckboxMarginLockValues}
                lockValue={checkboxMarginLockValues}
              />
              <Spacer 
                label={"Padding"}
                id={"padding"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "checkbox", "paddingLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "checkbox", "paddingRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "checkbox", "paddingTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "checkbox", "paddingBottom")}
                onChange={updateCheckboxSliderstyle}
                setLockValue={setCheckboxPaddingLockValues}
                lockValue={checkboxPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="settings-field-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Container
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field">
                <div className="editable-field form-row  ">
                  <p>Background Color</p>

                  <ColorPickerStyle
                    onChange={updateColorBackgroundContainer}
                    id={"color"}
                    tag={getResponsiveStyle(widget?.style, responsive, "container", "backgroundColor")}
                  />
                </div>

                <hr />
                <BorderOptions
                  tag={getResponsiveStyle(widget?.style, responsive, "container", null)}
                  onChange={updateContainerSliderstyle}
                  onChangeColor={updateContainerBorderColor}
                  setBorderWidthLockValues={setBorderContainerWidthLockValues}
                  setBorderRadiusLockValues={setBorderContainerRadiusLockValues}
                  borderWidthLockValues={borderContainerWidthLockValues}
                  borderRadiusLockValues={borderContainerRadiusLockValues}
                  updateSelectOptions={updateContainerSelectOptions} 
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr />

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Spacing</Accordion.Header>
            <Accordion.Body>
              <Spacer 
                label={"Margin"}
                id={"margin"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "container", "marginLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "container", "marginRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "container", "marginTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "container", "marginBottom")}
                onChange={updateContainerSliderstyle}
                setLockValue={setContainerMarginLockValues}
                lockValue={containerMarginLockValues}
              />
              <Spacer 
                label={"Padding"}
                id={"padding"}
                tagLeft={getResponsiveStyle(widget?.style, responsive, "container", "paddingLeft")}
                tagRight={getResponsiveStyle(widget?.style, responsive, "container", "paddingRight")}
                tagTop={getResponsiveStyle(widget?.style, responsive, "container", "paddingTop")}
                tagBottom={getResponsiveStyle(widget?.style, responsive, "container", "paddingBottom")}
                onChange={updateContainerSliderstyle}
                setLockValue={setContainerPaddingLockValues}
                lockValue={containerPaddingLockValues}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(SingleChoiceStyle); 
