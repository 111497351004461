import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../../utils";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import UpdateNameModal from "./ChangeNameModal";
import { useState } from "react";
import UpdateProfileModal from "./ChangeProfileModal";
import DeleteUserModal from "./DeleteUserModal";

const ProfileCard = ({ auth }) => {
  const [modal, setModal] = useState(false);

  const [profileModal, setProfileModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const showDeleteModal = (value = null) => {
    setDeleteModal(value !== null ? value : !deleteModal);
  };

  const showProfileModal = (value = null) => {
    setProfileModal(value !== null ? value : !profileModal);
  };

  const showModal = (value = null) => {
    setModal(value !== null ? value : !modal);
  };
  return (
    <div className="formify-card m-4">
      <div className="header-content">
        <div className="profile-data">
          <Avatar
            src={auth.user ? auth.user.profile_picture : null}
            sx={{ width: 42, height: 42 }}
          />
          <div className="user-info d-flex flex-column align-items-start">
            <h4>
              <Link to="/login"> {auth.user ? auth.user.first_name && auth.user.last_name? auth.user.first_name+" "+auth.user.last_name: auth.user.username: ""} </Link>
            </h4>
            <p>{auth.user.username}</p>
          </div>
        </div>
        <div className="header-btns">
          <UpdateNameModal show={modal} handler={showModal} />
          <button className="formify-blue-btn me-2" onClick={showModal}>
            Change Name
          </button>
          <UpdateProfileModal show={profileModal} handler={showProfileModal} />
          <button className="formify-light-btn" onClick={showProfileModal}>
            Change Avatar
          </button>
        </div>
      </div>
      <div className="details">
        <div className="w-100">
          <h4 className="login-details-name">Login Details</h4>
          <div className="flex-data">
            <div className="login-details">
              <p>Your current email is</p>
              <p className="user-email">{auth.user ? auth.user.email : ""}</p>
            </div>
            <Link to="/profile/change/password">
              <button className="formify-blue-btn"> Change Password</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="delete mt-3">
        <DeleteUserModal show={deleteModal} handler={showDeleteModal} />
        <h4>Delete your account</h4>
        <p>
          Once deleted, all your forms and their data will get removed from our
          system, forever!
        </p>
        <button
          className="delete-btn "
          onClick={() => {
            setDeleteModal(!deleteModal);
          }}
        >
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default connect(mapAuthErrorsStateToProps)(ProfileCard);
