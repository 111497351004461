import { ChevronLeft } from "react-feather";
import { Drawer } from "@mui/material";
import { useState } from "react";
import ChangeResponsiveness from "./settings-components/single-components/changeResponsiveness";
import { Accordion } from "react-bootstrap";
import { DRAWER_WIDTH, mapAuthAndFormStateToProps } from "../../utils";
import { connect } from "react-redux";
import { setFormDispatcher, setFormStatesDispatcher } from "../../actions/form";
import { handleSetFormDispatcher } from "../utils/handleDispatcher";

const SettingsSidebar = ({
  form: { form, formStates }, 
  setFormDispatcher,
  setFormStatesDispatcher
}) => {
  const STATUS_OPTIONS = ["public", "private", "drafted"];

  const handleChange = (e) => {
    handleSetFormDispatcher([e.target.id], [e.target.value], form, setFormDispatcher)
  };

  const handleErrorMessagesChange = (e) => {
    handleSetFormDispatcher([`error_messages.${e.target.id}`], [e.target.value], form, setFormDispatcher)
  };


  const formSelect = (options, field) => {
    return (
      <>
        {options.map((opt, index) => {
          return (
            <option value={opt} key={index}>
              {opt.charAt(0).toUpperCase() + opt.slice(1)}
            </option>
          );
        })}
      </>
    );
  };

  const getNameFromType = {
    "form_date": "Date Widget",
    "form_dropdown": "Dropdown Widget",
    "form_email": "Email Widget",
    "form_long_text": "Short and Long Text Widget",
    "form_name": "Full Name Widget",
    "form_number": "Number Widget",
    "form_number_min_max": "If Number Widget Has Min/Max",
    "form_phone": "Phone Widget",
    "form_single_choice": "Single Choice and Multiple Choice Widget",
    "form_address": "Address Widget",
    "form_long_text_max_chars": "Short and Long Text Widget with max characters",
    "form_long_text_validator": "Short and Long Text Widget with Validator",
    "form_email_domain_list": "Email Widget With Domain List",
    "form_accept_terms": "Email Terms and Conditions"
  }


  return (
    <div className="widget-sidebar">
      <button
        className="formify-btn sidebar-controller-button"
        style={{
          marginLeft: formStates.sideBar ? `${DRAWER_WIDTH + 75}px` : "75px",
        }}
        onClick={() => setFormStatesDispatcher(!formStates.sideBar)}
      >
        <ChevronLeft className={formStates.sideBar ? "" : "sidebarClosed"} />
      </button>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            position: "absolute",
            left: "75px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={formStates.sideBar}
      >
        <div className="sidebar-wraper">
          <div className="form-information ">
            <h4 className="form-name">{form ? form.name : ""}</h4>
            <p className="form-url">artshop.getformify.com/Y812356128</p>
          </div>
          <div className="sidebar-settings-wraper">
            <div className="header">
              <h4 className="formify-h4">Form Settings</h4>
              <p className="formify-p">Change form status and properties.</p>
            </div>
            <div className="settings-body">
              <form>
                <div className="input-holder position-relative settings-input">
                  <label htmlFor="name">Form Title</label>
                  <p className="description">
                    Enter a short, descriptive name for this form.
                  </p>
                  <input
                    type="name"
                    id="name"
                    value={form.name || ""}
                    onChange={handleChange}
                    placeholder="Your form title"
                  />
                </div>
                <hr className="grey-hr" />
                <div className="input-holder position-relative settings-input">
                  <label htmlFor="status">Form Status</label>
                  <p className="description">
                    You can disable your form now, on a specific date, or when
                    it reaches a certain number of submissions.
                  </p>
                  <select
                    id="status"
                    defaultValue={form.status}
                    onChange={handleChange}
                  >
                    {formSelect(STATUS_OPTIONS, "status")}
                  </select>
                </div>

                <hr className="grey-hr" />
                <div className="settings-field-container">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="field-label">
                        Error Messages
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-widget-spacer "></div>
                        {form && Object.entries(form.error_messages).map(([key, value]) => (
                          <div className={"editable-field input-holder"}>
                            <p className="field-heading">{getNameFromType[key]}</p>
                            <textarea
                              rows={2}
                              autoComplete="off"
                              type={"text"}
                              id={key}
                              value={value}
                              onChange={handleErrorMessagesChange}
                              name={key}
                              placeholder={value}
                            />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <hr className="grey-hr" />

                <div className="input-holder position-relative settings-input">
                  <label htmlFor="name">Google Analytics (Tracking ID)</label>
                  <p className="description">Tracking ID</p>
                  <input
                    type="text"
                    id="googleAnalyticsTrackingId"
                    value={form.googleAnalyticsTrackingId || ""}
                    onChange={handleChange}
                    placeholder="UA-000000-01"
                  />
                </div>

                <hr className="grey-hr" />

                <div className="input-holder position-relative settings-input">
                  <label htmlFor="name">Facebook Pixel ID</label>
                  <p className="description">Pixel ID</p>
                  <input
                    type="text"
                    id="facebookPixelTrackingId"
                    value={form.facebookPixelTrackingId || ""}
                    onChange={handleChange}
                    placeholder="5603402359741606"
                  />
                </div>
              </form>
            </div>

            <div className="settings-footer d-flex flex-column justify-content-between mt-2">
              <h4 className="formify-h4">
                Looking to manage your integrations?
              </h4>
              <p className="formify-sm-p" style={{ fontSize: "12px" }}>
                Connect your form to other apps, or manage existing
                configurations within this form.
              </p>
              <button
                className="formify-btn-no-margin text-start mt-2"
                style={{ width: "fit-content" }}
                onClick={ () => setFormStatesDispatcher(true, "openIntegrations") }
              >Manage Integrations</button>
            </div>
          </div>
        </div>
      </Drawer>
      <ChangeResponsiveness />
    </div>
  );
};

export default connect(mapAuthAndFormStateToProps, { setFormStatesDispatcher, setFormDispatcher })(SettingsSidebar);
