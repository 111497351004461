import { connect } from "react-redux";
import { mapAuthStateToProps } from "../../../../utils";
import { Link } from "react-router-dom";

const SubblyList = ({ auth }) => {
  const subblyProductGridHTML = () => {
    return auth.user.stores.subbly.products.length === 0 ? (
      <div className="no-products">
        {" "}
        <p>Your Subbly Product List is currently empty!</p>{" "}
      </div>
    ) : (
      <div className="subbly-products-grid">
        {auth.user.stores.subbly.products.map((e) => {
          return (
            <Link to={`/integrations/subbly/edit-product/${e._id}`}>
              <div className="subbly-product">
                {" "}
                <div className="subbly-img">
                  <img alt="Formify" src={e.product_image}></img>{" "}
                </div>
                <p className="product-title">{e.product_name}</p>
                <p className="product-id">{e.product_id}</p>{" "}
              </div>
            </Link>
          );
        })}{" "}
      </div>
    );
  };
  return (
    <>
      {/* <div className="subbly-header">
        <div className="row mt-2">
          <h4 className="integration-page-header">Manage Products</h4>
          <p className="grey-paragraph">
            Manage, create and connect the products you want to show in your Formify
            form by simply using your products ID from Subbly.
          </p>
        </div>

      </div> */}
        {/* <div className="row mt-2 col-lg-4">
      <Link to="#"> Manage products</Link>
    </div> */}
      {subblyProductGridHTML()}

      <div>
        <Link to="/integrations/subbly/add-product" className="upgradePlan">
          Add Product
        </Link>
      </div>
    </>
  );
};

export default connect(mapAuthStateToProps)(SubblyList);
