import { Modal, Overlay, Tooltip } from "react-bootstrap";
import { Avatar } from "@mui/material";
import { connect } from "react-redux";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../../utils";
import { Image, Folder } from "react-feather";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { updateProfilePic, changeImageGallery } from "../../../actions/auth";
import _ from "lodash";
import { clearErrors } from "../../../actions/errors";
import axios from "axios";
import { usePrevious } from "../../../hooks/usePrevious";

const UpdateProfileModal = (props) => {
  const [image, setImage] = useState({
    data: null,
    url: props.auth.user ? props.auth.user.profile_picture : "",
  });

  const [gallery, setGallery] = useState({
    show: false,
    images: [],
    selected: null,
  });

  const prevProfilePic = usePrevious(props.auth.user.profile_picture);

  useEffect(() => {
    if (props.auth.user.profile_picture !== prevProfilePic) {
      props.handler(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth]);

  const btnRef = useRef(null);
  const imageRef = useRef(null);
  const galleryRef = useRef(null);

  useEffect(() => {
    axios.get(`${BASE_DEV_URL}/gallery/`).then((res) => {
      setGallery({
        ...gallery,
        ...res.data,
      });
    });

    return () => {
      props.clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setImage({
      ...image,
      url: props.auth.user ? props.auth.user.profile_picture : "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.user]);

  const setSelected = (id) => {
    setGallery({ ...gallery, selected: id });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.data);
    props.updateProfilePic(formData);
  };
  const handleImgChange = (e) => {
    if (e.target.files[0])
      setImage({
        ...image,
        data: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
    else {
      setImage({
        ...image,
        data: null,
        url: props.auth.user ? props.auth.user.profile_picture : "",
      });
    }
  };

  const setGalleryImage = (e) => {
    props.changeImageGallery({ gallery_image: gallery.selected });
  };

  return gallery.show ? (
    <Modal
      show={props.show}
      size="lg"
      centered
      className="name-modal picture-modal gallery-modal"
    >
      <Modal.Header style={{ justifyContent: "center" }}>
        <div className="name-modal-header text-center">
          <h4 ref={galleryRef}>Browse Library</h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Overlay
          target={galleryRef.current}
          show={!_.isEmpty(props.errors)}
          placement="top"
        >
          {(data) => (
            <Tooltip
              id="login-error"
              style={{ backgroundColor: "#FC5B3F" }}
              {...data}
            >
              {props.errors ? props.errors.message : ""}
            </Tooltip>
          )}
        </Overlay>
        <div className="row image-wraper mb-4  gy-4 gx-2">
          {gallery.images.map((image) => {
            return (
              <div
                className={
                  image.id === gallery.selected
                    ? "col image-holder  selected text-center"
                    : "col image-holder text-center "
                }
                key={image.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelected(image.id);
                }}
              >
                <img
                  src={image.image}
                  style={{
                    height: "132px",
                    width: "132px",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col d-flex align-items-center justify-content-between gallery-modal">
            <div className="submit-btn me-3" style={{ width: "100%" }}>
              <button
                className="formify-btn "
                type="button"
                onClick={setGalleryImage}
                style={{ fontSize: "14px", width: "100%" }}
              >
                Save Changes
              </button>
            </div>
            <div className="submit-btn " style={{ width: "100%" }}>
              <button
                className="invert-formify-btn"
                onClick={() => {
                  props.clearErrors();
                  setGallery({ ...gallery, show: false });
                }}
                type="button"
                style={{ fontSize: "14px", width: "100%" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      show={props.show}
      size="lg"
      centered
      className="name-modal picture-modal"
    >
      <Modal.Header style={{ justifyContent: "center" }}>
        <div className="name-modal-header text-center">
          <h4> Change Avatar</h4>
          <p>Enter your new first and last name then save changes</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row gx-0 gy-2 profile-form-row">
            <div className="col-lg-5 col-md-5  col-sm-5 col-5">
              <Avatar
                src={image.url}
                sx={{ minWidth: "112px", minHeight: "112px" }}
              />
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7 col-7 profile-images  ">
              <Overlay
                target={btnRef.current}
                show={!_.isEmpty(props.errors)}
                placement="bottom"
              >
                {(data) => (
                  <Tooltip
                    id="login-error"
                    style={{ backgroundColor: "#FC5B3F" }}
                    {...data}
                  >
                    {props.errors ? props.errors.message : ""}
                  </Tooltip>
                )}
              </Overlay>
              <button
                type="button"
                ref={btnRef}
                onClick={() => {
                  imageRef.current.click();
                }}
              >
                <Image
                  style={{
                    backgroundColor: "#6563FF",
                    padding: "4px",
                    borderRadius: "7px",
                    color: "white",
                    height: "32px",
                    width: "32px",
                  }}
                />
                <p className="m-0 text-center">Upload Image</p>
              </button>
              <button
                type="button"
                onClick={() => {
                  setGallery({ ...gallery, show: true });
                }}
              >
                <Folder
                  style={{
                    backgroundColor: "#6563FF",
                    padding: "4px",
                    borderRadius: "7px",

                    color: "white",
                    height: "32px",
                    width: "32px",
                  }}
                />
                <p className="m-0 text-center">Choose from Library</p>
              </button>
              <input
                type="file"
                name="image"
                className="d-none"
                onChange={handleImgChange}
                ref={imageRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="submit-btn mt-4">
                <button
                  className="formify-btn"
                  type="submit"
                  style={{ width: "100%", fontSize: "14px" }}
                >
                  Save Changes
                </button>
                <button
                  className="invert-formify-btn mt-3"
                  onClick={() => {
                    props.clearErrors();
                    props.handler(false);
                  }}
                  type="button"
                  style={{ width: "100%", fontSize: "14px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapAuthErrorsStateToProps, {
  updateProfilePic,
  clearErrors,
  changeImageGallery,
})(UpdateProfileModal);
