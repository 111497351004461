import { useState, useMemo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import FormifyButton from "../utils/FromifyButton";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import axios from "axios";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import { toast } from "react-toastify";
import Youtube from "../loaders/Loaders";
import SearchInput from "./SearchInput";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CircularProgress } from "@mui/material";
// import noticeIcon from "../../../public/assets/images/icons/notice-icon.svg"

const MediaLibraryModal = ({
  uploadOnly,
  setVisible,
  uploadAppendImage,
  data,
  setData,
}) => {
  const [activeTab, setActiveTab] = useState("upload");
  const [cropper, setCropper] = useState("");
  const [file, setFile] = useState(null);
  const [isUploadLoading, setUploadLoading] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (activeTab !== "upload") getData("");
  }, [activeTab]);

  useEffect(() => {
    if (search) getData(search);
  }, [search]);

  const setImageCallback = (key, url) => {
    if (data.index > -1) data.callback(key, url, data.index);
    else data.callback(key, url);
    setData({});
  };

  const close = () => {
    if (!uploadOnly) setData({});
    else setVisible(false);
  };

  const getData = (search) => {
    setLoading(true);

    axios
      .get(
        `${BASE_DEV_URL}/mediaLibrary/${activeTab}/newest/${search}`,
        tokenConfig(store.getState)
      )
      .then((res) => {
        setImages(res.data.images);

        setTimeout(() => {
          setLoading(false);
        }, 400);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setUploadLoading(true);
      const blob = dataURLtoBlob(cropper.getCroppedCanvas().toDataURL());
      const formData = new FormData();
      formData.append("image", blob, Date.now().toString() + "_" + file?.name);
      if (!uploadOnly && data.hasIndex) formData.append("index", data.index);
      updateElement(formData);
    }
  };

  const updateElement = (formData) => {
    axios
      .post(
        `${BASE_DEV_URL}/mediaLibrary`,
        formData,
        tokenConfig(store.getState)
      )
      .then((res) => {
        if (uploadOnly) {
          uploadAppendImage();
        } else {
          setImages((prevState) => [res.data.image, ...prevState]);
          setImageCallback(res.data.image.src, res.data.image.url);
        }
        setUploadLoading(false);
        setFile(null);
      })
      .catch((err) => {
        toast.error(err);
        setUploadLoading(false);
      });
  };
  const validateFormat = () => {
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg" ||
      file?.type === "image/PNG" ||
      file?.type === "image/JPEG" ||
      file?.type === "image/JPG" ||
      file?.type === "image/gif" || 
      file?.type === "image/GIF"
    )
      return { isValid: true };
    else
      return {
        isValid: false,
        message: "Invalid file format.",
        message2:"Only png, jpg, jpeg and gif are allowed."
      };
  };

  const validateSize = () => {
    if (file?.size > 4 * 1024 * 1024)
      return {
        isValid: false,
        message: "File size limit exceeded.",
        message2:"Max File Size is: 4MB"
      };
    else return { isValid: true };
  };

  const isFileValid = useMemo(() => {
    return validateFormat() && validateSize();
  }, [file]);

 

  const [validationResult, setValidationResult] = useState({ isValid: true });

  useEffect(() => {
    const formatResult = validateFormat();
    const sizeResult = validateSize();
    if (!formatResult.isValid) {
      setValidationResult(formatResult);
    } else if (!sizeResult.isValid) {
      setValidationResult(sizeResult);
    } else {
      setValidationResult({ isValid: true });
    }
  }, [file]);
  
  const closeModal = () => setFile(null);

  return (
    <Modal
      show={true}
      size="md"
      backdrop="static"
      centered
      className="media-library-modal"
    >
      <Modal.Header>
        <div className="media-modal-header">
          <h2>Media gallery</h2>
          <FormifyButton
            onClick={close}
            icon={
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 2.417L21.583 0 12 9.583 2.417 0 0 2.417 9.583 12 0 21.583 2.417 24 12 14.417 21.583 24 24 21.583 14.417 12 24 2.417z"
                  fill="#212529"
                ></path>
              </svg>
            }
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="h-100">
          <div className="d-flex mb-2 media-tabs-container">
            <FormifyButton
              title="Upload"
              classes={`media-subtitle-form-browse ${
                activeTab === "upload" ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab("upload")}
            />
            {!uploadOnly && (
              <>
                <FormifyButton
                  title="Image"
                  classes={`media-subtitle-form-browse ${
                    activeTab === "general" ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab("general")}
                />
                <FormifyButton
                  title="Icon"
                  classes={`media-subtitle-form-browse ${
                    activeTab === "icon" ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab("icons")}
                />
                <FormifyButton
                  title="My gallery"
                  classes={`media-subtitle-form-browse ${
                    activeTab === "custom" ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab("custom")}
                />
              </>
            )}
          </div>
          {file && !validationResult.isValid && (
  <div className="media-message-warning">
    <div>
      <img
        src={
          process.env.PUBLIC_URL +
          "/assets/images/icons/notice-icon.svg"
        }
        width={"35px"}
        height={"35px"}
      />
    </div>
    <div>
      <h5>{validationResult.message}</h5>
      <p>{validationResult.message2}</p>
    </div>
  </div>
)}

          <div className="modal-body-container">
            {activeTab !== "upload" && <SearchInput setSearch={setSearch} />}
            {activeTab === "upload" ? (
  <>
    {!file || !validationResult.isValid ? (
      <Dropzone
        onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="modal-upload-here">
            <input {...getInputProps()} />
            <h3>
              <span>Upload</span> or drop an image right here
            </h3>
            {file && !validationResult.isValid ? (
              <p
                className="text-center"
                style={{ color: "#FC5B3F" }}
              >
                {validationResult.message}
              </p>
            ):
            <p
            className="text-center"
            style={file && !isFileValid  ? { color: "#FC5B3F" } : {color:"#898989"}}
          >
            Only files with the following extensions are allowed:
            png, gif, jpg, jpeg. <br />
            Max File Size is: 4MB
          </p>
            }
          </div>
        )}
      </Dropzone>
                ) : (
                  <>
                    <Cropper
                      style={{ height: "calc(100% - 40px)", width: "100%" }}
                      className="cropper-box"
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".cropper-preview"
                      src={URL.createObjectURL(file)}
                      viewMode={1}
                      maxHeight={50}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => setCropper(instance)}
                      guides={true}
                    />
                    <div className="d-flex justify-content-end">
                      <button
                        className="delete-btn mt-2"
                        onClick={closeModal}
                        type="button"
                      >
                        Cancel
                      </button>
                      <FormifyButton
                        loaderWhite={true}
                        loading={isUploadLoading}
                        title="Save changes"
                        classes="d-inline-block img-btn mt-2"
                        onClick={getCropData}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="mt-3 h-100">
                {isLoading ? (
                  <div className="form-list">
                    <Youtube />
                  </div>
                ) : images.length > 0 ? (
                  <ImageList cols={3} gap={8}>
                    {images.map((item) => {
                      return (
                        <ImageListItem
                          key={item._id}
                          onClick={() => setImageCallback(item.src, item.url)}
                          className="media-library-image"
                        >
                          <img
                            src={`${item.url}`}
                            srcSet={`${item.url}`}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/missing font-02.png"
                      }
                      alt="no-data"
                      width={60}
                    />
                    <p className="mt-3">
                      Seems like you don't have any image uploaded.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MediaLibraryModal;
