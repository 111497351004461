import ContactGrowth from '../stats/ContactGrowth.js';
import axios from "axios";
import FlowInsights from '../stats/FlowInsights.js';
import ListSize from '../stats/ListSize.js';
import { useEffect, useState } from "react";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import FormContainer from "../form/FormContainer";
import QuickActions from "../dashboard/QuickAction";
import Youtube from "../loaders/Loaders";
import AddForm from "../utils/AddForm";
import { connect } from "react-redux";
import { mapAuthErrorsStateToProps } from "../../utils";

const Stats = ({ auth }) => {
  const [forms, setForm] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(`${BASE_DEV_URL}/form/`, tokenConfig(store.getState))
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setForm([...response.data.forms]);
      });
  }, []);

  return (
    <>
    <div className="container-fluid mt-4 contact-list p-relative pl-20 pb-100">
      <div className="stats-container">
        <div className="stats stats-one"><ContactGrowth/></div>
        <div className="stats stats-two"><FlowInsights/></div>
        <div className="stats stats-two"><ListSize/></div>
      </div>
      {/* <div className='text-end w-85'>
        <Link className="link-btn" to="">Create form +</Link>
      </div> */}
        <div className="form-stats">
          <h1>My Signup Flows</h1>
          {loading ? (
            <div className="form-list pe-5">
              <Youtube />
            </div>
          ) : forms.length > 0 ? (
            <div className="form-list pe-5">
              {forms.map((form, index) => {
                return (
                  <FormContainer
                    key = {form._id}
                    name={form.name}
                    _id={form._id}
                    status = {form.form_settings.status}
                    responses = {form.responses}
                    index={index}
                    forms={forms}
                    setForm={setForm}
                  />
                );
              })}
            </div>
          ) : (
            <AddForm text="Add Signup Flow"/>
            )}
    {/* <div className="pb-100">
      <div className="container-fluid mt-3 contact-list p-relative">
        <div className="stats-container">
          <div className="stats stats-one"><ContactGrowth/></div>
          <div className="stats stats-two"><FlowInsights/></div>
          <div className="stats stats-two"><ListSize/></div>
        </div>
       
          <div className="form-stats">
            <h1>My Forms</h1>
            {loading ? (
              <div className="form-list pe-5">
                <Youtube />
              </div>
            ) : forms.length > 0 ? (
              <div className="form-list pe-5">
                {forms.map((form, index) => {
                  return (
                    <FormContainer
                      key = {form._id}
                      name={form.name}
                      _id={form._id}
                      status = {form.form_settings.status}
                      responses = {form.responses}
                      index={index}
                      forms={forms}
                      setForm={setForm}
                    />
                  );
                })}
              </div>
            ) : (
              <AddForm text="Add Formiform"/>
              )}
          </div>
        <QuickActions/>
      </div>
    </div> */}
    </div>
    </div>

    </>
  );
};

export default connect(mapAuthErrorsStateToProps)(Stats);