import { useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Group,
  Text,
  Tabs,
} from "@mantine/core";
import prompts from "../../data/prompts.json";
import { Link, useNavigate } from "react-router-dom";

export default function Copy() {
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();

  return (
    <main>
      <Container size="md" my={50}>
        {/* Layout of left and right */}
        <Grid>
          <Grid.Col lg={9} sm={12}>
            <h1 className="formify-h1">
              What kind of copy do you want to generate?
            </h1>
          </Grid.Col>
          <Grid.Col lg={3} sm={12} align="right">
            <Button
              onClick={() => {
                navigate("/copy/saved");
              }}
            >
              View saved copy
            </Button>
          </Grid.Col>
        </Grid>

        <Tabs
          value={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
          mt="md"
        >
          <Tabs.List>
            <Tabs.Tab value="all">All</Tabs.Tab>
            <Tabs.Tab value="email">Email</Tabs.Tab>
            <Tabs.Tab value="social-media">Social</Tabs.Tab>
            <Tabs.Tab value="website">Website</Tabs.Tab>
            <Tabs.Tab value="google-ads">Google Ads</Tabs.Tab>
            <Tabs.Tab value="growth">Growth</Tabs.Tab>
          </Tabs.List>
        </Tabs>

        <Grid mt="md">
          {prompts
            .filter((prompt) => {
              if (activeTab === "all") {
                return true;
              }

              return prompt.category === activeTab;
            })
            .map((prompt) => {
              return (
                <Grid.Col lg={4} key={prompt.slug} sm={6} xs={12}>
                  <Card shadow="sm" radius="md" withBorder>
                    <div style={{ minHeight: 100 }}>
                      <Group position="apart" mb="xs">
                        <Text weight={500}>{prompt.name}</Text>
                      </Group>

                      <Text size="sm" color="dimmed">
                        {prompt.description}
                      </Text>
                    </div>

                    <Link to={`/copy/${prompt.slug}`}>
                      <Button
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                      >
                        Generate
                      </Button>
                    </Link>
                  </Card>
                </Grid.Col>
              );
            })}
        </Grid>
      </Container>
    </main>
  );
}
