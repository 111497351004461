import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_DEV_URL } from "../../utils";
import { tokenConfig } from "../../actions/auth";
import store from "../../store";
import List from "../loaders/List";

const ListSize = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${BASE_DEV_URL}/form/contact/count/`, tokenConfig(store.getState))
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setData(response.data.contacts);
      });
  };
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1)+"..." : str;
  };
  return (
    <div>
      <h1>List Size</h1>
      <div className="two-stat">
        <div className="insights-head d-flex justify-content-between">
          <p>Website list</p>
          <p>SIZE</p>
        </div>
        <div className="list-overflow-data">
          {!loading ? data.length > 0 ?data.map((form, index) => {
            return (
              <div key = {form._id} className="insight-data d-flex justify-content-between">
                <p>{truncate(form.ProductName, 40)}</p>
                <p>{form.OrderCount}</p>
              </div>
            );
          }): "No data" : <List/>}
        </div>
      </div>
    </div>
  );
};
export default ListSize;
