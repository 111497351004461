import { combineReducers } from "redux";
import auth from "./auth";
import errors from "./errors";
import messages from "./messages";
import loader from "./loader";
import form from "./form";
import rechargeProdReducer from "./rechargeProdSlice";
import dynamicProdSlice from "./dynamicProdSlice";
import subscriptionProdSlice from "./subscriptionProdSlice";


const rootReducer = combineReducers({
  auth,
  errors,
  messages,
  loader,
  form,
  rechargeProd: rechargeProdReducer,
  subscriptionProducts: subscriptionProdSlice,
  dynamicProd: dynamicProdSlice,
});

export default rootReducer;
