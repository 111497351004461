import React from 'react';

const FormifyButton = React.forwardRef(({
  type = "button", 
  title = '', 
  onClick, 
  icon = '', 
  loading = false, 
  disabled = false, 
  classes = '',
  size = '',
  style = null,
  loaderWhite = false,
  onMouseLeave = null,
  onMouseEnter = null
}, ref) => {  // Notice how `ref` is passed as the second argument here
  return (
    <button 
      ref={ref}  // Assign ref to the button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`formify-new-btn ${loaderWhite ? "white-loader" : ""} ${size} ${classes} ${loading ? 'new-loader' : ''}`}
      style={style}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {loading ? '\xa0' : icon}
      {title && <span className={icon ? 'ps-2' : ''}>{title}</span>}
    </button>
  );
});

export default FormifyButton;
