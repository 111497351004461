import { useState } from "react";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";

const DuplicateModal = ({
    showDuplicate,
    contactDuplicate,
    duplicate,
    placeholder,
    setShowDuplicate,
    setDuplicate,
    formName,
    setFormName,
  }) => {
    const handleFormNameChange = (event) => {
      setFormName(event.target.value);
    };
  
    const handleDuplicateForm = () => {
      
        duplicate(contactDuplicate, formName);
      setShowDuplicate(false);
      setFormName('');
    };
   
  return (
    <Modal show={showDuplicate} size="lg" centered className="name-modal">
     
       <h4 className="text-center pt-4">Duplicate Form</h4>
       <p className="text-center">
       To create a copy of this form, give the new form a name, and we’ll create a draft form you can customize.
       </p>
  
      <Modal.Body>


      <div className="input-holder position-relative">
        <label htmlFor="email">Form Name</label>
        <input
         type="text"
          style={{ width: "100%", fontSize: "14px" }}
         placeholder={placeholder}
         value={formName}
         onChange={handleFormNameChange}
        />
        <div>
      </div>
    

        <button className="formify-btn mt-3"
         type="button"
          style={{ width: "100%", fontSize: "14px" }}
        onClick={handleDuplicateForm}>Duplicate Form</button>
        <button
          className="invert-formify-btn mt-3"
          onClick={() => {
            setShowDuplicate(false);
            setDuplicate(null);
          }}
          type="button"
          style={{ width: "100%", fontSize: "14px" }}
        >
          Cancel
        </button>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default DuplicateModal;
