/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import FormSteps from "../form/FormSteps";
import FormCanvas from "../form/FormCanvas";
import WidgetSidebar from "../form/WidgetSidebar";
import SettingsSidebar from "../form/SettingsSidebar";
import HistorySidebar from "../form/HistorySidebar";
import IntegrationsSidebar from "../form/IntegrationsSidebar";
import PublishSidebar from "../form/PublishSidebar";
import { DragDropContext } from "react-beautiful-dnd";
import { Edit } from "react-feather";
import { useEffect } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { Route, Routes, useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import FormifyButton from "../utils/FromifyButton";
import {
  BASE_DEV_URL,
  DRAWER_WIDTH,
  mapAuthAndFormStateToProps,
} from "../../utils";
import { tokenConfig } from "../../actions/auth";
import {
  setWidgetDispatcher,
  setFormStatesDispatcher,
  setWidgetStepAndPositionDispatcher,
  setFormDispatcher,
  resetFormStateDispatcher,
  setResponsiveDispatcher,
} from "../../actions/form";
import store from "../../store";
import { toast } from "react-toastify";
import _ from "lodash";
import { createElement } from "../utils/formFunctions";
import { widgetsCode } from "../utils/widgetsCode";
import Hubspot from "../form/integrations/Hubspot";
import SalesForce from "../form/integrations/Salesforce";
import { connect, useSelector } from "react-redux";
import ActiveCampaign from "../form/integrations/ActiveCampaign";
import MailChimp from "../form/integrations/Mailchimp";
import Store from "../form/integrations/Store";
import StyleSidebar from "../form/StyleSidebar";
import ProductsContainers from "../form/settings-components/containers/ProductsContainers";
import DynamicProductsContainers from "../form/settings-components/containers/DynamicProductContainers";

import ParagraphContainers from "../form/settings-components/containers/ParagraphContainers";

import EmailContainer from "../form/settings-components/containers/EmailContainer";
import IntroductionContainers from "../form/settings-components/containers/IntroductionContainers";
import UpsellContainers from "../form/settings-components/containers/UpsellContainers";
import SingleChoiceContainers from "../form/settings-components/containers/SingleChoiceContainers";
import MultiChoiceContainers from "../form/settings-components/containers/MultiChoiceContainers";
import ShortTextContainers from "../form/settings-components/containers/ShortTextContainers";
import FullNameContainers from "../form/settings-components/containers/FullNameContainers";

import ImageContainer from "../form/settings-components/containers/ImageContainer";
import DropDownContainer from "../form/settings-components/containers/DropdownContainer";
import NumberContainers from "../form/settings-components/containers/NumberContainers";
import PhoneNumberContainers from "../form/settings-components/containers/PhoneNumberContainers";
import LongTextContainers from "../form/settings-components/containers/LongTextContainers";
import SkipStepContainers from "../form/settings-components/containers/SkipStepContainer";
import AddressContainer from "../form/settings-components/containers/AddressContainer";
import DateContainer from "../form/settings-components/containers/DateContainer";
import ResponsiveTabs from "../form/settings-components/single-components/ResponsiveTabs";
import { handleSetFormDispatcher } from "../utils/handleDispatcher";
import MediaLibraryModal from "../medialibrary/MediaLibraryModal";
import UnsavedChanges from "../utils/UnsavedChanges";
import UpdateForm from "../form/settings-components/single-components/UpdateForm";
import RedirectChoiceContainer from "../form/settings-components/containers/RedirectChoiceContainer";
import FormifyLoader from "../utils/FormifyLoader";
import { createGlobalStyle } from "styled-components";
import ProductContent from "../form/settings-components/content/ProductContent";
import Klavyio from "../form/integrations/Klaviyo";
import { Close } from "@mui/icons-material";
import Klaviyo from "../form/integrations/Klaviyo";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "${(props) => props.name}";
    src: url(${(props) => props.url});
  } ;`;
const FormBuilder = ({
  auth,
  form: { form, widget, formStates, updatedForm, responsive },
  createElement,
  setWidgetDispatcher,
  setFormStatesDispatcher,
  setFormDispatcher,
  setWidgetStepAndPositionDispatcher,
  resetFormStateDispatcher,
  setResponsiveDispatcher,
}) => {
  const MERGETAGSINPUT = [
    "form_email",
    "form_single_choice",
    "form_address",
    "form_phone",
    "form_name",
    "form_number",
    "form_dropdown",
    "form_short_text",
    "form_long_text",
    "form_name",
    "form_multi_choice",
  ];
  const params = useParams();
  const nav = useNavigate();
  const [deviceFrame, setDeviceFrame] = useState(false);
  const [items, setItems] = useState(widgetsCode);
  const [crop, setCrop] = useState({});
  const [search, setSearch] = useState("");
  const [hover, setHover] = useState(false);
  const [activestep, setActiveStep] = useState(false);

  const [discardChangesLink, setDiscardChangesLink] = useState(null);
  const rechargeProd = useSelector((state) => state.rechargeProd);
  const subscriptionProducts = useSelector(
    (state) => state.subscriptionProducts
  );

  const openWidgetSidebar = () => setFormStatesDispatcher(true, "openWidgets");
  const openSettingsSidebar = () =>
    setFormStatesDispatcher(true, "openSettings");
  const openStyleSidebar = () => setFormStatesDispatcher(true, "openStyle");
  const openPublishSidebar = () => setFormStatesDispatcher(true, "openPublish");
  const stepElements = useMemo(() => {
    if (!_.isEmpty(form?.steps)) {
      const step =
        form.steps[
          _.findIndex(form.steps, (step) => {
            return step.order_number === parseInt(params.step);
          })
        ];
      return step ? step.elements : [];
    } else {
      return [];
    }
  }, [form, params.step]);

  const [dismissedWarnings, setDismissedWarnings] = useState([]);

  const handleDismiss = (index) => {
    setDismissedWarnings([...dismissedWarnings, index]);
  };

  const shouldDisplayWarning = (index) => !dismissedWarnings.includes(index);

  const getShopifyProducts = async (page = 1, after = "") => {
    try {
      let url = `${BASE_DEV_URL}/store/shopify/products`;
      if (rechargeProd) {
        url += `?recharge=true&page=${page}`;
      } else if (subscriptionProducts) {
        url += `/subscriptions?first=12${after ? "&after=" + after : ""}`;
      }
  
      const result = await axios.get(url, tokenConfig(store.getState));
  
      if (rechargeProd) {
        return result.data;
      } else if (subscriptionProducts) {
        // console.log("subbbb", result)
        return result; // Assuming the response structure is different for subscription products
      } else {
        // console.log("subbbb 11111111", result)

        return result;
      }
    } catch (error) {
      console.log("shopifyerror", error)
      toast.error("There is something wrong with your Shopify connection.");
    }
  };
  

  const getWoocommerceProducts = async (page = 1) => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/woocommerce/products?page=${page}&test`,
        tokenConfig(store.getState)
      );

      return result;
    } catch (error) {
      toast.error(
        "There is something wrong with your Woo Commerce connection."
      );
    }
  };

  const getRechargeProducts = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/recharge/products`,
        tokenConfig(store.getState)
      );
      return result;
    } catch (error) {
      toast.error("There is something wrong with your Recharge connection.");
    }
  };

  const getBigcommerceProducts = async () => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/bigcommerce/products`,
        tokenConfig(store.getState)
      );
      return result;
    } catch (error) {
      toast.error(
        "There is something wrong with your Big Commerce connection."
      );
    }
  };

  // const getSubblyProducts = async () => {
  //   if (auth && auth.user.stores.subbly) {
  //     return auth.user.stores.subbly.products;
  //   }
  // };

  // useEffect(()=>{
  //   getSubblyProducts()
  // },[page])

  const getSubblyProducts = async (page = 1) => {
    try {
      const result = await axios.get(
        `${BASE_DEV_URL}/store/subbly/products?page=${page}`,
        tokenConfig(store.getState)
      );
      return result;
    } catch (error) {
      toast.error("There is something wrong with your Subbly connection.");
    }
  };

  const getMergeWidgets = useMemo(() => {
    let mergeWidgets = [];
    if (params.step > 1 && form?._id) {
      form?.steps.slice(0, params.step - 1).forEach((el) => {
        if (el.elements.length >= 1) {
          el.elements.forEach((e) => {
            if (MERGETAGSINPUT.includes(e.element_type))
              mergeWidgets.push({
                id: e._id,
                name: e.name,
                step: el.order_number,
              });
          });
        }
      });
    }
    return mergeWidgets;
  }, [params.step, form]);

  useEffect(() => {
    if (!form._id) {
      axios
        .get(`${BASE_DEV_URL}/form/${params.id}`, tokenConfig(store.getState))
        .then((response) => {
          setFormDispatcher(response.data.form);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [form]);

  const storeToMethodMap = {
    woocommerce: getWoocommerceProducts,
    shopify: getShopifyProducts,
    bigcommerce: getBigcommerceProducts,
    subbly: getSubblyProducts,
    recharge: getRechargeProducts,
  };

  useEffect(() => {
    if (form?.store_selected) {
      storeToMethodMap[form.store_selected]().then((res) => {
        let widgets = Array.from(_.cloneDeep(items.widgets));
        widgets = widgets.map((widget) => {
          if (widget.data.element_type === "form_product" || widget.data.element_type === "form_dynamic_product") {
            let prod = [];
            if (form.store_selected === "subbly") {
              prod = res;
            } else {
              prod = res?.data?.products || [];
            }

            if (form.store_selected === "subbly") {
              if (prod.length > 2) {
                let sProd = prod.slice(0, 2).map((item) => {
                  return item.id.toString();
                });
                widget.data.content.products_objects_array = prod
                  .slice(0, 2)
                  .map((item) => ({
                    key: item.id,
                    label: item.product_name,
                  }));
                widget.data.content.products = [...sProd];
              }
            }
          }
          return widget;
        });
        setItems({ ...items, widgets });
      });
    } else {
      let widgets = Array.from(_.cloneDeep(items.widgets));
      widgets = widgets.map((widget) => {
        if (widget.data.element_type === "form_product" || widget.data.element_type === "form_dynamic_product") {
          widget.data.content.products = [];
          widget.products = [];
        }
        return widget;
      });

      setItems({ ...items, widgets });
    }
  }, [form?.store_selected]);

  const filterItems = (value) => {
    const widgets = Array.from(items.widgets);

    const re = new RegExp(value, "i");
    widgets.forEach((widget) => {
      if (re.test(widget.data.name)) {
        widget.show = true;
      } else {
        widget.show = false;
      }
    });

    setItems({
      ...items,
      widgets: widgets,
    });
  };

  useEffect(() => {
    filterItems(search);
  }, [search]);

  const getEditSidebarWidget = () => {
    switch (widget.element_type) {
      case "form_short_text":
        return <ShortTextContainers mergeWidget={getMergeWidgets} />;
      case "form_date":
        return <DateContainer />;
      case "form_long_text":
        return <LongTextContainers mergeWidget={getMergeWidgets} />;
      case "form_dropdown":
        return <DropDownContainer mergeWidget={getMergeWidgets} />;
      case "form_paragraph":
        return <ParagraphContainers mergeWidget={getMergeWidgets} />;
      case "form_skip_step":
        return <SkipStepContainers />;
      case "form_name":
        return <FullNameContainers />;
      case "form_image":
        return <ImageContainer setCrop={setCrop} />;
      case "form_heading":
        return <IntroductionContainers mergeWidget={getMergeWidgets} />;
      case "form_email":
        return <EmailContainer mergeWidget={getMergeWidgets} />;
      case "form_product":
        return <ProductsContainers storeToMethodMap={storeToMethodMap} />;
      case "form_dynamic_product":
          return <DynamicProductsContainers storeToMethodMap={storeToMethodMap} />;
      case "form_upsell":
        return <UpsellContainers storeToMethodMap={storeToMethodMap} />;
      case "form_single_choice":
        return (
          <SingleChoiceContainers
            setCrop={setCrop}
            mergeWidget={getMergeWidgets}
          />
        );
      case "form_multi_choice":
        return (
          <MultiChoiceContainers
            setCrop={setCrop}
            mergeWidget={getMergeWidgets}
          />
        );
      case "form_address":
        return <AddressContainer />;
      case "form_phone":
        return <PhoneNumberContainers mergeWidget={getMergeWidgets} />;
      case "form_number":
        return <NumberContainers mergeWidget={getMergeWidgets} />;
      case "form_redirect_choice":
        return (
          <RedirectChoiceContainer
            setCrop={setCrop}
            mergeWidget={getMergeWidgets}
          />
        );
      default:
        return "foo";
    }
  };

  const getElementFromList = (key, index) => {
    const startList = Array.from(items[key]);
    const selected = startList[index];
    return selected;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (result.source.droppableId !== result.destination.droppableId) {
      createElement(
        getElementFromList(result.source.droppableId, result.source.index),
        result.destination.index,
        params,
        form
      );
    } else {
      let element = _.cloneDeep(stepElements[result.source.index]);
      element.order = result.destination.index + 1;
      reorder(
        stepElements,
        result.source.index,
        result.destination.index,
        element
      );
    }
  };

  const updateElement = (step, newElement, oldElementId) => {
    step = _.cloneDeep(step);
    let element =
      step.elements[
        _.findIndex(step.elements, function (element) {
          return element._id === oldElementId;
        })
      ];

    if (element) {
      if (element.order < parseInt(newElement.order)) {
        step.elements = step.elements.map((e) => {
          if (e.order <= newElement.order && e.order > parseInt(element.order))
            e.order -= 1;
          return e;
        });
      } else {
        step.elements = step.elements.map((e) => {
          if (e.order >= newElement.order && e.order < parseInt(element.order))
            e.order += 1;
          return e;
        });
      }
      step.elements[
        _.findIndex(step.elements, function (element) {
          return element._id === oldElementId;
        })
      ] = newElement;
      return step;
    }
  };

  const reorder = (arr, startIndex, endIndex, element) => {
    const result = Array.from(arr);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    let newForm = _.cloneDeep(form);
    let steps = Array.from(newForm.steps);
    steps.map((step) => {
      if (step.order_number === parseInt(params.step)) step.elements = result;
      return step;
    });
    newForm.steps = steps;
    newForm.steps[params.step - 1] = updateElement(
      newForm.steps[params.step - 1],
      element,
      element._id
    );
    handleSetFormDispatcher([`steps`], [steps], form, setFormDispatcher);
  };

  const elementPosition = (steps, element) => {
    return steps[params.step - 1].elements.findIndex(
      (item) => item._id == element._id
    );
  };

  const editElement = (element) => {
    setWidgetStepAndPositionDispatcher(
      params.step - 1,
      elementPosition(form.steps, element)
    );
    setWidgetDispatcher(element, null, false);
    setFormStatesDispatcher(true, "openEditWidget");
  };

  const stepLen = useMemo(() => {
    if (form) {
      if (form.steps) {
        return form.steps.length;
      }
    }
    return 1;
  }, [form]);

  const SidebarButtons = ({ id, title, image, onClick, className = "" }) => (
    <Tooltip
      id={id}
      title={title}
      arrow
      placement="right"
      className={`form-tooltip hoverable ${className}`}
    >
      <img
        onClick={onClick}
        src={process.env.PUBLIC_URL + `/assets/images/icons/${image}`}
        alt={title}
        className={`img-fluid form-logo`}
      />
    </Tooltip>
  );

  const beforeunload = (e) => {
    if (updatedForm) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [updatedForm]);

  const to_redirect = (link, link2 = null) => {
    const NOT_ALLOWED = ["/", "/form", "/form/responses"];
    if (NOT_ALLOWED.includes(link)) {
      if (updatedForm) {
        setDiscardChangesLink(link2 ? link2 : link);
        return false;
      } else {
        resetFormStateDispatcher();
        return true;
      }
    }
    return true;
  };

  const leaveForm = () => {
    resetFormStateDispatcher();
    nav(discardChangesLink);
  };
  const menuWidgets = {
    openWidgets: {
      component: (
        <WidgetSidebar
          widgets={items.widgets}
          search={search}
          searchHandler={setSearch}
          account_type={auth.user.transactions[0]?.account_type}
        />
      ),
      showResponsive: true,
    },
    openSettings: {
      component: <SettingsSidebar />,
      showResponsive: false,
    },
    openHistory: {
      component: <HistorySidebar />,
      showResponsive: false,
    },
    openIntegrations: {
      component: <IntegrationsSidebar
      widgets={items.widgets}
      search={search}
      searchHandler={setSearch}
      account_type={auth.user.transactions[0]?.account_type}
      />,
      showResponsive: false,
    },
    openEditWidget: {
      component: getEditSidebarWidget(),
      showResponsive: true,
    },
    openStyle: {
      component: <StyleSidebar />,
      showResponsive: false,
    },
    openPublish: {
      component: <PublishSidebar />,
      showResponsive: false,
    },
  };
  let basePath = window.location.pathname;

  // Check if the URL already ends with '/integration/subbly' and remove it if present
  const subblyPath = '/integration/subbly';
  if (basePath.endsWith(subblyPath)) {
    basePath = basePath.substring(0, basePath.length - subblyPath.length);
  }

  const newPath = `${basePath}${subblyPath}`;
  return !form?._id ? (
    <FormifyLoader removeLogo={false} />
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className="container-fluid  form-builder"
        style={{
          minHeight: "100vh",
          paddingLeft: 0,
          backgroundColor: form?.form_style?.color?.page_color,
        }}
      >
        {discardChangesLink && (
          <UnsavedChanges
            leaveForm={leaveForm}
            setVisible={setDiscardChangesLink}
          />
        )}
        <div className="row">
          <div className="form-sidebar">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/formify_logo_icon.svg"
              }
              alt="Formify"
              className="img-fluid form-logo pointer"
              onClick={() => {
                if (to_redirect("/")) nav("/");
              }}
            />
            <div className="form-sidebard-buttons">
              <SidebarButtons
                id="dashboard-tooltip"
                title="Widgets"
                image="dashboard.svg"
                onClick={() => {
                  openWidgetSidebar();
                  nav(
                    `/form/${form._id}/step/${params.step ? params.step : 1}`
                  );
                }}
              />
              <SidebarButtons
                id="templates-tooltip"
                title="Templates"
                image="templates.svg"
              />
              <SidebarButtons
                id="integrations-tooltip"
                title="Integrations"
                image="integrations.svg"
                onClick={() => {
                  setFormStatesDispatcher(true, "openIntegrations");
                  setResponsiveDispatcher("desktop");
                }}
              />
              <SidebarButtons
                id="style-tooltip"
                title="Style"
                image="brush-2.svg"
                onClick={() => {
                  openStyleSidebar();
                  nav(
                    `/form/${params.id}/step/${params.step ? params.step : 1}`
                  );
                }}
              />
              <SidebarButtons
                id="preview-tooltip"
                title="Preview"
                image="eye.svg"
                onClick={() => {
                  openWidgetSidebar();
                  nav(`/form/view/${params.id}`);
                }}
              />
              <SidebarButtons
                id="settings-tooltip"
                title="Settings"
                image="settings.svg"
                onClick={() => openSettingsSidebar()}
              />
              <SidebarButtons
                id="publish-tooltip"
                title="Publish"
                image="link.svg"
                onClick={() => openPublishSidebar()}
              />
              <SidebarButtons
                id="responses-tooltip"
                title="Responses"
                image="users.svg"
                onClick={() => {
                  if (
                    to_redirect(
                      "/form/responses",
                      `/form/responses/${params.id}`
                    )
                  )
                    nav(`/form/responses/${params.id}`);
                }}
              />
              <SidebarButtons
                id="exit-tooltip"
                title="Exit"
                image="left-arrow.svg"
                onClick={() => {
                  if (to_redirect("/")) nav("/");
                }}
                className="exit-button"
              />
            </div>
          </div>
          {formStates.sideBar && <UpdateForm />}
          <div className="col">{menuWidgets[formStates.state].component}</div>
        </div>
        <div
          className="form-builder-container row justify-content-center"
          style={{
            marginLeft: formStates.sideBar ? `${DRAWER_WIDTH}px` : 0,
            overflow: stepElements.length > 0 ? "hidden scroll" : " unset",
          }}
        >
          {menuWidgets[formStates.state].showResponsive && (
            <div className="responsive-ribbon">
              <ResponsiveTabs
                setDeviceFrame={setDeviceFrame}
                deviceFrame={deviceFrame}
              />
            </div>
          )}
          <div
            className={`col-lg-12 d-flex  flex-column align-items-center mt-5 transition ${
              deviceFrame &&
              menuWidgets[formStates.state].showResponsive &&
              "screen-preview"
            }`}
            style={{
              maxWidth:
                responsive === "mobile"
                  ? 414
                  : responsive === "tablet"
                  ? 767
                  : parseInt(form.form_style.layout.width),
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    {form?.font_family &&
                      Object.keys(form?.font_family).map((i, j) => {
                        return (
                          <GlobalStyle
                            name={i}
                            url={form?.font_family[i].url}
                            key={i}
                          />
                        );
                      })}
                    <div className="form-widget steps">
                      <FormSteps
                        currentstep={params.step}
                        steps={form.steps}
                        formId={params.id}
                        style={form.form_style ? form.form_style.layout : {}}
                        openWidgetSidebar={openWidgetSidebar}
                      />

                      <div className="edit-tab">
                        <button
                          onClick={() => {
                            openStyleSidebar();
                            nav(
                              `/form/${params.id}/step/${
                                params.step ? params.step : 1
                              }`
                            );
                          }}
                        >
                          <Edit className="edit-icon" />
                        </button>
                      </div>
                    </div>
                    <FormCanvas
                      form={form}
                      openHandler={openWidgetSidebar}
                      isEditable={true}
                      elements={stepElements}
                      editElement={editElement}
                      step={params.step}
                    />
                    <div className="form-widget steps">
                      <div className="form-btn-group d-flex justify-content-center  mb-5 ">
                        {params.step < stepLen ? (
                          <div className="col">
                            <FormifyButton
                              type="button"
                              classes="invert-formify-btn-hover form-submit-btn "
                              onClick={() => {
                                openWidgetSidebar();
                                nav(
                                  `/form/${params.id}/step/${
                                    parseInt(params.step) + 1
                                  }`
                                );
                                setActiveStep(true);
                              }}
                              loaderWhite={true}
                              title={
                                form?.form_style?.layout?.buttons?.nextContent
                              }
                              onMouseLeave={() => setHover(false)}
                              onMouseEnter={() => setHover(true)}
                              style={
                                hover
                                  ? {
                                      ...form.form_style.layout.buttons.onHover,
                                      marginTop:
                                        form.form_style.layout.buttons.default
                                          .marginTop,
                                      marginBottom:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginBottom,
                                      marginLeft:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginLeft,
                                      marginRight:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginRight,
                                      paddingTop:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingTop,
                                      paddingBottom:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingBottom,
                                      paddingLeft:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingLeft,
                                      paddingRight:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingRight,
                                    }
                                  : form.form_style.layout.buttons.default
                              }
                            />
                          </div>
                        ) : null}

                        {params.step === stepLen.toString() ? (
                          <div className="col">
                            <FormifyButton
                              type="button"
                              classes="invert-formify-btn-hover form-submit-btn"
                              onClick={() => {
                                setActiveStep(true);
                              }}
                              loaderWhite={true}
                              title={
                                form?.form_style?.layout?.buttons?.submitContent
                              }
                              onMouseLeave={() => setHover(false)}
                              onMouseEnter={() => setHover(true)}
                              style={
                                hover
                                  ? {
                                      ...form.form_style.layout.buttons.onHover,
                                      marginTop:
                                        form.form_style.layout.buttons.default
                                          .marginTop,
                                      marginBottom:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginBottom,
                                      marginLeft:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginLeft,
                                      marginRight:
                                        form.form_style.layout?.buttons?.default
                                          ?.marginRight,
                                      paddingTop:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingTop,
                                      paddingBottom:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingBottom,
                                      paddingLeft:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingLeft,
                                      paddingRight:
                                        form.form_style.layout?.buttons?.default
                                          ?.paddingRight,
                                    }
                                  : form.form_style.layout.buttons.default
                              }
                            />
                          </div>
                        ) : null}

                        <div className="edit-tab">
                          <button
                            onClick={() => {
                              openStyleSidebar();
                              nav(
                                `/form/${params.id}/step/${
                                  params.step ? params.step : 1
                                }`
                              );
                            }}
                          >
                            <Edit className="edit-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
              <Route path="/integration/hubspot" element={<Hubspot />} />
              <Route path="/integration/salesforce" element={<SalesForce />} />
              <Route path="/integration/klaviyo" element={<Klaviyo />} />
              <Route
                path="/integration/activecampaign"
                element={<ActiveCampaign />}
              />
              <Route path="/integration/mailchimp" element={<MailChimp />} />
              <Route
                path="/integration/shopify"
                element={<Store store="shopify" />}
              />
              <Route
                path="/integration/recharge"
                element={<Store store="recharge" />}
              />
              <Route
                path="/integration/woocommerce"
                element={<Store store="woocommerce" />}
              />
              <Route
                path="/integration/subbly"
                element={<Store store="subbly" />}
              />
              <Route
                path="/integration/bigcommerce"
                element={<Store store="bigcommerce" />}
              />
            </Routes>
          </div>
        </div>
        {form.store_selected === "subbly" &&
        form.integrations?.subbly?.survey == undefined ? (
          <div className="warning-holder">
            <div
              className="subbly-integration-warning"
              style={{ display: shouldDisplayWarning(0) ? "block" : "none" }}
            >
              <p className="warning-info-text">
                {" "}
                You do not have a survey connected with your Formify flow. If
                you use surveys with your products, please map your questions
                <Link to={`${newPath}?openConfig=true`}>[here]</Link>. Failing to
                connect a product with a survey to Formify may lead to:
              </p>
              <ul>
                <li>
                  Users seeing and having to answer the survey questions again
                  after the Formify flow.
                </li>
                <li>
                  Customer preferences not being passed into your subscription
                  CRM.
                </li>

                <li>Incomplete customer information records.</li>
                {/* <li>Read more about mapping in our documentation <Link to="/integrations/subbly">[here]</Link>.</li> */}
                <li>Read more about mapping in our documentation <a style={{color:"#0d6efd"}} href="https://docs.google.com/document/d/1Wy2u9nhl4P-uueI2ABoHiHlLx_gH68QeUFNt9Aiom3Y/edit#heading=h.96g1fnf489n5" target="_blank" className="link-dark rounded">[here]</a>.</li>


                
                <li>
                  If you do not use surveys on your products, please skip this
                  warning
                </li>
              </ul>
              {shouldDisplayWarning(0) && (
                <>
                  <Link
                    className="formify-btn"
                    style={{ marginRight: "10px" }}
                    to={`${newPath}?openConfig=true`}
                  >
                    
                    Go to fix
                  </Link>

                  <button
                    className="delete-btn"
                    style={{ margin: "0px" }}
                    onClick={() => handleDismiss(0)}
                  >
                    Dismiss!
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {Object.keys(crop).length !== 0 && (
        <MediaLibraryModal data={crop} setData={setCrop} />
      )}
    </DragDropContext>
  );
};

export default connect(mapAuthAndFormStateToProps, {
  setWidgetDispatcher,
  createElement,
  setFormDispatcher,
  setResponsiveDispatcher,
  setFormStatesDispatcher,
  resetFormStateDispatcher,
  setWidgetStepAndPositionDispatcher,
})(FormBuilder);
