/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Plus, X } from "react-feather";
import InputField from "../single-components/InputField";
import SwitchChip from "../single-components/switchChip";
import FontFamilyPicker from "../single-components/FontFamilyPicker";
import AlignmentTabs from "../single-components/AlignmentTabs";
import WidthOptions from "../single-components/WidthOptions";
import ColorPickerStyle from "../single-components/ColorPickerStyle";
import { Accordion } from "react-bootstrap";
import ChangeResponsiveness from "../single-components/changeResponsiveness";
import { mapAuthAndFormStateToProps } from "../../../../utils";
import { connect } from "react-redux";
import { setWidgetDispatcher } from "../../../../actions/form";
import { handleSetWidgetDispatcher } from "../../../utils/handleDispatcher";
import FormifyInput from "../single-components/FormifyInput";
import { checkFontSize, getResponsiveStyle } from "../../../utils/responsiveFunctions";

const EmailContent = ({
  form: { widget, responsive },
  setWidgetDispatcher,
  mergeWidget,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e, type) => {
    handleSetWidgetDispatcher([`content.${e.target.id}`], [e.target.value], widget, setWidgetDispatcher, false, type);
  };

  const handleChangeAlign = (e, id) => {
    handleSetWidgetDispatcher([`style.${responsive}.${id}`], [e], widget, setWidgetDispatcher);
  };
  const changeRequired = (val, key) => {
    if(key === "required"){
      if(val){
        handleSetWidgetDispatcher([
          `style.desktop.hide`,
          `style.mobile.hide`,
          `style.tablet.hide`,
          `content.required`
          ], 
          [false, false,false,val], widget, setWidgetDispatcher);
      }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
    }else handleSetWidgetDispatcher([`content.${key}`], [val], widget, setWidgetDispatcher);
  };

  const handleDomainChange = (val, index) => {
    if (widget.content) {
      const domain = Array.from(widget.content.allowedDomains);
      domain[index] = val;
      handleSetWidgetDispatcher([`content.allowedDomains`], [domain], widget, setWidgetDispatcher);
    }
  };

  const addDomain = (val) => {
    if (widget.content) {
      const domain = Array.from(widget.content.allowedDomains);
      domain.push(val);
      handleSetWidgetDispatcher([`content.allowedDomains`, `required`], [domain, false], widget, setWidgetDispatcher);
    }
  };

  const removeDomain = (index) => {
    if (widget.content) {
      const domain = Array.from(widget.content.allowedDomains);
      domain.splice(index, 1);
      handleSetWidgetDispatcher([`content.allowedDomains`], [domain], widget, setWidgetDispatcher);
    }
  };

  const updateLabelFont = (name, url) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.fontFamily`,
        `style.desktop.input.fontFamily`,
        `style.desktop.helpText.fontFamily`
      ], 
      [
        { name: name, src: url },
        { name: name, src: url },
        { name: name, src: url }
      ], widget, setWidgetDispatcher, true
    );
  };

  const updateWidth = (e) => {
    handleSetWidgetDispatcher([`style.${responsive}.input.width`], [e], widget, setWidgetDispatcher);
  };

  const updateColorLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.label.color`,
        `style.tablet.label.color`,
        `style.mobile.label.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorHelpText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.helpText.color`,
        `style.tablet.helpText.color`,
        `style.mobile.helpText.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorAcceptanceText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.acceptance_text.color`,
        `style.tablet.acceptance_text.color`,
        `style.mobile.acceptance_text.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateColorAcceptanceLinkText = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.acceptance_text.linkColor`,
        `style.tablet.acceptance_text.linkColor`,
        `style.mobile.acceptance_text.linkColor`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateFontSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.label.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateInputSize = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.input.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  const updateInputLabel = (value) => {
    handleSetWidgetDispatcher(
      [
        `style.desktop.input.color`,
        `style.tablet.input.color`,
        `style.mobile.input.color`
      ], 
      [value, value, value], widget, setWidgetDispatcher
    );
  };

  const updateFontSizeHelpText = (e) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.helpText.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher);
    }
  };

  const updateFontSizeAcceptanceText = (e, type) => {
    if (/[0-9]/.test(e.target.value)) {
      handleSetWidgetDispatcher([`style.${responsive}.acceptance_text.${e.target.id}`], [e.target.value + "px"], widget, setWidgetDispatcher, false, type);
    }
  };

  return (
    <>
      <div className="settings-field-container">
        <div className="editable-field  quick-form-actions">
          <div className="quick-action-container font-family-picker">
            <FontFamilyPicker
              attribute={widget?.style?.desktop?.label}
              onChangeFont={updateLabelFont}
              id={"heading"}
            />
          </div>
          <div className="quick-action-container">
            <AlignmentTabs
              id={"textAlign"}
              tag={getResponsiveStyle(widget?.style, responsive, "textAlign", null)}
              handleSelect={handleChangeAlign}
            />
          </div>
          <div className="quick-action-container">
            <WidthOptions
              handleSelect={updateWidth}
              tag={getResponsiveStyle(widget?.style, responsive, "input", "width")}
              id={"width"}
              name={"width"}
            />
          </div>
        </div>
        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">Title</Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <InputField
                  id={"title"}
                  label={"Title"}
                  onChange={handleChange}
                  placeholder={"Title"}
                  type="text"
                  value={widget.content.title}
                  mergeWidget={mergeWidget}
                />
                <div className="editable-field quick-form-actions pt-4 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1"><ChangeResponsiveness /></div>
                    </div>
                    <FormifyInput 
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={updateFontSize}
                      value={checkFontSize(widget?.style, responsive, "label", "fontSize")}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateColorLabel}
                      id={"color"}
                      tag={getResponsiveStyle(widget?.style, responsive, "label", "color")}
                    />
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Placeholder
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <InputField
                  id={"placeholder"}
                  label={"Placeholder"}
                  onChange={handleChange}
                  value={widget.content.placeholder}
                  placeholder={"Placeholder"}
                  type="text"
                />
                <div className="editable-field  quick-form-actions pt-4 family-picke">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1"><ChangeResponsiveness /></div>
                    </div>
                    <FormifyInput 
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={updateInputSize}
                      value={checkFontSize(widget?.style, responsive, "input", "fontSize")}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateInputLabel}
                      id={"color"}
                      tag={widget?.style?.[responsive]?.input?.color}
                    />
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Help Text
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <InputField
                  id={"help_text"}
                  label={"Help Text"}
                  onChange={handleChange}
                  value={widget.content.help_text}
                  placeholder={"Help Text"}
                  type="text"
                />
                <div className="editable-field  quick-form-actions pt-4 family-picke">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1"><ChangeResponsiveness /></div>
                    </div>
                    <FormifyInput 
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={updateFontSizeHelpText}
                      defaultValue={widget?.style?.[responsive]?.helpText?.fontSize}
                      value={checkFontSize(widget?.style, responsive, "helpText", "fontSize")}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateColorHelpText}
                      id={"color"}
                      tag={widget?.style?.[responsive]?.helpText?.color}
                    />
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="field-label">
              Acceptance Text
            </Accordion.Header>
            <Accordion.Body>
              <div className="editable-field ">
                <InputField
                  id={"acceptance_text"}
                  label={"Acceptance Text"}
                  onChange={handleChange}
                  value={widget.content.acceptance_text}
                  placeholder={
                    "I accept to the {{terms}} and {{privacy-policy}}"
                  }
                  type="text"
                  numRows={3}
                />
                <InputField
                  id={"acceptance_text_tos"}
                  label={"ToS Text"}
                  onChange={handleChange}
                  value={widget.content.acceptance_text_tos}
                  placeholder={"terms and services"}
                  type="text"
                />
                <InputField
                  id={"acceptance_text_tos_link"}
                  label={"ToS Link"}
                  onChange={handleChange}
                  value={widget.content.acceptance_text_tos_link}
                  placeholder={"https://"}
                  type="text"
                />
                <InputField
                  id={"acceptance_text_privacy_policy"}
                  label={"Privacy Policy Text"}
                  onChange={handleChange}
                  value={widget.content.acceptance_text_privacy_policy}
                  placeholder={"Privacy Policy"}
                  type="text"
                />
                <InputField
                  id={"acceptance_text_privacy_policy_link"}
                  label={"Privacy Policy Link"}
                  onChange={handleChange}
                  value={widget.content.acceptance_text_privacy_policy_link}
                  placeholder={"https://"}
                  type="text"
                />
                <div className="editable-field  quick-form-actions pt-4 font-family-picker">
                  <div className="quick-action-container">
                    <div className="d-flex justify-between">
                      <p>Font Size(px)</p>
                      <div className="mb-1"><ChangeResponsiveness /></div>
                    </div>
                    <FormifyInput 
                      type="number"
                      id={"fontSize"}
                      min={1}
                      max={200}
                      onChange={updateFontSizeAcceptanceText}
                      defaultValue={checkFontSize(widget?.style, responsive, "acceptance_text", "fontSize")}
                      value={checkFontSize(widget?.style, responsive, "acceptance_text", "fontSize")}
                    />
                  </div>
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Font Color</p>
                    <ColorPickerStyle
                      onChange={updateColorAcceptanceText}
                      id={"color"}
                      tag={widget?.style?.[responsive]?.acceptance_text?.color}
                    />
                  </div>
                  <hr />
                  <div className="editable-field w-100 form-row mt-3 mb-0">
                    <p>Link Color</p>
                    <ColorPickerStyle
                      onChange={updateColorAcceptanceLinkText}
                      id={"linkColor"}
                      tag={widget?.style?.[responsive]?.acceptance_text?.linkColor}
                    />
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="settings-field-container">
        <div className="editable-field form-row">
          <p className="field-label">Required</p>
          <SwitchChip
            id="required"
            checked={widget.content ? widget.content.required : false}
            onChange={(val) => changeRequired(val, "required")}
          />
        </div>
        <div className="editable-field form-row">
          <p className="field-label">Restrict to certain domains</p>
          <SwitchChip
            id={"domains"}
            checked={widget.checked}
            onChange={(checked) => setChecked(checked)}
          />
        </div>
        <div className="editable-field form-row">
          <p className="field-label">Hide Label</p>
          <SwitchChip
            id="hide_label"
            checked={widget.content ? widget.content.hide_label : false}
            onChange={(val) => {
              changeRequired(val, "hide_label");
            }}
          />
        </div>
        {checked ? (
          widget.content ? (
            <>
              {widget.content.allowedDomains.map((ad, index) => {
                return (
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <InputField
                      id={"domain"}
                      label={"Domain"}
                      onChange={(e) => {
                        handleDomainChange(e.target.value, index);
                      }}
                      placeholder={"Help Text"}
                      type="text"
                      value={ad || ""}
                    />
                    <a
                      href="#/"
                      onClick={() => {
                        removeDomain(index);
                      }}
                    >
                      <X style={{ color: "#ea4335" }} />
                    </a>
                  </div>
                );
              })}
              <div className="d-flex justify-content-center">
                <button
                  className="formify-btn-no-margin text-center"
                  style={{
                    borderRadius: "50%",
                    padding: "5px",
                    width: "35px",
                    height: "35px",
                  }}
                  onClick={() => {
                    addDomain("example.com");
                  }}
                >
                  <Plus />
                </button>
              </div>
            </>
          ) : null
        ) : null}{" "}
      </div>
    </>
  );
};

export default connect(mapAuthAndFormStateToProps, { setWidgetDispatcher })(EmailContent);
