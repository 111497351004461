import { Modal } from "react-bootstrap";
import { ArrowRight } from "react-feather";

const BillingModal = ({
  show,
  currentPlan,
  newPlan,
  setShow,
  updateSub,
  proRate,
  type,
  textContent,
}) => {

  return (
    <Modal show={show} size="lg" centered className="name-modal">
      <Modal.Header>
        {/* <div className="billing-modal-header w-100 d-flex justify-content-center align-items-center">
          <div
            className={`plan-header me-2  ${
              currentPlan
                ? currentPlan.plan_name.split(" ").join("-").toLowerCase()
                : null
            } billing-modal`}
          >
            <p> {currentPlan ? currentPlan?.plan_name: null}</p>
          </div>
          <ArrowRight style={{ color: "#6563FF" }} />

          <div
            className={`plan-header  ms-2 ${
              newPlan
                ? newPlan?.product?.name.split(" ").join("-").toLowerCase()
                : null
            } billing-modal`}
          >
            <p> {newPlan ? newPlan?.product?.name : null}</p>
          </div>
        </div> */}
      </Modal.Header>
      <Modal.Body>
        <div className="billing-modal-body">
          <h4 className="formify-h4 text-center font-weight-600">
            {type === "downgrade" ? `Downgrading` : `Upgrading`} to{" "}
            {newPlan ? newPlan?.product.name : null}
          </h4>
          <p className="grey-p mt-2 text-center">{textContent}</p>
            <p className="grey-p mt-2 text-center"> Your prorated total for this {type} will be ${proRate}</p>
          <div className="btn-group d-flex flex-column align-itemns">
            <button
              className="formify-btn-no-margin"
              style={{ padding: "10px 15px" }}
              onClick={() => {
                updateSub(newPlan.id);
              }}
            >
              {type === "downgrade" ? `Downgrade` : `Upgrade`}
            </button>
            <button
              className="invert-formify-btn mt-2"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BillingModal;
