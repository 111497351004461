import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BASE_DEV_URL, mapAuthErrorsStateToProps } from "../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../actions/auth";
import axios from "axios";
import { Overlay, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { clearErrors } from "../../actions/errors";
const PasswordResetEmail = ({
  auth,
  resetPassword,
  errors,
  messages,
  clearErrors,
}) => {
  const [form, setForm] = useState({
    password: "",
    confirm_password: "",
    isValid: false,
    passwordReset: false,
  });
  const nav = useNavigate();
  const passwordRef = useRef(null);
  const cPasswordRef = useRef(null);
  let params = useParams();
  useEffect(() => {
    if (auth.isAuthenticated) nav("/user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    axios
      .post(`${BASE_DEV_URL}/auth/reset/password/verify`, {
        token: params.token,
      })
      .then((res) => {
        setForm({
          ...form,
          isValid: res.data.valid,
        });
      })
      .catch((err) => {
        nav("/login");
      });

    return function cleanup() {
      clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(messages)) {
      if (messages.message === "Password is set") {
        setForm({ ...form, passwordReset: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.password === form.confirm_password) {
      resetPassword({ password: form.password, token: params.token });
    }
  };

  return form.isValid ? (
    !form.passwordReset ? (
      <div className="container login-wraper mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-12 login-form-wraper px-4 py-5 pt-4">
            <div className="login-header forgot-password-header  ms-3 d-flex flex-column align-items-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
                alt="formify-logo"
                className="img-fluid"
              />
              <h4>Reset Your Password</h4>
              <p className="text-center">
                We get it, it happens! Enter the new password you would like to associate with your account!
              </p>
            </div>
            <Overlay
              target={passwordRef.current}
              show={!_.isEmpty(errors)}
              placement="bottom"
            >
              {(props) => (
                <Tooltip
                  id="login-error"
                  style={{ backgroundColor: "#FC5B3F" }}
                  {...props}
                >
                  {errors.message}
                </Tooltip>
              )}
            </Overlay>

            <Overlay
              target={cPasswordRef.current}
              show={form.password !== form.confirm_password}
              placement="bottom"
            >
              {(props) => (
                <Tooltip
                  id="login-error"
                  style={{ backgroundColor: "#FC5B3F" }}
                  {...props}
                >
                  Passwords don't match
                </Tooltip>
              )}
            </Overlay>

            <form className="login-form mt-2" onSubmit={handleSubmit}>
              <div className="input-holder position-relative">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={form.password}
                  onChange={(e) => {
                    setForm({ ...form, [e.target.id]: e.target.value });
                  }}
                  name="password"
                  placeholder="Min. 8 characters, one UPPERCASE"
                  ref={passwordRef}
                />
              </div>
              <div className="input-holder position-relative">
                <label htmlFor="cpassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirm_password"
                  value={form.confirm_password}
                  onChange={(e) => {
                    setForm({ ...form, [e.target.id]: e.target.value });
                  }}
                  name="cpassword"
                  placeholder="Min. 8 characters, one UPPERCASE"
                  ref={cPasswordRef}
                />
              </div>

              <div className="submit-btn ">
                <button>Reset Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <div className="container login-wraper mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-12 login-form-wraper px-4 py-5 pt-4">
            <div className="login-header forgot-password-header  ms-3 d-flex flex-column align-items-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/formify_ai_logo.svg"}
                alt="formify-logo"
                className="img-fluid"
              />
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/password-reset-succ.png"
                }
                style={{ minHeight: "190px", minWidth: "190px" }}
                alt="succ-logo"
                className="img-fluid"
              />
              <h4 className="text-center">Password Successfully Changed</h4>
              <p className="text-center">
                Now you can use your new password to login to your formify
                account
              </p>
            </div>

            <form>
              <div className="submit-btn ">
                <Link to="/login">
                  <button>Let’s Login</button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  ) : null;
};

export default connect(mapAuthErrorsStateToProps, {
  resetPassword,
  clearErrors,
})(PasswordResetEmail);
